import { useMemo } from 'react';
import { KPIGridItem } from '../../kpi-grid/KPIGrid';
import { recentStatusToTimelineData } from '../../../../../../utils';
import {
  AppTypography,
  RunningStatusTimelineView,
} from '../../../../../../base';
import { SystemKpiHookProps } from '../../SystemKpiHookProps';
import { useShouldRenderKpi } from '../../useShouldRenderKpi';
import { FormattedMessage } from 'react-intl';
import { Stack, styled } from '@mui/material';

const TimelineContainer = styled(Stack)({
  padding: '0.25rem 0 0 0',
  width: '100%',
});

export function useRunningStatusTimelineGridItem(props: SystemKpiHookProps) {
  const { system, gridArea, boldLabelTitles } = props;
  const shouldRender = useShouldRenderKpi(props);

  return useMemo(() => {
    if (!shouldRender) {
      return undefined;
    }
    return (
      <KPIGridItem gridArea={gridArea ?? 'runningStatusTimeline'}>
        <TimelineContainer>
          <RunningStatusTimelineView
            data={recentStatusToTimelineData(
              system?.liveStatus?.recentSystemStatus
            )}
          />
          <AppTypography
            variant="subtitle2"
            $colorKey="lightText"
            $bold={boldLabelTitles}
          >
            <FormattedMessage
              id="common-running-status-timeline-label"
              description="Label for running status timeline"
              defaultMessage="Running Status Timeline"
            />
          </AppTypography>
        </TimelineContainer>
      </KPIGridItem>
    );
  }, [
    shouldRender,
    system?.liveStatus?.recentSystemStatus,
    gridArea,
    boldLabelTitles,
  ]);
}
