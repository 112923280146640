import { Button, Dialog, DialogContent, Stack, styled } from '@mui/material';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { DialogHeader } from '../../dialogs/DialogHeader';
import { AppToggleButtonGroupItem } from '../AppToggleButton';
import { AppToggleButtonGroup } from '../AppToggleButtonGroup';
import { MobileCombinedDatePickerInputs } from './MobileCombinedDatePickerInputs';
import { CalendarPickerView } from '@mui/lab';

const StyledDialogContent = styled(DialogContent)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '1rem 1.5rem',
});

const FormContainer = styled(Stack)({
  flex: 1,
});
const StyledToggleButtonGroupContainer = styled(Stack)({
  marginBottom: '1rem',
  width: '100%',
});

const FormButtonContainer = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'center',
  marginTop: '1rem',
});

export interface MobileCombinedDatePickerDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (
    selectedToggleItem: string,
    newStartDate: string,
    newEndDate: string | null
  ) => void;
  startDate: string;
  endDate: string;
  initToggleButtonGroupSelectedItem: AppToggleButtonGroupItem;
  toggleButtonGroupItems: AppToggleButtonGroupItem[];
  singleDateToggleItems: string[]; //Array of enum values that need a single date input
  alternateForm?: ReactNode;
  alternateFormToggleItem?: AppToggleButtonGroupItem;
}
export function MobileCombinedDatePickerDialog({
  open,
  onClose,
  onSubmit,
  startDate,
  endDate,
  initToggleButtonGroupSelectedItem,
  toggleButtonGroupItems,
  singleDateToggleItems,
  alternateForm,
  alternateFormToggleItem,
}: MobileCombinedDatePickerDialogProps) {
  const [selectedItem, setSelectedItem] = useState<AppToggleButtonGroupItem>(
    initToggleButtonGroupSelectedItem
  );
  const [startValue, setStartValue] = useState<Date | null>(
    new Date(startDate)
  );
  const [endValue, setEndValue] = useState<Date | null>(new Date(endDate));

  const intl = useIntl();
  const title = intl.formatMessage({
    id: 'mobile-combined-date-range-picker-dialog-title',
    description: 'Title for combined date picker dialog',
    defaultMessage: 'Select Dates',
  });
  const applyLabel = intl.formatMessage({
    id: 'common-apply-label',
    description: 'Label for apply',
    defaultMessage: 'Apply',
  });

  const isRanged = useMemo(() => {
    return singleDateToggleItems.length > 0
      ? !singleDateToggleItems.includes(selectedItem.value)
      : true;
  }, [selectedItem.value, singleDateToggleItems]);

  const handleSubmit = useCallback(
    (
      newSelectedItem: AppToggleButtonGroupItem,
      newStartDate: Date | null,
      newEndDate: Date | null
    ) => {
      const startISO = newStartDate ? newStartDate.toISOString() : startDate;
      const endISO = newEndDate ? newEndDate.toISOString() : null;

      onSubmit(newSelectedItem.value, startISO, endISO);
      onClose();
    },
    [onClose, onSubmit, startDate]
  );

  const handleStartOnChange = useCallback((newValue: string) => {
    setStartValue(new Date(newValue));
  }, []);

  const handleEndOnChange = useCallback((newValue: string) => {
    setEndValue(new Date(newValue));
  }, []);

  const defaultValues = useMemo(() => {
    const values: Record<string, string> = {
      startDate: format(new Date(startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(endDate), 'yyyy-MM-dd'),
    };

    return values;
  }, [startDate, endDate]);

  const availableViews = useMemo(() => {
    if (selectedItem.value === 'Week') {
      return ['day' as CalendarPickerView];
    } else {
      return [selectedItem.value.toLowerCase() as CalendarPickerView];
    }
  }, [selectedItem]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogHeader
        label={title}
        headerVariant="h6"
        colorKey={'highlight1'}
        onClose={onClose}
      />
      <StyledDialogContent>
        <StyledToggleButtonGroupContainer>
          <AppToggleButtonGroup
            shouldGrow={true}
            items={toggleButtonGroupItems}
            selectedItem={selectedItem}
            didSelectItem={(item: AppToggleButtonGroupItem | undefined) => {
              if (item !== undefined) setSelectedItem(item);
            }}
          />
        </StyledToggleButtonGroupContainer>
        {alternateForm &&
        alternateFormToggleItem?.value === selectedItem.value ? (
          alternateForm
        ) : (
          <FormContainer>
            <MobileCombinedDatePickerInputs
              isRanged={isRanged}
              startDate={startValue?.toISOString() ?? startDate}
              endDate={endValue?.toISOString() ?? startDate}
              setStartDate={handleStartOnChange}
              setEndDate={handleEndOnChange}
              views={availableViews}
            />
            <FormButtonContainer>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                onClick={() => handleSubmit(selectedItem, startValue, endValue)}
              >
                {applyLabel}
              </Button>
            </FormButtonContainer>
          </FormContainer>
        )}
      </StyledDialogContent>
    </Dialog>
  );
}
