import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { UseMutationResult } from 'react-query';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  styled,
} from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import { CompanySite, GQLCompanySite, activeGroup$ } from '@beeriot/api-client';
import { SuspenseAddOrEditSiteList } from './SuspenseAddOrEditSiteList';
import { LoadingButton } from '../../../base';
import { AddOrEditSiteListHeader } from './AddOrEditSiteListHeader';

const StyledDialogContent = styled(DialogContent)({
  padding: '0px',
});

const StyledDialogActions = styled(DialogActions)({
  justifyContent: 'center',
});

export interface AddOrEditSiteListDialogProps {
  mutation: UseMutationResult<CompanySite, unknown, CompanySite, unknown>;
  open: boolean;
  confirm: (
    selectedSites: GQLCompanySite[],
    removedSites: GQLCompanySite[],
    groupId: string | null
  ) => void;
  cancel: () => void;
  editMode: boolean;
}

export function AddOrEditSiteListDialog({
  mutation,
  open,
  confirm,
  cancel,
  editMode,
}: AddOrEditSiteListDialogProps) {
  const [activeGroup] = useObservable(activeGroup$);
  const intl = useIntl();
  const [selectedSites, setSelectedSites] = useState<GQLCompanySite[]>([]);
  const [removedSites, setRemovedSites] = useState<GQLCompanySite[]>([]);

  const handleConfirm = useCallback(() => {
    confirm(selectedSites, removedSites, activeGroup?.id ?? '');
  }, [selectedSites, removedSites, confirm, activeGroup]);

  const addSitesLabel = intl.formatMessage({
    id: 'organize-sites-add-edit-sites-add-sites-label',
    description: 'Add sites label',
    defaultMessage: 'Add Sites',
  });

  const updateSitesLabel = intl.formatMessage({
    id: 'organize-sites-add-edit-sites-update-sites-button',
    description: 'Button to update site',
    defaultMessage: 'Update Sites',
  });
  const cancelLabel = intl.formatMessage({
    id: 'common-cancel-button',
    description: 'Label for cancel button',
    defaultMessage: 'Cancel',
  });

  return (
    <Dialog fullWidth open={open}>
      <AddOrEditSiteListHeader />
      <StyledDialogContent>
        <SuspenseAddOrEditSiteList
          selectedSites={selectedSites}
          setSelectedSites={setSelectedSites}
          removedSites={removedSites}
          setRemovedSites={setRemovedSites}
          editMode={editMode}
        />
      </StyledDialogContent>
      <StyledDialogActions>
        <Button color="shade" variant="contained" onClick={cancel}>
          {cancelLabel}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={handleConfirm}
          isLoading={mutation.isLoading}
        >
          {editMode ? updateSitesLabel : addSitesLabel}
        </LoadingButton>
      </StyledDialogActions>
    </Dialog>
  );
}
