import { UseQueryResult, useQuery } from 'react-query';
import { FilterRunApi, FilterRunBatchDetails } from '../generated/api';
import {
  GQLFilterRun,
  useGetFilterRunQuery,
  useInfiniteListFilterRunsQuery,
  useListFilterRunsQuery,
} from '../generated/gql';
import { getNextPageParamHandler, removeFalsey } from '../utils';
import { appConfiguration } from './configuration';
const filterRunApi = new FilterRunApi(appConfiguration);

export function useFilterRun(
  systemId: string,
  filterRunId: string,
  startTime: string,
  endTime: string
) {
  return useGetFilterRunQuery({ systemId, filterRunId, startTime, endTime });
}



async function getFilterRunBatchDetails(
  systemId: string,
  filterRunId: string,
  startTime: string,
  endTime: string
) {
  if (!systemId || !filterRunId) return null;
  const res = await filterRunApi.getFilterRunBatchDetails(
    systemId,
    filterRunId,
    startTime,
    endTime
  );
  return res.data;
}

export function useFilterRunBatchDetails(
  systemId: string,
  filterRunId: string,
  startTime: string,
  endTime: string
): UseQueryResult<FilterRunBatchDetails[]> {

  return useQuery(
    ['filterRunBatchDetails', systemId,filterRunId, startTime, endTime, ],
    () => getFilterRunBatchDetails(systemId,filterRunId, startTime, endTime,)
  );
}

export function useFilterRuns(
  systemId: string,
  startTime: string,
  endTime: string
) {
  const { data: filterRuns } = useListFilterRunsQuery(
    { systemId, startTime, endTime },
    {
      enabled: !!systemId,
      refetchInterval: 15000,
      getNextPageParam: getNextPageParamHandler(
        (query) => query?.listFilterRuns?.length
      ),
    }
  );

  // This filters out undefined and null companies and allows for neat typing
  const gqlFilterRuns = removeFalsey<GQLFilterRun>(filterRuns?.listFilterRuns);

  return gqlFilterRuns;
}
