import { FormattedMessage } from 'react-intl';
import { Alert, Button, Dialog, Stack } from '@mui/material';
import { AppTypography } from '../../../base/AppTypography';
import {
  analyticsBeerBrands$,
  analyticsEndDate$,
  analyticsMeasures$,
  analyticsStartDate$,
  analyticsSystems$,
  useActiveAppEntities,
  useGetAnalyticsExport,
} from '@beeriot/api-client';
import { useObservable } from '@ngneat/use-observable';
import { useState } from 'react';
import { LoadingButton } from '../../../base';

interface ExportAnalyticsDataDialogProps {
  open: boolean;
  onClose: () => void;
}

export function ExportAnalyticsDataDialog({
  open,
  onClose,
}: ExportAnalyticsDataDialogProps) {
  const [hasError, setHasError] = useState(false);
  const { activeSite } = useActiveAppEntities();
  const [measures] = useObservable(analyticsMeasures$);
  const [systems] = useObservable(analyticsSystems$);
  const [beerBrands] = useObservable(analyticsBeerBrands$);
  const [startDate] = useObservable(analyticsStartDate$);
  const [endDate] = useObservable(analyticsEndDate$);

  const close = () => {
    setHasError(false);
    onClose();
  };

  const exportDataMutation = useGetAnalyticsExport(
    (url) => {
      const link = document.createElement('a');
      link.href = url;
      link.click();
      link.remove();
      close();
    },
    (_err) => setHasError(true)
  );

  return (
    <Dialog open={open} onClose={close}>
      <Stack sx={{ alignItems: 'center', padding: '1rem' }}>
        <AppTypography variant="h5" gutterBottom>
          <FormattedMessage
            id="export-data-dialog-title"
            defaultMessage="Export Data"
            description="Title for export data dialog"
          />
        </AppTypography>
        <AppTypography variant="body1" sx={{ marginBottom: '2rem' }}>
          <FormattedMessage
            id="export-analytics-data-dialog-content"
            defaultMessage="This will export the currently displayed analytics data to a CSV file."
            description="Contant explaining the analytics data export"
          />
        </AppTypography>
        {hasError && (
          <Alert variant="filled" severity="info" sx={{ marginBottom: '1rem' }}>
            <FormattedMessage
              id="export-analytics-data-dialog-error-label"
              defaultMessage="An error occurred while exporting analytics data. Please try again later or contact support."
              description="Label for analytics export error"
            />
          </Alert>
        )}
        <Stack direction="row" sx={{ gap: '1rem' }}>
          <Button variant="contained" color="shade" onClick={close}>
            <FormattedMessage
              id="common-cancel-button"
              description="Label for cancel button"
              defaultMessage="Cancel"
            />
          </Button>
          <LoadingButton
            variant="contained"
            isLoading={exportDataMutation.isLoading}
            loadingLabel={
              <FormattedMessage
                id="common-exporting-label"
                description="Label to indicate data is exporting"
                defaultMessage="Exporting..."
              />
            }
            onClick={() => {
              exportDataMutation.mutate({
                siteId: activeSite?.id,
                startDate: startDate,
                endDate: endDate,
                measures: measures,
                systemIds: systems.map((x) => x.id),
                brandIds: beerBrands.map((x) => x.id),
              });
            }}
          >
            <FormattedMessage
              id="common-export-data-button"
              description="Button to export data"
              defaultMessage="Export Data"
            />
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  );
}
