import { Stack, styled } from '@mui/material';
import { ColorKey } from '@beeriot/api-client';
import { transientOptions } from '../../utils/transientOptions';

export const BreadcrumbArrowMask = styled(
  Stack,
  transientOptions
)<BreadcrumbArrowProps>(({ theme, $leftStripeColorKey }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundColor: theme.palette.componentBase.main,
  clipPath: 'polygon(0% 0%, 40% 0%, 80% 50%, 40% 100%, 0% 100%)',
  borderBottomWidth: '.5rem',
  borderBottomStyle: 'solid',
  borderBottomColor: theme.palette[$leftStripeColorKey]?.main,
  left: '-1px',
}));

const BreadcrumbArrowRight = styled(
  Stack,
  transientOptions
)<BreadcrumbArrowProps>(({ theme, $rightStripeColorKey }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  borderBottomWidth: '.5rem',
  borderBottomStyle: 'solid',
  borderBottomColor: theme.palette[$rightStripeColorKey]?.main,
  clipPath: 'polygon(2% 0%, 100% 0%, 100% 100%, 0% 100%)',
  left: '1px',
}));

const ContainerStack = styled(Stack)(({ theme }) => ({
  position: 'relative',
  width: '2rem',
  minWidth: '2rem',
  backgroundColor: theme.palette.componentBase.main,
}));

const ArrowShadowWrap = styled(Stack)({
  width: '100%',
  height: '100%',
  position: 'absolute',
  filter: `drop-shadow(1px 0px 4px rgba(0,0,0,0.22))`,
  // Clip start of drop-shadow
  clipPath: 'polygon(1% 0%, 100% 0%, 100% 100%, 0% 100%)',
  left: '-1px',
});

interface BreadcrumbArrowProps {
  $leftStripeColorKey: ColorKey;
  $rightStripeColorKey: ColorKey;
}

export const BreadcrumbArrow = ({
  $leftStripeColorKey,
  $rightStripeColorKey,
}: BreadcrumbArrowProps) => {
  return (
    <ContainerStack>
      <BreadcrumbArrowRight
        $leftStripeColorKey={$leftStripeColorKey}
        $rightStripeColorKey={$rightStripeColorKey}
      />
      <ArrowShadowWrap>
        <BreadcrumbArrowMask
          $leftStripeColorKey={$leftStripeColorKey}
          $rightStripeColorKey={$rightStripeColorKey}
        />
      </ArrowShadowWrap>
    </ContainerStack>
  );
};
