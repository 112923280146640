import { Stack, styled } from '@mui/material';
import { RootPage } from '../../RootPage';
import { LineControlBox } from './LineControlBox';

const ContentStack = styled(Stack)({
  overflow: 'hidden',
  height: '100%',
});

export function LinePage() {
  return (
    <RootPage>
      <ContentStack direction="row">
        <LineControlBox />
      </ContentStack>
    </RootPage>
  );
}
