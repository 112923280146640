import {
  useActiveAppEntities,
  useIntlError,
  useUpdateUsersAlertSettings,
  useUsersAlertSettings,
  userInfo$,
} from '@beeriot/api-client';
import { useObservable } from '@ngneat/use-observable';
import { useMemo, useState } from 'react';
import { AlertSettings, AlertSettingsFields } from '../../settings';
import { SettingsPage } from './SettingsPage';

export function AlertSettingsPage() {
  const { activeCompany } = useActiveAppEntities();
  const [userInfo] = useObservable(userInfo$);
  const [error, setError] = useState<string | undefined>();
  const currentAlertSettings = useUsersAlertSettings();
  const intlError = useIntlError();

  const saveAlertSettingsMutation = useUpdateUsersAlertSettings({
    onSuccess: () => {
      setError('');
    },
    onError: (err: string) => {
      setError(intlError(err));
    },
  });

  const alertSettings: AlertSettingsFields = useMemo(
    () => ({
      email: userInfo?.email ?? '',
      machineAlarms: currentAlertSettings.data?.alertTypes.machineAlarms,
      progressAlerts: currentAlertSettings.data?.alertTypes.progressAlerts,
      maintenanceWarnings:
        currentAlertSettings.data?.alertTypes.maintenanceWarnings,
      systemWarnings: currentAlertSettings.data?.alertTypes.systemWarnings,
      language: currentAlertSettings.data?.deliveryOptions.language,
      failureFrequencyAlert:
        currentAlertSettings.data?.deliveryOptions.failureFrequencyAlert,
    }),
    [userInfo, currentAlertSettings.data]
  );

  return (
    <SettingsPage>
      <AlertSettings
        id={userInfo?.sub ? `alerts_${userInfo?.sub}` : ''}
        entityId={activeCompany?.id ?? ''}
        alertSettings={alertSettings}
        mutation={saveAlertSettingsMutation}
        error={error}
      />
    </SettingsPage>
  );
}
