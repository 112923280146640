import { createStore, select, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { CalendarMode } from '../enums';
import { GQLBeerBrand, GQLSystem } from '../generated/gql';
import { ColorKey } from '../types';
import { startOfDay } from 'date-fns';
import { combineLatest, first, map, of, switchMap } from 'rxjs';
import { darken } from '@mui/material';
import { fullCompanyGqlSystems$, gqlSystems$ } from './system';
import { filterColorKeys } from './filterColorKeys';

export enum SiteGraphType {
  DegreeOfEfficiency = 'DegreeOfEfficiency',
  BeerProductionByLine = 'BeerProductionByLine',
  AverageConsumptionCost = 'AverageConsumptionCost',
  TotalConsumptionCosts = 'TotalConsumptionCosts',
}

export enum LineGraphType {
  DegreeOfEfficiency = 'DegreeOfEfficiency',
  AverageCapacity = 'AverageCapacity',
  BeerProduction = 'BeerProduction',
  WaterConsumption = 'WaterConsumption',
}

export interface FilterLine {
  colorKey: ColorKey;
  system: GQLSystem;
}

export interface FilterBrand {
  colorKey: ColorKey;
  brand: GQLBeerBrand;
}

export interface ExecSummaryProps {
  viewingFilter: CalendarMode;
  date: string;
  selectedSiteGraph: SiteGraphType | null;
  selectedLineGraph: LineGraphType | null;
  filterLines: FilterLine[] | null | undefined;
  filterBrands: FilterBrand[];
  filterLinesDialogOpen: boolean;
  filterBrandsDialogOpen: boolean;
}

export const execSummaryStore = createStore(
  { name: 'execSummary' },
  withProps<ExecSummaryProps>({
    viewingFilter: CalendarMode.Day,
    date: startOfDay(new Date()).toISOString(),
    filterLines: [],
    filterBrands: [],
    selectedLineGraph: null,
    selectedSiteGraph: null,
    filterLinesDialogOpen: false,
    filterBrandsDialogOpen: false,
  })
);

persistState(execSummaryStore, {
  key: 'execSummary',
  storage: sessionStorageStrategy,
});

export const execSummaryViewingFilter$ = execSummaryStore.pipe(
  select((state) => {
    return state.viewingFilter;
  })
);

export const execSummaryDate$ = execSummaryStore.pipe(
  select((state) => {
    return state.date;
  })
);

export function assignSystemColors(filters:FilterLine[] | null | undefined) {
 
  const newFilterLines: FilterLine[] | null | undefined = filters?.map((x, i) => {
    x.colorKey = filterColorKeys[i]
    return x
  });
  return newFilterLines;
}


export const execSummaryFilterLines$ = execSummaryStore.pipe(
  select((state) => {


    // return assignSystemColors(state.filterLines);
    return state.filterLines;
  }),
).pipe(
  switchMap((filters) => {
  return combineLatest([of(filters), fullCompanyGqlSystems$, gqlSystems$]).pipe(first())
  }), 
  map(([siteSystems, fullList, gqlSystems]) => {
  return siteSystems?.map((sys, idx) => {
    const system: FilterLine = sys as FilterLine;
  
    system.colorKey = filterColorKeys[idx];
    const coloredSystem = (fullList)?.find(x => x.id === sys.system.id) ?? (gqlSystems)?.find(x => x.id === sys.system.id) ;
    if(coloredSystem){
      system.colorKey = coloredSystem.colorKey;
    }
    return system;
    // return sys;
  }) ?? null;
  }));

export const execSummaryFilterBrands$ = execSummaryStore.pipe(
  select((state) => {
    return state.filterBrands;
  })
);

export const filtersAreActive$ = execSummaryStore.pipe(
  select((state) => {
    return state.filterBrands.length > 0 || (state.filterLines && state.filterLines?.length > 0);
  })
);

export const execSummarySelectedSiteGraph$ = execSummaryStore.pipe(
  select((state) => state.selectedSiteGraph)
);

export const execSummarySelectedLineGraph$ = execSummaryStore.pipe(
  select((state) => state.selectedLineGraph)
);

export const filterLinesDialogOpen$ = execSummaryStore.pipe(
  select((state) => state.filterLinesDialogOpen)
);

export const filterBrandsDialogOpen$ = execSummaryStore.pipe(
  select((state) => state.filterBrandsDialogOpen)
);
