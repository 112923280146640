export enum ThresholdValue {
  DegreeOfEfficiency = 'DegreeOfEfficiency',
  ActualFlow = 'ActualFlow',
  Cluster = 'Cluster',
  OperationalCluster = 'OperationalCluster',
  Turbidity = 'Turbidity',
  Oxygen = 'Oxygen',
  NetCapacity = 'NetCapacity',
  Opex = 'Opex',
  ConcentrateStatus = 'ConcentrateStatus',
  Water = 'Water',
  DaWater = 'DaWater',
  Caustic = 'Caustic',
  Peroxide = 'Peroxide',
  MembraneCleaner = 'MembraneCleaner',
  ActualDp = 'ActualDp',
}
