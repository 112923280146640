import { useEffect } from 'react';
import { filter, take, tap } from 'rxjs/operators';
import {
  startIdleTimer,
  stopIdleTimer,
  inactiveMinutes$,
} from '../state/auth/idleTimer';
import { useLogout } from './useLogout';

const kAUTO_LOGOUT_TIME_MINUTES = 5;

export const useAutoLogout = () => {
  const logout = useLogout();
  useEffect(() => {
    const sub = inactiveMinutes$
      .pipe(
        filter((x) => x === kAUTO_LOGOUT_TIME_MINUTES),
        take(1),
        tap((x) => logout())
      )
      .subscribe();
    startIdleTimer();
    return () => {
      sub.unsubscribe();
      stopIdleTimer();
    };
  }, []);
};
