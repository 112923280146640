import {
  MutableRefObject,
  useId,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import fastdom from 'fastdom';
import { fromEvent, interval, Subject } from 'rxjs';
import { map, throttleTime, share, tap, take } from 'rxjs/operators';
import { hookLogFactory } from './hookLogFactory';

const IS_DEBUG = process.env.NODE_ENV === 'development';
const ENABLE_LOGGING = IS_DEBUG && false;

type ElementSizeCache = Record<string, number>;
const windowWatcher = (() => {
  const resizeEvents$ = fromEvent(window, 'resize').pipe(map((x) => x));
  const input$$ = new Subject<null>();
  const output$ = resizeEvents$.pipe(
    throttleTime(30, undefined, { leading: true, trailing: true }),
    map((x) => {
      return x;
    }),
    share()
  );
  return {
    input$$,
    output$,
    elementSizeCache: {} as ElementSizeCache,
  };
})();

type UseDomNodeClientRectValueProps = {
  nodeRef?: MutableRefObject<HTMLDivElement | null>;
  key: 'clientWidth' | 'clientHeight' | 'clientLeft' | 'clientTop';
  defaultValue?: number;
  watchWindowResize?: boolean;
  cachedValueIdentifier?: string;
};
export function useDomNodeClientRectValue({
  nodeRef,
  key,
  defaultValue = 0,
  watchWindowResize = false,
  cachedValueIdentifier = undefined,
}: UseDomNodeClientRectValueProps) {
  const anId = useId();
  const aCacheKey = cachedValueIdentifier ?? anId;
  const cachedValue = windowWatcher.elementSizeCache[aCacheKey];
  const initialValue = cachedValue ? cachedValue : defaultValue;

  const nodeValueRef = useRef(initialValue);

  const [_, __] = useState({});
  const forceRender = () => __({});

  const hookLog = useMemo(() => {
    if (!ENABLE_LOGGING) {
      return () => undefined;
    }
    const hookId = [
      `useDomNodeClientRectValue`,
      `    for rectValue: ${key}`,
      `    with id: ${anId}`,
    ];
    const cacheIdFrag = `    with cachedValueIdentifier: ${cachedValueIdentifier}`;
    cachedValueIdentifier && hookId.push(cacheIdFrag);

    return hookLogFactory(hookId.join('\n'));
  }, [key, anId, cachedValueIdentifier]);

  useLayoutEffect(() => {
    ENABLE_LOGGING && hookLog(`mounted`);
    let cancelMeasure = false;
    const triggerMeasure$$ = new Subject();
    const scheduleMeasureNode = () => triggerMeasure$$.next(null);
    const measureNode = () => {
      nodeRef &&
        !cancelMeasure &&
        fastdom.measure(() => {
          if (nodeRef?.current && !cancelMeasure) {
            const _nextNodeValue = nodeRef?.current[key] ?? initialValue;
            if (_nextNodeValue !== nodeValueRef.current) {
              fastdom.mutate(() => {
                if (_nextNodeValue !== initialValue) {
                  if (aCacheKey) {
                    windowWatcher.elementSizeCache[aCacheKey] = _nextNodeValue;
                  }
                  ENABLE_LOGGING && hookLog(`updating value: `, _nextNodeValue);
                  !cancelMeasure && (nodeValueRef.current = _nextNodeValue);
                  !cancelMeasure && forceRender();
                }
              });
            }
          } else if (!cancelMeasure) {
            scheduleMeasureNode();
          }
        });
    };
    const outputSub = watchWindowResize
      ? windowWatcher.output$.subscribe(triggerMeasure$$)
      : undefined;
    const triggerSub = triggerMeasure$$
      .pipe(
        throttleTime(100, undefined, { leading: true, trailing: true }),
        tap(() => {
          measureNode();
        })
      )
      .subscribe();
    const initialCaptureSub = interval(1000)
      .pipe(
        take(3),
        tap(() => {
          nodeValueRef.current === initialValue && scheduleMeasureNode();
        })
      )
      .subscribe();

    return () => {
      outputSub?.unsubscribe();
      triggerSub?.unsubscribe();
      initialCaptureSub?.unsubscribe();
      cancelMeasure = true;
      ENABLE_LOGGING && hookLog(`unmounted`);
    };
  }, [
    key,
    nodeValueRef,
    watchWindowResize,
    defaultValue,
    hookLog,
    nodeRef,
    initialValue,
    aCacheKey,
  ]);

  if (
    windowWatcher.elementSizeCache[aCacheKey] &&
    windowWatcher.elementSizeCache[aCacheKey] !== nodeValueRef.current
  ) {
    nodeValueRef.current = windowWatcher.elementSizeCache[aCacheKey];
  }

  ENABLE_LOGGING &&
    hookLog(
      `returned: ${nodeValueRef.current}\n    cached: ${windowWatcher.elementSizeCache[aCacheKey]}`
    );
  return nodeValueRef.current;
}

type UseDomNodeWidthProps = {
  nodeRef?: MutableRefObject<HTMLDivElement | null>;
  defaultValue?: number;
  watchWindowResize?: boolean;
  cachedValueIdentifier?: string;
};
export function useDomNodeWidth({
  nodeRef,
  defaultValue,
  watchWindowResize,
  cachedValueIdentifier = undefined,
}: UseDomNodeWidthProps) {
  const width = useDomNodeClientRectValue({
    nodeRef,
    key: 'clientWidth',
    defaultValue,
    watchWindowResize,
    cachedValueIdentifier,
  });
  return width;
}

type UseDomNodeHeightProps = {
  nodeRef?: MutableRefObject<HTMLDivElement | null>;
  defaultValue?: number;
  watchWindowResize?: boolean;
  cachedValueIdentifier?: string;
};
export function useDomNodeHeight({
  nodeRef,
  defaultValue,
  watchWindowResize,
  cachedValueIdentifier = undefined,
}: UseDomNodeHeightProps) {
  const height = useDomNodeClientRectValue({
    nodeRef,
    key: 'clientHeight',
    defaultValue,
    watchWindowResize,
    cachedValueIdentifier,
  });
  return height;
}
