import { OnErrorFn } from '@formatjs/intl';
import { useCallback, useEffect, useState, useTransition } from 'react';
import { IntlProvider } from 'react-intl';
import { SettingsUsersLanguageEnum, useGQLUserSettings, useUserSettings } from '@beeriot/api-client';
import { getLanguage, LocaleMessages } from './Languages';
import { setDefaultOptions } from 'date-fns';

setDefaultOptions({ weekStartsOn: 1});

interface LocalizationProviderProps {
  children: React.ReactNode;
}

export function LocalizationProvider({ children }: LocalizationProviderProps) {
  // const { data: userSettings } = useUserSettings();
  const {data: gqlUser } = useGQLUserSettings()
  const userSettings = gqlUser?.getUser?.settingsInfo;
  const [messages, setMessages] = useState<LocaleMessages>();

  const onError: OnErrorFn = useCallback(
    (err) => {
      // All translations will be considered missing until messages are loaded
      if (!messages && err.code === 'MISSING_TRANSLATION') return;
      console.error(err);
    },
    [messages]
  );

  const [isTransitioning, startTransition] = useTransition();
  useEffect(() => {
    const loadMessages = async () => {
      const language = getLanguage(userSettings?.language as unknown as SettingsUsersLanguageEnum);
      const messages = await language.messages;
      startTransition(() => {setMessages(messages);});
      
    };
    loadMessages();
  }, [userSettings]);

  return (
    <IntlProvider
      locale={userSettings?.language ?? 'en'}
      messages={messages?.default}
      onError={onError}
    >
      {children}
    </IntlProvider>
  );
}
