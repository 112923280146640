import { useMemo } from 'react';
import { KPIGridItem } from '../../kpi-grid/KPIGrid';
import { SystemKpiHookProps } from '../../SystemKpiHookProps';
import { useShouldRenderKpi } from '../../useShouldRenderKpi';
import { KPIVerticalLabels } from '../../kpi-grid/KPIVerticalLabel';
import { formatUnit } from '../../../../../../utils';
import { FormattedMessage } from 'react-intl';
import { ThresholdValue } from '../../../../../../utils/threshold-color/thresholdValue';
import { useThresholdColor } from '../../../../../../utils/threshold-color/useThresholdColor';

export function useLastHourAverageFlowGridItem(props: SystemKpiHookProps) {
  const { system, gridArea, NA } = props;
  const shouldRender = useShouldRenderKpi(props);
  const thresholdColor = useThresholdColor(system?.id);

  return useMemo(() => {
    if (!shouldRender) {
      return undefined;
    }
    const labelColor = thresholdColor(
      ThresholdValue.ActualFlow,
      system?.liveKPI?.lastHourAverageFlow
    );
    return (
      <KPIGridItem gridArea={gridArea ?? 'averageFlow'}>
        <KPIVerticalLabels
          label={
            system?.liveKPI?.lastHourAverageFlow != null
              ? formatUnit(
                  Math.trunc(system?.liveKPI?.lastHourAverageFlow),
                  `hL/h`
                )
              : NA
          }
          labelBold
          subLabel={
            <FormattedMessage
              id="common-average-flow-label"
              defaultMessage="Last Hour Average Flow"
              description="Label for average flow over the last hour"
            />
          }
          labelColorKey={labelColor}
        />
      </KPIGridItem>
    );
  }, [
    shouldRender,
    thresholdColor,
    system?.liveKPI?.lastHourAverageFlow,
    gridArea,
    NA,
  ]);
}
