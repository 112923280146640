import { GQLCluster, useInfiniteListClustersQuery } from '../generated/gql';
import {
  defaultPagingParams,
  removeFalsey,
  getNextPageParamHandler,
} from '../utils';

export function useGQLClusters(
  systemId?: string | null,
  blockId?: string | null
): GQLCluster[] {
  const {
    data: clusters,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteListClustersQuery(
    {
      systemId,
      blockId,
      status: 'active',
      ...defaultPagingParams,
    },
    {
      enabled: !!systemId || !!blockId,
      refetchInterval: 15000,
      getNextPageParam: getNextPageParamHandler(
        (query) => query?.listClusters?.length
      ),
    }
  );

  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage();
  }

  const gqlClusters = removeFalsey<GQLCluster>(
    clusters?.pages.flatMap((x) => x.listClusters)
  );
  return gqlClusters.sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0));
}
