import { ListItem, ListItemProps, styled } from '@mui/material';

export const MobileNavListItem: React.ComponentType<ListItemProps> = styled(
  ListItem
)<ListItemProps>({
  margin: '0',
  padding: '0',
  color: 'white',
  alignItems: 'center',
  alignContent: 'start',
  width: '100%',
  '&:hover': {
    cursor: 'pointer',
  },
});
