import { useMemo } from 'react';

export function getCurrencySymbol(langCode: string, currency: string): string {
  return (0)
    .toLocaleString(langCode, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
}

export function useCurrencySymbol(langCode: string, currency: string): string {
  return useMemo(() => {
    return getCurrencySymbol(langCode, currency);
  }, [langCode, currency]);
}
