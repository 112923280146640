import { InfiniteData } from 'react-query';
import {
  GQLCompanySite,
  GQLGetCompanySiteQuery,
  GQLListSystemsQuery,
  GQLSystem,
} from '../../generated/gql';
import { AppQueryClient } from '../../utils';
import { alertStore } from '../alertStore';
import { systemStore } from '../system';
import { siteStore } from './siteStore';
import { Area } from '../../generated';
import { emitOnce } from '@ngneat/elf';

// export const setActiveSite = (activeSite?: GQLCompanySite) => {
//   systemStore.reset();
//   // siteStore.reset();
//   siteStore.update((s) => ({ ...s, activeSite: activeSite ?? null }));
//   alertStore.update((s) => ({ ...s, siteIdFilter: activeSite?.id }));
// };

export const setActiveSiteId = (activeSiteId?: string | null) => {
  const cachedSite: GQLGetCompanySiteQuery | null | undefined =
    AppQueryClient.getQueryData(
      ['getCompanySite', { siteId: activeSiteId }],
      {}
    );
  if (cachedSite?.getCompanySite) {
    setActiveSite(cachedSite.getCompanySite);
  }
};

export const setActiveSite = (activeSite?: GQLCompanySite | null) => {
  // emitOnce(() => {
    siteStore.update((state) => ({ ...state, activeSite: activeSite ?? null }));
    alertStore.update((s) => ({
      ...s,
      siteIdFilter: activeSite?.id ?? undefined,
    }));

    setTimeout(() => {
      updateAreasFromCache(activeSite ?? null);
      updateSystemsFromCache(activeSite ?? null);
    }, 200)
   
  // });
};

function updateAreasFromCache(activeSite: GQLCompanySite | null) {
  siteStore.update((state) => {
    // const first = state.gqlSystems?.[0];
    const cachedAreas: Area[] | null | undefined = AppQueryClient.getQueryData([
      'areas',
      activeSite?.id,
    ]);
    if (cachedAreas && cachedAreas !== state.areas) {
      return { ...state, areas: cachedAreas, activeSite };
    } else if (!activeSite) {
      return { ...state, areas: null, activeSite };
    }
    return state;
  });
}
function updateSystemsFromCache(activeSite: GQLCompanySite | null) {
  systemStore.update((state) => {
    
    if(!activeSite?.id) {
      return { ...state, gqlSystems: null, activeSystem: null };
    }
    const first = state.gqlSystems?.[0];
    if (first?.companySiteId === activeSite?.id) {
      return { ...state, activeSystem: null };
    } 
    const cachedSystems: InfiniteData<GQLListSystemsQuery> | null | undefined =
      AppQueryClient.getQueryData(
        [
          'listSystems.infinite',
          {
            companyId: activeSite?.companyId,
            companySiteId: activeSite?.id,
            limit: 100,
            skip: 0,
            status: 'active',
          },
        ],
        {}
      );
    if (cachedSystems?.pages?.[0]?.listSystems) {
      return {
        ...state,
        gqlSystems: cachedSystems?.pages?.[0]?.listSystems as GQLSystem[],
        activeSystem: null
      };
    }
    return { ...state, gqlSystems: null, activeSystem: null };
  });
}
