import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Button, Paper, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useObservable } from '@ngneat/use-observable';
import {
  activeCompany$,
  activeSite$,
  GQLBeerBrand,
  useDeleteBeerBrand,
  useIntlError,
  useUpdateBeerBrand,
} from '@beeriot/api-client';
import { Form, FormError } from '../../form';
import {
  SectionControl,
  SectionLabel,
  SectionTextAreaInput,
  SectionTextInput,
  SettingsFooterButtons,
  TitleHeader,
} from '../settings-form';
import { FormErrorContainer } from '../../auth/FormErrorContainer';
import { AppIcon } from '../../base';
import { ConfirmBrandDeleteDialog } from './ConfirmBrandDeleteDialog';

const StyledPaper = styled(Paper)({
  maxWidth: '40rem',
});

const StyledStack = styled(Stack)({
  justifyContent: 'space-between',
});

const StyledFormErrorContainer = styled(FormErrorContainer)({
  margin: '1rem',
});

const defaultValues: EditBeerTypesFormFields = {
  name: '',
  rawMaterial: '',
  degreesPlato: 0,
  abv: 0,
  directionFermented: '',
  beerType: '',
};

export interface EditBeerTypesFormFields {
  name: string;
  rawMaterial: string;
  degreesPlato: number;
  abv: number;
  directionFermented: string;
  beerType: string;
}

function mapToDefaultValues(
  item?: GQLBeerBrand | null
): EditBeerTypesFormFields {
  if (!item) return defaultValues;
  return {
    name: item.name ?? '',
    rawMaterial: item.rawMaterial ?? '',
    degreesPlato: item.plato ?? 0,
    abv: item.abv ?? 0,
    directionFermented: item.fermented ?? '',
    beerType: item.beerType ?? '',
  };
}

export interface EditBeerTypesProps {
  brand?: GQLBeerBrand;
}

export const EditBeerTypes = ({ brand }: EditBeerTypesProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const defaultFormValues = useMemo(
    () => ({ defaultValues: mapToDefaultValues(brand) }),
    [brand]
  );
  const [newBrandId, setNewBrandId] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const intlError = useIntlError();
  const [activeCompany] = useObservable(activeCompany$);
  const [activeSite] = useObservable(activeSite$);

  const editBeerTypeMutation = useUpdateBeerBrand(
    () => {
      setError('');
    },
    (err: string) => {
      setError(intlError(err));
    }
  );

  const deleteBeerTypeMutation = useDeleteBeerBrand(
    () => {
      navigate(`/companies/${activeCompany?.id}/settings/beer-brands`);
      setError('');
    },
    (err: string) => {
      setError(intlError(err));
    }
  );

  const degrees = '\u00b0';

  return (
    <>
      <StyledPaper>
        <TitleHeader>
          <StyledStack direction="row">
            <FormattedMessage
              id="settings-beer-brand-edit-header-title"
              defaultMessage="Edit Beer Brand"
              description="label for Edit Beer Brand header"
            />
            <Button
              color="error"
              startIcon={<AppIcon icon={['far', 'trash-alt']} />}
              onClick={() => setIsDeleteModalOpen(true)}
            >
              <FormattedMessage
                id="settings-beer-brand-header-delete-button"
                defaultMessage="Delete Beer Brand"
                description="Delete Beer Brand button"
              />
            </Button>
          </StyledStack>
        </TitleHeader>
        <Form<EditBeerTypesFormFields>
          onSubmit={(data) => {
            editBeerTypeMutation.mutate({
              id: brand?.id ?? '',
              companyId: brand?.companyId ?? activeCompany?.id ?? '',
              companySiteId: brand?.companySiteId ?? activeSite?.id ?? '',
              name: data.name,
              rawMaterial: data.rawMaterial,
              plato: data.degreesPlato,
              abv: data.abv,
              fermented: data.directionFermented,
              beerType: data.beerType,
            });
          }}
          formProps={defaultFormValues}
        >
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-beer-brand-name-label"
                defaultMessage="Name"
                description="label for name"
              />
            </SectionLabel>
            <SectionTextInput
              name="name"
              required
              placeholder={intl.formatMessage({
                id: 'settings-beer-brand-name-placeholder',
                defaultMessage: 'Enter Name',
                description: 'label for name placeholder',
              })}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-beer-brand-raw-material-label"
                defaultMessage="Raw Material"
                description="label for raw material"
              />
            </SectionLabel>
            <SectionTextAreaInput
              name="rawMaterial"
              minRows={6}
              required
              placeholder={intl.formatMessage({
                id: 'settings-beer-brand-raw-material-placeholder',
                defaultMessage: 'Enter Raw Material',
                description: 'label for raw material placeholder',
              })}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-beer-brand-degrees-plato-label"
                defaultMessage="{degrees} Plato"
                description="label for degrees plato"
                values={{ degrees: degrees }}
              />
            </SectionLabel>
            <SectionTextInput
              name="degreesPlato"
              type="number"
              validationOptions={{ valueAsNumber: true }}
              required
              placeholder={intl.formatMessage(
                {
                  id: 'settings-beer-brand-degrees-plato-placeholder',
                  defaultMessage: 'Enter {degrees} Plato',
                  description: 'label for degrees plato placeholder',
                },
                { degrees: degrees }
              )}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-beer-brand-abv-label"
                defaultMessage="ABV"
                description="label for abv"
              />
            </SectionLabel>
            <SectionTextInput
              name="abv"
              type="number"
              inputProps={{ step: '0.1' }}
              validationOptions={{ valueAsNumber: true }}
              endAdornment={'%'}
              required
              placeholder={intl.formatMessage({
                id: 'settings-beer-brand-abv-placeholder',
                defaultMessage: 'Enter ABV',
                description: 'label for abv placeholder',
              })}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-beer-brand-direction-fermented-label"
                defaultMessage="Fermented"
                description="label for direction fermented"
              />
            </SectionLabel>
            <SectionTextInput
              name="directionFermented"
              required
              placeholder={intl.formatMessage({
                id: 'settings-beer-brand-direction-fermented-placeholder',
                defaultMessage: 'Enter Fermented',
                description: 'label for direction fermented placeholder',
              })}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-beer-brand-beer-type-label"
                defaultMessage="Beer Type"
                description="label for beer type"
              />
            </SectionLabel>
            <SectionTextInput
              name="beerType"
              required
              placeholder={intl.formatMessage({
                id: 'settings-beer-brand-beer-type-placeholder',
                defaultMessage: 'Enter Beer Type',
                description: 'label for beer type placeholder',
              })}
            />
          </SectionControl>
          <StyledFormErrorContainer>
            <FormError error={error} color="info" />
          </StyledFormErrorContainer>
          <SettingsFooterButtons
            isLoading={editBeerTypeMutation?.isLoading}
            isSuccess={editBeerTypeMutation?.isSuccess}
            onConfirmOkSuccessDialog={() => {
              if (newBrandId) {
                navigate(
                  `/companies/${activeCompany?.id}/settings/beer-brands-details/edit-beer-brand/${newBrandId}`
                );
                setNewBrandId(undefined);
              }
              editBeerTypeMutation.reset();
            }}
            submitLabel={intl.formatMessage({
              id: 'settings-edit-beer-brand-submit-button',
              defaultMessage: 'Update Beer Brand',
              description: 'label for update beer brand submit button',
            })}
            successLabel={intl.formatMessage({
              id: 'settings-edit-beer-brand-success-message',
              defaultMessage: 'Successfully updated beer brand.',
              description: 'label for edit beer brand success message',
            })}
          />
        </Form>
      </StyledPaper>
      {brand && (
        <ConfirmBrandDeleteDialog
          open={isDeleteModalOpen}
          mutation={deleteBeerTypeMutation}
          brand={brand}
          cancel={() => setIsDeleteModalOpen(false)}
        />
      )}
    </>
  );
};
