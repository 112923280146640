import { ReactNode, useCallback, useMemo } from 'react';
import { useObservable } from '@ngneat/use-observable';
import {
  execSummarySelectedSiteGraph$,
  execSummaryStore,
  SiteGraphType,
} from '@beeriot/api-client';
import { GridItemStatus } from '../../types/GridItemStatus';
import { ExpandableGraphCard } from './ExpandableGraphCard';
import { SelectOption } from '../../form/SelectOption';

interface ExpandableSiteGraphCardProps {
  title: ReactNode;
  formOnSubmit?: (data: SelectOption) => void;
  selectOptions?: SelectOption[];
  defaultValue?: SelectOption;
  graphType: SiteGraphType;
  children: ReactNode;
}

export function ExpandableSiteGraphCard({
  title,
  formOnSubmit,
  selectOptions,
  defaultValue,
  graphType,
  children,
}: ExpandableSiteGraphCardProps) {
  const [selectedSiteGraph] = useObservable(execSummarySelectedSiteGraph$);

  const status = useMemo((): GridItemStatus => {
    if (selectedSiteGraph === null) return 'default';
    const status = graphType === selectedSiteGraph ? 'selected' : 'hidden';

    return status;
  }, [graphType, selectedSiteGraph]);

  const toggleExpand = useCallback(() => {
    const item = selectedSiteGraph === graphType ? null : graphType;
    execSummaryStore.update((state) => ({
      ...state,
      selectedSiteGraph: item,
    }));
  }, [graphType, selectedSiteGraph]);

  return (
    <ExpandableGraphCard
      status={status}
      title={title}
      formOnSubmit={formOnSubmit}
      selectOptions={selectOptions}
      defaultValue={defaultValue}
      toggleExpand={toggleExpand}
    >
      {children}
    </ExpandableGraphCard>
  );
}
