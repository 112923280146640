import { select } from '@ngneat/elf';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { SiteGroup } from '../../lib/interfaces/SiteGroup';
import { groupSitesByLetterOperator } from './siteStore.operators';
import { siteStore } from './siteStore';

export const activeSite$ = siteStore.pipe(
  select((state) => {
    return state.activeSite;
  })
);

export const siteSearch$ = siteStore.pipe(
  select((state) => {
    return state.siteSearch;
  })
);

export const activeAreaId$ = siteStore.pipe(
  select((state) => {
    return state.activeAreaId;
  })
);

export const sites$ = siteStore.pipe(
  select((state) => {
    return state.sites;
  })
);

export const unfilteredSitesCount$ = siteStore.pipe(
  select((state) => {
    return state.unfilteredSitesCount;
  })
);

export const filteredSitesCount$ = siteStore.pipe(
  select((state) => {
    return state.filteredSitesCount;
  })
);

export const sitesGroupBy$ = siteStore.pipe(
  select((state) => {
    return state.groupBy;
  })
);

export const sitesGroupFilter$ = siteStore.pipe(
  select((state) => {
    return state.group;
  })
);

export const groupedSites$: Observable<SiteGroup[] | null> = siteStore.pipe(
  select((state) => {
    return state.sites;
  }),
  groupSitesByLetterOperator()
);

export const activeSiteAreas$ = siteStore.pipe(
  select((state) => {
    console.log('activeSiteAreas', state.areas)
    return state.areas ?? null;
  }),
  map((areas) => areas 
    ? areas?.sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0))
    : null),
  // shareReplay({ refCount: true, bufferSize: 1 })
);
