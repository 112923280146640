import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import {
  InfiniteData,
  QueryErrorResetBoundary,
  UseMutationResult,
} from 'react-query';
import { IconLabel, LoadingPaper } from '../../base';
import {
  GQLListReportDefinitionsQuery,
  ReportDefinition,
  ReportDefinitionBase,
} from '@beeriot/api-client';

const SettingsLoadingPaper = styled(LoadingPaper)({
  height: '50rem',
  width: '40rem',
  margin: '0.5rem',
});

const AddEditAutomaticReportSettings = lazy(() =>
  import('./AddEditAutomaticReportSettingsQuery').then(
    ({ AddEditAutomaticReportSettingsQuery }) => ({
      default: AddEditAutomaticReportSettingsQuery,
    })
  )
);

export interface SuspenseAddEditAutomaticReportSettingsProps {
  addMutation?: UseMutationResult<
    ReportDefinition,
    unknown,
    ReportDefinitionBase,
    unknown
  >;
  editMutation?: UseMutationResult<
    ReportDefinition,
    unknown,
    {
      id: string;
      definitionBase: ReportDefinitionBase;
    },
    {
      previousState: InfiniteData<GQLListReportDefinitionsQuery> | undefined;
    }
  >;
  error?: React.ReactNode;
}

export function SuspenseAddEditAutomaticReportSettings({
  editMutation,
  addMutation,
  error,
}: SuspenseAddEditAutomaticReportSettingsProps) {
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <IconLabel
                label={
                  <FormattedMessage
                    id="settings-automatic-report-settings-error-label"
                    description="Error message for automatic report settings error"
                    defaultMessage="Automatic Report Settings Unavailable"
                  />
                }
              />
            );
          }}
        >
          <Suspense fallback={<SettingsLoadingPaper />}>
            <AddEditAutomaticReportSettings
              editMutation={editMutation}
              addMutation={addMutation}
              error={error}
            />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
