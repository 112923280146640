import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { SystemKpiHookProps } from '../../SystemKpiHookProps';

import { getTimeSince } from '../../../../../../utils/getTimeSinceStart';
import { KPIVerticalLabels } from '../../kpi-grid/KPIVerticalLabel';
import { KPIGridItem } from '../../kpi-grid/KPIGrid';
import { useShouldRenderKpi } from '../../useShouldRenderKpi';

export function useTimeSinceStartGridItem(props: SystemKpiHookProps) {
  const { system, NA, boldLabelTitles, gridArea } = props;
  const shouldRender = useShouldRenderKpi(props);

  return useMemo(() => {
    if (!shouldRender) {
      return undefined;
    }
    const timeSinceStart = getTimeSince(
      system?.liveStatus?.productionStartTime
    );
    return (
      <KPIGridItem gridArea={gridArea ?? 'timeSinceStart'}>
        <KPIVerticalLabels
          label={timeSinceStart ?? NA}
          labelBold={boldLabelTitles}
          $flexWrap={'nowrap'}
          subLabel={
            <FormattedMessage
              id="site-line-detail-time-since-start"
              defaultMessage="Time Since Start"
              description="sub label for time since start"
            />
          }
        />
      </KPIGridItem>
    );
  }, [
    shouldRender,
    system?.liveStatus?.productionStartTime,
    gridArea,
    NA,
    boldLabelTitles,
  ]);
}
