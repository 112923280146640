import { Property } from 'csstype';
import { Drawer, DrawerProps, styled } from '@mui/material';
import { transientOptions } from '../../utils/transientOptions';

export const StyledMiniDrawer = styled(
  Drawer,
  transientOptions
)<DrawerProps & { $width?: Property.Width }>(({ theme, open, $width }) => {
  return {
    height: '100%',
    '& .MuiDrawer-paper': {
      position: 'static',
      width: open ? $width ?? '15rem' : '3rem',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: open
          ? theme.transitions.duration.enteringScreen
          : theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: theme.palette.componentBase.main,
    },
  };
});
