import { Box, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ColorKey } from '@beeriot/api-client';
import { AppTypography } from '../base/AppTypography';
import { BadgeListContainer, LabeledBadge } from '../base';

const StyledBox = styled(Box)({
  height: '2rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const LegendLabel = styled(AppTypography)({
  marginLeft: '0px',
  marginRight: '3rem',
  fontWeight: 'bold',
});

// TODO: Replace with API model
export interface LineType {
  name: string;
  abbreviation: string;
}

// TODO: Sync line type colors across state
export const lineTypeColorMap = new Map<string, ColorKey>([
  ['PR', 'primary'],
  ['KF', 'highlight2'],
  ['CBS', 'highlight7'],
  ['CFS', 'highlight4'],
]);

export interface LineLegendProps {
  lineTypes: LineType[];
}

export function LineLegend({ lineTypes }: LineLegendProps) {
  return (
    <StyledBox>
      <LegendLabel>
        <FormattedMessage
          id="common-legend-label"
          defaultMessage="Legend"
          description="Label for data legend"
        />
      </LegendLabel>
      <BadgeListContainer gap="2rem">
        {lineTypes.map((lineType) => {
          return (
            <LabeledBadge
              key={lineType.abbreviation}
              label={lineType.name}
              badgeContent={lineType.abbreviation}
              badgeColorKey={lineTypeColorMap.get(lineType.abbreviation)}
            />
          );
        })}
      </BadgeListContainer>
    </StyledBox>
  );
}
