import { ColorKey } from '@beeriot/api-client';

export type SystemType = 'profi' | 'keraflux' | 'cbs' | 'cfs';

export function isSystemType(str: string): str is SystemType {
  return (
    str === 'profi' || str === 'keraflux' || str === 'cbs' || str === 'cfs'
  );
}

export const systemTypeAbbreviations: Record<SystemType, string> = {
  profi: 'PR',
  keraflux: 'KR',
  cbs: 'CBS',
  cfs: 'CFS',
};

export function getSystemAbbrv(str?: string | null): string {
  return !!str && isSystemType(str) ? systemTypeAbbreviations[str] : '?';
}

export const systemTypeColorKeys: Record<string, ColorKey> = {
  profi: 'highlight1',
  keraflux: 'highlight2',
  cbs: 'highlight7',
  cfs: 'highlight4',
};

export function getSystemColor(str?: string | null): ColorKey {
  return !!str && isSystemType(str) ? systemTypeColorKeys[str] : 'secondary';
}
