import { FormattedMessage } from 'react-intl';
import { UseMutationResult } from 'react-query';
import { useFormContext } from 'react-hook-form';
import { Button } from '@mui/material';
import { IotProvisioningUrl } from '@beeriot/api-client';
import { CertificateProvisioningFormFields } from './CertificateProvisioningFormFields';

interface CertificateDownloadButtonProps {
  getCertificateMutation: UseMutationResult<
    IotProvisioningUrl,
    unknown,
    {
      companyId?: string | undefined;
      iotId?: string | undefined;
    },
    unknown
  >;
}

export function CertificateDownloadButton({
  getCertificateMutation,
}: CertificateDownloadButtonProps) {
  const { reset } = useFormContext<CertificateProvisioningFormFields>();

  const downloadCertificate = () => {
    const url = getCertificateMutation.data?.url;
    const filename = getCertificateMutation.data?.filename;
    if (!url || !filename) return;

    fetch(url, {
      method: 'GET',
      headers: {
        ContentType: 'application/zip',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        link.click();
        link.remove();
      });

    getCertificateMutation.reset();
    reset();
  };

  return (
    <Button
      variant="contained"
      onClick={downloadCertificate}
      sx={{ margin: '1rem' }}
    >
      <FormattedMessage
        id="certificate-download-button"
        defaultMessage="Download Certificate"
        description="Button for downloading a certificate"
      />
    </Button>
  );
}
