import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AlertType } from '@beeriot/api-client';

export function useIntlAlertType(): (alertType?: AlertType | null) => string {
  const intl = useIntl();
  const machineAlarms = intl.formatMessage({
    id: 'alert-type-machine-alarms',
    defaultMessage: 'Machine Alarms',
    description: 'Label for machine alarms alert type',
  });
  const progressAlerts = intl.formatMessage({
    id: 'alert-type-progress-alerts',
    defaultMessage: 'Progress Alerts',
    description: 'Label for progress alerts alert type',
  });
  const maintenanceWarnings = intl.formatMessage({
    id: 'alert-type-maintenance-warnings',
    defaultMessage: 'Maintenance Warnings',
    description: 'Label for maintenance warnings alert type',
  });
  const systemWarnings = intl.formatMessage({
    id: 'alert-type-system-warnings',
    defaultMessage: 'System Warnings',
    description: 'Label for system warnings alert type',
  });
  const NA = intl.formatMessage({
    id: 'common-not-applicable',
    defaultMessage: 'N/A',
    description: "Label for when there isn't a value to display",
  });

  return useCallback(
    (alertType?: AlertType | null) => {
      if (!alertType) return NA;
      const alertFilterIntlRecord: Record<AlertType, string> = {
        machineAlarms: machineAlarms,
        progressAlerts: progressAlerts,
        maintenanceWarnings: maintenanceWarnings,
        systemWarnings: systemWarnings,
      };

      return alertFilterIntlRecord[alertType];
    },
    [NA, machineAlarms, maintenanceWarnings, progressAlerts, systemWarnings]
  );
}
