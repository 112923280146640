import { Suspense } from 'react';
import { useTheme } from '@mui/material';
import { GQLSystem, useSystemSettings } from '@beeriot/api-client';
import { useResponsiveSizes } from '../utils/responsive-design/useResponsiveSizes';
import { getFlowGaugeInfo } from '../utils/getFlowGaugeInfo';
import { getResponsiveDataForGauge } from './RadialGauge';
import { LoadingBox } from './LoadingBox';
import { FlowGauge } from './FlowGauge';

interface SystemFlowGaugeProps {
  system: GQLSystem;
  isCard?: boolean;
}

export function SuspenseSystemFlowGauge({
  system,
  isCard,
}: SystemFlowGaugeProps) {
  return (
    <Suspense fallback={<LoadingBox sx={{ width: '12rem', height: '7rem' }} />}>
      <SystemFlowGauge system={system} isCard={isCard} />
    </Suspense>
  );
}

function SystemFlowGauge({ system, isCard }: SystemFlowGaugeProps) {
  const theme = useTheme();
  const { isMobileSize, isTabletSize } = useResponsiveSizes();
  const responsiveGaugeInfo = getResponsiveDataForGauge(
    isMobileSize,
    isTabletSize
  );
  
  // const { data: systemSettings } = useSystemSettings(system?.id);
  const systemSettings = system?.systemSettingsInfo ;
  const flowGaugeInfo = getFlowGaugeInfo(systemSettings);

  return (
    <FlowGauge
      flow={{
        amount: system.liveKPI?.actualFlow
          ? Math.trunc(system.liveKPI?.actualFlow)
          : null,
        unit: 'hL/h',
        max: flowGaugeInfo.maxFlow,
      }}
      radius={responsiveGaugeInfo.radius}
      gaugeMap={flowGaugeInfo.gaugeMap}
      warningLimit={flowGaugeInfo.warningLimit}
      successLimit={flowGaugeInfo.successLimit}
      labelVariant={responsiveGaugeInfo.labelVariant}
      subLabelVariant={isMobileSize || isTabletSize ? 'caption' : 'subtitle2'}
      backgroundColor={
        isCard ? theme.palette.white.main : theme.palette.componentBase.main
      }
    />
  );
}
