import { createStore, select, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { DrawerType } from './enums';

export interface UiStoreProps {
  drawerStates: Record<DrawerType, boolean>;
}

export const uiStore = createStore(
  { name: 'ui' },
  withProps<UiStoreProps>({
    drawerStates: {
      Alerts: true,
      SiteDetails: true,
      LineDetails: true,
      BlockDetails: true,
      ExecSummarySiteDetails: true,
      ExecSummaryLineDetails: true,
      GroupTypeList: true,
    },
  })
);

persistState(uiStore, {
  key: 'ui',
  storage: sessionStorageStrategy,
});

export const drawerStates$ = uiStore.pipe(
  select((state) => {
    return state.drawerStates;
  })
);
