import {
  GQLCompanySite,
  CompanySite,
  CompanySiteBase,
  toCompanySiteBase,
} from '@beeriot/api-client';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  styled,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { UseMutationResult } from 'react-query';
import { AppTypography, LoadingButton } from '../../base';

const StyledDialogContent = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.base.main,
}));

const WarningContainer = styled(Box)({
  marginTop: '1rem',
});

const DividerContainer = styled(Box)({
  padding: '0',
});

const StyledDivider = styled(Divider)({
  borderBottomWidth: '2px',
});

export interface ConfirmSiteDeleteDialogProps {
  mutation: UseMutationResult<CompanySite, unknown, CompanySiteBase, unknown>;
  site: GQLCompanySite;
  open: boolean;
  confirm: (company: CompanySiteBase) => void;
  cancel: () => void;
}

export function ConfirmSiteDeleteDialog({
  mutation,
  site,
  open,
  confirm,
  cancel,
}: ConfirmSiteDeleteDialogProps) {
  const intl = useIntl();

  const warningMessage = intl.formatMessage({
    id: 'common-delete-warning-message',
    description: 'Warning to let user know the effect deleting this will have',
    defaultMessage: 'Warning, this action cannot be undone.',
  });

  const confirmLabel = intl.formatMessage({
    id: 'common-remove-button',
    description: 'Label for confirmation button',
    defaultMessage: 'Yes, Remove',
  });

  const cancelLabel = intl.formatMessage({
    id: 'common-cancel-button',
    description: 'Label for cancel button',
    defaultMessage: 'Cancel',
  });

  return (
    <Dialog open={open}>
      <StyledDialogTitle>
        <FormattedMessage
          id="edit-sites-delete-site-dialog-title"
          description="Title for site deletion"
          defaultMessage="Remove Site"
        />
      </StyledDialogTitle>
      <DividerContainer>
        <StyledDivider />
      </DividerContainer>
      <StyledDialogContent>
        <Box>
          <AppTypography>
            <FormattedMessage
              id="edit-sites-delete-site-dialog-content"
              description="Content text for site deletion"
              defaultMessage="Are you sure you want to remove the site {siteName}?"
              values={{ siteName: `'${site?.name}'` ?? '' }}
            />
          </AppTypography>
        </Box>
        <WarningContainer>
          <AppTypography $colorKey="warning">{warningMessage}</AppTypography>
        </WarningContainer>
        {mutation.isError && (
          <WarningContainer>
            <AppTypography $colorKey="error">
              {mutation.error as string}
            </AppTypography>
          </WarningContainer>
        )}
      </StyledDialogContent>
      <DialogActions>
        <Button color="shade" variant="contained" onClick={cancel}>
          {cancelLabel}
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          onClick={() => confirm(toCompanySiteBase(site))}
          isLoading={mutation.isLoading}
        >
          {confirmLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
