import { DefaultRouteNavigation } from '../../navigation/main-tabs/DefaultRouteNavigation';
import { PermissionKey } from './PermissionKey';
import { useHasPermission } from './useHasPermission';

interface PermissionViewProps {
  permissionKey: PermissionKey;
  redirect?: boolean;
  children: JSX.Element;
}

export function PermissionView({
  permissionKey,
  redirect,
  children,
}: PermissionViewProps) {
  const hasPermission = useHasPermission();
  return hasPermission(permissionKey) ? (
    children
  ) : redirect ? (
    <DefaultRouteNavigation />
  ) : null;
}
