import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  styled,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { AppTypography, LoadingButton } from '../../base';

const StyledDialogContent = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const DividerContainer = styled(Box)({
  padding: '0px 2.5rem',
});

const StyledDivider = styled(Divider)({
  borderBottomWidth: '2px',
});

export interface ConfirmSiteDeleteDialogProps {
  username?: string | null;
  open: boolean;
  confirm: () => void;
  cancel: () => void;
}

export function ConfirmRevokeDialog({
  username,
  open,
  confirm,
  cancel,
}: ConfirmSiteDeleteDialogProps) {
  const intl = useIntl();

  const confirmLabel = intl.formatMessage({
    id: 'revoke-access-button',
    description: 'Label for revoke confirmation button',
    defaultMessage: 'Yes, Revoke',
  });
  const cancelLabel = intl.formatMessage({
    id: 'common-cancel-button',
    description: 'Label for cancel button',
    defaultMessage: 'Cancel',
  });

  return (
    <Dialog open={open}>
      <DialogTitle>
        <FormattedMessage
          id="users-revoke-access-dialog-title"
          description="Title for revoking user access"
          defaultMessage="Revoke Access"
        />
      </DialogTitle>
      <DividerContainer>
        <StyledDivider />
      </DividerContainer>
      <StyledDialogContent>
        <Box>
          <AppTypography>
            <FormattedMessage
              id="users-revoke-access-dialog-content"
              description="Content text for revoke access"
              defaultMessage="Are you sure you want to revoke access for {username}?"
              values={{ username: username ?? '-' }}
            />
          </AppTypography>
        </Box>
      </StyledDialogContent>
      <DialogActions>
        <Button color="shade" variant="contained" onClick={cancel}>
          {cancelLabel}
        </Button>
        <LoadingButton color="error" variant="contained" onClick={confirm}>
          {confirmLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
