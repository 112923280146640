import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setupUser, useIntlError } from '@beeriot/api-client';
import { useMutation } from 'react-query';
import {
  LocationState,
  ResetPasswordForm,
  ResetPasswordFormFields,
} from '../../auth';
import { RootAuthPageStack } from './RootAuthPageStack';

export function UserSetupPage() {
  const [error, setError] = useState<string | undefined>();
  const navigate = useNavigate();
  const intlError = useIntlError();
  const location = useLocation();
  const locationState = location.state as LocationState;

  const mutation = useMutation((data: ResetPasswordFormFields) => {
    return setupUser(data.password).then(
      () => {
        setError(''); // Clear error on subsequent submission
        navigate(locationState?.path || '/');
      },
      (err: string) => {
        setError(intlError(err));
      }
    );
  });

  return (
    <RootAuthPageStack>
      <ResetPasswordForm userSetupMutation={mutation} error={error} />
    </RootAuthPageStack>
  );
}
