import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Stack, Grid, styled } from '@mui/material';
import {
  legalNoticesURL,
  privacyActURL,
  privacyPolicyURL,
  termsAndConditionsURL,
  transientOptions,
  useResponsiveSizes,
  youtubeSocialsURL,
} from '../../utils';
import { AppIcon } from '../AppIcon';

const FooterContainerStack = styled(
  Stack,
  transientOptions
)<{ $sticky: boolean; $noTopMargin?: boolean }>(
  ({ $sticky, $noTopMargin, theme }) => ({
    marginTop: $noTopMargin ? 0 : 'auto',
    position: $sticky ? 'sticky' : 'static',
    bottom: 0,
    width: '100%',
    zIndex: 2,
  })
);

const StyledStack = styled(
  Stack,
  transientOptions
)<{ $isMobile: boolean }>(({ $isMobile, theme }) => ({
  flexDirection: $isMobile ? 'column' : 'row',
  gap: $isMobile ? '1rem' : '2rem',
  boxShadow: theme.shadows[2],
  backgroundColor: theme.palette.primary.main,
  padding: '0.75rem 1rem',
  width: '100%',
}));

const StyledGridItem = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
});

StyledGridItem.defaultProps = {
  item: true,
  xs: 12,
  sm: 4,
  md: 'auto',
};

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.white.main,
  textDecoration: 'none',
}));

interface GlobalFooterProps {
  topFooterComponent?: ReactNode;
}

export function GlobalFooter({ topFooterComponent }: GlobalFooterProps) {
  const { isMobileSize } = useResponsiveSizes();
  return (
    <>
      {isMobileSize && (
        <FooterContainerStack direction="column" $sticky={true}>
          {topFooterComponent}
        </FooterContainerStack>
      )}
      <FooterContainerStack
        direction="column"
        $sticky={!isMobileSize}
        $noTopMargin={isMobileSize && !!topFooterComponent}
      >
        {!isMobileSize && topFooterComponent}
        <StyledStack $isMobile={isMobileSize}>
          <StyledLink href={youtubeSocialsURL} target="_blank" rel="noopener">
            <AppIcon size="2x" icon={['fab', 'youtube']} />
          </StyledLink>
          <Grid container rowSpacing={2} columnSpacing={isMobileSize ? 2 : 3}>
            <StyledGridItem item>
              <StyledLink
                href={privacyPolicyURL}
                target="_blank"
                rel="noopener"
              >
                <FormattedMessage
                  id="global-footer-privacy-policy-StyledLink"
                  description="StyledLink to privacy policy"
                  defaultMessage="Privacy Policy"
                />
              </StyledLink>
            </StyledGridItem>
            <StyledGridItem item>
              <StyledLink
                href={termsAndConditionsURL}
                target="_blank"
                rel="noopener"
              >
                <FormattedMessage
                  id="global-footer-terms-and-conditions"
                  description="StyledLink to terms and conditions"
                  defaultMessage="Terms and Conditions"
                />
              </StyledLink>
            </StyledGridItem>
            <StyledGridItem item>
              <StyledLink href={legalNoticesURL} target="_blank" rel="noopener">
                <FormattedMessage
                  id="global-footer-legal-notices-impressum"
                  description="StyledLink to legal notices / impressum"
                  defaultMessage="Legal Notices / Impressum"
                />
              </StyledLink>
            </StyledGridItem>
            <StyledGridItem item xs={12} sm={5}>
              <StyledLink href={privacyActURL} target="_blank" rel="noopener">
                <FormattedMessage
                  id="global-footer-california-do-not-sell"
                  description="StyledLink to california do not sell my data"
                  defaultMessage="California: Do Not Sell My Data"
                />
              </StyledLink>
            </StyledGridItem>
          </Grid>
        </StyledStack>
      </FooterContainerStack>
    </>
  );
}
