import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { CalendarMode } from '@beeriot/api-client';

export function useIntlCalendarModeSubLabel(): (
  calendarMode?: CalendarMode
) => string {
  const intl = useIntl();
  const forDayLabel = intl.formatMessage({
    id: 'common-for-day-label',
    defaultMessage: 'For Day',
    description: 'Label to indicate a value is for a day',
  });
  const forWeekLabel = intl.formatMessage({
    id: 'common-for-week-label',
    defaultMessage: 'For Week',
    description: 'Label to indicate a value is for a week',
  });
  const forMonthLabel = intl.formatMessage({
    id: 'common-for-month-label',
    defaultMessage: 'For Month',
    description: 'Label to indicate a value is for a month',
  });
  const forYearLabel = intl.formatMessage({
    id: 'common-for-year-label',
    defaultMessage: 'For Year',
    description: 'Label to indicate a value is for a year',
  });
  const NA = intl.formatMessage({
    id: 'common-not-applicable',
    defaultMessage: 'N/A',
    description: "Label for when there isn't a value to display",
  });

  return useCallback(
    (calendarMode?: CalendarMode) => {
      if (!calendarMode) return NA;
      const calendarModeIntlRecord: Record<CalendarMode, string> = {
        [CalendarMode.Day]: forDayLabel,
        [CalendarMode.Week]: forWeekLabel,
        [CalendarMode.Month]: forMonthLabel,
        [CalendarMode.Year]: forYearLabel,
      };

      return calendarModeIntlRecord[calendarMode];
    },
    [NA, forDayLabel, forMonthLabel, forWeekLabel, forYearLabel]
  );
}
