import isEmail from 'sane-email-validation';
import { FormTagInput, FormTagInputProps } from './FormTagInput';

export const FormTagEmailField = (props: FormTagInputProps) => {
  return (
    <FormTagInput
      {...props}
      placeholder={props.placeholder}
      tagItemValidator={(email: string) => isEmail(email)}
      validationOptions={
        props.validationOptions ?? {
          required: true,
          validate: {
            required: (values) => {
              if (props.required && values.length === 0) {
                return false; // required
              }
              return true;
            },
            allValidEmails: (values) => {
              const allValuesAreEmails = values.every((email: string) =>
                isEmail(email)
              );

              // Return boolean to indicate validity
              return allValuesAreEmails;
            },
          },
        }
      }
    />
  );
};
