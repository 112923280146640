import { FormattedMessage } from 'react-intl';
import { differenceInDays } from 'date-fns';

interface FormattedDaysLeftProps {
  startDate: Date;
  endDate: Date;
}

export function FormattedDaysLeft({
  startDate,
  endDate,
}: FormattedDaysLeftProps) {
  const days = differenceInDays(endDate, startDate);

  return (
    <FormattedMessage
      id="common-days-count"
      defaultMessage="{daysCount} Days"
      description="Label for days until"
      values={{
        daysCount: days,
      }}
    />
  );
}
