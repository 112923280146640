import { styled, Drawer, DrawerProps } from '@mui/material';
import { transientOptions } from '../../utils';

export const MobileDrawer = styled<
  (
    props: DrawerProps & {
      $toolbarHeight: number;
    }
  ) => JSX.Element
>(
  Drawer,
  transientOptions
)(({ theme, $toolbarHeight }) => ({
  '&.MuiDrawer-root': {
    top: `${$toolbarHeight}px;`,
  },
  '& .MuiBackdrop-root': {
    top: `${$toolbarHeight}px;`,
  },
  '& .MuiPaper-root': {
    // top: '4rem',
    top: `${$toolbarHeight}px;`,
    height: `calc(100% - ${$toolbarHeight}px);`,
    backgroundColor: theme.palette.primary.main,
  },
}));
