import { combineLatest, from, of } from 'rxjs';
import {
  concatMap,
  groupBy,
  map,
  mergeMap,
  pluck,
  toArray,
} from 'rxjs/operators';
import { GQLCompanySite } from '../../../generated/gql';

export function groupSitesByLetter(data: GQLCompanySite[]) {
  const sitesObs$ = from(data).pipe(
    map((site) => ({
      site,
      firstLetter: site.name?.charAt(0)?.toLocaleUpperCase() ?? '',
    })),
    toArray(),
    concatMap((sites) =>
      from(
        sites.sort(({ firstLetter: a }, { firstLetter: b }) =>
          a.localeCompare(b)
        )
      )
    ),

    groupBy(({ firstLetter }) => firstLetter),
    mergeMap((group) =>
      combineLatest({
        name: of(group.key),
        sites: group.pipe(pluck('site'), toArray()),
      })
    ),
    toArray()
  );

  const groupedSites = sitesObs$.syncLastResult();
  return groupedSites;
}
