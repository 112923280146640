import { useCallback, useState } from 'react';
import { Box, styled, Grid, List, ListItem } from '@mui/material';
import {
  Company,
  CompanyGroup,
  GQLCompany,
  useUpdateCompany,
  useDeleteCompany,
} from '@beeriot/api-client';
import { EditCompanyCard } from './EditCompanyCard';
import { ConfirmCompanyDeleteDialog } from './ConfirmCompanyDeleteDialog';
import { EditCompanyDialog } from './EditCompanyDialog';
import { CompanyCardListSectionHeader } from '../company-card-list';

const StyledGroupContainer = styled(Box)({
  margin: '0',
  height: 'fit-content',
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'space-evenly',
});

export interface EditCompanyCardListProps {
  companyGroups: CompanyGroup[];
}

export function EditCompanyCardList({
  companyGroups,
}: EditCompanyCardListProps) {
  const [activeCompany, setActiveCompany] = useState<GQLCompany | undefined>(
    undefined
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const updateCompany = useUpdateCompany({
    onSuccess: (data: Company | undefined) => {
      setIsEditModalOpen(false);
    },
    onError: (err: string) => {
      console.log('updateCompany: onError: ', err);
    },
  });
  const deleteCompany = useDeleteCompany(activeCompany?.id ?? '', {
    onSuccess: (_data: Company | undefined) => {
      setIsDeleteModalOpen(false);
    },
    onError: (err: string) => {
      console.log('deleteCompany: onError: ', err);
    },
  });

  const handleDeleteCompany = useCallback(
    (company: GQLCompany) => {
      deleteCompany.mutate(company);
    },
    [deleteCompany]
  );

  const handleUpdateCompany = useCallback(
    (company: Company) => {
      updateCompany.mutate(company);
    },
    [updateCompany]
  );

  return (
    <List>
      {companyGroups.map((companyGroup) => {
        return (
          <ListItem key={companyGroup.letter}>
            <StyledGroupContainer>
              <CompanyCardListSectionHeader
                companyCount={companyGroup.companies.length}
                companyLetter={companyGroup.letter}
              />
              <Grid container spacing={3} columns={2}>
                {companyGroup.companies.map((company) => {
                  return (
                    <Grid key={company.id} item>
                      <EditCompanyCard
                        company={company}
                        setIsDeleteModalOpen={() => {
                          setIsDeleteModalOpen(true);
                          setActiveCompany(company);
                        }}
                        setIsEditModalOpen={() => {
                          setIsEditModalOpen(true);
                          setActiveCompany(company);
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </StyledGroupContainer>
          </ListItem>
        );
      })}
      {activeCompany && (
        <ConfirmCompanyDeleteDialog
          mutation={deleteCompany}
          company={activeCompany}
          open={isDeleteModalOpen}
          confirm={handleDeleteCompany}
          cancel={() => setIsDeleteModalOpen(false)}
        />
      )}
      {activeCompany && (
        <EditCompanyDialog
          mutation={updateCompany}
          company={activeCompany}
          open={isEditModalOpen}
          confirm={handleUpdateCompany}
          cancel={() => setIsEditModalOpen(false)}
        />
      )}
    </List>
  );
}
