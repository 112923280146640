import { useIntl } from 'react-intl';
import { Box, styled } from '@mui/material';
import { ColorKey } from '@beeriot/api-client';
import { VerticalLabels } from './VerticalLabels';
import { AppCircularProgress } from './AppCircularProgress';

const BoxContainer = styled(Box)({
  display: 'grid',
  gridTemplate: 'container',
  placeItems: 'center',
  placeContent: 'center',
  overflow: 'visible',
});

const CenterCircularProgress = styled(AppCircularProgress)({
  gridArea: 'container',
  maxWidth: '100%',
});

const CenterBox = styled(Box)`
  grid-area: container;
  max-width: 100%;
  & > * {
    text-align: center;
    justify-content: center;
  }
`;

const PercentSign = styled('span')({
  fontSize: '.85rem',
});

const StyledVerticalLabels = styled(VerticalLabels)({
  display: 'flex',
});

export interface CircularProgressLabelProps {
  value?: number | null;
  colorKey?: ColorKey;
  size?: string;
}

export function CircularProgressLabel({
  value,
  colorKey,
  size = '7em',
}: CircularProgressLabelProps) {
  const intl = useIntl();
  const NA = intl.formatMessage({
    id: 'common-not-applicable',
    defaultMessage: 'N/A',
    description: "Label for when there isn't a value to display",
  });
  const complete = intl.formatMessage({
    id: 'common-complete',
    defaultMessage: 'Complete',
    description: 'Label for completion status',
  });

  return (
    <BoxContainer>
      <CenterCircularProgress
        variant="determinate"
        value={100}
        $colorKey="componentShade"
        thickness={5}
        size={size}
      />
      <CenterCircularProgress
        variant="determinate"
        value={value != null ? value : undefined}
        $colorKey={colorKey}
        thickness={5}
        size={size}
      />
      <CenterBox>
        <StyledVerticalLabels
          label={
            <>
              {value != null ? value : NA}
              {value != null && <PercentSign>%</PercentSign>}
            </>
          }
          labelColorKey={colorKey}
          labelVariant="h5"
          subLabel={value != null ? complete : undefined}
          justifyContent="center"
        />
      </CenterBox>
    </BoxContainer>
  );
}
