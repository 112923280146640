import React from 'react';
import { Dialog, DialogProps, useMediaQuery, useTheme } from '@mui/material';
import { EmbeddedPdf } from './EmbeddedPdf';

interface Props extends Pick<DialogProps, 'onClose'> {
  content?: { url: string };
  open?: boolean;
}

export const PdfDialog: React.FC<Props> = React.memo(
  ({ content, open, ...props }) => {
    const theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
      <Dialog
        open={open === undefined ? true : open}
        {...props}
        fullScreen={fullscreen}
        maxWidth="lg"
        fullWidth
      >
        <EmbeddedPdf url={content && content.url} />
      </Dialog>
    );
  }
);
