import { PropsWithChildren, ReactNode, useMemo } from 'react';

import { CardContent, styled } from '@mui/material';
import { ColorKey, GQLSystem, SettingsSystems } from '@beeriot/api-client';
import { AppCard } from '../../../base/AppCard';
import { useResponsiveSizes } from '../../../utils/responsive-design/useResponsiveSizes';

import { useSystemKpiGridContent } from './grids/system-cards/useSystemKpiGridContent';
import { useIntl } from 'react-intl';
import { SystemKpiGridAreaTemplate } from './grids/SystemKpiHookProps';
import { normalizeGridTemplate } from './grids/useSystemKpiHookDefaultProps';
import { useSystemCardHeaderGridContent } from './grids/system-cards/useSystemCardHeaderGridContent';
import { useProcessKpiGridContent } from './grids/process-cards/useProcessKpiGridContent';
import { entityDataAttributes } from '../../../utils/entityDataAttributes';
import { KPIGrid } from './grids/kpi-grid/KPIGrid';

const StyledAppCard = styled(AppCard)({
  display: 'flex',
  flex: 1,
  overflow: 'visible',
});
const StyledAppCardContent = styled(CardContent)({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  '&:last-child': {
    paddingBottom: '1rem',
  },
});

export type SystemCardBaseProps = PropsWithChildren<{
  system?: GQLSystem;
  // general
  /** override the card title (defaults to system name) */
  cardTitle?: string;
  boldLabelTitles?: boolean;
  noStripe?: boolean;
  stripeColor?: ColorKey;
  headerContent?: ReactNode;
  editBrand?: boolean;
  // grid
  gridTemplateRows?: string;
  gridTemplateColumns?: string;
  gridTemplateAreas?: SystemKpiGridAreaTemplate;
}>;

export const SystemCardBase = ({
  children,
  system,
  // general
  cardTitle,
  boldLabelTitles,
  noStripe,
  stripeColor: cardColorKey,
  headerContent,
  editBrand,
  // grid
  gridTemplateRows,
  gridTemplateColumns,
  gridTemplateAreas = [],
}: SystemCardBaseProps) => {
  const intl = useIntl();
  const { isMobileSize, isSmallTabletSize, isTabletSize, isDesktopSize } =
    useResponsiveSizes();

  const {
    gridAreaTemplateString,
    gridTemplateRowsString,
    gridTemplateColumnsString,
  } = useMemo(() => {
    const areas = gridTemplateAreas ?? [];
    const first = areas?.[0] ?? [];
    return {
      gridAreaTemplateString: normalizeGridTemplate(areas),
      gridTemplateRowsString:
        areas && areas.length > 0 ? `repeat(${areas.length}, fr1)` : undefined,
      gridTemplateColumnsString:
        first?.length > 0 ? `repeat(${first.length}, fr1)` : undefined,
    };
  }, [gridTemplateAreas]);

  const gridHookProps = useMemo(() => {
    return {
      system,
      intl,
      gridTemplateRows: gridTemplateRowsString,
      gridTemplateColumns: gridTemplateColumnsString,
      gridTemplateAreas: gridAreaTemplateString,
      //
      isMobileSize,
      isSmallTabletSize,
      isTabletSize,
      isDesktopSize,
      boldLabelTitles,
    };
  }, [
    boldLabelTitles,
    gridAreaTemplateString,
    gridTemplateColumnsString,
    gridTemplateRowsString,
    intl,
    isDesktopSize,
    isMobileSize,
    isSmallTabletSize,
    isTabletSize,
    system,
  ]);

  const _headerContent = useSystemCardHeaderGridContent(gridHookProps);
  const _systemKpiGridContent = useSystemKpiGridContent(gridHookProps);
  const _processKpiGridContent = useProcessKpiGridContent(gridHookProps);

  const _bodyContent = useMemo(() => {
    return (
      <StyledAppCardContent>
        <KPIGrid
          // gap={3}
          spacing={1}
          gridTemplateRows={gridTemplateRows ?? gridTemplateRowsString}
          gridTemplateColumns={gridTemplateColumns ?? gridTemplateColumnsString}
          gridTemplateAreas={gridAreaTemplateString}
        >
          {_headerContent}
          {_systemKpiGridContent}
          {_processKpiGridContent}
          {children}
        </KPIGrid>
      </StyledAppCardContent>
    );
  }, [
    gridTemplateRows,
    gridTemplateRowsString,
    gridTemplateColumns,
    gridTemplateColumnsString,
    gridAreaTemplateString,
    _headerContent,
    _systemKpiGridContent,
    _processKpiGridContent,
    children,
  ]);

  const _entireCardContent = useMemo(() => {
    const _dataAttributes = entityDataAttributes({ system });
    return (
      <StyledAppCard
        {..._dataAttributes}
        $colorKey={cardColorKey}
        $stripeWidth={noStripe ? '0px' : '1em'}
      >
        {_bodyContent}
      </StyledAppCard>
    );
  }, [system, cardColorKey, noStripe, _bodyContent]);
  return _entireCardContent;
};
