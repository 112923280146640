import { ReactNode } from 'react';
import { styled, TableRow, TableCell, TableRowProps } from '@mui/material';
import { AppIcon } from '../../base/AppIcon';
import { transientOptions } from '../../utils/transientOptions';

const StyledTableRow = styled(
  TableRow,
  transientOptions
)<{ $clickable: boolean }>(({ $clickable }) => ({
  '& :hover': {
    cursor: $clickable ? 'pointer' : 'inherit',
  },
}));

const ChevronTableCell = styled(TableCell)({
  width: '40px',
  maxWidth: '40px',
});

export interface AppTableRowProps<T> {
  children: ReactNode;
  data: T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRowClick?: (data: T, target: any) => void;
  tableRowProperties?: TableRowProps;
}

export function AppTableRow<T>({
  children,
  onRowClick,
  data,
  tableRowProperties,
}: AppTableRowProps<T>) {
  return (
    <StyledTableRow
      {...tableRowProperties}
      onClick={(e) => onRowClick?.(data, e.target)}
      $clickable={!!onRowClick}
    >
      {children}
      <ChevronTableCell>
        {onRowClick && (
          <AppIcon icon="chevron-right" $colorKey="subtitleText" />
        )}
      </ChevronTableCell>
    </StyledTableRow>
  );
}
