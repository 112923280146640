import { useIntl } from 'react-intl';
import { Grid } from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import {
  analyticsBeerBrands$,
  analyticsMeasures$,
  analyticsStore,
  analyticsSystems$,
} from '@beeriot/api-client';
import { FilterPill } from '../../../analytics';

export function AnalyticsFiltersRow() {
  const intl = useIntl();
  const [systems] = useObservable(analyticsSystems$);
  const [brands] = useObservable(analyticsBeerBrands$);
  const [measures] = useObservable(analyticsMeasures$);

  const kpiLabel = intl.formatMessage({
    id: 'common-kpi-label',
    description: 'Label for KPI',
    defaultMessage: 'KPI',
  });
  const systemLabel = intl.formatMessage({
    id: 'common-system-label',
    defaultMessage: 'System',
    description: 'label for system',
  });
  const brandLabel = intl.formatMessage({
    id: 'common-brand-label',
    defaultMessage: 'Brand',
    description: 'Label for brand',
  });
  const NA = intl.formatMessage({
    id: 'common-not-applicable',
    defaultMessage: 'N/A',
    description: "Label for when there isn't a value to display",
  });

  return (
    <Grid container spacing={2}>
      {measures.map((measure) => (
        <Grid item key={measure}>
          <FilterPill
            filterCategory={kpiLabel}
            // TODO: Localize measure name
            filterValue={measure}
            colorKey={'highlight2'}
            onDelete={() => {
              analyticsStore.update((state) => ({
                ...state,
                measures: state.measures.filter((x) => x !== measure),
              }));
            }}
          />
        </Grid>
      ))}
      {systems.map((system) => (
        <Grid item key={system.id}>
          <FilterPill
            filterCategory={systemLabel}
            filterValue={system.name ?? NA}
            onDelete={() => {
              analyticsStore.update((state) => ({
                ...state,
                systems: state.systems.filter((x) => x.id !== system.id),
              }));
            }}
          />
        </Grid>
      ))}
      {brands.map((brand) => (
        <Grid item key={brand.id}>
          <FilterPill
            filterCategory={brandLabel}
            filterValue={brand.name ?? NA}
            onDelete={() => {
              analyticsStore.update((state) => ({
                ...state,
                beerBrands: state.beerBrands.filter((x) => x.id !== brand.id),
              }));
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
}
