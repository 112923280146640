import { useIntl } from 'react-intl';
import { CSSObjectWithLabel } from 'react-select';
import { Stack, useTheme } from '@mui/material';
import { alertTypes } from '@beeriot/api-client';
import {
  FormSelect,
  SelectOption,
  SuspenseSiteDropdown,
  SuspenseSystemDropdown,
} from '../form';
import { useIntlAlertType } from '../enums';

export function AlertHistoryFilterFormFields() {
  const theme = useTheme();
  const intl = useIntl();
  const intlAlertType = useIntlAlertType();
  const alertTypePlaceholder = intl.formatMessage({
    id: 'alert-history-alert-type-select-placeholder',
    description: 'Placeholder for alert type dropdown',
    defaultMessage: 'Select Alert Type',
  });
  const allAlertTypes = intl.formatMessage({
    id: 'alert-history-alert-type-select-all-types-label',
    description: 'Label for showing all alert types',
    defaultMessage: 'All Alert Types',
  });
  const typeLabel = intl.formatMessage({
    id: 'common-type-form-label',
    defaultMessage: 'Type:',
    description: 'Inner form label for filtering by a type of something',
  });

  const alertTypeOptions: SelectOption[] = [
    { value: 'all', label: allAlertTypes },
  ].concat(
    alertTypes.map((x) => ({
      value: x,
      label: intlAlertType(x),
    }))
  );

  return (
    <Stack direction="row">
      <FormSelect
        name="alertType"
        innerLabel={typeLabel}
        placeholder={alertTypePlaceholder}
        options={alertTypeOptions}
        styles={{
          control: (base: CSSObjectWithLabel) => ({
            ...base,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor: theme.palette.componentShade.main,
          }),
        }}
      />
      <SuspenseSiteDropdown
        showAllOption
        selectStyles={{
          control: (base: CSSObjectWithLabel) => ({
            ...base,
            borderRadius: 0,
            borderLeft: 'none',
            borderRight: 'none',
            backgroundColor: theme.palette.componentShade.main,
          }),
        }}
      />
      <SuspenseSystemDropdown
        showAllOption
        selectStyles={{
          control: (base: CSSObjectWithLabel) => ({
            ...base,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            backgroundColor: theme.palette.componentShade.main,
          }),
        }}
      />
    </Stack>
  );
}
