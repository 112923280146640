import { useState, useCallback } from 'react';
import { Stack, styled } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { AppTypography } from '../../base/AppTypography';
import { Form, FormError } from '../../form';
import { RouterLink } from '../../navigation';
import { LoginTextInput } from '../LoginTextInput';
import { PallLogo } from '../PallLogo';
import {
  PasswordConditions,
  meetsPasswordRequirements,
} from '../password-conditions';
import { AuthSubmitButton } from '../AuthSubmitButton';
import { FormErrorContainer } from '../FormErrorContainer';
import { UseMutationResult } from 'react-query';
import { ShowHidePasswordButton } from '../ShowHidePasswordButton';
import { User } from '@beeriot/api-client';

const CancelButton = styled(RouterLink)(({ theme }) => ({
  display: 'flex',
  textDecoration: 'underline',
  color: theme.palette.white.main,
  justifyContent: 'center',
  marginTop: '2rem',
}));

const FormLabel = styled(AppTypography)({
  marginTop: '0.5rem',
  marginBottom: '1rem',
});

const ShowPasswordContainer = styled(Stack)({
  alignItems: 'end',
});

interface ResetPasswordFormProps {
  forgotPasswordMutation?: UseMutationResult<User, Error, string, unknown>;
  userSetupMutation?: UseMutationResult<
    void,
    unknown,
    ResetPasswordFormFields,
    unknown
  >;
  error?: React.ReactNode;
}

export interface ResetPasswordFormFields {
  password: string;
}

export function ResetPasswordForm({
  forgotPasswordMutation,
  userSetupMutation,
  error,
}: ResetPasswordFormProps) {
  const [showPassword, setShowPassword] = useState(false);
  const intl = useIntl();
  const passwordPlaceholder = intl.formatMessage({
    id: 'login-password-placeholder',
    description: 'Placeholder for password input',
    defaultMessage: 'Enter password',
  });

  const mutation = forgotPasswordMutation
    ? forgotPasswordMutation
    : userSetupMutation;

  return (
    <Form<ResetPasswordFormFields>
      onSubmit={(data) =>
        forgotPasswordMutation
          ? forgotPasswordMutation.mutate(data.password)
          : userSetupMutation?.mutate(data)
      }
    >
      <PallLogo />
      <AppTypography variant="body1" $bold $colorKey="white">
        <FormattedMessage
          id="reset-password-form-title-label"
          defaultMessage="Enter New Password"
          description="Label for reset password form title"
        />
      </AppTypography>
      <FormLabel variant="subtitle2" $colorKey="white">
        <FormattedMessage
          id="reset-password-form-label"
          defaultMessage="Please enter your desired password below:"
          description="Label for reset password form"
        />
      </FormLabel>
      <LoginTextInput
        required
        name="password"
        validate={useCallback((value: string) => {
          const isValid = meetsPasswordRequirements(value);
          return typeof isValid != 'string' && (isValid as boolean);
        }, [])}
        placeholder={passwordPlaceholder}
        type={showPassword ? 'text' : 'password'}
      />
      <ShowPasswordContainer>
        <ShowHidePasswordButton
          showPassword={showPassword}
          setShowPassword={setShowPassword}
        />
      </ShowPasswordContainer>
      <PasswordConditions />
      <FormErrorContainer>
        <FormError error={error} color="info" />
      </FormErrorContainer>
      <AuthSubmitButton type="submit" isLoading={mutation?.isLoading}>
        <FormattedMessage
          id="reset-password-save-password-button"
          defaultMessage="Save New Password"
          description="Save password button"
        />
      </AuthSubmitButton>
      <CancelButton to="/auth">
        <FormattedMessage
          id="common-cancel-button"
          description="Label for cancel button"
          defaultMessage="Cancel"
        />
      </CancelButton>
    </Form>
  );
}
