import { Button, Stack, styled } from '@mui/material';
import { AppCard, AppTypography } from '../../../base';
import { Group, groupStore } from '@beeriot/api-client';

const ItemContainer = styled(Stack)({
  width: '100%',
  height: '100%',
  marginBottom: '0.5rem',
});

const StyledAppCard = styled(AppCard)({
  width: '100%',
});

const GroupTypeButton = styled(Button)({
  width: '100%',
  height: '100%',
  padding: '0.75rem 1rem',
  justifyContent: 'flex-start',
});

export interface GroupTypeListItemProps {
  item: Group;
  selectedTypeId: string;
}

export function GroupTypeListItem({
  item,
  selectedTypeId,
}: GroupTypeListItemProps) {
  const handleGroupTypeClick = (group: Group) => {
    groupStore.update((state) => ({
      ...state,
      activeGroupType: group,
    }));
  };

  return item.id === selectedTypeId ? (
    <ItemContainer>
      <StyledAppCard $colorKey="primary" $stripeWidth="0.5rem">
        <GroupTypeButton
          key={item.id}
          onClick={() => handleGroupTypeClick(item)}
          variant="text"
        >
          <AppTypography $colorKey="primary">{item.name}</AppTypography>
        </GroupTypeButton>
      </StyledAppCard>
    </ItemContainer>
  ) : (
    <ItemContainer>
      <GroupTypeButton
        key={item.id}
        onClick={() => handleGroupTypeClick(item)}
        variant="text"
      >
        <AppTypography $colorKey="lightText">{item.name}</AppTypography>
      </GroupTypeButton>
    </ItemContainer>
  );
}
