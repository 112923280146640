import { ColorKey, GQLSystem } from '@beeriot/api-client';
import { SelectOption } from '../../../form/SelectOption';
import { ProfiSystemDetailCardBase } from './ProfiSystemDetailCardBase';

import { SystemCardBaseProps } from '../base-card-common/SystemCardBase';

export interface ProfiSystemDetailCardProps {
  system: GQLSystem;
  noStripe?: boolean;
  stripeColor?: ColorKey;
  brand?: SelectOption;
  brandOptions?: SelectOption[];
  cardTitle?: string;
  boldTitle?: boolean;
  boldLabelTitles?: boolean;
}

export const ProfiSystemDetailCard = (props: SystemCardBaseProps) => {
  const { noStripe, stripeColor, system, boldLabelTitles = false } = props;
  return (
    <ProfiSystemDetailCardBase
      data-element-id={'profi-system-detail-card'}
      {...props}
      noStripe={noStripe}
      stripeColor={stripeColor}
      system={system}
      boldLabelTitles={boldLabelTitles}
    />
  );
};
