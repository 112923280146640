import { useIntl } from 'react-intl';
import { Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import { GQLCompanySite, ColorKey } from '@beeriot/api-client';
import { AppCard } from '../base/AppCard';
import {
  VerticalLabels,
  AppTypography,
  OverridableComponentAppTypography,
  IconLabel,
  SuspenseLabeledSystemCountsBadgeList,
} from '../base';
import { transientOptions } from '../utils';
import { useEntityDataAttributes } from '../utils/entityDataAttributes';
import { SuspenseAlertCount } from '../alerts/alert-count/SuspenseAlertCount';

const StyledCard = styled(
  AppCard,
  transientOptions
)(({ theme }) => ({
  width: '100%',
  padding: '1rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  justifyContent: 'stretch',
  flexWrap: 'wrap',
  gap: '0.5rem',
}));

const SiteName = styled(AppTypography)({
  fontWeight: 'bold',
}) as OverridableComponentAppTypography;

const HeaderStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
  gap: '1rem',
}));

const ContentStack = styled(Stack)(({ theme }) => ({
  flex: 1,
  justifyContent: 'space-between',
  gap: '1.5rem',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'end',
  },
}));

const StyledSystemCountsBadgeList = styled(
  SuspenseLabeledSystemCountsBadgeList
)({
  marginLeft: 'auto',
  minHeight: 0,
});

export type CardSizeVariant = 'default' | 'small';

export interface SiteCardProps {
  site: GQLCompanySite;
  stripeColorKey?: ColorKey;
  sizeVariant?: CardSizeVariant;
}

export function SiteCard({
  site,
  stripeColorKey,
  sizeVariant = 'default',
}: SiteCardProps) {
  const intl = useIntl();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const activeAlertsLabel = intl.formatMessage({
    id: 'common-active-alerts',
    description: 'Label for active alerts',
    defaultMessage: 'Active Alerts',
  });

  const siteDataAttributes = useEntityDataAttributes({ site });
  return (
    <StyledCard
      data-element-id={'site-card-element'}
      {...siteDataAttributes}
      $colorKey={stripeColorKey}
      $stripeWidth="0.7rem"
    >
      <HeaderStack direction="row">
        <VerticalLabels
          label={
            <SiteName
              data-element-id={'site-name-element'}
              component="div"
              variant={sizeVariant === 'small' ? 'subtitle1' : 'h6'}
            >
              {site.name}
            </SiteName>
          }
          subLabel={
            <IconLabel
              data-element-id={'site-address-location'}
              label={site.address?.state}
              colorKey="lightText"
              variant={sizeVariant === 'small' ? 'caption' : 'subtitle2'}
              icon={'location-dot'}
              iconColorKey="lightText"
            />
          }
          subLabelBold
        />
        {sizeVariant === 'small' ? null : (
          <StyledSystemCountsBadgeList
            data-element-id={'site-badge-list'}
            siteId={site.id}
            site={site}
          />
        )}
      </HeaderStack>
      <ContentStack direction="row">
        {isSmall ? null : <SuspenseAlertCount siteId={site.id} site={site}/>}
      </ContentStack>
    </StyledCard>
  );
}
SiteCard.displayName = 'SiteCard';
