import {
  GQLListSystemsQuery,
  GQLListSystemsQueryVariables
} from '../generated/gql';
import { AppQueryClient } from '../utils';
import { axiosHelper } from '../generated/axiosHelper';
import { PrefetchListSystemsDocument } from '../generated/PrefetchListSystemsDocument';



export async function prefetchSystems(companyId?: string | null) {
  const systemsQuery = axiosHelper<
    GQLListSystemsQuery,
    GQLListSystemsQueryVariables
  >(PrefetchListSystemsDocument);
  const cachedFullList = AppQueryClient.getQueryData(['listSystems.infinite.prefetchFull', { skip: 0, limit: 100, status: "active", companyId, }]);
  if (!cachedFullList) {
    const rawSystems = await AppQueryClient.fetchQuery(['listSystems.infinite.prefetchFull', { skip: 0, limit: 100, status: "active", companyId,  }], async () => systemsQuery({ skip: 0, limit: 100, companyId, status:'active' }));
    if (rawSystems.listSystems) {
      AppQueryClient.setQueryData(
        ['listSystems.infinite.prefetchFull', { skip: 0, limit: 100, status: "active", companyId,  }],
        { pages: [rawSystems], pageParams: [], isCachedPartial: true }
      );
      const companySites = rawSystems.listSystems?.reduce(
        (acc, system) => {
          if (system?.companyId) {
            const isActive = system?.status === 'active';
            if (!acc[system?.companyId]) {
              acc[system?.companyId] = [];
            }
            acc[system?.companyId].push(system);
            AppQueryClient.setQueryData(
              ['getSystem.prefetch', { systemId: system?.id, isActive }],
              { getSystem: system, isCachedPartial: true }
            );
            AppQueryClient.setQueryData(
              [
                'getSystem.prefetch',
                { systemId: system?.id, companyId: system?.companyId, isActive },
              ],

              { getSystem: system, isCachedPartial: true }
            );
            AppQueryClient.setQueryData(
              [
                'getSystem.prefetch',
                { systemId: system?.id, companyId: system?.companyId, companySiteId: system?.companySiteId, isActive },
              ],
              { getSystem: system, isCachedPartial: true }
            );
          }
          return acc;
        },
        {} as Record<string, typeof rawSystems.listSystems>
      );

      Object.entries(companySites).forEach(([companyId, companySystems]) => {
        AppQueryClient.setQueryData(
          ['listSystems.infinite.prefetchFull', { companyId: companyId, limit: 100, skip: 0, status: 'active' }],
          { pages: [{ listSystems: companySystems }], pageParams: [], isCachedPartial: true }
        );

        const siteSystems = companySystems.reduce((acc, system) => {
          if (system?.companySiteId) {
            if (!acc[system?.companySiteId]) {
              acc[system?.companySiteId] = [];
            }
            acc[system?.companySiteId].push(system);
          }
          return acc;
        }, {} as Record<string, typeof companySystems>);

        Object.entries(siteSystems).forEach(([companySiteId, siteSystems]) => {
          if (siteSystems.length) {
            AppQueryClient.setQueryData(
              ['listSystems.infinite.site.prefetch', { companyId: companyId, companySiteId: companySiteId, limit: 100, skip: 0, status: 'active' }],
              { pages: [{ listSystems: siteSystems }], pageParams: [], isCachedPartial: true }
            );
            AppQueryClient.setQueryData(
              ['listSystems.infinite.site.prefetch', { companySiteId: companySiteId, limit: 100, skip: 0, status: 'active' }],
              { pages: [{ listSystems: siteSystems }], pageParams: [], isCachedPartial: true }
            );
          }

        });

      });
    }
  }



}
