import { Chip, styled } from '@mui/material';
import { ColorKey } from '@beeriot/api-client';
import { AppIcon, AppTypography } from '../../base';

export interface FilterPillProps {
  filterCategory?: string;
  filterValue: string;
  colorKey?: ColorKey;
  onDelete?: () => void;
}

const BoldSpan = styled('span')({ fontWeight: 'bold' });
const StyledTypography = styled(AppTypography)({ marginRight: '.25rem' });
const StyledChip = styled(Chip)({ padding: '1.15rem .5rem' });

export function FilterPill({
  filterCategory,
  filterValue,
  colorKey,
  onDelete,
}: FilterPillProps) {
  return (
    <StyledChip
      color={colorKey ?? 'primary'}
      label={
        <StyledTypography $colorKey="white">
          {filterCategory && <BoldSpan>{`${filterCategory}: `}</BoldSpan>}
          {filterValue}
        </StyledTypography>
      }
      deleteIcon={<AppIcon $colorKey="white" icon="close" />}
      onDelete={onDelete}
    />
  );
}
