import { Paper, Stack, styled } from '@mui/material';
import { Document } from '@beeriot/api-client';
import { useResponsiveSizes } from '../../utils';
import { DocumentationPanelHeader } from './DocumentationPanelHeader';
import { SuspenseDocumentationTableContainer } from './SuspenseDocumentationTableContainer';

const TableContainer = styled(Stack)(({ theme }) => ({
  margin: '1rem',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  overflow: 'auto',
}));

export interface DocumentationTableContainerProps {
  showDeletedDocuments: boolean;
  showAddForm: () => void;
  showEditForm: (data: Document) => void;
  downloadDocument: (data: Document) => void;
  showDocument: (data: Document) => void;
  showConfirmUnDelete: (data: Document) => void;
  toggleDeletedDocuments: () => void;
}

export const DocumentationTableContainer = ({
  showDeletedDocuments,
  showAddForm,
  showEditForm,
  downloadDocument,
  showDocument,
  showConfirmUnDelete,
  toggleDeletedDocuments,
}: DocumentationTableContainerProps) => {
  const { isMobileSize } = useResponsiveSizes();
  return (
    <TableContainer>
      {isMobileSize ? (
        <SuspenseDocumentationTableContainer
          showEditForm={showEditForm}
          showDocument={showDocument}
          downloadDocument={downloadDocument}
          showConfirmUnDelete={showConfirmUnDelete}
        />
      ) : (
        <StyledPaper>
          <DocumentationPanelHeader
            showAddForm={showAddForm}
            toggleDeletedDocuments={toggleDeletedDocuments}
            showDeletedDocuments={showDeletedDocuments}
          />
          <SuspenseDocumentationTableContainer
            showEditForm={showEditForm}
            showDocument={showDocument}
            downloadDocument={downloadDocument}
            showConfirmUnDelete={showConfirmUnDelete}
          />
        </StyledPaper>
      )}
    </TableContainer>
  );
};
