import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled, Alert } from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import {
  useCreateDocument,
  Document,
  useIntlError,
  useUpdateDocument,
  useViewDocument,
  useDownloadDocument,
  useRestoreDocument,
  lineDocumentsStore,
  showDeletedDocuments$,
} from '@beeriot/api-client';
import { RootPage } from '../../pages/RootPage';
import {
  AddEditDocumentDialog,
  ConfirmUnDeleteDialog,
} from '../../dialogs/documentation';
import { DocumentationTableContainer } from './DocumentationTableContainer';
import { AppTypography, AppIcon, GlobalFooter, PdfDialog } from '../../base';
import { DocumentationButtons } from './DocumentationButtons';
import { useResponsiveSizes } from '../../utils';

const StyledRootPage = styled(RootPage)({
  overflow: 'auto',
});

const WarningAlert = styled(Alert)(({ theme }) => ({
  margin: '1rem 1rem 0 1rem',
  border: `1px solid ${theme.palette.warning.main}`,
}));

export function DocumentationPanel() {
  const [open, setOpen] = useState<boolean>(false);
  const [openUndeleteDialog, setOpenUndeleteDialog] = useState<boolean>(false);
  const [openPdfDialog, setOpenPdfDialog] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Document | undefined>();
  const { isDesktopSize } = useResponsiveSizes();
  const [error, setError] = useState<string | undefined>();
  const intlError = useIntlError();
  const [showDeletedDocuments] = useObservable(showDeletedDocuments$);
  const createDocumentMutation = useCreateDocument({
    onSuccess: () => {
      setError('');
    },
    onError: (err: string) => {
      setError(intlError(err));
    },
  });
  const updateDocumentMutation = useUpdateDocument(selectedItem?.id ?? '', {
    onSuccess: () => {
      setError('');
    },
    onError: (err: string) => {
      setError(intlError(err));
    },
  });
  const viewDocumentMutation = useViewDocument();
  const downloadDocumentMutation = useDownloadDocument();
  const restoreDocumentMutation = useRestoreDocument({
    onSuccess: (data: Document | undefined) => {
      setOpenUndeleteDialog(false);
    },
    onError: () => {
      setOpenUndeleteDialog(false);
    },
  });

  const showAddForm = useCallback(() => {
    setSelectedItem(undefined);
    setOpen(true);
  }, []);

  const showEditForm = useCallback((data: Document) => {
    setSelectedItem(data);
    setOpen(true);
  }, []);

  const showConfirmUnDelete = useCallback((data: Document) => {
    setSelectedItem(data);
    setOpenUndeleteDialog(true);
  }, []);

  const showDocument = useCallback(
    (data: Document) => {
      viewDocumentMutation.mutate(data);
      setOpenPdfDialog(true);
    },
    [viewDocumentMutation]
  );

  const downloadDocument = useCallback(
    (data: Document) => {
      downloadDocumentMutation.mutate(data);
    },
    [downloadDocumentMutation]
  );

  const toggleDeletedDocuments = useCallback(() => {
    lineDocumentsStore.update((state) => ({
      ...state,
      showDeletedDocuments: !state.showDeletedDocuments,
    }));
  }, []);

  return (
    <StyledRootPage>
      {showDeletedDocuments && (
        <WarningAlert
          severity="warning"
          icon={
            <AppIcon
              icon={'circle-exclamation'}
              $colorKey={'warning'}
              size="1x"
            />
          }
        >
          <AppTypography $bold variant="subtitle2">
            <FormattedMessage
              id="documentation-deleted-documents-warning"
              description="Warning for deleted documents"
              defaultMessage="Documents below show the days remaining before removal. After that time, they will be permanently deleted."
            />
          </AppTypography>
        </WarningAlert>
      )}
      {!isDesktopSize && (
        <DocumentationButtons
          showDeletedDocuments={showDeletedDocuments}
          showAddForm={showAddForm}
          toggleDeletedDocuments={toggleDeletedDocuments}
        />
      )}
      <DocumentationTableContainer
        showDeletedDocuments={showDeletedDocuments}
        showAddForm={showAddForm}
        showEditForm={showEditForm}
        showDocument={showDocument}
        downloadDocument={downloadDocument}
        showConfirmUnDelete={showConfirmUnDelete}
        toggleDeletedDocuments={toggleDeletedDocuments}
      />
      <GlobalFooter />
      <AddEditDocumentDialog
        item={selectedItem}
        open={open}
        mutation={
          selectedItem ? updateDocumentMutation : createDocumentMutation
        }
        error={error}
        onClose={() => setOpen(false)}
        showDocument={showDocument}
        downloadDocument={downloadDocument}
        setError={setError}
      />
      <PdfDialog
        open={openPdfDialog}
        onClose={() => setOpenPdfDialog(false)}
        content={{ url: viewDocumentMutation.data?.url ?? '' }}
      />
      {selectedItem && (
        <ConfirmUnDeleteDialog
          item={selectedItem}
          mutation={restoreDocumentMutation}
          open={openUndeleteDialog}
          onClose={() => setOpenUndeleteDialog(false)}
        />
      )}
    </StyledRootPage>
  );
}
