import { Form, SelectOption } from '../form';
import { AlertHistoryFilterFormFields } from './AlertHistoryFilterFormFields';
import { AlertState, alertStore, isAlertType } from '@beeriot/api-client';

interface FilterByFormFields {
  alertType: SelectOption;
  site: SelectOption | null;
  system: SelectOption | null;
}

interface AlertHistoryFilterByDropdownProps {
  className?: string;
}

export const AlertHistoryFilterDropdown = ({
  className,
}: AlertHistoryFilterByDropdownProps) => {
  return (
    <Form<FilterByFormFields>
      className={className}
      submitOnChange
      onSubmit={(data) => {
        const storeUpdates: Partial<AlertState> = {};
        if (isAlertType(data.alertType.value)) {
          storeUpdates.alertTypeFilter = data.alertType.value;
        } else {
          storeUpdates.alertTypeFilter = undefined;
        }
        if (data.site?.value) {
          storeUpdates.siteIdFilter = data.site.value;
        }
        if (data.system?.value) {
          storeUpdates.systemIdFilter = data.system.value;
        }
        alertStore.update((state) => ({
          ...state,
          ...storeUpdates,
        }));
      }}
    >
      <AlertHistoryFilterFormFields />
    </Form>
  );
};
