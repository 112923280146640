import { useCallback, useMemo } from 'react';
import { UseMutationResult } from 'react-query';
import { useObservable } from '@ngneat/use-observable';
import { UseFormProps } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { FileWithPath } from 'react-dropzone';
import { Button, Stack, styled } from '@mui/material';
import {
  Document,
  useDeleteDocument,
  CreateUpdateDocumentRequest,
  documentUploadProgress$,
  activeSystem$,
  DocumentBaseFileFormatEnum,
} from '@beeriot/api-client';
import { LinearProgressWithLabel } from '../../base/LinearProgressWithLabel';
import { AppTypography } from '../../base/AppTypography';
import { Form, FormDropzone, FormFooterButtons, FormError } from '../../form';
import {
  SectionControl,
  SectionLabel,
  SectionTextAreaInput,
  SectionTextInput,
  VerticalSectionControl,
} from '../../settings/settings-form';
import { ConfirmDeleteDocumentButton } from './ConfirmDeleteDocumentButton';
import { FormErrorContainer } from '../../auth/FormErrorContainer';
import { transientOptions, useResponsiveSizes } from '../../utils';
import { PermissionKey, PermissionView } from '../../base';

function fromFormFields(
  systemId: string,
  fields: AddEditDocumentFormFields,
  item?: Document,
  onError?: (err: string) => void
): CreateUpdateDocumentRequest {
  const file = fields.files ? fields.files[0] : undefined;
  const fileFormatParts = file?.path?.split('.') ?? [];
  const fileFormat =
    fileFormatParts.length > 0
      ? fileFormatParts[fileFormatParts.length - 1]
      : undefined;
  const defaultFallbackFilename =
    item?.fileFormat && item?.name ? `${item?.name}.${item?.fileFormat}` : '';

  if (
    !fileFormat ||
    !(Object.values(DocumentBaseFileFormatEnum) as string[]).includes(
      fileFormat
    )
  ) {
    onError?.('Unsupported file format detected');
    throw new Error('Unsupported file format detected');
  }

  return {
    document: {
      systemId,
      name: fields.documentName,
      description: fields.description,
      filename: file?.name ?? item?.filename ?? defaultFallbackFilename,
      fileFormat:
        (fileFormat as DocumentBaseFileFormatEnum) ?? item?.fileFormat ?? '',
    },
    file: file,
  };
}

const HeaderText = styled(AppTypography)({ alignSelf: 'center' });

const HeaderStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.componentBase.main,
  justifyContent: 'space-between',
  padding: '1rem',
  gap: '2rem',
  borderTopRightRadius: theme.shape.borderRadius,
  borderTopLeftRadius: theme.shape.borderRadius,
}));

const FormButtonContainer = styled(
  Stack,
  transientOptions
)<{ $isMobile: boolean }>(({ $isMobile }) => ({
  margin: $isMobile ? '0rem' : '1rem',
  flexDirection: 'row',
  justifyContent: $isMobile ? 'center' : 'end',
  flex: 1,
  flexWrap: 'wrap',
}));

const FileSectionLabel = styled(
  SectionLabel,
  transientOptions
)<{ $isMobile: boolean }>(({ $isMobile }) => ({
  marginRight: $isMobile ? 'auto' : '0',
}));

const StyledFormButtons = styled(FormFooterButtons)({ margin: '0.5rem' });
const StyledLinearProgressWithLabel = styled(LinearProgressWithLabel)({
  padding: '0.5rem 1rem',
});

const StyledFormErrorContainer = styled(FormErrorContainer)({
  margin: '1rem',
});

const MobileControlsStack = styled(Stack)({
  padding: '1rem',
  gap: '1rem',
});

export interface AddEditDocumentFormFields {
  documentName: string;
  files: FileWithPath[];
  description: string;
}

interface AddEditDocumentFormProps {
  item?: Document;
  mutation:
    | UseMutationResult<Document, string, CreateUpdateDocumentRequest, unknown>
    | UseMutationResult<
        Document | undefined,
        string,
        CreateUpdateDocumentRequest,
        unknown
      >;
  error?: React.ReactNode;
  onCancel: () => void;
  downloadDocument: (data: Document) => void;
  showDocument: (data: Document) => void;
  setError: (err: string) => void;
}

export function AddEditDocumentForm({
  item,
  mutation,
  error,
  onCancel,
  downloadDocument,
  showDocument,
  setError,
}: AddEditDocumentFormProps) {
  const intl = useIntl();
  const { isMobileSize, isPhoneSize, isTabletSize, isSmallTabletSize } =
    useResponsiveSizes();
  const isEditForm = useMemo(() => item !== undefined, [item]);
  const submitButtonLabel = isEditForm
    ? intl.formatMessage({
        id: 'documentation-document-modal-edit-document-button',
        description: 'Edit document button',
        defaultMessage: 'Edit Document',
      })
    : intl.formatMessage({
        id: 'documentation-document-modal-add-document-button',
        description: 'Add document button',
        defaultMessage: 'Add Document',
      });
  const [progress] = useObservable(documentUploadProgress$);

  const formProps: UseFormProps<AddEditDocumentFormFields> = useMemo(
    () => ({
      defaultValues: {
        documentName: item?.name ?? '',
        files: [],
        description: item?.description ?? '',
      },
    }),
    [item]
  );
  const [activeSystem] = useObservable(activeSystem$);
  const deleteDocument = useDeleteDocument();

  const onDelete = useCallback(() => {
    if (item) {
      deleteDocument.mutate(item);
    }

    onCancel(); // This closes the dialog after deletion
  }, [onCancel, deleteDocument, item]);

  const onConfirmOkSuccessDialog = useCallback(() => {
    mutation?.reset();
    onCancel();
  }, [onCancel, mutation]);

  const onSubmit = useCallback(
    (data: AddEditDocumentFormFields) => {
      mutation.mutate(
        fromFormFields(activeSystem?.id ?? '', data, item, (err) =>
          setError(err)
        )
      );
    },
    [activeSystem, mutation, item]
  );

  const FileSectionControl = isMobileSize
    ? VerticalSectionControl
    : SectionControl;

  const download = useCallback(() => {
    if (item) downloadDocument(item);
  }, [downloadDocument, item]);

  const view = useCallback(() => {
    if (item) showDocument(item);
  }, [item, showDocument]);

  let deleteDocumentPermissionKey: PermissionKey;

  switch (activeSystem?.type) {
    case 'profi':
      deleteDocumentPermissionKey = PermissionKey.SitesPROFiDocumentationDelete;
      break;
    case 'cbs':
      deleteDocumentPermissionKey = PermissionKey.SitesCBSDocumentationDelete;
      break;
    case 'cfs':
      deleteDocumentPermissionKey = PermissionKey.SitesCFSDocumentationDelete;
      break;
    case 'keraflux':
      deleteDocumentPermissionKey =
        PermissionKey.SitesKerafluxDocumentationDelete;
      break;
    default:
      deleteDocumentPermissionKey = PermissionKey.SitesPROFiDocumentationDelete;
      break;
  }

  return (
    <>
      <HeaderStack direction="row">
        <HeaderText $bold variant="h6">
          {isEditForm ? (
            <FormattedMessage
              id="documentation-document-modal-edit-title"
              description="Label for edit document form modal"
              defaultMessage="Edit Document"
            />
          ) : (
            <FormattedMessage
              id="documentation-document-modal-add-title"
              description="Label for add document form modal"
              defaultMessage="Add Document"
            />
          )}
        </HeaderText>
      </HeaderStack>
      <Form<AddEditDocumentFormFields>
        onSubmit={onSubmit}
        formProps={formProps}
      >
        <Stack>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="documentation-document-modal-document-name-label"
                defaultMessage="Document Name"
                description="label for document name"
              />
            </SectionLabel>
            <SectionTextInput
              name="documentName"
              required
              placeholder={intl.formatMessage({
                id: 'documentation-document-modal-document-name-placeholder',
                defaultMessage: 'Enter Document Name',
                description: 'label for document name placeholder',
              })}
            />
          </SectionControl>
          <FileSectionControl>
            <FileSectionLabel $isMobile={isMobileSize}>
              <FormattedMessage
                id="common-file-label"
                defaultMessage="File"
                description="label for file"
              />
            </FileSectionLabel>
            <FormDropzone
              name="files"
              required={!isEditForm}
              placeholder={
                isEditForm
                  ? item?.filename ?? `${item?.name}.${item?.fileFormat}`
                  : undefined
              }
            />
          </FileSectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="documentation-document-modal-description-label"
                defaultMessage="Description"
                description="label for description"
              />
            </SectionLabel>
            <SectionTextAreaInput
              name="description"
              minRows={6}
              required
              placeholder={intl.formatMessage({
                id: 'documentation-document-modal-description-placeholder',
                defaultMessage: 'Enter description',
                description: 'label for description placeholder',
              })}
            />
          </SectionControl>
          {mutation?.isLoading && progress > 0 && progress < 100 && (
            <StyledLinearProgressWithLabel value={progress ?? 0} />
          )}
          {error && (
            <StyledFormErrorContainer>
              <FormError error={error} color="info" />
            </StyledFormErrorContainer>
          )}
          {isPhoneSize && (
            <MobileControlsStack>
              <FormButtonContainer $isMobile={isMobileSize}>
                {isEditForm && (
                  <Stack direction="row">
                    <Button
                      variant="contained"
                      onClick={download}
                      sx={{ margin: '0.5rem 0.25rem' }}
                    >
                      <FormattedMessage
                        id="common-download-button"
                        description="Button for download"
                        defaultMessage="Download"
                      />
                    </Button>
                    <Button
                      variant="contained"
                      onClick={view}
                      sx={{ margin: '0.5rem 0.25rem' }}
                    >
                      <FormattedMessage
                        id="common-view-button"
                        description="Button for view"
                        defaultMessage="View"
                      />
                    </Button>
                    <PermissionView permissionKey={deleteDocumentPermissionKey}>
                      <ConfirmDeleteDocumentButton onDelete={onDelete} />
                    </PermissionView>
                  </Stack>
                )}
                <StyledFormButtons
                  isLoading={mutation?.isLoading}
                  isSuccess={mutation?.isSuccess}
                  successLabel={
                    isEditForm
                      ? intl.formatMessage({
                          id: 'documentation-document-modal-update-success-message',
                          defaultMessage: 'Successfully updated document.',
                          description:
                            'label for update document success message',
                        })
                      : intl.formatMessage({
                          id: 'documentation-document-modal-add-success-message',
                          defaultMessage: 'Successfully added document.',
                          description: 'label for add document success message',
                        })
                  }
                  onConfirmOkSuccessDialog={onConfirmOkSuccessDialog}
                  submitLabel={submitButtonLabel}
                  onCancel={onCancel}
                  requireCancelConfirmation={false}
                />
              </FormButtonContainer>
            </MobileControlsStack>
          )}
          {(isTabletSize || isSmallTabletSize) && isEditForm && (
            <MobileControlsStack>
              <Button
                variant="contained"
                onClick={download}
                sx={{ margin: '0.5rem 0.25rem' }}
              >
                <FormattedMessage
                  id="common-download-button"
                  description="Button for download"
                  defaultMessage="Download"
                />
              </Button>
              <Button
                variant="contained"
                onClick={view}
                sx={{ margin: '0.5rem 0.25rem' }}
              >
                <FormattedMessage
                  id="common-view-button"
                  description="Button for view"
                  defaultMessage="View"
                />
              </Button>
            </MobileControlsStack>
          )}
          {!isPhoneSize ? (
            <FormButtonContainer $isMobile={isMobileSize}>
              {isEditForm ? (
                <ConfirmDeleteDocumentButton onDelete={onDelete} />
              ) : null}
              <StyledFormButtons
                isLoading={mutation?.isLoading}
                isSuccess={mutation?.isSuccess}
                successLabel={
                  isEditForm
                    ? intl.formatMessage({
                        id: 'documentation-document-modal-update-success-message',
                        defaultMessage: 'Successfully updated document.',
                        description:
                          'label for update document success message',
                      })
                    : intl.formatMessage({
                        id: 'documentation-document-modal-add-success-message',
                        defaultMessage: 'Successfully added document.',
                        description: 'label for add document success message',
                      })
                }
                onConfirmOkSuccessDialog={onConfirmOkSuccessDialog}
                submitLabel={submitButtonLabel}
                onCancel={onCancel}
                requireCancelConfirmation={false}
              />
            </FormButtonContainer>
          ) : null}
        </Stack>
      </Form>
    </>
  );
}
