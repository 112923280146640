import {
  ReactNode,
  PropsWithChildren,
  forwardRef,
  useRef,
  useState,
  ComponentProps,
  isValidElement,
  Children,
  ReactElement,
  useMemo,
  cloneElement,
  MutableRefObject,
  useEffect,
} from 'react';
import { Stack, styled } from '@mui/material';
import { TabList, TabContext } from '@mui/lab';
import {
  useTabRoutingState,
  useTabsRoutesProps,
} from '../tabs/hooks/useTabRoutingState';
import { RoutesCustom } from '../RoutesCustom';
import { useIntl } from 'react-intl';
import { AppBreadcrumbsContainer } from '../../base/breadcrumbs/AppBreadcrumbsContainer';
import { RoutingBreadcrumbs } from '../../base/breadcrumbs/routing-breadcrumbs';

import { transientOptions } from '../../utils/transientOptions';
import { useResponsiveSizes } from '../../utils/responsive-design/useResponsiveSizes';
import { RoutesContainer } from '../RoutesContainer';
import {
  setDropdownText,
  setIsNavigationMenuOpen,
  setSubMenuHeight,
} from '@beeriot/api-client';
import { SubTabsButton } from '../sub-tabs/SubTabsButton';
import { useLocation } from 'react-router-dom';
import { MobileTabDrawer } from '../mobile-tab-drawer/MobileTabDrawer';
import { useDomNodeHeight } from '../../utils';
const TabsContainer = styled(Stack)(({ theme }) => ({
  zIndex: theme.zIndex.appBar + 1,
  boxShadow: theme.shadows[3],
  backgroundColor: theme.palette.componentBase.main,
}));

const StyledTabs = styled(
  TabList,
  transientOptions
)<{ $isDesktop: boolean }>(({ theme, $isDesktop }) => ({
  backgroundColor: theme.palette.componentBase.main,
  padding: $isDesktop ? '0 2rem' : '1rem',
  '& .MuiTabs-flexContainer': {
    height: '100%',
    gap: '1rem',
    justifyContent: 'space-between',
  },
  '& .MuiLink-root': {
    padding: '0',
    fontSize: $isDesktop ? '1.15rem' : undefined,
  },
  '& .MuiTabs-indicator': $isDesktop
    ? {
        height: '0.5rem',
      }
    : {
        background: 'clear',
        opacity: 0,
      },
  '& .MuiTabs-scrollButtons.Mui-disabled': {
    opacity: 0.3,
  },
}));

interface SubTabsProps extends PropsWithChildren<useTabsRoutesProps> {
  renderControls?: (activeIdx: number) => ReactNode;
}

export const SubTabs = forwardRef(
  (
    {
      tabsKey: name,
      routes,
      lastRouteMatch,
      tabButtonRenderer,
      tabPanelRenderer,
      renderControls,
    }: SubTabsProps,
    _ref
  ) => {
    const { isDesktopSize, isMobileSize, isTabletSize } = useResponsiveSizes();
    const isCompactLayout = isMobileSize || isTabletSize;
    const { activeIdx, activeIdxStr, tabs, panels, tabRoutes } =
      useTabRoutingState({
        tabsKey: name,
        lastRouteMatch,
        routes: routes,
        tabButtonRenderer,
        tabPanelRenderer,
      });
    const [isOpen, setIsOpen] = useState(false);

    const __ref = useRef<HTMLDivElement | null>(null);
    const ref =
      (_ref as unknown as MutableRefObject<HTMLDivElement | null>) ?? __ref;
    const routesContainerRef = useRef<HTMLDivElement | null>(null);
    const controlsHeight = useDomNodeHeight({
      nodeRef: ref,
      defaultValue: 48,
      watchWindowResize: true,
      cachedValueIdentifier: 'SubTabsMenu-MenuHeight',
    });
    useEffect(() => {
      setSubMenuHeight(controlsHeight);
    }, [controlsHeight]);

    const intl = useIntl();
    const subTabsAriaLabel = intl.formatMessage({
      id: 'common-sub-tab-aria-label',
      description: 'Sub tab navigation aria label',
      defaultMessage: 'Sub Tab Navigation',
    });

    const location = useLocation();

    const tabButtons = useMemo(() => {
      return Children.map(tabs, (child, idx: number) => {
        if (!isValidElement<ComponentProps<typeof SubTabsButton>>(child)) {
          console.log(`not valid element: `, child);
          return child;
        }

        let elementChild: ReactElement<ComponentProps<typeof SubTabsButton>> =
          child;
        if (child.props) {
          elementChild = cloneElement<ComponentProps<typeof SubTabsButton>>(
            elementChild,
            {
              onClick: (ev) => {
                child?.props?.onClick?.(ev);
                setIsOpen(false);
                setIsNavigationMenuOpen(false);
                const tabLabel = tabRoutes?.[idx]?.label;
                if (tabLabel) setDropdownText(tabLabel);
              },
            }
          );
          // console.log(`attached clicks`);
        }
        return elementChild;
      });
    }, [tabRoutes, tabs, setIsOpen]);

    const _tabsMenu = (
      <StyledTabs
        $isDesktop={isDesktopSize}
        aria-label={subTabsAriaLabel}
        variant={'scrollable'}
        scrollButtons={false}
        orientation={isCompactLayout ? 'vertical' : 'horizontal'}
      >
        {tabButtons}
      </StyledTabs>
    );
    const tabsDrawer = (
      <MobileTabDrawer
        isOpen={isOpen}
        isMobileSize={isCompactLayout}
        containerRef={routesContainerRef}
        setIsOpen={setIsOpen}
      >
        {_tabsMenu}
      </MobileTabDrawer>
    );

    const shouldShowTabs = !isCompactLayout || (isCompactLayout && isOpen);
    // console.log({ isMobileSize, isOpen, shouldShowTabs });

    return (
      <TabContext value={activeIdxStr}>
        <TabsContainer direction={isCompactLayout ? 'column' : 'row'} ref={ref}>
          <AppBreadcrumbsContainer>
            <RoutingBreadcrumbs
              didSelectLastBreadCrumb={() => {
                setIsOpen(true);
              }}
            />
          </AppBreadcrumbsContainer>
          {!isCompactLayout ? tabsDrawer : undefined}

          {renderControls && renderControls(activeIdx)}
        </TabsContainer>
        {isCompactLayout ? tabsDrawer : undefined}
        <RoutesContainer ref={routesContainerRef}>
          <RoutesCustom>{panels}</RoutesCustom>
        </RoutesContainer>
      </TabContext>
    );
  }
);
