import { Suspense, useState, useTransition } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  initialize,
  login,
  NewPasswordRequiredCode,
  useGQLUser,
  useIntlError,
  Roles,
  MembershipRoleNameEnum
} from '@beeriot/api-client';
import { LocationState, LoginForm, LoginFormFields } from '../../auth';
import { RootAuthPageStack } from './RootAuthPageStack';
import { useMutation } from 'react-query';
import { useNavigateCancellable } from '../../navigation';

export function LoginPage() {
  const [error, setError] = useState<string | undefined>();


  const location = useLocation();
  const locationState = location.state as LocationState | null;
  const  navigate  = useNavigate();
  const intlError = useIntlError();
  const [isTransitioning, startTransition] = useTransition();
  const u = useGQLUser();
  
  const loginMutation = useMutation(async (data: LoginFormFields) => {
   
    try {
      const userInfo = await login(data.email, data.password);
      const membership = await initialize(userInfo, startTransition);

      startTransition(() => {
        const companyath =  membership.roleName === MembershipRoleNameEnum.Admin || membership.roleName ===  MembershipRoleNameEnum.SuperUser ? '/companies' : `/companies/${membership?.companyId ?? ""}/sites`;

        const nextPath = locationState?.path !== location.pathname ? locationState?.path ?? companyath
        : companyath;
        navigate(nextPath, { replace: true});

      });
      
    } catch (loginErr) {
      if (loginErr === NewPasswordRequiredCode) {
        startTransition(() => {
          navigate('/setup', { replace: true});   
        }); 
      } else if (typeof loginErr === 'string') {
        setError(intlError(loginErr));
      } else {
        setError(intlError(''));
      }
    
    }
  },);
  
  return (
    <RootAuthPageStack>
      <LoginForm error={error} mutation={loginMutation} />
    </RootAuthPageStack>
  );
}
