import { useState } from 'react';
import { Button, Stack, styled } from '@mui/material';
import { ColorKey } from '@beeriot/api-client';
import { AppIcon } from '../../AppIcon';
import { AppTypography } from '../../AppTypography';
import { useFormatDate } from '../../../utils/formatUnits';
import { RangedAppDatePickerDialog } from './RangedAppDatePickerDialog';

const StyledStack = styled(Stack)({
  gap: '2rem',
  alignItems: 'center',
});

export interface RangedAppDatePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  onStartChange: (startDate: Date | null) => void;
  onEndChange: (endDate: Date | null) => void;
  colorKey?: ColorKey;
  labelPrefix?: string;
}

export function RangedAppDatePicker({
  startDate,
  endDate,
  onStartChange,
  onEndChange,
  colorKey = 'primary',
  labelPrefix,
}: RangedAppDatePickerProps) {
  const [open, setOpen] = useState(false);
  const formatDate = useFormatDate();
  return (
    <>
      <Button
        variant="contained"
        color={colorKey}
        onClick={() => setOpen(true)}
      >
        <StyledStack direction="row">
          <AppTypography>
            {labelPrefix ? `${labelPrefix} ` : null}
            {`${formatDate(startDate)} - ${formatDate(endDate)}`}
          </AppTypography>
          <AppIcon size="lg" icon="calendar-days" $colorKey="white" />
        </StyledStack>
      </Button>
      <RangedAppDatePickerDialog
        open={open}
        onClose={() => setOpen(false)}
        startDate={startDate}
        endDate={endDate}
        onStartChange={onStartChange}
        onEndChange={onEndChange}
        colorKey={colorKey}
      />
    </>
  );
}
