import { GlobalFooter, ScrollStack } from '../../base';
import { SuspenseCompanyCardList } from '../../companies';
import { useResponsiveSizes } from '../../utils';
import { LineLegendFooter } from '../LineLegendFooter';
import { RootPage } from '../RootPage';
import { CompaniesControlBox } from './CompaniesControlBox';

export function CompaniesPage() {
  const { isMobileSize } = useResponsiveSizes();
  return (
    <RootPage>
      <CompaniesControlBox />
      <ScrollStack>
        <SuspenseCompanyCardList />
        <GlobalFooter
          topFooterComponent={isMobileSize ? null : <LineLegendFooter />}
        />
      </ScrollStack>
    </RootPage>
  );
}
