import { ColorKey } from '@beeriot/api-client';
import { IconLabel } from '../../base/IconLabel';

interface PasswordConditionLabelProps {
  conditionMet: boolean;
  label: React.ReactNode;
  $colorKey?: ColorKey;
}

export function PasswordConditionLabel({
  conditionMet,
  label,
  $colorKey,
}: PasswordConditionLabelProps) {
  return (
    <IconLabel
      variant="subtitle1"
      colorKey={$colorKey ?? 'white'}
      bold={conditionMet ? true : false}
      icon={conditionMet ? 'check' : 'circle-small'}
      iconSize={conditionMet ? '1x' : 'xs'}
      label={label}
    />
  );
}
