import { FormattedMessage } from 'react-intl';
import { Stack, styled } from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import {
  execSummaryDate$,
  execSummaryViewingFilter$,
  execSummaryStore,
  CalendarMode,
} from '@beeriot/api-client';
import {
  AppBreadcrumbsContainer,
  ControlBox,
  CalendarModePicker,
  AppTypography,
  AppDatePicker,
  CombinedCalendarModePicker,
  RoutingBreadcrumbs,
} from '../../../../base';
import { useResponsiveSizes } from '../../../../utils/responsive-design/useResponsiveSizes';
import { transientOptions } from '../../../../utils/transientOptions';
import { dateToStartOfView } from '../../../../utils/calendarmode-utils';
import { parseISO } from 'date-fns';

const Container = styled(Stack)({
  width: '100%',
});

const StyledControlBox = styled(
  ControlBox,
  transientOptions
)({
  padding: '0.5rem 1rem',
  flexWrap: 'wrap',
  gap: '1rem',
});

export function ExecSummarySiteControlBox() {
  const { isMobileSize, isSmallTabletSize, isDesktopSize } =
    useResponsiveSizes();
  const [view] = useObservable(execSummaryViewingFilter$);
  const [dateString] = useObservable(execSummaryDate$);
  const date = parseISO(dateString);

  const setView = (viewingFilter: CalendarMode) => {
    execSummaryStore.update((state) => ({
      ...state,
      viewingFilter,
      date: dateToStartOfView(viewingFilter, date).toISOString(),
    }));
  };

  const setDate = (newDate: Date | null) => {
    if (newDate)
      execSummaryStore.update((state) => ({
        ...state,
        date: newDate.toISOString(),
      }));
  };

  return (
    <Container direction={isMobileSize || isSmallTabletSize ? 'column' : 'row'}>
      <AppBreadcrumbsContainer>
        <RoutingBreadcrumbs />
      </AppBreadcrumbsContainer>
      <StyledControlBox direction="row">
        {!isDesktopSize ? (
          <CombinedCalendarModePicker
            fullWidth
            calendarMode={view}
            value={date}
            onChange={setDate}
            onCalendarModeChange={setView}
          />
        ) : (
          <>
            <AppTypography $bold $colorKey="lightText">
              <FormattedMessage
                id="common-viewing-label"
                defaultMessage="Viewing"
                description="Label for viewing"
              />
            </AppTypography>
            <CalendarModePicker value={view} onChange={setView} />
            <AppDatePicker
              calendarMode={view}
              value={date}
              onChange={setDate}
            />
          </>
        )}
      </StyledControlBox>
    </Container>
  );
}
