import { PermissionKey } from '../PermissionKey';

export const customerAdminPermissionKeys = [
  PermissionKey.SitesList,
  PermissionKey.SitesListPreviewInfo,
  PermissionKey.SitesEditSites,

  PermissionKey.SitesOrganizeSites,
  PermissionKey.SitesOrganizeSitesAddSiteToRegionGroup,
  PermissionKey.SitesOrganizeSitesRemoveSiteFromRegionGroup,
  PermissionKey.SitesOrganizeSitesCreateRegionGroup,
  PermissionKey.SitesOrganizeSitesCreateRegionGroupType,
  PermissionKey.SitesOrganizeSitesDeleteRegionGroup,
  PermissionKey.SitesOrganizeSitesDeleteRegionGroupType,

  PermissionKey.SiteDetails,
  PermissionKey.SitesSiteOverviewSidebar,
  PermissionKey.SitesSiteOverviewToggleSidebar,
  PermissionKey.SitesSiteOverviewSidebarSelectAreas,
  PermissionKey.SitesSiteOverviewUpdateFiltrationInfo,
  PermissionKey.SitesSiteOverviewUpdateStabilizationInfo,
  PermissionKey.SitesSiteOverviewUpdatePackingInfo,
  PermissionKey.SitesSiteOverviewUpdateBeerRecoveryInfo,

  PermissionKey.SitesPROFiLineStatus,
  PermissionKey.SitesPROFiLineStatusDetailsCard,
  PermissionKey.SitesPROFiLineStatusWidget,
  PermissionKey.SitesPROFiLineStatusWidgetHide,

  PermissionKey.SitesPROFiStatistics,
  PermissionKey.SitesPROFiStatisticsDataExport,

  PermissionKey.SitesPROFiFilterRuns,
  PermissionKey.SitesPROFiFilterRunsDataExport,

  PermissionKey.SitesPROFiDocumentation,
  PermissionKey.SitesPROFiDocumentationAdd,
  PermissionKey.SitesPROFiDocumentationDelete,
  PermissionKey.SitesPROFiReports,
  PermissionKey.SitesPROFiReportsAdd,
  PermissionKey.SitesPROFiClusters,
  PermissionKey.SitesPROFiBrandEdit,
  PermissionKey.SitesPROFiMembraneInfo,
  PermissionKey.SitesPROFiSystemEdit,

  PermissionKey.SitesCBSLineStatus,
  PermissionKey.SitesCBSLineStatusDetailsCard,
  PermissionKey.SitesCBSLineStatusWidget,
  PermissionKey.SitesCBSLineStatusWidgetHide,

  PermissionKey.SitesCBSStatistics,
  PermissionKey.SitesCBSStatisticsDataExport,

  PermissionKey.SitesCBSDocumentation,
  PermissionKey.SitesCBSDocumentationAdd,
  PermissionKey.SitesCBSDocumentationDelete,
  PermissionKey.SitesCBSReports,
  PermissionKey.SitesCBSReportsAdd,
  PermissionKey.SitesCBSBrandEdit,
  PermissionKey.SitesCBSSystemEdit,

  PermissionKey.SitesCFSLineStatus,
  PermissionKey.SitesCFSLineStatusDetailsCard,
  PermissionKey.SitesCFSLineStatusWidget,
  PermissionKey.SitesCFSLineStatusWidgetHide,
  PermissionKey.SitesCFSFilterRuns,
  PermissionKey.SitesCFSStatistics,
  PermissionKey.SitesCFSStatisticsDataExport,
  PermissionKey.SitesCFSDocumentation,
  PermissionKey.SitesCFSDocumentationAdd,
  PermissionKey.SitesCFSDocumentationDelete,
  PermissionKey.SitesCFSReports,
  PermissionKey.SitesCFSReportsAdd,
  PermissionKey.SitesCFSSystemEdit,

  PermissionKey.SitesKerafluxLineStatus,
  PermissionKey.SitesKerafluxLineStatusDetailsCard,
  PermissionKey.SitesKerafluxLineStatusWidget,
  PermissionKey.SitesKerafluxLineStatusWidgetHide,
  PermissionKey.SitesKerafluxDocumentation,
  PermissionKey.SitesKerafluxDocumentationAdd,
  PermissionKey.SitesKerafluxDocumentationDelete,
  PermissionKey.SitesKerafluxBatchInfo,
  PermissionKey.SitesKerafluxReports,
  PermissionKey.SitesKerafluxReportsAdd,
  PermissionKey.SitesKerafluxStatistics,
  PermissionKey.SitesKerafluxStatisticsDataExport,
  PermissionKey.SitesKerafluxSystemEdit,

  PermissionKey.ExecutiveSummary,

  PermissionKey.ExecutiveSummaryMachine,
  PermissionKey.ExecutiveSummaryMachineTimeframeSelection,
  PermissionKey.ExecutiveSummaryMachineDataExport,

  PermissionKey.ExecutiveSummaryMembraneBlock,
  PermissionKey.ExecutiveSummaryMembraneBlockDataExport,
  PermissionKey.ExecutiveSummaryMembraneBlockEquipmentEffectiveGraph,

  PermissionKey.ExecutiveSummaryCompareLines,
  PermissionKey.ExecutiveSummaryCompareLinesComparedGraphsDataExport,
  PermissionKey.ExecutiveSummaryCompareLinesBeerProductionGraphDataExport,
  PermissionKey.ExecutiveSummaryCompareLinesFilterByLines,
  PermissionKey.ExecutiveSummaryCompareBrands,

  PermissionKey.Analytics,
  PermissionKey.AnalyticsExportData,

  PermissionKey.AlertWidget,

  PermissionKey.AlertHistory,
  PermissionKey.AlertHistoryDetails,
  PermissionKey.AlertHistoryDismiss,

  PermissionKey.Settings,

  PermissionKey.SettingsAccount,
  PermissionKey.SettingsAccountEdit,

  PermissionKey.SettingsUserManagement,
  PermissionKey.SettingsUserManagementAddUser,

  PermissionKey.SettingsAlerts,
  PermissionKey.SettingsAlertsEdit,

  PermissionKey.SettingsReporting,
  PermissionKey.SettingsReportingCreate,
  PermissionKey.SettingsReportingEdit,
  PermissionKey.SettingsReportingDelete,

  PermissionKey.SettingsChangePassword,

  PermissionKey.SettingsLocalUtilityCosts,
  PermissionKey.SettingsLocalUtilityCostsEdit,
  PermissionKey.SettingsLocalUtilityBillingUnitsEdit,

  PermissionKey.SettingsIoTStatus,

  PermissionKey.SettingsPallNotifications,
  PermissionKey.SettingsPallNotificationsSelectRoles,

  PermissionKey.SettingsBeerTypes,
  PermissionKey.SettingsBeerTypesDelete,
  PermissionKey.SettingsBeerTypesAdd,

  PermissionKey.SettingsProvisioning,
  PermissionKey.SettingsProvisioningStartSetup,
  PermissionKey.SettingsCertificateProvisioning,
  PermissionKey.SettingsCertificateProvisioningGenerate,

  PermissionKey.SettingsThresholds,
  PermissionKey.SettingsThresholdsEdit,
  PermissionKey.SettingsThresholdsConsumptionCalulationEdit,
  PermissionKey.SettingsThresholdsCloneSettings,

  PermissionKey.SettingsPushNotifications,
];
