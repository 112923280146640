import { FormattedMessage, useIntl } from 'react-intl';
import { CardContent, Grid, Stack, styled } from '@mui/material';
import { format } from 'date-fns';
import { GQLConsumable, GQLSystem } from '@beeriot/api-client';
import {
  AppTypography,
  AppCard,
  RunningStatusTimelineView,
  VerticalLabels,
} from '../../../base';
import { RunningState } from '../../../enums/RunningState';
import { getEnumFromString, useFormatMonth } from '../../../utils';

const TimelineContainer = styled(Stack)({
  padding: '0.25rem 0 0 0',
  width: '100%',
});

const StackContainer = styled(Stack)({
  gap: '1rem',
});

const StyledTypography = styled(AppTypography)({
  marginBottom: '0.5rem',
});

export interface KerafluxMembraneModuleCardProps {
  system: GQLSystem;
  consumables: GQLConsumable[];
}

export function KerafluxMembraneModuleCard({
  system,
  consumables,
}: KerafluxMembraneModuleCardProps) {
  const formatMonth = useFormatMonth();
  const intl = useIntl();
  const NA = intl.formatMessage({
    id: 'common-not-applicable',
    defaultMessage: 'N/A',
    description: "Label for when there isn't a value to display",
  });
  const consumable =
    consumables && consumables.length > 0 ? consumables[0] : undefined;

  return (
    <AppCard>
      <CardContent>
        <StackContainer>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <VerticalLabels
                label={
                  consumable?.productNumber ? consumable.productNumber : NA
                }
                labelBold
                subLabel={
                  <FormattedMessage
                    id="common-product-no"
                    description="Label for abbreviated product number"
                    defaultMessage="Product No"
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              <VerticalLabels
                label={formatMonth(consumable?.liveStatus?.installationTime)}
                labelBold
                subLabel={
                  <FormattedMessage
                    id="common-installed-date"
                    description="Label for installed date"
                    defaultMessage="Installed Date"
                  />
                }
              />
            </Grid>
          </Grid>
          <TimelineContainer>
            {system.liveStatus?.recentSystemStatus &&
            system.liveStatus.recentSystemStatus.length > 0 ? (
              <RunningStatusTimelineView
                data={system.liveStatus.recentSystemStatus.map((x, idx) => ({
                  status: getEnumFromString(RunningState, x?.status),
                  magnitude: x?.percent ?? 0,
                  id: idx.toString(),
                }))}
              />
            ) : (
              <StyledTypography $bold variant="subtitle2">
                {NA}
              </StyledTypography>
            )}
            <AppTypography variant="subtitle2" $colorKey="lightText">
              <FormattedMessage
                id="common-running-status-label"
                description="Label for running status"
                defaultMessage="Running Status"
              />
            </AppTypography>
          </TimelineContainer>
        </StackContainer>
      </CardContent>
    </AppCard>
  );
}
