import { styled, Stack } from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';
import { RouteTabLink, RouteTabLinkProps } from '../tabs/RouteTabLink';

const StyledMobileTabsButton = styled(RouteTabLink)(({ theme }) => ({
  '&&': {
    color: theme.palette.disabledPrimary.main,
    '&.Mui-selected': {
      color: theme.palette.disabled.contrastText,
    },
    width: '100%',
    alignItems: 'start',
    fontSize: theme.typography.h3.fontSize,
    fontWeight: 400,
    padding: '1rem 1.5rem',
    maxWidth: 'unset',
  },
}));
StyledMobileTabsButton.displayName = 'StyledMobileTabsButton';

const MobileTabsButtonWrapper = styled(Stack)({
  width: '100%',
});

interface MobileTabsButtonProps extends RouteTabLinkProps {
  onClick: () => void;
}

export const MobileTabsButton = forwardRef(
  (props: RouteTabLinkProps, ref: ForwardedRef<HTMLAnchorElement>) => {
    return (
      <MobileTabsButtonWrapper onClick={props.onClick}>
        <StyledMobileTabsButton ref={ref} {...props} />
      </MobileTabsButtonWrapper>
    );
  }
);
MobileTabsButton.displayName = 'MobileTabsButton';
