import { Variant } from '@mui/material/styles/createTypography';
import { ClusterLiveStatusTestResultEnum, ColorKey } from '@beeriot/api-client';
import { AppTypography } from './AppTypography';
import { useIntlTestResult } from '../enums';
import { ThresholdValue } from '../utils/threshold-color/thresholdValue';

export interface TestResultLabelProps {
  getThresholdColor: (
    field: ThresholdValue,
    value: string | number | boolean | null | undefined
  ) => ColorKey;
  result?: ClusterLiveStatusTestResultEnum | '-';
  labelBold?: boolean;
  variant?: Variant;
}

export const TestResultLabel = ({
  getThresholdColor,
  result,
  labelBold,
  variant = 'body1',
}: TestResultLabelProps) => {
  const intlTestResult = useIntlTestResult();
  const labelColor = getThresholdColor(ThresholdValue.Cluster, result);
  return (
    <AppTypography $bold={labelBold} variant={variant} $colorKey={labelColor}>
      {result === '-' ? result : intlTestResult(result)}
    </AppTypography>
  );
};
