import { select } from '@ngneat/elf';
import { shareReplay } from 'rxjs/operators';
import { distinctUntilChangedObject } from '../../utils';
import { companyStore } from './companyStore';
import { groupCompaniesByLetterOperator } from './companyStore.operators';

export const activeCompany$ = companyStore.pipe(
  select((state) => {
    return state.activeCompany;
  }),
  distinctUntilChangedObject()
);

export const companySearch$ = companyStore.pipe(
  select((state) => {
    return state.companySearch;
  })
);

export const companies$ = companyStore.pipe(
  select((state) => {
    return state.companies;
  })
);

export const groupedCompanies$ = companyStore.pipe(
  select((state) => {
    return state.companies;
  }),
  distinctUntilChangedObject(),
  groupCompaniesByLetterOperator(),
  shareReplay(1)
);
