import { useMemo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  SectionControl,
  SectionLabel,
  TitleHeader,
  SectionTextInput,
} from '../settings-form';
import { meetsPasswordRequirements } from '../../auth/password-conditions';
import { ChangePasswordFormFields } from './ChangePasswordFormFields';

interface PasswordControlsProps {
  showPassword: boolean;
}

export const PasswordControls = ({ showPassword }: PasswordControlsProps) => {
  const intl = useIntl();
  const { watch, getValues, trigger } =
    useFormContext<ChangePasswordFormFields>();

  const password: string = watch('password');
  const passwordVerify: string = watch('passwordVerify');
  const inputType = useMemo(
    () => (showPassword ? 'text' : 'password'),
    [showPassword]
  );

  const fieldRequiredLabel = intl.formatMessage({
    id: 'common-field-required-message',
    defaultMessage: 'This field is required.',
    description: 'label for required field',
  });

  useEffect(() => {
    password !== '' &&
      passwordVerify !== '' &&
      trigger(['password', 'passwordVerify']);
  }, [trigger, password, passwordVerify]);

  const validatePasswordsForPassword = useMemo(
    () => ({
      required: fieldRequiredLabel,
      validate: () => {
        const vals = getValues();
        return meetsPasswordRequirements(
          vals['password'],
          vals['passwordVerify']
        );
      },
    }),
    [getValues, fieldRequiredLabel]
  );

  const validatePasswordsForVerifyPassword = useMemo(
    () => ({
      required: fieldRequiredLabel,
      validate: () => {
        const vals = getValues();
        return meetsPasswordRequirements(
          vals['passwordVerify'],
          vals['password']
        );
      },
    }),
    [getValues, fieldRequiredLabel]
  );

  return (
    <Stack>
      <TitleHeader>
        <FormattedMessage
          id="settings-change-password-header-title"
          defaultMessage="Change Password"
          description="label for Change Password header"
        />
      </TitleHeader>
      <SectionControl>
        <SectionLabel>
          <FormattedMessage
            id="settings-change-password-new-password-label"
            defaultMessage="New Password"
            description="label for New Password"
          />
        </SectionLabel>
        <SectionTextInput
          name="password"
          type={inputType}
          placeholder={intl.formatMessage({
            id: 'settings-change-password-new-password-placeholder',
            defaultMessage: 'Enter New Password',
            description: 'label for New Password placeholder',
          })}
          validationOptions={validatePasswordsForPassword}
        />
      </SectionControl>
      <SectionControl>
        <SectionLabel>
          <FormattedMessage
            id="settings-change-password-confirm-new-password-label"
            defaultMessage="Confirm New Password"
            description="label for Confirm New Password"
          />
        </SectionLabel>
        <SectionTextInput
          name="passwordVerify"
          type={inputType}
          placeholder={intl.formatMessage({
            id: 'settings-change-password-confirm-new-password-placeholder',
            defaultMessage: 'Re-enter New Password',
            description: 'label for  New Password Verify placeholder',
          })}
          validationOptions={validatePasswordsForVerifyPassword}
        />
      </SectionControl>
    </Stack>
  );
};
