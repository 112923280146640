import { useGQLSystemLiveStatus } from '@beeriot/api-client';
import { useResponsiveValues } from '../../../utils/responsive-design/useResponsiveSizes';
import { SystemKpiGridAreaTemplate } from '../base-card-common/grids/SystemKpiHookProps';
import {
  SystemCardBase,
  SystemCardBaseProps,
} from '../base-card-common/SystemCardBase';

const templateArea: SystemKpiGridAreaTemplate = [
  ['titleHeader', 'titleHeader', 'titleHeader'],
  ['runningState', 'brand', 'empty1'],
  ['timeSinceStart', 'timeRemaining', 'percentCompleteIndicator'],
  ['batchSize', 'remainingVolume', 'percentCompleteIndicator'],
];

const mobileTemplateArea: SystemKpiGridAreaTemplate = [
  ['mobileTitleHeader', 'mobileTitleHeader', 'mobileTitleHeader'],
  ['brand', 'brand', 'brand'],
  ['timeSinceStart', 'timeRemaining', 'percentCompleteIndicator'],
  ['batchSize', 'remainingVolume', 'percentCompleteIndicator'],
  ['runningStatusTimeline', 'runningStatusTimeline', 'runningStatusTimeline'],
];

export const CBSSystemDetailCardBase = (props: SystemCardBaseProps) => {
  const { boldLabelTitles = false, system } = props;
  const gridTemplateAreas = useResponsiveValues<SystemKpiGridAreaTemplate>({
    xxs: mobileTemplateArea,
    md: templateArea,
  });
  useGQLSystemLiveStatus(system);
  return (
    <SystemCardBase
      {...props}
      boldLabelTitles={boldLabelTitles}
      gridTemplateAreas={props.gridTemplateAreas ?? gridTemplateAreas ?? []}
    />
  );
};
CBSSystemDetailCardBase.displayName = 'CBSSystemDetailCardBase';
