import { useContext, useMemo } from 'react';
import {
  UNSAFE_RouteContext,
  useNavigate,
  NavigateOptions,
  RouteMatch,
  RouteObject,
  matchPath,
  useLocation,
  NavigateFunction,
  To,
} from 'react-router';
import {
  from,
  firstValueFrom,
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  of,
  asyncScheduler,
} from 'rxjs';
import { filter, map, switchMap, take, throttleTime } from 'rxjs/operators';
import { useMemoAsync } from '../utils/hooks';
import { appRouterRef } from '../base/AppProvider';
import { useDebounce } from 'use-debounce';
import equal from 'fast-deep-equal';

export const useRouteContext = () => {
  const ctx = useContext(UNSAFE_RouteContext);
  return ctx;
};

export const useHasRenderedMatchAtPath = ({ path }: { path: string }) => {
  const { matches } = useContext(UNSAFE_RouteContext);
  const location = useLocation();

  const matchedPath = useMemo(() => {
    const found = matches.find((x) => {
      return x.route.path === path;
    });
    if (!found) {
      const match = matchPath(path, location.pathname);
      if (match) return match;
    }
    return found;
  }, [matches, location.pathname, path]);

  const [val] = useDebounce(matchedPath, 30, {equalityFn: equal})

  return val;
};

export const useIsSitesListPage = () =>
  useHasRenderedMatchAtPath({
    path: 'companies/:companyId/sites',
  });
export const useIsCompaniesPage = () =>
  useHasRenderedMatchAtPath({ path: 'companies' });
export const useIsSitesPage = () =>
  useHasRenderedMatchAtPath({
    path: 'companies/:companyId/sites/*',
  });
export const useIsExecSummaryPage = () =>
  useHasRenderedMatchAtPath({
    path: 'companies/:companyId/exec-summary/*',
  });

export const useIsSettingsPage = () =>
  useHasRenderedMatchAtPath({
    path: 'companies/:companyId/settings/*',
  });

export const useIsSitesDetailsPage = () => {
  const { matches } = useRouteContext();
  const isSitesPage = useIsSitesPage();

  const last = matches[matches.length - 1];
  const result = !last?.route?.index ? last : undefined;
  const [val] = useDebounce(result, 100, {equalityFn: equal});
  if (isSitesPage) {
    if (
      !isSitesPage.pathname.endsWith('/sites') &&
      !isSitesPage.pathname.endsWith('/sites/')
    ) {
      return val;
    }
  }
  return undefined;
};

export const useIsSystemDetailsPage = () => {
  const isSitesPage = useIsSitesPage();
  const pathname = isSitesPage?.pathname ? isSitesPage?.pathname : undefined;
  const match = useMemo(() => {
      if (pathname && pathname.indexOf('/lines/') > -1) {
        return {
          isSystemDetailsPage: true,
          isLineStatus: pathname.indexOf('line-status') > -1,
          isStats: pathname.indexOf('statistics') > -1,
          isFilterRuns: pathname.indexOf('filter-runs') > -1,
          isDocumentation: pathname.indexOf('documentation') > -1,
          isReports: pathname.indexOf('reports') > -1,
        };
      }
    
    return {
      isSystemDetailsPage: false,
      isLineStatus: false,
      isStats: false,
      isFilterRuns: false,
      isDocumentation: false,
      isReports: false,
    };
  }, [pathname])
  
  const [val] =useDebounce(match, 100, {equalityFn: equal});
  return val
};

export function useIsExecSummaryRoute(
  matches: RouteMatch<string, RouteObject>[]
) {
  return useMemoAsync(async () => {
    const worker = from(matches).pipe(
      filter((x) => {
        const isExec = x?.route?.path?.indexOf('exec-summary') !== -1 ?? false;
        return isExec;
      }),
      map((x) => true)
    );
    try {
      const res = await firstValueFrom(worker);
      return res;
    } catch (err) {
      return false;
    }
  }, [matches]);
}

type NavigateCancellableParams = {
  delta?: number;
  to?: string;
  options?: NavigateOptions;
};
type NavigateCancellableSubjectParams = NavigateCancellableParams & {
  cancelObs: Observable<true | null>;
};
const navigateFunc$$ = new BehaviorSubject<NavigateFunction | null>(null);
const navigate$$ = new Subject<NavigateCancellableSubjectParams>();
const navigateWorker$ = navigate$$.pipe(
  switchMap((x) => {
    return combineLatest({
      _navigate: navigateFunc$$,
      params: of(x),
      cancelled: x.cancelObs,
    });
  }),
  throttleTime(10, asyncScheduler, { leading: true, trailing: true }),
  map(({ _navigate, params, cancelled }) => {
    if (!cancelled && params && _navigate) {
      if (params.to) {
        _navigate(params.to, params.options);
      } else if (params.delta) {
        _navigate(params.delta);
      }
    }
  })
);

let lastCancel$$: BehaviorSubject<true | null> | undefined;
export const useNavigateCancellable = () => {
  const cancel$$ = new BehaviorSubject<true | null>(null);
  const navigate = appRouterRef.router.navigate.bind(appRouterRef.router);

  return {
    navigate: ((...args: unknown[]) => {
      let to: To | undefined;
      let options: NavigateOptions | undefined;
      let delta: number | undefined;
      if (args[0] && typeof args[0] === 'string') {
        to = args[0];
        options = args.length > 1 ? (args[1] as NavigateOptions) : undefined;
      } else if (args[0] && typeof args[0] === 'number') {
        delta = args[0];
      }
      (async () => {
        if (lastCancel$$) {
          lastCancel$$.next(true);
        }
        lastCancel$$ = cancel$$;

        navigateWorker$.pipe(take(1)).subscribe();
        navigateFunc$$.next(navigate);
        navigate$$.next({
          to: to as string | undefined,
          options,
          delta,
          cancelObs: cancel$$,
        });
      })();
    }) as unknown as NavigateFunction,
    cancel$$,
  };
};
