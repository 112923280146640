export const fullDateFormats = [
  'yyyy-MM-dd',
  'MMM dd yyyy',
  'MMMM dd, yyyy',
  'dd MMM yyyy',
  'yyyy/MM/dd',
  'd/M/yy',
  'M/d/yy',
  'dd/MM/yyyy',
  'MM/dd/yyyy',
  'd-M-yy',
  'M-d-yy',
  'dd-MM-yyyy',
  'MM-dd-yyyy',
] as const;

export const monthYearFormats = [
  'MMM yyyy',
  'MMMM yyyy',
  'M-yy',
  'yy-M',
  'yyyy-MM',
  'MM-yyyy',
  'MM/yyyy',
] as const;
