import { createStore, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { Area } from '../../generated/api';
import { GQLCompanySite } from '../../generated/gql';
import { AreaSystem } from '../../lib/interfaces/AreaSystem';

export interface SiteProps {
  activeSite: GQLCompanySite | null;
  activeAreaId: string | null;
  siteSearch: string;
  groupBy: string;
  group: string;
  unfilteredSitesCount?: number;
  filteredSitesCount?: number;

  sites: GQLCompanySite[] | null;
  areas: Area[] | null;
  areaSystems: AreaSystem[] | null;
}
export const siteStore = createStore(
  { name: 'site' },
  withProps<SiteProps>({
    activeSite: null,
    activeAreaId: null,
    siteSearch: '',
    groupBy: 'alphabetical',
    group: '',
    sites: null,
    areas: null,
    areaSystems: null,
  })
);

persistState(siteStore, {
  key: 'site',
  storage: sessionStorageStrategy,
});
