import { Grid, Stack, styled } from '@mui/material';
import { GQLCompanySite, Group } from '@beeriot/api-client';
import { AddSiteButton } from '../AddSiteButton';
import { SiteDeletionCard } from './SiteDeletionCard';
import { PermissionView, PermissionKey } from '../../../base';

const GridContainer = styled(Stack)({
  alignItems: 'center',
  margin: '0.5rem 1rem',
});

const StyledGridItem = styled(Grid)({
  alignItems: 'center',
});

export interface GroupListItemGridProps {
  group: Group;
  sites: GQLCompanySite[];
}

export function GroupSitesGrid({ group, sites }: GroupListItemGridProps) {
  return (
    <GridContainer direction={'row'}>
      <Grid container spacing={2}>
        {sites.map((site) => (
          <StyledGridItem
            item
            key={site.id}
            xs={12}
            md={6}
            lg={4}
            {...{ xl: 3, xxxl: 2 }}
          >
            <SiteDeletionCard site={site} group={group} />
          </StyledGridItem>
        ))}

        <PermissionView
          permissionKey={PermissionKey.SitesOrganizeSitesAddSiteToRegionGroup}
        >
          <StyledGridItem
            item
            key="add-site"
            xs={12}
            md={6}
            lg={4}
            {...{ xl: 3, xxxl: 2 }}
          >
            <AddSiteButton group={group} />
          </StyledGridItem>
        </PermissionView>
      </Grid>
    </GridContainer>
  );
}
