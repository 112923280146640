import { ReactElement, useCallback, useMemo } from 'react';
import { Property } from 'csstype';
import { StyledMiniDrawer } from './StyledMiniDrawer';
import { AppSidebarDrawerHeader } from './AppSidebarDrawerHeader';
import { useResponsiveSizes } from '../../utils/responsive-design/useResponsiveSizes';
import { DrawerType } from '../../enums';
import { useObservable } from '@ngneat/use-observable';
import { drawerStates$, uiStore } from '../../ui-store';

interface AppDrawerProps {
  type: DrawerType;
  headerLabel?: string;
  openHeaderLabelOverride?: ReactElement;
  drawerContent: ReactElement;
  hideToggleButton?: boolean;
  anchor?: 'left' | 'right';
  $width?: Property.Width;
}

export function AppDrawer({
  type,
  headerLabel,
  openHeaderLabelOverride,
  drawerContent,
  hideToggleButton,
  $width,
  anchor,
}: AppDrawerProps) {
  const [drawerStates] = useObservable(drawerStates$);
  const isOpen = useMemo(() => drawerStates[type], [drawerStates, type]);
  const setOpen = useCallback(
    (open: boolean) => {
      uiStore.update((state) => {
        const newDrawerStates = { ...drawerStates };
        newDrawerStates[type] = open;
        return { ...state, drawerStates: newDrawerStates };
      });
    },
    [drawerStates, type]
  );

  const { isDesktopSize } = useResponsiveSizes();

  return !isDesktopSize ? null : (
    <StyledMiniDrawer
      variant="permanent"
      anchor={anchor}
      open={isOpen}
      $width={$width}
    >
      {headerLabel && (
        <AppSidebarDrawerHeader
          isOpen={isOpen}
          hideToggleButton={hideToggleButton}
          toggleOpen={() => setOpen(!isOpen)}
          headerLabel={headerLabel}
          anchor={anchor}
          openHeaderLabelOverride={openHeaderLabelOverride}
        />
      )}
      {isOpen && drawerContent}
    </StyledMiniDrawer>
  );
}
