import { useEffect, useState } from 'react';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar,
  Line,
  ComposedChart,
} from 'recharts';
import { useCurrentPng } from 'recharts-to-png';
import { styled } from '@mui/material';
import { GraphModel } from './report-store';
import { ChartElementType } from './ChartElementType';

const xAxisLabelOffset = 25;
const yAxisLabelOffset = 45;
const textHeight = 50;

const StyledChart = styled(ComposedChart)({
  '.recharts-legend-item-text': { margin: '0 30px 0 15px' },
});

interface GraphRenderProps {
  model: GraphModel;
  onRenderComplete: (png: string) => void;
}

export function GraphRender({ model, onRenderComplete }: GraphRenderProps) {
  const [xAxisHeight, setXAxisHeight] = useState<number | undefined>();
  const [yAxisWidth, setYAxisWidth] = useState<number | undefined>();
  const [renderComplete, setRenderComplete] = useState(false);
  const [getPng, { ref }] = useCurrentPng({ backgroundColor: null });

  useEffect(() => {
    if (renderComplete) {
      return;
    }

    const xAxisBB = document.querySelector('.xAxis')?.getBoundingClientRect();
    const yAxisBB = document.querySelector('.yAxis')?.getBoundingClientRect();
    const xAxisText = document
      .querySelector('.xAxis .recharts-label')
      ?.getBoundingClientRect();
    const yAxisText = document
      .querySelector('.yAxis .recharts-label')
      ?.getBoundingClientRect();

    if (!xAxisBB || !yAxisBB) {
      return;
    }

    if (!xAxisHeight || !yAxisWidth) {
      const yAxisLabelWidth = yAxisText?.width
        ? yAxisText.width + yAxisLabelOffset + textHeight
        : 0;
      const xAxisLabelHeight = xAxisText?.height
        ? xAxisText.height + xAxisLabelOffset
        : 0;
      setYAxisWidth(yAxisBB.width + yAxisLabelWidth);
      setXAxisHeight(xAxisBB.height + xAxisLabelHeight);
      return;
    }

    if (xAxisBB.left < 0) {
      setYAxisWidth(yAxisWidth + Math.abs(xAxisBB.left));
    }

    const updatePngData = async () => {
      const png = await getPng();
      if (png) onRenderComplete(png);
    };

    updatePngData().catch(console.error);
    setRenderComplete(true);
  }, [
    getPng,
    model.chartElements,
    model.renderedPng,
    onRenderComplete,
    renderComplete,
    xAxisHeight,
    yAxisWidth,
  ]);

  if (model.renderedPng) return null;

  return (
    <StyledChart
      height={model.graphHeight}
      width={model.graphWidth}
      data={model.data}
      ref={ref}
      style={{
        fontSize: '45px',
      }}
      margin={{ top: 50 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="name"
        label={{
          value: model.xAxisLabel,
          position: 'insideBottom',
          offset: xAxisLabelOffset,
        }}
        height={xAxisHeight}
        tickMargin={15}
        angle={model.xAxisTickAngle}
        ticks={model.xAxisTicks}
        textAnchor={model.xAxisTickAnchor ?? 'middle'}
      />
      <YAxis
        yAxisId={'left'}
        label={{
          value: model.yAxisLabel,
          angle: -90,
          position: 'insideLeft',
          offset: yAxisLabelOffset,
        }}
        width={yAxisWidth}
      />
      {model.rightYAxisLabel && (
        <YAxis
          yAxisId={'right'}
          orientation="right"
          label={{
            value: model.rightYAxisLabel,
            angle: -90,
            position: 'right',
            offset: yAxisLabelOffset,
          }}
          width={yAxisWidth}
        />
      )}
      <Legend
        iconSize={50}
        layout="vertical"
        verticalAlign="top"
        align="right"
        wrapperStyle={{ paddingLeft: '75px' }}
      />
      {model.chartElements?.map((x) => {
        if (x.type === ChartElementType.Line) {
          return (
            <Line
              dot={false}
              isAnimationActive={false}
              yAxisId={x.yAxis ?? 'left'}
              key={x.dataKey}
              dataKey={x.dataKey}
              stroke={x.color}
              strokeWidth={10}
            />
          );
        }
        if (x.type === ChartElementType.Bar) {
          return (
            <Bar
              isAnimationActive={false}
              yAxisId={x.yAxis ?? 'left'}
              key={x.dataKey}
              dataKey={x.dataKey}
              fill={x.color}
              // TODO: determine dynamically based on element count
              barSize={25}
            />
          );
        }
        return null;
      })}
    </StyledChart>
  );
}
