import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { QueryErrorResetBoundary } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { styled } from '@mui/material';
import { IconLabel, LoadingPaper } from '../../base';
import { SectionedSitesListQueryProps } from './SectionedSitesListQuery';

const SiteDropdownLoadingPaper = styled(LoadingPaper)({
  height: '2rem',
  width: '15rem',
  margin: '0.5rem',
});

const SectionedSitesList = lazy(() =>
  import('./SectionedSitesListQuery').then(({ SectionedSitesListQuery }) => ({
    default: SectionedSitesListQuery,
  }))
);

export function SuspenseSectionedSiteList({
  didSelectSite,
}: SectionedSitesListQueryProps) {
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <IconLabel
                colorKey="white"
                label={
                  <FormattedMessage
                    id="sites-dropdown-error-label"
                    description="Error message for sites dropdown error"
                    defaultMessage="Sites Unavailable"
                  />
                }
              />
            );
          }}
        >
          <Suspense fallback={<SiteDropdownLoadingPaper />}>
            <SectionedSitesList didSelectSite={didSelectSite} />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
