import { styled } from '@mui/material';
import { AppTypography } from '../../../base/AppTypography';

const SectionVerticalLabel = styled(AppTypography)(({ theme }) => ({
  padding: '1rem 1rem 0 1rem',
}));

SectionVerticalLabel.defaultProps = {
  variant: 'subtitle1',
  $colorKey: 'lightText',
  $bold: true,
};

export { SectionVerticalLabel };
