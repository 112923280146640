import { styled, CircularProgress } from '@mui/material';
import { ColorKey } from '@beeriot/api-client';
import { transientOptions } from '../utils/transientOptions';

interface AppCircularProgressProps {
  $colorKey?: ColorKey;
}

export const AppCircularProgress = styled(
  CircularProgress,
  transientOptions
)<AppCircularProgressProps>(({ theme, $colorKey }) => {
  const paletteColor = $colorKey ?? 'darkText';
  return {
    color: theme.palette[paletteColor]?.main,
  };
});
