import { useIntl } from 'react-intl';
import { Stack, styled } from '@mui/material';
import {
  FormFooterButtons,
  FormFooterButtonsProps,
} from '../../form/FormFooterButtons';

export const SettingsButtonContainer = styled(Stack)({
  padding: '1rem',
});

export const SettingsFooterButtons = ({
  submitLabel,
  showCancelButton = true,
  requireCancelConfirmation = true,
  justify = 'center',
  ...formFooterButtonProps
}: FormFooterButtonsProps) => {
  const intl = useIntl();
  const defaultSubmitLabel = submitLabel
    ? submitLabel
    : intl.formatMessage({
        id: 'settings-common-footer-submit-button',
        defaultMessage: 'Save Settings',
        description: 'message for settings submit footer button',
      });

  return (
    <SettingsButtonContainer>
      <FormFooterButtons
        {...formFooterButtonProps}
        submitLabel={defaultSubmitLabel}
        showCancelButton={showCancelButton}
        requireCancelConfirmation={requireCancelConfirmation}
        justify={justify}
      />
    </SettingsButtonContainer>
  );
};
