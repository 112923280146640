import { styled } from '@mui/material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import {
  startOfWeek,
  endOfWeek,
  isWithinInterval,
  isSameDay,
  getMonth,
  isFirstDayOfMonth,
  addDays,
} from 'date-fns';
import { CalendarMode } from '@beeriot/api-client';
import { transientOptions } from '../../utils';

// CustomPickerDayProps, CustomPickersDay & renderWeekPickerDay code from MUI DatePicker docs: https://mui.com/components/date-picker/#customized-day-rendering
type CustomPickerDayProps = PickersDayProps<Date> & {
  $dayIsBetween?: boolean;
  $isFirstDay?: boolean;
  $isLastDay?: boolean;
};

const CustomPickersDay = styled(
  PickersDay,
  transientOptions
)<CustomPickerDayProps>(
  ({ theme, $dayIsBetween, $isFirstDay, $isLastDay }) => ({
    ...($dayIsBetween && {
      borderRadius: 0,
      backgroundColor: theme.palette['primary'].main,
      color: theme.palette['white'].main,
      '&:hover, &:focus': {
        backgroundColor: theme.palette['secondary'].main,
      },
    }),
    ...($isFirstDay && {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    }),
    ...($isLastDay && {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    }),
  })
) as React.ComponentType<CustomPickerDayProps>;

export function dayRenderer(
  calendarMode: CalendarMode,
  value: Date | null
): (
  day: Date,
  _selectedDays: Date[],
  pickersDayProps: PickersDayProps<Date>
) => JSX.Element {
  return (
    day: Date,
    _selectedDates: (Date | null)[],
    pickersDayProps: PickersDayProps<Date>
  ) => {
    if (!value || !day || calendarMode !== CalendarMode.Week) {
      return <CustomPickersDay {...pickersDayProps} disableMargin />;
    }
    const isFirstOfYear = isFirstDayOfMonth(value) && getMonth(value) === 0;
    const start = isFirstOfYear ? value : startOfWeek(new Date(value));
    const end = isFirstOfYear ? addDays(value, 6) : endOfWeek(new Date(value));

    const dayIsBetween = isWithinInterval(day, { start, end });
    const isFirstDay = isSameDay(day, start);
    const isLastDay = isSameDay(day, end);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        $dayIsBetween={dayIsBetween}
        $isFirstDay={isFirstDay}
        $isLastDay={isLastDay}
      />
    );
  };
}
