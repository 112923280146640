import { styled, Stack } from '@mui/material';
import { useMemo } from 'react';
import { ColorKey } from '@beeriot/api-client';
import { RunningState, RunningStateColorKey } from '../enums/RunningState';
import { transientOptions } from '../utils/transientOptions';
import { getEnumFromString } from '../utils';

const TimelineContainer = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  height: '1rem',
  width: '100%',
  borderRadius: '0.5rem',
  overflow: 'hidden',
});

const StyledSegment = styled(
  Stack,
  transientOptions
)<{ $colorKey: ColorKey; $width: number }>(({ theme, $colorKey, $width }) => {
  return {
    width: $width + '%',
    backgroundColor: theme.palette[$colorKey].main,
  };
});

export type TimelineData = {
  status?: string | null;
  magnitude?: number | null;
  id: string;
};

export interface RunningStatusTimelineViewProps {
  data: TimelineData[];
}

export function RunningStatusTimelineView({
  data,
}: RunningStatusTimelineViewProps) {
  const total = useMemo(() => {
    return data.reduce((sum, x) => sum + (x.magnitude ?? 0), 0);
  }, [data]);

  return (
    <TimelineContainer>
      {data.length === 0 && (
        <StyledSegment $colorKey={'disabled'} $width={100} />
      )}
      {data.map((x: TimelineData, i) => {
        const state = getEnumFromString(RunningState, x.status);
        return (
          <StyledSegment
            key={x.id}
            $colorKey={state ? RunningStateColorKey[state] : 'disabled'}
            $width={((x.magnitude ?? 0) / total) * 100}
          />
        );
      })}
    </TimelineContainer>
  );
}
