import { FormattedMessage } from 'react-intl';
import { Button, Stack, styled } from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import { activeSystem$ } from '@beeriot/api-client';
import { IconLabel, PermissionKey, PermissionView } from '../../base';
import { transientOptions, useResponsiveSizes } from '../../utils';

const StyledStack = styled(
  Stack,
  transientOptions
)<{ $isMobile: boolean }>(({ $isMobile }) => ({
  justifyContent: $isMobile ? 'unset' : 'flex-end',
  padding: $isMobile ? '0' : '1rem',
  gap: '1rem',
  flex: 1,
}));

const StyledButton = styled(
  Button,
  transientOptions
)<{ $isMobile: boolean }>(({ $isMobile }) => ({
  display: 'inline-block',
  width: $isMobile ? '100%' : 'fit-content',
  marginLeft: $isMobile ? '0' : '1rem',
}));

interface ReportsHeaderButtonsProps {
  showAddRecurringReportForm: () => void;
  toggleRecurringReports: () => void;
  showRecurringReports: boolean;
}

export function ReportsHeaderButtons({
  showAddRecurringReportForm,
  toggleRecurringReports,
  showRecurringReports,
}: ReportsHeaderButtonsProps) {
  const { isMobileSize } = useResponsiveSizes();
  const [activeSystem] = useObservable(activeSystem$);
  let addReportPermissionKey: PermissionKey;
  switch (activeSystem?.type) {
    case 'profi':
      addReportPermissionKey = PermissionKey.SitesPROFiReportsAdd;
      break;
    case 'cbs':
      addReportPermissionKey = PermissionKey.SitesCBSReportsAdd;
      break;
    case 'cfs':
      addReportPermissionKey = PermissionKey.SitesCFSReportsAdd;
      break;
    case 'keraflux':
      addReportPermissionKey = PermissionKey.SitesKerafluxReportsAdd;
      break;

    default:
      addReportPermissionKey = PermissionKey.SitesPROFiReportsAdd;
      break;
  }
  return (
    <StyledStack direction="row" $isMobile={isMobileSize}>
      <StyledButton
        $isMobile={isMobileSize}
        color={showRecurringReports ? 'success' : 'highlight2'}
        variant="contained"
        onClick={toggleRecurringReports}
      >
        {showRecurringReports ? (
          <FormattedMessage
            id="reports-table-header-return-to-reports-button"
            defaultMessage="Return to Reports"
            description="button label for returning to reports list"
          />
        ) : (
          <IconLabel
            variant="button"
            label={
              <FormattedMessage
                id="reports-table-header-show-recurring-reports-button"
                defaultMessage="Recurring Reports"
                description="button label for recurring reports"
              />
            }
            colorKey="white"
            icon={['far', 'refresh']}
            iconColorKey="white"
            iconSize="lg"
          />
        )}
      </StyledButton>
      <PermissionView permissionKey={addReportPermissionKey}>
        <StyledButton
          $isMobile={isMobileSize}
          variant="contained"
          onClick={showAddRecurringReportForm}
        >
          <IconLabel
            variant="button"
            label={
              <FormattedMessage
                id="reports-new-report-button"
                defaultMessage="New Report"
                description="Button for new report"
              />
            }
            colorKey="white"
            icon={['fas', 'file-circle-plus']}
            iconColorKey="white"
            iconSize="lg"
          />
        </StyledButton>
      </PermissionView>
    </StyledStack>
  );
}
