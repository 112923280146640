import { HmacSHA256, enc } from 'crypto-js';
import CognitoProvider from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { authStore } from '../state/auth/authStore';
import { AWSError } from 'aws-sdk';
import { awsRegion, clientId, clientSecret } from './awsConstants';
import { appConfiguration } from './configuration';
import { getUserInfo } from './getUserInfo';
import { UserApi } from '../generated';
import { useMutation } from 'react-query';

const userApi = new UserApi(appConfiguration);

export const NewPasswordRequiredCode = 'NEW_PASSWORD_REQUIRED';

export async function login(username: string, password: string) {
  if (!awsRegion || !clientId || !clientSecret) {
    throw new Error('Missing cognito environment variables');
  }

  const cognito = new CognitoProvider({
    region: awsRegion,
  });

  try {
    const data = await cognito
      .initiateAuth({
        AuthParameters: {
          USERNAME: username,
          PASSWORD: password,
          SECRET_HASH: hashSecret(username, clientId, clientSecret),
        },
        AuthFlow: 'USER_PASSWORD_AUTH',
        ClientId: clientId,
      })
      .promise();

    const accessToken = data?.AuthenticationResult?.AccessToken;
    const refreshToken = data?.AuthenticationResult?.RefreshToken;
    if (accessToken && refreshToken) {
      appConfiguration.accessToken = accessToken;
      authStore.update((state) => ({
        ...state,
        username,
        accessToken,
        refreshToken,
      }));
      try {
        const userInfo = await getUserInfo(authStore);
        return userInfo;
      } catch (err) {
        console.log(`error getting userInfo: `, err);
      }
    }
    if (data?.ChallengeName === NewPasswordRequiredCode) {
      authStore.update((state) => ({
        ...state,
        username,
        session: data?.Session || null,
      }));

      throw NewPasswordRequiredCode;
    }

    throw new Error('login failed');
  } catch (err: any) {
    console.log(`error logging in: `, err);
    throw err.code ?? err;
  }
}

export function setupUser(newPassword: string): Promise<void> {
  if (!awsRegion || !clientId || !clientSecret) {
    throw new Error('Missing cognito environment variables');
  }

  const state = authStore.getValue();
  const username = state.username;
  const session = state.session;
  if (!username || !session) {
    throw new Error('Missing auth state');
  }

  const cognito = new CognitoProvider({
    region: awsRegion,
  });

  return new Promise((resolve, reject) => {
    cognito.respondToAuthChallenge(
      {
        Session: session,
        ChallengeName: NewPasswordRequiredCode,
        ChallengeResponses: {
          USERNAME: username,
          NEW_PASSWORD: newPassword,
          SECRET_HASH: hashSecret(username, clientId, clientSecret),
        },
        ClientId: clientId,
      },
      (err, data) => {
        if (err) {
          reject(err.code);
          return;
        }

        const accessToken = data?.AuthenticationResult?.AccessToken;
        const refreshToken = data?.AuthenticationResult?.RefreshToken;
        if (accessToken && refreshToken) {
          authStore.update((state) => ({
            ...state,
            username,
            accessToken,
            refreshToken,
          }));
          resolve();
          return;
        }

        reject();
      }
    );
  });
}

export function useForgotPassword(
  onSuccess: (email: string) => void,
  onError: (error: Error) => void
) {
  return useMutation(
    async (email: string) => {
      const response = await userApi.triggerForgotPassword(email);
      return response.data;
    },
    {
      onSuccess: (_, email) => onSuccess(email),
      onError: (error) => onError(error as Error),
    }
  );
}

export function useConfirmForgotPassword(
  userId: string,
  code: string,
  onSuccess: () => void,
  onError: (error: Error) => void
) {
  return useMutation(
    async (password: string) => {
      const response = await userApi.confirmForgotPassword(userId, {
        password,
        code,
      });
      return response.data;
    },
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );
}

export function useChangePassword(
  userId: string,
  onSuccess: () => void,
  onError: (error: Error) => void
) {
  return useMutation(
    async (password: string) => {
      const response = await userApi.updateUserPassword(userId, { password });
      return response.data;
    },
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );
}

export function changePassword(
  currentPassword: string,
  newPassword: string
): Promise<void> {
  if (!awsRegion || !clientId || !clientSecret) {
    throw new Error('Missing cognito environment variables');
  }

  const cognito = new CognitoProvider({
    region: awsRegion,
  });

  const state = authStore.getValue();
  const accessToken = state.accessToken;
  if (!accessToken) {
    throw new Error('Missing auth state');
  }

  return new Promise((resolve, reject) => {
    cognito.changePassword(
      {
        PreviousPassword: currentPassword,
        ProposedPassword: newPassword,
        AccessToken: accessToken,
      },
      (err: AWSError) => {
        if (err) reject(err.code);
        resolve();
      }
    );
  });
}

function hashSecret(username: string, clientId: string, clientSecret: string) {
  const message = username + clientId;
  const encoded = HmacSHA256(message, clientSecret);
  return enc.Base64.stringify(encoded);
}
