import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import {
  AppProvider,
  makeAppRoutes,
  registerIcons,
} from '@beeriot/ui-components';
import { registerWebPushServiceWorker } from './registerWebPushServiceWorker';
registerIcons();

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <StrictMode>
    <AppProvider>{makeAppRoutes()}</AppProvider>
  </StrictMode>
);

window.addEventListener('load', function () {
  registerWebPushServiceWorker();
});
