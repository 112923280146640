import { useNavigate } from 'react-router-dom';
import { useActiveAppEntities } from '@beeriot/api-client';
import { AppTypography } from '../../../AppTypography';
import { Breadcrumb } from '../../Breadcrumb';
import { BreadcrumbsLineDropdown } from '../../dropdown/BreadcrumbsLineDropdown';
import { RoutingBreadcrumbProps } from './RoutingRootBreadcrumb';
import { SiteLoader } from '../../../../utils';

export function RoutingLineSelectorBreadcrumb({ to }: RoutingBreadcrumbProps) {
  const navigate = useNavigate();
  const { activeSite, activeSystem, areaSystems } = useActiveAppEntities();

  return (
    <>
      <Breadcrumb
        $stripeColorKey={'highlight2'}
        $followingItemStripeColorKey={'highlight5'}
        $isDropdown={true}
        renderDropdownContent={(open, setOpen) => (
          <BreadcrumbsLineDropdown
            areas={areaSystems}
            showSelectedLine={true}
            parentPageName={'sites'}
            onClose={() => setOpen(false)}
          />
        )}
        onClick={() => to && navigate(to)}
      >
        <AppTypography $bold={true} $colorKey={'highlight2'}>
          {activeSystem?.name}
        </AppTypography>
      </Breadcrumb>
      {activeSite && <SiteLoader siteId={activeSite.id} />}
    </>
  );
}
