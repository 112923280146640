import {
  animationFrameScheduler,
  fromEvent,
  interval,
  merge,
  Subject,
  of,
  combineLatest,
} from 'rxjs';
import {
  map,
  switchMap,
  take,
  tap,
  observeOn,
  shareReplay,
  startWith,
  throttleTime,
} from 'rxjs/operators';

const resetUserAction$$ = new Subject<unknown>();
const userActions$ = merge(
  fromEvent(window, 'mousemove'),
  fromEvent(window, 'mousedown'),
  fromEvent(window, 'keypress'),
  fromEvent(window, 'touchmove')
).pipe(switchMap((x) => of(x).pipe(observeOn(animationFrameScheduler))));
export const inactiveMinutes$ = of(true).pipe(
  switchMap(() =>
    resetUserAction$$.pipe(
      // throttle for 5 seconds of delay so we aren't constantly checking
      // for events
      throttleTime(5000, undefined, { leading: false, trailing: true })
    )
  ),

  switchMap((_, index) => {
    // start a timer, and also listen for user events,
    //  if they do something, emit a reset signal
    //  to the top of this pipeline
    userActions$
      .pipe(
        take(1),
        tap(() => resetUserAction$$.next(true))
      )
      .subscribe();

    return combineLatest({
      timerIndex: of(index),
      inactiveTime: interval(60_000).pipe(startWith(-1)),
    });
  }),
  // add one, the interval's output will be zero-indexed
  map((props) => ({ ...props, inactiveTime: props.inactiveTime + 1 })),
  // tap(({ timerIndex, inactiveTime }) =>
  //   console.log(`timer(${timerIndex}) inactive for ${inactiveTime} minutes`)
  // ),
  map(({ inactiveTime }) => inactiveTime),
  shareReplay(1)
);

export const kIDLE_ACTIVITY_GLOBAL_SUBSCRIPTION_KEY =
  '$$_biot_inactive_time_sub_$$';

export const startIdleTimer = () => {
  // assign the timer to a global var so that the timer can be used in
  //  multiple places like multiple hooks/components without creating
  //  multiple timers
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _win = global as any;
  if (!_win[kIDLE_ACTIVITY_GLOBAL_SUBSCRIPTION_KEY]) {
    _win[kIDLE_ACTIVITY_GLOBAL_SUBSCRIPTION_KEY] = inactiveMinutes$.subscribe();
    resetUserAction$$.next(true);
  }
};
export const stopIdleTimer = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _win = global as any;
  if (_win[kIDLE_ACTIVITY_GLOBAL_SUBSCRIPTION_KEY]) {
    _win[kIDLE_ACTIVITY_GLOBAL_SUBSCRIPTION_KEY].unsubscribe();
    _win[kIDLE_ACTIVITY_GLOBAL_SUBSCRIPTION_KEY] = undefined;
  }
};
