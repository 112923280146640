import {
  GroupBase,
  GroupStatusEnum,
  useCreateGroup,
} from '@beeriot/api-client';
import { styled, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  AddEditGroupNameForm,
  AddEditGroupNameFormFields,
} from '../../AddEditGroupNameForm';

const StyledDialog = styled(Dialog)({
  '& .MuiDialogContent-root': {
    padding: '0rem 0rem 1.5rem 0rem',
  },
});

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.componentBase.main,
}));

export interface AddGroupTypeDialogProps {
  companyId: string;
  open: boolean;
  handleClose: () => void;
}

export function AddGroupTypeDialog({
  companyId,
  open,
  handleClose,
}: AddGroupTypeDialogProps) {
  const intl = useIntl();
  const createGroup = useCreateGroup();

  const handleSubmit = useCallback(
    (data: AddEditGroupNameFormFields) => {
      const group: GroupBase = {
        name: data.name,
        companyId: companyId,
        status: GroupStatusEnum.Active,
      };

      createGroup.mutate(group);
      handleClose();
    },
    [createGroup, handleClose, companyId]
  );

  const dialogTitle = intl.formatMessage({
    id: 'organize-sites-add-new-group-type',
    defaultMessage: 'New Group Type',
    description: 'New group type label',
  });

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <StyledDialogTitle>{dialogTitle}</StyledDialogTitle>
      <DialogContent>
        <AddEditGroupNameForm
          mutation={createGroup}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          isChildGroup={false}
          editMode={false}
        />
      </DialogContent>
    </StyledDialog>
  );
}
