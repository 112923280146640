import {
  SettingsSites,
  SettingsSitesCurrencyEnum,
  SettingsSitesElectricUnitsEnum,
  SettingsSitesVolumeUnitsEnum,
} from '@beeriot/api-client';
import { SelectOption } from '../../form';

export const electricOptions: SelectOption[] = Object.entries(
  SettingsSitesElectricUnitsEnum
).map(([key, value]) => ({
  value: value,
  label: key,
}));

export const volumeOptions: SelectOption[] = Object.entries(
  SettingsSitesVolumeUnitsEnum
).map(([key, value]) => ({
  value: value,
  label: key,
}));

export const currencyOptions: SelectOption[] = Object.entries(
  SettingsSitesCurrencyEnum
).map(([key, value]) => ({
  value: value,
  label: key,
}));

export interface LocalUtilityCostsSettingsFormFields {
  electricUnits?: SelectOption;
  volumeUnits?: SelectOption;
  currency?: SelectOption;
  electricCost?: number;
  coldWaterCost?: number;
  hotWaterCost?: number;
  daWaterCost?: number;
  chemCausticCost?: number;
  chemHydrogenPeroxideCost?: number;
  chemAcidCost?: number;
  chemSpecialCleanerCost?: number;
  pvppCost?: number;
  enzymaticCost?: number;
  coolingEnergyCost?: number;
}

export function mapDefaultValues(
  localUtilityCostsSettings?: SettingsSites | undefined
): LocalUtilityCostsSettingsFormFields | undefined {
  if (localUtilityCostsSettings === undefined) return undefined;

  const defaultValues: LocalUtilityCostsSettingsFormFields = {
    electricUnits: electricOptions.find(
      (data) => data.value === localUtilityCostsSettings?.electricUnits
    ),
    volumeUnits: volumeOptions.find(
      (data) => data.value === localUtilityCostsSettings?.volumeUnits
    ),
    currency: currencyOptions.find(
      (data) => data.value === localUtilityCostsSettings?.currency
    ),
    electricCost: localUtilityCostsSettings?.electricCost,
    coldWaterCost: localUtilityCostsSettings?.coldWaterCost,
    hotWaterCost: localUtilityCostsSettings?.hotWaterCost,
    daWaterCost: localUtilityCostsSettings?.daWaterCost,
    chemCausticCost: localUtilityCostsSettings?.chemCausticCost,
    chemHydrogenPeroxideCost:
      localUtilityCostsSettings?.chemHydrogenPeroxideCost,
    chemAcidCost: localUtilityCostsSettings?.chemAcidCost,
    chemSpecialCleanerCost: localUtilityCostsSettings?.chemSpecialCleanerCost,
    pvppCost: localUtilityCostsSettings?.pvppCost,
    enzymaticCost: localUtilityCostsSettings?.enzymaticCost,
    coolingEnergyCost: localUtilityCostsSettings?.coolingEnergyCost,
  };

  return defaultValues;
}

export function toLocalUtilityCostsSettingsRequestModel(
  fields: LocalUtilityCostsSettingsFormFields
): SettingsSites {
  return {
    electricUnits:
      (fields.electricUnits?.value as SettingsSitesElectricUnitsEnum) ??
      SettingsSitesElectricUnitsEnum.KWh,
    volumeUnits:
      (fields.volumeUnits?.value as SettingsSitesVolumeUnitsEnum) ??
      SettingsSitesVolumeUnitsEnum.Gal,
    currency:
      (fields.currency?.value as SettingsSitesCurrencyEnum) ??
      SettingsSitesCurrencyEnum.Usd,
    electricCost: fields.electricCost ?? 0,
    coldWaterCost: fields.coldWaterCost ?? 0,
    hotWaterCost: fields.hotWaterCost ?? 0,
    daWaterCost: fields.daWaterCost ?? 0,
    chemCausticCost: fields.chemCausticCost ?? 0,
    chemHydrogenPeroxideCost: fields.chemHydrogenPeroxideCost ?? 0,
    chemAcidCost: fields.chemAcidCost ?? 0,
    chemSpecialCleanerCost: fields.chemSpecialCleanerCost ?? 0,
    pvppCost: fields.pvppCost ?? 0,
    enzymaticCost: fields.enzymaticCost ?? 0,
    coolingEnergyCost: fields.coolingEnergyCost ?? 0,
  };
}
