import { styled, ToggleButtonGroup } from '@mui/material';
import { transientOptions } from '../utils/transientOptions';
import { AppToggleButton, AppToggleButtonGroupItem } from './AppToggleButton';

type GrowProps = {
  $shouldGrow?: boolean;
};

const StyledGroup = styled(
  ToggleButtonGroup,
  transientOptions
)<GrowProps>(({ $shouldGrow }) => ({
  flex: $shouldGrow ? 1 : undefined,
}));

const StyledButton = styled(
  AppToggleButton,
  transientOptions
)<GrowProps>(({ $shouldGrow }) => ({
  flex: $shouldGrow ? 1 : undefined,
}));
export interface AppToggleButtonGroupProps {
  items: AppToggleButtonGroupItem[];
  selectedItem: AppToggleButtonGroupItem | undefined;
  didSelectItem: (item: AppToggleButtonGroupItem | undefined) => void;
  shouldGrow?: boolean;
}
export function AppToggleButtonGroup({
  items,
  selectedItem,
  didSelectItem,
  shouldGrow,
}: AppToggleButtonGroupProps) {
  return (
    <StyledGroup
      exclusive
      $shouldGrow={shouldGrow}
      value={selectedItem?.value}
      onChange={(_, value) => {
        if (value) didSelectItem(items.find((x) => x.value === value));
      }}
    >
      {items.map((x, idx) => (
        <StyledButton
          key={idx}
          $shouldGrow={shouldGrow}
          value={x.value}
          label={x.label}
          icon={x.icon}
          iconSize={x.iconSize}
        />
      ))}
    </StyledGroup>
  );
}
