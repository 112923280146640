import {
  SiteSystemSelectionHeader,
  SuspenseThresholdSettingsForm,
} from '../../settings';
import { SettingsPage } from './SettingsPage';

export function ThresholdSettingsPage() {
  return (
    <SettingsPage header={<SiteSystemSelectionHeader />}>
      <SuspenseThresholdSettingsForm />
    </SettingsPage>
  );
}
