import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import {
  GQLCompany,
  SettingsUsers,
  SettingsUsersFavorites,
  SettingsUsersFavoritesTypeEnum,
  useGQLUserSettings,
  useUpdateUserSettings,
  useUserSettings,
} from '@beeriot/api-client';
import {
  AppCard,
  AppIcon,
  AppTypography,
  OverridableComponentAppTypography,
  VerticalLabels,
  SuspenseLabeledSystemCountsBadgeList,
} from '../base';
import { useEntityDataAttributes } from '../utils/entityDataAttributes';

const StyledCard = styled(AppCard)(({ theme }) => ({
  width: '100%',
  padding: '1rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  justifyContent: 'stretch',
}));

const CompanyName = styled(AppTypography)({
  lineHeight: '1',
}) as OverridableComponentAppTypography;

const LeftSideStack = styled(Stack)(({ theme }) => ({
  flex: 1,
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '0.5rem',
}));

const HeaderStack = styled(Stack)(({ theme }) => ({
  gap: '1rem',
  alignItems: 'center',
}));

const ContentStack = styled(Stack)({
  gap: '1.5rem',
});

const StyledSystemCountsBadgeList = styled(
  SuspenseLabeledSystemCountsBadgeList
)({
  height: '100%',
});

export interface CompanyCardProps {
  company: GQLCompany;
  favorited: boolean;
}

export function CompanyCard({ company, favorited }: CompanyCardProps) {
  const theme = useTheme();
  // const { data: userSettings } = useUserSettings();
  const {data: gqlUser } = useGQLUserSettings()
  const userSettings = gqlUser?.getUser?.settingsInfo;
  const isSmall = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  const updateUserSettings = useUpdateUserSettings();

  const intl = useIntl();
  const NA = intl.formatMessage({
    id: 'common-not-applicable',
    defaultMessage: 'N/A',
    description: "Label for when there isn't a value to display",
  });

  const sitesLabel = intl.formatMessage({
    id: 'common-sites-label',
    description: 'Sites label',
    defaultMessage: 'Sites',
  });

  const toggleFavorite = useCallback(() => {
    if (userSettings?.favorites) {
      const newFavorites = [...userSettings.favorites];
      if (favorited) {
        const companyIdx = newFavorites.findIndex((x) => x?.id === company.id);
        newFavorites.splice(companyIdx, 1);
      } else {
        newFavorites.push({
          type: SettingsUsersFavoritesTypeEnum.Companies,
          id: company.id,
        });
      }

      updateUserSettings.mutate({ ...userSettings, favorites: newFavorites } as unknown as SettingsUsers);
    }
  }, [userSettings, updateUserSettings, company, favorited]);

  const companyDataAttribute = useEntityDataAttributes({
    company,
  });
  return (
    <StyledCard data-element-type={'company-card'} {...companyDataAttribute}>
      <LeftSideStack direction="row">
        <HeaderStack direction="row">
          <AppIcon
            data-element-type={'favorite-icon'}
            className="favorite"
            onClick={(e) => {
              toggleFavorite();
              e.stopPropagation();
            }}
            icon={'star-sharp'}
            $colorKey={favorited ? 'warning' : 'shade'}
            size="2x"
          />
          <VerticalLabels
            data-element-type={'company-name-and-location'}
            label={
              <CompanyName
                component="div"
                $bold={isSmall ? false : undefined}
                noWrap
              >
                {company.name}
              </CompanyName>
            }
            subLabel={company.address?.country}
          />
        </HeaderStack>
        <ContentStack direction="row">
          <VerticalLabels
            data-element-type={'site-count'}
            labelBold
            label={
              company.companySiteCount?.count != null
                ? `${company.companySiteCount?.count}`
                : NA
            }
            subLabelBold
            subLabel={sitesLabel}
          />
        </ContentStack>
      </LeftSideStack>
      {isSmall ? null : <StyledSystemCountsBadgeList company={company} companyId={company.id} />}
    </StyledCard>
  );
}
