import { useMemo, useState } from 'react';
import { Document, PDFViewer, Font } from '@react-pdf/renderer';
import { useObservable } from '@ngneat/use-observable';
import { Button } from '@mui/material';
import {
  alertsSummary$,
  reportCover$,
  reportGraphModels$,
  reportMetadata$,
  reportStore,
  reportSummary$,
} from './report-store';
import { CoverPage } from './CoverPage';
import { GraphRender } from './GraphRender';
import { GraphPage } from './GraphPage';
import { TableOfContents } from './TableOfContents';
import { DownloadLink } from './DownloadLink';
import { AlertsSummaryPage } from './report-summary/AlertsSummaryPage';
import { ReportSummaryPage } from './report-summary/ReportSummaryPage';

const hyphenationCallback = (word: string) => {
  return word.split('');
};

Font.registerHyphenationCallback(hyphenationCallback);

export function Report() {
  const [metadata] = useObservable(reportMetadata$);
  const [cover] = useObservable(reportCover$);
  const [graphs] = useObservable(reportGraphModels$);
  const [reportSummary] = useObservable(reportSummary$);
  const [alertsSummary] = useObservable(alertsSummary$);
  const [alertsSummaryPageNumber, setAlertsSummaryPageNumber] = useState<
    number | undefined
  >(undefined);
  const pngs: Record<string, string> = {};

  const renderDocument = useMemo(() => {
    console.log('renderDocument');
    const pageNumbers: Record<string, number> = {};
    const addRenderedPage = (id: string, pageNumber: number) => {
      pageNumbers[id] = pageNumber;
      console.log('addRenderedPage pageNumbers', JSON.stringify(pageNumbers));
      if (Object.keys(pageNumbers).length === graphs?.graphModels?.length) {
        console.log('addRenderedPage updating store');
        reportStore.update((state) => ({
          ...state,
          graphModels: {
            graphModels: graphs.graphModels?.map((x) => ({
              ...x,
              renderedPageNumber: pageNumbers[x.id],
            })),
          },
        }));
      }
    };

    return (
      <Document>
        {cover && <CoverPage cover={cover} />}
        {graphs?.graphModels && (!alertsSummary || alertsSummaryPageNumber) && (
          <TableOfContents
            models={graphs.graphModels}
            hasSummary={!!reportSummary}
            hasAlertsSummary={!!alertsSummary}
            alertsSummaryPage={alertsSummaryPageNumber}
          />
        )}
        {reportSummary && <ReportSummaryPage summary={reportSummary} />}
        {graphs?.graphModels?.every((x) => x.hideGraph || x.renderedPng) &&
          graphs?.graphModels.map((x) => (
            <GraphPage
              key={x.id}
              model={x}
              onRenderPage={(pageNumber) => addRenderedPage(x.id, pageNumber)}
            />
          ))}
        {/* Ensure the alerts summary page is not rendered until after graphs */}
        {alertsSummary &&
          graphs?.graphModels?.every((x) => !!x.renderedPageNumber) && (
            <AlertsSummaryPage
              summary={alertsSummary}
              alertsSummaryPage={alertsSummaryPageNumber}
              setAlertsSummaryPage={setAlertsSummaryPageNumber}
            />
          )}
      </Document>
    );
  }, [
    alertsSummary,
    alertsSummaryPageNumber,
    cover,
    graphs?.graphModels,
    reportSummary,
  ]);

  const finalDocument = useMemo(() => {
    if (
      !cover ||
      !graphs?.graphModels?.every((x) => x.renderedPageNumber) ||
      (!!alertsSummary && !alertsSummaryPageNumber)
    ) {
      console.log('finalDocument pending');
      return null;
    }
    console.log('returning finalDocument');
    return renderDocument;
  }, [
    alertsSummary,
    alertsSummaryPageNumber,
    cover,
    graphs?.graphModels,
    renderDocument,
  ]);

  const addRenderedPngs = () => {
    const newGraphModels = graphs?.graphModels?.map((x) => ({
      ...x,
      renderedPng: pngs[x.id],
    }));

    console.log('updating report store with png data');
    reportStore.update((state) => ({
      ...state,
      graphModels: { graphModels: newGraphModels },
    }));
  };

  const DocComponent = () => renderDocument;

  const reset = () => {
    reportStore.reset();
    window.location.reload();
  };

  return (
    <>
      <p>Last Update: September 7, 2023</p>
      {finalDocument && metadata?.filename && (
        <DownloadLink doc={finalDocument} filename={metadata.filename} />
      )}
      <Button variant="contained" onClick={reset}>
        Reset
      </Button>
      {graphs?.graphModels &&
        graphs.graphModels
          .filter((x) => !x.hideGraph)
          .map((x) => {
            return (
              <GraphRender
                key={x.id}
                model={x}
                onRenderComplete={(png) => {
                  pngs[x.id] = png;
                  console.log('new png for graph ', x.id);
                  if (
                    Object.keys(pngs).length ===
                    graphs.graphModels?.filter((x) => !x.hideGraph).length
                  ) {
                    console.log('all graph pngs available');
                    addRenderedPngs();
                  }
                }}
              />
            );
          })}
      <PDFViewer style={{ height: '100%' }}>
        <DocComponent />
      </PDFViewer>
    </>
  );
}
