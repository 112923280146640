import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Stack, styled } from '@mui/material';
import { Property } from 'csstype';
import { transientOptions } from '../utils/transientOptions';
import { useFormContext } from 'react-hook-form';
import { AppDialog } from '../base/AppDialog';
import { AppTypography } from '../base/AppTypography';
import { LoadingButton } from '../base/LoadingButton';
import { PermissionKey, PermissionView } from '../base/user-permissions';

interface StyledStackProps {
  $justify?: Property.JustifyContent;
}

const StyledStack = styled(
  Stack,
  transientOptions
)<StyledStackProps>(({ $justify }) => ({
  justifyContent: $justify,
  columnGap: '1rem',
}));

export interface FormFooterButtonsProps {
  isLoading?: boolean;
  isSuccess?: boolean;
  successLabel?: string;
  loadingLabel?: string;
  submitLabel?: string;
  showCancelButton?: boolean;
  cancelLabel?: string;
  requireCancelConfirmation?: boolean;
  onCancel?: () => void;
  onConfirmOkSuccessDialog?: () => void;
  justify?: Property.JustifyContent;
  className?: string;
  submitPermissionKey?: PermissionKey;
  disableSubmit?: boolean;
}

export function FormFooterButtons({
  isLoading = false,
  isSuccess = false,
  successLabel,
  loadingLabel,
  submitLabel,
  showCancelButton = true,
  cancelLabel,
  requireCancelConfirmation = true,
  onCancel,
  onConfirmOkSuccessDialog,
  justify = 'end',
  className,
  submitPermissionKey,
  disableSubmit,
}: FormFooterButtonsProps) {
  const {
    reset,
    formState: { isDirty, isValid },
  } = useFormContext();
  const [open, setOpen] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const intl = useIntl();
  const defaultCancelButtonLabel = intl.formatMessage({
    id: 'common-cancel-button',
    description: 'Label for cancel button',
    defaultMessage: 'Cancel',
  });
  const defaultSubmitButtonLabel = intl.formatMessage({
    id: 'common-submit-button',
    description: 'Label for submit button',
    defaultMessage: 'Submit',
  });

  // If onCancel is not provided then the form will be reset by default
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      reset();
    }
  };

  // If requireCancelConfirmation is true then a dialog will be displayed which must be confirmed before the form is cancelled
  const initialCancel = () => {
    // Only confirm cancel when the form is actually dirty/changed
    if (requireCancelConfirmation && isDirty) {
      setOpen(true);
    } else {
      handleCancel();
    }
  };

  const confirmCancel = () => {
    setOpen(false);
    handleCancel();
  };

  const denyCancel = () => {
    setOpen(false);
  };

  const confirmOkSuccessDialog = () => {
    setOpenSuccessDialog(false);
    onConfirmOkSuccessDialog?.();
  };

  useEffect(() => {
    if (isSuccess) setOpenSuccessDialog(true);
  }, [isSuccess]);

  return (
    <>
      <StyledStack $justify={justify} direction="row" className={className}>
        {showCancelButton && (
          <Button color="shade" onClick={initialCancel} variant="contained">
            {cancelLabel ?? defaultCancelButtonLabel}
          </Button>
        )}
        {submitPermissionKey ? (
          <PermissionView permissionKey={submitPermissionKey}>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={isLoading || !isDirty || !isValid}
              isLoading={isLoading ?? false}
              loadingLabel={loadingLabel}
            >
              {submitLabel ?? defaultSubmitButtonLabel}
            </LoadingButton>
          </PermissionView>
        ) : (
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={isLoading || disableSubmit || !isDirty || !isValid}
            isLoading={isLoading ?? false}
            loadingLabel={loadingLabel}
          >
            {submitLabel ?? defaultSubmitButtonLabel}
          </LoadingButton>
        )}
      </StyledStack>
      <AppDialog
        dialogTitle={
          <FormattedMessage
            id="form-footer-buttons-reset-form-dialog-title-label"
            description="Label for the reset form dialog title"
            defaultMessage="Reset Form?"
          />
        }
        dialogContent={
          <AppTypography>
            <FormattedMessage
              id="form-footer-buttons-reset-form-dialog-content"
              description="Message asking the user if they would like to reset the form"
              defaultMessage="This will return all form fields to their initial value. Are you sure you want to proceed?"
            />
          </AppTypography>
        }
        open={open}
        confirm={confirmCancel}
        cancel={denyCancel}
      />
      <AppDialog
        dialogTitle={
          <FormattedMessage
            id="common-success-label"
            description="Label for a successful event"
            defaultMessage="Success!"
          />
        }
        dialogContent={
          successLabel ? <AppTypography>{successLabel}</AppTypography> : null
        }
        open={openSuccessDialog}
        confirm={confirmOkSuccessDialog}
        confirmLabel={intl.formatMessage({
          id: 'common-ok-button',
          defaultMessage: 'Ok',
          description: 'label for ok button',
        })}
        showCancelButton={false}
      />
    </>
  );
}
