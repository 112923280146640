import { AxiosResponse } from 'axios';
import { filterNil } from '@ngneat/elf';
import { useObservable } from '@ngneat/use-observable';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
  InfiniteData,
} from 'react-query';
import { combineLatest, from, of } from 'rxjs';
import {
  concatMap,
  last,
  map,
  shareReplay,
  takeLast,
  tap,
  toArray,
} from 'rxjs/operators';
import {
  Area,
  AreaApi,
  System,
  SystemBase,
  SystemTypeEnum,
} from '../../generated/api';
import {
  GQLGetSystemQuery,
  GQLListSystemsQuery,
  GQLSystem,
  useGetSystemQuery,
  useInfiniteListSystemsQuery,
} from '../../generated/gql';
import { systemStore } from '../../state/system/systemStore';
import { siteStore } from '../../state/site/siteStore';

import { activeSite$ } from '../../state/site/siteStore.selectors';
import { activeSiteAreaSystems$ } from '../../state/site/activeSiteAreaSystems$';

import {
  AppQueryClient,
  LONG_POLLING_INTERVAL,
  LONG_STALE_TIME_INTERVAL,
  MEDIUM_STALE_TIME_INTERVAL,
  defaultPagingParams,
  getNextPageParamHandler,
  optimisticUpdateInfiniteData,
} from '../../utils';
import { AreaSystem } from '../interfaces/AreaSystem';
import { appConfiguration } from '../configuration';
import { MutationCallbackMethods } from '../settings';
import equal from 'fast-deep-equal';
import { refetchIfPartialInfiniteData } from '../refetchIfPartialData';
import {
  useCachedInfiniteListSystemsQuery,
  useCachedPrefetchInfiniteListSystemsQuery,
} from './systemsRequests';
import { systemApi } from './systemApi';
import { get } from 'http';

interface SystemQueryVariables {
  skip: number;
  limit: number;
  companySiteId: string | null | undefined;
  companyId: string | null | undefined;
  status: string;
}

export const areasApi = new AreaApi(appConfiguration);
const queryKeyPrefix = 'listSystems.infinite';
const queryVariables = (
  companySiteId?: string | null,
  companyId?: string | null
): SystemQueryVariables => {
  return {
    companySiteId,
    companyId,
    status: 'active',
    ...defaultPagingParams,
  };
};
const queryKey = (companySiteId?: string | null, companyId?: string | null) => [
  queryKeyPrefix,
  queryVariables(companySiteId, companyId),
];

async function updateSystem(gqlSystem: GQLSystem) {
  if (!gqlSystem.id) return null;
  const res = await systemApi.updateSystem(gqlSystem.id, toSystem(gqlSystem));
  return res.data;
}

async function getAreas(siteId?: string | null) {
  if (!siteId) return [];
  const res = await areasApi.getAreas(0, 100, undefined, siteId, 'active');
  return res.data;
}

export function useAreas(siteId?: string | null) {
  const q = useQuery(['areas', siteId], async () => getAreas(siteId), {
    onSettled(data) {
      if (data && siteId) {
        siteStore.update((s) => ({ ...s, areas: data.length ? data : null }));
      }
    },
    // placeholderData:[]
    enabled: siteId !== undefined && siteId !== null,
    suspense: false,
    staleTime: MEDIUM_STALE_TIME_INTERVAL,
    cacheTime: LONG_STALE_TIME_INTERVAL,
  });
  return q;
}

export const useGQLSystemLiveStatus = (
  system: GQLSystem | null | undefined
) => {
  const AppQueryClient = useQueryClient();
  const queryVars = {
    companyId: system?.companyId,
    companySiteId: system?.companySiteId,
    skip: 0,
    limit: 100,
    status: 'active',
  };
  const prefetchData = AppQueryClient.getQueryData([
    'listSystems.infinite.prefetch',
    queryVars,
  ]) as InfiniteData<GQLListSystemsQuery> | undefined;
  const fullSiteData = AppQueryClient.getQueryData([
    'listSystems.infinite.fullSite',
    queryVars,
  ]) as InfiniteData<GQLListSystemsQuery> | undefined;

  const { prefetch, full } = useCachedSystemValues('liveStatus',prefetchData, system, fullSiteData);

  
    const q = useQuery(
      ['getSystemLiveStatus', { systemId: system?.id }],
      () =>
        systemApi.getSystemLiveStatus(system?.id ?? '', {
          params: { live: false },
        }),
      {
        enabled: !!system?.id && (!prefetch) && !full,
        suspense: false,
        staleTime: 1000 * 60 * 30,
        cacheTime: 1000 * 60 * 60,
        onSuccess: (data) => {
          // Update prefetch and fullSite query data
          AppQueryClient.setQueryData<
            InfiniteData<GQLListSystemsQuery> | undefined
          >(['listSystems.infinite.prefetch', queryVars], (oldData) => {
            if (!oldData) return oldData;

            return {
              ...oldData,
              pages: oldData.pages.map((page) => {
                return {
                  ...page,
                  listSystems:
                    (page.listSystems?.map((s) =>
                      s?.id === system?.id ? { ...s, liveStatus: data.data } : s
                    ) as GQLSystem[]) ?? [],
                };
              }),
            };
          });
        },
      }
    );
    return q;
};

export const useGQLSystemLiveKPI = (
  system: GQLSystem | null | undefined
) => {
  const AppQueryClient = useQueryClient();
  const queryVars = {
    companyId: system?.companyId,
    companySiteId: system?.companySiteId,
    skip: 0,
    limit: 100,
    status: 'active',
  };
  const prefetchData = AppQueryClient.getQueryData([
    'listSystems.infinite.prefetch',
    queryVars,
  ]) as InfiniteData<GQLListSystemsQuery> | undefined;
  const fullSiteData = AppQueryClient.getQueryData([
    'listSystems.infinite.fullSite',
    queryVars,
  ]) as InfiniteData<GQLListSystemsQuery> | undefined;

  const { prefetch, full } = useCachedSystemValues('liveKPI', prefetchData, system, fullSiteData);

  const q = useQuery(
    ['getSystemLiveKPI', { systemId: system?.id }],
    () =>
      systemApi.getSystemLiveKPI(system?.id ?? '', {
        params: { live: false },
      }),
    {
      enabled: !!system?.id && (!prefetch) && !full,
      suspense: false,
      staleTime: 1000 * 60 * 30,
      cacheTime: 1000 * 60 * 60,
      onSuccess: (data) => {
        // Update prefetch and fullSite query data
        AppQueryClient.setQueryData<
          InfiniteData<GQLListSystemsQuery> | undefined
        >(['listSystems.infinite.prefetch', queryVars], (oldData) => {
          if (!oldData) return oldData;

          return {
            ...oldData,
            pages: oldData.pages.map((page) => {
              return {
                ...page,
                listSystems:
                  (page.listSystems?.map((s) =>
                    s?.id === system?.id ? { ...s, liveKPI: data.data } : s
                  ) as GQLSystem[]) ?? [],
              };
            }),
          };
        });
      },
    }
  );
  return q;
};

export const useGQLSystemLiveRegeneration = (
  system: GQLSystem | null | undefined
) => {
  const AppQueryClient = useQueryClient();
  const queryVars = {
    companyId: system?.companyId,
    companySiteId: system?.companySiteId,
    skip: 0,
    limit: 100,
    status: 'active',
  };
  const prefetchData = AppQueryClient.getQueryData([
    'listSystems.infinite.prefetch',
    queryVars,
  ]) as InfiniteData<GQLListSystemsQuery> | undefined;
  const fullSiteData = AppQueryClient.getQueryData([
    'listSystems.infinite.fullSite',
    queryVars,
  ]) as InfiniteData<GQLListSystemsQuery> | undefined;

  const { prefetch, full } = useCachedSystemValues('liveRegeneration', prefetchData, system, fullSiteData);

  const q = useQuery(
    ['getSystemLiveRegeneration', { systemId: system?.id }],
    () =>
      systemApi.getSystemRegeneration(system?.id ?? '', {
        params: { live: false },
      }),
    {
      enabled: !!system?.id && (!prefetch) && !full,
      suspense: false,
      staleTime: 1000 * 60 * 30,
      cacheTime: 1000 * 60 * 60,
      onSuccess: (data) => {
        // Update prefetch and fullSite query data
        AppQueryClient.setQueryData<
          InfiniteData<GQLListSystemsQuery> | undefined
        >(['listSystems.infinite.prefetch', queryVars], (oldData) => {
          if (!oldData) return oldData;

          return {
            ...oldData,
            pages: oldData.pages.map((page) => {
              return {
                ...page,
                listSystems:
                  (page.listSystems?.map((s) =>
                    s?.id === system?.id ? { ...s, liveRegeneration: data.data } : s
                  ) as GQLSystem[]) ?? [],
              };
            }),
          };
        });
      },
    }
  );
  return q;
};

export const useGQLSystemCleanUpLiveStatus = (
  system: GQLSystem | null | undefined
) => {
  const AppQueryClient = useQueryClient();
  const queryVars = {
    companyId: system?.companyId,
    companySiteId: system?.companySiteId,
    skip: 0,
    limit: 100,
    status: 'active',
  };
  const prefetchData = AppQueryClient.getQueryData([
    'listSystems.infinite.prefetch',
    queryVars,
  ]) as InfiniteData<GQLListSystemsQuery> | undefined;
  const fullSiteData = AppQueryClient.getQueryData([
    'listSystems.infinite.fullSite',
    queryVars,
  ]) as InfiniteData<GQLListSystemsQuery> | undefined;

  const { prefetch, full } = useCachedSystemValues('cleanUpLiveStatus', prefetchData, system, fullSiteData);

  const q = useQuery(
    ['getSystemCleanUpLiveStatus', { systemId: system?.id }],
    () =>
      systemApi.getSystemCleanUpStatus(system?.id ?? '', {
        params: { live: false },
      }),
    {
      enabled: !!system?.id && (!prefetch) && !full,
      suspense: false,
      staleTime: 1000 * 60 * 30,
      cacheTime: 1000 * 60 * 60,
      onSuccess: (data) => {
        // Update prefetch and fullSite query data
        AppQueryClient.setQueryData<
          InfiniteData<GQLListSystemsQuery> | undefined
        >(['listSystems.infinite.prefetch', queryVars], (oldData) => {
          if (!oldData) return oldData;

          return {
            ...oldData,
            pages: oldData.pages.map((page) => {
              return {
                ...page,
                listSystems:
                  (page.listSystems?.map((s) =>
                    s?.id === system?.id ? { ...s, cleanUpLiveStatus: data.data } : s
                  ) as GQLSystem[]) ?? [],
              };
            }),
          };
        });
      },
    }
  );
  return q;
};

export const useGQLSystemCleanUpLiveKPI = (
  system: GQLSystem | null | undefined
) => {
  const AppQueryClient = useQueryClient();
  const queryVars = {
    companyId: system?.companyId,
    companySiteId: system?.companySiteId,
    skip: 0,
    limit: 100,
    status: 'active',
  };
  const prefetchData = AppQueryClient.getQueryData([
    'listSystems.infinite.prefetch',
    queryVars,
  ]) as InfiniteData<GQLListSystemsQuery> | undefined;
  const fullSiteData = AppQueryClient.getQueryData([
    'listSystems.infinite.fullSite',
    queryVars,
  ]) as InfiniteData<GQLListSystemsQuery> | undefined;

  const { prefetch, full } = useCachedSystemValues('cleanUpLiveKPI', prefetchData, system, fullSiteData);

  const q = useQuery(
    ['getSystemCleanUpLiveKPI', { systemId: system?.id }],
    () =>
      systemApi.getSystemCleanUpKPI(system?.id ?? '', {
        params: { live: false },
      }),
    {
      enabled: !!system?.id && (!prefetch) && !full,
      suspense: false,
      staleTime: 1000 * 60 * 30,
      cacheTime: 1000 * 60 * 60,
      onSuccess: (data) => {
        // Update prefetch and fullSite query data
        AppQueryClient.setQueryData<
          InfiniteData<GQLListSystemsQuery> | undefined
        >(['listSystems.infinite.prefetch', queryVars], (oldData) => {
          if (!oldData) return oldData;

          return {
            ...oldData,
            pages: oldData.pages.map((page) => {
              return {
                ...page,
                listSystems:
                  (page.listSystems?.map((s) =>
                    s?.id === system?.id ? { ...s, cleanUpLiveKPI: data.data } : s
                  ) as GQLSystem[]) ?? [],
              };
            }),
          };
        });
      },
    }
  );
  return q;
};

function useCachedSystemValues(key: 'liveStatus' | 'liveKPI' | 'liveRegeneration' | 'cleanUpLiveKPI' | 'cleanUpLiveStatus', prefetchData: InfiniteData<GQLListSystemsQuery> | undefined, system: GQLSystem | null | undefined, fullSiteData: InfiniteData<GQLListSystemsQuery> | undefined) {
  const prefetch = useMemo(() => {
    let sysIdx = -1;
    const page = prefetchData?.pages.find((p) => {
      const found = p.listSystems?.findIndex((s) => s?.id === system?.id);
      if (found && found > -1) {
        sysIdx = found;
        return true
      }
      return false
    });
    if(page && sysIdx > -1) {
      return page?.listSystems?.[sysIdx]?.[key];
    }
    return undefined 
  }, [prefetchData, system]);

  const full = useMemo(() => {
    let sysIdx = -1;
    const page = fullSiteData?.pages.find((p) => {
      const found = p.listSystems?.findIndex((s) => s?.id === system?.id);
      if (found && found > -1) {
        sysIdx = found;
        return true
      }
      return false
    });
    if(page && sysIdx > -1) {
      return page?.listSystems?.[sysIdx]?.[key];
    }
    return undefined 
   
  }, [fullSiteData, system]);
  return { prefetch, full };
}

export function useGQLSystems(
  companySiteId?: string | null,
  companyId?: string | null,
  opts?: UseInfiniteQueryOptions<
    GQLListSystemsQuery,
    unknown,
    GQLListSystemsQuery
  >
): GQLSystem[] | null {
  const queryClient = useQueryClient();

  const prefetchResult = useCachedPrefetchInfiniteListSystemsQuery(
    queryVariables(companySiteId, companyId),
    {
      enabled: !!companySiteId || !!companyId,
      getNextPageParam: getNextPageParamHandler<GQLListSystemsQuery>(
        (query) => {
          if (query && 'listSystems' in query) {
            return query.listSystems?.length;
          }
          return undefined;
        }
      ),
      structuralSharing: true,
      ...(opts ?? {}),
      suspense: true,
      staleTime: 1000 * 60 * 30,
      cacheTime: 1000 * 60 * 60,
      onError(err) {
        if (err) {
          console.log(err);
        }
      },
    }
  );
  const {
    data: systems,
    dataUpdatedAt,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isFetching,
    refetch,
  } = useCachedInfiniteListSystemsQuery<GQLListSystemsQuery>(
    queryVariables(companySiteId, companyId),
    {
      enabled: prefetchResult.isFetched,
      getNextPageParam: getNextPageParamHandler<GQLListSystemsQuery>(
        (query) => {
          if (query && 'listSystems' in query) {
            return query.listSystems?.length;
          }
          return undefined;
        }
      ),
      structuralSharing: true,

      // placeholderData: {
      //   pages:[],
      //   pageParams: []
      // }
      ...(opts ?? {}),
      staleTime: 1000 * 60 * 30,
      cacheTime: 1000 * 60 * 60,
      suspense: false,
      onError(err) {
        if (err) {
          console.log(err);
        }
      },
    }
  );

  const lastResultRef = useRef<GQLSystem[] | null>(null);
  let result: GQLSystem[] | null = null;
  useEffect(() => {
    const pages = systems?.pages ?? prefetchResult.data?.pages;
    if (pages) {
      const _gqlSystems = of(pages)
        .pipe(
          concatMap((_systems) => from(_systems ?? [])),
          map((page) => {
            if ('listSystems' in page) {
              return page?.listSystems;
            } else {
              return undefined;
            }
          }),
          filterNil(),
          concatMap((listSystems) => from(listSystems as GQLSystem[])),
          filterNil(),
          toArray(),
          map((_systems) =>
            _systems.sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0))
          ),
          last()
        )
        .subscribe({
          next: (v) => {
            if(!equal(v, lastResultRef.current)) {
              if (v) {
                systemStore.update((state) => ({
                  ...state,
                  fullCompanyGqlSystems: (companyId && !companySiteId) ? v : state.fullCompanyGqlSystems,
                  gqlSystems: (companySiteId) ? v : state.gqlSystems, //state.gqlSystems,
                  liveKPILastUpdatedTime:
                    dataUpdatedAt ?? state?.liveKPILastUpdatedTime,
                }));
                result = v;
                v.forEach((s) => {
                  AppQueryClient.setQueryData(['getSystem', { systemId: s.id }], {
                    getSystem: s,
                });
              });
              } else {
                systemStore.update((state) => ({
                  ...state,
                  fullCompanyGqlSystems: (companyId && !companySiteId) ? null : state.fullCompanyGqlSystems,
                  gqlSystems: (companySiteId) ? null : state.gqlSystems, //state.gqlSystems,
                  liveKPILastUpdatedTime:
                    dataUpdatedAt ?? state?.liveKPILastUpdatedTime,
                }));
                result = v;
              }

          
            }
            lastResultRef.current = v;
          },
        });
    } else {
      systemStore.update((state) => ({
        ...state,
        liveKPILastUpdatedTime: dataUpdatedAt ?? state?.liveKPILastUpdatedTime,
        fullCompanyGqlSystems: (companyId && !companySiteId) ? null : state.fullCompanyGqlSystems,
                gqlSystems: (companySiteId) ? null : state.gqlSystems, 
      }));
      result = null;
      lastResultRef.current = null;
    }
  }, [systems, prefetchResult]);

  // refetchIfPartialInfiniteData(systems, isFetching, refetch);
  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage();
  }
  return result;
}

export function useGQLSystem(
  { systemId, isActive }: {  systemId?: string | null; isActive?: true } = {},
  options?:
    | UseQueryOptions<GQLGetSystemQuery, unknown, GQLGetSystemQuery, QueryKey>
    | undefined
): GQLSystem | undefined {
  // const cachedPrefetch = AppQueryClient.getQueryState(['listSystems.infinite.prefetch', { skip: 0, limit: 100, status: "active", companyId: variables?.companyId, companySiteId: variables?.companySiteId }]);
  // const cachedFull = AppQueryClient.getQueryState(['listSystems.infinite.fullSite', { skip: 0, limit: 100, status: "active", companyId: variables?.companyId, companySiteId: variables?.companySiteId }]);
  
  const { data: system, dataUpdatedAt } = useGetSystemQuery(
    {
      systemId: systemId ?? '',
    },
    {
      // refetchInterval: 15000,
      enabled: !!systemId,
      suspense: false,
      ...(options || {}),
    }
  );

  useEffect(() => {
    if (!!systemId && dataUpdatedAt)
      systemStore.update((state) => ({
        ...state,
        liveKPILastUpdatedTime: dataUpdatedAt,
        ...(isActive && system?.getSystem
          ? { activeSystem: system?.getSystem }
          : {}),
      }));
  }, [dataUpdatedAt, systemId]);

  return system?.getSystem ?? undefined;
}

export function useUpdateArea(
  siteId?: string,
  areaId?: string,
  callbacks?: MutationCallbackMethods
) {
  const queryClient = useQueryClient();
  return useMutation(
    async (area: Area) => {
      if (!areaId) return null;
      const response = await areasApi.updateArea(areaId, area);
      return response.data;
    },
    {
      onMutate: async (data: Area) => {
        await Promise.all([
          queryClient.cancelQueries(['systems', siteId]),
          queryClient.cancelQueries(['areas', siteId]),
        ]);

        // optimistically update areas
        const prevAreasState = queryClient.getQueryData<Area[]>([
          'areas',
          siteId,
        ]);
        const newAreasData = prevAreasState ? [...prevAreasState] : [];
        const updatedAreasIdx =
          prevAreasState?.findIndex((x) => x.id === areaId) ?? -1;
        if (updatedAreasIdx !== -1) {
          newAreasData[updatedAreasIdx] = { id: areaId, ...data };
        }
        queryClient.setQueryData(['areas', siteId], newAreasData);

        // optimistically update area systems
        const previousSystemsState = queryClient.getQueryData<AreaSystem[]>([
          'systems',
          siteId,
        ]);
        const newSystemsData = previousSystemsState
          ? [...previousSystemsState]
          : [];
        const updatedAreaIdx =
          previousSystemsState?.findIndex((x) => x.area.id === areaId) ?? -1;
        if (updatedAreaIdx !== -1) {
          newSystemsData[updatedAreaIdx] = {
            ...newSystemsData[updatedAreaIdx],
            area: { id: areaId, ...data },
          };
        }
        queryClient.setQueryData(['systems', siteId], newSystemsData);
        return { previousState: previousSystemsState };
      },
      onError: (_err, _newSettings, context) => {
        if (context?.previousState) {
          queryClient.setQueryData(['systems', siteId], context.previousState);
        }
        callbacks?.onError(_err as string);
      },
      onSuccess: () => {
        callbacks?.onSuccess();
      },
    }
  );
}

export const useUpdateSystem = (
  onSuccess: (system: System | null) => void,
  onError: (err: string) => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (system: GQLSystem) => {
      const response = await updateSystem(system);
      return response;
    },
    {
      onMutate: async (system: GQLSystem) => {
        const systemData = queryClient.getQueryData<GQLSystem>([
          'getSystem',
          { systemId: system.id },
        ]);
        queryClient.setQueryData(['getSystem', { systemId: system.id }], {
          getSystem: system,
        });

        const listData = await optimisticUpdateInfiniteData<
          GQLListSystemsQuery,
          GQLSystem
        >(
          queryClient,
          queryKey(system.companySiteId),
          'listSystems.infinite',
          system
        );

        return {
          systemData,
          listData,
        };
      },
      onError: (err, system, context) => {
        if (context?.listData.previousState) {
          queryClient.setQueryData(
            queryKey(system.companySiteId),
            context.listData.previousState
          );
        }
        if (context?.systemData) {
          queryClient.setQueryData(['getSystem', { systemId: system.id }], {
            getSystem: context.systemData,
          });
        }
        onError(err as string);
      },
      onSuccess: (system) => {
        onSuccess(system);
      },
    }
  );
};

export const useAreaSystems = () => {
  const [{ activeSite, activeSiteAreaSystems }] =
    useObservable(_useAreaSystems$);
  useAreas(activeSite?.id);
  useGQLSystems(activeSite?.id);
  return activeSiteAreaSystems;
};
const _useAreaSystems$ = combineLatest({
  activeSite: activeSite$,
  activeSiteAreaSystems: activeSiteAreaSystems$,
}).pipe(shareReplay(1));

function toSystem(gqlSystem: GQLSystem): SystemBase {
  return {
    companyId: gqlSystem.companyId ?? '',
    companySiteId: gqlSystem.companySiteId ?? '',
    areaId: gqlSystem.areaId ?? '',
    name: gqlSystem.name ?? '',
    serialNumber: gqlSystem.serialNumber ?? undefined,
    modelNumber: gqlSystem.modelNumber ?? undefined,
    brandId: gqlSystem.brandId ?? undefined,
    sortOrder: gqlSystem.sortOrder ?? 0,
    type: gqlSystem.type
      ? (gqlSystem.type as SystemTypeEnum)
      : SystemTypeEnum.Profi,
    deploymentDate: gqlSystem.deploymentDate ?? '',
    status: 'active',
  };
}

interface SystemCount {
  type: string;
  count: number;
}

async function getSystemCounts(
  companyId?: string,
  siteId?: string
): Promise<SystemCount[] | null> {
  const entityId = companyId ? companyId : siteId ? siteId : undefined;
  const entityType = companyId ? 'company' : 'companySite';
  if (!entityId) return null;
  const res = await systemApi.getSystemCount(entityId, entityType);
  return [
    {
      type: 'profi',
      count: res.data.profi ?? 0,
    },
    {
      type: 'cbs',
      count: res.data.cbs ?? 0,
    },
    {
      type: 'cfs',
      count: res.data.cfs ?? 0,
    },
    {
      type: 'keraflux',
      count: res.data.keraflux ?? 0,
    },
  ];
}

export function useSystemCounts(companyId?: string, siteId?: string) {
  return useQuery(
    ['systemCounts', companyId, siteId],
    () => getSystemCounts(companyId, siteId),
    {
      placeholderData: [
        {
          type: 'profi',
          count: 0,
        },
        {
          type: 'cbs',
          count: 0,
        },
        {
          type: 'cfs',
          count: 0,
        },
        {
          type: 'keraflux',
          count: 0,
        },
      ],
    }
  );
}
