import { useQuery, UseQueryResult } from 'react-query';
import { CalendarMode, calendarModeAggregations } from '../enums';
import { ExecutiveSummary, ExecutiveSummaryApi } from '../generated';
import { getEndDate } from '../utils';
import { appConfiguration } from './configuration';

export const executiveSummaryApi = new ExecutiveSummaryApi(appConfiguration);

async function getExecutiveSummary(
  siteId: string | undefined | null,
  startDate: string,
  endDate: string,
  aggregation: 'hourly' | 'daily' | 'weekly' | 'monthly'
) {
  if (!siteId) return null;
  const res = await executiveSummaryApi.getExecutiveByCompanySiteId(
    siteId,
    startDate,
    endDate,
    aggregation
  );
  return res.data;
}

export function useExecutiveSummary(
  siteId: string | undefined | null,
  startDate: string,
  calendarMode: CalendarMode
): UseQueryResult<ExecutiveSummary> {
  const aggregation = calendarModeAggregations[calendarMode];
  const endDate = getEndDate(startDate, calendarMode);

  return useQuery(
    ['siteSettings', siteId, startDate, endDate, aggregation],
    () => getExecutiveSummary(siteId, startDate, endDate, aggregation)
  );
}
