import { useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Paper, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { User } from '@beeriot/api-client';
import { PasswordConditions } from '../../auth/password-conditions';
import { ShowHidePasswordButton } from '../../auth/ShowHidePasswordButton';
import { Form, FormError } from '../../form';
import { SettingsFooterButtons } from '../settings-form';
import { PasswordControls } from './PasswordControls';
import { UseMutationResult } from 'react-query';
import { FormErrorContainer } from '../../auth/FormErrorContainer';
import { ChangePasswordFormFields } from './ChangePasswordFormFields';

const StyledPaper = styled(Paper)({
  maxWidth: '40rem',
});

const StyledPasswordConditions = styled(PasswordConditions)({
  margin: '1rem',
});

const ShowPasswordContainer = styled(Stack)({
  alignItems: 'start',
  margin: '1rem',
});

const StyledFormErrorContainer = styled(FormErrorContainer)({
  margin: '1rem',
});

const defaultValues: ChangePasswordFormFields = {
  password: '',
  passwordVerify: '',
};

export interface ChangePasswordProps {
  mutation: UseMutationResult<User, Error, string, unknown>;
  error?: React.ReactNode;
}

export const ChangePassword = ({ mutation, error }: ChangePasswordProps) => {
  const intl = useIntl();
  const [showPassword, setShowPassword] = useState(false);
  const defaultFormValues = useMemo(() => ({ defaultValues }), []);

  return (
    <StyledPaper>
      <Form<ChangePasswordFormFields>
        onSubmit={(data) => mutation.mutate(data.password)}
        formProps={defaultFormValues}
      >
        <PasswordControls showPassword={showPassword} />
        <ShowPasswordContainer>
          <ShowHidePasswordButton
            $colorKey={'primary'}
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
        </ShowPasswordContainer>
        <StyledPasswordConditions $colorKey={'primary'} isVerifyEnabled />
        <StyledFormErrorContainer>
          <FormError error={error} color="info" />
        </StyledFormErrorContainer>
        <SettingsFooterButtons
          isLoading={mutation?.isLoading}
          isSuccess={mutation?.isSuccess}
          successLabel={intl.formatMessage({
            id: 'settings-change-password-success-message',
            defaultMessage: 'Successfully changed password.',
            description: 'label for change password success message',
          })}
        />
      </Form>
    </StyledPaper>
  );
};
