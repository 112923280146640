import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { IconLabel, LoadingPaper } from '../../base';

const ThresholdSettingsLoadingPaper = styled(LoadingPaper)({
  height: '100%',
  width: '40rem',
  margin: '0.5rem',
});

const ThresholdSettingsForm = lazy(() =>
  import('./ThresholdSettingsFormQuery').then(
    ({ ThresholdSettingsFormQuery }) => ({
      default: ThresholdSettingsFormQuery,
    })
  )
);

export function SuspenseThresholdSettingsForm() {
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <IconLabel
                label={
                  <FormattedMessage
                    id="settings-threshold-settings-error-label"
                    description="Error message for threshold settings error"
                    defaultMessage="Error loading threshold settings for this site"
                  />
                }
              />
            );
          }}
        >
          <Suspense fallback={<ThresholdSettingsLoadingPaper />}>
            <ThresholdSettingsForm />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
