import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  styled,
} from '@mui/material';
import { LoadingButton } from './LoadingButton';

const StyledStack = styled(DialogActions)({
  justifyContent: 'center',
  columnGap: '0.5rem',
});

const StyledTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.componentBase.main,
  borderTopRightRadius: theme.shape.borderRadius,
  borderTopLeftRadius: theme.shape.borderRadius,
  paddingLeft: theme.spacing(3),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

export interface AppDialogProps {
  open: boolean;
  dialogTitle: ReactNode;
  dialogContent: ReactNode;
  confirmLabel?: ReactNode;
  confirmIsLoading?: boolean;
  confirm: () => void;
  showCancelButton?: boolean;
  cancelLabel?: ReactNode;
  cancel?: () => void;
  dialogProps?: Omit<DialogProps, 'open'>;
}

export function AppDialog({
  open,
  dialogTitle,
  dialogContent,
  confirmLabel,
  confirmIsLoading = false,
  confirm,
  showCancelButton = true,
  cancelLabel,
  cancel,
  dialogProps,
}: AppDialogProps) {
  return (
    <Dialog open={open} onClose={cancel} maxWidth="md" {...dialogProps}>
      <StyledTitle>{dialogTitle}</StyledTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <StyledStack>
        {showCancelButton && (
          <Button color="shade" variant="contained" onClick={cancel}>
            {cancelLabel ?? (
              <FormattedMessage
                id="common-cancel-button"
                defaultMessage="Cancel"
                description="Label for cancel button"
              />
            )}
          </Button>
        )}
        <LoadingButton
          variant="contained"
          onClick={confirm}
          isLoading={confirmIsLoading}
        >
          {confirmLabel ?? (
            <FormattedMessage
              id="common-confirm-button"
              defaultMessage="Confirm"
              description="Confirm button"
            />
          )}
        </LoadingButton>
      </StyledStack>
    </Dialog>
  );
}
