import { styled, Stack, Button, useTheme } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useObservable } from '@ngneat/use-observable';
import {
  useSoftDeleteGroup,
  activeCompany$,
  activeGroupType$,
  useGroups,
  Group,
  groupStore,
} from '@beeriot/api-client';
import {
  SuspenseGroupList,
  GroupTypeListDrawer,
  ConfirmGroupDeleteDialog,
  AddGroupTypeDialog,
} from '../../../sites';
import { SuspenseOrganizeSitesControlBox } from './organize-sites-control-box';
import { RootPage } from '../../RootPage';
import { IconLabel, PermissionKey, PermissionView } from '../../../base';
import { useResponsiveSizes } from '../../../utils';
import { Form, FormSelect, SelectOption } from '../../../form';
import { CSSObjectWithLabel } from 'react-select';

interface GroupTypeSelectionFormFields {
  groupType: SelectOption;
}

const ContentStack = styled(Stack)({
  overflow: 'hidden',
  height: '100%',
});

const GroupsStack = styled(Stack)({
  overflow: 'auto',
  flex: '1 1 auto',
});

const StyledFormContainer = styled(Stack)({
  width: '100%',
  maxWidth: '40rem',
  gap: '1rem',
});

const ButtonContainer = styled(Stack)({
  width: '100%',
  maxWidth: '40rem',
});

export function OrganizeSitesPage() {
  const intl = useIntl();
  const theme = useTheme();
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [activeCompany] = useObservable(activeCompany$);
  const [activeGroupType] = useObservable(activeGroupType$);
  const { isMobileSize } = useResponsiveSizes();
  const { data: groups } = useGroups(activeCompany?.id ?? '');
  const groupTypes = groups?.filter((x) => !x?.parentGroupId);
  const deleteGroup = useSoftDeleteGroup();

  const groupTypeStyles = useMemo(
    () => ({
      control: (base: CSSObjectWithLabel) => ({
        ...base,
        backgroundColor: theme.palette.componentBase.main,
      }),
    }),
    [theme]
  );

  const groupTypeFilterLabel = intl.formatMessage({
    id: 'organize-sites-control-box-group-type-label',
    defaultMessage: 'Group Type:',
    description: 'Group type label',
  });

  const groupTypeOptions: SelectOption[] | undefined = groupTypes?.map((x) => {
    return { label: x.name, value: x.id };
  });

  const currGroup = useMemo(() => {
    const type =
      activeGroupType && groupTypes
        ? groupTypes.find((x) => x.id === activeGroupType.id)
        : null;
    return type ?? null;
  }, [groupTypes, activeGroupType]);

  const handleDeleteGroup = useCallback(
    (groupToDelete: Group | null) => {
      if (groupToDelete) {
        deleteGroup.mutate(groupToDelete);
        setOpenDelete(false);
      }
    },
    [deleteGroup]
  );

  return (
    <RootPage>
      <SuspenseOrganizeSitesControlBox />
      {isMobileSize ? (
        <Stack>
          {groupTypeOptions && (
            <StyledFormContainer sx={{ padding: '2rem 2rem 1rem 2rem' }}>
              <Form<GroupTypeSelectionFormFields>
                submitOnChange
                onSubmit={(data) => {
                  const selectedGroup = groupTypes?.find(
                    (x) => x.id === data.groupType.value
                  );
                  if (selectedGroup) {
                    groupStore.update((state) => ({
                      ...state,
                      activeGroupType: selectedGroup,
                    }));
                  }
                }}
              >
                {isMobileSize ? (
                  <FormSelect
                    styles={groupTypeStyles}
                    innerLabel={groupTypeFilterLabel}
                    name="groupType"
                    placeholder={''}
                    options={groupTypeOptions}
                    defaultValue={groupTypeOptions.find(
                      (x) => x.value === activeGroupType?.id
                    )}
                  />
                ) : null}
              </Form>
            </StyledFormContainer>
          )}
          <StyledFormContainer
            direction="row"
            sx={{ padding: '1rem 2rem 0 2rem' }}
          >
            <PermissionView
              permissionKey={
                PermissionKey.SitesOrganizeSitesCreateRegionGroupType
              }
            >
              <>
                {activeCompany?.id != null ? (
                  <ButtonContainer>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => setOpenCreate(true)}
                    >
                      <IconLabel
                        label={
                          <FormattedMessage
                            id="organize-sites-add-new-group-type-button"
                            description="Button that opens New Group Type dialog"
                            defaultMessage="New Group Type"
                          />
                        }
                        variant="body2"
                        icon={'plus'}
                        iconSize="lg"
                      />
                    </Button>
                  </ButtonContainer>
                ) : null}

                <AddGroupTypeDialog
                  companyId={activeCompany?.id ?? ''}
                  open={openCreate}
                  handleClose={() => setOpenCreate(false)}
                />
              </>
            </PermissionView>
            <PermissionView
              permissionKey={
                PermissionKey.SitesOrganizeSitesDeleteRegionGroupType
              }
            >
              <ButtonContainer>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setOpenDelete(true)}
                >
                  <IconLabel
                    gap="0.5rem"
                    icon={['far', 'trash-alt']}
                    variant="body2"
                    iconSize="lg"
                    label={
                      <FormattedMessage
                        id="sites-organize-sites-delete-group-type-button"
                        description="Button to delete a group type"
                        defaultMessage="Delete Group Type"
                      />
                    }
                  />
                </Button>
              </ButtonContainer>
            </PermissionView>
          </StyledFormContainer>
        </Stack>
      ) : null}
      <ContentStack direction="row">
        <GroupTypeListDrawer />
        <GroupsStack>
          <SuspenseGroupList />
        </GroupsStack>
      </ContentStack>
      <ConfirmGroupDeleteDialog
        mutation={deleteGroup}
        group={currGroup}
        open={openDelete}
        confirm={handleDeleteGroup}
        cancel={() => setOpenDelete(false)}
        isGroupType={true}
      />
    </RootPage>
  );
}
