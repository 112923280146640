import { useGQLSystemLiveStatus } from '@beeriot/api-client';
import { useResponsiveValues } from '../../../utils';
import {
  SystemCardBase,
  SystemCardBaseProps,
} from '../base-card-common/SystemCardBase';
import { SystemKpiGridAreaTemplate } from '../base-card-common/grids/SystemKpiHookProps';

// prettier-ignore
const templateArea: SystemKpiGridAreaTemplate = [
  ['titleHeader', 'titleHeader', 'titleHeader'],
  ['runningState', 'diaFiltration', 'timeSinceStart'],
  ];

// prettier-ignore
const mobileTemplateArea: SystemKpiGridAreaTemplate = [
    ['mobileTitleHeader', 'mobileTitleHeader', 'mobileTitleHeader'],
    ['diaFiltration', 'timeSinceStart', 'empty1'],
  ];

export const KerafluxSystemDetailCardBase = (props: SystemCardBaseProps) => {
  const { boldLabelTitles = false, system } = props;
  const gridTemplateAreas = useResponsiveValues<SystemKpiGridAreaTemplate>({
    xxs: mobileTemplateArea,
    md: templateArea,
  });
  useGQLSystemLiveStatus(system);
  return (
    <SystemCardBase
      {...props}
      gridTemplateAreas={props.gridTemplateAreas ?? gridTemplateAreas ?? []}
      boldLabelTitles={boldLabelTitles}
    />
  );
};
KerafluxSystemDetailCardBase.displayName = 'KerafluxSystemDetailCardBase';
