import { Navigate, useLocation } from 'react-router-dom';
import { useObservable } from '@ngneat/use-observable';
import { isAuthenticated$, useAutoLogout } from '@beeriot/api-client';

type RequireAuthProps = {
  children: JSX.Element;
};

export type LocationState = {
  path: string;
};

export function RequireAuth({ children }: RequireAuthProps) {
  const [isAuthenticated] = useObservable(isAuthenticated$);
  const location = useLocation();
  const locationState: LocationState = { path: location.pathname };

  useAutoLogout();

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/auth" replace state={locationState} />
  );
}
