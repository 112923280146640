import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Stack, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useObservable } from '@ngneat/use-observable';
import { activeCompany$ } from '@beeriot/api-client';
import { AppIcon, AppTypography, PermissionKey, PermissionView } from '../base';
import { useResponsiveSizes } from '../utils';
import { EditSystemDialog } from '../lines';
import { ContactSupportDialog, ConfirmExportDataDialog } from '../dialogs';

const StyledButton = styled(Button)({
  minWidth: '12rem',
  padding: '0.25rem 1rem',
  margin: '1rem 1rem 1rem 0rem',
});

const MachineControlsContainer = styled(Stack)({
  display: 'flex',
  marginLeft: 'auto',
  justifyContent: 'center',
  flexDirection: 'row',
});

interface LineSubTabsControls {
  routeKey: string;
  systemType?: string;
}

export const LineSubTabsControls = ({
  routeKey,
  systemType,
}: LineSubTabsControls) => {
  const [editSystemDialogOpen, setEditSystemDialogOpen] = useState(false);
  const [contactSupportDialogOpen, setContactSupportDialogOpen] =
    useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const navigate = useNavigate();
  const onConfirmDataExport = useCallback(() => {
    setOpenConfirmDialog(false);
  }, []);
  const openEditSystemDialog = () => setEditSystemDialogOpen(true);
  const closeEditSystemDialog = () => setEditSystemDialogOpen(false);
  const activeCompany = useObservable(activeCompany$);
  const navigateThresholds = () =>
    navigate(`/companies/${activeCompany[0]?.id}/settings/thresholds/`);

  const { isDesktopSize } = useResponsiveSizes();
  if (!isDesktopSize) return null;

  let editSystemPermissionKey: PermissionKey;
  let statisticsExportDataPermissionKey: PermissionKey;
  switch (systemType) {
    case 'profi':
      editSystemPermissionKey = PermissionKey.SitesPROFiSystemEdit;
      statisticsExportDataPermissionKey =
        PermissionKey.SitesPROFiStatisticsDataExport;
      break;
    case 'cbs':
      editSystemPermissionKey = PermissionKey.SitesCBSSystemEdit;
      statisticsExportDataPermissionKey =
        PermissionKey.SitesCBSStatisticsDataExport;

      break;
    case 'cfs':
      editSystemPermissionKey = PermissionKey.SitesCFSSystemEdit;
      statisticsExportDataPermissionKey =
        PermissionKey.SitesCFSStatisticsDataExport;

      break;
    case 'keraflux':
      editSystemPermissionKey = PermissionKey.SitesKerafluxSystemEdit;
      statisticsExportDataPermissionKey =
        PermissionKey.SitesKerafluxStatisticsDataExport;

      break;
    default:
      editSystemPermissionKey = PermissionKey.SitesPROFiSystemEdit;
      statisticsExportDataPermissionKey =
        PermissionKey.SitesPROFiStatisticsDataExport;
      break;
  }

  switch (routeKey) {
    case 'line-status':
      return (
        <>
          <MachineControlsContainer>
            <PermissionView permissionKey={editSystemPermissionKey}>
              <StyledButton
                variant="contained"
                startIcon={<AppIcon icon={'pen-to-square'} $colorKey="white" />}
                onClick={openEditSystemDialog}
              >
                <AppTypography>
                  <FormattedMessage
                    id="edit-system-button"
                    description="Button for editing systems"
                    defaultMessage="Edit System"
                  />
                </AppTypography>
              </StyledButton>
            </PermissionView>
            <PermissionView permissionKey={PermissionKey.SettingsThresholds}>
              <StyledButton
                variant="contained"
                color="secondary"
                startIcon={<AppIcon icon={'sliders'} $colorKey="white" />}
                onClick={navigateThresholds}
              >
                <AppTypography>
                  <FormattedMessage
                    id="thresholds-and-usage-button"
                    description="Button for navigating to Thresholds & Usage settings screen"
                    defaultMessage="Thresholds & Usage"
                  />
                </AppTypography>
              </StyledButton>
            </PermissionView>
          </MachineControlsContainer>
          <EditSystemDialog
            open={editSystemDialogOpen}
            onClose={closeEditSystemDialog}
          />
        </>
      );
    case 'statistics':
      return (
        <MachineControlsContainer>
          <PermissionView permissionKey={statisticsExportDataPermissionKey}>
            <StyledButton
              variant="contained"
              color={'success'}
              startIcon={<AppIcon icon={'download'} $colorKey="white" />}
              onClick={() => setOpenConfirmDialog(true)}
            >
              <AppTypography>
                <FormattedMessage
                  id="common-export-data-button"
                  description="Button to export data"
                  defaultMessage="Export Data"
                />
              </AppTypography>
            </StyledButton>
          </PermissionView>
          <PermissionView permissionKey={PermissionKey.SettingsThresholds}>
            <StyledButton
              variant="contained"
              color="secondary"
              startIcon={<AppIcon icon={'sliders'} $colorKey="white" />}
              onClick={navigateThresholds}
            >
              <AppTypography>
                <FormattedMessage
                  id="thresholds-and-usage-button"
                  description="Button for navigating to Thresholds & Usage settings screen"
                  defaultMessage="Thresholds & Usage"
                />
              </AppTypography>
            </StyledButton>
          </PermissionView>
          <ConfirmExportDataDialog
            open={openConfirmDialog}
            cancel={() => setOpenConfirmDialog(false)}
            confirm={onConfirmDataExport}
          />
        </MachineControlsContainer>
      );
    case 'filter-runs':
      return (
        <MachineControlsContainer>
          <PermissionView permissionKey={PermissionKey.SettingsThresholds}>
            <StyledButton
              variant="contained"
              color="secondary"
              startIcon={<AppIcon icon={'sliders'} $colorKey="white" />}
              onClick={navigateThresholds}
            >
              <AppTypography>
                <FormattedMessage
                  id="thresholds-and-usage-button"
                  description="Button for navigating to Thresholds & Usage settings screen"
                  defaultMessage="Thresholds & Usage"
                />
              </AppTypography>
            </StyledButton>
          </PermissionView>
        </MachineControlsContainer>
      );
    case 'documentation':
      return (
        <MachineControlsContainer>
          <StyledButton
            variant="contained"
            startIcon={<AppIcon icon={'life-ring'} $colorKey="white" />}
            onClick={() => setContactSupportDialogOpen(true)}
          >
            <AppTypography>
              <FormattedMessage
                id="common-contact-support"
                description="Label for contact support"
                defaultMessage="Contact Support"
              />
            </AppTypography>
          </StyledButton>
          <ContactSupportDialog
            open={contactSupportDialogOpen}
            onClose={() => setContactSupportDialogOpen(false)}
          />
        </MachineControlsContainer>
      );
    default:
      return null;
  }
};
