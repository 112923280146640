import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useObservable } from '@ngneat/use-observable';
import { activeCompany$, activeSite$ } from '@beeriot/api-client';
import { RouterLink } from '../../../../navigation/RouterLink';
import { AppTypography } from '../../../AppTypography';
import { Breadcrumb } from '../../Breadcrumb';
import { useIsExecSummaryPage } from '../../../../navigation/hooks';

export function RoutingSitesBreadcrumb({
  showActiveSiteTitle,
  disableBreadcrumb,
}: {
  showActiveSiteTitle?: boolean;
  disableBreadcrumb?: boolean;
}) {
  const isExecSummary = useIsExecSummaryPage();

  const [activeCompany] = useObservable(activeCompany$);
  const [activeSite] = useObservable(activeSite$);
  const navigate = useNavigate();

  const sitesOrExecSummaryFragment = isExecSummary ? 'exec-summary' : 'sites';
  const lastFragment = isExecSummary ? 'summary' : 'overview';
  const navLink = showActiveSiteTitle
    ? `/companies/${activeCompany?.id}/${sitesOrExecSummaryFragment}/${activeSite?.id}/${lastFragment}`
    : `/companies/${activeCompany?.id}/sites`;
  return (
    <Breadcrumb
      $stripeColorKey={'primary'}
      $followingItemStripeColorKey={'highlight2'}
      onClick={() => navigate(navLink)}
      disableBreadcrumb={disableBreadcrumb}
    >
      <AppTypography $bold={true} $colorKey={'primary'}>
        {showActiveSiteTitle ? (
          activeSite?.name ?? ''
        ) : (
          <FormattedMessage
            id="common-sites-label"
            defaultMessage="Sites"
            description="Sites label"
          />
        )}
      </AppTypography>
    </Breadcrumb>
  );
}
