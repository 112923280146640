import {
  CssBaseline,
  ThemeProvider,
} from '@mui/material';
import { createMemoryRouter, Route, RouterProvider } from 'react-router-dom';
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { devTools } from '@ngneat/elf-devtools';
import { QueryClientProvider } from 'react-query';
import { LocalizationProvider } from '@beeriot/localization';
import {
  setupAxiosInterceptors,
  AppQueryClient as queryClient
} from '@beeriot/api-client';
import { appTheme } from '../theme';
import { TabsProvider } from '../navigation/tabs/providers/TabsProvider';
import {
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import React, { Suspense, useEffect } from 'react';
import { Router } from '@remix-run/router';
import { ReactQueryDevtools } from 'react-query/devtools'

if (process.env.NODE_ENV === 'development') {
  devTools({
    logTrace:true,

    // requires a patch in elf-devtools
    //
    // logTraceFilter(action) {
    //     const watch = [
    //       '[Company] - Update',
    //       '[Site] - Update',
    //       '[System] - Update',
    //     ];
    //     return watch.includes(action?.type)
    // },
  });
}

interface AppProviderProps {
  isStorybook?: boolean;
  children: React.ReactNode;
}

interface AppMemoryRouterProps {
  children: React.ReactNode;
}

const makeAppRouter = ({ isStorybook, children }: AppProviderProps) => {
  return isStorybook
    ? createMemoryRouter(createRoutesFromElements(children), {
        initialEntries: ['/'],
      })
    : createBrowserRouter(createRoutesFromElements(children));
};

export const appRouterRef = {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  router: undefined as unknown as Router 
} 

export function AppProvider({ isStorybook, children }: AppProviderProps) {
  setupAxiosInterceptors(queryClient);
  // useEffect(() => {
    if(!appRouterRef.router && children) {
      appRouterRef.router = makeAppRouter({isStorybook, children})
    }
  // }, [isStorybook, children]);

  return (
   
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <MuiLocalizationProvider dateAdapter={AdapterDateFns}>
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider>
            <Suspense fallback={null}>
            <TabsProvider>
              { appRouterRef.router ? ( <RouterProvider
                router={appRouterRef.router}
              />) : undefined}
            </TabsProvider>
            </Suspense>
          </LocalizationProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </MuiLocalizationProvider>
    </ThemeProvider>
  
  );
}
