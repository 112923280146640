import { createStore, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { AuthProps } from './authStore.model';

export const maxRetries = 3;

export const authStore = createStore(
  { name: 'auth' },
  withProps<AuthProps>({
    userId: null,
    username: null,
    email: null,
    firstName: null,
    lastName: null,
    accessToken: null,
    refreshToken: null,
    session: null,
    isRefreshing: false,
    retryStatus: [],
  })
);
export const persist = persistState(authStore, {
  key: 'auth',
  storage: sessionStorageStrategy,
});
