import { ReactNode } from 'react';
import { Button, Stack, styled } from '@mui/material';
import { IconLabel } from '../../base/IconLabel';

const StyledStack = styled(Stack)({
  justifyContent: 'flex-end',
  padding: '1rem 0',
});

const StyledButton = styled(Button)({
  display: 'inline-block',
  width: 'fit-content',
  justifyContent: 'start',
});

// TODO: confirmation screen
export interface SettingsHeaderButtonProps {
  label?: ReactNode;
  onClick: () => void;
}

export function SettingsHeaderButton({
  label,
  onClick,
}: SettingsHeaderButtonProps) {
  return (
    <StyledStack direction="row">
      <StyledButton variant="contained" onClick={onClick}>
        <IconLabel
          variant="button"
          label={label}
          colorKey="white"
          icon={['far', 'circle-plus']}
          iconColorKey="white"
          iconSize="lg"
        />
      </StyledButton>
    </StyledStack>
  );
}
