import { useMemo } from 'react';
import { styled, Switch, SwitchProps, Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const IOSSwitchStyled = styled<typeof Switch>((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const InputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0.5rem',
  borderRadius: '0.5rem',
  border: 'none',
}));

interface FormSwitchProps {
  name: string;
  defaultValue?: boolean;
  required?: boolean;
  className?: string;
  readOnly?: boolean;
}

export function FormSwitch({
  name,
  defaultValue,
  required,
  className,
  readOnly,
}: FormSwitchProps) {
  const { control } = useFormContext();
  const rules = useMemo(() => {
    return { required };
  }, [required]);

  return (
    <InputContainer>
      <Controller
        name={name}
        rules={rules}
        render={({ field: { ref, ...fieldProps } }) => (
          // NOTE: Cannot pass ref to styled component this will throw errors
          <IOSSwitchStyled
            {...fieldProps}
            className={className}
            checked={fieldProps.value}
            readOnly={readOnly}
          />
        )}
        control={control}
        defaultValue={defaultValue ?? true}
      />
    </InputContainer>
  );
}
