import { createStore, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { GQLSystem } from '../../generated/gql';
import { TelemetryDataExportExportStatusEnum } from '../../generated';

export interface CompletedTelemetryExportRecord {
  companyId: string;
  siteId: string;
  systemId: string;
  exportStatus?: TelemetryDataExportExportStatusEnum;
}

export interface SystemProps {
  activeSystem: GQLSystem | null;
  liveKPILastUpdatedTime: number | null;

  gqlSystems: GQLSystem[] | null;
  fullCompanyGqlSystems: GQLSystem[] | null;


  completedTelemetryExport: CompletedTelemetryExportRecord | null;
}

export const systemStore = createStore(
  { name: 'system' },
  withProps<SystemProps>({
    activeSystem: null,
    liveKPILastUpdatedTime: null,
    gqlSystems: null,
    fullCompanyGqlSystems: null,
    completedTelemetryExport: null,
  })
);

persistState(systemStore, {
  key: 'system',
  storage: sessionStorageStrategy,
});
