import { FileWithPath } from 'react-dropzone';
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import globalAxios from 'axios';
import {
  Document,
  DocumentApi,
  DocumentBase,
  DocumentPutData,
  DocumentStatusEnum,
} from '../generated';
import { appConfiguration } from './configuration';
import { lineDocumentsStore } from '../state/lineDocumentsStore';

const DocumentQueryKey = 'document';
const DocumentsQueryKey = 'documents';

export const documentApi = new DocumentApi(appConfiguration);

export interface CreateUpdateDocumentRequest {
  document: DocumentBase;
  file?: FileWithPath;
}

interface Callbacks {
  onSuccess: (data: Document | undefined) => void;
  onError: (err: string) => void;
}

async function getDocuments(
  systemId: string,
  showDeletedDocuments: boolean,
  name: string
) {
  const res = await documentApi.getDocuments(
    0,
    100,
    systemId,
    undefined,
    name !== '' ? name.replace(/\s+/g, "+") : undefined,
    showDeletedDocuments
      ? DocumentStatusEnum.Inactive
      : DocumentStatusEnum.Active
  );
  return res.data;
}

async function getDocument(id: string) {
  const res = await documentApi.getDocument(id);
  return res.data;
}

export function useDeleteDocument() {
  const queryClient = useQueryClient();
  return useMutation(
    async (document: Document) => {
      const response = await documentApi.deleteDocument(document.id);
      return response.data;
    },
    {
      onSuccess: (data) => {
        const name = lineDocumentsStore.getValue().documentsSearchText;
        queryClient.refetchQueries([
          DocumentsQueryKey,
          data.systemId,
          false,
          name,
        ]);
      },
    }
  );
}

async function download(dataurl: string, filename: string) {
  const response = await globalAxios.get(dataurl, { responseType: 'blob' });
  const link = document.createElement('a');
  link.download = filename;
  link.href = URL.createObjectURL(response.data);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function useDownloadDocument() {
  return useMutation(
    async (item: Document) => {
      const response = await documentApi.getDocumentUrl(item.id);
      return {
        url: response.data.url,
        filename: item.filename ?? `${item.name}.${item.fileFormat}`,
      };
    },
    {
      onSuccess: async (data) => {
        if (!data.url) return;
        download(data.url, data.filename);
      },
    }
  );
}

export function useViewDocument() {
  return useMutation(
    async (item: Document) => {
      const response = await documentApi.getDocumentUrl(item.id);
      return {
        url: response.data.url,
        filename: item.filename ?? `${item.name}.${item.fileFormat}`,
      };
    },
    {
      onSuccess: async (data) => {
        console.log(`view document: ${data}`);
        if (!data.url) return;
      },
    }
  );
}

function refetchDocuments(queryClient: QueryClient, systemId?: string) {
  const name = lineDocumentsStore.getValue().documentsSearchText;
  const showDeletedDocuments =
    lineDocumentsStore.getValue().showDeletedDocuments;
  queryClient.refetchQueries([
    DocumentsQueryKey,
    systemId,
    showDeletedDocuments,
    name,
  ]);
}

export function useRestoreDocument({ onSuccess, onError }: Callbacks) {
  const queryClient = useQueryClient();
  return useMutation(
    async (item: Document) => {
      item.status = DocumentStatusEnum.Active;
      const response = await documentApi.getDocumentRestore(item.id);
      return response.data;
    },
    {
      onSuccess: async (data) => {
        refetchDocuments(queryClient, data?.systemId);
        onSuccess(data);
      },
      onError,
    }
  );
}

export function useUpdateDocument(
  documentId: string,
  { onSuccess, onError }: Callbacks
) {
  const queryClient = useQueryClient();
  return useMutation(
    async (request: CreateUpdateDocumentRequest) => {
      // First create document record.
      const updateDocumentResponse = await documentApi.updateDocument(
        documentId,
        request.document
      );

      if (request.file) {
        // Then get document upload url.
        const getDocumentUploadUrlResponse =
          await documentApi.getDocumentUploadUrl(
            updateDocumentResponse.data.id
          );

        if (updateDocumentResponse) {
          await uploadDocument(
            request,
            getDocumentUploadUrlResponse.data,
            updateDocumentResponse.data
          );
        }
      }

      return updateDocumentResponse.data;
    },
    {
      onSuccess: (data) => {
        refetchDocuments(queryClient, data?.systemId);
        onSuccess(data);
      },
      onError,
    }
  );
}

export function useCreateDocument({ onSuccess, onError }: Callbacks) {
  const queryClient = useQueryClient();
  return useMutation(
    async (request: CreateUpdateDocumentRequest) => {
      if (!request.file) return undefined;
      // First create document record.
      const createDocumentResponse = await documentApi.createDocument(
        request.document
      );

      // Then get document upload url.
      const getDocumentUploadUrlResponse =
        await documentApi.getDocumentUploadUrl(createDocumentResponse.data.id);

      if (getDocumentUploadUrlResponse) {
        await uploadDocument(
          request,
          getDocumentUploadUrlResponse.data,
          createDocumentResponse.data
        );
      }
      return createDocumentResponse.data;
    },
    {
      onSuccess: (data) => {
        refetchDocuments(queryClient, data?.systemId);
        onSuccess(data);
      },
      onError,
    }
  );
}

async function uploadDocument(
  request: CreateUpdateDocumentRequest,
  res: DocumentPutData,
  document: Document
) {
  const uploadUrl = res.url;
  if (!uploadUrl || !res.fields || !request.file) {
    console.error('Unable to obtain document upload url');
    return document;
  }
  const data = new FormData();

  data.append('Content-Type', request.file?.type);
  data.append('key', res.fields?.key ?? '');
  data.append('AWSAccessKeyId', res.fields?.AWSAccessKeyId ?? '');
  data.append(
    'x-amz-security-token',
    res.fields?.['x-amz-security-token'] ?? ''
  );
  data.append('policy', res.fields?.policy ?? '');
  data.append('signature', res.fields?.signature ?? '');
  data.append('file', request.file);

  await globalAxios.post(uploadUrl, data, {
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / progressEvent.total) * 100;
      lineDocumentsStore.update((state) => ({
        ...state,
        documentUploadProgress: progress,
      }));
    },
  });

  return document;
}

export function useDocuments(
  systemId: string,
  showDeletedDocuments = false,
  name: string
): UseQueryResult<Document[]> {
  return useQuery(
    [DocumentsQueryKey, systemId, showDeletedDocuments, name],
    () => getDocuments(systemId, showDeletedDocuments, name)
  );
}

export function useDocument(id: string): UseQueryResult<Document> {
  return useQuery([DocumentQueryKey, id], () => getDocument(id));
}
