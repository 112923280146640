import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { IconLabel } from '../../base/IconLabel';
import { LoadingPaper } from '../../base/LoadingPaper';

const FilterRunLoadingPaper = styled(LoadingPaper)({
  height: '35rem',
  width: '100%',
});

const FilterRunsTableItems = lazy(() =>
  import('./FilterRunsTableItemsQuery').then(
    ({ FilterRunsTableItemsQuery }) => ({
      default: FilterRunsTableItemsQuery,
    })
  )
);

export function SuspenseFilterRunsTableItems() {
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <IconLabel
                label={
                  <FormattedMessage
                    id="filter-run-error-label"
                    description="Error message for filter run loading"
                    defaultMessage="Error loading filter run details"
                  />
                }
              />
            );
          }}
        >
          <Suspense fallback={<FilterRunLoadingPaper />}>
            <FilterRunsTableItems />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
