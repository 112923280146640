import { DevTool } from '@hookform/devtools';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FormProvider,
  FieldValues,
  SubmitHandler,
  useForm,
  UseFormProps,
} from 'react-hook-form';

interface FormProps<T extends FieldValues> {
  onSubmit: SubmitHandler<T>;
  submitOnChange?: boolean;
  resetOnSubmit?: boolean;
  keepValuesForResetOnSubmit?: boolean;
  formProps?: UseFormProps<T>;
  className?: string;
  children: React.ReactNode;
  debug?: boolean;
}

export function Form<T extends FieldValues>({
  onSubmit,
  submitOnChange,
  resetOnSubmit,
  keepValuesForResetOnSubmit,
  formProps,
  className,
  children,
  debug,
}: FormProps<T>) {
  const defaultFormProps = useMemo(() => {
    const props: UseFormProps<T> = { ...formProps };
    if (!props.mode) {
      props.mode = 'onChange';
    }
    return props;
  }, [formProps]);
  const [prevFormProps, setPrevFormProps] =
    useState<UseFormProps<T>>(defaultFormProps);
  const methods = useForm<T>({ ...defaultFormProps });
  const isDevToolsEnabled = process.env.NODE_ENV === 'development' && debug;

  const submit: SubmitHandler<T> = useCallback(
    (data, event) => {
      onSubmit(data, event);
      if (resetOnSubmit) {
        if (keepValuesForResetOnSubmit) {
          methods.reset(undefined, { keepValues: true, keepIsValid: true });
        } else {
          methods.reset();
        }
      }
    },
    [keepValuesForResetOnSubmit, methods, onSubmit, resetOnSubmit]
  );

  useEffect(() => {
    if (JSON.stringify(prevFormProps) !== JSON.stringify(defaultFormProps)) {
      methods.reset(defaultFormProps?.defaultValues);
      setPrevFormProps(defaultFormProps);
    }
  }, [defaultFormProps, methods, prevFormProps]);

  useEffect(() => {
    const subscription = submitOnChange
      ? methods.watch(() => methods.handleSubmit(submit)())
      : undefined;
    return () => subscription?.unsubscribe();
  }, [submitOnChange, methods, submit]);

  return (
    <FormProvider {...methods}>
      <form className={className} onSubmit={methods.handleSubmit(submit)}>
        {children}
        {isDevToolsEnabled && <DevTool control={methods.control} />}
      </form>
    </FormProvider>
  );
}
