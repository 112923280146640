import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { styled } from '@mui/material';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { useResponsiveValues } from '../../../../../../utils/responsive-design/useResponsiveSizes';
import { KPIGridItem } from '../../kpi-grid/KPIGrid';
import { getEnumFromString } from '../../../../../../utils';
import { RunningState } from '../../../../../../enums';
import {
  CurrentStateLabel,
  CurrentStateLabelComponent,
} from '../../../../../../base';
import { SystemKpiHookProps } from '../../SystemKpiHookProps';
import { useShouldRenderKpi } from '../../useShouldRenderKpi';

interface RunningStatusHookProps extends SystemKpiHookProps {
  prefiltrationState?: boolean;
  finalFiltrationState?: boolean;
}

const StyledCurrentStateLabel = styled(CurrentStateLabel)({
  minHeight: '3.25rem',
});
export function useRunningStatusGridItem(props: RunningStatusHookProps) {
  const { system, gridArea } = props;
  const shouldRender = useShouldRenderKpi(props);
  const intl = useIntl();
  const prefiltrationSubLabel = intl.formatMessage({
    id: 'cbs-prefiltration-state-label',
    defaultMessage: 'Prefiltration State',
    description: 'Prefiltration state label',
  });
  const finalFiltrationSubLabel = intl.formatMessage({
    id: 'cbs-final-filtration-state-label',
    defaultMessage: 'Final Filtration State',
    description: 'Final filtration state label',
  });

  const subLabelOverride = props.prefiltrationState
    ? prefiltrationSubLabel
    : props.finalFiltrationState
    ? finalFiltrationSubLabel
    : undefined;

  const runningStatusIconSize = useResponsiveValues<SizeProp>({
    xxs: 'sm',
    md: 'lg',
  });
  return useMemo(() => {
    if (!shouldRender) {
      return undefined;
    }
    const runningState = getEnumFromString(
      RunningState,
      system?.liveStatus?.currentSystemStatus
    );
    return (
      <KPIGridItem gridArea={gridArea ?? 'runningState'}>
        <StyledCurrentStateLabel
          labelComponentType={CurrentStateLabelComponent.KPIVerticalLabel}
          labelBold
          labelVariant="h6"
          subLabelOverride={subLabelOverride}
          iconSize={runningStatusIconSize}
          state={runningState}
        />
      </KPIGridItem>
    );
  }, [
    shouldRender,
    system?.liveStatus?.currentSystemStatus,
    gridArea,
    subLabelOverride,
    runningStatusIconSize,
  ]);
}
