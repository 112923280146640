import {
  GQLGetSystemStatsQuery,
  GQLSystemStatState,
} from '../../../generated/gql';
import { ComparableSection } from '../lineStatistics';

export type SystemStatsKey = keyof Omit<
  GQLSystemStatState,
  'week' | 'month' | 'year'
>;

export function createComparableSection(
  keys: string[],
  data?: GQLGetSystemStatsQuery
): ComparableSection {
  const current =
    data?.getSystemStats?.current && data.getSystemStats.current.length > 0
      ? data.getSystemStats.current[0]
      : undefined;
  const compare =
    data?.getSystemStats?.compare && data.getSystemStats.compare.length > 0
      ? data.getSystemStats.compare[0]
      : undefined;

  const section: ComparableSection = {
    current: {},
    compare: {},
  };
  keys.forEach((x) => {
    section.current[x] = current?.[x as SystemStatsKey];
    section.compare[x] = compare?.[x as SystemStatsKey];
  });
  return section;
}
