import { ReactNode, useCallback, useMemo } from 'react';
import { useObservable } from '@ngneat/use-observable';
import {
  execSummarySelectedLineGraph$,
  execSummaryStore,
  LineGraphType,
} from '@beeriot/api-client';
import { GridItemStatus } from '../../types/GridItemStatus';
import { ExpandableGraphCard } from './ExpandableGraphCard';

interface ExpandableLineGraphCardProps {
  title: ReactNode;
  graphType: LineGraphType;
  children: ReactNode;
}

export function ExpandableLineGraphCard({
  title,
  graphType,
  children,
}: ExpandableLineGraphCardProps) {
  const [selectedLineGraph] = useObservable(execSummarySelectedLineGraph$);

  const status = useMemo((): GridItemStatus => {
    if (selectedLineGraph === null) return 'default';
    const status = graphType === selectedLineGraph ? 'selected' : 'hidden';

    return status;
  }, [graphType, selectedLineGraph]);

  const toggleExpand = useCallback(() => {
    const item = selectedLineGraph === graphType ? null : graphType;
    execSummaryStore.update((state) => ({
      ...state,
      selectedLineGraph: item,
    }));
  }, [graphType, selectedLineGraph]);

  return (
    <ExpandableGraphCard
      status={status}
      title={title}
      toggleExpand={toggleExpand}
    >
      {children}
    </ExpandableGraphCard>
  );
}
