import { SystemStatsCategory } from '../../../enums';
import { GQLGetSystemStatsQuery } from '../../../generated/gql';
import { LineStatisticsGroup } from '../lineStatistics';
import { createComparableSection } from './createComparableSection';
import { processSystemStatsSection } from './processSystemStatsSection';

export function getProfiStatisticsGroups(
  processQuery?: GQLGetSystemStatsQuery,
  cleaningConsumptionQuery?: GQLGetSystemStatsQuery,
  qualityQuery?: GQLGetSystemStatsQuery
): LineStatisticsGroup[] {
  const process = createComparableSection(
    [
      'volumeFiltered',
      'averageFlow',
      'degreeOfEfficiency',
      'tmpIncrease',
      'filtrationOperatedToFinalTMP',
      'capacityFullRunsTillTMPBar',
      'filterLineUtilization',
      'netCapacity',
      'totalCIPS',
      'beerLossByFilterLoss',
    ],
    processQuery
  );
  const processGroup = processSystemStatsSection(
    SystemStatsCategory.ProcessData,
    process
  );

  const cleaningConsumption = createComparableSection(
    [
      'water',
      'caustic',
      'peroxyde',
      'acidicChemical',
      'membraneCleaner',
      'kWHl',
    ],
    cleaningConsumptionQuery
  );
  const cleaningConsumptionGroup = processSystemStatsSection(
    SystemStatsCategory.CleaningConsumption,
    cleaningConsumption
  );

  const quality = createComparableSection(
    ['oxygen', 'hazeCentrifugeOut', 'hazeFilterOut', 'clusterTestFailurePct'],
    qualityQuery
  );
  const qualityGroup = processSystemStatsSection(
    SystemStatsCategory.Quality,
    quality
  );

  const groups: LineStatisticsGroup[] = [
    processGroup,
    cleaningConsumptionGroup,
    qualityGroup,
  ];

  return groups;
}
