import { useMemo, ReactNode } from 'react';
import { getEnumFromString } from '../../../../../../utils';
import { RunningState } from '../../../../../../enums';
import { SystemKpiHookProps } from '../../SystemKpiHookProps';
import { useShouldRenderKpi } from '../../useShouldRenderKpi';
import { SystemCardHeader } from '../../header/SystemCardHeader';

export function useSystemTitleHeaderGridItem(
  props: SystemKpiHookProps & {
    cardTitle?: string;
    headerContent?: ReactNode;
  }
) {
  const { system, headerContent, cardTitle, gridArea } = props;
  const shouldRender = useShouldRenderKpi(props);

  return useMemo(() => {
    if (!shouldRender) {
      return undefined;
    }
    const debugProps = {
      'data-kpi-grid-area': gridArea ?? 'titleHeader',
    };
    return (
      headerContent ?? (
        <SystemCardHeader
          {...debugProps}
          gridArea={gridArea ?? 'titleHeader'}
          cardTitle={cardTitle ?? (system?.name as string | undefined) ?? ''}
          showDivider={false}
        />
      )
    );
  }, [shouldRender, system?.name, headerContent, gridArea, cardTitle]);
}

export function useSystemCompactTitleHeaderGridItem(
  props: SystemKpiHookProps & {
    cardTitle?: string;
    headerContent?: ReactNode;
  }
) {
  const { system, headerContent, cardTitle, gridArea } = props;
  const shouldRender = useShouldRenderKpi(props);

  return useMemo(() => {
    if (!shouldRender) {
      return undefined;
    }
    const runningState = getEnumFromString(
      RunningState,
      system?.liveStatus?.currentSystemStatus
    );
    const debugProps = {
      'data-kpi-grid-area': gridArea ?? 'titleHeader',
    };
    return (
      headerContent ?? (
        <SystemCardHeader
          {...debugProps}
          gridArea={gridArea ?? 'mobileTitleHeader'}
          cardTitle={cardTitle ?? (system?.name as string | undefined) ?? ''}
          runningState={runningState}
          showDivider={true}
        />
      )
    );
  }, [
    shouldRender,
    system?.liveStatus?.currentSystemStatus,
    system?.name,
    headerContent,
    gridArea,
    cardTitle,
  ]);
}
