import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
  useState,
  ComponentProps,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import { Stack, styled, Box } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import {
  useTabRoutingState,
  useTabsRoutesProps,
} from '../tabs/hooks/useTabRoutingState';
import { RoutesCustom } from '../RoutesCustom';
import { transientOptions, useResponsiveSizes } from '../../utils';
import { MaxDetail, RoutingBreadcrumbs } from '../../base';
import { setDropdownText, setIsNavigationMenuOpen } from '@beeriot/api-client';

import { SidebarTabsButton } from './SidebarTabsButton';
import { RoutesContainer } from '../RoutesContainer';
import { MobileTabDrawer } from '../mobile-tab-drawer/MobileTabDrawer';

const RootStack = styled(Stack)({
  height: '100%',
  overflow: 'hidden',
});

const SidebarStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.componentBase.main,
  padding: '1rem',
  zIndex: theme.zIndex.appBar + 1,
  boxShadow: theme.shadows[3],
}));

const FooterContainer = styled(Box)({
  marginTop: 'auto',
});

const StyledTabs = styled(
  TabList,
  transientOptions
)(({ theme }) => ({
  height: '100%',
  '& .MuiTabs-flexContainer': {
    height: '100%',
    minWidth: '15rem',
  },
  '& .MuiTypography-root': {
    margin: 0,
  },
  '& .MuiTabs-indicator': {
    background: 'clear',
    opacity: 0,
  },
  '& .MuiTabs-scrollButtons.Mui-disabled': {
    opacity: 0.3,
  },
}));

type SidebarTabsProps = PropsWithChildren<useTabsRoutesProps> & {
  header?: ReactNode;
  footer?: ReactNode;
  breadcrumbMaxDetail?: MaxDetail;
  dropdownPlaceholderText?: string;
  parentPageTitle?: string;
  parentPagePath?: string;
};

export const SidebarTabs: React.FC<SidebarTabsProps> = ({
  header,
  footer,
  tabsKey: name,
  routes,
  lastRouteMatch,
  tabButtonRenderer,
  tabPanelRenderer,
  ignoreLastRouteMatch,
  // TODO: these probabaly are not necessary anymore
  breadcrumbMaxDetail,
  dropdownPlaceholderText,
  parentPageTitle,
  parentPagePath,
}) => {
  const { activeIdx, activeIdxStr, tabs, panels, tabRoutes } =
    useTabRoutingState({
      tabsKey: name,
      lastRouteMatch,
      routes: routes,
      tabButtonRenderer,
      tabPanelRenderer,
      ignoreLastRouteMatch,
    });
  const routesContainerRef = useRef<HTMLDivElement | null>(null);

  const intl = useIntl();
  const { isMobileSize } = useResponsiveSizes();
  const sidebarTabsAriaLabel = intl.formatMessage({
    id: 'common-sidebar-tabs-aria-label',
    description: 'Sidebar navigation tabs aria label',
    defaultMessage: 'Sidebar Navigation Tabs',
  });

  const tabLabel = tabRoutes?.[activeIdx]?.label;
  useEffect(() => {
    if (tabLabel) setDropdownText(tabLabel);
  }, [tabLabel]);

  const [isOpen, setIsOpen] = useState(false);

  // loop through the tabs and attach click handlers
  // to manage open state, dropdown labelling,
  // and click handlers passed down through props
  const tabButtons = useMemo(() => {
    return React.Children.map(tabs, (child, idx: number) => {
      if (
        !React.isValidElement<ComponentProps<typeof SidebarTabsButton>>(child)
      ) {
        // console.log(`not valid element: `, child);
        return child;
      }

      let elementChild: React.ReactElement<
        ComponentProps<typeof SidebarTabsButton>
      > = child;
      // if (child.props) {
      elementChild = React.cloneElement<
        ComponentProps<typeof SidebarTabsButton>
      >(elementChild, {
        onClick: (ev) => {
          child?.props?.onClick?.(ev);
          setIsOpen(false);
          setIsNavigationMenuOpen(false);
          const tabLabel = tabRoutes?.[idx]?.label;
          if (tabLabel) setDropdownText(tabLabel);
        },
      });
      // console.log(`attached clicks`);
      // }
      return elementChild;
    });
  }, [tabRoutes, tabs, setIsOpen]);
  const tabsMenu = (
    <SidebarStack>
      {header}
      <StyledTabs
        orientation={'vertical'}
        aria-label={sidebarTabsAriaLabel}
        variant={'scrollable'}
        scrollButtons={false}
      >
        {tabButtons}
      </StyledTabs>
      <FooterContainer>{footer}</FooterContainer>
    </SidebarStack>
  );
  const tabsDrawer = (
    <MobileTabDrawer
      isOpen={isOpen}
      isMobileSize={isMobileSize}
      containerRef={routesContainerRef}
      setIsOpen={setIsOpen}
    >
      {tabsMenu}
    </MobileTabDrawer>
  );

  return (
    <TabContext value={activeIdxStr}>
      <RootStack direction={isMobileSize ? 'column' : 'row'}>
        {isMobileSize ? (
          <RoutingBreadcrumbs
            didSelectLastBreadCrumb={() => {
              setIsOpen(true);
            }}
          />
        ) : undefined}
        {!isMobileSize || (isMobileSize && isOpen) ? tabsDrawer : undefined}
        <RoutesContainer ref={routesContainerRef}>
          <RoutesCustom>{panels}</RoutesCustom>
        </RoutesContainer>
      </RootStack>
    </TabContext>
  );
};
