import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AppTypography } from '../../../AppTypography';
import { Breadcrumb, BreadcrumbProps } from '../../Breadcrumb';
import { useCallback } from 'react';

export type RoutingBreadcrumbProps = Partial<BreadcrumbProps> & {
  localizerKey?: string | undefined;
  label?: string | undefined;
  to?: string | undefined;
};

export function RoutingRootBreadcrumb(props: RoutingBreadcrumbProps) {
  const { localizerKey, label, to } = props;
  const navigate = useNavigate();
  const intl = useIntl();

  const msg = localizerKey && intl.messages[localizerKey];
  const _label = msg
    ? typeof msg === 'string'
      ? msg
      : label
      ? label
      : ''
    : '';
  const _onClick = useCallback(() => {
    // we want to be able to have click events pass through
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (props as any)?.onClick?.();
    to && navigate(to);
  }, [props, to, navigate]);
  return (
    <Breadcrumb
      {...(props ?? {})}
      $stripeColorKey={'primary'}
      $followingItemStripeColorKey={'highlight2'}
      onClick={_onClick}
    >
      <AppTypography $bold={true} $colorKey={'primary'}>
        {label ?? _label}
      </AppTypography>
    </Breadcrumb>
  );
}
