import { styled } from '@mui/material';
import { GQLCompanySite, Group } from '@beeriot/api-client';
import { AppCard } from '../../../base';
import { GroupHeader } from './GroupHeader';
import { GroupSitesGrid } from './GroupSitesGrid';

const StyledAppCard = styled(AppCard)(({ theme }) => ({
  width: '100%',
  minHeight: 'max-content',
  margin: '0.5rem 0',
  padding: '1rem',
  backgroundColor: theme.palette.componentBase.main,
}));

export interface GroupListItemProps {
  group: Group;
  sites: GQLCompanySite[];
}

export function GroupCard({ group, sites }: GroupListItemProps) {
  return (
    <StyledAppCard>
      <GroupHeader group={group} />
      <GroupSitesGrid group={group} sites={sites} />
    </StyledAppCard>
  );
}
