import { Button, ButtonPropsVariantOverrides } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { ReactNode, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AppDialog } from './AppDialog';

export interface ConfirmDeleteButtonProps {
  onDelete: () => void;
  buttonTitle?: ReactNode;
  dialogTitle: ReactNode;
  dialogMessage: ReactNode;
  buttonVariant?: OverridableStringUnion<
    'text' | 'outlined' | 'contained',
    ButtonPropsVariantOverrides
  >;
  startIcon?: ReactNode;
}

export const ConfirmDeleteButton = ({
  onDelete,
  buttonTitle,
  dialogTitle,
  dialogMessage,
  buttonVariant,
  startIcon,
}: ConfirmDeleteButtonProps) => {
  const intl = useIntl();

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const showConfirmDelete = useCallback(() => {
    setOpenConfirmDeleteDialog(true);
  }, []);

  const cancelDelete = useCallback(() => {
    setOpenConfirmDeleteDialog(false);
  }, []);

  const confirmDelete = useCallback(() => {
    setOpenConfirmDeleteDialog(false);
    onDelete();
  }, [onDelete]);

  return (
    <>
      <Button
        color="error"
        startIcon={startIcon}
        onClick={showConfirmDelete}
        variant={buttonVariant ?? 'text'}
      >
        {buttonTitle ?? (
          <FormattedMessage
            id="common-delete-button"
            defaultMessage="Delete"
            description="Button for delete"
          />
        )}
      </Button>
      <AppDialog
        dialogTitle={dialogTitle}
        dialogContent={dialogMessage}
        open={openConfirmDeleteDialog}
        confirm={confirmDelete}
        confirmLabel={intl.formatMessage({
          id: 'common-yes-delete-button',
          defaultMessage: 'Yes, Delete',
          description: 'label for confirm delete button',
        })}
        showCancelButton
        cancel={cancelDelete}
      />
    </>
  );
};
