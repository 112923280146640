import { Stack, styled } from '@mui/material';

export const SectionControl = styled(Stack)(({ theme }) => ({
  display: 'flex',
  borderBottom: 'solid 1px',
  alignItems: 'center',
  borderColor: theme.palette.grey[300],
  '& > :first-of-type': {
    width: '45%',
  },
  '& > :last-child': {
    width: '55%',
  },
}));

SectionControl.defaultProps = {
  direction: 'row',
};

export const VerticalSectionControl = styled(Stack)(({ theme }) => ({
  display: 'flex',
  borderBottom: 'solid 1px',
  alignItems: 'center',
  borderColor: theme.palette.grey[300],
}));
