import { Button, Stack, styled } from '@mui/material';
import {
  GQLCompanySite,
  useUpdateSite,
  removeFalsey,
  toCompanySiteBase,
  Group,
} from '@beeriot/api-client';
import {
  AppCard,
  AppIcon,
  PermissionKey,
  PermissionView,
  VerticalLabels,
} from '../../../base';
import { useCallback, useState } from 'react';
import { ConfirmSiteDeleteDialog } from './ConfirmSiteDeleteDialog';

const StyledCard = styled(AppCard)({
  padding: '0.5rem 1rem',
});

const StackContainer = styled(Stack)({
  justifyContent: 'space-between',
  gap: '0.5rem',
});

export interface SiteDeletionCardProps {
  site: GQLCompanySite;
  group: Group;
}

export function SiteDeletionCard({ site, group }: SiteDeletionCardProps) {
  const [open, setOpen] = useState<boolean>(false);
  const updateSite = useUpdateSite(site.companyId ?? undefined);

  const handleDeleteSite = useCallback(() => {
    if (site.groups) {
      const updatedGroups = removeFalsey(
        site.groups?.filter((x) => x !== group.id)
      );
      updateSite.mutate({
        ...toCompanySiteBase(site),
        id: site.id,
        groups: updatedGroups,
      });
      setOpen(false);
    }
  }, [updateSite, site, group]);

  return (
    <StyledCard>
      <StackContainer direction="row">
        <VerticalLabels
          label={site.name}
          labelVariant={'body1'}
          subLabel={site?.address?.state}
          subLabelVariant={'subtitle1'}
          subLabelIcon={'location-dot'}
        />
        <PermissionView
          permissionKey={
            PermissionKey.SitesOrganizeSitesRemoveSiteFromRegionGroup
          }
        >
          <Button onClick={() => setOpen(true)}>
            <AppIcon
              $colorKey="error"
              icon={['far', 'circle-minus']}
              size="2x"
            />
          </Button>
        </PermissionView>
      </StackContainer>
      <ConfirmSiteDeleteDialog
        mutation={updateSite}
        site={site}
        groupName={group.name}
        open={open}
        confirm={handleDeleteSite}
        cancel={() => setOpen(false)}
      />
    </StyledCard>
  );
}
