import { useMutation } from 'react-query';
import {
  Company,
  CompanyBase,
  CompanySite,
  CompanySiteBase,
  SystemBase,
  System,
  SystemBaseTypeEnum,
  AreaBase,
  Area,
  Address,
  SystemBaseProvisioningBlocks,
  SystemBaseProvisioning,
  SettingsSystemsServiceSettingsEnum,
  SystemBaseProvisioningColumns,
} from '../generated/api';
import { companyApi } from './companies';
import { siteApi } from './sites/sites';
import { areasApi } from './systems/systems';
import { systemApi } from './systems/systemApi';
import { provisionDevice } from './iotProvisioning';

export interface ProvisioningPayload {
  companyName?: string;
  companyId?: string;
  companyAddress?: Address;
  siteName?: string;
  siteId?: string;
  siteAddress?: Address;
  areaId?: string;
  areaName?: string;
  machineType: string;
  machineName: string;
  machineSerialNumber: string;
  deploymentDate: string;
  blocksQuantity?: number;
  blockClustersQuantity?: number;
  modulePartNumber?: string;
  clusterModuleQuanity?: number;
  consumablesQuantity?: number;
  consumablesProductNumber?: string;
  consumablesInstallDate?: string;
  columnsQuantity?: number;
  columnsCassettes?: number;
  columnsPosition?: number;
  columnsClustersQuantity?: number;
  squareMetersPerModule?: number;
  moduleMembraneArea?: number;
}

type Saga<T = unknown> = Promise<T> & {
  rollback: () => Promise<void>;
};

const addCompany = async (company: CompanyBase) => {
  const response = await companyApi.createCompany(company);
  return response.data;
};

const deleteCompany = async (companyId: string) => {
  const response = await companyApi.deleteCompany(companyId);
  return response.data;
};

function createCompanySaga(
  companyName: string,
  companyAddress: Address | undefined
) {
  let result: Company;

  const company: CompanyBase = {
    name: companyName ?? '',
    address: companyAddress,
    status: 'active',
  };
  const p = new Promise((res, rej) => {
    addCompany(company)
      .then((_result) => {
        result = _result;
        res(result);
      })
      .catch(rej);
  }) as Saga<Company>;
  p.rollback = async () => {
    await deleteCompany(result.id);
  };
  return p;
}

const addCompanySite = async (companySiteBase: CompanySiteBase) => {
  const response = await siteApi.createCompanySite(companySiteBase);
  return response.data;
};

const deleteCompanySite = async (companySiteId: string) => {
  const response = await siteApi.deleteCompanySite(companySiteId);
  return response.data;
};

function createCompanySiteSaga(
  companyId: string,
  siteName: string,
  siteAddress: Address | undefined
) {
  let result: CompanySite;

  const site: CompanySiteBase = {
    companyId: companyId,
    name: siteName,
    address: siteAddress,
    status: 'active',
  };
  const p = new Promise((res, rej) => {
    addCompanySite(site)
      .then((_result) => {
        result = _result;
        res(result);
      })
      .catch(rej);
  }) as Saga<CompanySite>;
  p.rollback = async () => {
    await deleteCompanySite(result.id);
  };
  return p;
}

const addArea = async (areaBase: AreaBase) => {
  const response = await areasApi.createArea(areaBase);
  return response.data;
};

const deleteArea = async (areaId: string) => {
  const response = await areasApi.deleteArea(areaId);
  return response.data;
};

function createAreaSaga(
  companyId: string,
  companySiteId: string,
  areaName: string
) {
  let result: Area;

  const area: AreaBase = {
    companyId,
    companySiteId,
    name: areaName,
    status: 'active',
  };
  const p = new Promise((res, rej) => {
    addArea(area)
      .then((_result) => {
        result = _result;
        res(result);
      })
      .catch(rej);
  }) as Saga<Area>;
  p.rollback = async () => {
    await deleteArea(result.id ?? '');
  };
  return p;
}

const addSystem = async (systemBase: SystemBase) => {
  const response = await systemApi.createSystem(systemBase);
  return response.data;
};

const deleteSystem = async (systemId: string) => {
  const response = await systemApi.deleteSystem(systemId);
  return response.data;
};

function createSystemSaga(
  companyId: string,
  companySiteId: string,
  areaId: string,
  payload: ProvisioningPayload
) {
  let result: System;

  const columns = [];
  const blocks: SystemBaseProvisioningBlocks = {
    quantity: payload?.blocksQuantity ?? 0,
    clusters: {
      quantity: payload?.blockClustersQuantity ?? 0,
      modules: {
        quantity: payload?.clusterModuleQuanity ?? 0,
        partNumber: payload?.modulePartNumber ?? '',
      },
      consumables: {
        quantity: payload?.consumablesQuantity ?? 0,
        productNumber: payload?.consumablesProductNumber ?? '',
        installDate: payload?.consumablesInstallDate ?? '',
      },
    },
  };

  for (let i = 0; i < (payload?.columnsQuantity ?? 0); i++) {
    const column: SystemBaseProvisioningColumns = {
      cassettes: payload?.columnsCassettes ?? 0,
      position: payload?.columnsPosition ?? 0,
      clusters: { quantity: payload.columnsClustersQuantity ?? 0 },
    };
    columns.push(column);
  }
  const provisioning: SystemBaseProvisioning =
    columns.length == 0
      ? {
          blocks: blocks ?? [],
          settings: {
            degreeOfEfficiency: {
              green: 0,
              red: 0,
            },
            actualFlow: {
              green: 0,
              yellow: 0,
              red: 0,
            },
            serviceSettings: {
              serviceInterval: {
                unitOfMeasure: 'seconds',
                value: 0,
              },
              // API typing for service settings is incorrect
            } as unknown as SettingsSystemsServiceSettingsEnum,
          },
        }
      : {
          columns: columns ?? [],
          settings: {
            degreeOfEfficiency: {
              green: 0,
              red: 0,
            },
            actualFlow: {
              green: 0,
              yellow: 0,
              red: 0,
            },
            serviceSettings: {
              serviceInterval: {
                unitOfMeasure: 'seconds',
                value: 0,
              },
              // API typing for service settings is incorrect
            } as unknown as SettingsSystemsServiceSettingsEnum,
          },
        };

  const system: SystemBase = {
    companyId,
    companySiteId,
    areaId,
    name: payload.machineName,
    type: payload.machineType as SystemBaseTypeEnum,
    serialNumber: payload.machineSerialNumber,
    deploymentDate: payload.deploymentDate,
    blockCount: provisioning.blocks?.quantity ?? 0,
    clusterCount: provisioning.blocks?.clusters.quantity ?? 0,
    moduleCount: payload.clusterModuleQuanity ?? 0,
    provisioning: provisioning,
    membraneArea: payload.moduleMembraneArea?.toString() ?? '',
    squareMetersPerModule: payload.squareMetersPerModule ?? 0,
    status: 'active',
  };
  const p = new Promise((res, rej) => {
    addSystem(system)
      .then((_result) => {
        result = _result;
        res(result);
      })
      .catch(rej);
  }) as Saga<System>;
  p.rollback = async () => {
    await deleteSystem(result.id);
  };
  return p;
}

export const useProvisioning = (
  onSuccess: () => void,
  onError: (err: string) => void
) => {
  return useMutation(
    async (payload: ProvisioningPayload) => {
      let company: Company | null = null;
      let companySite: CompanySite | null = null;
      let system: System | null = null;
      let companySaga: Saga<Company> | undefined;

      if (payload.companyId == undefined) {
        //create new company
        //wait for company creation completion
        companySaga = createCompanySaga(
          payload?.companyName ?? '',
          payload.companyAddress
        );
        const _company = await companySaga;
        company = _company;
      }

      try {
        const companyId = company ? company.id : payload?.companyId;
        let siteId = payload?.siteId;
        let areaId = payload?.areaId;

        let companySiteSaga: Saga<CompanySite> | undefined;
        let areaSaga: Saga<Area> | undefined;

        if (!siteId) {
          if (!payload?.siteName) {
            throw Error('Missing site name');
          }
          if (!payload?.siteAddress) {
            throw Error('Missing site address');
          }

          companySiteSaga = createCompanySiteSaga(
            companyId ?? '',
            payload?.siteName ?? '',
            payload.siteAddress
          );
          //create new site if applicable
          //wait for site creation completion
          companySite = await companySiteSaga;
          siteId = companySite.id;
        }

        //Create new area on new site
        if (!areaId) {
          if (!payload?.areaName) {
            throw Error('Missing area name');
          }

          areaSaga = createAreaSaga(
            companyId ?? '',
            siteId ?? '',
            payload.areaName ?? ''
          );
          const area = await areaSaga;
          areaId = area.id ?? '';
        }

        //create the new machine
        const systemSaga = createSystemSaga(
          companyId ?? '',
          siteId ?? '',
          areaId,
          payload
        );

        try {
          system = await systemSaga;
          const res = await provisionDevice(companyId, system.id);
          if (res === null) {
            throw Error('Missing provisioning prerequisites');
          }
        } catch (error) {
          await systemSaga?.rollback();
          await companySaga?.rollback();
          await companySiteSaga?.rollback();
          await areaSaga?.rollback();
          throw error;
        }
      } catch (error) {
        await companySaga?.rollback();
        throw error;
      }

      return {
        company: company,
        site: companySite,
        system: system,
      };
    },
    {
      onError: (err) => {
        onError(err as string);
      },
      onSuccess: async () => {
        onSuccess();
      },
    }
  );
};
