import { ColorKey } from '../types';


export const filterColorKeys: ColorKey[] = [
  'highlight7',
  'highlight4',
  'highlight6',
  'highlight5',
  'highlight1',
  'highlight2',
  'highlight3',
  'highlight9',
  'highlight10',
  'highlight8',
  'highlight11',
  'highlight12',
  'highlight13',
  'highlight14',
  'highlight15',
  'highlight16',
  'highlight17',
  'highlight18',
  'highlight19',
  'highlight20',
  'highlight21',
  'highlight22',
  'highlight23',
  'highlight24',
  'highlight25',
  'highlight26',
  'highlight27',
  'highlight28',
  'highlight29',
  'highlight30',
];
