import { ReactNode } from 'react';
import { TextWrapProps } from '../../base/TextWrapProps';
import { SelectOption } from '../../form/SelectOption';
import { BrandSelect } from './BrandSelect';
import { KPIVerticalLabels } from '../line-status/base-card-common/grids/kpi-grid/KPIVerticalLabel';

export interface BrandSelectLabelProps extends TextWrapProps {
  defaultValue?: SelectOption | null;
  value?: SelectOption | null;
  subLabel?: ReactNode;
  options?: SelectOption[];
  onChange?: (newValue: SelectOption | null) => void;
}

export function BrandSelectLabel({
  defaultValue,
  value,
  subLabel,
  options,
  onChange,
  $flexWrap,
  $whiteSpace,
  $wordWrap,
}: BrandSelectLabelProps) {
  return (
    <KPIVerticalLabels
      label={
        <BrandSelect
          defaultValue={defaultValue}
          value={value}
          options={options}
          onChange={onChange}
        />
      }
      labelColorKey="primary"
      subLabel={subLabel}
      $flexWrap={$flexWrap}
      $whiteSpace={$whiteSpace}
      $wordWrap={$wordWrap}
    />
  );
}
