import { GQLCompany, useActiveAppEntities } from '@beeriot/api-client';
import { useEffect, useState, useRef } from 'react';
import {
  PathMatch,
  RouteMatch,
  RouteObject,
  useLocation,
} from 'react-router-dom';
import {
  useIsExecSummaryPage,
  useIsSettingsPage,
  useIsSitesPage,
} from '../../navigation';
import { useAppRoutePaths } from '../utils';
import { last } from 'rxjs';




/**
 * Provides the latest link paths for the main tab bar.  i.e. if you navigate
 * into a sub-route of a main tab, this hook keeps track of where you went,
 * so you can switch to another tab, and use that path as the destination for
 * the main tab button instead of redirecting back to the Sites list, or Settings
 * main page
 * @param props
 * @returns
 */
export const useLastMainTabRoutes = (props?: {
  activeCompany?: GQLCompany | null;
}) => {
  const { activeCompany, activeSite } = useActiveAppEntities();
  const location = useLocation();
  const isSitesPage = useIsSitesPage();
  const isSettingsPage = useIsSettingsPage();
  const isExecSummaryPage = useIsExecSummaryPage();

  const routes = useAppRoutePaths();

  type InitialState = {
    [k: string]: {
      path: string;
      companyId?: string | undefined;
      siteId?: string | undefined;
      isActive?: boolean | undefined;
     
    }[];
  };
  const renderCount = useRef(false);
  const [_, render] = useState(false);
  const lastRoutes = useRef<InitialState>({
    settings: [{
      path: routes.settingsAccountPath,
      isActive: !!isSettingsPage,
      companyId: activeCompany?.id,
      siteId: activeSite?.id,
    }],
    sites: [{
      path: routes.sitesListPath,
      isActive: !!isSitesPage,
      companyId: activeCompany?.id,
      siteId: activeSite?.id,
    }],
    execSummary: [{
      path: routes.siteExecSummaryPath,
      isActive: !!isExecSummaryPage,
      companyId: activeCompany?.id,
      siteId: activeSite?.id,
    }],
  });

  useEffect(() => {
    if (routes.sitesListPath === '') {
      // bail because app routes is still in defaults
      return;
    }
    type MainTabModel = [
      keyof typeof lastRoutes.current,
      PathMatch<string> | RouteMatch<string, RouteObject> | undefined,
      string
    ];

    // loop through each of the tabs with sub/side tabs
    //  if we are on one of them, save their last selected
    //  so we can flip back to it.
    //
    //  if the active company changes, then reset the tabs
    //  to their default/fallback
    //
    // assign the last state to a let variable, then assign
    //  a new object ... spread to that variable if we have
    //  mutations.  that way everything happns on a single
    //  setState
    const next = lastRoutes.current;
    const model: MainTabModel[] = [
      ['settings', isSettingsPage, routes.settingsAccountPath],
      ['sites', isSitesPage, routes.sitesListPath],
      ['execSummary', isExecSummaryPage, routes.siteExecSummaryPath],
    ];
  
    let dirty = false;
    model.forEach(([key, isActiveRoute, fallbackDestination]) => {
      let routeStack = next?.[key]
      const first = routeStack[0];
      const last = routeStack[routeStack.length - 1];

      if (
        last.companyId !== activeCompany?.id ||
        last.siteId !== activeSite?.id
      ) {
        dirty = true;
        routeStack = [{
          path: fallbackDestination,
          companyId: activeCompany?.id,
          siteId: activeSite?.id,
          isActive:!!isActiveRoute
        }]
      } else  if(last.path === location.pathname) {
        // no-op
      } else if(fallbackDestination === location.pathname) {
        dirty = true;
        if(routeStack.length > 1) {
          routeStack = [ {
            path: fallbackDestination,
            companyId: activeCompany?.id,
            siteId: activeSite?.id,
            isActive:!!isActiveRoute
          }]
        }
        
      } else {
        dirty = true;
      // } else if(isActiveRoute) {
        // if(last.isActive) {
        //   routeStack.push({
        //     path: last.path,
        //     companyId: activeCompany?.id,
        //     siteId: activeSite?.id,
        //     isActive: true,
        //   })
        // } else {
          if(isActiveRoute) {
          routeStack = [...routeStack, {
            path: location.pathname,
            companyId: activeCompany?.id,
            siteId: activeSite?.id,
            isActive: !!isActiveRoute,
          }]
        }
          // routeStack.push({
          //   path: location.pathname,
          //   companyId: activeCompany?.id,
          //   siteId: activeSite?.id,
          //   isActive: !!isActiveRoute,
          // })
        // }
        
      } 

      next[key] = routeStack

      // if (isActiveRoute && location.pathname !== lastRoutes?.[key]?.path) {
      //   if(last?.isActive && last?.path !== location.pathname) {
      //     next = {
      //       ...next,
      //       [key]: {
      //         path: last.path,
      //         companyId: activeCompany?.id,
      //         siteId: activeSite?.id,
      //         isActive: true,
      //       },
      //     };
      //   } else {
      //     // next = {
      //     //   ...next,
      //     //   [key]: {
      //     //     path: location.pathname,
      //     //     companyId: activeCompany?.id,
      //     //     siteId: activeSite?.id,
      //     //     isActive: true,
      //     //   },
      //     // };
      //   }

       
      // } else {
      //   if (
      //     lastRoutes?.[key]?.companyId !== activeCompany?.id ||
      //     lastRoutes?.[key]?.siteId !== activeSite?.id
      //   ) {
      //     next = {
      //       ...next,
      //       [key]: {
      //         path: fallbackDestination,
      //         companyId: activeCompany?.id,
      //         siteId: activeSite?.id,
      //       },
      //     };
      //   }
      // }
    });

    // next = dirty ? { ...next } : next;
    lastRoutes.current = next;
    dirty && render(renderCount.current = !renderCount.current);
  }, [
    //lastRoutes, 
    isSettingsPage, isSitesPage, isExecSummaryPage, location.pathname, activeCompany?.id, routes.settingsAccountPath, routes.sitesListPath, routes.siteExecSummaryPath, activeSite?.id, routes, ]);

  // console.log({lastRoutes: lastRoutes.current})
  return lastRoutes.current;
};
