import { MainTabsRoutes } from './main-tabs/MainTabsRoutes';
import { Suspense, lazy, useEffect, useMemo, useRef, useState } from 'react';
import { Route, Routes, useMatch, useMatches } from 'react-router-dom';
import { RequireAuth } from '../auth/RequireAuth';
import {
  LoginPage,
  ResetPasswordPage,
  UserSetupPage,
  ForgotPasswordPage,
} from '../pages/auth';
import { ParamIdHandler } from '../base';
import { activeCompany$, useCompanies, useGQLSites, useMembership, userId$ } from '@beeriot/api-client';
import { useObservable } from '@ngneat/use-observable';
///

export const makeAppRoutes = () => {
  return (
    <>
      <Route
        path="auth"
        element={
          <Suspense>
            <LoginPage />
          </Suspense>
        }
      />
      <Route path="setup" element={<UserSetupPage />} />
      <Route path="forgot-password" element={<ForgotPasswordPage />} />
      <Route path="reset-password" element={<ResetPasswordPage />} />

      <Route path="*" element={<MainTabsWrapper />} />
    </>
  );
};
const MainTabsWrapper = () => {
  const match = useMatches();
  console.log(match);
  const isMatch = useMatch('/companies/*');
  const [showSuspense, setShowSuspense] = useState(true);
  const timerRef = useRef<number | undefined>();

  const [userId] = useObservable(userId$);
  const [activeCompany] = useObservable(activeCompany$);
  const {data:membership}  = useMembership(null, {suspense: false, enabled: !!userId });
  const sites = useGQLSites(activeCompany?.id ?? membership?.companyId, null, '', {suspense: false, enabled: !!userId });
  const companies = useCompanies('', {suspense:false, enabled: !!userId  });
  useEffect(() => {
    if (isMatch) {
      clearTimeout(timerRef.current);
      // timerRef.current = setTimeout(() => {
        setShowSuspense(false);
      // }, 0) as unknown as number;
    } else {
      clearTimeout(timerRef.current);
      setShowSuspense(true);
      timerRef.current = undefined;
    }
    return () => {
      //reset
      clearTimeout(timerRef.current);
      timerRef.current = undefined;
    };
  }, [isMatch]);
  const mains = useMemo(() => <MainTabsRoutes />, [])
  return (
    <>
      <ParamIdHandler />
      <RequireAuth>
        {//companies.length > 0 ? 
        (showSuspense ? (
          <Suspense>
            {mains}
          </Suspense>
        ) : (
          mains
        ))// : <span/>
        }
        
      </RequireAuth>
    </>
  );
};
