import { Navigate, Route, Routes, useMatches } from 'react-router-dom';

export const RouterRedirect = ({
  from,
  to,
  replace,
  state,
  index,
}: {
  from?: string;
  index?: true;
  to: string;
  replace?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: any;
}) => {
  const matches = useMatches();
  // check if we are already at the destination
  const found = matches.find((match) => match.pathname === to);
  return (
    <Routes>
      <Route
        index={index}
        path={from}
        element={found ? <span/> : <Navigate to={to} replace={replace} state={state} />}
      />
    </Routes>
  );
};
