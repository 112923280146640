import { Stack, styled } from '@mui/material';
import { AppTypography } from '../../../base/AppTypography';

export const SectionHeaderStack = styled(Stack)(({ theme }) => ({
  padding: '1rem',
  backgroundColor: theme.palette.grey[100],
  borderBottom: 'solid 1px',
  borderColor: theme.palette.grey[300],
}));

export const SectionHeader = styled(AppTypography)(({ theme }) => ({
  padding: '1rem',
  backgroundColor: theme.palette.grey[100],
  borderBottom: 'solid 1px',
  borderColor: theme.palette.grey[300],
}));

SectionHeader.defaultProps = {
  variant: 'h6',
  $bold: true,
};

export const TitleHeader = styled(SectionHeader)({
  borderTopLeftRadius: '0.5rem',
  borderTopRightRadius: '0.5rem',
});

export const TitleHeaderStack = styled(SectionHeaderStack)({
  borderTopLeftRadius: '0.5rem',
  borderTopRightRadius: '0.5rem',
});
