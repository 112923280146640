import { Link, LinkProps, styled } from '@mui/material';
import React, { ForwardedRef } from 'react';
import {
  generatePath,
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from 'react-router-dom';
import { transientOptions } from '../utils';

const StyledLink = styled(
  Link,
  transientOptions
)({
  textDecoration: 'none',
}) as typeof Link;

type ExcludedTypes = 'to';
export type RouterLinkProps = Exclude<ReactRouterLinkProps, ExcludedTypes> &
  Exclude<LinkProps, ExcludedTypes> & {
    to?: string;
    params?: {
      [k: string]: string | undefined;
    };
    $isMobile?: boolean;
  };
type _RouterLinkProps = ReactRouterLinkProps & LinkProps;
/**
 * A @mui Link which has been retrofitted with a react-router Link.  Can be styled as needed.
 */
export const RouterLink = React.forwardRef(
  (props: RouterLinkProps, ref: ForwardedRef<HTMLAnchorElement>) => {
    let linkTo = props.to ?? '';
    if (props.params) {
      linkTo = generatePath(linkTo, props.params);
    }
    return (
      <StyledLink
        ref={ref}
        component={ReactRouterLink}
        {...props}
        to={linkTo}
      />
    );
  }
);
