import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled, Alert } from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import {
  reportStore,
  showRecurringReports$,
  GQLReport,
  GQLReportDefinition,
  useGetReportUrl,
  useDeleteReport,
} from '@beeriot/api-client';
import { RootPage } from '../../pages/RootPage';
import { ReportsTableContainer } from './ReportsTableContainer';
import { AppTypography, AppIcon, GlobalFooter, AppDialog } from '../../base';
import { NewRecurringReportDialog } from '../../dialogs/reports/NewRecurringReportDialog';
import { ShareReportDialog } from '../../dialogs/reports/ShareReportDialog';
import { DeleteRecurringReportDialog } from '../../dialogs/reports/DeleteRecurringReportDialog';

const StyledRootPage = styled(RootPage)({
  overflow: 'auto',
});

const WarningAlert = styled(Alert)(({ theme }) => ({
  margin: '1rem 1rem 0 1rem',
  border: `1px solid ${theme.palette.warning.main}`,
}));

export function ReportsPanel() {
  const getReportUrl = useGetReportUrl(
    (url) => {
      if (url) {
        window.open(url, '_blank')?.focus();
      } else {
        setOpenViewErrorDialog(true);
      }
    },
    (err) => {
      console.log(err);
    }
  );

  const deleteMutation = useDeleteReport(
    () => setOpenDeleteDialog(false),
    () => setOpenDeleteDialog(false)
  );

  const [openViewErrorDialog, setOpenViewErrorDialog] =
    useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openShareReportDialog, setOpenShareReportDialog] =
    useState<boolean>(false);
  const [openNewRecurringReportDialog, setOpenNewRecurringReportDialog] =
    useState<boolean>(false);

  const [deleteItem, setDeleteItem] = useState<
    GQLReportDefinition | undefined
  >();
  const [shareReport, setShareReport] = useState<GQLReport | undefined>(
    undefined
  );
  const [showRecurringReports] = useObservable(showRecurringReports$);

  const showAddRecurringReportForm = useCallback(() => {
    setOpenNewRecurringReportDialog(true);
  }, []);

  const showConfirmDeleteReport = useCallback((data: GQLReportDefinition) => {
    setDeleteItem(data);
    setOpenDeleteDialog(true);
  }, []);

  const showShareReport = useCallback((data: GQLReport) => {
    setOpenShareReportDialog(true);
    setShareReport(data);
  }, []);

  const showReport = useCallback(
    (data: GQLReport) => {
      getReportUrl.mutate(data.id);
    },
    [getReportUrl]
  );

  const toggleRecurringReports = useCallback(() => {
    reportStore.update((state) => ({
      ...state,
      showRecurringReports: !state.showRecurringReports,
    }));
  }, []);

  return (
    <StyledRootPage>
      {!showRecurringReports && (
        <WarningAlert
          severity="warning"
          icon={
            <AppIcon
              icon={'circle-exclamation'}
              $colorKey={'warning'}
              size="1x"
            />
          }
        >
          <AppTypography $bold variant="subtitle2">
            <FormattedMessage
              id="reports-storage-warning"
              description="Warning for report storage"
              defaultMessage="Reports are stored for 12 months. Please ensure to download before deletion."
            />
          </AppTypography>
        </WarningAlert>
      )}
      <ReportsTableContainer
        viewButtonLoading={getReportUrl.isLoading}
        showRecurringReports={showRecurringReports}
        showAddRecurringReportForm={showAddRecurringReportForm}
        showShareReport={showShareReport}
        showReport={showReport}
        showConfirmDeleteReport={showConfirmDeleteReport}
        toggleRecurringReports={toggleRecurringReports}
      />
      <GlobalFooter />
      {shareReport && (
        <ShareReportDialog
          open={openShareReportDialog}
          onClose={() => setOpenShareReportDialog(false)}
          report={shareReport}
        />
      )}
      <NewRecurringReportDialog
        open={openNewRecurringReportDialog}
        onClose={() => setOpenNewRecurringReportDialog(false)}
      />
      {deleteItem && (
        <DeleteRecurringReportDialog
          open={openDeleteDialog}
          selectedReport={deleteItem}
          onClose={() => setOpenDeleteDialog(false)}
          deleteMutation={deleteMutation}
        />
      )}
      <AppDialog
        open={openViewErrorDialog}
        dialogTitle={
          <FormattedMessage
            id="common-error-label"
            description="Label for an event error"
            defaultMessage="Error"
          />
        }
        dialogContent={
          <FormattedMessage
            id="reports-view-error-label"
            description="Label for error when viewing a report"
            defaultMessage="There was an error accessing this report, please try again later or contact support."
          />
        }
        showCancelButton={false}
        confirm={() => {
          setOpenViewErrorDialog(false);
        }}
      />
    </StyledRootPage>
  );
}
