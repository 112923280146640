import { useMemo } from 'react';
import { styled, InputBase, Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { AppIcon } from '../base/AppIcon';

const StyledIcon = styled(AppIcon)({
  marginRight: '1rem',
});

const InputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '0.5rem',
  border: 'none',
  fontFamily: theme.typography.fontFamily,
  backgroundColor: theme.palette.componentShade.main,
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  flexGrow: 1,
  padding: '0.2rem 0.5rem',
}));

interface FormTextInputProps {
  name: string;
  icon?: IconProp;
  iconSize?: SizeProp;
  placeholder?: string;
  required?: boolean;
  className?: string;
  defaultValue?: string;
}

export function FormTextInput({
  name,
  icon,
  iconSize,
  placeholder,
  required,
  className,
  defaultValue,
}: FormTextInputProps) {
  const { control } = useFormContext();

  const rules = useMemo(() => {
    return { required };
  }, [required]);

  return (
    <InputContainer className={className}>
      <Controller
        name={name}
        rules={rules}
        render={({ field }) => (
          <StyledInput
            {...field}
            placeholder={placeholder}
            startAdornment={
              icon && (
                <StyledIcon
                  icon={icon}
                  $colorKey="shade"
                  size={iconSize ?? 'lg'}
                />
              )
            }
          />
        )}
        control={control}
        defaultValue={defaultValue ?? ''}
      />
    </InputContainer>
  );
}
