import { CBSSystemDetailCardBase } from './CBSSystemDetailCardBase';
import { SystemCardBaseProps } from '../base-card-common/SystemCardBase';

export const CBSSystemDetailCard = (props: SystemCardBaseProps) => {
  return (
    <CBSSystemDetailCardBase
      data-element-id={'cbs-system-detail-card'}
      {...props}
    />
  );
};
CBSSystemDetailCard.displayName = 'CBSSystemDetailCard';
