export const systemStatsDecimalPlaces: Record<string, number> = {
  volumeFiltered: 0,
  totalStabilizedBeer: 0,
  totalColdFilterBeer: 0,
  totalRecoveredBeer: 0,
  averageFlow: 0,
  degreeOfEfficiency: 0,
  tmpIncrease: 1,
  filtrationOperatedToFinalTMP: 1,
  capacityFullRunsTillTMPBar: 0,
  filterLineUtilization: 0,
  netCapacity: 0,
  totalCIPS: 0,
  beerLossByFilterLoss: 0,
  water: 0,
  caustic: 0,
  peroxyde: 0,
  acidicChemical: 0,
  daWater: 0,
  actualStabilizationCost: 0,
  membraneCleaner: 0,
  kWHl: 0,
  electrical: 0,
  oxygen: 0,
  hazeCentrifugeOut: 0,
  hazeFilterOut: 0,
  clusterTestFailurePct: 0,
  dpTrendBar: 0,
  fluxRateHl: 0,
  fluxRateM2H: 0,
  sanitationCycles: 0,
  causticCycles: 0,
  enzymaticCycles: 0,
  diaFiltrationYield: 0,
  beerYield: 0,
  plato: 1,
  averageTemperature: 0,
};
