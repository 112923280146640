import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Stack,
  Button,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { LoadingPaper } from '../../base';

const CompanyCardList = lazy(() =>
  import('./CompanyCardListQuery').then(({ CompanyCardListQuery }) => ({
    default: CompanyCardListQuery,
  }))
);

const CompanyLoadingPaper = styled(LoadingPaper)({
  height: '8rem',
  width: '34rem',
  margin: '1.5rem',
});

const ErrorContainer = styled(Stack)({
  width: '100%',
  alignItems: 'center',
});

const MessageContainer = styled(Stack)({
  textAlign: 'center',
  width: '30rem',
  margin: '3rem',
  justifyContent: 'center',
});

function CompanyLoadingPapers() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  return (
    <Stack>
      <Stack direction="row">
        <CompanyLoadingPaper />
        {isSmall ? null : <CompanyLoadingPaper />}
        {isSmall ? null : <CompanyLoadingPaper />}
      </Stack>
      <Stack direction="row">
        <CompanyLoadingPaper />
        {isSmall ? null : <CompanyLoadingPaper />}
        {isSmall ? null : <CompanyLoadingPaper />}
      </Stack>
      <Stack direction="row">
        <CompanyLoadingPaper />
        {isSmall ? null : <CompanyLoadingPaper />}
        {isSmall ? null : <CompanyLoadingPaper />}
      </Stack>
    </Stack>
  );
}

export function SuspenseCompanyCardList() {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ resetErrorBoundary }) => {
            return (
              <ErrorContainer>
                <MessageContainer>
                  <Typography>
                    <FormattedMessage
                      id="companies-error-loading-label"
                      description="Label for companies loading error"
                      defaultMessage="There was an error loading companies"
                    />
                  </Typography>
                  <Button onClick={() => resetErrorBoundary()}>
                    <FormattedMessage
                      id="common-try-again-button"
                      description="Button for trying an action again"
                      defaultMessage="Try Again"
                    />
                  </Button>
                </MessageContainer>
              </ErrorContainer>
            );
          }}
          onReset={reset}
        >
          <Suspense fallback={<CompanyLoadingPapers />}>
            <CompanyCardList />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
