import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { IconLabel, LoadingBox } from '../../base';
import { DocumentationTableQueryProps } from './DocumentationTableQuery';

const TableLoadingBox = styled(LoadingBox)({
  height: '100%',
  minHeight: '20rem',
  width: '100%',
  borderRadius: '0 0 0.5rem 0.5rem',
});

const DocumentationTableContainerComponent = lazy(() =>
  import('./DocumentationTableQuery').then(({ DocumentationTableQuery }) => ({
    default: DocumentationTableQuery,
  }))
);

export function SuspenseDocumentationTableContainer({
  showEditForm,
  showDocument,
  downloadDocument,
  showConfirmUnDelete,
}: DocumentationTableQueryProps) {
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <IconLabel
                label={
                  <FormattedMessage
                    id="documentation-table-error-label"
                    description="Error message for documentation table"
                    defaultMessage="Documentation Unavailable"
                  />
                }
              />
            );
          }}
        >
          <Suspense fallback={<TableLoadingBox />}>
            <DocumentationTableContainerComponent
              showEditForm={showEditForm}
              showDocument={showDocument}
              downloadDocument={downloadDocument}
              showConfirmUnDelete={showConfirmUnDelete}
            />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
