import { CompanySiteBase, CompanySiteStatusEnum } from '../../../generated/api';
import { GQLCompanySite } from '../../../generated/gql';
import { removeFalsey } from '../../../utils/removeFalsey';

export function toCompanySiteBase(item: GQLCompanySite): CompanySiteBase {
  return {
    companyId: item.companyId ?? '',
    name: item.name ?? '',
    address: {
      address1: item.address?.address1 ?? '',
      address2: item.address?.address2 ?? '',
      city: item.address?.city ?? '',
      country: item.address?.country ?? '',
      state: item.address?.state ?? '',
      zipcode: item.address?.zipcode ?? '',
    },
    longitude: item.longitude ?? '',
    latitude: item.latitude ?? '',
    status: stringToStatus(item.status),
    groups: item.groups ? removeFalsey<string>(item.groups) : [],
  };
}
function stringToStatus(str?: string | null): CompanySiteStatusEnum {
  if (!str) return CompanySiteStatusEnum.Active;
  switch (str) {
    case 'active':
      return CompanySiteStatusEnum.Active;
    case 'inactive':
      return CompanySiteStatusEnum.Inactive;
    default:
      return CompanySiteStatusEnum.Active;
  }
}
