import { FormattedMessage, useIntl } from 'react-intl';
import { useObservable } from '@ngneat/use-observable';
import { Stack, styled } from '@mui/material';
import { alertsLastUpdate$ } from '@beeriot/api-client';
import { AppDrawer, VerticalLabels } from '../base';
import { DrawerType } from '../enums';
import { SuspenseAlertsDrawerContent } from './SuspenseAlertsDrawerContent';

const StyledStack = styled(Stack)({
  marginLeft: 'auto',
});

export function AlertsDrawer() {
  const [alertsLastUpdate] = useObservable(alertsLastUpdate$);
  const intl = useIntl();
  const headerLabel = intl.formatMessage({
    id: 'common-alerts-label',
    description: 'Label for alerts',
    defaultMessage: 'Alerts',
  });

  return (
    <StyledStack>
      <AppDrawer
        $width="16rem"
        type={DrawerType.Alerts}
        anchor="right"
        headerLabel={headerLabel}
        openHeaderLabelOverride={
          <VerticalLabels
            labelBold
            label={headerLabel}
            subLabel={
              alertsLastUpdate ? (
                <FormattedMessage
                  id="common-last-update-label"
                  description="Label for last updated time"
                  defaultMessage="Updated - {lastUpdate}"
                  values={{
                    lastUpdate: alertsLastUpdate,
                  }}
                />
              ) : null
            }
            subLabelVariant="caption"
            justifyContent="right"
          />
        }
        drawerContent={<SuspenseAlertsDrawerContent />}
      />
    </StyledStack>
  );
}
