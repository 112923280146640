import { ReactNode } from 'react';
import { Stack, styled } from '@mui/material';
import { SelectOption } from '../../form/SelectOption';
import { GridItemStatus } from '../../types/GridItemStatus';
import { ExpandableGridItem } from '../ExpandableGridItem';
import { AppCard } from '../AppCard';
import { GraphHeader } from './GraphHeader';

const GraphAppCard = styled(AppCard)({
  width: '100%',
  minHeight: '30rem',
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
});

const GraphContainer = styled(Stack)({
  height: 0,
  flex: '1 1 auto',
});

interface ExpandableGraphCardProps {
  status: GridItemStatus;
  toggleExpand: () => void;
  title: ReactNode;
  formOnSubmit?: (data: SelectOption) => void;
  selectOptions?: SelectOption[];
  defaultValue?: SelectOption;
  children: ReactNode;
}

export function ExpandableGraphCard({
  status,
  toggleExpand,
  title,
  formOnSubmit,
  selectOptions,
  defaultValue,
  children,
}: ExpandableGraphCardProps) {
  return (
    <ExpandableGridItem status={status}>
      <GraphAppCard>
        <GraphHeader
          title={title}
          status={status}
          onClick={toggleExpand}
          formOnSubmit={formOnSubmit}
          options={selectOptions}
          defaultValue={defaultValue}
        />
        <GraphContainer>{children}</GraphContainer>
      </GraphAppCard>
    </ExpandableGridItem>
  );
}
