import { activeSystem$ } from '@beeriot/api-client';
import { useObservable } from '@ngneat/use-observable';
import { AlertsDrawer } from '../../alerts';
import {
  ScrollStack,
  GlobalFooter,
  PermissionView,
  PermissionKey,
  RunningStatusLegendRow,
  LegendContainer,
} from '../../base';
import { RootPage } from '../../pages/RootPage';
import { useResponsiveSizes } from '../../utils/responsive-design/useResponsiveSizes';
import { LineSidebarDrawer } from '../line-sidebar-menu';
import { SuspenseLineStatusPanelContent } from './SuspenseLineStatusPanelContent';
import { useDropdownLabelText } from '../../app-routing/useDropdownLabelText';

export function LineStatusPanel() {
  const { isDesktopSize } = useResponsiveSizes();
  const [activeSystem] = useObservable(activeSystem$);

  useDropdownLabelText({
    id: 'common-line-status-label',
    description: 'Label for line status',
    defaultMessage: 'Line Status',
  });

  return (
    <RootPage direction="row">
      <LineSidebarDrawer />
      <ScrollStack>
        <SuspenseLineStatusPanelContent />
        <GlobalFooter
          topFooterComponent={
            !isDesktopSize ? (
              <LegendContainer>
                <RunningStatusLegendRow systemType={activeSystem?.type} />
              </LegendContainer>
            ) : null
          }
        />
      </ScrollStack>
      <PermissionView permissionKey={PermissionKey.AlertWidget}>
        <AlertsDrawer />
      </PermissionView>
    </RootPage>
  );
}
