import { Dialog, Stack, styled, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouterLink } from '../../navigation';
import { DialogHeader } from '../../dialogs';
import { CompletedTelemetryExportRecord, TelemetryDataExportExportStatusEnum } from '@beeriot/api-client';

const ContentStack = styled(Stack)({
  textAlign: 'center',
  padding: '1rem',
});

interface TelemetryDataExportProgressPopupProps {
  open: boolean;
  onClose: () => void;
  completed: boolean;
  telemetryLink?: string;
  completedTelemetryExport?: CompletedTelemetryExportRecord;
}

export function TelemetryDataExportProgressPopup({
  open,
  onClose,
  completed,
  telemetryLink,
  completedTelemetryExport
}: TelemetryDataExportProgressPopupProps) {
  const intl = useIntl();
  const title = intl.formatMessage({
    id: 'telemetry-data-export-progress-popup-title',
    description: 'title for telemetry data export progress popup',
    defaultMessage: 'Telemtry Data Export Progress',
  });
  return (
    <Dialog open={open}>
      <DialogHeader label={title} colorKey="highlight1" onClose={onClose} />
      {completedTelemetryExport?.exportStatus ===
      TelemetryDataExportExportStatusEnum.Completed ? (
        <ContentStack>
          <Typography variant="body1">
            <FormattedMessage
              id="telemetry-data-export-completed-popup"
              description="Popup text for telemetry completed popup"
              defaultMessage="You request has been completed. You can find your new telemetry export"
            />
          </Typography>
          <RouterLink
            onClick={onClose}
            to={
              `/companies/${completedTelemetryExport.companyId}/sites/${completedTelemetryExport.siteId}/lines/${completedTelemetryExport.systemId}/telemetry-data-export` ??
              ''
            }
          >
            <Typography>
              <FormattedMessage
                id="telemetry-data-export-completed-popup-link"
                description="Text for link to telemetry page of completed export"
                defaultMessage="here"
              />
            </Typography>
          </RouterLink>
        </ContentStack>
      ) : completedTelemetryExport?.exportStatus ===
        TelemetryDataExportExportStatusEnum.Error ? (
        <ContentStack>
          <Typography variant="body1">
            <FormattedMessage
              id="telemetry-data-export-failed-popup"
              description="Popup text for telemetry completed popup"
              defaultMessage="Your request has failed. You can attempt a new Telemetry Export"
            />
          </Typography>
          <RouterLink
            onClick={onClose}
            to={
              `/companies/${completedTelemetryExport.companyId}/sites/${completedTelemetryExport.siteId}/lines/${completedTelemetryExport.systemId}/telemetry-data-export` ??
              ''
            }
          >
            <Typography>
              <FormattedMessage
                id="telemetry-data-export-completed-popup-link"
                description="Text for link to telemetry page of failed export"
                defaultMessage="here"
              />
            </Typography>
          </RouterLink>
        </ContentStack>
      ) : (
        <ContentStack>
          <Typography variant="body1">
            <FormattedMessage
              id="telemetry-data-export-received-popup"
              description="Popup text for telemetry received popup"
              defaultMessage="Your request has been submitted. You can continue using the rest of the app while you wait for the export to finish. You will receieve a popup when your export has been completed."
            />
          </Typography>
        </ContentStack>
      )}
    </Dialog>
  );
}
