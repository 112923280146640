import { createContext } from 'react';
import { RouteTabObject } from '../models/RouteTabObject';

export interface TabsContextState {
  tabs: {
    [k: string]: {
      lastRouteMatch: string | undefined;
      lastRoutesMatches: (string | undefined)[];
    };
  };
  setLastRouteMatchForTabsNamed(props: {
    tabsName: string;
    route?: string;
    routes: RouteTabObject[];
    activeIdx?: number;
  }): void;
}

export const TabsContext = createContext<TabsContextState>({
  tabs: {},
  setLastRouteMatchForTabsNamed: (props: {
    tabsName: string;
    route?: string;
    routes: RouteTabObject[];
    activeIdx?: number;
  }) => undefined,
});
