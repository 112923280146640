import { Box, styled } from '@mui/material';
import { transientOptions } from '../../utils';

export const FullsizeContainer = styled(
  Box,
  transientOptions
)(({ theme }) => ({
  backgroundColor: theme.palette.componentBase.main,
  height: '100%',
}));
