import { FormattedMessage } from 'react-intl';
import { UseMutationResult } from 'react-query';
import { FileWithPath } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { Stack, styled } from '@mui/material';
import { AlertMappingPutData } from '@beeriot/api-client';
import {
  FormDropzone,
  FormError,
  FormFooterButtons,
  SuspenseSystemDropdown,
} from '../../form';
import { FormErrorContainer } from '../../auth/FormErrorContainer';

const StyledFormFooterButtons = styled(FormFooterButtons)({
  marginTop: '1rem',
});

interface AlertTypeMappingFormContentProps {
  onClose: () => void;
  mutation: UseMutationResult<
    AlertMappingPutData | undefined,
    unknown,
    {
      systemId: string;
      file: FileWithPath;
    },
    unknown
  >;
}

export function AlertTypeMappingFormContent({
  onClose,
  mutation,
}: AlertTypeMappingFormContentProps) {
  const { watch } = useFormContext();
  const system = watch('system');
  return (
    <>
      <Stack sx={{ padding: '0.25rem', marginBottom: '1rem' }}>
        <SuspenseSystemDropdown />
      </Stack>
      <FormDropzone required name="files" />
      {mutation.isError && (
        <FormErrorContainer>
          <FormError
            color="info"
            error={
              <FormattedMessage
                id="common-error-message-label"
                defaultMessage="An error occured"
                description="label for standard error message"
              />
            }
          />
        </FormErrorContainer>
      )}
      <StyledFormFooterButtons
        disableSubmit={!system}
        isLoading={mutation.isLoading}
        justify="center"
        onCancel={onClose}
        requireCancelConfirmation={false}
      />
    </>
  );
}
