import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Stack, Typography, styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { LoadingPaper } from '../../base/LoadingPaper';

const StyledLoadingPaper = styled(LoadingPaper)({
  height: '35rem',
  width: '100%',
});

const ErrorContainer = styled(Stack)({
  width: '100%',
  alignItems: 'center',
});

const MessageContainer = styled(Stack)({
  textAlign: 'center',
  width: '30rem',
  margin: '3rem',
  justifyContent: 'center',
});

const IoTStatusPanel = lazy(() =>
  import('./IoTStatusPanelQuery').then(({ IoTStatusPanelQuery }) => ({
    default: IoTStatusPanelQuery,
  }))
);

export function SuspenseIoTStatusPanel() {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ resetErrorBoundary }) => {
            return (
              <ErrorContainer>
                <MessageContainer>
                  <Typography>
                    <FormattedMessage
                      id="iot-statuserror-label"
                      description="Error message for iot status loading"
                      defaultMessage="Error loading IoT Status"
                    />
                  </Typography>
                  <Button onClick={() => resetErrorBoundary()}>
                    <FormattedMessage
                      id="common-try-again-button"
                      description="Button for trying an action again"
                      defaultMessage="Try Again"
                    />
                  </Button>
                </MessageContainer>
              </ErrorContainer>
            );
          }}
          onReset={reset}
        >
          <Suspense fallback={<StyledLoadingPaper />}>
            <IoTStatusPanel />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
