import { distinctUntilArrayItemChanged, select } from '@ngneat/elf';
import { shareReplay, map, tap,switchMap, first,  } from 'rxjs/operators';
import { systemStore } from './systemStore';
import { filterColorKeys } from '../filterColorKeys';
import { GQLSystem } from '../../generated/gql';
import { ColorKey } from '../../types';
import { combineLatest, of } from 'rxjs';

export const activeSystem$ = systemStore.pipe(
  select((state) => {
    return state.activeSystem;
  })
);

export const liveKPILastUpdatedTime$ = systemStore.pipe(
  select((state) => {
    return state.liveKPILastUpdatedTime;
  })
);


export const completedTelemetryExport$ = systemStore.pipe(
  select((state) => {
    return state.completedTelemetryExport;
  })
);

export type GQLSystemWithColor = GQLSystem & { colorKey: ColorKey}

export const gqlSystems$ = systemStore.pipe(
  select((state) => {
    return state.gqlSystems ?? null;
  }),
  // distinctUntilArrayItemChanged(),
  map((systems) =>
    systems?.sort((a, b) =>
      a.sortOrder == b.sortOrder
        ? (a.name ?? '').localeCompare(b.name ?? '')
        : (a?.sortOrder ?? 0) < (b?.sortOrder ?? 0)
        ? -1
        : 1
    )
  ),
  switchMap((systems) => {
    return combineLatest([of(systems), fullCompanyGqlSystems$]).pipe(first())
  }),
  map(([siteSystems, fullList]) => {
    const _siteSystems = siteSystems as GQLSystemWithColor[] | undefined
    const siteList = _siteSystems?.map((sys, idx) => {
      const system: GQLSystemWithColor = sys as GQLSystemWithColor;
      system.colorKey = filterColorKeys[idx % (filterColorKeys.length - 1)]
      return (fullList as GQLSystemWithColor[] | undefined)?.find(x => x.id === sys.id) ?? system
    }) ?? null
    return siteList;
  }),
  // shareReplay(1)
);

export const fullCompanyGqlSystems$ = systemStore.pipe(
  select((state) => {
    // console.log('fullCompanyGqlSystems', state.fullCompanyGqlSystems)
    return state.fullCompanyGqlSystems// ?? state.gqlSystems ?? null;
  }),
  // distinctUntilArrayItemChanged(),
  map((systems) =>
    systems?.sort((a, b) =>
      a.sortOrder == b.sortOrder
        ? (a.name ?? '').localeCompare(b.name ?? '')
        : (a?.sortOrder ?? 0) < (b?.sortOrder ?? 0)
        ? -1
        : 1
    )
  ),
  map((systems) => {
    const fullList =  (systems)?.map((x, i) => {
      const system: GQLSystemWithColor = x as GQLSystemWithColor;
      system.colorKey = filterColorKeys[(filterColorKeys.length - 1) % i]
      return system
    }) ?? null
    return fullList;
  }),
  // shareReplay(1)
);
