import { useMemo, useState, useCallback } from 'react';
import {
  Autocomplete,
  styled,
  TextField,
  createFilterOptions,
  InputAdornment,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { AppIcon } from '../base/AppIcon';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiOutlinedInput-input': {
    fontWeight: 'bold',
  },
  '& .MuiOutlinedInput-root': {
    flexWrap: 'nowrap',
  },
});

const filter = createFilterOptions<AutocompleteOption>();

export interface AutocompleteOption {
  label: string;
  inputValue?: string;
}

interface FormAutocompleteTextFieldProps {
  name: string;
  options: unknown[];
  itemTypeLabel?: string;
  isOptionEqualToValue?: (option: AutocompleteOption, value: any) => boolean;
  placeholder?: string;
  required?: boolean;
  setNewObjectCreated?: (created: boolean) => void;
}

export function FormAutocompleteTextField({
  name,
  options,
  itemTypeLabel = 'item',
  isOptionEqualToValue,
  placeholder,
  required,
  setNewObjectCreated,
}: FormAutocompleteTextFieldProps) {
  const [newVal, setNewVal] = useState<string>('');
  const [newValueCreated, setNewValueCreated] = useState<boolean>(false);
  const { control } = useFormContext();

  const rules = useMemo(() => {
    return { required };
  }, [required]);

  const findExistingOption = useCallback(
    (value: string) => {
      const option = options.find((x) => x === value);

      return option !== undefined;
    },
    [options]
  );

  return (
    <Controller
      name={name}
      rules={rules}
      render={({ field }) => (
        <Autocomplete
          {...field}
          freeSolo
          options={options}
          disableClearable
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          isOptionEqualToValue={isOptionEqualToValue}
          onChange={(e: any, newValue: string | AutocompleteOption) => {
            const optionToCheck =
              (typeof newValue === 'string' ? newValue : newValue.inputValue) ??
              '';
            const isExisting = findExistingOption(optionToCheck);
            if (isExisting && optionToCheck !== newVal) {
              setNewValueCreated(false);
              if (setNewObjectCreated) setNewObjectCreated(false);
              field.onChange(newValue);
            } else if (typeof newValue === 'string') {
              field.onChange({
                label: newValue,
              });
            } else if (newValue && newValue.inputValue) {
              setNewValueCreated(true);
              if (setNewObjectCreated) setNewObjectCreated(true);
              setNewVal(newValue.inputValue);
              field.onChange({ label: newValue.inputValue });
            } else {
              field.onChange(newValue);
            }
          }}
          renderOption={(props, option) => (
            <li {...props}>{option.label ? option.label : option}</li>
          )}
          renderInput={(params) => {
            if (newValueCreated)
              params.InputProps.endAdornment = (
                <InputAdornment position="end">
                  <AppIcon icon="sparkles" $colorKey="secondary" />
                </InputAdornment>
              );
            return (
              <StyledTextField
                {...params}
                placeholder={placeholder}
                fullWidth
              />
            );
          }}
          getOptionLabel={(option) => {
            // Value selected with enter, comes right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.label;
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option || inputValue === option.label
            );
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                label: `No ${itemTypeLabel} found, this will create a new ${itemTypeLabel}. Create "${inputValue}"`,
              });
            }

            return filtered;
          }}
        />
      )}
      control={control}
    />
  );
}
