import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { IconLabel } from '../../base/IconLabel';
import { LoadingPaper } from '../../base/LoadingPaper';

const FilterRunLoadingPaper = styled(LoadingPaper)({
  minHeight: '20rem',
  minWidth: '24rem',
  margin: '0.5rem',
});

const FilterRunDialogContent = lazy(() =>
  import('./FilterRunDialogContentQuery').then(
    ({ FilterRunDialogContentQuery }) => ({
      default: FilterRunDialogContentQuery,
    })
  )
);

export function SuspenseFilterRunDialogContent() {
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <IconLabel
                label={
                  <FormattedMessage
                    id="filter-run-error-label"
                    description="Error message for filter run loading"
                    defaultMessage="Error loading filter run details"
                  />
                }
              />
            );
          }}
        >
          <Suspense fallback={<FilterRunLoadingPaper />}>
            <FilterRunDialogContent />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
