import { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { parseISO } from 'date-fns';
import { Stack, styled } from '@mui/material';
import {
  StatisticsViewingFilter,
  lineStatisticsStore,
  lineStatisticsViewingFilter$,
  lineStatisticsCompareDate$,
  lineStatisticsDate$,
  CalendarMode,
  useActiveAppEntities,
} from '@beeriot/api-client';
import { useObservable } from '@ngneat/use-observable';
import { ControlBox } from '../../base/ControlBox';
import { AppTypography } from '../../base/AppTypography';
import {
  dateToStartOfView,
  getEnumFromString,
  previousPeriodFromCalendarMode,
  transientOptions,
  useResponsiveSizes,
} from '../../utils';
import { Form } from '../../form';
import { AppDatePicker } from '../../base';
import { StatisticsCalendarModePicker } from './StatisticsCalendarModePicker';
import { SuspenseFilterRunComparisonSelect } from './SuspenseFilterRunComparisonSelect';
import { FilterRunsFormFields } from './FilterRunsFormFields';

const StyledControlBox = styled(
  ControlBox,
  transientOptions
)<{ $justifyCenter: boolean }>(({ $justifyCenter }) => ({
  flexWrap: 'wrap',
  gap: '1rem',
  justifyContent: $justifyCenter ? 'center' : 'flex-start',
}));

const StyledStack = styled(Stack)({
  flexWrap: 'wrap',
  gap: '1rem',
  justifyContent: 'center',
});

export const LineStatisticsHeader = () => {
  const intl = useIntl();
  const { activeSystem } = useActiveAppEntities();
  const { isMobileSize, isSmallTabletSize } = useResponsiveSizes();
  const [view] = useObservable(lineStatisticsViewingFilter$);
  const [dateString] = useObservable(lineStatisticsDate$);
  const [compareDateString] = useObservable(lineStatisticsCompareDate$);

  const todaysDateRef = useRef<Date>();
  
  const calendarMode = getEnumFromString(CalendarMode, view);
  const date = parseISO(dateString);
  const compareDate = parseISO(compareDateString);

  const setView = (viewingFilter: StatisticsViewingFilter) => {
    const today = new Date(todaysDateRef.current as Date);
    let newDate: string | undefined = undefined;
    let newCompareDate: string | undefined = undefined;
    if (viewingFilter !== 'Run') {
      const previousDate = previousPeriodFromCalendarMode(viewingFilter, new Date(today));
      newDate = dateToStartOfView(viewingFilter, new Date(today)).toISOString();
      newCompareDate = dateToStartOfView(
        viewingFilter,
        new Date(previousDate)
      ).toISOString();
    }

    lineStatisticsStore.update((state) => ({
      ...state,
      viewingFilter,
      date: newDate ?? state.date,
      compareDate: newCompareDate ?? state.compareDate,
    }));
  };

  const setDate = (newDate: Date | null) => {
    if (newDate)
      lineStatisticsStore.update((state) => ({
        ...state,
        date: newDate.toISOString(),
      }));
  };

  const setCompareDate = (newDate: Date | null) => {
    if (newDate)
      lineStatisticsStore.update((state) => ({
        ...state,
        compareDate: newDate.toISOString(),
      }));
  };

  const setFilterRuns = (data: FilterRunsFormFields) => {
    if (data.filterRun && data.comparedRun)
      lineStatisticsStore.update((state) => ({
        ...state,
        filterRun: data.filterRun.data,
        compareFilterRun: data.comparedRun.data,
      }));
  };

  const comparedTo = intl.formatMessage({
    id: 'common-compared-to-label',
    description: 'Label for a compared value',
    defaultMessage: 'Compared To',
  });

  useEffect(() => {
    if (activeSystem?.type === 'cbs' && view === 'Run') {
      lineStatisticsStore.update((state) => ({
        ...state,
        viewingFilter: CalendarMode.Week,
      }));
      setView(CalendarMode.Week);
    }
  }, [activeSystem?.type, view]);

  if(!todaysDateRef.current) {
    todaysDateRef.current = new Date();
    setView(CalendarMode.Week);
  }

  return (
    <StyledControlBox $justifyCenter={isMobileSize || isSmallTabletSize}>
      <AppTypography $bold $colorKey="lightText">
        <FormattedMessage
          id="common-viewing-label"
          defaultMessage="Viewing"
          description="Label for viewing"
        />
      </AppTypography>
      <StatisticsCalendarModePicker value={view} onChange={setView} />
      {calendarMode ? (
        <StyledStack direction="row">
          <AppDatePicker
            calendarMode={calendarMode}
            value={date}
            onChange={setDate}
          />
          <AppDatePicker
            calendarMode={calendarMode}
            value={compareDate}
            onChange={setCompareDate}
            colorKey="highlight2"
            subLabelOverride={comparedTo}
          />
        </StyledStack>
      ) : (
        <Form<FilterRunsFormFields> submitOnChange onSubmit={setFilterRuns}>
          <StyledStack direction="row">
            <SuspenseFilterRunComparisonSelect />
          </StyledStack>
        </Form>
      )}
    </StyledControlBox>
  );
};
