import { useMediaQuery, useTheme, Breakpoint, AppTheme } from '@mui/material';

export interface ResponsiveSizes {
  isXLSize: boolean;
  isDesktopSize: boolean;
  isTabletSize: boolean;
  isMobileSize: boolean;
  isSmallPhoneSize: boolean;
  isPhoneSize: boolean;
  isSmallTabletSize: boolean;
}

export const useResponsiveSizes = (): ResponsiveSizes => {
  const theme = useTheme();
  const isXLSize = useMediaQuery(theme.breakpoints.up('xl'));
  const isDesktopSize = useMediaQuery(theme.breakpoints.up('lg'));
  const isTabletSize = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const isSmallTabletSize = useMediaQuery(
    theme.breakpoints.between('sm', 'md')
  );
  const isSmallPhoneSize = useMediaQuery(theme.breakpoints.down('xs'));
  const isPhoneSize = useMediaQuery(theme.breakpoints.down('sm'));

  const isMobileSize =
    isSmallPhoneSize || isPhoneSize || isTabletSize || isSmallTabletSize;
  const result = {
    isXLSize,
    isDesktopSize,
    isTabletSize,
    isSmallTabletSize,
    isSmallPhoneSize,
    isPhoneSize,
    isMobileSize,
  };
  // console.log(result);
  return result;
};

export type BreakpointValue = number | string | boolean | SimpleDict;
type SimpleDict = {
  [k: string]: BreakpointValue;
};
type BreakpointValueMap<T> = {
  [K in Breakpoint]?: T;
};
type BreakpointArrayEntry<T> = [Breakpoint, T];

export const useResponsiveValues = <
  ValueT = BreakpointValue,
  T extends { [s: string]: ValueT } = BreakpointValueMap<ValueT>
>(
  valDict: T
) => {
  const theme = useTheme();
  const { isXXXL, isXXL, isXL, isLG, isMD, isSM, isXS, isXXS } =
    useActiveResponsiveSizes(theme);
  const breakpointSizes: Breakpoint[] = getValidResponsiveSizes({
    isXXXL,
    isXXL,
    isXL,
    isLG,
    isMD,
    isSM,
    isXS,
    isXXS,
  });

  const validValue = validValueForAvailableSizes<ValueT>(
    valDict,
    breakpointSizes
  );
  return validValue;
};

function validValueForAvailableSizes<
  ValueT = BreakpointValue,
  T extends { [s: string]: ValueT } = BreakpointValueMap<ValueT>
>(valDict: T, breakpointSizes: Breakpoint[]) {
  const results = (Object.entries(valDict) as BreakpointArrayEntry<ValueT>[])
    .filter(([sizeKey, value]) => {
      return (
        breakpointSizes.findIndex(
          (breakpointOptionKey) => sizeKey === breakpointOptionKey
        ) !== -1
      );
    })
    .reduce((acc, entry) => {
      return entry;
    }, undefined as BreakpointArrayEntry<ValueT> | undefined);
  if (results) {
    const _r = results[1];
    return _r as ValueT;
  }
  return undefined;
}

const XXS_SIZES: Breakpoint[] = ['xxs'];
const XS_SIZES: Breakpoint[] = ['xxs', 'xs'];
const SM_SIZES: Breakpoint[] = ['xxs', 'xs', 'sm'];
const MD_SIZES: Breakpoint[] = ['xxs', 'xs', 'sm', 'md'];
const LG_SIZES: Breakpoint[] = ['xxs', 'xs', 'sm', 'md', 'lg'];
const XL_SIZES: Breakpoint[] = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl'];
const XXL_SIZES: Breakpoint[] = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
const XXXL_SIZES: Breakpoint[] = [
  'xxs',
  'xs',
  'sm',
  'md',
  'lg',
  'xl',
  'xxl',
  'xxxl',
];
function getValidResponsiveSizes({
  isXXXL,
  isXXL,
  isXL,
  isLG,
  isMD,
  isSM,
  isXS,
  isXXS,
}: {
  isXXXL: boolean;
  isXXL: boolean;
  isXL: boolean;
  isLG: boolean;
  isMD: boolean;
  isSM: boolean;
  isXS: boolean;
  isXXS: boolean;
}) {
  let breakpointSizes: Breakpoint[] = [];
  if (isXXS) {
    breakpointSizes = XXS_SIZES;
  } else if (isXS) {
    breakpointSizes = XS_SIZES;
  } else if (isSM) {
    breakpointSizes = SM_SIZES;
  } else if (isMD) {
    breakpointSizes = MD_SIZES;
  } else if (isLG) {
    breakpointSizes = LG_SIZES;
  } else if (isXL) {
    breakpointSizes = XL_SIZES;
  } else if (isXXL) {
    breakpointSizes = XXL_SIZES;
  } else if (isXXXL) {
    breakpointSizes = XXXL_SIZES;
  } else {
    breakpointSizes = XXXL_SIZES;
  }

  return breakpointSizes;
}

function useActiveResponsiveSizes(theme: AppTheme) {
  const isXXXL = useMediaQuery(theme.breakpoints.up('xxxl'));
  const isXXL = useMediaQuery(theme.breakpoints.down('xxxl'));
  const isXL = useMediaQuery(theme.breakpoints.down('xxl'));
  const isLG = useMediaQuery(theme.breakpoints.down('xl'));
  const isMD = useMediaQuery(theme.breakpoints.down('lg'));
  const isSM = useMediaQuery(theme.breakpoints.down('md'));
  const isXS = useMediaQuery(theme.breakpoints.down('sm'));
  const isXXS = useMediaQuery(theme.breakpoints.down('xs'));
  return { isXXXL, isXXL, isXL, isLG, isMD, isSM, isXS, isXXS };
}
