import { FormattedMessage, useIntl } from 'react-intl';
import { styled, Divider, Stack, Dialog, DialogTitle } from '@mui/material';
import { Area, useUpdateArea } from '@beeriot/api-client';
import { Form, FormFooterButtons } from '../../form';
import { SectionLabel, SectionTextInput } from '../../settings/settings-form';

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.componentBase.main,
}));

const StyledFormFooterButtons = styled(FormFooterButtons)({
  margin: '1rem',
});

interface AreaNameFormFields {
  name: string;
}

interface AreaNameFormDialogProps {
  area: Area;
  open: boolean;
  close: () => void;
}

export function AreaNameFormDialog({
  area,
  open,
  close,
}: AreaNameFormDialogProps) {
  const intl = useIntl();
  const updateArea = useUpdateArea(area.companySiteId, area.id);

  const namePlaceholder = intl.formatMessage({
    id: 'area-name-placeholder',
    description: 'Placeholder for area name input',
    defaultMessage: 'Enter area name',
  });

  return (
    <Dialog open={open}>
      <StyledDialogTitle>
        <FormattedMessage
          id="area-name-form-title"
          defaultMessage="Edit Area - {areaName}"
          description="Title for area name form"
          values={{ areaName: area.name }}
        />
      </StyledDialogTitle>
      <Form<AreaNameFormFields>
        onSubmit={(data) => {
          updateArea.mutate({
            name: data.name,
            companyId: area.companyId,
            companySiteId: area.companySiteId,
            sortOrder: area.sortOrder,
            status: 'active',
          });
          close();
        }}
      >
        <Stack direction="row">
          <SectionLabel>
            <FormattedMessage
              id="area-name-form-input-label"
              defaultMessage="Area Name"
              description="label for area name input"
            />
          </SectionLabel>
          <SectionTextInput
            name="name"
            placeholder={namePlaceholder}
            defaultValue={area.name}
          />
        </Stack>
        <Divider />
        <StyledFormFooterButtons
          requireCancelConfirmation={false}
          justify="center"
          isLoading={updateArea.isLoading}
          onCancel={close}
        />
      </Form>
    </Dialog>
  );
}
