
export const PrefetchListCompanySitesDocument = `
    query listCompanySites($companyId: String, $group: String, $name: String, $status: String, $skip: Int, $limit: Int) {
  listCompanySites(
    companyId: $companyId
    group: $group
    name: $name
    status: $status
    skip: $skip
    limit: $limit
  ) {
    address {
      address1
      address2
      city
      state
      zipcode
      country
    }
    id
    name
    longitude
    latitude
    status
    groups
    companyId
    
    
  }
}
    `;
