import { ProvisioningSettingsPanel } from '../../settings';
import { SettingsPage } from './SettingsPage';

export function ProvisioningSettingsPage() {
  return (
    <SettingsPage>
      <ProvisioningSettingsPanel />
    </SettingsPage>
  );
}
