import { AppTypography } from '../../../../../base/AppTypography';
import { useResponsiveValues } from '../../../../../utils/responsive-design/useResponsiveSizes';
import { Variant } from '@mui/material/styles/createTypography';

export const SystemCardTitle = ({
  cardTitle,
  boldTitle = true,
  typographyVariantOverride,
}: {
  cardTitle?: string;
  boldTitle?: boolean;
  typographyVariantOverride?: Variant;
}) => {
  const typographyVariant = useResponsiveValues<Variant>({
    xxs: 'h4',
    sm: 'h4',
    md: 'h5',
  });
  return (
    <AppTypography
      variant={typographyVariantOverride ?? typographyVariant}
      $bold={boldTitle}
    >
      {cardTitle}
    </AppTypography>
  );
};
