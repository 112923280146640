import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { Variant } from '@mui/material/styles/createTypography';
import { FormattedMessage } from 'react-intl';
import { VerticalLabels, VerticalLabelsProps } from './VerticalLabels';
import {
  RunningState,
  RunningStateColorKey,
  RunningStateIcon,
  useIntlRunningState,
} from '../enums';
import { KPIVerticalLabels } from '../lines/line-status/base-card-common/grids/kpi-grid/KPIVerticalLabel';
import { ReactNode, useMemo } from 'react';

export enum CurrentStateLabelComponent {
  NormalVerticalLabel = 'NormalVerticalLabel',
  KPIVerticalLabel = 'KPIVerticalLabel',
}

export interface CurrentStateLabelProps {
  /** setting this will move the running state into the sub label */
  mainLabelOverride?: ReactNode;
  subLabelOverride?: ReactNode;
  labelComponentType?: CurrentStateLabelComponent;
  state?: RunningState;
  hideSubLabel?: boolean;
  labelVariant?: Variant;
  labelBold?: boolean;
  iconSize?: SizeProp;
  className?: string;
}

export function CurrentStateLabel({
  mainLabelOverride,
  subLabelOverride,
  state,
  labelComponentType = CurrentStateLabelComponent.NormalVerticalLabel,
  hideSubLabel = false,
  labelVariant = 'subtitle1',
  labelBold = false,
  iconSize = 'sm',
  className = undefined,
}: CurrentStateLabelProps) {
  const intlRunningState = useIntlRunningState();
  const icon = state ? RunningStateIcon[state] : undefined;
  const color = state ? RunningStateColorKey[state] : 'disabled';

  // Let the dev configure whether this used a VerticalLabel or KPIVerticalLabel
  let LabelElement = VerticalLabels;
  if (labelComponentType === CurrentStateLabelComponent.KPIVerticalLabel) {
    LabelElement = KPIVerticalLabels as (
      props: VerticalLabelsProps
    ) => JSX.Element;
  }

  // if the dev supplied a `mainLabelOverride`, then put the running
  // state into the sub label.  here we memoize the main/sub label components
  // and the props for the label/sublabel.
  const { mainLabel, subLabel, labelProps } = useMemo(() => {
    const mainLabel = mainLabelOverride ?? intlRunningState(state);
    const subLabel = subLabelOverride ? (
      subLabelOverride
    ) : mainLabelOverride ? (
      intlRunningState(state)
    ) : hideSubLabel ? null : (
      <FormattedMessage
        id="common-current-state"
        defaultMessage="Current State"
        description="Label for current state"
      />
    );

    // if this is putting the running state in the bottom label, we need to
    //  move the props that would have gone to the main label to the sub-label props
    const labelProps = !mainLabelOverride
      ? {
          labelColorKey: color,
          labelIcon: icon,
          labelIconSize: iconSize,
          labelBold: labelBold,
        }
      : {
          subLabelColorKey: color,
          subLabelIcon: icon,
          subLabelIconSize: iconSize,
          subLabelBold: labelBold,
        };
    return {
      mainLabel,
      subLabel,
      labelProps,
    };
  }, [
    color,
    hideSubLabel,
    icon,
    iconSize,
    intlRunningState,
    labelBold,
    mainLabelOverride,
    state,
    subLabelOverride,
  ]);

  return (
    <LabelElement
      className={className}
      labelVariant={labelVariant}
      label={mainLabel}
      subLabel={subLabel}
      {...labelProps}
    />
  );
}
