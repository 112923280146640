import { useCallback, useMemo } from 'react';
import { Stack, styled, useTheme } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import Select, {
  components,
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  GroupBase,
  OptionProps,
  SingleValueProps,
  StylesConfig,
  ValueContainerProps,
  MultiValueGenericProps,
} from 'react-select';
import { Property } from 'csstype';
import { ColorKey } from '@beeriot/api-client';
import { SelectOption } from './SelectOption';
import { AppMedallion } from '../base/AppMedallion';
import {
  AppTypography,
  OverridableComponentAppTypography,
} from '../base/AppTypography';
import { AppIcon } from '../base/AppIcon';
import { transientOptions } from '../utils/transientOptions';

const StyledAppIcon = styled(AppIcon)({
  fontSize: '1.5rem',
  paddingRight: '1em'
});

const StackContainer = styled(Stack)({
  height: 'fit-content',
  flexDirection: 'row',
  alignItems: 'center',
  flexShrink: 0,
});

const SingleRowStackContainer = styled(Stack)({
  width: '100%',
  height: 'fit-content',
  flexDirection: 'row',
  alignItems: 'center',
});

const StyledTypography = styled(AppTypography)({
  display: 'flex',
  height: 'fit-content',
  padding: '0.25rem 0.5rem 0.25rem 0rem',
  alignItems: 'center',
  flexShrink: 0,
  width: 'calc(95%)',
}) as OverridableComponentAppTypography;

const InnerLabelTypography = styled(AppTypography)({
  padding: '0.25rem 0.5rem 0.25rem 0rem',
});

const StyledMedallion = styled(AppMedallion)({
  marginRight: '0.5rem',
});

const OptionContainer = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

const StackMultiValueContainer = styled(
  Stack,
  transientOptions
)<{ $colorKey: ColorKey }>(({ theme, $colorKey }) => ({
  flexDirection: 'row',
  backgroundColor: theme.palette[$colorKey].main,
  color: theme.palette.white.main,
  borderRadius: '15px',
  paddingLeft: '5px',
}));

function getMedallionColor(options: SelectOption[], currVal: SelectOption) {
  const medallionColors: ColorKey[] = [
    'highlight7',
    'highlight4',
    'highlight6',
    'highlight3',
    'highlight5',
    'highlight1',
    'highlight2',
  ];

  const currIndex = options.findIndex((x) => {
    const option = x as SelectOption;
    return option.value === currVal.value;
  });
  return currVal.colorKey ?? medallionColors[currIndex];
}

export interface FormSelectProps {
  name: string;
  options: SelectOption[] | null | undefined;
  placeholder: string;
  defaultValue?: SelectOption | SelectOption[] | null;
  required?: boolean;
  disabled?: boolean;
  isSearchable?: boolean;
  innerLabel?: React.ReactNode;
  styles?: StylesConfig<unknown, boolean, GroupBase<unknown>>;
  className?: string;
  hasColorMedallion?: boolean;
  isMulti?: boolean;
  shortMenu?: boolean;
  $backgroundColor?: ColorKey;
  $color?: ColorKey;
  innerLabelMaxWidth?: Property.MaxWidth;
  valueMaxWidth?: Property.MaxWidth;
  validate?: (value?: string) => boolean;
}

export function FormSelect({
  name,
  options,
  placeholder,
  defaultValue,
  required,
  disabled,
  isSearchable = false,
  innerLabel,
  styles,
  className,
  hasColorMedallion,
  isMulti,
  shortMenu,
  $backgroundColor,
  $color,
  innerLabelMaxWidth,
  valueMaxWidth,
  validate,
}: FormSelectProps) {
  const theme = useTheme();
  const { control } = useFormContext();

  const rules = useMemo(() => {
    return { required, validate };
  }, [required, validate]);

  const selectStyles = useMemo(() => {
    return (
      styles ?? {
        valueContainer: (base: CSSObjectWithLabel) =>
          ({
            ...base,
            flexDirection: 'row',
            display: 'flex',
            color: $color ? theme.palette[$color].main : base.color,
          } as CSSObjectWithLabel),
        control: (base: CSSObjectWithLabel) =>
          ({
            ...base,
            height: '100%',
            border: 'none',
            backgroundColor: $backgroundColor
              ? theme.palette[$backgroundColor].main
              : theme.palette.componentShade.main,
            justifyContent: 'stretch',
          } as CSSObjectWithLabel),
        singleValue: (base: CSSObjectWithLabel) => ({
          ...base,
          color: $color ? theme.palette[$color].main : base.color,
        }),
        multiValueRemove: (base: CSSObjectWithLabel) => ({
          ...base,
          borderRadius: '0px 15px 15px 0px',
        }),
        multiValueLabel: (base: CSSObjectWithLabel) => ({
          ...base,
          color: $color ? theme.palette[$color].main : theme.palette.white.main,
          paddingRight: '5px',
          paddingLeft: '5px',
        }),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        menuList: (styles: any) => {
          return {
            ...styles,
            maxHeight: shortMenu ? 136 : 'inherit',
          };
        },
      }
    );
  }, [styles, theme.palette, $backgroundColor, $color, shortMenu]);

  const IndicatorSeparator = useCallback(() => {
    return null;
  }, []);

  const DropdownIndicator = useCallback(
    (props: DropdownIndicatorProps) => {
      return (
        <components.DropdownIndicator {...props}>
          <Stack>
          <StyledAppIcon
            icon={'chevron-down'}
            $colorKey={$color ?? 'link'}
            size="lg"
            style={{paddingRight:'1em'}}
          />
          </Stack>
          
        </components.DropdownIndicator>
      );
    },
    [$color]
  );

  const Option = useCallback(
    ({ children, ...props }: OptionProps) => {
      let medallionColorKey = null;
      if (hasColorMedallion) {
        medallionColorKey = getMedallionColor(
          props.options as SelectOption[],
          props.data as SelectOption
        );
      }

      return isMulti ? (
        <components.Option {...props}>
          <OptionContainer>
            <StackContainer>
              {hasColorMedallion ? (
                <StyledMedallion colorKey={medallionColorKey ?? 'highlight1'} />
              ) : null}
              <StyledTypography $colorKey="darkText">
                {children}
              </StyledTypography>
            </StackContainer>
          </OptionContainer>
        </components.Option>
      ) : (
        <components.Option {...props}>{children}</components.Option>
      );
    },
    [hasColorMedallion, isMulti]
  );

  const ValueContainer = useCallback(
    ({ children, ...props }: ValueContainerProps) => {
      return (
        <components.ValueContainer {...props}>
          <SingleRowStackContainer sx={{ minWidth: 0 }}>
            {innerLabel && (
              <InnerLabelTypography
                $maxWidth={innerLabelMaxWidth}
                $ellipsize
                $colorKey={$color ?? 'lightText'}
                sx={{ minWidth: '.25rem', flex: '1 0 auto' }}
              >
                {innerLabel}
              </InnerLabelTypography>
            )}
            <StyledTypography
              component="div"
              $maxWidth={valueMaxWidth}
              $ellipsize
              $colorKey={$color ?? 'darkText'}
              $bold
            >
              {children}
            </StyledTypography>
          </SingleRowStackContainer>
        </components.ValueContainer>
      );
    },
    [$color, innerLabelMaxWidth, valueMaxWidth, innerLabel]
  );

  const MultiValueContainer = useCallback(
    ({ children, ...props }: MultiValueGenericProps) => {
      const optionColorKey = getMedallionColor(
        props.selectProps.options as SelectOption[],
        props.data as SelectOption
      );
      return (
        <components.MultiValueContainer {...props}>
          <StackMultiValueContainer $colorKey={optionColorKey}>
            {children}
          </StackMultiValueContainer>
        </components.MultiValueContainer>
      );
    },
    []
  );

  const SingleValue = useCallback(
    ({ children, ...props }: SingleValueProps) => {
      let medallionColorKey = null;
      if (hasColorMedallion) {
        medallionColorKey = getMedallionColor(
          props.options as SelectOption[],
          props.data as SelectOption
        );
      }
      return hasColorMedallion ? (
        <components.SingleValue {...props}>
          <StackContainer>
            <StyledMedallion colorKey={medallionColorKey ?? 'highlight1'} />
            <StyledTypography
              component="div"
              $colorKey="darkText"
              $bold
              minWidth={'0.25rem'}
            >
              {children}
            </StyledTypography>
          </StackContainer>
        </components.SingleValue>
      ) : (
        <components.SingleValue {...props}>{children}</components.SingleValue>
      );
    },
    [hasColorMedallion]
  );

  const customComponents = isMulti
    ? {
        IndicatorSeparator,
        DropdownIndicator,
        Option,
        SingleValue,
        MultiValueContainer,
      }
    : {
        IndicatorSeparator,
        DropdownIndicator,
        Option,
        SingleValue,
        ValueContainer,
      };

  return (
    <Controller
      name={name}
      rules={rules}
      render={({ field }) => (
        <Select
          {...field}
          isDisabled={disabled}
          className={className}
          isSearchable={isSearchable}
          placeholder={placeholder}
          styles={selectStyles}
          components={customComponents}
          options={options ?? []}
          isMulti={isMulti}
          closeMenuOnSelect={isMulti ? false : true}
          menuPlacement={'auto'}
        />
      )}
      control={control}
      defaultValue={defaultValue ?? ''}
    />
  );
}
