import { styled } from '@mui/material';
import { AppTypography } from '../../../base/AppTypography';

const SectionLabel = styled(AppTypography)(({ theme }) => ({
  padding: '0.5rem 1rem',
}));

SectionLabel.defaultProps = {
  variant: 'subtitle1',
  $colorKey: 'lightText',
  $bold: true,
};

export { SectionLabel };
