const squared = '\u00B2';
const cubed = '\u00B3';
const degrees = '\u00b0';

// TODO: Unit localization
export const systemStatsUnits: Record<string, string> = {
  volumeFiltered: 'hL',
  totalStabilizedBeer: 'hL',
  totalColdFilteredBeer: 'hL',
  totalRecoveredBeer: 'hL',
  averageFlow: 'hL/h',
  degreeOfEfficiency: '%',
  tmpIncrease: 'bar',
  filtrationOperatedToFinalTMP: '%',
  capacityFullRunsTillTMPBar: `l/m${squared}`,
  filterLineUtilization: '%',
  netCapacity: `l/m${squared}`,
  totalCIPS: '',
  beerLossByFilterLoss: '%',
  water: `m${cubed}`,
  waterConsumption: `m${cubed}`,
  daWater: `m${cubed}`,
  dawaterConsumption: `m${cubed}`,
  // TODO: localize currency
  actualStabilizationCost: '$/h',
  electricalCost: '$/kWh',
  coolingEnergyCost: '$/kWh',
  waterCost: `$/m${cubed}`,
  dawaterCost: `$/m${cubed}`,
  causticCost: '$/kg',
  peroxideCost: '$/kg',
  acidicCost: '$/kg',
  membraneCleanerCost: '$/kg',
  enzymaticCost: '$/hL',
  caustic: 'kg',
  causticConsumption: 'kg',
  peroxyde: 'kg',
  peroxideConsumption: 'kg',
  acidicChemical: 'kg',
  membraneCleaner: 'kg',
  membraneCleanerKg: 'kg',
  kWHl: 'kW/hL',
  electrical: 'kWh',
  electricalEnergy: 'kWh',
  oxygen: 'ppm',
  hazeCentrifugeOut: 'EBC',
  hazeFilterOut: 'EBC',
  clusterTestFailurePct: '%',
  dpTrendBar: 'bar',
  fluxRate: `m${squared}/h`,
  sanitationCycles: '',
  causticCycles: '',
  enzymaticCycles: '',
  enzymaticConsumption: 'hL',
  diaFiltrationYield: '%',
  beerYield: '%',
  plato: degrees,
  averageTemperature: degrees,
  nitricAcid: 'kg',
  coolingEnergy: 'kWh',
  totalCost: '$',
  avgCost: '$',
};
