import { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Stack, Button, styled } from '@mui/material';
import {
  useUpdateCompanySite,
  CompanySite,
  CompanySiteBase,
  activeSite$,
  siteStore,
} from '@beeriot/api-client';
import { useObservable } from '@ngneat/use-observable';
import {
  AppBreadcrumbsContainer,
  ControlBox,
  IconLabel,
  PermissionView,
  PermissionKey,
  RoutingBreadcrumbs,
} from '../../../base';
import { EditSiteDialog } from '../../../sites/site-edit-card-list/EditSiteDialog';
import { useResponsiveSizes } from '../../../utils';

const ControlContainer = styled(Stack)({
  width: '100%',
});

const StyledControlBox = styled(ControlBox)({
  justifyContent: 'end',
});

const EditSiteButton = styled(Button)({});

export function SiteDetailsControlBox() {
  const { isMobileSize } = useResponsiveSizes();
  const [activeSite] = useObservable(activeSite$);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const showEditSiteModal = () => {
    setIsEditModalOpen(true);
  };

  const updateSite = useUpdateCompanySite(activeSite?.id ?? '', {
    onSuccess: (data: CompanySite | undefined) => {
      setIsEditModalOpen(false);
      if (data) siteStore.update((state) => ({ ...state, activeSite: data }));
    },
    onError: (err: string) => {
      console.log('updateSite: onError: ', err);
    },
  });

  const handleUpdateSite = useCallback(
    (site: CompanySiteBase) => {
      updateSite.mutate(site);
    },
    [updateSite]
  );

  return (
    <ControlContainer direction="row">
      <AppBreadcrumbsContainer>
        <RoutingBreadcrumbs />
      </AppBreadcrumbsContainer>
      {!isMobileSize && (
        <StyledControlBox>
          <PermissionView permissionKey={PermissionKey.SitesEditSites}>
            <EditSiteButton variant="contained" onClick={showEditSiteModal}>
              <IconLabel
                icon="pen-to-square"
                variant="body1"
                label={
                  <FormattedMessage
                    id="sites-edit-site-button"
                    description="Button to show edit site dialog"
                    defaultMessage="Edit Site"
                  />
                }
              />
            </EditSiteButton>
          </PermissionView>
        </StyledControlBox>
      )}
      {activeSite && (
        <EditSiteDialog
          mutation={updateSite}
          site={activeSite}
          open={isEditModalOpen}
          confirm={handleUpdateSite}
          cancel={() => setIsEditModalOpen(false)}
        />
      )}
    </ControlContainer>
  );
}
