import { FormattedMessage } from 'react-intl';
import { AppTableHeader } from '../../base';
import { ReportsHeaderButtons } from './ReportsHeaderButtons';

interface ReportsPanelHeaderProps {
  showAddRecurringReportForm: () => void;
  toggleRecurringReports: () => void;
  showRecurringReports: boolean;
}

export const ReportsPanelHeader = ({
  showAddRecurringReportForm,
  toggleRecurringReports,
  showRecurringReports,
}: ReportsPanelHeaderProps) => {
  return (
    <AppTableHeader
      label={
        showRecurringReports ? (
          <FormattedMessage
            id="recurring-reports-table-header-title"
            defaultMessage="Recurring Reports"
            description="header for recurring reports table"
          />
        ) : (
          <FormattedMessage
            id="reports-table-header-title"
            defaultMessage="Reports"
            description="header for reports table"
          />
        )
      }
    >
      <ReportsHeaderButtons
        showAddRecurringReportForm={showAddRecurringReportForm}
        toggleRecurringReports={toggleRecurringReports}
        showRecurringReports={showRecurringReports}
      />
    </AppTableHeader>
  );
};
