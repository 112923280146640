import {
  forwardRef,
  ForwardRefRenderFunction,
  FunctionComponent,
  RefAttributes,
} from 'react';

/// returns a sane normal type
export function betterForwardRef<R, P = Record<string, unknown>>(
  forwardedComponent: ForwardRefRenderFunction<R, P>
) {
  return forwardRef<R, P>(forwardedComponent) as unknown as FunctionComponent<
    P & RefAttributes<R>
  >;
}
