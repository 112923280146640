import { Navigate } from 'react-router-dom';
import { GQLCompanySite, useActiveAppEntities } from '@beeriot/api-client';
import { PermissionKey } from '../../base/user-permissions/PermissionKey';
import { useHasPermission } from '../../base/user-permissions/useHasPermission';
import { useMemo } from 'react';

const systemTypePermissionKeyRecord: Record<string, PermissionKey> = {
  profi: PermissionKey.SitesPROFiLineStatus,
  cbs: PermissionKey.SitesCBSLineStatus,
  cfs: PermissionKey.SitesCFSLineStatus,
  keraflux: PermissionKey.SitesKerafluxLineStatus,
};
export const DefaultRouteNavigation = () => {
  const { activeCompany, activeSite, activeSystem, sites } = useActiveAppEntities();
  const hasPermission = useHasPermission();

  const res = useMemo(() => {
    if (hasPermission(PermissionKey.CompaniesList)) {
      return <Navigate to="/companies" />;
    }
  
    if (!activeCompany) {
      return null; // wait for state
    }
  
    if (hasPermission(PermissionKey.SitesList)) {
      return <Navigate to={`/companies/${activeCompany.id}/sites`} />;
    }
  
    if ((!activeSite && !sites) || ( sites && sites.length > 0)) {
      return null; // wait for state
    }
    const site = activeSite ?? sites?.[0] as GQLCompanySite;
    if (hasPermission(PermissionKey.SiteDetails)) {
      
      return (
        <Navigate
          to={`/companies/${activeCompany.id}/sites/${site?.id}/overview`}
        />
      );
    }
  
    if (!activeSystem) {
      return null; // wait for state
    }
  
    if (
      activeSystem?.type &&
      hasPermission(systemTypePermissionKeyRecord[activeSystem.type])
    ) {
      return (
        <Navigate
          to={`/companies/${activeCompany.id}/sites/${site.id}/lines/${activeSystem.id}/line-status`}
        />
      );
    }
  
    return <Navigate to="/auth" />;
  },[activeCompany, activeSite, activeSystem, hasPermission, sites]);
  return res;
};
