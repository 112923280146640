import { Box, Button, Stack, StackProps, styled } from '@mui/material';
import { useCallback } from 'react';
import Dropzone, {
  DropzoneRef,
  DropzoneRootProps,
  DropzoneProps,
  FileWithPath,
  DropEvent,
  FileRejection,
} from 'react-dropzone';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useValidationRules } from './useValidationRules';
import { IconLabel } from '../base/IconLabel';
import { SectionLabel } from '../settings/settings-form';

interface DropdownContainerProps extends StackProps {
  /**
   * Is dragging file on top of component
   */
  isdragontop: string;
}

const DropzoneContainer = styled(Stack)<DropdownContainerProps>(
  ({ isdragontop, theme }) => ({
    flex: 1,
    flexDirection: 'column',
    padding: '1rem 0.5rem 1rem 0rem',
    margin: '0.2rem',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: isdragontop === 'true' ? '#e0e0e0' : '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out, color .24s ease-in-out',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '25%',
    },
  })
);

const DropzoneControlsContainer = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
    flexDirection: 'column',
  },
}));

const FileUploadButton = styled(Button)({
  maxHeight: '2.5rem',
  minWidth: '10rem',
});

const FileInputLabel = styled(SectionLabel)(({ theme }) => ({
  flex: 2,
  overflowWrap: 'break-word',
  inlineSize: '20rem',
  [theme.breakpoints.down('sm')]: {
    inlineSize: '10rem',
  },
}));

function formatBytes(bytes: number) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let i;
  for (i = 0; bytes >= 1024 && i < 4; i++) {
    bytes /= 1024;
  }
  return bytes.toFixed(2) + units[i];
}

interface FormDropzoneProps extends DropzoneProps {
  name: string;
  placeholder?: string;
  required?: boolean;
  validationOptions?:
    | Partial<RegisterOptions>
    | ((values: Record<string, unknown>) => Partial<RegisterOptions>);
  multiple?: boolean;
  maxFiles?: number;
}

export const FormDropzone = ({
  name,
  placeholder,
  required,
  validationOptions,
  multiple = false,
  maxFiles = 1,
}: FormDropzoneProps) => {
  const { setValue } = useFormContext();
  const onDrop = useCallback(
    (
      acceptedFiles: FileWithPath[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      setValue(name, acceptedFiles, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue, name]
  );
  const onFileDialogOpen = useCallback(() => {
    setValue(name, undefined, { shouldValidate: true });
  }, [setValue, name]);

  const { rules, register } = useValidationRules(required, validationOptions);
  const { ref: registerRef } = register(name, rules);
  let dropzoneRef: DropzoneRef;

  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef) {
      dropzoneRef.open();
    }
  };

  // Handle updating value state when items are selected
  const onValidate = (file: File) => {
    if (file) {
      setValue(name, [file], {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    return null;
  };

  return (
    <Dropzone
      ref={(ref) => {
        if (ref) {
          dropzoneRef = ref;
          registerRef(ref);
        }
      }}
      noClick
      noKeyboard
      multiple={multiple}
      maxFiles={maxFiles}
      onDrop={onDrop}
      onFileDialogOpen={onFileDialogOpen}
      validator={onValidate}
    >
      {({
        acceptedFiles,
        isDragActive,
        isDragAccept,
        isDragReject,
        getRootProps,
        getInputProps,
      }: {
        acceptedFiles: FileWithPath[];
        isDragActive: boolean;
        isDragAccept: boolean;
        isDragReject: boolean;
        getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps;
        getInputProps: (props?: DropzoneRootProps) => DropzoneRootProps;
      }) => {
        const hasSelectedFile = acceptedFiles && acceptedFiles.length > 0;

        return (
          <DropzoneContainer
            className="container"
            isdragontop={isDragActive && isDragAccept ? 'true' : 'false'}
          >
            <DropzoneControlsContainer
              {...getRootProps({ className: 'dropzone' })}
            >
              <input {...getInputProps()} />
              <FileInputLabel>
                {hasSelectedFile
                  ? acceptedFiles.map((file, i) => (
                      <Box key={file.name + '-' + i}>
                        {file.path} - {formatBytes(file.size)}
                      </Box>
                    ))
                  : placeholder ??
                    (maxFiles > 1 ? (
                      <FormattedMessage
                        id="common-drag-dropmax-label"
                        defaultMessage="Drag and Drop file(s) here. Maximum of {maxFiles}."
                        description="Label for drag and drop with a maximum number of files"
                        values={{
                          maxFiles: maxFiles,
                        }}
                      />
                    ) : (
                      <FormattedMessage
                        id="common-drag-drop-label"
                        defaultMessage="Drag and Drop file here"
                        description="label for drag and drop"
                      />
                    ))}
              </FileInputLabel>
              <FileUploadButton
                onClick={openDialog}
                variant="contained"
                color="success"
              >
                <IconLabel
                  label={
                    multiple ? (
                      <FormattedMessage
                        id="common-multiple-file-selection-label"
                        defaultMessage="Select file(s)"
                        description="Label for multiple file selection button"
                      />
                    ) : hasSelectedFile ? (
                      <FormattedMessage
                        id="common-file-re-selection-label"
                        defaultMessage="Change file"
                        description="label for file re-selection button"
                      />
                    ) : (
                      <FormattedMessage
                        id="common-file-selection-label"
                        defaultMessage="Select file"
                        description="label for file selection button"
                      />
                    )
                  }
                  variant="button"
                  colorKey="white"
                  icon={['fas', 'cloud-arrow-up']}
                  iconColorKey="white"
                  iconSize="lg"
                />
              </FileUploadButton>
            </DropzoneControlsContainer>
          </DropzoneContainer>
        );
      }}
    </Dropzone>
  );
};
