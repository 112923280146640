import { AWSError, CognitoIdentityServiceProvider } from 'aws-sdk';
import { PromiseResult } from 'aws-sdk/lib/request';
import { axiosHelper } from '../generated/axiosHelper';
import {
  GetCompanyDocument,
  GQLGetCompanyQuery,
  GQLGetCompanyQueryVariables,
} from '../generated/gql';
import { companyStore } from '../state/company/companyStore';
import { getUserAttribute } from './getUserAttribute';
import { getMembership } from './permissions';
import { setToDefaultSite } from './sites/setToDefaultSite';
import { setToDefaultSystem } from './systems/setToDefaultSystem';
import { MembershipRoleNameEnum } from '../generated';
import { setActiveCompany } from '../state/company/setActiveCompany';
import { AppQueryClient } from '../utils';
import { TransitionStartFunction } from 'react';

const siteRequiredRoles: MembershipRoleNameEnum[] = [
  MembershipRoleNameEnum.Supervisor,
  MembershipRoleNameEnum.Operator,
];

const systemRequiredRoles: MembershipRoleNameEnum[] = [
  MembershipRoleNameEnum.Operator,
];

// Initializes any store resources that should be populated prior to the app loading.
// Intended to be called during login.
export async function initialize(
  userInfo: PromiseResult<
    CognitoIdentityServiceProvider.GetUserResponse,
    AWSError
  >,
  startTransition: TransitionStartFunction,
) {
  const userId = getUserAttribute('sub', userInfo?.UserAttributes);
  const membership = await getMembership(userId);
  if (!membership) throw new Error('Membership not found');

  (async () => {
    const companyQuery = axiosHelper<
    GQLGetCompanyQuery,
    GQLGetCompanyQueryVariables
  >(GetCompanyDocument).bind(null, { companyId: membership?.companyId ?? '' });
  const rawCompanies = await companyQuery();
  const activeCompany = rawCompanies.getCompany
    ? rawCompanies.getCompany
    : null;
  if (activeCompany) {
    startTransition(() => {
    setActiveCompany(activeCompany);
    AppQueryClient.setQueryData(['getCompany', { companyId: activeCompany?.id, }],
    {getCompany: activeCompany},);
    });
    // companyStore.update((state) => ({
    //   ...state,
    //   activeCompany: activeCompany,
    // }));
  } else {
    throw new Error('Company not found');
  }

  const site = await setToDefaultSite(activeCompany.id);
  if (!site && siteRequiredRoles.includes(membership.roleName)) {
    throw new Error('Site is required and not found');
  }
  const system = await setToDefaultSystem(site?.id);
  if (!system && systemRequiredRoles.includes(membership.roleName)) {
    throw new Error('System is required and not found');
  }
  })();
 
  return membership
}
