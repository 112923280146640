import { useMemo } from 'react';
import { KPIGridItem } from '../../kpi-grid/KPIGrid';
import { SystemKpiHookProps } from '../../SystemKpiHookProps';
import { useShouldRenderKpi } from '../../useShouldRenderKpi';
import { CircularProgressLabel } from '../../../../../../base/CircularProgressLabel';

export function useProductionPercentCompleteGridItem(
  props: SystemKpiHookProps
) {
  const { system, gridArea, isDesktopSize } = props;
  const shouldRender = useShouldRenderKpi(props);

  return useMemo(() => {
    if (!shouldRender) {
      return undefined;
    }

    return (
      <KPIGridItem
        display="flex"
        gridArea={gridArea ?? 'percentCompleteIndicator'}
      >
        <CircularProgressLabel
          value={
            system?.liveStatus?.productionPercentCompletePct != null
              ? Math.trunc(system.liveStatus.productionPercentCompletePct)
              : undefined
          }
          colorKey="success"
          size={isDesktopSize ? '7rem' : '6rem'}
        />
      </KPIGridItem>
    );
  }, [
    shouldRender,
    gridArea,
    system?.liveStatus?.productionPercentCompletePct,
    isDesktopSize,
  ]);
}
