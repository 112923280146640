import { Alert, AlertColor, AlertTitle } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface FormErrorProps {
  error?: React.ReactNode;
  color?: AlertColor;
}

export function FormError({ error, color }: FormErrorProps) {
  return error ? (
    <Alert severity="error" variant="filled" color={color}>
      <AlertTitle>
        <FormattedMessage
          id="common-error-label"
          description="Label for an event error"
          defaultMessage="Error"
        />
      </AlertTitle>
      {error}
    </Alert>
  ) : null;
}
