import { useMemo } from 'react';
import { useActualDpGridItem } from './process-kpis/useActualDpGridItem';
import { useCipCyclesGridItem } from './process-kpis/useCipCyclesGridItem';
import { useCommonDegreeOfEfficiencyGridItem } from './process-kpis/useCommonDegreeOfEfficiencyGridItem';
import { useActualFlowGuageGridItem } from './process-kpis/useActualFlowGuageGridItem';
import { useNetCapacityGridItem } from './process-kpis/useNetCapacityGridItem';
import { useOxygenGridItem } from './process-kpis/useOxygenGridItem';
import { useLastHourAverageFlowGridItem } from './process-kpis/useLastHourAverageFlowGridItem';
import { useTurbidityGridItem } from './process-kpis/useTurbidityGridItem';
import { SystemKpiHookProps } from '../SystemKpiHookProps';
import { useSystemKpiHookDefaultProps } from '../useSystemKpiHookDefaultProps';
import { useStabilizedKpiGridItem } from './process-kpis/useStabilizedKpiGridItem';
import { useThroughputPerCassetteKpiGridItem } from './process-kpis/useThroughputPerCassetteKpiGridItem';
import { useCipCyclesSinceRefillGridItem } from './process-kpis/useCipCyclesSinceRefillGridItem';

export function useProcessKpiGridContent(
  props: Omit<
    SystemKpiHookProps,
    | 'showForSystemTypes'
    | 'hideOnMobileForSystemTypes'
    | 'NA'
    | 'gridArea'
    | 'activeKpis'
  >
) {
  const defaultHookProps = useSystemKpiHookDefaultProps(props);

  // process details grid
  const actualDpKpiItem = useActualDpGridItem({
    ...defaultHookProps,
    gridArea: 'actualDp',
  });
  const cipCyclesKpiItem = useCipCyclesGridItem({
    ...defaultHookProps,
    gridArea: 'cipCycles',
  });
  const cipCyclesSinceRefillKpiItem = useCipCyclesSinceRefillGridItem({
    ...defaultHookProps,
    gridArea: 'cipCyclesSinceRefill',
  });
  const commonDegreeOfEfficiencyKpiItem = useCommonDegreeOfEfficiencyGridItem({
    ...defaultHookProps,
    gridArea: 'commonDegreeOfEfficiency',
  });
  const actualFlowKpiItem = useActualFlowGuageGridItem({
    ...defaultHookProps,
    gridArea: 'actualFlow',
  });

  const netCapacityKpiItem = useNetCapacityGridItem({
    ...defaultHookProps,
    gridArea: 'netCapacity',
  });
  const turbidityKpiItem = useTurbidityGridItem({
    ...defaultHookProps,
    gridArea: 'turbidity',
  });
  const oxygenKpiItem = useOxygenGridItem({
    ...defaultHookProps,
    gridArea: 'oxygen',
  });

  const stabilizedKpiItem = useStabilizedKpiGridItem({
    ...defaultHookProps,
    gridArea: 'stabilized',
  });
  const lastHourAvgFlowKpiItem = useLastHourAverageFlowGridItem({
    ...defaultHookProps,
    gridArea: 'lastHourAverageFlow',
  });
  const throughputPerCassetteKpiItem = useThroughputPerCassetteKpiGridItem({
    ...defaultHookProps,
    gridArea: 'throughputPerCassette',
  });

  const _gridContent = useMemo(() => {
    return (
      <>
        {actualDpKpiItem}
        {actualFlowKpiItem}
        {commonDegreeOfEfficiencyKpiItem}
        {cipCyclesKpiItem}
        {cipCyclesSinceRefillKpiItem}
        {netCapacityKpiItem}
        {stabilizedKpiItem}
        {lastHourAvgFlowKpiItem}
        {throughputPerCassetteKpiItem}
        {turbidityKpiItem}
        {oxygenKpiItem}
      </>
    );
  }, [
    actualDpKpiItem,
    actualFlowKpiItem,
    commonDegreeOfEfficiencyKpiItem,
    cipCyclesKpiItem,
    cipCyclesSinceRefillKpiItem,
    netCapacityKpiItem,
    stabilizedKpiItem,
    lastHourAvgFlowKpiItem,
    throughputPerCassetteKpiItem,
    turbidityKpiItem,
    oxygenKpiItem,
  ]);
  return _gridContent;
}
