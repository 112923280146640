import React, { useEffect, useRef } from 'react';
import anime from 'animejs';
import { styled } from '@mui/material';

const LoadingSvg = styled('svg')`
  .line {
    opacity: 0.2;
  }
  box-sizing: unset;
`;

interface LoadingProps {
  className?: string;
  color?: string;
  width?: string | number;
  height?: string | number;
}

export const Loading: React.FC<LoadingProps> = ({
  height = '24px',
  width = '48px',
  color = 'currentColor',
  className,
}) => {
  const ref = useRef<SVGSVGElement>(null);
  const lineClass = 'line';

  useEffect(() => {
    const targets = ref.current?.querySelectorAll(`.${lineClass}`);
    const animation = anime({
      targets,
      translateX: [0, -7, 0],
      translateY: [0, -7.5, 0],
      scale: [1, 1.5, 1],
      opacity: [0.2, 1, 0.2],
      duration: 1300,
      easing: 'easeInOutSine',
      loop: true,
      delay: anime.stagger(130, { start: 0 }),
    });

    return () => {
      animation.pause();
      if (targets) anime.remove(targets);
    };
  }, [ref]);

  return (
    <LoadingSvg
      ref={ref}
      height={height}
      width={width}
      className={className}
      viewBox="0 0 98 45"
      version="1.1"
    >
      <defs>
        <path
          id="bar"
          fill={color}
          d="M 25.91,-0 C 28.29,-0 28.7,1.92 26.82,4.29 L 9.81,25.71 C 7.93,28.08 4.48,30 2.09,30 -0.29,30 -0.7,28.08 1.18,25.71 L 18.19,4.29 C 20.07,1.92 23.52,-0 25.91,-0 Z M 25.91,-0"
        />
      </defs>
      <g transform="translate(7, 7.5)">
        <g transform="translate(0, 0)">
          <use className={lineClass} xlinkHref="#bar" />
        </g>
        <g transform="translate(14, 0)">
          <use className={lineClass} xlinkHref="#bar" />
        </g>
        <g transform="translate(28, 0)">
          <use className={lineClass} xlinkHref="#bar" />
        </g>
        <g transform="translate(42, 0)">
          <use className={lineClass} xlinkHref="#bar" />
        </g>
        <g transform="translate(56, 0)">
          <use className={lineClass} xlinkHref="#bar" />
        </g>
      </g>
    </LoadingSvg>
  );
};
