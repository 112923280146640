import { Paper, Stack, styled } from '@mui/material';
import { TelemetryDataExport } from '@beeriot/api-client';
import { useResponsiveSizes } from '../../utils';
import { TelemetryDataExportPanelHeader } from './TelemetryDataExportPanelHeader';
import { SuspenseTelemetryDataExportTableContainer } from './SuspenseTelemetryDataExportTableContainer';

const TableContainer = styled(Stack)(({ theme }) => ({
  margin: '1rem',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  overflow: 'auto',
}));

export interface TelemetryDataExportTableContainerProps {
  showNewDataExportForm: () => void;
  showActionsDialog: (data: TelemetryDataExport | undefined) => void;
  downloadDataExport: (data: TelemetryDataExport | undefined) => void;
  shareDataExport: (data: TelemetryDataExport | undefined) => void;
}

export const TelemetryDataExportTableContainer = ({
  showNewDataExportForm,
  showActionsDialog,
  downloadDataExport,
  shareDataExport,
}: TelemetryDataExportTableContainerProps) => {
  const { isMobileSize } = useResponsiveSizes();
  return (
    <TableContainer>
      {isMobileSize ? (
        <SuspenseTelemetryDataExportTableContainer
          downloadDataExport={downloadDataExport}
          shareDataExport={shareDataExport}
          showActionsDialog={showActionsDialog}
        />
      ) : (
        <StyledPaper>
          <TelemetryDataExportPanelHeader
            showNewDataExportForm={showNewDataExportForm}
          />
          <SuspenseTelemetryDataExportTableContainer
            downloadDataExport={downloadDataExport}
            shareDataExport={shareDataExport}
            showActionsDialog={showActionsDialog}
          />
        </StyledPaper>
      )}
    </TableContainer>
  );
};
