import { ReactNode } from 'react';
import { styled, Stack } from '@mui/material';
import { Property } from 'csstype';
import { transientOptions } from '../../utils/transientOptions';
import { useResponsiveSizes } from '../../utils/responsive-design/useResponsiveSizes';

const StyledStack = styled(
  Stack,
  transientOptions
)<{ $width?: Property.Width }>(({ theme, $width }) => ({
  zIndex: theme.zIndex.appBar,
  boxShadow: theme.shadows[2],
  width: $width,
  minWidth: '15rem',
  flex: '0 0 auto',
}));

interface AppBreadcrumbsContainerProps {
  children: ReactNode;
}

export function AppBreadcrumbsContainer({
  children,
}: AppBreadcrumbsContainerProps) {
  const { isMobileSize, isDesktopSize, isTabletSize } = useResponsiveSizes();
  const width =
    isMobileSize || isTabletSize ? '100%' : isDesktopSize ? '25rem' : 'unset';
  return <StyledStack $width={width}>{children}</StyledStack>;
}
