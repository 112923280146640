import { createStore, select, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { GQLCompanySite, GQLSystem } from '../generated/gql';

export interface CopyThresholdsStoreProps {
  activeSite: GQLCompanySite | null;
  selectedSystem: GQLSystem | null;
}

export const copyThresholdsStore = createStore(
  { name: 'copy-thresholds' },
  withProps<CopyThresholdsStoreProps>({
    activeSite: null,
    selectedSystem: null,
  })
);

persistState(copyThresholdsStore, {
  key: 'copy-thresholds',
  storage: sessionStorageStrategy,
});

export const copyThresholdsActiveSite$ = copyThresholdsStore.pipe(
  select((state) => {
    return state.activeSite;
  })
);

export const copyThresholdsSelectedSystem$ = copyThresholdsStore.pipe(
  select((state) => {
    return state.selectedSystem;
  })
);
