import  {  AxiosResponse} from 'axios';
import {
    QueryFunction,
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useQuery,
} from 'react-query';
import {
    GQLGetSystemQuery,
  GQLListSystemsQuery,
  GQLListSystemsQueryVariables,
  GQLSystem,
  ListSystemsDocument,
} from '../../generated/gql';
import { axiosHelper } from '../../generated/axiosHelper';
import { el } from 'date-fns/locale';
import { systemApi } from './systemApi';
import { System } from '../../generated';
import { AppQueryClient, LONG_POLLING_INTERVAL } from '../../utils';
import { systemStore } from '../../state';
import { PrefetchListSystemsDocument } from '../../generated/PrefetchListSystemsDocument';

enum API_FETCH_STATE {
  EMPTY = 'EMPTY',
  LOADING_API = 'LOADING_API',
  LOADED_API = 'LOADED_API',
  LOADED_GQL = 'LOADED_GQL',
}

export const useCachedListSystemsQuery = <
  TData = GQLListSystemsQuery ,
  TError = unknown
>(
  variables?: GQLListSystemsQueryVariables,
  options?: UseQueryOptions<GQLListSystemsQuery , TError, TData>
) => {
  const gqlQuery = axiosHelper<
    GQLListSystemsQuery,
    GQLListSystemsQueryVariables
  >(ListSystemsDocument).bind(null, variables);
  const gqlPrefetchQuery = axiosHelper<
  GQLListSystemsQuery,
  GQLListSystemsQueryVariables
>(PrefetchListSystemsDocument).bind(null, variables);;

  const outerQuery:QueryFunction<GQLListSystemsQuery > = makeOuterQuery(gqlQuery, gqlPrefetchQuery, variables);
  return useQuery<GQLListSystemsQuery , TError, TData>(
    variables === undefined ? ['listSystems'] : ['listSystems', variables],
    outerQuery,
    {
      ...options,
      meta: {
        ...(options?.meta ?? {}),
        apiFetchState: API_FETCH_STATE.EMPTY,
      },
      structuralSharing: true,
    }
  );
};

export const useCachedInfiniteListSystemsQuery = <
  TData = GQLListSystemsQuery,
  TError = unknown
>(
  variables?: GQLListSystemsQueryVariables,
  options?: UseInfiniteQueryOptions<GQLListSystemsQuery, TError, TData>
) => {
  
    const gqlQuery = axiosHelper<
    GQLListSystemsQuery,
    GQLListSystemsQueryVariables
  >(ListSystemsDocument).bind(null, variables);;
  const fullResult = useInfiniteQuery<GQLListSystemsQuery, TError, TData>(
    variables === undefined
      ? ['listSystems.infinite.fullSite']
      : ['listSystems.infinite.fullSite', variables],
      async () => gqlQuery(),
    {
      ...options,
      meta: {
        ...(options?.meta ?? {}),
        apiFetchState: API_FETCH_STATE.EMPTY,
      },
      suspense: false,
      structuralSharing: true,
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 35,
    }
  );
  return fullResult;
 
};
export function useCachedPrefetchInfiniteListSystemsQuery<TData = GQLListSystemsQuery ,
  TError = unknown>(variables?: GQLListSystemsQueryVariables,
    options?: UseInfiniteQueryOptions<GQLListSystemsQuery , TError, TData>
  ) {
  const gqlPrefetchQuery = axiosHelper<
    GQLListSystemsQuery,
    GQLListSystemsQueryVariables
  >(PrefetchListSystemsDocument).bind(null, variables);;

  const cachedPrefetch = AppQueryClient.getQueryState(['listSystems.infinite.prefetch', { skip: 0, limit: 100, status: "active", companyId: variables?.companyId, companySiteId: variables?.companySiteId }]);
  const prefetchResult = useInfiniteQuery<GQLListSystemsQuery , TError, TData>(
    variables === undefined
      ? ['listSystems.infinite.prefetch']
      : ['listSystems.infinite.prefetch', variables],
    async () => gqlPrefetchQuery(),
    {
      ...options,
      meta: {
        ...(options?.meta ?? {}),
        apiFetchState: API_FETCH_STATE.EMPTY,
      },
      suspense: true,
      structuralSharing: true,
      staleTime: 1000 * 60 * 30,
      cacheTime: 1000 * 60 * 35,
    }
  );
  return prefetchResult;
}

function makeOuterQuery(gqlQuery: () => Promise<GQLListSystemsQuery>, prefetchGqlQuery: () => Promise<GQLListSystemsQuery>, variables: GQLListSystemsQueryVariables | undefined): QueryFunction<GQLListSystemsQuery > {
const opts = {
  staleTime: 1000 * 60 * 1,
  cacheTime: 1000 * 60 * 5,
}
    return async (ctx) => {
        const meta = ctx.meta as { apiFetchState: API_FETCH_STATE; reqPromise?: Promise<unknown> } ?? {apiFetchState:API_FETCH_STATE.EMPTY};
       
        const cachedPartial = AppQueryClient.getQueryData(['listSystems.infinite.prefetch', { skip: 0, limit: 100, status: "active", companyId: variables?.companyId, companySiteId: variables?.companySiteId }]) as GQLListSystemsQuery | undefined;
        const cachedPartialState = AppQueryClient.getQueryState(['listSystems.infinite.prefetch', { skip: 0, limit: 100, status: "active", companyId: variables?.companyId, companySiteId: variables?.companySiteId }]) ;
        const cachedFullSite = AppQueryClient.getQueryData(['listSystems.infinite.siteList', { skip: 0, limit: 100, status: "active", companyId: variables?.companyId, companySiteId: variables?.companySiteId }]) as GQLListSystemsQuery | undefined;
        const cachedFullSiteState = AppQueryClient.getQueryState(['listSystems.infinite.siteList', { skip: 0, limit: 100, status: "active", companyId: variables?.companyId, companySiteId: variables?.companySiteId }]) ;
        
        const now = new Date().getTime();
        if(cachedPartial) {
          if(cachedFullSite) {
            if(now - (cachedFullSiteState?.dataUpdatedAt ?? 0) > LONG_POLLING_INTERVAL) {
              const systemsPromise =  AppQueryClient.fetchQuery(['listSystems.infinite.siteList', { skip: 0, limit: 100, status: "active", companyId: variables?.companyId, companySiteId: variables?.companySiteId }], async () => gqlQuery(), opts);
              meta.reqPromise = systemsPromise;
              const systems = await systemsPromise;   
              return systems;
            } else {
              return cachedFullSite;
            }
          } else {
            
            const systemsPromise =  AppQueryClient.fetchQuery(['listSystems.infinite.siteList', { skip: 0, limit: 100, status: "active", companyId: variables?.companyId, companySiteId: variables?.companySiteId }], async () => gqlQuery(),opts);
              meta.reqPromise = systemsPromise;
              const systems = await systemsPromise;   
              return systems;
           
          }
        } else {
          const systemsPromise =  AppQueryClient.fetchQuery(['listSystems.infinite.prefetch', { skip: 0, limit: 100, status: "active", companyId: variables?.companyId, companySiteId: variables?.companySiteId }], async () => prefetchGqlQuery(),{staleTime: 1000 * 60 * 5, cacheTime: 1000 * 60 * 5});
          meta.reqPromise = systemsPromise;
          const systems = await systemsPromise;  
          return systems;
        }


     
    };

}

