import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, Card, Stack, styled } from '@mui/material';
import {
  GQLBlock,
  SettingsSystems,
  getSequenceProgress,
} from '@beeriot/api-client';
import {
  RunningState,
  RunningStateColorKey,
  RunningStateIcon,
  useIntlRunningState,
} from '../../enums';
import {
  formatTimeFromSeconds,
  getEnumFromString,
  getFlowGaugeInfo,
  useResponsiveSizes,
} from '../../utils';
import {
  AppTypography,
  FlowGauge,
  GaugeSection,
  getResponsiveDataForGauge,
  IconLabel,
  VerticalLabels,
} from '../../base';

const StyledCardHeader = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.componentBase.main,
  padding: '0.25rem 0.5rem',
}));

const StyledStack = styled(Stack)({
  flexDirection: 'row',
  padding: '0.5rem 0.5rem',
  justifyContent: 'space-between',
});

const RadialContainer = styled(Stack)({
  textAlign: 'center',
  padding: '0rem 0.5rem',
});

export interface BlockOverviewCardProps {
  block: GQLBlock;
  systemSettings?: SettingsSystems;
}

export function BlockOverviewCard({
  block,
  systemSettings,
}: BlockOverviewCardProps) {
  const { isMobileSize, isTabletSize } = useResponsiveSizes();
  const intl = useIntl();
  const NA = intl.formatMessage({
    id: 'common-not-applicable',
    defaultMessage: 'N/A',
    description: "Label for when there isn't a value to display",
  });
  const cardHeader = intl.formatMessage({
    id: 'block-details-overview-header',
    description: 'Label for block status overview card',
    defaultMessage: 'Block Status Overview',
  });

  const intlRunningState = useIntlRunningState();
  const runningState =
    getEnumFromString(RunningState, block.liveStatus?.sequenceStatus) ??
    RunningState.Off;
  const runningStateIcon = RunningStateIcon[runningState];
  const runningStateIconColor = RunningStateColorKey[runningState];
  const runningStateLabel = intlRunningState(runningState);
  const progress = getSequenceProgress(block.liveStatus);

  const responsiveGaugeInfo = getResponsiveDataForGauge(
    isMobileSize,
    isTabletSize
  );

  const flowGaugeInfo = getFlowGaugeInfo(systemSettings);

  return (
    <Card>
      <StyledCardHeader direction="row">
        <AppTypography variant="caption" $bold>
          {cardHeader.toUpperCase()}
        </AppTypography>
      </StyledCardHeader>
      <StyledStack>
        <Grid container rowSpacing={1} columnSpacing={3}>
          <Grid item xs={6}>
            <VerticalLabels
              labelBold
              labelVariant="subtitle1"
              label={block.description ?? NA}
              subLabelBold
              subLabel={
                <IconLabel
                  label={runningStateLabel}
                  colorKey={runningStateIconColor}
                  variant={'caption'}
                  icon={runningStateIcon}
                  iconSize={'1x'}
                  iconColorKey={runningStateIconColor}
                />
              }
            />
          </Grid>
          <Grid item xs={6}>
            <VerticalLabels
              label={
                block?.liveStatus?.timeToMaxdPSeconds != null
                  ? formatTimeFromSeconds(block.liveStatus.timeToMaxdPSeconds)
                  : NA
              }
              labelBold
              labelColorKey="warning"
              subLabel={
                <FormattedMessage
                  id="site-block-detail-time-to-max"
                  defaultMessage="Time to Max dP"
                  description="sub label for time to max"
                />
              }
            />
          </Grid>
        </Grid>
        <RadialContainer>
          <FlowGauge
            flow={{
              amount: block?.liveKPI?.flowHlH
                ? Math.trunc(block.liveKPI.flowHlH)
                : undefined,
              unit: 'hL/h',
              max: flowGaugeInfo.maxFlow,
            }}
            radius={responsiveGaugeInfo.radius}
            gaugeMap={flowGaugeInfo.gaugeMap}
            warningLimit={flowGaugeInfo.warningLimit}
            successLimit={flowGaugeInfo.successLimit}
            labelVariant={responsiveGaugeInfo.labelVariant}
            subLabelVariant={
              isMobileSize || isTabletSize ? 'caption' : 'subtitle2'
            }
            backgroundColor="white"
            gaugeOverrideColorKey="primary"
            gaugeBackgroundOverrideColorKey="disabledPrimary"
          />
        </RadialContainer>
      </StyledStack>
    </Card>
  );
}
