import { useIntl } from 'react-intl';
import { Dialog, DialogContent, styled } from '@mui/material';
import { DialogHeader } from '../../../dialogs';
import { SuspenseAnalyticsFiltersForm } from './SuspenseAnalyticsFiltersForm';

const StyledDialogContent = styled(DialogContent)({
  overflow: 'visible',
});

interface MobileAnalyticsFilterFormDialogProps {
  open: boolean;
  onClose: () => void;
}

export function MobileAnalyticsFilterFormDialog({
  open,
  onClose,
}: MobileAnalyticsFilterFormDialogProps) {
  const intl = useIntl();
  const title = intl.formatMessage({
    id: 'adjust-filters-title',
    description: 'Title label for adjusting filters',
    defaultMessage: 'Adjust Filters',
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader
        label={title}
        headerVariant="h6"
        colorKey={'highlight2'}
        onClose={onClose}
      />
      <StyledDialogContent>
        <SuspenseAnalyticsFiltersForm />
      </StyledDialogContent>
    </Dialog>
  );
}
