import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Stack, Paper, styled } from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import { activeCompany$ } from '@beeriot/api-client';
import { AppTableHeader, AppTableHeaderControlsContainer } from '../../base';
import { SettingsHeaderButton } from '../settings-form';
import { useResponsiveSizes } from '../../utils';
import { SuspenseBeerTypesSettingsList } from './SuspenseBeerTypesSettingsList';

const TableContainer = styled(Stack)({
  minWidth: '50rem',
  maxWidth: '80rem',
});

const StyledAppTableHeaderControlsContainer = styled(
  AppTableHeaderControlsContainer
)({
  justifyContent: 'end',
});

export const BeerTypesSettingsPanel = () => {
  const { isMobileSize } = useResponsiveSizes();
  const navigate = useNavigate();
  const [activeCompany] = useObservable(activeCompany$);

  const showAddForm = useCallback(() => {
    navigate(
      `/companies/${activeCompany?.id}/settings/beer-brands-details/new-beer-brand`
    );
  }, [navigate, activeCompany]);

  return isMobileSize ? (
    <SuspenseBeerTypesSettingsList />
  ) : (
    <TableContainer>
      <Paper>
        <AppTableHeader
          label={
            <FormattedMessage
              id="settings-beer-brands-header"
              defaultMessage="Beer Brands"
              description="header for Beer Brands table"
            />
          }
        />
        <SuspenseBeerTypesSettingsList />
      </Paper>
    </TableContainer>
  );
};
