import { Stack, styled } from '@mui/material';
import { GlobalFooter } from '../../../base';
import { SuspenseEditSiteCardList } from '../../../sites';
import { useResponsiveSizes } from '../../../utils';
import { LineLegendFooter } from '../../LineLegendFooter';
import { RootPage } from '../../RootPage';
import { SitesControlBox } from '../sites-list/SitesControlBox';

const SitesStack = styled(Stack)({
  overflow: 'auto',
  flex: '1 1 auto',
});

export function EditSitesPage() {
  const { isMobileSize } = useResponsiveSizes();
  return (
    <RootPage>
      <SitesControlBox />
      <SitesStack>
        <SuspenseEditSiteCardList />
        <GlobalFooter
          topFooterComponent={isMobileSize ? null : <LineLegendFooter />}
        />
      </SitesStack>
    </RootPage>
  );
}
