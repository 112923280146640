import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useObservable } from '@ngneat/use-observable';
import { execSummaryStore, filtersAreActive$ } from '@beeriot/api-client';
import { AppIcon } from '../../../../base';

export function EndComparisonButton() {
  const [filtersAreActive] = useObservable(filtersAreActive$);
  const endComparison = () => {
    execSummaryStore.update((state) => ({
      ...state,
      filterLines: [],
      filterBrands: [],
    }));
  };

  return filtersAreActive ? (
    <Button
      color="error"
      variant="contained"
      onClick={endComparison}
      startIcon={<AppIcon icon="xmark-large" $colorKey="white" />}
    >
      <FormattedMessage
        id="exec-summary-end-comparison-button"
        defaultMessage="End Comparison"
        description="Button to end comparison"
      />
    </Button>
  ) : null;
}
