import { ReactNode } from 'react';
import { Stack, styled } from '@mui/material';
import { Property } from 'csstype';
import { GlobalFooter, ScrollStack } from '../../base';
import { RootPage } from '../RootPage';

const ContentStack = styled(Stack)({
  height: '100%',
  overflow: 'auto',
});

const SettingsStack = styled(Stack)({
  padding: '2rem',
});

interface SettingsPageProps {
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  $contentWidth?: Property.Width;
  $contentMaxWidth?: Property.MaxWidth;
  $overflow?: Property.Overflow;
}

export function SettingsPage({ children, header, footer }: SettingsPageProps) {
  return (
    <RootPage>
      {header}
      <ContentStack>
        <ScrollStack>
          <SettingsStack>{children}</SettingsStack>
          <GlobalFooter topFooterComponent={footer}/>
        </ScrollStack>
      </ContentStack>
    </RootPage>
  );
}
