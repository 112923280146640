import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { ColorKey } from '@beeriot/api-client';
import { VerticalLabels } from './VerticalLabels';

interface LowGoodLabelProps {
  low?: boolean | null;
  labelColor?: ColorKey;
  subLabel: ReactNode;
}

export function LowGoodLabel({ low, labelColor, subLabel }: LowGoodLabelProps) {
  const intl = useIntl();
  const NA = intl.formatMessage({
    id: 'common-not-applicable',
    defaultMessage: 'N/A',
    description: "Label for when there isn't a value to display",
  });
  const Low = intl.formatMessage({
    id: 'common-low',
    defaultMessage: 'Low',
    description: 'Label to indicate metric is low',
  });
  const Good = intl.formatMessage({
    id: 'common-good',
    defaultMessage: 'Good',
    description: 'Label to indicate metric is good',
  });

  const label = low == null ? NA : low ? Low : Good;
  const colorKey = low == null ? undefined : low ? 'error' : 'success';

  return (
    <VerticalLabels
      label={label}
      labelBold
      labelColorKey={labelColor ?? colorKey}
      subLabel={subLabel}
    />
  );
}
