import { Stack, styled } from '@mui/material';
import { LineSubTabs } from '../../../app-routing/LineSubTabs';
import { ScrollStack } from '../../../base';

const ControlContainer = styled(Stack)({
  width: '100%',
});

export function LineControlBox() {
  return (
    <ControlContainer direction="row">
      <ScrollStack>
        <LineSubTabs />
      </ScrollStack>
    </ControlContainer>
  );
}
