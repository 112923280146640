import {
  styled,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  '& th:not(:last-child)': {
    borderRight: `1px solid ${theme.palette.grey[600]}`,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.grey[600],
  fontWeight: 'bold',
  padding: '0.5rem 1rem',
  '& div': {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function descendingComparator<T>(a: T, b: T, orderBy?: keyof T) {
  if (orderBy == null || a == null || b == null) return 0;

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// TODO: fix issue with interfaces having optional properties
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getDefaultComparator<Key extends keyof any>(
  sortOrder: SortOrder,
  orderBy?: Key
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): (a: { [key in Key]?: any }, b: { [key in Key]?: any }) => number {
  return sortOrder === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export type SortOrder = 'asc' | 'desc';

export interface AppTableHeaderRowProps<T> {
  columns: GridColDef[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  orderBy?: string;
  sortOrder?: SortOrder;
}

export function AppTableHeaderRow<T>({
  columns,
  onRequestSort,
  orderBy,
  sortOrder,
}: AppTableHeaderRowProps<T>) {
  function createSortHandler(property: string) {
    return (event: React.MouseEvent<HTMLSpanElement>) => {
      onRequestSort(event, property);
    };
  }

  const mapColumns = (
    columns: GridColDef[],
    orderBy: string | undefined,
    sortOrder: SortOrder | undefined
  ) => {
    if (columns === undefined || columns.length <= 0) return [];
    return columns.map((column, index) => {
      const colspan = index === columns.length - 1 ? 2 : 1;
      const isActive = orderBy === column.field;
      const direction = isActive ? sortOrder : 'asc';
      return (
        <StyledTableCell
          key={`user-management-column-${index}`}
          align={column.align ?? column.headerAlign}
          colSpan={colspan}
          sortDirection={isActive ? sortOrder : false}
        >
          <div>
            {column?.renderHeader
              ? column?.renderHeader({
                  field: column.field,
                  colDef: { ...column, computedWidth: 0 },
                })
              : column.headerName}
            {column.sortable && (
              <TableSortLabel
                active={isActive}
                direction={direction}
                hideSortIcon={isActive}
                onClick={createSortHandler(column.field)}
              />
            )}
          </div>
        </StyledTableCell>
      );
    });
  };

  return (
    <TableHead>
      <StyledTableRow>
        {columns && mapColumns(columns, orderBy, sortOrder)}
      </StyledTableRow>
    </TableHead>
  );
}
