import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { GroupBase, StylesConfig } from 'react-select';
import { styled } from '@mui/material';
import { LoadingPaper } from '../../base/LoadingPaper';
import { IconLabel } from '../../base/IconLabel';

const SystemDropdownLoadingPaper = styled(LoadingPaper)({
  height: '2rem',
  width: '15rem',
  margin: '0.5rem',
});

const SystemsDropdownList = lazy(() =>
  import('./SystemsDropdownListQuery').then(({ SystemsDropdownListQuery }) => ({
    default: SystemsDropdownListQuery,
  }))
);

interface SuspenseSystemDropdownProps {
  selectStyles?: StylesConfig<unknown, boolean, GroupBase<unknown>> | undefined;
  isMulti?: boolean;
  matchActive?: boolean;
  showAllOption?: boolean;
  defaultValue?: string;
  required?: boolean;
}

export function SuspenseSystemDropdown({
  selectStyles,
  isMulti,
  matchActive,
  showAllOption,
  defaultValue,
}: SuspenseSystemDropdownProps) {
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <IconLabel
                label={
                  <FormattedMessage
                    id="systems-dropdown-error-label"
                    description="Error message for systems dropdown error"
                    defaultMessage="Machines Unavailable"
                  />
                }
              />
            );
          }}
        >
          <Suspense fallback={<SystemDropdownLoadingPaper />}>
            <SystemsDropdownList
              matchActive={matchActive}
              selectStyles={selectStyles}
              isMulti={isMulti}
              showAllOption={showAllOption}
              defaultValue={defaultValue}
            />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
