import { styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { AppTypography } from '../../base/AppTypography';
import { RouterLink } from '../../navigation';
import { PallLogo } from '../PallLogo';
import { AuthSubmitButton } from '../AuthSubmitButton';

const InstructionsLabel = styled(AppTypography)({
  marginTop: '0.5rem',
  marginBottom: '1rem',
});

export function ResetPasswordSuccess() {
  return (
    <div>
      <PallLogo />
      <AppTypography variant="body1" $bold $colorKey="white">
        <FormattedMessage
          id="forgot-password-reset-success-label"
          defaultMessage="Password Reset"
          description="Label for reset success title"
        />
      </AppTypography>
      <InstructionsLabel variant="subtitle2" $colorKey="white">
        <FormattedMessage
          id="forgot-password-reset-success-instructions-label"
          defaultMessage="Your password has been saved. You can now use it to login."
          description="Label for forgot password reset success instructions"
        />
      </InstructionsLabel>
      <RouterLink to="/auth">
        <AuthSubmitButton>
          <FormattedMessage
            id="forgot-password-return-to-login-button"
            defaultMessage="Return to login"
            description="Button to return to login page"
          />
        </AuthSubmitButton>
      </RouterLink>
    </div>
  );
}
