import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { Stack, Button, Typography, styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { LoadingButton } from '../../../../../base/LoadingButton';

const MessageContainer = styled(Stack)({
  textAlign: 'center',
  width: '30rem',
  margin: '3rem',
  justifyContent: 'center',
});

const ErrorContainer = styled(Stack)({
  width: '100%',
  alignItems: 'center',
});

export const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  alignSelf: 'flex-end',
}));

function ButtonLoadingBox() {
  return (
    <Stack>
      <StyledLoadingButton
        type="button"
        variant="contained"
        isLoading={true}
        loadingLabel={
          <FormattedMessage
            id="common-loading-label"
            description="Loading message"
            defaultMessage="Loading..."
          />
        }
        color="highlight5"
      >
        <FormattedMessage
          id="exec-summary-compare-brands-button"
          description="Label for compare brands button"
          defaultMessage="Compare Brands"
        />
      </StyledLoadingButton>
    </Stack>
  );
}

const ExecSummaryBrandCompareButton = lazy(() =>
  import('./ExecSummaryBrandCompareButtonQuery').then(
    ({ ExecSummaryBrandCompareButtonQuery }) => ({
      default: ExecSummaryBrandCompareButtonQuery,
    })
  )
);

export function SuspenseExecSummaryBrandCompareButton() {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ resetErrorBoundary }) => {
            return (
              <ErrorContainer>
                <MessageContainer>
                  <Typography>
                    <FormattedMessage
                      id="site-details-error-loading-label"
                      description="Label for site details loading error"
                      defaultMessage="There was an error loading site details"
                    />
                  </Typography>
                  <Button onClick={() => resetErrorBoundary()}>
                    <FormattedMessage
                      id="common-try-again-button"
                      description="Button for trying an action again"
                      defaultMessage="Try Again"
                    />
                  </Button>
                </MessageContainer>
              </ErrorContainer>
            );
          }}
          onReset={reset}
        >
          <Suspense fallback={<ButtonLoadingBox />}>
            <ExecSummaryBrandCompareButton />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
