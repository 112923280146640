import { Property } from 'csstype';
import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import {
  RunningState,
  RunningStateColorKey,
  useIntlRunningState,
} from '../enums';
import { LabeledBadge } from './LabeledBadge';
import { BadgeListContainer } from './BadgeListContainer';
import { transientOptions } from '../utils/transientOptions';
import { useResponsiveSizes } from '../utils/responsive-design/useResponsiveSizes';

const StyledStack = styled(Stack)({
  paddingTop: '1rem',
  alignItems: 'start',
  flexDirection: 'column',
  justifyContent: 'start',
});

const StyledLegendLabel = styled(Typography)({
  margin: '0px 0.5rem 0.5rem 0px',
  fontWeight: 'bold',
});

const StyledRowStack = styled(
  Stack,
  transientOptions
)<{ $isMobile: boolean }>(({ theme, $isMobile }) => ({
  padding: '0.5rem',
  backgroundColor: theme.palette.componentBase.main,
  alignItems: $isMobile ? 'start' : 'center',
  flexDirection: $isMobile ? 'column' : 'row',
  justifyContent: $isMobile ? 'center' : 'start',
  position: $isMobile ? 'sticky' : 'unset',
  bottom: $isMobile ? '0' : 'unset',
}));

const StyledRowLegendLabel = styled(
  Typography,
  transientOptions
)<{ $isMobile: boolean }>(({ $isMobile }) => ({
  margin: $isMobile ? '0 0 0.5rem 0' : '0.5rem 0.5rem 0.5rem 0',
  fontWeight: 'bold',
}));

export const LegendContainer = styled(Stack)(({ theme }) => ({
  marginTop: 'auto',
  backgroundColor: theme.palette.componentBase.main,
  borderBottom: `1px solid ${theme.palette.componentShade.main}`,
}));

interface RunningStatusLegendProps {
  systemType?: string | null;
}

export function RunningStatusLegend({ systemType }: RunningStatusLegendProps) {
  return (
    <StyledStack>
      <StyledLegendLabel>
        <FormattedMessage
          id="running-status-legend-label"
          defaultMessage="Running Status Legend"
          description="Label for running status legend"
        />
      </StyledLegendLabel>
      <RunningStatusLegendBadges systemType={systemType} />
    </StyledStack>
  );
}

export function RunningStatusLegendRow({
  systemType,
}: RunningStatusLegendProps) {
  const { isMobileSize } = useResponsiveSizes();
  return (
    <StyledRowStack $isMobile={isMobileSize}>
      <StyledRowLegendLabel $isMobile={isMobileSize}>
        <FormattedMessage
          id="running-status-legend-label"
          defaultMessage="Running Status Legend"
          description="Label for running status legend"
        />
      </StyledRowLegendLabel>
      <RunningStatusLegendBadges systemType={systemType} />
    </StyledRowStack>
  );
}

function RunningStatusLegendBadges({ systemType }: RunningStatusLegendProps) {
  switch (systemType) {
    case 'profi':
      return <ProfiRunningStatusLegendBadges />;
    case 'cbs':
      return <CBSRunningStatusLegendBadges />;
    case 'cfs':
      return <CFSRunningStatusLegendBadges />;
    default:
      return <ProfiRunningStatusLegendBadges />;
  }
}

function ProfiRunningStatusLegendBadges() {
  return (
    <BadgeListContainer>
      <LegendItem runningState={RunningState.Running} />
      <LegendItem runningState={RunningState.Cleaning} />
      <LegendItem runningState={RunningState.Idle} />
      <LegendItem runningState={RunningState.Off} />
    </BadgeListContainer>
  );
}

function CBSRunningStatusLegendBadges() {
  return (
    <BadgeListContainer $gap="0.8rem">
      <LegendItem $gap="0.25rem" runningState={RunningState.Running} />
      <LegendItem $gap="0.25rem" runningState={RunningState.Regeneration} />
      <LegendItem $gap="0.25rem" runningState={RunningState.Idle} />
      <LegendItem $gap="0.25rem" runningState={RunningState.Off} />
    </BadgeListContainer>
  );
}

function CFSRunningStatusLegendBadges() {
  return (
    <BadgeListContainer>
      <LegendItem runningState={RunningState.Sanitation} />
      <LegendItem runningState={RunningState.Caustic} />
      <LegendItem runningState={RunningState.Enzymatic} />
    </BadgeListContainer>
  );
}

interface LegendItemProps {
  runningState: RunningState;
  $gap?: Property.Gap;
}

function LegendItem({ runningState, $gap }: LegendItemProps) {
  const intlRunningState = useIntlRunningState();
  return (
    <LabeledBadge
      $gap={$gap}
      label={intlRunningState(runningState)}
      labelVariant="subtitle2"
      badgeColorKey={RunningStateColorKey[runningState]}
    />
  );
}
