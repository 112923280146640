import {
  ReactElement,
  ReactNode,
  useCallback,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';
import { Collapse, Stack, styled } from '@mui/material';
import { AppIcon } from '../../base/AppIcon';
import { MobileNavListItem } from '../../navigation/main-tabs';
import { SuspenseSectionedSiteList } from './MainTabsRoutes.pages';
import { RouteTabProps } from '../../navigation/tabs/RouteTab';
import { defaultTabButtonRenderer } from '../../navigation/tabs/hooks/defaultTabButtonRenderer';
import { RouteTabObject } from '../../navigation/tabs/models/RouteTabObject';
import { AppTypography } from '../../base/AppTypography';
import { transientOptions } from '../../utils';
export const SitesListItemContainer = styled(Stack, {
  shouldForwardProp: (propName: string) => {
    return (
      ['textColor', 'selectionFollowsFocus', 'indicator', 'fullWidth'].indexOf(
        propName
      ) === -1 &&
      (transientOptions?.shouldForwardProp?.(propName) ?? true)
    );
  },
})(({ theme }) => ({
  margin: '0',
  paddingRight: '1rem',
  width: '100%',
}));

export const SitesListItemAppTypography = styled(AppTypography)(
  ({ theme }) => ({
    '&&': {
      padding: '1.5rem 0.5rem 1.5rem 1.5rem',
      width: '100%',
      alignItems: 'start',
      fontWeight: 'normal',
      fontSize: theme.typography.h3.fontSize,
    },
  })
);

export function useMainTabRoutesTabButtonRenderer({
  setOpenCollapse,
  openCollapse,
  setIsMobileMenuActive,
  isMobileSize,
}: {
  setOpenCollapse: Dispatch<SetStateAction<boolean>>;
  openCollapse: boolean;
  setIsMobileMenuActive: Dispatch<SetStateAction<boolean>>;
  isMobileSize: boolean;
}) {
  const _refs = useRef({
    openCollapse,
    isMobileSize,
    setOpenCollapse,
    setIsMobileMenuActive,
    handleOpenSettings: () => _refs.current.setOpenCollapse(!openCollapse),
    didSelectSite: () => _refs.current.setIsMobileMenuActive(false),
    renderer: (
      route: Partial<RouteTabObject>,
      index: number,
      array: Partial<RouteTabObject>[],
      _children: ReactNode[]
    ) => {
      // return <div>{route.path}</div>
      const tabRouteObject = array[index];
      if (_refs.current.isMobileSize && tabRouteObject.path?.includes('sites/')) {
        return [
          defaultTabButtonRenderer(
            { ...route, hidden: true },
            index,
            array,
            _children as ReactElement<RouteTabProps>[]
          ),
          <SitesListItemContainer key={`site-list-accordian--${index}`}>
            <MobileNavListItem disableGutters onClick={_refs.current.handleOpenSettings}>
              <SitesListItemAppTypography>
                {tabRouteObject.label}
              </SitesListItemAppTypography>
              {_refs.current.openCollapse ? (
                <AppIcon size="lg" icon="chevron-up" />
              ) : (
                <AppIcon size="lg" icon="chevron-down" />
              )}
            </MobileNavListItem>
            <Collapse in={_refs.current.openCollapse} timeout="auto" unmountOnExit>
              <SuspenseSectionedSiteList didSelectSite={_refs.current.didSelectSite} />
            </Collapse>
          </SitesListItemContainer>,
        ];
      }

      return defaultTabButtonRenderer(
        route,
        index,
        array,
        _children as ReactElement<RouteTabProps>[]
      );
    }
  });
  _refs.current.setOpenCollapse =setOpenCollapse;
  _refs.current.openCollapse =openCollapse;
  _refs.current.isMobileSize =isMobileSize;
  _refs.current.setIsMobileMenuActive =setIsMobileMenuActive;
  
  // const handleOpenSettings = useCallback(() => {
  //   _refs.current.setOpenCollapse(!openCollapse);
  // }, [openCollapse]);

  // const didSelectSite = useCallback(() => {
  //   // close menu after selecting a site
  //   _refs.current.setIsMobileMenuActive(false);
  // }, []);

  
  
  // const tabButtonRenderer = useCallback(
  //   (
  //     route: Partial<RouteTabObject>,
  //     index: number,
  //     array: Partial<RouteTabObject>[],
  //     _children: ReactNode[]
  //   ) => {
  //     const tabRouteObject = array[index];
  //     if (isMobileSize && tabRouteObject.path?.includes('sites/')) {
  //       return [
  //         defaultTabButtonRenderer(
  //           { ...route, hidden: true },
  //           index,
  //           array,
  //           _children as ReactElement<RouteTabProps>[]
  //         ),
  //         <SitesListItemContainer key={`site-list-accordian--${index}`}>
  //           <MobileNavListItem disableGutters onClick={_refs.current.handleOpenSettings}>
  //             <SitesListItemAppTypography>
  //               {tabRouteObject.label}
  //             </SitesListItemAppTypography>
  //             {openCollapse ? (
  //               <AppIcon size="lg" icon="chevron-up" />
  //             ) : (
  //               <AppIcon size="lg" icon="chevron-down" />
  //             )}
  //           </MobileNavListItem>
  //           <Collapse in={openCollapse} timeout="auto" unmountOnExit>
  //             <SuspenseSectionedSiteList didSelectSite={_refs.current.didSelectSite} />
  //           </Collapse>
  //         </SitesListItemContainer>,
  //       ];
  //     }

  //     return defaultTabButtonRenderer(
  //       route,
  //       index,
  //       array,
  //       _children as ReactElement<RouteTabProps>[]
  //     );
  //   },
  //   [isMobileSize, openCollapse,]
  // );
  // return tabButtonRenderer;
  return _refs.current.renderer;
}
