import { MutableRefObject, ReactNode, useCallback, useState } from 'react';
import { Stack, Button, styled } from '@mui/material';
import { TabList } from '@mui/lab';
import { useIntl, FormattedMessage } from 'react-intl';
import { SuspenseUserRole } from './user-info-box/SuspenseUserRole';
import { useObservable } from '@ngneat/use-observable';
import { userInfo$ } from '@beeriot/api-client';
import { AppTypography, AppMedallion } from '../../base';
import { UserInfoDialogs, DialogState } from './user-info-box/UserInfoDialogs';
import { transientOptions } from '../../utils';


const MobileNavTabs = styled(
  TabList,
  transientOptions
)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'start',
  '& .MuiTabs-flexContainer': {
    height: '100%',
    alignItems: 'start',
  },
  '& .MuiTypography-root': {
    fontSize: '1.25rem',
    padding: '1.5rem',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.white.main,
    height: '0.5rem',
    bottom: null,
    top: 0,
  },
  '& .MuiTabs-scrollButtons': {
    color: theme.palette.white.main,
  },
  '& .MuiTabs-scrollButtons.Mui-disabled': {
    opacity: 0.3,
  },
}));

const MobileNavContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  alignItems: 'space-between',
}));

const MobileNavUserInfoContainer = styled(Stack)(({ theme }) => ({
  padding: '1.5rem',
  borderTop: '1px solid white',
  borderBottom: '1px solid white',
}));

const MobileNavUserInfoTitle = styled(AppTypography)(({ theme }) => ({
  color: theme.palette.white.main,
  textTransform: 'uppercase',
}));

const MobileNavFooterContainer = styled(Stack)(({ theme }) => ({}));
const MobileNavUpperContainer = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
}));

const StyledMedallion = styled(AppMedallion)(({ theme }) => ({
  height: '3rem',
  width: '3rem',
  fontSize: '1.5rem',
  marginRight: '1rem',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
}));

const ContentsStack = styled(Stack)({
  height: '100%',
  alignItems: 'center',
  padding: '0.5rem 0',
});

const MobileNavItemAppTypography = styled(AppTypography)(({ theme }) => ({
  width: '100%',
  alignItems: 'start',
  fontWeight: 600,
  lineHeight: 1.25,
}));

const MobileNavItem = styled(Button)({
  padding: '1.5rem',
  margin: 0,
  width: '100%',
  justifyContent: 'flex-start',
  alignSelf: 'start',
  alignItems: 'start',
  textAlign: 'start',
});

export interface MobileNavMenuProps {
  tabs: ReactNode[];
  activeIdx?: number;
  isMobileMenuActive: boolean;
  setIsMobileMenuActive: (isActive: boolean) => void;

  modalContainerNode?: MutableRefObject<HTMLDivElement | undefined>;
}

export const MobileNavMenu = ({
  tabs,
  activeIdx,
  isMobileMenuActive,
  setIsMobileMenuActive,
  modalContainerNode,
}: MobileNavMenuProps) => {
  const intl = useIntl();
  const tabsAriaLabel = intl.formatMessage({
    id: 'common-tabs-aria-label',
    description: 'Main navigation tabs aria label',
    defaultMessage: 'Main Navigation Tabs',
  });

  const [dialogState, setDialogState] = useState<DialogState>({
    tooltip: false,
    contactSupport: false,
    logout: false,
  });

  const onSelectLogout = useCallback(() => {
    setDialogState({
      tooltip: false,
      contactSupport: false,
      logout: true,
    });
  }, []);

  const onSelectContactSupport = useCallback(() => {
    setDialogState({
      tooltip: false,
      contactSupport: true,
      logout: false,
    });
  }, []);

  return (
    <MobileNavContainer>
      <MobileNavUpperContainer>
        <MobileNavUserInfoBox />

        <MobileNavTabs
          orientation="vertical"
          aria-label={tabsAriaLabel}
          onChange={() => setIsMobileMenuActive(false)}
        >
          {tabs}
        </MobileNavTabs>
      </MobileNavUpperContainer>
      <MobileNavFooterContainer>
        <MobileNavItem onClick={onSelectContactSupport}>
          <MobileNavItemAppTypography variant="h6" $colorKey={'white'}>
            <FormattedMessage
              id="common-contact-support"
              description="Label for contact support"
              defaultMessage="Contact Support"
            />
          </MobileNavItemAppTypography>
        </MobileNavItem>
        <MobileNavItem onClick={onSelectLogout}>
          <MobileNavItemAppTypography variant="h6" $colorKey={'white'}>
            <FormattedMessage
              id="common-logout-button"
              description="Button for logging out"
              defaultMessage="Log Out"
            />
          </MobileNavItemAppTypography>
        </MobileNavItem>
      </MobileNavFooterContainer>
      <UserInfoDialogs
        dialogState={dialogState}
        setDialogState={setDialogState}
      />
    </MobileNavContainer>
  );
};

const MobileNavUserInfoBox = () => {
  const [userInfo] = useObservable(userInfo$);

  return (
    <MobileNavUserInfoContainer>
      <MobileNavUserInfoTitle $bold variant="subtitle2">
        <FormattedMessage
          id="user-info-dropdown-title-label"
          description="Label for user info dropdown title"
          defaultMessage="Logged in as {user}"
          values={{
            user: userInfo?.email,
          }}
        />
      </MobileNavUserInfoTitle>
      <ContentsStack direction="row">
        <StyledMedallion colorKey="highlight8">
          {userInfo?.initials}
        </StyledMedallion>
        <SuspenseUserRole name={userInfo?.name} />
      </ContentsStack>
    </MobileNavUserInfoContainer>
  );
};
