import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { IconLabel, LoadingBox } from '../../base';

const MedallionLoadingBox = styled(LoadingBox)({
  height: '1.5rem',
  width: '1.5rem',
  borderRadius: '50%',
  marginRight: '1rem',
});

const MobileAlertButton = lazy(() =>
  import('./MobileAlertButtonQuery').then(({ MobileAlertButtonQuery }) => ({
    default: MobileAlertButtonQuery,
  }))
);

interface SuspenseMobileAlertButtonProps {
  openAlerts: () => void;
}

export function SuspenseMobileAlertButton({
  openAlerts,
}: SuspenseMobileAlertButtonProps) {
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <IconLabel
                label={
                  <FormattedMessage
                    id="alerts-error-label"
                    description="Error message for alerts"
                    defaultMessage="Alerts Unavailable"
                  />
                }
              />
            );
          }}
        >
          <Suspense fallback={<MedallionLoadingBox />}>
            <MobileAlertButton openAlerts={openAlerts} />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
