import { useMemo, useRef } from 'react';
import { styled, useTheme } from '@mui/material';
import { KPIGridItem } from '../../kpi-grid/KPIGrid';
import { SystemKpiHookProps } from '../../SystemKpiHookProps';
import { useShouldRenderKpi } from '../../useShouldRenderKpi';
import { FlowGauge } from '../../../../../../base/FlowGauge';
import { getResponsiveDataForGauge } from '../../../../../../base/RadialGauge';
import { useDomNodeHeight } from '../../../../../../utils/layout';
import { getFlowGaugeInfo } from '../../../../../../utils/getFlowGaugeInfo';
import { SuspenseSystemFlowGauge } from '../../../../../../base/SuspenseSystemFlowGauge';

const StyledFlowGauge = styled(FlowGauge)({
  alignSelf: 'flex-end',
  maxHeight: '6em',
  maxWidth: '6em',
});

export function useActualFlowGuageGridItem(props: SystemKpiHookProps) {
  const {
    system,
    gridArea,
    NA,
    isMobileSize = false,
    isTabletSize = false,
  } = props;
  const shouldRender = useShouldRenderKpi(props);
  const theme = useTheme();

  const ref = useRef<HTMLDivElement | null>(null);
  const currentHeight = useDomNodeHeight({
    nodeRef: ref,
    defaultValue: 48,
    watchWindowResize: true,
  });
  return useMemo(() => {
    if (!shouldRender) {
      return undefined;
    }

    return (
      <KPIGridItem gridArea={gridArea ?? 'actualFlow'}>
        {system && <SuspenseSystemFlowGauge system={system} isCard />}
      </KPIGridItem>
    );
  }, [shouldRender, gridArea, system]);
}
