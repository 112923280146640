import { Stack, styled } from '@mui/material';
import { AppTypography } from '../base/AppTypography';
import { ReactComponent as PallSVG } from '../../assets/Pall.svg';
import { FormattedMessage } from 'react-intl';

const StyledStack = styled(Stack)({
  alignItems: 'center',
  columnGap: '1rem',
  marginBottom: '3rem',
});

const LogoTitle = styled(AppTypography)({
  letterSpacing: '0.15rem',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
});

export function PallLogo() {
  return (
    <StyledStack direction="row">
      <PallSVG />
      <LogoTitle $colorKey="white" variant="body2" $bold>
        <FormattedMessage
          id="login-pall-logo-label"
          defaultMessage="Pall Corporation"
          description="Label for pall corporation logo"
        />
      </LogoTitle>
    </StyledStack>
  );
}
