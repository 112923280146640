import {
  GQLGetCompanyQuery,
} from '../../generated/gql';
import { AppQueryClient } from '../../utils';
import { setActiveCompany } from './setActiveCompany';


export const setActiveCompanyId = (activeCompanyId?: string | null) => {
  const cachedCompany: GQLGetCompanyQuery | null | undefined =
    AppQueryClient.getQueryData(
      ['getCompany', { companyId: activeCompanyId }],
    );
  if (cachedCompany?.getCompany) {
    setActiveCompany(cachedCompany.getCompany);
  }
};


