import { FormattedMessage } from 'react-intl';
import { Alert, Button, Dialog, Divider, Stack, styled } from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import {
  alertStore,
  selectedAlert$,
  GQLAlertHistory,
  GQLAlertActive,
  useDismissAlert,
} from '@beeriot/api-client';
import {
  AppTypography,
  VerticalLabels,
  PermissionKey,
  PermissionView,
} from '../../base';
import { useFormatDateTime, useResponsiveSizes } from '../../utils';
import { DialogHeader } from '../../dialogs';
import { getAlertColor } from '../AlertCard';
import { AlertDialogNavButton } from './AlertDialogNavButton';
import { useEffect, useState } from 'react';

const ContainerStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  maxWidth: '20rem',
}));

const FooterStack = styled(Stack)({
  margin: '2rem 0.5rem 0.5rem 0.5rem',
  gap: '1rem',
  justifyContent: 'center',
});

const CounterTypography = styled(AppTypography)({
  marginTop: '2rem',
  textAlign: 'center',
});

const DismissButton = styled(Button)({
  transition: 'none',
});

const StyledDivider = styled(Divider)({
  margin: '0.5rem 0',
});

const StyledVericalLabels = styled(VerticalLabels)({
  padding: '0.5rem',
});

const dialogPaperProps = {
  style: { backgroundColor: 'transparent', boxShadow: 'none' },
};

interface AlertDialogProps {
  alerts: GQLAlertHistory[] | GQLAlertActive[];
}

export function AlertDialog({ alerts }: AlertDialogProps) {
  const { isMobileSize } = useResponsiveSizes();
  const [closeAlert, setCloseAlert] = useState<
    GQLAlertHistory | GQLAlertActive | null
  >(null);
  const formatDateTime = useFormatDateTime();
  const [alert] = useObservable(selectedAlert$);
  const open = !closeAlert && alert !== null;
  const cancel = () => {
    setCloseAlert(alert);
  };
  const dismissAlertMutation = useDismissAlert(() => cancel());

  const finishClose = () => {
    dismissAlertMutation.reset();
    alertStore.update((state) => ({ ...state, selectedAlert: null }));
    setCloseAlert(null);
  };

  const displayAlert = alert || closeAlert;
  const colorKey = getAlertColor(displayAlert?.alertType) ?? 'shade';
  const formattedDate = displayAlert?.time
    ? formatDateTime(displayAlert?.time)
    : '-';
  const alertIndex = alerts.findIndex(
    (x) => x?.thumbprint === displayAlert?.thumbprint
  );
  const alertPosition = alertIndex !== -1 ? alertIndex + 1 : '-';
  const totalAlertCount = alerts.length;

  useEffect(() => {
    if (dismissAlertMutation.status !== 'idle') {
      dismissAlertMutation.reset();
    }
  }, [dismissAlertMutation, alertIndex]);

  return (
    <Dialog
      open={open}
      onClose={cancel}
      PaperProps={dialogPaperProps}
      TransitionProps={{
        onExited: finishClose,
      }}
    >
      <Stack direction="row">
        {!isMobileSize && (
          <AlertDialogNavButton
            alerts={alerts}
            direction={'back'}
            alertIndex={alertIndex}
            totalAlertCount={totalAlertCount}
          />
        )}
        <Stack>
          <ContainerStack>
            <DialogHeader
              label={displayAlert?.alertType ?? '-'}
              colorKey={colorKey}
              onClose={cancel}
            />
            <StyledVericalLabels
              labelBold
              label={
                <FormattedMessage
                  id="common-received-label"
                  description="Label for received"
                  defaultMessage="Received"
                />
              }
              subLabel={formattedDate}
              labelColorKey="subtitleText"
              labelVariant="subtitle2"
              subLabelColorKey="darkText"
              subLabelBold
              subLabelVariant="body1"
            />
            <StyledDivider />
            <StyledVericalLabels
              labelBold
              label={
                <FormattedMessage
                  id="common-description-label"
                  description="Label for description"
                  defaultMessage="Description"
                />
              }
              subLabel={displayAlert?.measure_name}
              labelColorKey="subtitleText"
              labelVariant="subtitle2"
              subLabelColorKey="darkText"
              subLabelBold
              subLabelVariant="body1"
            />
            {dismissAlertMutation.isError && (
              <Alert
                variant="filled"
                severity="info"
                sx={{ margin: '1rem 1rem 0 1rem' }}
              >
                <FormattedMessage
                  id="alert-dialog-dismiss-alert-error-label"
                  defaultMessage="An error occurred while dismissing this alert. Please try again later or contact support."
                  description="Label for alert dismissal error"
                />
              </Alert>
            )}
            <FooterStack direction="row">
              <Button variant="contained" color="shade" onClick={cancel}>
                <FormattedMessage
                  id="common-close-button"
                  description="Button for closing an element"
                  defaultMessage="Close"
                />
              </Button>
              <PermissionView permissionKey={PermissionKey.AlertHistoryDismiss}>
                <DismissButton
                  variant="contained"
                  color={colorKey}
                  onClick={() => {
                    if (alert?.thumbprint) {
                      dismissAlertMutation.mutate(alert.thumbprint);
                    }
                  }}
                >
                  <FormattedMessage
                    id="alert-dialog-dismiss-alert-button"
                    description="Button for dismissing an alert"
                    defaultMessage="Dismiss Alert"
                  />
                </DismissButton>
              </PermissionView>
            </FooterStack>
          </ContainerStack>
          {!isMobileSize && (
            <CounterTypography $colorKey="white" variant="h5">
              <FormattedMessage
                id="position-of-count-label"
                description="Label for showing position within a total count"
                defaultMessage="{position} of {totalCount}"
                values={{
                  position: alertPosition,
                  totalCount: totalAlertCount,
                }}
              />
            </CounterTypography>
          )}
        </Stack>
        {!isMobileSize && (
          <AlertDialogNavButton
            alerts={alerts}
            direction={'forward'}
            alertIndex={alertIndex}
            totalAlertCount={totalAlertCount}
          />
        )}
      </Stack>
    </Dialog>
  );
}
