import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled, Stack, Button } from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import { showMissingAnalyticsData$ } from '@beeriot/api-client';
import {
  ControlBox,
  IconLabel,
  PermissionKey,
  PermissionView,
} from '../../../base';
import { useResponsiveSizes } from '../../../utils/responsive-design/useResponsiveSizes';
import { AnalyticsFiltersRow } from './AnalyticsFiltersRow';
import { SuspenseAnalyticsFiltersForm } from './SuspenseAnalyticsFiltersForm';
import { ExportAnalyticsDataDialog } from './ExportAnalyticsDataDialog';

const StyledButton = styled(Button)({
  width: '100%',
  maxWidth: '10rem',
  marginLeft: 'auto',
});

const StyledStack = styled(Stack)({
  width: '100%',
  gap: '1rem',
});

export function AnalyticsControlBox() {
  const [open, setOpen] = useState(false);
  const { isDesktopSize } = useResponsiveSizes();
  const [showMissingAnalyticsData] = useObservable(showMissingAnalyticsData$);
  return (
    <ControlBox direction="row">
      <StyledStack>
        <StyledStack direction="row">
          <SuspenseAnalyticsFiltersForm />
          {isDesktopSize && (
            <PermissionView permissionKey={PermissionKey.AnalyticsExportData}>
              <StyledButton
                variant="contained"
                color="success"
                disabled={showMissingAnalyticsData}
                onClick={() => setOpen(true)}
              >
                <IconLabel
                  iconSize="lg"
                  icon="download"
                  gap="0.5rem"
                  variant="body1"
                  label={
                    <FormattedMessage
                      id="common-export-data-button"
                      description="Button to export data"
                      defaultMessage="Export Data"
                    />
                  }
                />
              </StyledButton>
            </PermissionView>
          )}
        </StyledStack>
        <AnalyticsFiltersRow />
      </StyledStack>
      <ExportAnalyticsDataDialog open={open} onClose={() => setOpen(false)} />
    </ControlBox>
  );
}
