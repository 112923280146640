import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { Stack, Button, Typography, styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { LoadingPaper } from '../../../base';

const GroupList = lazy(() =>
  import('./GroupListQuery').then(({ GroupListQuery }) => ({
    default: GroupListQuery,
  }))
);

const GroupLoadingPaper = styled(LoadingPaper)({
  height: '12rem',
  width: 'calc(100% - 4rem)',
  margin: '2rem',
});

const ErrorContainer = styled(Stack)({
  width: '100%',
  padding: '1rem',
  alignItems: 'center',
});

const MessageContainer = styled(Stack)({
  textAlign: 'center',
  justifyContent: 'center',
});

function GroupLoadingPapers() {
  return (
    <Stack>
      <GroupLoadingPaper />
      <GroupLoadingPaper />
      <GroupLoadingPaper />
    </Stack>
  );
}

export function SuspenseGroupList() {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ resetErrorBoundary }) => {
            return (
              <ErrorContainer>
                <MessageContainer>
                  <Typography>
                    <FormattedMessage
                      id="groups-error-loading-label"
                      description="Label for groups loading error"
                      defaultMessage="There was an error loading groups"
                    />
                  </Typography>
                  <Button onClick={() => resetErrorBoundary()}>
                    <FormattedMessage
                      id="common-try-again-button"
                      description="Button for trying an action again"
                      defaultMessage="Try Again"
                    />
                  </Button>
                </MessageContainer>
              </ErrorContainer>
            );
          }}
          onReset={reset}
        >
          <Suspense fallback={<GroupLoadingPapers />}>
            <GroupList />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
