import { CardContent, Stack, styled } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { GQLColumn } from '@beeriot/api-client';
import { AppTypography, AppCard, CurrentStateLabel } from '../../../base';
import { RunningStatusKpiElement } from './RunningStatusKpiElement';
import { useEntityDataAttributes } from '../../../utils/entityDataAttributes';
import { KPIVerticalLabels } from '../base-card-common/grids/kpi-grid/KPIVerticalLabel';
import {
  formatUnit,
  getEnumFromString,
  recentStatusToTimelineData,
} from '../../../utils';
import { RunningState } from '../../../enums';

const StackContainer = styled(Stack)({
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  gap: '1rem',
});

export interface ColumnDetailCardProps {
  column: GQLColumn;
}

export function ColumnDetailCard({ column }: ColumnDetailCardProps) {
  const intl = useIntl();
  const NA = intl.formatMessage({
    id: 'common-not-applicable',
    defaultMessage: 'N/A',
    description: "Label for when there isn't a value to display",
  });

  const dataAttributes = useEntityDataAttributes({
    column,
  });

  const runningState = getEnumFromString(
    RunningState,
    column.columnRecentRunningStatus?.currentColumnStatus
  );

  return (
    <AppCard className={'column-detail-card'} {...dataAttributes}>
      <CardContent>
        <StackContainer direction={'row'} sx={{ width: '100%' }}>
          <Stack sx={{ width: '100%', justifyContent: 'space-between' }}>
            <AppTypography variant="h6" $bold>
              {column?.name ?? NA}
            </AppTypography>
            <RunningStatusKpiElement
              timelineData={recentStatusToTimelineData(
                column.columnRecentRunningStatus?.recentRunningStatus
              )}
            />
          </Stack>
          <Stack
            sx={{
              gap: '0.5rem',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
            }}
          >
            <CurrentStateLabel
              state={runningState}
              hideSubLabel
              labelBold
              iconSize="lg"
            />
            <Stack sx={{ gap: '1rem' }} direction="row">
              <KPIVerticalLabels
                data-element-id={'cassettes'}
                label={column?.cassettes != null ? column.cassettes : NA}
                labelBold
                justifyContent="right"
                subLabel={
                  <FormattedMessage
                    id="cbs-line-status-cassettes-label"
                    description="label for cassettes"
                    defaultMessage="Cassettes"
                  />
                }
              />
              <KPIVerticalLabels
                data-element-id={'actualDp'}
                label={
                  column?.columnRecentRunningStatus?.actualDp?.dP != null
                    ? formatUnit(
                        column.columnRecentRunningStatus.actualDp.dP,
                        'bar'
                      )
                    : NA
                }
                labelBold
                justifyContent="right"
                subLabel={
                  <FormattedMessage
                    id="common-actual-dp-label"
                    defaultMessage="Actual dp"
                    description="Actual dp label"
                  />
                }
              />
            </Stack>
          </Stack>
        </StackContainer>
      </CardContent>
    </AppCard>
  );
}
