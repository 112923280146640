import { Variant } from '@mui/material/styles/createTypography';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import {
  RunningState,
  RunningStateColorKey,
  RunningStateIcon,
  useIntlRunningState,
} from '../enums';
import { IconLabel } from './IconLabel';

interface RunningStateIconLabelProps {
  runningState?: RunningState;
  labelBold?: boolean;
  variant?: Variant;
  iconSize?: SizeProp;
}

export function RunningStateIconLabel({
  runningState,
  labelBold,
  variant = 'caption',
  iconSize = '1x',
}: RunningStateIconLabelProps) {
  const intlRunningState = useIntlRunningState();
  const runningStateIcon = runningState
    ? RunningStateIcon[runningState]
    : undefined;
  const runningStateIconColor = runningState
    ? RunningStateColorKey[runningState]
    : 'disabled';
  const runningStateLabel = intlRunningState(runningState);

  return (
    <IconLabel
      bold={labelBold}
      label={runningStateLabel}
      colorKey={runningStateIconColor}
      variant={variant}
      icon={runningStateIcon}
      iconSize={iconSize}
      iconColorKey={runningStateIconColor}
    />
  );
}
