import { Stack, styled } from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';
import { RouteTabLink, RouteTabLinkProps } from '../tabs/RouteTabLink';
import { AppTypography } from '../../base';
import { FormattedMessage } from 'react-intl';

export const MobileSidebarTabsButton = styled(RouteTabLink)(({ theme }) => {
  return {
    '&&&&': {
      alignItems: 'flex-start',
      background: 'transparent',
      color: theme.palette.disabled.light,
      marginLeft: `2.0rem`,
      marginRight: `4rem`,
      minHeight: `2rem`,
      textAlign: 'left',
      fontSize: theme.typography.h3.fontSize,
      '&.Mui-selected': {
        color: theme.palette.primary.main,
        background: theme.palette.base,
      },
      '& .MuiTouchRipple-root': {
        borderRadius: '0',
      },
    },
  };
});
MobileSidebarTabsButton.displayName = 'MobileSidebarTabsButton';

export const StyledSidebarTabsButton = styled(RouteTabLink)(
  ({ theme, $isMobile }) => {
    return {
      '&&&&': {
        alignItems: 'flex-start',
        background: theme.palette.componentBase.main,
        color: theme.palette.disabled.main,
        fontSize: $isMobile ? theme.typography.h4.fontSize : '1.25rem',
        minHeight: `2rem`,
        textAlign: 'left',
        ...($isMobile
          ? {
              fontWeight: 'normal',
              '&.Mui-selected': {
                fontWeight: 600,
                color: theme.palette.primary.main,
              },
            }
          : {
              '&.Mui-selected': {
                fontWeight: 600,
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.white.main,
                background: theme.palette.base,
                borderLeft: `solid ${0.5}rem ${theme.palette.primary.main}`,
                borderRadius: `${0.5}rem`,
                marginRight: `3.5rem`,
                marginTop: `0.2rem`,
                marginBottom: `0.2rem`,
                boxShadow: theme.shadows[2],
              },
              '& .MuiTouchRipple-root': {
                borderRadius: '0',
              },
            }),
      },
    };
  }
);
StyledSidebarTabsButton.displayName = 'StyledSidebarTabsButton';

export const SidebarTabsButton = forwardRef(
  (props: RouteTabLinkProps, ref: ForwardedRef<HTMLAnchorElement>) => {
    return (
      <Stack
        direction="row"
        sx={{
          flexWrap: 'wrap',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <StyledSidebarTabsButton ref={ref} {...props} />
        {props.$showViewingIndicator && props.$isMobile && props.selected && (
          <AppTypography $colorKey="lightText" variant="body1">
            <FormattedMessage
              id="common-viewing-label"
              defaultMessage="Viewing"
              description="Label for viewing"
            />
          </AppTypography>
        )}
      </Stack>
    );
  }
);
SidebarTabsButton.displayName = 'SidebarTabsButton';
