import { ReactNode, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Checkbox, FormControlLabel, styled, Box } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { ColorKey } from '@beeriot/api-client';
import { AppIcon } from '../base/AppIcon';
import { AppTypography } from '../base/AppTypography';

const InputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  height: '100%',
  width: '100%',
  '&.Mui-checked': {
    fontWeight: 'bold',
  },
}));

export interface FormLabeledCheckboxProps {
  id: string;
  name: string;
  label: ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  className?: string;
  defaultValue?: boolean;
  preventBoldOnSelect?: boolean;
  disabled?: boolean;
  $boldText?: boolean;
  $labelColorKey?: ColorKey;
  $labelVariant?: Variant;
  $checkedColorKey?: ColorKey;
  $uncheckedColorKey?: ColorKey;
  $colorFilledCheckbox?: boolean;
  $checkboxSize?: SizeProp;
}

export function FormLabeledCheckbox({
  id,
  name,
  label,
  onChange,
  required,
  className,
  defaultValue,
  preventBoldOnSelect = false,
  disabled,
  $boldText = undefined,
  $labelColorKey,
  $labelVariant = 'body1',
  $checkedColorKey,
  $uncheckedColorKey,
  $colorFilledCheckbox,
  $checkboxSize,
}: FormLabeledCheckboxProps) {
  const { control } = useFormContext();
  const isControlBold = useWatch({ name: name, control, defaultValue });
  const rules = useMemo(() => {
    return { required };
  }, [required]);

  const isBold: boolean = useMemo(() => {
    if ($boldText !== undefined) return $boldText;
    else if (!preventBoldOnSelect) {
      return defaultValue === true && isControlBold === undefined
        ? true
        : isControlBold;
    }
  }, [$boldText, preventBoldOnSelect, defaultValue, isControlBold]);

  return (
    <InputContainer>
      <StyledFormControlLabel
        label={
          <AppTypography
            $colorKey={
              disabled
                ? 'lightText'
                : isBold
                ? $checkedColorKey
                : $labelColorKey
            }
            $bold={isBold}
            variant={$labelVariant}
          >
            {label}
          </AppTypography>
        }
        control={
          <Controller
            name={name}
            rules={rules}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => (
              <Checkbox
                {...field}
                id={id}
                className={className}
                checked={!!field.value}
                disabled={disabled}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                  onChange?.(e);
                }}
                icon={
                  <AppIcon
                    size={$checkboxSize ?? 'lg'}
                    icon={
                      $colorFilledCheckbox
                        ? ['fas', 'square']
                        : ['far', 'square']
                    }
                    $colorKey={
                      disabled
                        ? 'lightText'
                        : $colorFilledCheckbox && !$uncheckedColorKey
                        ? 'componentShade'
                        : $uncheckedColorKey
                    }
                  />
                }
                checkedIcon={
                  <AppIcon
                    size={$checkboxSize ?? 'lg'}
                    icon={['fas', 'square-check']}
                    $colorKey={
                      disabled
                        ? 'lightText'
                        : $colorFilledCheckbox && !$checkedColorKey
                        ? 'componentShade'
                        : $checkedColorKey
                    }
                  />
                }
              />
            )}
          />
        }
      />
    </InputContainer>
  );
}
