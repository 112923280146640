import { useCallback, useMemo } from 'react';
import { UseMutationResult } from 'react-query';
import { UseFormProps } from 'react-hook-form';
import { useIntl, FormattedMessage } from 'react-intl';
import { Paper, Stack, styled } from '@mui/material';
import { Form, SelectOption } from '../../form';
import { PermissionKey, PermissionView } from '../../base';
import {
  TitleHeader,
  SectionControl,
  SectionLabel,
  SectionVerticalLabel,
  SectionTextInput,
  SectionFormSelect,
  SettingsFooterButtons,
  SectionVerticalControl,
  SectionTextAreaInput,
} from '../settings-form';
import {
  PallNotificationMethod,
  PallNotificationRoles,
  PallNotificationType,
  pallNotificationTypeOptions,
  pallNotificationMethodOptions,
  pallNotificationRolesOptions,
} from '../Settings.models';

const StyledPaper = styled(Paper)({
  maxWidth: '40rem',
});

export interface SendPallNotificationFields {
  type: PallNotificationType;
  method: PallNotificationMethod;
  intendedRoles: PallNotificationRoles;
  title: string;
  message: string;
}

export interface SendPallNotificationFormFields {
  type: SelectOption;
  method: SelectOption;
  intendedRoles: SelectOption;
  title: string;
  message: string;
}

function fromFormFields(
  formFields: SendPallNotificationFormFields
): SendPallNotificationFields {
  return {
    type: formFields.type.value as PallNotificationType,
    method: formFields.method.value as PallNotificationMethod,
    intendedRoles: formFields.intendedRoles.value as PallNotificationRoles,
    title: formFields.title,
    message: formFields.message,
  };
}

interface SendPallNotificationFormProps {
  mutation?: UseMutationResult<
    unknown,
    unknown,
    SendPallNotificationFields,
    unknown
  >;
}

export const SendPallNotificationForm = ({
  mutation,
}: SendPallNotificationFormProps) => {
  const intl = useIntl();
  const formProps: UseFormProps<SendPallNotificationFormFields> = useMemo(
    () => ({
      defaultValues: {
        type: pallNotificationTypeOptions[0],
        method: pallNotificationMethodOptions[0],
        intendedRoles: pallNotificationRolesOptions[0],
        title: '',
        message: '',
      },
    }),
    []
  );

  const onSubmit = useCallback(
    (data: SendPallNotificationFormFields) => {
      mutation?.mutate(fromFormFields(data));
    },
    [mutation]
  );

  return (
    <StyledPaper>
      <Form<SendPallNotificationFormFields>
        onSubmit={onSubmit}
        formProps={formProps}
      >
        <Stack>
          <TitleHeader>
            <FormattedMessage
              id="settings-send-pall-notification-header-title"
              defaultMessage="Send Pall Notification"
              description="label for Send Pall Notification header"
            />
          </TitleHeader>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-send-pall-notification-type-label"
                defaultMessage="Notification Type"
                description="label for Notification Type"
              />
            </SectionLabel>
            <SectionFormSelect
              name="type"
              placeholder={intl.formatMessage({
                id: 'settings-send-pall-notification-type-placeholder',
                defaultMessage: 'Select Notification Type',
                description: 'label for Notification Type placeholder',
              })}
              options={pallNotificationTypeOptions}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-send-pall-notification-method-label"
                defaultMessage="Notification Method"
                description="label for Notification Method"
              />
            </SectionLabel>
            <SectionFormSelect
              name="method"
              placeholder={intl.formatMessage({
                id: 'settings-send-pall-notification-method-placeholder',
                defaultMessage: 'Select Notification Method',
                description: 'label for Notification Method placeholder',
              })}
              options={pallNotificationMethodOptions}
            />
          </SectionControl>
          <PermissionView
            permissionKey={PermissionKey.SettingsPallNotificationsSelectRoles}
          >
            <SectionControl>
              <SectionLabel>
                <FormattedMessage
                  id="settings-send-pall-notification-intended-roles-label"
                  defaultMessage="Intended Roles"
                  description="label for Intended Roles"
                />
              </SectionLabel>
              <SectionFormSelect
                name="intendedRoles"
                placeholder={intl.formatMessage({
                  id: 'settings-send-pall-notification-intended-roles-placeholder',
                  defaultMessage: 'Select Intended Roles',
                  description: 'label for Intended Roles placeholder',
                })}
                options={pallNotificationRolesOptions}
              />
            </SectionControl>
          </PermissionView>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-send-pall-notification-title-label"
                defaultMessage="Notification Title"
                description="label for Notification Title"
              />
            </SectionLabel>
            <SectionTextInput
              name="title"
              required
              placeholder={intl.formatMessage({
                id: 'settings-send-pall-notification-title-placeholder',
                defaultMessage: 'Enter Title',
                description: 'label for Title placeholder',
              })}
            />
          </SectionControl>
          <SectionVerticalControl>
            <SectionVerticalLabel>
              <FormattedMessage
                id="settings-send-pall-notification-message-label"
                defaultMessage="Message"
                description="label for Message"
              />
            </SectionVerticalLabel>
            <SectionTextAreaInput
              name="message"
              minRows={6}
              required
              placeholder={intl.formatMessage({
                id: 'settings-send-pall-notification-message-placeholder',
                defaultMessage: 'Enter Message',
                description: 'label for Message placeholder',
              })}
            />
          </SectionVerticalControl>
        </Stack>
        <SettingsFooterButtons
          showCancelButton={false}
          submitLabel={intl.formatMessage({
            id: 'settings-send-pall-notification-submit-button',
            defaultMessage: 'Send Notification',
            description: 'message for Send Notification submit footer button',
          })}
          isLoading={mutation?.isLoading}
          isSuccess={mutation?.isSuccess}
          successLabel={intl.formatMessage({
            id: 'settings-send-pall-notification-success-message',
            defaultMessage: 'Successfully sent Pall Notification.',
            description: 'label for send pall notification success message',
          })}
        />
      </Form>
    </StyledPaper>
  );
};
