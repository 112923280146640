import { styled } from '@mui/material';
import { betterForwardRef } from '../../../../../utils/betterForwardRef';
import {
  VerticalLabels,
  VerticalLabelsProps,
} from '../../../../../base/VerticalLabels';

const KPIVerticalLabelsInner = styled(VerticalLabels)({
  flex: 1,
  flexShrink: 0,
  minHeight: '3.25rem',
  '&& > .top-label.top-label': {
    minHeight: '2rem',
  },
  '&& > .sub-label': {
    minHeight: '1rem',
  },
});
KPIVerticalLabelsInner.defaultProps = {
  $flexWrap: 'nowrap',
};

export const KPIVerticalLabels = betterForwardRef<
  HTMLDivElement,
  VerticalLabelsProps
>((props: VerticalLabelsProps, ref) => {
  return <KPIVerticalLabelsInner {...props} subLabelBold={true} ref={ref} />;
});
KPIVerticalLabels.displayName = 'KPIVerticalLabels';
