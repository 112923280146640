import { useIntl } from 'react-intl';
import { parseISO } from 'date-fns';
import { Paper, Stack, styled } from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import {
  filterRunsStartDate$,
  filterRunsEndDate$,
  filterRunsStore,
} from '@beeriot/api-client';
import { FilterRunsTableHeader } from './FilterRunsTableHeader';
import { useResponsiveSizes } from '../../utils';
import { RangedAppDatePicker } from '../../base/date-picker';
import { SuspenseFilterRunsTableItems } from './SuspenseFilterRunsTableItems';

const TableContainer = styled(Stack)({
  margin: '1rem',
  gap: '1rem',
});

const StyledPaper = styled(Paper)({
  overflow: 'auto',
});

export function FilterRunsTable() {
  const intl = useIntl();
  const runsBetween = intl.formatMessage({
    id: 'filter-run-table-header-date-descripttion',
    defaultMessage: 'Runs between',
    description: 'description for filter run table date range',
  });
  const { isMobileSize } = useResponsiveSizes();
  const [startDateString] = useObservable(filterRunsStartDate$);
  const [endDateString] = useObservable(filterRunsEndDate$);
  const startDate = parseISO(startDateString);
  const endDate = parseISO(endDateString);

  const handleStartDateChange = (date: Date | null) => {
    if (date)
      filterRunsStore.update((state) => ({
        ...state,
        startDate: date.toISOString(),
      }));
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date)
      filterRunsStore.update((state) => ({
        ...state,
        endDate: date.toISOString(),
      }));
  };

  return (
    <TableContainer>
      {isMobileSize ? (
        <>
          <RangedAppDatePicker
            startDate={startDate}
            endDate={endDate}
            onStartChange={handleStartDateChange}
            onEndChange={handleEndDateChange}
            labelPrefix={runsBetween}
          />
          <SuspenseFilterRunsTableItems />
        </>
      ) : (
        <StyledPaper>
          <FilterRunsTableHeader
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={handleStartDateChange}
            onEndDateChange={handleEndDateChange}
          />
          <SuspenseFilterRunsTableItems />
        </StyledPaper>
      )}
    </TableContainer>
  );
}
