import { ButtonBase, Popover, Stack, styled, useTheme } from '@mui/material';
import { ReactNode, useRef, useState, useCallback, useMemo } from 'react';
import { ColorKey } from '@beeriot/api-client';
import { transientOptions } from '../../utils/transientOptions';
import { AppIcon } from '../AppIcon';
import { BreadcrumbArrow } from './BreadcrumbArrow';
import { useResponsiveSizes } from '../../utils';
import { useIsMounted } from '../useIsMounted';


const StyledBreadcrumbBox = styled(
  ButtonBase,
  transientOptions
)(({
  justifyContent: 'start',
  borderBottomWidth: '.5rem',
  borderBottomStyle: 'solid',
  padding: '1rem 0.25rem 1rem 1rem',
  cursor: 'pointer',
  flexGrow:  '0',
  width:  'auto',
}));



const StyledBreadcrumbBoxDropdown = styled(
  StyledBreadcrumbBox,
  transientOptions
)
(({
  padding: '1rem',
  flexGrow:'1',
  width: '100%',
}));

const StyledBreadcrumbBoxSmallPhoneSize = styled(
  StyledBreadcrumbBox,
  transientOptions

)(({
  padding: '1rem 0rem 1rem 0.25rem',
  flexGrow:'0',
  width: 'auto',
}));


const ParentContainerStack = styled(
  Stack,
  transientOptions
)<{ $isDropdown?: boolean }>(({ $isDropdown }) => ({
  width: $isDropdown ? '100%' : 'auto',
}));

const ContainerStack = styled(
  Stack,
  transientOptions
)<{ $isDropdown?: boolean }>(({ $isDropdown }) => ({
  position: 'relative',
  width: $isDropdown ? '100%' : 'auto',
  whiteSpace: 'nowrap',
}));

const StyledDropdownStack = styled(
  Stack,
  transientOptions
)<{ $isSmallPhoneSize?: boolean }>(({ $isSmallPhoneSize }) => ({
  width: '100%',
  gap: $isSmallPhoneSize ? '0.5rem' : '2rem',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export interface BreadcrumbProps {
  isLast?: boolean;
  $stripeColorKey: ColorKey;
  $followingItemStripeColorKey?: ColorKey;
  $isDropdown?: boolean;
  disableBreadcrumb?: boolean;
  onClick?: () => void;
  renderDropdownContent?: (
    open: boolean,
    setOpen: (open: boolean) => void
  ) => ReactNode;
  children: ReactNode;
  className?: string;
}

export function Breadcrumb({
  isLast,
  $stripeColorKey,
  $followingItemStripeColorKey,
  $isDropdown,
  disableBreadcrumb,
  onClick,
  renderDropdownContent,
  children,
  className,
}: BreadcrumbProps) {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  const { isSmallPhoneSize } = useResponsiveSizes();
  const theme = useTheme();
  const isMounted = useIsMounted();

  const click = useCallback(() => {
    if(!isMounted.current) return;
    $isDropdown && setOpen(true);
    onClick?.();
  }, [$isDropdown, onClick, isMounted]);

  const onClose = useCallback(() => {
    if(!isMounted.current) return;
    setOpen(false);
  }, [isMounted]);

  const DropdownComponent = useMemo(() => {
    if(isSmallPhoneSize){
      return StyledBreadcrumbBoxDropdown
    } else if($isDropdown) {
      return StyledBreadcrumbBoxSmallPhoneSize
    }
    return StyledBreadcrumbBox
  }, [$isDropdown, isSmallPhoneSize])
  const stripeColor = theme.palette[$stripeColorKey]?.main

  return (
    <>
      <ParentContainerStack
        direction="row"
        $isDropdown={$isDropdown}
        className={className}
        onClick={disableBreadcrumb ? undefined : click}
      >
        <ContainerStack $isDropdown={$isDropdown}>
          <DropdownComponent
            ref={ref}
            style={{borderBottomColor: stripeColor}}
            onClick={disableBreadcrumb ? undefined : click}
            disabled={disableBreadcrumb}
          >
            <StyledDropdownStack
              $isSmallPhoneSize={isSmallPhoneSize}
              direction="row"
            >
              {children}
              {$isDropdown && (
                <AppIcon
                  icon={'chevron-down'}
                  $colorKey="highlight3"
                  size="lg"
                  style={{paddingRight:'1em'}}
                />
              )}
            </StyledDropdownStack>
          </DropdownComponent>
        </ContainerStack>
        {!isLast &&
          (!$isDropdown || ($isDropdown && !isLast)) &&
          $followingItemStripeColorKey && (
            <BreadcrumbArrow
              $leftStripeColorKey={$stripeColorKey}
              $rightStripeColorKey={$followingItemStripeColorKey}
            />
          )}
      </ParentContainerStack>
      {ref.current && renderDropdownContent && (
        <Popover
          open={open}
          anchorEl={ref.current}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {renderDropdownContent && renderDropdownContent(open, setOpen)}
        </Popover>
      )}
    </>
  );
}
