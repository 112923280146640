import { useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { Divider } from '@mui/material';
import {
  SectionControl,
  SectionFormSelect,
  SectionLabel,
} from '../../settings/settings-form';
import { useIntlReportAggregation } from '../../intl/useIntlReportAggregation';
import { SelectOption } from '../../form/SelectOption';
import { ReportAggregationEnum } from '@beeriot/api-client';
import { FormDateTextField } from '../../form';
import { addDays, format, startOfDay } from 'date-fns';

export function OneTimeReportFormFields() {
  const intl = useIntl();
  const reportAggregationIntl = useIntlReportAggregation();

  const aggregationLabel = intl.formatMessage({
    id: 'reports-new-recurring-report-aggregation-label',
    description: 'report aggregation label',
    defaultMessage: 'Aggregation',
  });
  const aggregationPlaceholder = intl.formatMessage({
    id: 'reports-new-recurring-report-aggregation-placeholder',
    description: 'aggregation placeholder label',
    defaultMessage: 'Select Aggregation',
  });
  const startDateLabel = intl.formatMessage({
    id: 'common-start-date-label',
    description: 'start date label',
    defaultMessage: 'Start Date',
  });
  const endDateLabel = intl.formatMessage({
    id: 'common-end-date-label',
    description: 'end date label',
    defaultMessage: 'End Date',
  });

  const { watch } = useFormContext();
  const selectedReportFrequency = watch('frequency');

  const reportAggregationOptions: SelectOption[] = [
    {
      value: 'Hourly',
      label: reportAggregationIntl(ReportAggregationEnum.Hourly),
    },
    {
      value: 'Daily',
      label: reportAggregationIntl(ReportAggregationEnum.Daily),
    },
    {
      value: 'Weekly',
      label: reportAggregationIntl(ReportAggregationEnum.Weekly),
    },
    {
      value: 'Montly',
      label: reportAggregationIntl(ReportAggregationEnum.Monthly),
    },
  ];

  if (selectedReportFrequency.value !== 'OneTime') return null;

  return (
    <>
      <SectionControl>
        <SectionLabel>{aggregationLabel}</SectionLabel>
        <SectionFormSelect
          name="aggregation"
          placeholder={aggregationPlaceholder}
          options={reportAggregationOptions}
          required
        />
      </SectionControl>
      <Divider />
      <SectionControl>
        <SectionLabel>{startDateLabel}</SectionLabel>
        <FormDateTextField
          name="startDate"
          defaultValue={format(
            addDays(startOfDay(new Date()), -7),
            'yyyy-MM-dd'
          )}
          required
        />
      </SectionControl>
      <SectionControl>
        <SectionLabel>{endDateLabel}</SectionLabel>
        <FormDateTextField
          name="endDate"
          defaultValue={format(startOfDay(new Date()), 'yyyy-MM-dd')}
          required
        />
      </SectionControl>
      <Divider />
    </>
  );
}
