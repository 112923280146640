import { createStore, select, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { GQLReport, GQLReportDefinition } from '../generated/gql';

export interface AutomaticReportSettingsProps {
  activeReport?: GQLReport;
  activeRecurringReport?: GQLReportDefinition;
  showRecurringReports: boolean;
}

export const reportStore = createStore(
  { name: 'automaticReportSettings' },
  withProps<AutomaticReportSettingsProps>({
    showRecurringReports: false,
  })
);

persistState(reportStore, {
  key: 'automaticReportSettings',
  storage: sessionStorageStrategy,
});

export const activeReport$ = reportStore.pipe(
  select((state) => {
    return state.activeReport;
  })
);

export const activeRecurringReport$ = reportStore.pipe(
  select((state) => {
    return state.activeRecurringReport;
  })
);

export const showRecurringReports$ = reportStore.pipe(
  select((state) => {
    return state.showRecurringReports;
  })
);
