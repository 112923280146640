import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  ButtonBase,
  ButtonBaseProps,
  Divider,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import {
  activeBlock$,
  blockStore,
  GQLBlock,
  getSequenceProgress,
} from '@beeriot/api-client';
import {
  RunningState,
  RunningStateColorKey,
  RunningStateIcon,
} from '../../../enums/RunningState';
import { useIntlRunningState } from '../../../enums/useIntlRunningState';
import { getEnumFromString } from '../../../utils/getEnumFromString';
import { transientOptions } from '../../../utils/transientOptions';
import { AppIcon } from '../../AppIcon';
import { VerticalLabels } from '../../VerticalLabels';
import { AppTypography } from '../../AppTypography';

const NoBlocksTypography = styled(Typography)({
  textAlign: 'center',
  padding: '1rem',
});

const ContainerStack = styled(Stack)({
  width: '20rem',
  maxHeight: '50rem',
});

const StyledTypography = styled(AppTypography)(({ theme }) => ({
  padding: '0.25rem 0.5rem',
  textTransform: 'uppercase',
  backgroundColor: theme.palette.componentShade.main,
}));

const SiteButton = styled(
  ButtonBase,
  transientOptions
)<ButtonBaseProps & { $selected: boolean }>(({ theme, $selected }) => ({
  alignItems: 'center',
  padding: '0.25rem 0.5rem',
  backgroundColor: $selected ? theme.palette.disabledPrimary.main : 'inherit',
}));

const SiteSelectedIcon = styled(AppIcon)({
  marginRight: '0.5rem',
});

const TimeRemainingStack = styled(Stack)({
  marginLeft: 'auto',
});

interface BreadcrumbsDropdownProps {
  blocks?: GQLBlock[] | null;
  onClose: () => void;
}

export function BreadcrumbsBlockDropdown({
  blocks,
  onClose,
}: BreadcrumbsDropdownProps) {
  const navigate = useNavigate();
  const [activeBlock] = useObservable(activeBlock$);
  const intlRunningState = useIntlRunningState();
  const selectBlock = (block: GQLBlock) => {
    blockStore.update((state) => ({ ...state, activeBlock: block }));
    navigate(
      `/companies/${block.companyId}/sites/${block.companySiteId}/lines/${block.systemId}/blocks/${block.id}`
    );
    onClose();
  };

  return (
    <ContainerStack>
      {blocks?.length === 0 ? (
        <NoBlocksTypography>
          <FormattedMessage
            id="blocks-no-blocks-found-label"
            description="Label for when there are no blocks found"
            defaultMessage="No Blocks Found"
          />
        </NoBlocksTypography>
      ) : (
        <Stack>
          <StyledTypography $bold variant="subtitle2" $colorKey="subtitleText">
            <FormattedMessage
              id="blocks-machine-blocks-label"
              description="Label for machine blocks"
              defaultMessage="Machine Blocks"
            />
          </StyledTypography>
          {blocks?.map((block) => {
            const selected = block.id === activeBlock?.id;
            const progress = getSequenceProgress(block.liveStatus);
            const runningState =
              getEnumFromString(
                RunningState,
                block.liveStatus?.sequenceStatus
              ) ?? RunningState.Off;
            return (
              <Stack key={block.id}>
                <SiteButton
                  $selected={selected}
                  onClick={() => selectBlock(block)}
                >
                  {selected && (
                    <SiteSelectedIcon icon="check" $colorKey="primary" />
                  )}
                  <VerticalLabels
                    labelBold
                    labelVariant="body1"
                    labelColorKey={selected ? 'primary' : 'darkText'}
                    label={block.description}
                    subLabelBold
                    subLabelVariant="subtitle2"
                    subLabel={intlRunningState(runningState)}
                    subLabelColorKey={RunningStateColorKey[runningState]}
                    subLabelIcon={RunningStateIcon[runningState]}
                  />
                  <TimeRemainingStack>
                    <VerticalLabels
                      labelBold
                      labelVariant="body1"
                      labelColorKey="primary"
                      label={progress?.remaining}
                      subLabelVariant="subtitle2"
                      subLabel={
                        <FormattedMessage
                          id="common-time-remaining-label"
                          defaultMessage="Time Remaining"
                          description="Label for time remaining"
                        />
                      }
                    />
                  </TimeRemainingStack>
                </SiteButton>
                <Divider />
              </Stack>
            );
          })}
        </Stack>
      )}
    </ContainerStack>
  );
}
