import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dialog, Stack } from '@mui/material';
import { AppTypography } from '../../base/AppTypography';
import { ExportDataForm, ExportFields } from './ExportDataForm';
import {
  TelemetryDataExportExportStatusEnum,
  useActiveAppEntities,
  usePostTelemetryExport,
  useRefetchExports,
} from '@beeriot/api-client';

interface ExportDataDialogProps {
  open: boolean;
  onClose: () => void;
}

export function ExportDataDialog({ open, onClose }: ExportDataDialogProps) {
  const [hasError, setHasError] = useState(false);
  const { gqlSystems, activeSystem, activeCompany, activeSite } =
    useActiveAppEntities();
    const refetch = useRefetchExports();
  const close = () => {
    setHasError(false);
    onClose();
    refetch();
  };

  const exportDataMutation = usePostTelemetryExport(
    (data) => {
      console.log(data);
      const stats = data?.exportStatus ?? TelemetryDataExportExportStatusEnum.Error;
      if (stats === TelemetryDataExportExportStatusEnum.InProgress) {
        close();
      } else if(stats === TelemetryDataExportExportStatusEnum.Error) {
        setHasError(true);
        // onClose();
      }  else if(stats === TelemetryDataExportExportStatusEnum.Completed && data?.exportDownloadUrl) {
        const link = document.createElement('a');
        link.href = data?.exportDownloadUrl;
        link.click();
        link.remove();
        close();
      } else {
        setHasError(true);
      }
    },
    (_err) => {
      console.error(_err);
      setHasError(true);
    }
  );

  const onExport = useCallback(
    (data: ExportFields) => {
      const payload = {
        companyId: activeCompany?.id ?? '',
        siteId: activeSite?.id ?? '',
        startTime: data.startDate,
        endTime: data.endDate,
        exportType: data.filter?.value,
        fileName: data.name,
        systemId: activeSystem?.id ?? '',
      };
      console.log('export payload', payload);
      console.log('export formData', data);
      exportDataMutation.mutate(payload);
    },
    [activeCompany?.id, activeSite?.id, activeSystem?.id, exportDataMutation]
  );

  return (
    <Dialog open={open} onClose={close}>
      <Stack sx={{ alignItems: 'center' }}>
        <AppTypography variant="h5" gutterBottom>
          <FormattedMessage
            id="new-export-data-dialog-title"
            defaultMessage="New Data Export"
            description="Title for export data dialog"
          />
        </AppTypography>
        <ExportDataForm
          lines={gqlSystems}
          hasError={hasError}
          isLoading={exportDataMutation.isLoading}
          onSubmit={(data: ExportFields) => {
            onExport(data);
            // onClose();
          }}
          onClose={close}
        />
      </Stack>
    </Dialog>
  );
}
