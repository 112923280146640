import { CalendarMode } from '@beeriot/api-client';
import {
  dateToStartOfView,
  calendarModeCalendarPickerRecord,
} from '../../utils';
import { dayRenderer } from './dayRenderer';

export function getDatePickerProps(
  calendarMode: CalendarMode,
  value: Date | null,
  onChange: (data: Date) => void
) {
  const renderDay = dayRenderer(calendarMode, value);
  const calendarView = calendarModeCalendarPickerRecord[calendarMode];
  const handleOnChange = (date: Date | null) => {
    if (!date || date === value) return;
    const convertedSelection = dateToStartOfView(calendarMode, date);
    onChange(convertedSelection);
  };

  return {
    value,
    renderDay,
    calendarView,
    handleOnChange,
    disableFuture: true,
    disableHighlightToday: true,
    openTo: calendarView,
    views: [calendarView],
    onChange: handleOnChange,
  };
}
