import { styled, alpha } from '@mui/material';
import { LoadingButton } from '../base/LoadingButton';

export const AuthSubmitButton = styled(LoadingButton)(({ theme }) => ({
  '&&': {
    color: 'white',
    marginTop: '1rem',
    marginBottom: '1rem',
    width: '100%',
    padding: '0.5rem',
    borderRadius: '10px',
    border: `2px solid ${alpha(theme.palette.white.main, 0.25)}`,
  },
}));
AuthSubmitButton.displayName = 'AuthSubmitButton';
