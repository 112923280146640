import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, styled } from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import { companySearch$, companyStore } from '@beeriot/api-client';
import {
  ControlBox,
  IconLabel,
  PermissionView,
  PermissionKey,
} from '../../base';
import { Form, FormTextInput } from '../../form';
import { RouterLink } from '../../navigation';
import { transientOptions } from '../../utils/transientOptions';
import { useResponsiveSizes } from '../../utils/responsive-design/useResponsiveSizes';

const CompaniesSearchForm = styled(Form)({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}) as typeof Form;

const EditCompaniesLink = styled(RouterLink)({
  height: '100%',
  marginLeft: 'auto',
});

const DoneEditingCompaniesLink = styled(RouterLink)({
  height: '100%',
  marginLeft: 'auto',
});

const StyledButton = styled(Button)({
  height: '100%',
});

const StyledFormTextInput = styled(
  FormTextInput,
  transientOptions
)<{ $fullWidth?: boolean }>(({ $fullWidth }) => ({
  width: $fullWidth ? '100%' : '25rem',
}));

interface CompaniesSearchFormFields {
  companyName: string;
}

export function CompaniesControlBox() {
  const intl = useIntl();
  const location = useLocation();
  const { isMobileSize } = useResponsiveSizes();
  const [searchText] = useObservable(companySearch$);

  const isEditing = useMemo(
    () => location.pathname.includes('/edit-companies'),
    [location.pathname]
  );
  const selectSitePlaceholder = intl.formatMessage({
    id: 'companies-search-placeholder',
    description: 'Placeholder for companies search input',
    defaultMessage: 'Search by company name',
  });

  return (
    <ControlBox>
      <CompaniesSearchForm<CompaniesSearchFormFields>
        submitOnChange
        onSubmit={(data) => {
          if (data.companyName !== undefined) {
            companyStore.update((state) => ({
              ...state,
              companySearch: data.companyName,
            }));
          }
        }}
      >
        <StyledFormTextInput
          defaultValue={searchText}
          $fullWidth={isMobileSize}
          name="companyName"
          placeholder={selectSitePlaceholder}
          icon="magnifying-glass"
        />
      </CompaniesSearchForm>
      {!isMobileSize && (
        <PermissionView permissionKey={PermissionKey.CompaniesEditCompanies}>
          {isEditing ? (
            <DoneEditingCompaniesLink to={'/companies'}>
              <StyledButton variant="contained" color={'highlight2'}>
                <IconLabel
                  icon="check-double"
                  variant="body1"
                  label={
                    <FormattedMessage
                      id="companies-edit-companies-done-editing-button"
                      description="Button to navigate back to companies page when done editing"
                      defaultMessage="Done Editing"
                    />
                  }
                />
              </StyledButton>
            </DoneEditingCompaniesLink>
          ) : (
            <EditCompaniesLink to={'/companies/edit-companies'}>
              <StyledButton variant="contained">
                <IconLabel
                  icon="pen-to-square"
                  variant="body1"
                  label={
                    <FormattedMessage
                      id="companies-edit-companies-button"
                      description="Button to navigate to the edit companies page"
                      defaultMessage="Edit Companies"
                    />
                  }
                />
              </StyledButton>
            </EditCompaniesLink>
          )}
        </PermissionView>
      )}
    </ControlBox>
  );
}
