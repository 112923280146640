const ONE_MIN_IN_MS = 60 * 1000;

// Polling Constants
export const ULTRA_LONG_POLLING_INTERVAL = 60 * ONE_MIN_IN_MS;
export const LONG_POLLING_INTERVAL = 20 * ONE_MIN_IN_MS;
export const MEDIUM_POLLING_INTERVAL = 5 * ONE_MIN_IN_MS;
export const SHORT_POLLING_INTERVAL = 1 * ONE_MIN_IN_MS;
export const REALTIME_POLLING_INTERVAL = 15 * 1000; // 15 secs

//Stale Time Constants
export const LONG_STALE_TIME_INTERVAL = 20 * ONE_MIN_IN_MS;
export const MEDIUM_STALE_TIME_INTERVAL = 5 * ONE_MIN_IN_MS;
export const SHORT_STALE_TIME_INTERVAL = 1 * ONE_MIN_IN_MS;
