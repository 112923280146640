import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { formatUnit } from '../../../../../../utils/formatUnits';
import { ThresholdValue } from '../../../../../../utils/threshold-color/thresholdValue';
import { useThresholdColor } from '../../../../../../utils/threshold-color/useThresholdColor';
import { KPIGridItem } from '../../kpi-grid/KPIGrid';
import { SystemKpiHookProps } from '../../SystemKpiHookProps';
import { useShouldRenderKpi } from '../../useShouldRenderKpi';
import { KPIVerticalLabels } from '../../kpi-grid/KPIVerticalLabel';

export function useActualDpGridItem(props: SystemKpiHookProps) {
  const { system, gridArea, NA } = props;
  const shouldRender = useShouldRenderKpi(props);
  const thresholdColor = useThresholdColor(system?.id);

  return useMemo(() => {
    if (!shouldRender) {
      return undefined;
    }

    const labelColor = thresholdColor(
      ThresholdValue.ActualDp,
      system?.liveKPI?.lineOxygenPpb
    );

    return (
      <KPIGridItem gridArea={gridArea ?? 'actualDp'}>
        <KPIVerticalLabels
          label={
            system?.liveKPI?.actualDp != null
              ? formatUnit(system?.liveKPI?.actualDp, 'bar')
              : NA
          }
          labelBold
          subLabel={
            <FormattedMessage
              id="common-actual-dp-label"
              defaultMessage="Actual dp"
              description="Actual dp label"
            />
          }
          labelColorKey={labelColor}
        />
      </KPIGridItem>
    );
  }, [
    shouldRender,
    thresholdColor,
    system?.liveKPI?.lineOxygenPpb,
    system?.liveKPI?.actualDp,
    gridArea,
    NA,
  ]);
}
