import { useGQLSystemLiveKPI } from '@beeriot/api-client';
import { useResponsiveValues } from '../../../utils';
import { SystemKpiGridAreaTemplate } from '../base-card-common/grids/SystemKpiHookProps';
import { SystemCardBaseProps } from '../base-card-common/SystemCardBase';
import { ProfiSystemDetailCardBase } from './ProfiSystemDetailCardBase';

// prettier-ignore
const templateArea: SystemKpiGridAreaTemplate = [
  [ 'commonDegreeOfEfficiency', 'commonDegreeOfEfficiency','actualFlow'],
  [ 'netCapacity', 'lastHourAverageFlow','actualFlow'],
  ['turbidity', 'oxygen', 'cipCycles'],
  ];

// prettier-ignore
const mobileTemplateArea: SystemKpiGridAreaTemplate = [
  [ 'commonDegreeOfEfficiency', 'commonDegreeOfEfficiency','actualFlow'],
  [ 'netCapacity', 'lastHourAverageFlow','actualFlow'],
  ['turbidity', 'oxygen', 'cipCycles'],
  ];

export function ProfiProcessDetailCard(props: SystemCardBaseProps) {
  const { boldLabelTitles = true, system } = props;
  const gridTemplateAreas = useResponsiveValues<SystemKpiGridAreaTemplate>({
    xxs: mobileTemplateArea,
    md: templateArea,
  });
  useGQLSystemLiveKPI(system);
  return (
    <ProfiSystemDetailCardBase
      data-element-id={'profi-process-detail-card'}
      {...props}
      boldLabelTitles={boldLabelTitles}
      gridTemplateAreas={gridTemplateAreas}
    />
  );
}
