import { Box, styled } from '@mui/material';
import { IoTStatusLegend } from './IoTStatusLegend';

const IoTStatusLegendContainer = styled(Box)(({ theme }) => ({
  zIndex: 1,
  width: '100%',
  backgroundColor: theme.palette.componentBase.main,
  padding: '0.5rem 1rem',
  boxShadow: theme.shadows[2],
  overflow: 'auto',
}));

export function IoTStatusLegendFooter() {
  return (
    <IoTStatusLegendContainer>
      <IoTStatusLegend />
    </IoTStatusLegendContainer>
  );
}