import { useIntl } from 'react-intl';
import { AppDrawer, useHasPermission, PermissionKey } from '../../base';
import { DrawerType } from '../../enums';
import { SuspenseSiteDetailsSidebarMenu } from './SuspenseSiteDetailsSidebarMenu';

export function SiteDetailsSidebarDrawer() {
  const intl = useIntl();
  const headerLabel = intl.formatMessage({
    id: 'common-site-overview-label',
    defaultMessage: 'Site Overview',
    description: 'Site overview label',
  });

  const hasPermission = useHasPermission();

  return (
    <AppDrawer
      type={DrawerType.SiteDetails}
      headerLabel={headerLabel}
      hideToggleButton={
        !hasPermission(PermissionKey.SitesSiteOverviewToggleSidebar)
      }
      drawerContent={<SuspenseSiteDetailsSidebarMenu />}
      $width="25rem"
    />
  );
}
