import { ButtonBase, styled } from '@mui/material';
import {
  GQLAlertActive,
  GQLAlertHistory,
  alertStore,
} from '@beeriot/api-client';
import { AppIcon } from '../../base';

type Direction = 'forward' | 'back';

const NavButton = styled(ButtonBase)({
  backgroundColor: 'transparent',
  padding: '3rem',
});

interface AlertDialogNavButtonProps {
  alerts: GQLAlertActive[] | GQLAlertHistory[];
  direction: Direction;
  alertIndex: number;
  totalAlertCount: number;
}

export function AlertDialogNavButton({
  alerts,
  direction,
  alertIndex,
  totalAlertCount,
}: AlertDialogNavButtonProps) {
  const navigateAlerts = (direction: Direction) => {
    alertStore.update((state) => ({
      ...state,
      selectedAlert: alerts[alertIndex + (direction === 'forward' ? 1 : -1)],
    }));
  };

  const disabled =
    direction === 'forward'
      ? alertIndex === totalAlertCount - 1
      : alertIndex === 0;

  return (
    <NavButton onClick={() => navigateAlerts(direction)} disabled={disabled}>
      <AppIcon
        $colorKey={disabled ? 'subtitleText' : 'white'}
        icon={direction === 'forward' ? 'chevron-right' : 'chevron-left'}
        size="4x"
      />
    </NavButton>
  );
}
