import React from 'react';
import {
  Box,
  Button,
  styled,
  TablePagination,
  TablePaginationProps,
  TablePaginationTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { AppIcon } from '../../base/AppIcon';

const StyledBox = styled(Box)({
  flexShrink: 0,
  marginLeft: 2.5,
});

const StyledTablePagination = styled(TablePagination)({
  borderBottom: 'none',
}) as OverridableComponent<TablePaginationTypeMap<TablePaginationProps, 'div'>>;

function tablePaginationActions(props: TablePaginationActionsProps) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <StyledBox>
      <Button
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        <AppIcon icon="chevrons-left" />
      </Button>
      <Button
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        <AppIcon icon="chevron-left" />
      </Button>
      <Button
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        <AppIcon icon="chevron-right" />
      </Button>
      <Button
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        <AppIcon icon="chevrons-right" />
      </Button>
    </StyledBox>
  );
}

export interface AppTablePaginationProps {
  page?: number;
  pageSizes?: number[];
  rowsPerPage?: number;
  totalRows?: number;
  setPage: (value: number) => void;
  setRowsPerPage: (value: number) => void;
}

export const AppTablePagination = ({
  page = 0,
  pageSizes = [10, 15, 20, 25, 30],
  rowsPerPage = 10,
  totalRows = 0,
  setPage,
  setRowsPerPage,
}: AppTablePaginationProps) => {
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <StyledTablePagination
      component={'div'}
      page={page}
      count={totalRows}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={pageSizes}
      ActionsComponent={tablePaginationActions}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};
