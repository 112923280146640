import { useMemo } from 'react';
import { KPIGridItem } from '../../kpi-grid/KPIGrid';
import { SystemKpiHookProps } from '../../SystemKpiHookProps';
import { useShouldRenderKpi } from '../../useShouldRenderKpi';
import { KPIVerticalLabels } from '../../kpi-grid/KPIVerticalLabel';
import { FormattedMessage } from 'react-intl';

export function useCipCyclesSinceRefillGridItem(props: SystemKpiHookProps) {
  const { system, gridArea, NA } = props;
  const shouldRender = useShouldRenderKpi(props);

  return useMemo(() => {
    if (!shouldRender) {
      return undefined;
    }

    return (
      <KPIGridItem gridArea={gridArea ?? 'cipCycles'}>
        <KPIVerticalLabels
          label={
            system?.liveKPI?.cipCyclesSinceLastRefill != null
              ? Math.trunc(system?.liveKPI?.cipCyclesSinceLastRefill)
              : NA
          }
          labelBold
          subLabel={
            <FormattedMessage
              id="common-cip-cycles-since-refill-label"
              defaultMessage="CIP Cycles Since Refill"
              description="cip cycles since refill label"
            />
          }
          labelColorKey="success"
        />
      </KPIGridItem>
    );
  }, [shouldRender, gridArea, system?.liveKPI?.cipCyclesSinceLastRefill, NA]);
}
