import { Stack, Box, styled, Divider } from '@mui/material';
import { Form, FormFooterButtons } from '../../form';
import { FormattedMessage, useIntl } from 'react-intl';
import { SectionLabel, SectionTextInput } from '../../settings/settings-form';
import { UseMutationResult } from 'react-query';

const InputContainer = styled(Stack)({
  padding: '0.5rem 1rem',
  alignItems: 'baseline',
});

const StyledSectionLabel = styled(SectionLabel)({ minWidth: 'fit-content' });

const FormButtonContainer = styled(Box)({ marginTop: '1rem' });

const StyledFormButtons = styled(FormFooterButtons)({ margin: '0.5rem' });

export interface AddEditGroupNameFormFields {
  name: string;
}

export interface AddEditGroupNameFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutation: UseMutationResult<any, unknown, any, unknown> | null;
  handleSubmit: (data: AddEditGroupNameFormFields) => void;
  handleClose: () => void;
  editMode: boolean;
  isChildGroup?: boolean;
  defaultValue?: string;
  error?: React.ReactNode;
}

export function AddEditGroupNameForm({
  mutation,
  handleSubmit,
  handleClose,
  isChildGroup,
  editMode,
  defaultValue,
}: AddEditGroupNameFormProps) {
  const intl = useIntl();

  const childInputPlaceholder = intl.formatMessage({
    id: 'organize-sites-add-new-group-name-input-placeholder',
    description: 'Placeholder for the group name input',
    defaultMessage: 'Enter Group Name',
  });

  const typeInputPlaceholder = intl.formatMessage({
    id: 'organize-sites-add-new-group-type-name-input-placeholder',
    description: 'Placeholder for the group type name input',
    defaultMessage: 'Enter Group Type Name',
  });

  const selectSitesLabel = intl.formatMessage({
    id: 'organize-sites-add-new-group-select-sites-button',
    description: 'Go to site selection form',
    defaultMessage: 'Select Sites',
  });

  const saveButtonLabel = intl.formatMessage({
    id: 'common-save-button',
    description: 'Save button',
    defaultMessage: 'Save',
  });

  return (
    <Form<AddEditGroupNameFormFields> onSubmit={handleSubmit}>
      <InputContainer direction="row">
        <StyledSectionLabel>
          {isChildGroup ? (
            <FormattedMessage
              id="organize-sites-add-new-group-name-input-label"
              defaultMessage="Group Name"
              description="label for group name"
            />
          ) : (
            <FormattedMessage
              id="organize-sites-add-new-group-type-name-input-label"
              defaultMessage="Group Type Name"
              description="label for group type name"
            />
          )}
        </StyledSectionLabel>
        <SectionTextInput
          name="name"
          placeholder={
            isChildGroup ? childInputPlaceholder : typeInputPlaceholder
          }
          defaultValue={defaultValue}
        />
      </InputContainer>
      <Divider />
      <FormButtonContainer>
        <StyledFormButtons
          requireCancelConfirmation={false}
          submitLabel={
            isChildGroup && !editMode ? selectSitesLabel : saveButtonLabel
          }
          onCancel={handleClose}
          justify="center"
          isLoading={mutation === null ? undefined : mutation.isLoading}
        />
      </FormButtonContainer>
    </Form>
  );
}
