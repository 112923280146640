import { SettingsSystems } from '@beeriot/api-client';
import { GaugeSection } from '../base/RadialGauge';

interface FlowGaugeInfo {
  maxFlow: number;
  successLimit: number;
  warningLimit: number;
  gaugeMap?: Map<number, GaugeSection> | undefined;
}

export function getFlowGaugeInfo(
  systemSettings: SettingsSystems | undefined
): FlowGaugeInfo {
  const targetValue = systemSettings?.thresholdValues?.targetFlowRateHlh ?? 0;
  const greenPercentageDifference = systemSettings?.actualFlow?.green ?? 0;
  const redPercentageDifference = systemSettings?.actualFlow?.red ?? 0;

  const greenThreshold =
    targetValue - targetValue * (greenPercentageDifference / 100);
  const redThreshold =
    targetValue - targetValue * (redPercentageDifference / 100);
  const maxFlow = targetValue + targetValue * (greenPercentageDifference / 100);

  const redThresholdSize = redThreshold / maxFlow;
  const greenThresholdSize = 1 - greenThreshold / maxFlow;
  const yellowThresholdSize = 1 - redThresholdSize - greenThresholdSize;

  const warningLimit = (redThreshold / maxFlow) * 100;
  const successLimit = (greenThreshold / maxFlow) * 100;

  const gaugeMap = new Map([
    [redThresholdSize, GaugeSection.danger],
    [yellowThresholdSize, GaugeSection.warning],
    [greenThresholdSize, GaugeSection.safe],
  ]);

  return { maxFlow, successLimit, warningLimit, gaugeMap };
}
