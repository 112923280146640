import { useMutation, useQueryClient } from 'react-query';
import {
  Report,
  ReportApi,
  ReportDefinition,
  ReportDefinitionBase,
  ReportDefinitionBaseAggregationEnum,
  ReportDefinitionBaseFrequencyEnum,
} from '../generated';
import {
  GQLListReportDefinitionsQuery,
  GQLReport,
  GQLReportDefinition,
  useInfiniteListReportDefinitionsQuery,
  useInfiniteListReportsQuery,
} from '../generated/gql';
import {
  defaultPagingParams,
  getNextPageParamHandler,
  insertToInfiniteData,
  optimisticUpdateInfiniteData,
  removeFalsey,
} from '../utils';
import { appConfiguration } from './configuration';

export const defaultFrequencyAggregationRecord: Record<
  ReportDefinitionBaseFrequencyEnum,
  ReportDefinitionBaseAggregationEnum | null
> = {
  [ReportDefinitionBaseFrequencyEnum.OneTime]: null,
  [ReportDefinitionBaseFrequencyEnum.Daily]:
    ReportDefinitionBaseAggregationEnum.Hourly,
  [ReportDefinitionBaseFrequencyEnum.Weekly]:
    ReportDefinitionBaseAggregationEnum.Daily,
  [ReportDefinitionBaseFrequencyEnum.Monthly]:
    ReportDefinitionBaseAggregationEnum.Weekly,
  [ReportDefinitionBaseFrequencyEnum.Yearly]:
    ReportDefinitionBaseAggregationEnum.Monthly,
};

function toGQLReportDefinition(
  reportDefinition: ReportDefinition
): GQLReportDefinition {
  return {
    id: reportDefinition.id,
    companyId: reportDefinition.companyId,
    reportName: reportDefinition.reportName,
    reportType: reportDefinition.reportType,
    frequency: reportDefinition.frequency,
    aggregation: reportDefinition.aggregation,
    systems: reportDefinition.systems,
  };
}

interface ReportQueryVariables {
  skip: number;
  limit: number;
  companyId: string | null | undefined;
  systemId: string | null | undefined;
}

const reportsApi = new ReportApi(appConfiguration);
const definittionsQueryKeyPrefix = 'listReportDefinitions.infinite';
const queryVariables = (
  companyId?: string | null,
  systemId?: string | null
): ReportQueryVariables => {
  return {
    companyId,
    systemId,
    ...defaultPagingParams,
  };
};
const definitionsQueryKey = (companyId?: string, systemId?: string) => [
  definittionsQueryKeyPrefix,
  queryVariables(companyId, systemId),
];

export function useReports(systemId?: string): GQLReport[] {
  const {
    data: reports,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteListReportsQuery(
    { systemId: systemId, ...defaultPagingParams },
    {
      enabled: !!systemId,
      refetchInterval: 15000,
      getNextPageParam: getNextPageParamHandler(
        (query) => query?.listReports?.length
      ),
    }
  );

  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage();
  }

  const gqlReports = removeFalsey<GQLReport>(
    reports?.pages.flatMap((x) => x.listReports)
  );

  return gqlReports.sort((a, b) =>
    (a.generatedOn ?? '').localeCompare(b.generatedOn ?? '')
  );
}

export function useReportDefinitions(
  companyId?: string,
  systemId?: string
): GQLReportDefinition[] {
  const {
    data: reports,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteListReportDefinitionsQuery(
    queryVariables(companyId, systemId),
    {
      enabled: !!systemId || !!companyId,
      refetchInterval: 15000,
      getNextPageParam: getNextPageParamHandler(
        (query) => query?.listReportDefinitions?.length
      ),
    }
  );

  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage();
  }

  const gqlReports = removeFalsey<GQLReportDefinition>(
    reports?.pages.flatMap((x) => x.listReportDefinitions)
  );

  return gqlReports.sort((a, b) =>
    (a.reportName ?? '').localeCompare(b.reportName ?? '')
  );
}

export function useRefetchReportDefinitions() {
  const queryClient = useQueryClient();
  return () => queryClient.refetchQueries(definittionsQueryKeyPrefix);
}

async function addReportDefinition(definitionBase: ReportDefinitionBase) {
  const res = await reportsApi.createReportDefinition(definitionBase);
  return res.data;
}

async function editReportDefinition(
  id: string,
  definitionBase: ReportDefinitionBase
) {
  const res = await reportsApi.updateReportDefinition(id, definitionBase);
  return res.data;
}

async function generateReport(definitionBase: ReportDefinitionBase) {
  const res = await reportsApi.generateReport(definitionBase);
  return res.data;
}

async function deleteReportDefinition(id: string) {
  const res = await reportsApi.deleteReportDefinition(id);
  return res.data;
}

export const useAddReportDefinition = (
  onSuccess: (reportDefinition: GQLReportDefinition | null) => void,
  onError: (err: string) => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (definitionBase: ReportDefinitionBase) => {
      const response = await addReportDefinition(definitionBase);
      return response;
    },
    {
      onError: (err) => {
        onError(err as string);
      },
      onSuccess: async (data: ReportDefinition) => {
        const gqlReportDefinition = toGQLReportDefinition(data);
        await insertToInfiniteData<
          GQLListReportDefinitionsQuery,
          GQLReportDefinition
        >(
          queryClient,
          definitionsQueryKey(),
          'listReportDefinitions',
          gqlReportDefinition
        );
        onSuccess(gqlReportDefinition);
      },
    }
  );
};

export const useUpdateReportDefinition = (
  systemId: string,
  onSuccess: (reportDefinition: GQLReportDefinition | null) => void,
  onError: (err: string) => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (req: { id: string; definitionBase: ReportDefinitionBase }) => {
      const response = await editReportDefinition(req.id, req.definitionBase);
      return response;
    },
    {
      onMutate: async (reportDefinition: GQLReportDefinition) => {
        return await optimisticUpdateInfiniteData<
          GQLListReportDefinitionsQuery,
          GQLReportDefinition
        >(
          queryClient,
          definitionsQueryKey(reportDefinition.companyId ?? '', systemId),
          'listReportDefinitions',
          reportDefinition
        );
      },
      onError: (err, req, context) => {
        if (context?.previousState) {
          queryClient.setQueryData(
            definitionsQueryKey(req.definitionBase.companyId, systemId),
            context.previousState
          );
        }
        onError(err as string);
      },
      onSuccess: (reportDefinition) => {
        onSuccess(reportDefinition);
      },
    }
  );
};

export const useGenerateReport = (
  onSuccess: (report: Report | null) => void,
  onError: (err: string) => void
) => {
  return useMutation(
    async (definitionBase: ReportDefinitionBase) => {
      const response = await generateReport(definitionBase);
      return response;
    },
    {
      onError: (err) => {
        onError(err as string);
      },
      onSuccess: (report) => {
        onSuccess(report);
      },
    }
  );
};

async function getReportUrl(reportId: string) {
  const report = await reportsApi.getReport(reportId);
  return report.data.reportDownloadUrl;
}

export const useGetReportUrl = (
  onSuccess: (report: string | undefined) => void,
  onError: (err: string) => void
) => {
  return useMutation(
    async (reportId: string) => {
      const response = await getReportUrl(reportId);
      return response;
    },
    {
      onError: onError,
      onSuccess: onSuccess,
    }
  );
};

export const useDeleteReport = (
  onSuccess?: (report: ReportDefinition | null) => void,
  onError?: (err: string) => void
) => {
  return useMutation(
    async (id: string) => {
      const response = await deleteReportDefinition(id);
      return response;
    },
    {
      onError: (err) => {
        onError?.(err as string);
      },
      onSuccess: (report) => {
        onSuccess?.(report);
      },
    }
  );
};

async function shareReport(reportId: string, emailAddress: string) {
  await reportsApi.reportShare(reportId, emailAddress);
}

export const useShareReport = (
  onSuccess?: () => void,
  onError?: (err: string) => void
) => {
  return useMutation(
    async ({ id, email }: { id: string; email: string }) => {
      const response = await shareReport(id, email);
      return response;
    },
    {
      onError: (err) => {
        onError?.(err as string);
      },
      onSuccess: () => {
        onSuccess?.();
      },
    }
  );
};
