import { Button, styled } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { AppTypography } from '../../../base';
import { MobileAnalyticsFilterFormDialog } from './MobileAnalyticsFilterFormDialog';

const StyledButton = styled(Button)({
  margin: '1rem 2rem 0 2rem',
});

export function MobileAnalyticsFiltersForm() {
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const applyFilters = intl.formatMessage({
    id: 'common-adjust-filters-button',
    description: 'Button for adjusting filters',
    defaultMessage: 'Adjust Filters',
  });

  return (
    <>
      <StyledButton
        variant="contained"
        color="highlight2"
        onClick={() => setOpen(true)}
      >
        <AppTypography>{applyFilters}</AppTypography>
      </StyledButton>
      <MobileAnalyticsFilterFormDialog
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
}
