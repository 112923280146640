import { GetNextPageParamFunction } from 'react-query';

const pageSize = 100;

export const defaultPagingParams = {
  skip: 0,
  limit: pageSize,
};

export function getNextPageParamHandler<T>(
  getQueryLength: (query?: T) => number | undefined
): GetNextPageParamFunction<T> {
  return (lastPage, allPages) => {
    const totalPages = allPages.length ?? 0;
    const pageLength = (lastPage && getQueryLength(lastPage)) ?? 0;
    if (pageLength >= pageSize) {
      return {
        skip: totalPages * pageSize,
        limit: pageSize,
      };
    }
    return undefined;
  };
}
