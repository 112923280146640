import { useGQLSystemLiveKPI } from '@beeriot/api-client';
import { useResponsiveValues } from '../../../utils/responsive-design/useResponsiveSizes';
import { SystemKpiGridAreaTemplate } from '../base-card-common/grids/SystemKpiHookProps';
import { SystemCardBaseProps } from '../base-card-common/SystemCardBase';
import { CFSSystemDetailCardBase } from './CFSSystemDetailCardBase';

const templateArea: SystemKpiGridAreaTemplate = [
  ['lastHourAverageFlow', 'commonDegreeOfEfficiency', 'actualFlow'],
  ['actualDp', 'cipCycles', 'actualFlow'],
];

const mobileTemplateArea: SystemKpiGridAreaTemplate = [
  ['lastHourAverageFlow', 'commonDegreeOfEfficiency'],
  ['actualDp', 'cipCycles'],
  ['actualFlow', 'actualFlow'],
];

export function CFSProcessDetailCard(props: SystemCardBaseProps) {
  const { boldLabelTitles = true, system } = props;
  const gridTemplateAreas = useResponsiveValues<SystemKpiGridAreaTemplate>({
    xxs: mobileTemplateArea,
    md: templateArea,
  });
  useGQLSystemLiveKPI(system);
  return (
    <CFSSystemDetailCardBase
      {...props}
      boldLabelTitles={boldLabelTitles}
      gridTemplateAreas={gridTemplateAreas}
    />
  );
}
