import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { IconLabel, LoadingPaper } from '../../base';
import { LocalUtilityCostsSettingsProps } from './LocalUtilityCostsSettings';

const LocalUtilityCostsSettingsLoadingPaper = styled(LoadingPaper)({
  height: '100%',
  width: '40rem',
  margin: '0.5rem',
});

const LocalUtilityCostsSettingsForm = lazy(() =>
  import('./LocalUtilityCostsSettingsQuery').then(
    ({ LocalUtilityCostsSettingsQuery }) => ({
      default: LocalUtilityCostsSettingsQuery,
    })
  )
);

export function SuspenseLocalUtilityCostsSettings({
  mutation,
  error,
}: LocalUtilityCostsSettingsProps) {
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <IconLabel
                label={
                  <FormattedMessage
                    id="settings-local-utility-costs-settings-error-label"
                    description="Error message for local utiltiy costs error"
                    defaultMessage="Local Utility Costs Unavailable For this site"
                  />
                }
              />
            );
          }}
        >
          <Suspense fallback={<LocalUtilityCostsSettingsLoadingPaper />}>
            <LocalUtilityCostsSettingsForm mutation={mutation} error={error} />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
