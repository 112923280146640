import { useIntl } from 'react-intl';
import { Dialog, DialogContent, Stack, styled, TextField } from '@mui/material';
import { format } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';
import { CalendarMode, ColorKey } from '@beeriot/api-client';
import { useIntlCalendarMode } from '../../../enums';
import { DialogHeader } from '../../../dialogs/DialogHeader';
import { AppTypography } from '../../AppTypography';
import { CalendarModePicker } from '../CalendarModePicker';
import { getDatePickerProps } from '../getDatePickerProps';

const StyledTextField = styled(TextField)({
  marginLeft: 'auto',
});

const StyledStack = styled(Stack)({
  padding: '1rem',
  alignItems: 'center',
  gap: '1rem',
});

export interface CombinedCalendarModePickerDialogProps {
  calendarMode: CalendarMode;
  onCalendarModeChange: (view: CalendarMode) => void;
  open: boolean;
  onClose: () => void;
  value: Date | null;
  onChange: (date: Date | null) => void;
  colorKey: ColorKey;
}

export function CombinedCalendarModePickerDialog({
  calendarMode,
  onCalendarModeChange,
  open,
  onClose,
  value,
  onChange,
  colorKey,
}: CombinedCalendarModePickerDialogProps) {
  const getViewLabel = useIntlCalendarMode();
  const intl = useIntl();
  const title = intl.formatMessage({
    id: 'combined-date-picker-dialog-title',
    description: 'Title for combined date picker dialog',
    defaultMessage: 'Select Date',
  });
  const datePickerProps = getDatePickerProps(calendarMode, value, onChange);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader
        label={title}
        headerVariant="h6"
        colorKey={colorKey}
        onClose={onClose}
      />
      <DialogContent>
        <CalendarModePicker
          value={calendarMode}
          onChange={onCalendarModeChange}
        />
        <StyledStack direction="row">
          <AppTypography>{getViewLabel(calendarMode)}</AppTypography>
          <DatePicker
            {...datePickerProps}
            renderInput={(props) => {
              const newProps = {
                ...props,
                inputProps: {
                  ...props.inputProps,
                  value: value
                    ? calendarMode === CalendarMode.Year
                      ? format(value, 'yyyy')
                      : format(value, 'MM/dd/yyyy')
                    : props.inputProps?.value,
                },
              };
              return <StyledTextField {...newProps} />;
            }}
          />
        </StyledStack>
      </DialogContent>
    </Dialog>
  );
}
