import { createStore, select, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { startOfWeek, addDays } from 'date-fns';
import { CalendarMode } from '../enums';
import { GQLFilterRun } from '../generated/gql';

export const runFilterKey = 'Run';
export type StatisticsViewingFilter =
  | CalendarMode.Week
  | CalendarMode.Month
  | CalendarMode.Year
  | typeof runFilterKey;

const weekStart = startOfWeek(new Date());
const prevWeekStart = addDays(weekStart, -7);

export interface LineStatisticsProps {
  activeLineStatisticsId: string | null;
  filterRun: GQLFilterRun | null;
  compareFilterRun: GQLFilterRun | null;
  viewingFilter: StatisticsViewingFilter;
  date: string;
  compareDate: string;
}

export const lineStatisticsStore = createStore(
  { name: 'line-statistics' },
  withProps<LineStatisticsProps>({
    activeLineStatisticsId: null,
    filterRun: null,
    compareFilterRun: null,
    viewingFilter: CalendarMode.Week,
    date: weekStart.toISOString(),
    compareDate: prevWeekStart.toISOString(),
  })
);

persistState(lineStatisticsStore, {
  key: 'line-statistics',
  storage: sessionStorageStrategy,
});

export const activeLineStatisticsId$ = lineStatisticsStore.pipe(
  select((state) => {
    return state.activeLineStatisticsId;
  })
);

export const lineStatisticsFilterRun$ = lineStatisticsStore.pipe(
  select((state) => {
    return state.filterRun;
  })
);

export const lineStatisticsCompareFilterRun$ = lineStatisticsStore.pipe(
  select((state) => {
    return state.compareFilterRun;
  })
);

export const lineStatisticsViewingFilter$ = lineStatisticsStore.pipe(
  select((state) => {
    return state.viewingFilter;
  })
);

export const lineStatisticsDate$ = lineStatisticsStore.pipe(
  select((state) => {
    return state.date;
  })
);

export const lineStatisticsCompareDate$ = lineStatisticsStore.pipe(
  select((state) => {
    return state.compareDate;
  })
);
