import { createStore, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { SettingsUsers } from '../../generated/api';

export interface SettingsProps {
  userSettings: SettingsUsers | null;
}
export const settingsStore = createStore(
  { name: 'settings' },
  withProps<SettingsProps>({
    userSettings: null,
  })
);

persistState(settingsStore, {
  key: 'settings',
  storage: sessionStorageStrategy,
});
