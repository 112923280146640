import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { Stack, Button, Typography, styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { LoadingPaper } from '../../base';

const EditSiteCardList = lazy(() =>
  import('./EditSiteCardListQuery').then(({ EditSiteCardListQuery }) => ({
    default: EditSiteCardListQuery,
  }))
);

const ErrorContainer = styled(Stack)({
  width: '100%',
  alignItems: 'center',
});

const MessageContainer = styled(Stack)({
  textAlign: 'center',
  width: '30rem',
  margin: '3rem',
  justifyContent: 'center',
});

const SiteLoadingPaper = styled(LoadingPaper)({
  height: '8rem',
  width: '30rem',
  margin: '1.5rem',
});

function SiteLoadingPapers() {
  return (
    <Stack>
      <Stack direction="row">
        <SiteLoadingPaper />
        <SiteLoadingPaper />
      </Stack>
      <Stack direction="row">
        <SiteLoadingPaper />
        <SiteLoadingPaper />
        <SiteLoadingPaper />
      </Stack>
      <Stack direction="row">
        <SiteLoadingPaper />
      </Stack>
    </Stack>
  );
}

export function SuspenseEditSiteCardList() {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ resetErrorBoundary }) => {
            return (
              <ErrorContainer>
                <MessageContainer>
                  <Typography>
                    <FormattedMessage
                      id="sites-error-loading-label"
                      description="Label for sites loading error"
                      defaultMessage="There was an error loading sites"
                    />
                  </Typography>
                  <Button onClick={() => resetErrorBoundary()}>
                    <FormattedMessage
                      id="common-try-again-button"
                      description="Button for trying an action again"
                      defaultMessage="Try Again"
                    />
                  </Button>
                </MessageContainer>
              </ErrorContainer>
            );
          }}
          onReset={reset}
        >
          <Suspense fallback={<SiteLoadingPapers />}>
            <EditSiteCardList />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
