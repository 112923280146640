import { useState } from 'react';
import { Backdrop, Divider, Stack, StackProps, styled } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useObservable } from '@ngneat/use-observable';
import { userInfo$ } from '@beeriot/api-client';
import { AppIcon, AppMedallion, AppMedallionProps } from '../../../base';
import { UserInfoDropdown } from './UserInfoDropdown';
import { SuspenseUserRole } from './SuspenseUserRole';
import { transientOptions } from '../../../utils/transientOptions';
import { useResponsiveSizes } from '../../../utils/responsive-design/useResponsiveSizes';
import { DialogState, UserInfoDialogs } from './UserInfoDialogs';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.componentBase.main,
    padding: '0',
    maxWidth: 'none',
    marginRight: '0.8rem',
  },
  [`& .${tooltipClasses.tooltipArrow} .MuiTooltip-arrow`]: {
    color: theme.palette.componentBase.main,
    right: '0',
    left: 'inherit !important',
    transform: 'translate(-1.2rem) !important',
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.componentShade.main,
}));

const ContainerStack = styled(Stack)({
  height: '100%',
  cursor: 'pointer',
});
interface UserInfoContentsStackProps extends StackProps {
  $isDesktopSize?: boolean;
}
interface UserInfoAppMedallionProps extends AppMedallionProps {
  $isDesktopSize?: boolean;
}

const ContentsStack = styled(
  Stack,
  transientOptions
)<UserInfoContentsStackProps>(({ $isDesktopSize }) => ({
  height: '100%',
  minWidth: $isDesktopSize ? '20rem' : 'inherit',
  alignItems: 'center',
  padding: '0.5rem',
  margin: '0 1rem',
}));

const StyledMedallion = styled(
  AppMedallion,
  transientOptions
)<UserInfoAppMedallionProps>(({ theme, $isDesktopSize }) => ({
  height: '3rem',
  width: '3rem',
  fontSize: '1.5rem',
  marginRight: $isDesktopSize ? '1rem' : '0',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
}));

const DropdownIconContainer = styled(Stack)({
  marginLeft: 'auto',
});

export function UserInfoBox() {
  const [dialogState, setDialogState] = useState<DialogState>({
    tooltip: false,
    contactSupport: false,
    logout: false,
  });
  const [userInfo] = useObservable(userInfo$);
  const { isDesktopSize } = useResponsiveSizes();

  return (
    <>
      <Backdrop open={dialogState.tooltip} />
      <StyledTooltip
        PopperProps={{
          disablePortal: true,
        }}
        arrow
        placement="top-start"
        open={dialogState.tooltip}
        title={
          <UserInfoDropdown
            email={userInfo?.email ?? ''}
            onClose={() =>
              setDialogState({
                tooltip: false,
                contactSupport: false,
                logout: false,
              })
            }
            onSelectLogout={() => {
              setDialogState({
                tooltip: false,
                contactSupport: false,
                logout: true,
              });
            }}
            onSelectContactSupport={() => {
              setDialogState({
                tooltip: false,
                contactSupport: true,
                logout: false,
              });
            }}
          />
        }
      >
        <ContainerStack
          onClick={() =>
            setDialogState({
              tooltip: true,
              contactSupport: false,
              logout: false,
            })
          }
          direction="row"
        >
          <StyledDivider orientation="vertical" />
          <ContentsStack direction="row" $isDesktopSize={isDesktopSize}>
            <StyledMedallion
              colorKey="highlight8"
              $isDesktopSize={isDesktopSize}
            >
              {userInfo?.initials}
            </StyledMedallion>
            {isDesktopSize ? (
              <>
                <SuspenseUserRole name={userInfo?.name} />
                <DropdownIconContainer>
                  <AppIcon size="2x" $colorKey="white" icon="chevron-down" />
                </DropdownIconContainer>
              </>
            ) : null}
          </ContentsStack>
        </ContainerStack>
      </StyledTooltip>
      <UserInfoDialogs
        dialogState={dialogState}
        setDialogState={setDialogState}
      />
    </>
  );
}
