import { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import * as d3 from 'd3';
import { Arc } from 'd3';
import { Box, Stack, styled, useTheme } from '@mui/material';
import { ColorKey } from '@beeriot/api-client';
import { AppTypography } from './AppTypography';

const BoxContainer = styled(Box)({
  display: 'grid',
  gridTemplate: 'container',
  alignItems: 'end',
  justifyItems: 'center',
  placeContent: 'center',
  overflow: 'hidden',
});

const CenterSvg = styled('svg')({
  gridArea: 'container',
  maxWidth: '100%',
});

const CenterStack = styled(Stack)({
  gridArea: 'container',
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledTypography = styled(AppTypography)({
  fontSize: '1.5rem',
});

const PercentSign = styled('span')({
  fontSize: '.85rem',
});

export interface RadialProgressProps {
  progress?: number;
  colorKey: ColorKey;
  radius?: number;
}

export function RadialProgress({
  progress,
  colorKey,
  radius = 45,
}: RadialProgressProps) {
  const theme = useTheme();
  const svgRef = useRef(null);
  const intl = useIntl();
  const NA = intl.formatMessage({
    id: 'common-not-applicable',
    defaultMessage: 'N/A',
    description: "Label for when there isn't a value to display",
  });

  const startAngle = -0.8 * Math.PI;
  const endAngle = 0.8 * Math.PI;
  const height = radius * 1.9;
  const width = radius * 2;
  const outerRadius = radius;
  const innerRadius = outerRadius - Math.floor(outerRadius / 4);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const arc: Arc<any, any> = d3
    .arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .startAngle(startAngle)
    .endAngle(endAngle);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const progressArc: Arc<any, any> = d3
    .arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .startAngle(startAngle)
    .endAngle(endAngle * (((progress ?? 0) / 100) * 2 - 1));

  useEffect(() => {
    const svg = d3
      .select(svgRef.current)
      .selectAll('g')
      .attr('class', 'arc')
      .attr('transform', `translate(${outerRadius}, ${outerRadius})`);

    svg
      .append('path')
      .attr('d', arc)
      .attr('fill', theme.palette.componentShade.main);

    svg
      .append('path')
      .attr('d', progressArc)
      .attr('fill', theme.palette[colorKey].main);

    // masking circle
    svg
      .append('circle')
      .attr('cy', '0')
      .attr('cx', '0')
      .attr('r', `${Math.floor(outerRadius / 2)}`)
      .attr('fill', 'white');
  }, [arc, progressArc, outerRadius, colorKey, theme]);

  return (
    <BoxContainer height={height} width={width}>
      <CenterSvg ref={svgRef} height={height} width={width}>
        <g />
      </CenterSvg>
      <CenterStack height={height} width={width}>
        <StyledTypography $bold>
          {`${progress != null ? progress : NA}`}
          {progress && <PercentSign>%</PercentSign>}
        </StyledTypography>
      </CenterStack>
    </BoxContainer>
  );
}
