import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  ButtonBase,
  ButtonBaseProps,
  Divider,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import {
  activeCompany$,
  AppQueryClient,
  GQLCompany,
  groupCompaniesByLetter,
  setActiveSite,
  setActiveCompany
} from '@beeriot/api-client';
import { transientOptions } from '../../../utils/transientOptions';
import { AppIcon } from '../../../base/AppIcon';
import { VerticalLabels } from '../../../base/VerticalLabels';
import {
  AppTypography,
  OverridableComponentAppTypography,
} from '../../../base/AppTypography';

import { ParentPageName } from '../types/ParentPageName';
import { useMemo, useTransition } from 'react';
import { useIsMounted } from '../../useIsMounted';

const LabelsContainer = styled(Stack)({
  alignItems: 'center',
  width: '100%',
  alignContent: 'space-between',
});

const CompanyLabels = styled(VerticalLabels)({
  flex: 1,
});

const SitesCountLabel = styled(Typography)({
  textAlign: 'end',
});

const NoItemsTypography = styled(Typography)({
  textAlign: 'center',
  padding: '1rem',
});

const ContainerStack = styled(Stack)({
  width: '20rem',
  maxHeight: '50rem',
});

const StyledTypography = styled(AppTypography)(({ theme }) => ({
  padding: '0.25rem 0.5rem',
  textTransform: 'uppercase',
  backgroundColor: theme.palette.componentShade.main,
}));

const ItemButton = styled(
  ButtonBase,
  transientOptions
)<ButtonBaseProps & { $selected: boolean }>(({ theme, $selected }) => ({
  justifyContent: 'start',
  padding: '0.25rem 0.5rem',
  backgroundColor: $selected ? theme.palette.disabledPrimary.main : 'inherit',
}));

const SelectedIcon = styled(AppIcon)({
  marginRight: '0.5rem',
});

const CompanyName = styled(AppTypography)({
  lineHeight: '1',
}) as OverridableComponentAppTypography;

interface BreadcrumbsCompanyDropdownProps {
  onClose: () => void;
  companies?: GQLCompany[] | null | undefined;
  parentPageName?: ParentPageName;
}

export function BreadcrumbsCompanyDropdown({
  onClose,
  companies,
  parentPageName,
}: BreadcrumbsCompanyDropdownProps) {
  const navigate = useNavigate();
  const [activeCompany] = useObservable(activeCompany$);
  const isMounted = useIsMounted();
  const [transitioning, startTransition] = useTransition();
 
  const companyLinks = useMemo(() => {
    return companies?.reduce((acc, companiesItem) => {
      acc[companiesItem?.id] = () => {
        startTransition(() =>{
          isMounted.current && onClose();
          setActiveCompany(companiesItem);
          navigate(`/companies/${companiesItem.id}/sites`);
        })
        
        setTimeout(() => {
          startTransition(() =>{
            setActiveCompany(companiesItem);
          })
          }, 0)
      }
      return acc
    }, {} as Record<string, () => void>) ?? {}
  }, [companies, navigate, onClose])


  const companySectionsAndButtons = useMemo(() => {

   
    const companyGroups = companies ? groupCompaniesByLetter(companies) : [];
    
    return companyGroups.map(({ letter, companies }) => (
    <Stack key={letter}>
      <StyledTypography
        gap="0.5rem"
        variant="subtitle2"
        $colorKey="subtitleText"
      >
        {letter}
      </StyledTypography>
      {companies?.map((company) => {
        const selected = company.id === activeCompany?.id;
        return (
          <Stack key={company.id}>
            <ItemButton
              $selected={selected}
              onClick={companyLinks[company.id]}
            >
              {selected && (
                <SelectedIcon icon="check" $colorKey="primary" />
              )}
              <LabelsContainer direction="row">
                <CompanyLabels
                  textAlign={'left'}
                  justifyContent={'start'}
                  label={<CompanyName component="div" variant="h6">
                    {company?.name}
                  </CompanyName>}
                  subLabel={company?.address?.country} />
                <SitesCountLabel variant="subtitle2">
                  <FormattedMessage
                    id="company-dropdown-sites-count-label"
                    defaultMessage="{sitesCount} Sites"
                    description="Label for company dropdown sites count"
                    values={{
                      sitesCount: company.companySiteCount?.count ?? 0,
                    }} />
                </SitesCountLabel>
              </LabelsContainer>
            </ItemButton>
            <Divider />
          </Stack>
        );
      })}
    </Stack>
  ))},[activeCompany?.id, companies, companyLinks]);
  return (
    <ContainerStack>
      {companies?.length === 0 ? (
        <NoItemsTypography>
          <FormattedMessage
            id="companies-error-loading-label"
            description="Label for companies loading error"
            defaultMessage="There was an error loading companies"
          />
        </NoItemsTypography>
      ) : (
        <Stack>
          <StyledTypography $bold variant="subtitle2" $colorKey="subtitleText">
            <FormattedMessage
              id="common-companies-label"
              description="Companies label"
              defaultMessage="Companies"
            />
          </StyledTypography>
          {companySectionsAndButtons}
        </Stack>
      )}
    </ContainerStack>
  );
}

