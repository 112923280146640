import {
  GQLListCompanySitesQuery,
  GQLListCompanySitesQueryVariables
} from '../generated/gql';
import { AppQueryClient } from '../utils';
import { axiosHelper } from '../generated/axiosHelper';
import { PrefetchListCompanySitesDocument } from '../generated/PrefetchListCompanySitesDocument';



export async function prefetchCompanySites(companyId?: string | null) {
  const sitesQuery = axiosHelper<
    GQLListCompanySitesQuery,
    GQLListCompanySitesQueryVariables
  >(PrefetchListCompanySitesDocument);
  const cachedFullList = AppQueryClient.getQueryData(['listCompanySites.infinite', { skip: 0, limit: 100, }]);
  if (!cachedFullList) {
    const rawSites = await sitesQuery({ limit: 100, companyId });
    if (rawSites.listCompanySites) {
      AppQueryClient.setQueryData(['listCompanySites.infinite', { skip: 0, limit: 100, status: "active" }], { pages: [rawSites], pageParams: [], isCachedPartial: true });
      const companySites = rawSites.listCompanySites?.reduce(
        (acc, companySite) => {
          if (companySite?.companyId) {
            if (!acc[companySite?.companyId]) {
              acc[companySite?.companyId] = [];
            }
            acc[companySite?.companyId].push(companySite);
            AppQueryClient.setQueryData(
              ['getCompanySite', { siteId: companySite?.id }],
              { getCompanySite: companySite, isCachedPartial: true }
            );
            AppQueryClient.setQueryData(
              [
                'getCompanySite',
                { siteId: companySite?.id, companyId: companySite?.companyId },
              ],
              { getCompanySite: companySite, isCachedPartial: true }
            );
          }
          return acc;
        },
        {} as Record<string, typeof rawSites.listCompanySites>
      );

      Object.entries(companySites).forEach(([companyId, companySites]) => {
        AppQueryClient.setQueryData(
          ['listCompanySites.infinite', { companyId: companyId, group: "", limit: 100, skip: 0, name: "", status: "active" }],
          { pages: [{ listCompanySites: companySites }], pageParams: [], isCachedPartial: true }
        );
        AppQueryClient.setQueryData(
          ['listCompanySites.infinite', { companyId: companyId, limit: 100, skip: 0, status: "active" }],
          { pages: [{ listCompanySites: companySites }], pageParams: [], isCachedPartial: true }
        );
      });
    }
  }


}
