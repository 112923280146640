import { useCallback, useMemo, useState } from 'react';
import { Dialog, Stack, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { UseFormProps } from 'react-hook-form';
import {
  GQLCompany,
  GQLCompanySite,
  useProvisioning,
  ProvisioningPayload,
  Area,
} from '@beeriot/api-client';
import {
  ProvisioningSetupForm,
  ProvisioningSetupFormFields,
} from './ProvisioningSetupForm';
import { Form } from '../../form';
import { AppDialog, AppTypography } from '../../base';
import { format } from 'date-fns';

const HeaderText = styled(AppTypography)({ alignSelf: 'center' });

const HeaderStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.componentBase.main,
  justifyContent: 'space-between',
  padding: '1rem',
  gap: '2rem',
  borderTopRightRadius: theme.shape.borderRadius,
  borderTopLeftRadius: theme.shape.borderRadius,
}));

export interface ProvisioningSetupDialogProps {
  open: boolean;
  companies: GQLCompany[];
  sites: GQLCompanySite[];
  areas: Area[];
  defaultCompanyName: string | undefined;
  onClose: () => void;
  setSelectedCompany: (value: string | null) => void;
  setSelectedSite: (value: string | null) => void;
}

export function ProvisioningSetupDialog({
  open,
  companies,
  sites,
  areas,
  defaultCompanyName,
  onClose,
  setSelectedCompany,
  setSelectedSite,
}: ProvisioningSetupDialogProps) {
  const [provisionSucceeded, setProvisionSucceeded] = useState<
    boolean | undefined
  >(undefined);
  const [resultModalOpen, setResultModalOpen] = useState(false);
  const provisioning = useProvisioning(
    () => {
      onClose();
      setProvisionSucceeded(true);
      setResultModalOpen(true);
    },
    (err) => {
      onClose();
      setProvisionSucceeded(false);
      setResultModalOpen(true);
    }
  );
  const formProps: UseFormProps<ProvisioningSetupFormFields> = useMemo(() => {
    return {
      defaultValues: {
        companyName: defaultCompanyName,
        companyAddress1: '',
        companyAddress2: '',
        companyCity: '',
        companyState: '',
        companyZipcode: '',
        companyCountry: '',
        siteName: '',
        siteAddress1: '',
        siteAddress2: '',
        siteCity: '',
        siteState: '',
        siteZipcode: '',
        siteCountry: '',
        machineType: undefined,
        areaName: '',
        machineName: '',
        machineSerialNumber: '',
        deploymentDate: format(new Date(), 'yyyy-MM-dd'),
        blocksQuantity: '',
        blockClustersQuantity: '',
        columnsCassettes: '',
        columnsPosition: '',
        columnsClustersQuantity: '',
      },
    };
  }, [defaultCompanyName]);

  const findIdsByName = useCallback(
    (
      companyName: string,
      siteName: string,
      areaName: string
    ): {
      companyId: string | undefined;
      siteId: string | undefined;
      areaId: string | undefined;
    } => {
      const company = companies.find((x: GQLCompany) => x.name === companyName);
      const site = sites.find((x: GQLCompanySite) => x.name === siteName);
      const area = areas.find((x: Area) => x.name === areaName);

      return {
        companyId: company ? company.id : undefined,
        siteId: site ? site.id : undefined,
        areaId: area ? area.id : undefined,
      };
    },
    [companies, sites, areas]
  );

  const onSubmit = useCallback(
    (data: ProvisioningSetupFormFields) => {
      const companyName = data.companyName
        ? typeof data.companyName === 'string'
          ? data.companyName
          : data.companyName.label
        : '';
      const siteName = data.siteName
        ? typeof data.siteName === 'string'
          ? data.siteName
          : data.siteName.label
        : '';

      const areaName = data.areaName
        ? typeof data.areaName === 'string'
          ? data.areaName
          : data.areaName.label
        : '';

      const ids = findIdsByName(companyName, siteName, areaName);
      //convert form fields into provisioning payload
      const payload: ProvisioningPayload = {
        companyId: ids.companyId,
        companyName,
        companyAddress: {
          address1: data.companyAddress1 ?? '',
          address2: data.companyAddress2 ?? '',
          city: data.companyCity ?? '',
          state: data.companyState ?? '',
          zipcode: data.companyZipcode ?? '',
          country: data.companyCountry ?? '',
        },
        siteId: ids.siteId,
        siteName,
        siteAddress: {
          address1: data.siteAddress1 ?? '',
          address2: data.siteAddress2 ?? '',
          city: data.siteCity ?? '',
          state: data.siteState ?? '',
          zipcode: data.siteZipcode ?? '',
          country: data.siteCountry ?? '',
        },
        areaId: ids.areaId,
        areaName,
        machineName: data.machineName,
        machineType: data.machineType.value,
        machineSerialNumber: data.machineSerialNumber,
        deploymentDate: data.deploymentDate,
        blocksQuantity: data?.blocksQuantity
          ? parseInt(data.blocksQuantity)
          : 0,
        blockClustersQuantity: data?.blockClustersQuantity
          ? parseInt(data.blockClustersQuantity)
          : 0,
        clusterModuleQuanity: data?.numberOfModules
          ? parseInt(data.numberOfModules)
          : 0,
        modulePartNumber: data.modulePartNumber,
        consumablesInstallDate: data?.installationDate,
        columnsQuantity: data?.columnsQuantity
          ? parseInt(data.columnsQuantity)
          : 0,
        columnsCassettes: data?.columnsCassettes
          ? parseInt(data.columnsCassettes)
          : 0,
        columnsPosition: data?.columnsPosition
          ? parseInt(data.columnsPosition)
          : 0,
        columnsClustersQuantity: data?.columnsClustersQuantity
          ? parseInt(data.columnsClustersQuantity)
          : 0,
        squareMetersPerModule: data?.squareMetersPerModule
          ? parseInt(data.squareMetersPerModule)
          : 0,
        moduleMembraneArea: data?.moduleMembraneArea
          ? parseInt(data.moduleMembraneArea)
          : 0,
      };
      provisioning.mutate(payload);
    },
    [findIdsByName, provisioning]
  );

  return (
    <>
      <AppDialog
        dialogTitle={
          provisionSucceeded ? (
            <FormattedMessage
              id="settings-provisioning-setup-success-title-label"
              description="Title label for the provisioning success modal"
              defaultMessage="Provisioning Succeeded"
            />
          ) : (
            <FormattedMessage
              id="settings-provisioning-setup-fail-title-label"
              description="Title label for the provisioning failure modal"
              defaultMessage="Provisioning Failed"
            />
          )
        }
        dialogContent={
          <AppTypography>
            {provisionSucceeded ? (
              <FormattedMessage
                id="settings-provisioning-setup-success-label"
                description="Label for provisioning setup success message"
                defaultMessage="System successfully provisioned. Please proceed to certificate provisioning to generate any necessary certificates."
              />
            ) : (
              <FormattedMessage
                id="settings-provisioning-setup-error-label"
                description="Label for provisioning setup error message"
                defaultMessage="System could not be provisioned successfully, if this continues please contact support."
              />
            )}
          </AppTypography>
        }
        open={resultModalOpen}
        confirm={() => setResultModalOpen(false)}
        confirmLabel={
          <FormattedMessage
            id="common-ok-button"
            defaultMessage="Ok"
            description="label for ok button"
          />
        }
        showCancelButton={false}
      />
      <Dialog open={open} maxWidth="md" scroll="body" fullWidth>
        <HeaderStack direction="row">
          <HeaderText $bold variant="h6">
            <FormattedMessage
              id="settings-provisioning-setup-dialog-header"
              defaultMessage="Provisioning Setup"
              description="Dialog header for provisioning setup form"
            />
          </HeaderText>
        </HeaderStack>
        <Form<ProvisioningSetupFormFields>
          onSubmit={onSubmit}
          formProps={formProps}
        >
          <ProvisioningSetupForm
            companies={companies}
            sites={sites}
            areas={areas}
            isLoading={provisioning.isLoading}
            onCancel={onClose}
            setSelectedCompany={setSelectedCompany}
            setSelectedSite={setSelectedSite}
          />
        </Form>
      </Dialog>
    </>
  );
}
