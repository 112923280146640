import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { Stack, Button, Typography, styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { LoadingPaper } from '../../../base';
import { GQLCompanySite } from '@beeriot/api-client';

const AddOrEditSiteList = lazy(() =>
  import('./AddOrEditSiteListQuery').then(({ AddOrEditSiteListQuery }) => ({
    default: AddOrEditSiteListQuery,
  }))
);

const SiteLoadingPaper = styled(LoadingPaper)({
  height: '5rem',
  width: 'calc(100% - 1rem)',
  margin: '0.5rem',
});

const ErrorContainer = styled(Stack)({
  width: '100%',
  padding: '1rem',
  alignItems: 'center',
});

const MessageContainer = styled(Stack)({
  textAlign: 'center',
  justifyContent: 'center',
});

function SiteLoadingPapers() {
  return (
    <Stack>
      <SiteLoadingPaper />
      <SiteLoadingPaper />
      <SiteLoadingPaper />
      <SiteLoadingPaper />
      <SiteLoadingPaper />
      <SiteLoadingPaper />
    </Stack>
  );
}

export interface SuspenseAddOrEditSiteListProps {
  selectedSites: GQLCompanySite[];
  setSelectedSites: (sites: GQLCompanySite[]) => void;
  editMode: boolean;
  removedSites?: GQLCompanySite[];
  setRemovedSites?: (sites: GQLCompanySite[]) => void;
}

export function SuspenseAddOrEditSiteList({
  selectedSites,
  setSelectedSites,
  removedSites,
  setRemovedSites,
  editMode,
}: SuspenseAddOrEditSiteListProps) {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ resetErrorBoundary }) => {
            return (
              <ErrorContainer>
                <MessageContainer>
                  <Typography>
                    <FormattedMessage
                      id="sites-error-loading-label"
                      description="Label for sites loading error"
                      defaultMessage="There was an error loading sites"
                    />
                  </Typography>
                  <Button onClick={() => resetErrorBoundary()}>
                    <FormattedMessage
                      id="common-try-again-button"
                      description="Button for trying an action again"
                      defaultMessage="Try Again"
                    />
                  </Button>
                </MessageContainer>
              </ErrorContainer>
            );
          }}
          onReset={reset}
        >
          <Suspense fallback={<SiteLoadingPapers />}>
            <AddOrEditSiteList
              selectedSites={selectedSites}
              setSelectedSites={setSelectedSites}
              removedSites={removedSites}
              setRemovedSites={setRemovedSites}
              editMode={editMode}
            />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
