import { FormattedMessage } from 'react-intl';
import { Button, Stack, styled } from '@mui/material';
import { IconLabel } from '../../base';
import { transientOptions, useResponsiveSizes } from '../../utils';

const StyledStack = styled(
  Stack,
  transientOptions
)<{ $desktopSize: boolean }>(({ $desktopSize }) => ({
  justifyContent: $desktopSize ? 'flex-end' : 'flex-start',
  flex: $desktopSize ? '1' : 'inherit',
  maxWidth: $desktopSize ? '30rem' : 'unset',
  padding: '1rem',
  gap: '1rem',
}));

const StyledButton = styled(Button)({
  width: '15rem',
});

interface TelemetryDataExportButtonsProps {
  showNewDataExportForm: () => void;
}

export function TelemetryDataExportButtons({
  showNewDataExportForm,
}: TelemetryDataExportButtonsProps) {
  const { isDesktopSize } = useResponsiveSizes();
  return (
    <StyledStack $desktopSize={isDesktopSize} direction="row">
      <StyledButton variant="contained" onClick={showNewDataExportForm}>
        <IconLabel
          variant="button"
          label={
            <FormattedMessage
              id="telemetry-data-export-table-header-new-data-export-button"
              defaultMessage="New Data Export"
              description="button label for new data export"
            />
          }
          colorKey="white"
          icon={['far', 'circle-plus']}
          iconColorKey="white"
          iconSize="lg"
        />
      </StyledButton>
    </StyledStack>
  );
}
