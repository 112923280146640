import axios from 'axios';
import { gqlBasePath } from '../lib/configuration';
import { authStore } from '../state/auth/authStore';

export const axiosHelper = <TData, TVariables>(
  query: string
): ((variables?: TVariables) => Promise<TData>) => {
  return async (variables?: TVariables) => {
    const state = authStore.getValue();
    return axios
      .post<{ data: TData; errors: unknown }>(
        gqlBasePath,
        { query, variables },
        { headers: { Authorization: state.accessToken ?? '' } }
      )
      .then((res) => {
        // if (res.data.errors) throw new Error(JSON.stringify(res.data.errors));
        console.log(res)
        return res.data.data;
      });
  };
};
