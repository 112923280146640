import {
  GQLListSystemsQuery,
  GQLListSystemsQueryVariables,
  GQLSystem,
  ListSystemsDocument
} from '../../generated/gql';
import { systemStore } from '../../state/system/systemStore';
import { removeFalsey } from '../../utils/removeFalsey';
import { axiosHelper } from '../../generated/axiosHelper';


export async function setToDefaultSystem(siteId?: string | null) {
  /*
  const systemsQuery = axiosHelper<
      GQLListSystemsQuery,
      GQLListSystemsQueryVariables
    >(ListSystemsDocument);
    // .bind(null, {
    //   companySiteId: siteId,
    //   limit: 1,
    // });
    // const cachedFullList = AppQueryClient.getQueryData(['listSystems.infinite', siteId, {skip:0, limit:1, status: "active" }]);
    const variables = {...queryVariables(siteId, undefined), ...{skip:0, limit:1, status: "active" }};
    const g = await AppQueryClient.fetchInfiniteQuery<GQLListSystemsQuery,unknown, GQLListSystemsQuery>(
     
      variables === undefined ? ['listSystems.infinite'] : ['listSystems.infinite', variables],
        (metaData) => systemsQuery({...variables, ...(metaData.pageParam ?? {})}),
      { staleTime: LONG_POLLING_INTERVAL }
    );
    // const rawGroups = g; //await getGroups(companyId);
    // const groupTypes = rawGroups.filter((x) => !x.parentGroupId);
    // const activeGroupType = groupTypes.length > 0 ? groupTypes[0] : null;
  
  
    // const rawSystems = await systemsQuery();
    const gqlSystems = removeFalsey<GQLSystem>(g.pages[0]?.listSystems);
    const activeSystem = gqlSystems.length > 0 ? gqlSystems[0] : null;
    systemStore.update((state) => ({ ...state, activeSystem: activeSystem }));
    return activeSystem;
  */
  const systemsQuery = axiosHelper<
    GQLListSystemsQuery,
    GQLListSystemsQueryVariables
  >(ListSystemsDocument).bind(null, {
    companySiteId: siteId,
    limit: 1,
  });
  try {
    const rawSystems = await systemsQuery();
    const gqlSystems = removeFalsey<GQLSystem>(rawSystems.listSystems);
    const activeSystem = gqlSystems.length > 0 ? gqlSystems[0] : null;
    systemStore.update((state) => ({ ...state, activeSystem: activeSystem }));
    return activeSystem;
  } catch (error) {
    systemStore.update((state) => ({ ...state, activeSystem: null }));
    return null;
  }

}
