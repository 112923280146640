import { RootPage } from '../RootPage';
import {
  AlertHistoryControlBox,
  AlertHistoryTable,
} from '../../alert-history/';
import { GlobalFooter, ScrollStack } from '../../base';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { setDropdownText } from '@beeriot/api-client';

export function AlertsHistoryPage() {
  const intl = useIntl();
  const alertsLabel = intl.formatMessage({
    id: 'common-alerts-label',
    description: 'Label for alerts',
    defaultMessage: 'Alerts',
  });
  useEffect(() => {
    setDropdownText(alertsLabel);
  }, [alertsLabel]);
  return (
    <RootPage>
      <AlertHistoryControlBox />
      <ScrollStack>
        <AlertHistoryTable />
        <GlobalFooter />
      </ScrollStack>
    </RootPage>
  );
}
