import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebookSquare,
  faLinkedin,
  faTwitterSquare,
  faVimeoSquare,
  faYoutubeSquare,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  faArrowUpRight,
  faArrowDownRight,
  faArrowRightArrowLeft,
  faCircleMinus,
  faClock,
  faFolderMedical,
  faPlusCircle,
  faArrowsMaximize,
  faArrowsMinimize,
  faRefresh,
  faTrashAlt,
  faWashingMachine,
  faSquare as faSquareRegular,
  faOctagonExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faAnglesLeft,
  faAnglesRight,
  faArrowRightFromBracket,
  faBars,
  faBell,
  faBellExclamation,
  faBellOn,
  faCalendarDay,
  faCalendarDays,
  faCheck,
  faCheckDouble,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronsLeft,
  faChevronsRight,
  faCircle,
  faCircleDot,
  faCircle3,
  faCircle6,
  faCircle9,
  faCircleMinus as solidCircleMinus,
  faCircleSmall,
  faCircleX,
  faClose,
  faCloudArrowUp,
  faCopy,
  faDownRight,
  faDownload,
  faEarthAmerica,
  faGear,
  faLifeRing,
  faList,
  faLocationDot,
  faMagnifyingGlass,
  faNetworkWired,
  faPenToSquare,
  faPencil,
  faPlus,
  faSortAsc,
  faSortDesc,
  faSparkles,
  faSquare as faSquareSolid,
  faSquareCheck,
  faStarSharp,
  faUpRight,
  faXmarkLarge,
  faWarning,
  faWrench,
  faFileCirclePlus,
  faCircleExclamation,
  faSliders,
  faSpinnerThird,
} from '@fortawesome/pro-solid-svg-icons';

export function registerIcons() {
  library.add(
    faYoutube,
    faFacebookSquare,
    faTwitterSquare,
    faYoutubeSquare,
    faLinkedin,
    faVimeoSquare,
    faDownload,
    faArrowUpRight,
    faArrowDownRight,
    faArrowRightArrowLeft,
    faArrowRightFromBracket,
    faAnglesLeft,
    faAnglesRight,
    faArrowsMaximize,
    faArrowsMinimize,
    faBars,
    faBell,
    faBellExclamation,
    faBellOn,
    faCalendarDay,
    faCalendarDays,
    faCheck,
    faCheckDouble,
    faChevronUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronsLeft,
    faChevronsRight,
    faCircle,
    faCircleDot,
    faCircle3,
    faCircle6,
    faCircle9,
    faCircleMinus,
    faCircleSmall,
    faCircleX,
    faClock,
    faClose,
    faCloudArrowUp,
    faCopy,
    faEarthAmerica,
    faFolderMedical,
    faGear,
    faLifeRing,
    faList,
    faLocationDot,
    faMagnifyingGlass,
    faNetworkWired,
    faPenToSquare,
    faPencil,
    faPlus,
    faPlusCircle,
    faRefresh,
    faSortAsc,
    faSortDesc,
    faSparkles,
    faSquareSolid,
    faSquareRegular,
    faSquareCheck,
    faStarSharp,
    faTrashAlt,
    faUpRight,
    faDownRight,
    faXmarkLarge,
    faWarning,
    faWashingMachine,
    faWrench,
    faOctagonExclamation,
    faFileCirclePlus,
    faCircleExclamation,
    solidCircleMinus,
    faSliders,
    faSpinnerThird
  );
}
