import { combineLatest, from, Observable, of } from 'rxjs';
import {
  concatMap,
  groupBy,
  map,
  mergeMap,
  pluck,
  shareReplay,
  tap,
  toArray,
} from 'rxjs/operators';
import { GQLCompany } from '../../generated/gql';
import { CompanyGroup } from '../../lib';

export function groupCompaniesByLetterOperator() {
  return _groupCompaniesByLetter;
}

// implement returned method as a module function so its JIT'ed
function _groupCompaniesByLetter(dataObs: Observable<GQLCompany[]>) {
  return dataObs.pipe(
    concatMap((x) =>
      from(
        (x ?? []).sort((a, b) => (a?.name ?? '').localeCompare(b?.name ?? ''))
      ).pipe(
        map((company) => ({
          company,
          firstLetter: company.name?.charAt(0)?.toLocaleUpperCase() ?? '',
        })),
        groupBy(
          ({ firstLetter, company }) => {
            return firstLetter;
          },
          {
            element: ({ company }) => company,
          }
        ),
        mergeMap((group) => {
          return combineLatest({
            letter: of(group.key),
            companies: group.pipe(toArray()),
          });
        }),

        toArray<CompanyGroup>()
      )
    )
  );
}
