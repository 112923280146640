import { useCallback, useState } from 'react';
import { Box, styled, Chip, Grid } from '@mui/material';
import {
  CompanySite,
  useUpdateCompanySite,
  useDeleteCompanySite,
  GQLCompanySite,
  CompanySiteBase,
  SiteGroup,
} from '@beeriot/api-client';
import { ConfirmSiteDeleteDialog } from './ConfirmSiteDeleteDialog';
import { EditSiteDialog } from './EditSiteDialog';
import { EditSiteCard } from './EditSiteCard';
import { SiteCardListSectionHeader } from '../site-card-list';

const StyledGroupContainer = styled(Box)({
  margin: '0',
  height: 'fit-content',
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'space-evenly',
});

const SiteGridSectionsContainer = styled(Grid)({
  padding: '1rem',
});

const SectionHeaderGridItem = styled(Grid)({
  marginBottom: '1rem',
});

const SectionCardGridContainer = styled(Grid)({
  width: '100%',
});

const StyledGrid = styled(Grid)({
  width: '100%',
});

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.shade.main,
  color: theme.palette.white.main,
}));

export interface EditSiteCardListProps {
  siteGroups: SiteGroup[];
}

export function EditSiteCardList({ siteGroups }: EditSiteCardListProps) {
  const [activeSite, setActiveSite] = useState<GQLCompanySite | undefined>(
    undefined
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const updateSite = useUpdateCompanySite(activeSite?.id ?? '', {
    onSuccess: (data: CompanySite | undefined) => {
      setIsEditModalOpen(false);
    },
    onError: (err: string) => {
      console.log('updateSite: onError: ', err);
    },
  });
  const deleteSite = useDeleteCompanySite(activeSite?.id ?? '', {
    onSuccess: (data: CompanySite | undefined) => {
      setIsDeleteModalOpen(false);
    },
    onError: (err: string) => {
      console.log('deleteSite: onError: ', err);
    },
  });

  const handleDeleteSite = useCallback(
    (site: CompanySiteBase) => {
      deleteSite.mutate(site);
    },
    [deleteSite]
  );

  const handleUpdateSite = useCallback(
    (site: CompanySiteBase) => {
      updateSite.mutate(site);
    },
    [updateSite]
  );

  return (
    <>
      <SiteGridSectionsContainer
        container
        spacing={3}
        key={`site-group-container`}
      >
        {siteGroups.map((siteGroup) => {
          return (
            <SectionHeaderGridItem
              key={`site-group--${siteGroup.name}`}
              item
              xs={12}
            >
              <SectionHeaderGridItem item xs={12}>
                <SiteCardListSectionHeader
                  siteCount={siteGroup.sites.length}
                  siteLetter={siteGroup.name}
                />
              </SectionHeaderGridItem>
              <StyledGrid container item spacing={3} xs={12}>
                {siteGroup.sites.map((site) => (
                  <StyledGrid
                    key={`edit-site-card--${site.id}`}
                    item
                    xs={12}
                    md={6}
                    xl={4}
                  >
                    <EditSiteCard
                      site={site}
                      setIsDeleteModalOpen={() => {
                        setIsDeleteModalOpen(true);
                        setActiveSite(site);
                      }}
                      setIsEditModalOpen={() => {
                        setIsEditModalOpen(true);
                        setActiveSite(site);
                      }}
                    />
                  </StyledGrid>
                ))}
              </StyledGrid>
            </SectionHeaderGridItem>
          );
        })}
      </SiteGridSectionsContainer>
      {activeSite && (
        <ConfirmSiteDeleteDialog
          mutation={deleteSite}
          site={activeSite}
          open={isDeleteModalOpen}
          confirm={handleDeleteSite}
          cancel={() => setIsDeleteModalOpen(false)}
        />
      )}
      {activeSite && (
        <EditSiteDialog
          mutation={updateSite}
          site={activeSite}
          open={isEditModalOpen}
          confirm={handleUpdateSite}
          cancel={() => setIsEditModalOpen(false)}
        />
      )}
    </>
  );
}
