import { useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Stack, Divider, Button, styled } from '@mui/material';
import { AreaSystem, GQLSystem } from '@beeriot/api-client';
import {
  AppIcon,
  AppTypography,
  PermissionKey,
  PermissionView,
  RunningStatusTimelineView,
} from '../../base';
import { AreaNameFormDialog } from './AreaNameFormDialog';
import { recentStatusToTimelineData } from '../../utils/recentStatusToTimelineData';

const StyledButton = styled(Button)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'first baseline',
  padding: '0.5rem',
});

const IconButton = styled(Button)({
  padding: '0.5rem',
  margin: '0 0.5rem',
  minWidth: '0',
});

export interface SiteDetailsSidebarRunningStatusViewProps {
  area: AreaSystem;
}

export function SiteDetailsSidebarRunningStatusView({
  area,
}: SiteDetailsSidebarRunningStatusViewProps) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const permissionKeyForArea = useMemo(() => {
    switch (area.area.name.toLowerCase()) {
      case 'filtration':
        return PermissionKey.SitesSiteOverviewUpdateFiltrationInfo;
      case 'stabilization':
        return PermissionKey.SitesSiteOverviewUpdateStabilizationInfo;
      case 'packaging':
        return PermissionKey.SitesSiteOverviewUpdatePackingInfo;
      case 'beer recovery':
        return PermissionKey.SitesSiteOverviewUpdateBeerRecoveryInfo;
      default:
        return PermissionKey.SitesSiteOverviewUpdateFiltrationInfo;
    }
  }, [area]);

  return (
    <>
      <Stack>
        <AppTypography variant="body1" $bold>
          {area.area.name}
          <PermissionView permissionKey={permissionKeyForArea}>
            <IconButton onClick={() => setOpen(true)}>
              <AppIcon $colorKey="highlight3" icon="pencil-alt" />
            </IconButton>
          </PermissionView>
        </AppTypography>
        <Divider variant="middle" />
        {area.systems.map((x: GQLSystem) => (
          <StyledButton
            onClick={() =>
              navigate(
                `/companies/${x.companyId}/sites/${x.companySiteId}/lines/${x.id}`
              )
            }
            variant="text"
            key={x.id}
          >
            <AppTypography variant="body1" $colorKey="darkText">
              {x.name}
            </AppTypography>
            <RunningStatusTimelineView
              data={recentStatusToTimelineData(
                x?.liveStatus?.recentSystemStatus
              )}
            />
            <AppTypography variant="caption" $colorKey="lightText">
              <FormattedMessage
                id="common-running-status-timeline-label"
                description="Label for running status timeline"
                defaultMessage="Running Status Timeline"
              />
            </AppTypography>
          </StyledButton>
        ))}
      </Stack>
      <AreaNameFormDialog
        area={area.area}
        open={open}
        close={() => setOpen(false)}
      />
    </>
  );
}
