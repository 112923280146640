import { Stack, styled } from '@mui/material';
import { transientOptions } from '../utils/transientOptions';

export const ControlBox = styled(
  Stack,
  transientOptions
)(({ theme }) => ({
  zIndex: theme.zIndex.appBar,
  boxShadow: theme.shadows[2],
  backgroundColor: theme.palette.componentBase.main,
  padding: '0.75rem 1rem',
  width: '100%',
  alignItems: 'center',
}));

ControlBox.defaultProps = {
  direction: 'row',
};
