import { FormattedMessage } from 'react-intl';
import { Button, styled } from '@mui/material';
import { IconLabel } from '../IconLabel';

const StyledHideButton = styled(Button)({
  minWidth: '0',
  flexDirection: 'column',
});

export interface AppHideSidebarButtonProps {
  toggleOpen: () => void;
  anchor: 'left' | 'right';
}

export function AppHideSidebarButton({
  toggleOpen,
  anchor,
}: AppHideSidebarButtonProps) {
  return (
    <StyledHideButton onClick={toggleOpen}>
      <IconLabel
        label={
          <FormattedMessage
            id="common-hide-button"
            defaultMessage="Hide"
            description="Button to perform the hide action"
          />
        }
        colorKey="subtitleText"
        variant="subtitle2"
        icon={anchor === 'right' ? 'angles-right' : 'angles-left'}
      />
    </StyledHideButton>
  );
}
