import { useQuery } from 'react-query';
import { IoTStatsApi } from '../generated';
import { appConfiguration } from './configuration';
import { Aggregation } from './analytics';

const iotStatsApi = new IoTStatsApi(appConfiguration);

export async function getIotStatus(
  companyId: string | undefined,
  startDate: string,
  endDate: string,
  aggregation: Aggregation
) {
  if (!companyId) return null;
  const res = await iotStatsApi.getIoTStats(
    companyId,
    startDate,
    endDate,
    aggregation
  );
  return res.data;
}

export function useIoTStatus(
  companyId: string | undefined,
  startDate: string,
  endDate: string,
  aggregation: Aggregation
) {
  return useQuery(
    ['iotStats', companyId, startDate, endDate, aggregation],
    () => getIotStatus(companyId, startDate, endDate, aggregation),
    { enabled: !!companyId }
  );
}
