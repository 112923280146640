import { CompanySite, GQLCompanySite } from '@beeriot/api-client';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  styled,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { UseMutationResult } from 'react-query';
import { AppTypography, LoadingButton } from '../../../base';

const StyledDialogContent = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const WarningContainer = styled(Box)({
  marginTop: '1rem',
});

const DividerContainer = styled(Box)({
  padding: '0px 2.5rem',
});

const StyledDivider = styled(Divider)({
  borderBottomWidth: '2px',
});

export interface ConfirmSiteDeleteDialogProps {
  mutation: UseMutationResult<CompanySite, unknown, CompanySite, unknown>;
  site: GQLCompanySite;
  groupName: string;
  open: boolean;
  confirm: () => void;
  cancel: () => void;
}

export function ConfirmSiteDeleteDialog({
  mutation,
  site,
  groupName,
  open,
  confirm,
  cancel,
}: ConfirmSiteDeleteDialogProps) {
  const intl = useIntl();

  const confirmLabel = intl.formatMessage({
    id: 'common-remove-button',
    description: 'Label for confirmation button',
    defaultMessage: 'Yes, Remove',
  });
  const cancelLabel = intl.formatMessage({
    id: 'common-cancel-button',
    description: 'Label for cancel button',
    defaultMessage: 'Cancel',
  });

  return (
    <Dialog open={open}>
      <DialogTitle>
        <FormattedMessage
          id="organize-sites-delete-site-dialog-title"
          description="Title for site deletion"
          defaultMessage="Remove Site"
        />
      </DialogTitle>
      <DividerContainer>
        <StyledDivider />
      </DividerContainer>
      <StyledDialogContent>
        <Box>
          <AppTypography>
            <FormattedMessage
              id="edit-groups-delete-site-dialog-content"
              description="Dialog text for removing a site from a group"
              defaultMessage={`Are you sure you want to remove the site "{siteName}" from group "{groupName}"?`}
              values={{
                siteName: site?.name ?? '',
                groupName: groupName,
              }}
            />
          </AppTypography>
        </Box>
      </StyledDialogContent>
      <DialogActions>
        <Button color="shade" variant="contained" onClick={cancel}>
          {cancelLabel}
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          onClick={confirm}
          isLoading={mutation.isLoading}
        >
          {confirmLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
