import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Stack, styled } from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import { parseISO } from 'date-fns';
import {
  alertHistoryStartDate$,
  alertStore,
  alertHistoryCalendarMode$,
  CalendarMode,
  useActiveAppEntities,
} from '@beeriot/api-client';
import {
  AppTypography,
  CalendarModePicker,
  CombinedCalendarModePicker,
  ControlBox,
  IconLabel,
  AppDatePicker,
} from '../base';
import { RouterLink } from '../navigation';
import { AlertHistoryFilterDropdown } from './AlertHistoryFilterDropdown';
import {
  transientOptions,
  useResponsiveSizes,
  useResponsiveValues,
} from '../utils';

const ReturnToSitesLink = styled(RouterLink)({
  height: '100%',
  marginRight: '1rem',
});

const StyledButton = styled(Button)({
  height: '100%',
});

const StyledAlertHistoryFilterDropdown = styled(AlertHistoryFilterDropdown)({
  marginRight: '1rem',
});

const StyledStack = styled(
  Stack,
  transientOptions
)<{ $desktopSize: boolean }>(({ $desktopSize }) => ({
  marginBottom: $desktopSize ? '0' : '1rem',
}));

const DatePickerContainer = styled(
  Stack,
  transientOptions
)<{ $desktopSize: boolean }>(({ $desktopSize }) => ({
  width: $desktopSize ? 'unset' : '100%',
  gap: '1rem',
  alignItems: 'center',
}));

export function AlertHistoryControlBox() {
  const { activeCompany } = useActiveAppEntities();
  const [alertFilterStartDate] = useObservable(alertHistoryStartDate$);
  const [alertFilterCalendarMode] = useObservable(alertHistoryCalendarMode$);

  const { isMobileSize, isDesktopSize } = useResponsiveSizes();
  const showCombinedDatepicker = useResponsiveValues<boolean>({
    lg: false,
    xl: true,
  });
  const date = parseISO(alertFilterStartDate);

  const setMode = (calanderMode: CalendarMode) => {
    alertStore.update((state) => ({ ...state, calanderMode }));
  };

  const setDate = useCallback((newDate: Date | null) => {
    if (newDate)
      alertStore.update((state) => ({
        ...state,
        startDate: newDate.toISOString(),
      }));
  }, []);

  if (isMobileSize) return null;

  return (
    <ControlBox direction={isDesktopSize ? 'row' : 'column'}>
      <StyledStack $desktopSize={isDesktopSize} direction="row">
        <ReturnToSitesLink to={`/companies/${activeCompany?.id}/sites`}>
          <StyledButton variant="text">
            <IconLabel
              icon="chevron-left"
              variant="body1"
              label={
                <FormattedMessage
                  id="alert-history-back-to-sites-button"
                  description="Button to navigate back to the site page"
                  defaultMessage="Sites"
                />
              }
            />
          </StyledButton>
        </ReturnToSitesLink>
        <StyledAlertHistoryFilterDropdown />
      </StyledStack>
      <DatePickerContainer $desktopSize={isDesktopSize} direction="row">
        {!showCombinedDatepicker ? (
          <CombinedCalendarModePicker
            fullWidth
            calendarMode={alertFilterCalendarMode}
            value={date}
            onChange={setDate}
            onCalendarModeChange={setMode}
          />
        ) : (
          <>
            <AppTypography $bold $colorKey="lightText">
              <FormattedMessage
                id="common-viewing-label"
                defaultMessage="Viewing"
                description="Label for viewing"
              />
            </AppTypography>
            <CalendarModePicker
              value={alertFilterCalendarMode}
              onChange={setMode}
            />
            <AppDatePicker
              calendarMode={alertFilterCalendarMode}
              value={date}
              onChange={setDate}
            />
          </>
        )}
      </DatePickerContainer>
    </ControlBox>
  );
}
