import { Observable } from 'rxjs';

export function syncResult<T>(obs: Observable<T>) {
  let _value!: T;
  let _error: unknown;
  let _didNotComplete = true;
  obs.subscribe({
    next(value) {
      _value = value;
    },
    error(e) {
      _error = e;
    },
    complete() {
      _didNotComplete = false;
    },
  });
  if (_error) throw _error;
  if (_didNotComplete) {
    throw new Error(
      'observable did not complete syncronously.  sync result helpers can only be used on syncronous observables.  XHR, web sockets, timers, async schedulers will not work.'
    );
  }
  return _value;
}
