import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { RunningState } from './RunningState';

export function useIntlRunningState(): (runningState?: RunningState) => string {
  const intl = useIntl();
  const running = intl.formatMessage({
    id: 'running-state-running',
    defaultMessage: 'Running',
    description:
      'Status message that indicates a machine is in the running state',
  });
  const cleaning = intl.formatMessage({
    id: 'running-state-cleaning',
    defaultMessage: 'Cleaning',
    description:
      'Status message that indicates a machine is in the cleaning state',
  });
  const idle = intl.formatMessage({
    id: 'running-state-idle',
    defaultMessage: 'Idle',
    description: 'Status message that indicates a machine is in the idle state',
  });
  const off = intl.formatMessage({
    id: 'running-state-off',
    defaultMessage: 'Off',
    description: 'Status message that indicates a machine is off',
  });
  const regeneration = intl.formatMessage({
    id: 'running-state-regeneration',
    defaultMessage: 'Regeneration',
    description: 'Status message that indicates a machine is regenerating',
  });
  const sanitation = intl.formatMessage({
    id: 'running-state-sanitation',
    defaultMessage: 'Sanitation',
    description: 'Status message that indicates a cluster in sanitation state',
  });
  const caustic = intl.formatMessage({
    id: 'running-state-caustic',
    defaultMessage: 'Caustic',
    description: 'Status message that indicates a cluster in caustic state',
  });
  const enzymatic = intl.formatMessage({
    id: 'running-state-enzymatic',
    defaultMessage: 'Enzymatic',
    description: 'Status message that indicates a cluster in enzymatic state',
  });
  const NA = intl.formatMessage({
    id: 'common-not-applicable',
    defaultMessage: 'N/A',
    description: "Label for when there isn't a value to display",
  });

  return useCallback(
    (runningState?: RunningState) => {
      if (!runningState) return NA;
      const runningStateIntlRecord: Record<RunningState, string> = {
        [RunningState.Running]: running,
        [RunningState.Cleaning]: cleaning,
        [RunningState.Idle]: idle,
        [RunningState.Off]: off,
        [RunningState.Regeneration]: regeneration,
        [RunningState.Sanitation]: sanitation,
        [RunningState.Caustic]: caustic,
        [RunningState.Enzymatic]: enzymatic,
      };

      return runningStateIntlRecord[runningState];
    },
    [
      NA,
      running,
      cleaning,
      idle,
      off,
      regeneration,
      sanitation,
      caustic,
      enzymatic,
    ]
  );
}
