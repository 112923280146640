import { CalendarMode } from '@beeriot/api-client';
import { useIntlCalendarMode } from '../../enums';
import { getEnumFromString } from '../../utils';
import { AppToggleButtonGroupItem } from '../AppToggleButton';
import { AppToggleButtonGroup } from '../AppToggleButtonGroup';

const defaultCalendarModes = [
  CalendarMode.Day,
  CalendarMode.Week,
  CalendarMode.Month,
  CalendarMode.Year,
];

interface CalendarModePickerProps {
  value: CalendarMode;
  onChange: (data: CalendarMode) => void;
  overrideCalendarModes?: CalendarMode[];
  overrideLabelLocalizer?: (calendarMode?: CalendarMode) => string;
}

export function CalendarModePicker({
  value,
  onChange,
  overrideCalendarModes,
  overrideLabelLocalizer,
}: CalendarModePickerProps) {
  const getLabel = useIntlCalendarMode();
  const localizeLabel = overrideLabelLocalizer ?? getLabel;
  const calendarModes = overrideCalendarModes ?? defaultCalendarModes;
  const toggleItems: AppToggleButtonGroupItem[] = calendarModes.map((x) => ({
    value: x,
    label: localizeLabel(x),
  }));
  const selectedItem = toggleItems.find((x) => x.value === value);
  const didSelectItem = (item?: AppToggleButtonGroupItem) => {
    const calendarMode = getEnumFromString(CalendarMode, item?.value);
    if (calendarMode) onChange(calendarMode);
  };

  return (
    <AppToggleButtonGroup
      selectedItem={selectedItem}
      items={toggleItems}
      didSelectItem={didSelectItem}
    />
  );
}
