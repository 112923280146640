import { SystemStatsCategory } from '../../../enums';
import { GQLGetSystemStatsQuery } from '../../../generated/gql';
import { LineStatisticsGroup } from '../lineStatistics';
import { createComparableSection } from './createComparableSection';
import { processSystemStatsSection } from './processSystemStatsSection';

export function getCBSStatisticsGroups(
  processQuery?: GQLGetSystemStatsQuery,
  regenerationQuery?: GQLGetSystemStatsQuery
): LineStatisticsGroup[] {
  const process = createComparableSection(
    ['totalStabilizedBeer', 'averageFlow', 'degreeOfEfficiency', 'totalCIPS'],
    processQuery
  );
  const processGroup = processSystemStatsSection(
    SystemStatsCategory.ProcessData,
    process
  );

  const regeneration = createComparableSection(
    ['water', 'daWater', 'electrical', 'actualStabilizationCost'],
    regenerationQuery
  );
  const regenerationGroup = processSystemStatsSection(
    SystemStatsCategory.Regeneration,
    regeneration
  );

  const groups: LineStatisticsGroup[] = [processGroup, regenerationGroup];

  return groups;
}
