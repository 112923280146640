import { SystemStatsCategory } from '../../../enums';
import { GQLGetSystemStatsQuery } from '../../../generated/gql';
import { LineStatisticsGroup } from '../lineStatistics';
import { createComparableSection } from './createComparableSection';
import { processSystemStatsSection } from './processSystemStatsSection';

export function getKerafluxStatisticsGroups(
  processQuery?: GQLGetSystemStatsQuery,
  regenerationQuery?: GQLGetSystemStatsQuery
): LineStatisticsGroup[] {
  const process = createComparableSection(
    [
      'totalRecoveredBeer',
      'averageFlow',
      'degreeOfEfficiency',
      'tmpIncrease',
      'fluxRate',
      'diaFiltrationYield',
      'beerYield',
      'totalCIPS',
      'plato',
      'averageTemperature',
    ],
    processQuery
  );
  const processGroup = processSystemStatsSection(
    SystemStatsCategory.ProcessData,
    process
  );

  const cleaningConsumption = createComparableSection(
    ['water', 'caustic', 'nitricAcid', 'coolingEnergy'],
    regenerationQuery
  );
  const cleaningConsumptionGroup = processSystemStatsSection(
    SystemStatsCategory.CleaningConsumption,
    cleaningConsumption
  );

  const groups: LineStatisticsGroup[] = [
    processGroup,
    cleaningConsumptionGroup,
  ];

  return groups;
}
