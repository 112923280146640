import { filterNil, select } from '@ngneat/elf';
import { useEffect, useMemo, useRef } from 'react';
import {
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  useMutation,
  useQueryClient,
} from 'react-query';
import { Observable, from, of } from 'rxjs';
import { concatMap, map, take, toArray } from 'rxjs/operators';
import {
  CompanySite,
  CompanySiteApi,
  CompanySiteBase,
} from '../../generated/api';
import {
  GQLCompanySite,
  GQLGetCompanySiteQuery,
  GQLListCompanySitesQuery,
  useGetCompanySiteQuery,
  useInfiniteListCompanySitesQuery,
} from '../../generated/gql';
import { siteStore } from '../../state/site/siteStore';
import {
  defaultPagingParams,
  getNextPageParamHandler,
  AppQueryClient,
} from '../../utils';
import { appConfiguration } from '../configuration';
import equal from 'fast-deep-equal';
import { refetchIfPartialData, refetchIfPartialInfiniteData } from '../refetchIfPartialData';

export const siteApi = new CompanySiteApi(appConfiguration);
const queryKeyPrefix = 'listCompanySites.infinite';

export function useGQLSite(
  siteId?: string | null,
  options?:
    | UseQueryOptions<
        GQLGetCompanySiteQuery,
        unknown,
        GQLGetCompanySiteQuery,
        QueryKey
      >
    | undefined
): GQLCompanySite | undefined {
  const { data: site, isFetching, refetch } = useGetCompanySiteQuery(
    {
      siteId: siteId ?? '',
    },
    {
      // refetchInterval: 15000,
      enabled: !!siteId,
      suspense: false,
      ...(options ?? {}),
    }
  );

  // refetchIfPartialData(site, isFetching, refetch);
  return site?.getCompanySite ?? undefined;
}

export function useGQLSites(
  companyId?: string | null,
  groupId?: string | null,
  searchText?: string,
  opts?: UseInfiniteQueryOptions<GQLListCompanySitesQuery, unknown, GQLListCompanySitesQuery>
): {
  query: UseInfiniteQueryResult<GQLListCompanySitesQuery>;
  sortedData: GQLCompanySite[];
} {
  const oldId = useRef(companyId);
  useEffect(() => {
    if(companyId !== oldId.current) {
      oldId.current && AppQueryClient.cancelQueries([['listCompanySites.infinite', {companyId: oldId.current}]]);
      oldId.current = companyId;
    }
  }, [
    companyId,
  ])

  const q = useInfiniteListCompanySitesQuery(
    {
      companyId,
      group: groupId ?? '',
      name: searchText?.replace(/\s+/g, '+') ?? '',
      status: 'active',
      ...defaultPagingParams,
    },
    {
      enabled: !!companyId,
      // suspense: false,
      getNextPageParam: getNextPageParamHandler(
        (query) => query?.listCompanySites?.length
      ),
      staleTime: 60 * 1000,
      ...(opts ?? {}),
      
      
    }
  );
  const {
    data: sites,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    fetchNextPage,
    dataUpdatedAt,
    refetch
  } = q;

 

  const result = useMemo(() => {
    if (sites) {
      let obs!: Observable<GQLCompanySite>
      if(Array.isArray(sites)) {
      obs = from(sites as unknown as GQLCompanySite[]);
      } else {
        obs = of(sites)
        .pipe(
          concatMap((_sites) => from(_sites?.pages)),
          map((page) => page?.listCompanySites),
          filterNil(),
          concatMap((listSites) => from(listSites as GQLCompanySite[])),
          filterNil(),
          // map((system) => {
          //   return system;
          // }),
        );
      }
      const _gqlSystems = obs
        .pipe(
          toArray(),
          map((_systems) =>
            _systems.sort((a, b) =>
              (a?.name ?? '').localeCompare(b?.name ?? '')
            )
          )
        )
        .syncLastResult();
      return _gqlSystems;
    } else {
      return [];
    }
  }, [companyId, dataUpdatedAt, sites, sites?.pages, (sites as unknown as GQLCompanySite[])?.length]);

  useEffect(() => {
    let unmounted = false;
    const oldState = siteStore.getValue();

    if (!unmounted && !equal(oldState.sites, result)) {
      siteStore.update((state) => ({
        ...state,
        sites: result,

        unfilteredSitesCount:
          !searchText && !groupId ? result.length : state.unfilteredSitesCount,
      }));
    }
    return () => {
      unmounted = true;
    };
  }, [result]);

  // refetchIfPartialInfiniteData(sites, isFetching, refetch);

  // useEffect(() => {
  //   const oldState = siteStore.getValue()

  //   siteStore.update((state) => ({
  //     ...state,
  //     sites: result ?? state.sites,
  //     unfilteredSitesCount:
  //       !searchText && !groupId ? result.length : state.unfilteredSitesCount,
  //   }));
  // }, [result]);

  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage({cancelRefetch:true});
  }
  return {
    query: q,
    sortedData: result,
  };
}

interface UpdateSiteCallbacks {
  onSuccess: (data: CompanySite | undefined) => void;
  onError: (err: string) => void;
}

export function useUpdateCompanySite(
  companySiteId: string,
  { onSuccess, onError }: UpdateSiteCallbacks
) {
  const queryClient = useQueryClient();
  return useMutation(
    async (companySite: CompanySiteBase) => {
      const response = await siteApi.updateCompanySite(
        companySiteId,
        companySite
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(queryKeyPrefix);
        onSuccess(data);
      },
      onError,
    }
  );
}

export function useDeleteCompanySite(
  companySiteId: string,
  { onSuccess, onError }: UpdateSiteCallbacks
) {
  const queryClient = useQueryClient();
  return useMutation(
    async (companySite: CompanySiteBase) => {
      const response = await siteApi.updateCompanySite(companySiteId, {
        ...companySite,
        status: 'inactive',
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(queryKeyPrefix);
        onSuccess(data);
      },
      onError,
    }
  );
}

export function useUpdateSite(companyId?: string) {
  const queryClient = useQueryClient();

  return useMutation(
    async (site: CompanySite) => {
      const { id: siteId, ...baseSite } = site;
      const res = await siteApi.updateCompanySite(siteId, baseSite);
      return res.data;
    },
    {
      onMutate: async (data: CompanySite) => {
        await queryClient.cancelQueries(['sites', companyId]);
        const previousState = queryClient.getQueryData<CompanySite[]>([
          'sites',
          companyId,
          null,
        ]);

        let newState: CompanySite[] = [];
        if (previousState) {
          newState = [...previousState];
          if (newState) {
            const siteIndex = previousState?.findIndex((x) => x.id === data.id);
            if (siteIndex !== undefined && siteIndex > -1) {
              newState[siteIndex] = data;
            }
          }
        }
        queryClient.setQueryData(['sites', companyId], newState);
        return { previousState };
      },
      onError: (_err, _data, context) => {
        if (context?.previousState) {
          queryClient.setQueryData(['sites', companyId], context.previousState);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeyPrefix);
        queryClient.invalidateQueries(['groups', companyId]);
      },
    }
  );
}
