import { useIntl } from 'react-intl';
import { DrawerType } from '../../../../enums';
import { AppDrawer } from '../../../../base';
import { SuspenseExecSummarySiteSidebarMenu } from './SuspenseExecSummarySiteSidebarMenu';

export function ExecSummarySiteSidebarDrawer() {
  const intl = useIntl();
  const headerLabel = intl.formatMessage({
    id: 'common-site-overview-label',
    defaultMessage: 'Site Overview',
    description: 'Site overview label',
  });

  return (
    <AppDrawer
      type={DrawerType.ExecSummarySiteDetails}
      headerLabel={headerLabel}
      drawerContent={<SuspenseExecSummarySiteSidebarMenu />}
      $width="25rem"
    />
  );
}
