import { AWS_SPECIAL_CHARACTERS } from './aws-special-characters';

const MIN_PASSWORD_LENGTH = 10;

export function hasLowerCase(value: string) {
  return !!value && /[a-z]/.test(value);
}

export function hasUpperCase(value: string) {
  return !!value && /[A-Z]/.test(value);
}

export function meetsLengthRequirements(value: string) {
  return !!value && value?.length >= MIN_PASSWORD_LENGTH;
}

export function hasSpecialChar(value: string) {
  return !!value && AWS_SPECIAL_CHARACTERS.some((x) => value?.includes(x));
}

export function verifyPasswordsNotEmpty(
  password?: string,
  passwordVerify?: string
) {
  return (
    !!password && !!passwordVerify && password !== '' && passwordVerify !== ''
  );
}

export function verifyPasswordsMatch(
  password?: string,
  passwordVerify?: string
) {
  return (
    verifyPasswordsNotEmpty(password, passwordVerify) &&
    password === passwordVerify
  );
}

export function meetsPasswordRequirements(
  value: string,
  verificationValue?: string
): boolean | string {
  return (
    meetsLengthRequirements(value) &&
    hasLowerCase(value) &&
    hasUpperCase(value) &&
    hasSpecialChar(value) &&
    (verificationValue === undefined ||
      (verificationValue && verifyPasswordsMatch(value, verificationValue)))
  );
}
