import {
  setActiveSite,
  setActiveSystem,
  useActiveAppEntities,
} from '@beeriot/api-client';
import { Box, Stack, styled } from '@mui/material';
import { ControlBox } from '../../base';
import {
  Form,
  SelectOption,
  SuspenseSiteDropdown,
  SuspenseSystemDropdown,
} from '../../form';
import { useResponsiveSizes } from '../../utils';

const StyledForm = styled(Form)({
  display: 'flex',
  gap: '2rem',
  height: '100%',
  width: '100%',
}) as typeof Form;

interface SiteSystemSelectionFormFields {
  site: SelectOption;
  system: SelectOption;
}

export const SiteSystemSelectionHeader = () => {
  const { activeSite, activeSystem } = useActiveAppEntities();
  const { isSmallPhoneSize } = useResponsiveSizes();
  return (
    <ControlBox>
      <StyledForm<SiteSystemSelectionFormFields>
        submitOnChange
        onSubmit={(data) => {
          if (!!data.site && data.site.data.id !== activeSite?.id) {
            setActiveSite(data.site.data);
          }
          if (!!data.system && data.system.data.id !== activeSystem?.id) {
            setActiveSystem(data.system.data);
          }
        }}
      >
        <Stack
          flexDirection={isSmallPhoneSize ? 'column' : 'row'}
          sx={{ width: '100%' }}
        >
          <SuspenseSiteDropdown />
          <Box sx={{ padding: '0.5rem' }}></Box>
          <SuspenseSystemDropdown matchActive />
        </Stack>
      </StyledForm>
    </ControlBox>
  );
};
