import { styled, Stack } from '@mui/material';
import { SuspenseSiteCardList } from '../../../sites';
import { GlobalFooter, ParamIdReader } from '../../../base';
import { LineLegendFooter } from '../../LineLegendFooter';
import { BaseAlertsSidebarPage } from '../../BaseAlertsSidebarPage';
import { SitesControlBox } from './SitesControlBox';
import { useResponsiveSizes } from '../../../utils';

const SitesStack = styled(Stack)({
  overflow: 'auto',
  flex: '1 1 auto',
});

export function SitesPage() {
  const { isMobileSize } = useResponsiveSizes();
  return (
    <BaseAlertsSidebarPage header={<SitesControlBox />}>
      <ParamIdReader />
      <SitesStack>
        <SuspenseSiteCardList />
        <GlobalFooter
          topFooterComponent={isMobileSize ? null : <LineLegendFooter />}
        />
      </SitesStack>
    </BaseAlertsSidebarPage>
  );
}
