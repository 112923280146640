import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import { ColorKey } from '@beeriot/api-client';
import { transientOptions } from '../utils/transientOptions';
import { memo } from 'react';

export interface AppIconProps extends FontAwesomeIconProps {
  $colorKey?: ColorKey;
}

export const AppIcon = styled(
  memo(FontAwesomeIcon),
  transientOptions
)<AppIconProps>(({ theme, $colorKey }) => ({
  color: $colorKey ? theme.palette[$colorKey].main : 'inherit',
}));
