import { useIntl } from 'react-intl';
import { useObservable } from '@ngneat/use-observable';
import { activeSystem$ } from '@beeriot/api-client';
import {
  AppDrawer,
  useHasPermission,
  PermissionKey,
  PermissionView,
} from '../../base';
import { DrawerType } from '../../enums';
import { SuspenseLineSidebarMenu } from './SuspenseLineSidebarMenu';

export function LineSidebarDrawer() {
  const [activeSystem] = useObservable(activeSystem$);
  const intl = useIntl();
  const headerLabel = intl.formatMessage({
    id: 'common-line-status-label',
    defaultMessage: 'Line Status',
    description: 'Line status label',
  });

  const hasPermission = useHasPermission();

  let toggleHidePermissions: PermissionKey;
  let lineStatusDrawerPermissions: PermissionKey;
  switch (activeSystem?.type) {
    case 'profi':
      lineStatusDrawerPermissions = PermissionKey.SitesPROFiLineStatusWidget;
      toggleHidePermissions = PermissionKey.SitesPROFiLineStatusWidgetHide;
      break;
    case 'cbs':
      lineStatusDrawerPermissions = PermissionKey.SitesCBSLineStatusWidget;
      toggleHidePermissions = PermissionKey.SitesCBSLineStatusWidgetHide;
      break;
    case 'cfs':
      lineStatusDrawerPermissions = PermissionKey.SitesCFSLineStatusWidget;
      toggleHidePermissions = PermissionKey.SitesCFSLineStatusWidgetHide;
      break;
    case 'keraflux':
      lineStatusDrawerPermissions = PermissionKey.SitesKerafluxLineStatusWidget;
      toggleHidePermissions = PermissionKey.SitesKerafluxLineStatusWidgetHide;
      break;

    default:
      lineStatusDrawerPermissions = PermissionKey.SitesPROFiLineStatusWidget;
      toggleHidePermissions = PermissionKey.SitesPROFiLineStatusWidgetHide;
      break;
  }
  return (
    <PermissionView permissionKey={lineStatusDrawerPermissions}>
      <AppDrawer
        type={DrawerType.LineDetails}
        headerLabel={headerLabel}
        hideToggleButton={!hasPermission(toggleHidePermissions)}
        drawerContent={<SuspenseLineSidebarMenu />}
        $width="25rem"
      />
    </PermissionView>
  );
}
