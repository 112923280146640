import { SystemKpiHookProps } from './SystemKpiHookProps';

export function useShouldRenderKpi({
  activeKpis,
  gridArea,
}: Pick<SystemKpiHookProps, 'activeKpis' | 'gridArea'>) {
  if (activeKpis) {
    if (activeKpis[gridArea]) return true;
  }
  return false;
}
