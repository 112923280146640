import { SuspenseGroupTypeList } from './SuspenseGroupTypeList';
import { AppDrawer } from '../../../base';
import { DrawerType } from '../../../enums';

export function GroupTypeListDrawer() {
  return (
    <AppDrawer
      type={DrawerType.GroupTypeList}
      drawerContent={<SuspenseGroupTypeList />}
    />
  );
}
