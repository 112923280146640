import { parseISO, addDays, differenceInDays } from 'date-fns';

const MS_IN_HR = 3600000;
const MS_IN_MIN = 60000;

export const getTimeSince = (timeStr?: string | null) => {
  if (!timeStr) return undefined;
  const time = parseISO(timeStr).getTime();
  const currentTime = new Date().getTime();
  const duration = currentTime - time;
  if (duration < 0) return undefined;
  const totalHours = Math.floor(duration / MS_IN_HR);
  const minutes = Math.floor((duration % MS_IN_HR) / MS_IN_MIN);
  const timeSinceStart = `${totalHours}h ${minutes}m`;
  return timeSinceStart;
};

export const getDaysLeft = (
  startDate: Date | null,
  numDaysTilExpiration: number
) => {
  if (!startDate) return null;
  const expirationDate = addDays(startDate, numDaysTilExpiration);
  const numDaysLeft = differenceInDays(expirationDate, new Date());
  return numDaysLeft;
};
