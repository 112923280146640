import { ReactNode } from 'react';
import { Property } from 'csstype';
import { Box, styled } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { ColorKey } from '@beeriot/api-client';
import { transientOptions } from '../utils';
import { AppMedallion } from './AppMedallion';
import { AppTypography } from './AppTypography';

const StyledContainer = styled(
  Box,
  transientOptions
)<{ $gap?: Property.Gap; $useResponsiveSizing?: boolean }>(({ $gap }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  gap: $gap ?? '0.5rem',
}));

export interface LabeledBadgeProps {
  label?: ReactNode;
  labelVariant?: Variant;
  labelColorKey?: ColorKey;
  badgeColorKey?: ColorKey;
  badgeContent?: ReactNode;
  $gap?: Property.Gap;
}

export function LabeledBadge({
  label,
  labelVariant = 'body1',
  labelColorKey = 'lightText',
  badgeColorKey,
  badgeContent,
  $gap,
}: LabeledBadgeProps) {
  return (
    <StyledContainer $gap={$gap}>
      <AppMedallion colorKey={badgeColorKey}>{badgeContent}</AppMedallion>
      <AppTypography $bold variant={labelVariant} $colorKey={labelColorKey}>
        {label}
      </AppTypography>
    </StyledContainer>
  );
}
