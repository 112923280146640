import {
  ReportDefinitionBase,
  ReportDefinitionBaseAggregationEnum,
  ReportDefinitionBaseFrequencyEnum,
  ReportDefinitionBaseReportTypeEnum,
  defaultFrequencyAggregationRecord,
  useActiveAppEntities,
  useAddReportDefinition,
  useGenerateReport,
  useRefetchReportDefinitions,
} from '@beeriot/api-client';
import { Dialog, DialogTitle, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import {
  NewRecurringReportDialogContent,
  systemIdString,
} from './NewRecurringReportDialogContent';
import { getEnumFromString } from '../../utils';

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.componentBase.main,
  borderTopRightRadius: theme.shape.borderRadius,
  borderTopLeftRadius: theme.shape.borderRadius,
}));

interface NewRecurringReportDialogProps {
  open: boolean;
  onClose: () => void;
}

export function NewRecurringReportDialog({
  open,
  onClose,
}: NewRecurringReportDialogProps) {
  const { activeCompany, activeSite, gqlSystems } = useActiveAppEntities();
  const refetchReportDefinitions = useRefetchReportDefinitions();
  const addRecurringReport = useAddReportDefinition(
    (data) => {
      console.log('addRecurringReport success', data);
      refetchReportDefinitions();
    },
    (err) => {
      console.log('addRecurringReport error', err);
    }
  );
  const generateReport = useGenerateReport(
    (data) => {
      console.log('generate report success', data);
    },
    (err) => {
      console.log('generate report error', err);
    }
  );

  return (
    <Dialog open={open} maxWidth="md">
      <StyledDialogTitle>
        <FormattedMessage
          id="reports-new-report-label"
          description="Label for new report"
          defaultMessage="New Report"
        />
      </StyledDialogTitle>
      <NewRecurringReportDialogContent
        onClose={onClose}
        onSubmit={(data) => {
          const reportType = getEnumFromString(
            ReportDefinitionBaseReportTypeEnum,
            data.type.value
          );
          if (!reportType)
            throw new Error(
              `Unexpected report type encountered ${data.type.value}`
            );
          const reportFrequency = getEnumFromString(
            ReportDefinitionBaseFrequencyEnum,
            data.frequency.value
          );
          if (!reportFrequency)
            throw new Error(
              `Unexpected report frequency encountered ${data.frequency.value}`
            );
          const reportAggregation =
            getEnumFromString(
              ReportDefinitionBaseAggregationEnum,
              data.aggregation?.value
            ) ??
            defaultFrequencyAggregationRecord[reportFrequency] ??
            'daily';
          const reportDefinition: ReportDefinitionBase = {
            companyId: activeCompany?.id ?? '',
            reportName: data.name ?? '',
            reportType: reportType,
            frequency: reportFrequency,
            startDate: data.startDate,
            endDate: data.endDate,
            aggregation: reportAggregation,
            systems: gqlSystems
              ?.filter((x) => {
                const key: systemIdString = `systemId-${x.id}`;
                const checked = data[key];
                return checked;
              })
              .map((x) => ({
                systemId: x.id,
                companySiteId: activeSite?.id ?? '',
              })) ?? [],
          };
          addRecurringReport.mutate(reportDefinition);
          if (reportFrequency === 'One-Time') {
            generateReport.mutate(reportDefinition);
          }
        }}
        lines={gqlSystems}
        mutation={addRecurringReport}
      />
    </Dialog>
  );
}
