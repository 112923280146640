import { lazy, Suspense } from 'react';
import { FormattedMessage } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { styled } from '@mui/material';
import { IconLabel, LoadingBox } from '../../base';

const TableLoadingBox = styled(LoadingBox)({
  height: '100%',
  minHeight: '20rem',
  width: '100%',
  borderRadius: '0 0 0.5rem 0.5rem',
});

const UserManagementSettings = lazy(() =>
  import('./UserManagementSettingsListQuery').then(
    ({ UserManagementSettingsListQuery }) => ({
      default: UserManagementSettingsListQuery,
    })
  )
);

export function SuspenseUserManagementSettingsList() {
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <IconLabel
                label={
                  <FormattedMessage
                    id="settings-user-management-error-label"
                    description="Error message for user management table"
                    defaultMessage="Users Unavailable"
                  />
                }
              />
            );
          }}
        >
          <Suspense fallback={<TableLoadingBox />}>
            <UserManagementSettings />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
