import { RegisterOptions } from 'react-hook-form';
import { InputBase, InputBaseProps, Stack, styled } from '@mui/material';
import { useValidationRules } from '../../../form/useValidationRules';

const TextInputContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  padding: '0.5rem',
  borderRadius: '0.5rem',
  border: 'none',
  marginLeft: '1rem',
  fontFamily: theme.typography.fontFamily,
}));

const StyledTextInput = styled(InputBase)({
  flexGrow: 1,
  fontWeight: 'bold',
});

export interface SectionTextInputProps extends InputBaseProps {
  name: string;
  className?: string;
  validationOptions?:
    | Partial<RegisterOptions>
    | ((values: Record<string, unknown>) => Partial<RegisterOptions>);
}

export function SectionTextInput({
  name,
  className,
  validationOptions,
  type,
  placeholder,
  required,
  disabled,
  ...inputProps
}: SectionTextInputProps) {
  const { rules, register } = useValidationRules(required, validationOptions);

  return (
    <TextInputContainer>
      <StyledTextInput
        {...inputProps}
        {...register(name, rules)}
        type={type}
        disabled={disabled ?? false}
        className={className}
        placeholder={placeholder}
      />
    </TextInputContainer>
  );
}
