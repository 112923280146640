import { useState } from 'react';
import { useChangePassword, userInfo$ } from '@beeriot/api-client';
import { useIntlError } from '@beeriot/api-client';
import { ChangePassword } from '../../settings/change-password/ChangePassword';
import { SettingsPage } from './SettingsPage';
import { useObservable } from '@ngneat/use-observable';

export function ChangePasswordPage() {
  const [error, setError] = useState<string | undefined>();
  const intlError = useIntlError();
  const [user] = useObservable(userInfo$);

  const mutation = useChangePassword(
    user?.email ?? '',
    () => {
      setError('');
    },
    (err) => {
      setError(intlError(err?.message));
    }
  );

  return (
    <SettingsPage>
      <ChangePassword mutation={mutation} error={error} />
    </SettingsPage>
  );
}
