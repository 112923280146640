import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ReportFrequencyEnum } from '@beeriot/api-client';

export function useIntlReportFrequency(): (
  reportFrequency?: ReportFrequencyEnum
) => string {
  const intl = useIntl();
  const oneTime = intl.formatMessage({
    id: 'common-one-time-label',
    defaultMessage: 'One-Time',
    description: 'Label for a one-time event',
  });
  const daily = intl.formatMessage({
    id: 'common-daily',
    defaultMessage: 'Daily',
    description: 'Label for a daily event',
  });
  const weekly = intl.formatMessage({
    id: 'common-weekly',
    defaultMessage: 'Weekly',
    description: 'Label for a weekly event',
  });
  const monthly = intl.formatMessage({
    id: 'common-monthly',
    defaultMessage: 'Monthly',
    description: 'Label for a monthly event',
  });
  const yearly = intl.formatMessage({
    id: 'common-yearly',
    defaultMessage: 'Yearly',
    description: 'Label for a yearly event',
  });

  return useCallback(
    (reportFrequency?: ReportFrequencyEnum) => {
      if (!reportFrequency) return '-';

      const reportFrequencyIntlRecord: Record<ReportFrequencyEnum, string> = {
        [ReportFrequencyEnum.OneTime]: oneTime,
        [ReportFrequencyEnum.Daily]: daily,
        [ReportFrequencyEnum.Weekly]: weekly,
        [ReportFrequencyEnum.Monthly]: monthly,
        [ReportFrequencyEnum.Yearly]: yearly,
      };

      return reportFrequencyIntlRecord[reportFrequency];
    },
    [daily, monthly, oneTime, weekly, yearly]
  );
}
