import { select } from '@ngneat/elf';
import { shareReplay } from 'rxjs';
import { blockStore } from './blockStore';

export const activeBlock$ = blockStore.pipe(
  select((state) => {
    return state.activeBlock;
  })
  // shareReplay({ refCount: false, bufferSize: 1 })
);
