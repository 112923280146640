import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ReportTypeEnum } from '@beeriot/api-client';

export function useIntlReportType(): (
  reportFrequency?: ReportTypeEnum
) => string {
  const intl = useIntl();
  const consumptionReport = intl.formatMessage({
    id: 'report-type-consumption-report-label',
    defaultMessage: 'Consumption Report',
    description: 'Label for a consumption report',
  });
  const costReport = intl.formatMessage({
    id: 'report-type-cost-report-label',
    defaultMessage: 'Cost Report',
    description: 'Label for a cost report',
  });
  const performanceReport = intl.formatMessage({
    id: 'report-type-performance-report-label',
    defaultMessage: 'Performance Report',
    description: 'Label for a performance report',
  });
  const qualityReport = intl.formatMessage({
    id: 'report-type-quality-report-label',
    defaultMessage: 'Quality Report',
    description: 'Label for a quality report',
  });
  const moduleManagementReport = intl.formatMessage({
    id: 'report-type-module-management-report-label',
    defaultMessage: 'Module Management Report',
    description: 'Label for a module management report',
  });
  const serviceReport = intl.formatMessage({
    id: 'report-type-service-report-label',
    defaultMessage: 'Service Report',
    description: 'Label for a service report',
  });

  return useCallback(
    (reportType?: ReportTypeEnum) => {
      if (!reportType) return '-';

      const reportTypeIntlRecord: Record<ReportTypeEnum, string> = {
        [ReportTypeEnum.ConsumptionReport]: consumptionReport,
        [ReportTypeEnum.CostReport]: costReport,
        [ReportTypeEnum.PerformanceReport]: performanceReport,
        [ReportTypeEnum.QualityReport]: qualityReport,
        [ReportTypeEnum.ModuleManagementReport]: moduleManagementReport,
        [ReportTypeEnum.ServiceReport]: serviceReport,
      };

      return reportTypeIntlRecord[reportType];
    },
    [
      consumptionReport,
      costReport,
      moduleManagementReport,
      performanceReport,
      qualityReport,
      serviceReport,
    ]
  );
}
