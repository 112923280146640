import { BeerTypesSettingsPanel } from '../../settings';
import { SettingsPage } from './SettingsPage';

export function BeerTypesPage() {
  return (
    <SettingsPage>
      <BeerTypesSettingsPanel />
    </SettingsPage>
  );
}
