import { createStore, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { GQLCompany } from '../../generated/gql';

export interface CompanyProps {
  activeCompany: GQLCompany | null;
  companySearch: string;

  companies: GQLCompany[];
  filteredSitesCount?: number;
}

export const companyStore = createStore(
  { name: 'company' },
  withProps<CompanyProps>({
    activeCompany: null,
    companySearch: '',
    companies: [],
  })
);

persistState(companyStore, {
  key: 'company',
  storage: sessionStorageStrategy,
});
