import { useIntl } from 'react-intl';
import { DialogTitle, styled, Stack } from '@mui/material';

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.componentBase.main,
  borderBottom: `1px solid ${theme.palette.componentShade.main}`,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
}));

export interface AddGroupDialogHeaderProps {
  currentStep: number;
}

export function AddGroupDialogHeader({
  currentStep,
}: AddGroupDialogHeaderProps) {
  const intl = useIntl();
  const groupNameDialogTitle = intl.formatMessage({
    id: 'organize-sites-group-list-new-group',
    description: 'New group label',
    defaultMessage: 'New Group',
  });
  const addSitesdialogTitle = intl.formatMessage({
    id: 'organize-sites-add-new-group-select-sites-title',
    description: 'Dialog title for add sites form',
    defaultMessage: 'New Group - Select Sites',
  });

  return (
    <StyledDialogTitle>
      <Stack direction="row">
        {currentStep === 0 ? groupNameDialogTitle : addSitesdialogTitle}
      </Stack>
    </StyledDialogTitle>
  );
}
