import { SettingsPage } from './SettingsPage';
import { UserManagementSettingsPanel } from '../../settings/user-management';

export function UserManagementSettingsPage() {
  return (
    <SettingsPage>
      <UserManagementSettingsPanel />
    </SettingsPage>
  );
}
