import axios from 'axios';
import { appConfiguration } from '../lib/configuration';
import { authStore, maxRetries } from '../state/auth/authStore';
import { getRefreshToken } from './getRefreshToken';
import { getRegistry } from '@ngneat/elf';
import { QueryClient } from 'react-query';

export function setupAxiosInterceptors(queryClient: QueryClient) {
  const logout = () => {
    getRegistry().forEach((store) => store.reset());
    queryClient.clear();
  };

  axios.interceptors.response.use(
    (response) => {
      // Clear retries for requestKey
      const requestKey = JSON.stringify({
        url: response?.config?.url,
        data: response?.config?.data,
        method: response?.config?.method,
      });
      const state = authStore.getValue();
      const retryStatus = state.retryStatus.find(
        (x) => x.requestKey === requestKey
      );

      if (retryStatus !== undefined) {
        authStore.update((state) => {
          return {
            ...state,
            retryStatus: [...state.retryStatus].filter(
              (x) => x.requestKey != requestKey
            ),
          };
        });
      }

      return response;
    },
    (error) => {
      const status = error.response ? error.response.status : null;

      if (status === 401 || status === 403) {
        const state = authStore.getValue();
        const requestKey = JSON.stringify({
          url: error.config.url,
          data: error.config.data,
          method: error.config.method,
        });
        const retryStatusIdx = state.retryStatus.findIndex(
          (x) => x.requestKey === requestKey
        );
        const retryStatus =
          retryStatusIdx != -1 ? state.retryStatus[retryStatusIdx] : undefined;

        if (state.refreshToken == null) {
          logout();
          throw new Error(
            'Missing refresh token. Automatically log the user out'
          );
        }
        if (retryStatus !== undefined && retryStatus?.retryCount > maxRetries) {
          logout();
          throw new Error(
            `Reached max number of request retries refreshing access token for request: \n\n${requestKey}`
          );
        }

        return getRefreshToken().then(
          () => {
            const state = authStore.getValue();
            const accessToken = state.accessToken;
            if (!accessToken) {
              throw new Error('Missing auth state');
            }

            // Update appConfiguration
            appConfiguration.accessToken = accessToken;
            error.config.headers['Authorization'] = `Bearer ${accessToken}`;

            // Update retryCount
            authStore.update((state) => {
              const newRetryStatus = [...state.retryStatus];
              if (retryStatusIdx != -1) {
                const foundRetryStatus = newRetryStatus[retryStatusIdx];
                newRetryStatus[retryStatusIdx] = {
                  requestKey: foundRetryStatus.requestKey,
                  retryCount: foundRetryStatus.retryCount + 1,
                };
              } else {
                newRetryStatus.push({
                  requestKey,
                  retryCount: 0,
                });
              }

              return {
                ...state,
                retryStatus: newRetryStatus,
              };
            });

            return axios.request(error.config);
          },
          () => logout()
        );
      }

      return Promise.reject(error);
    }
  );
}
