import { useMemo } from 'react';
import { GQLSystem } from '@beeriot/api-client';
import {
  SystemKpiGridAreaKey,
  SystemKpiGridAreaTemplate,
  SystemKpiHookProps,
  SystemKpiKeyMap,
} from './SystemKpiHookProps';
import { IntlShape } from 'react-intl';
import { from, of } from 'rxjs';
import { concatMap, distinct, reduce, filter } from 'rxjs/operators';

export function useSystemKpiHookDefaultProps(
  props: {
    intl: IntlShape;
    system: GQLSystem | undefined;
  } & Partial<SystemKpiHookProps>
) {
  const {
    intl,
    system,
    isMobileSize,
    isSmallTabletSize,
    isTabletSize,
    isDesktopSize,
    //
    gridTemplateAreas,
  } = props;
  return useMemo(() => {
    const NA = intl.formatMessage({
      id: 'common-not-applicable',
      defaultMessage: 'N/A',
      description: "Label for when there isn't a value to display",
    });
    const _areas =
      typeof gridTemplateAreas !== 'string'
        ? normalizeGridTemplate(gridTemplateAreas ?? [])
        : gridTemplateAreas;

    const actives = activeKeysForGridTemplate(_areas);

    return {
      system,
      intl,
      NA,
      isMobileSize,
      isSmallTabletSize,
      isTabletSize,
      isDesktopSize,
      activeKpis: actives,
      gridTemplateAreas: _areas,
      boldLabelTitles: props.boldLabelTitles,
    } as SystemKpiHookProps;
  }, [
    intl,
    gridTemplateAreas,
    system,
    isMobileSize,
    isSmallTabletSize,
    isTabletSize,
    isDesktopSize,
    props.boldLabelTitles,
  ]);
}

/**
 *
 * @param gridTemplate a gridTemplateArea represented as a string,
 * array of row strings, or an 2-D array of GridArea keys
 * @returns a normalized single string version of the grid template
 */
export function normalizeGridTemplate(
  gridTemplate: SystemKpiGridAreaTemplate | string[] | string
): string {
  // if its already a single string then just no-op
  if (typeof gridTemplate === 'string') {
    return gridTemplate;
  } else {
    const first = gridTemplate[0];
    // if its an array, and the first element is a string,
    //  then the element is an array of row strings, strip
    //  out any quotes and build a new single string grid template
    if (first && typeof first !== 'string') {
      return (gridTemplate as unknown as SystemKpiGridAreaTemplate)
        .map((x) => `"${x.join(' ').split('"').join('')}"`)
        .join('\n');
    } else if (first) {
      // if its an array of arrays of single names, join them into
      // a new single grid template string
      return (gridTemplate as unknown as SystemKpiGridAreaTemplate)
        .map((x) => `"${(x as unknown as string).split('"').join('')}"`)
        .join('\n');
    } else {
      return '';
    }
  }
}

/**
 *
 * @param gridTemplate a gridTemplateArea string
 * @returns a `Record<GridAreaKey, boolean>` map of GridArea names/keys that were found in the template string.  can be used to match for which keys have been referenced
 */
function activeKeysForGridTemplate(gridTemplate: string): SystemKpiKeyMap {
  const keyMap = of(gridTemplate)
    .pipe(
      // split on new lines, quotes, spaces
      concatMap((x) => {
        // console.log(x);
        const keys = x
          .split('"')
          .join(' ')
          .split('\n')
          .join(' ')
          .split(' ') as string[];
        return from(keys).pipe(filter((x) => !!x && x !== ''));
      }),

      distinct(),
      reduce((acc, curr) => {
        acc[curr as SystemKpiGridAreaKey] = true;
        return acc;
      }, {} as SystemKpiKeyMap)
    )
    .syncFirstResult();

  return keyMap;
}
