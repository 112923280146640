import {
  GQLReportDefinition,
  Brand,
  GQLUser,
  activeEntities$,
  GQLCompany,
  GQLBlock,
  GQLSystem,
  GQLCompanySite,
  SiteGroup,
  Area,
} from '@beeriot/api-client';
import { useObservable } from '@ngneat/use-observable';

import { map, shareReplay, throttleTime } from 'rxjs/operators';

type AppRoutePathsType = {
  activeCompany: GQLCompany | null;
  activeSite: GQLCompanySite | null;
  activeSystem: GQLSystem | null;
  activeBlock: GQLBlock | null;
  companiesListPath: string;
  companyAlertsPath: string;
  sitesListPath: string;
  siteDetailsPath: string;
  siteDetailsPathForSite: (site: GQLCompanySite) => string;
  siteAlertsPath: string;


  systemLineStatusPathForSystem:(system: GQLSystem) => string;
  systemStatisticsPathForSystem: (system: GQLSystem) => string;
  systemFilterRunsPathForSystem: (system: GQLSystem) => string;
  systemDocumentationPathForSystem: (system: GQLSystem) => string;
  systemReportsPathForSystem:(system: GQLSystem) => string;

  systemLineStatusPath: string;
  systemStatisticsPath: string;
  systemFilterRunsPath: string;
  systemDocumentationPath: string;
  systemReportsPath: string;
  siteExecSummaryPath: string;
  siteExecSummaryPathForSite: (site: GQLCompanySite) => string;
  systemExecSummaryPath: string;
  systemExecSummaryPathForSystem: (system: GQLSystem) => string;
  settingsAccountPath: string;
  settingsUserManagementPath: string;
  settingsNewUserPath: string;
  settingsEditUserPath: (user: GQLUser) => string;
  settingsBeerBrandsPath: string;
  settingsEditBeerBrandPath: (brand: Brand) => string;
  settingsReportingPath: string;
  settingsNewReportPath: string;
  settingsEditReportPathForReport: (
    automaticReport?: GQLReportDefinition
  ) => string;
};

const defaultAppRoutePaths: AppRoutePathsType = {
  activeCompany: null,
  activeSite: null,
  activeSystem: null,
  activeBlock: null,
  companiesListPath: '',
  companyAlertsPath: '',
  sitesListPath: '',
  siteDetailsPath: '',
  siteAlertsPath: '',
  systemLineStatusPath: '',
  systemStatisticsPath: '',
  systemFilterRunsPath: '',
  systemDocumentationPath: '',
  systemReportsPath: '',
  systemLineStatusPathForSystem:(system: GQLSystem) => "",
  systemStatisticsPathForSystem: (system: GQLSystem) => "",
  systemFilterRunsPathForSystem: (system: GQLSystem) => "",
  systemDocumentationPathForSystem:(system: GQLSystem) => "",
  systemReportsPathForSystem:(system: GQLSystem) => "",
  siteExecSummaryPath: '',
  systemExecSummaryPath: '',
  settingsAccountPath: '',
  settingsUserManagementPath: '',
  settingsNewUserPath: '',
  settingsEditUserPath: function (user: GQLUser): string {
    return '';
  },
  settingsBeerBrandsPath: '',
  settingsEditBeerBrandPath: function (brand: Brand): string {
    return '';
  },
  settingsReportingPath: '',
  settingsNewReportPath: '',
  settingsEditReportPathForReport: function (
    automaticReport?: GQLReportDefinition | undefined
  ): string {
    return '';
  },
  siteExecSummaryPathForSite: function (site: GQLCompanySite): string {
    return '';
  },
  systemExecSummaryPathForSystem: function (system: GQLSystem): string {
    return '';
  },
  siteDetailsPathForSite: function (site: GQLCompanySite): string {
    return '';
  },
};

export const appRoutePathsForActiveEntities = ({
  activeCompany,
  activeSite,
  activeSystem,
  activeBlock,
}: {
  activeCompany: GQLCompany | null;
  activeSite: GQLCompanySite | null;
  activeSystem: GQLSystem | null;
  activeBlock: GQLBlock | null;
  sites: GQLCompanySite[] | null;
  groupedSites:  SiteGroup[] | null;
  areas:Area[] | null;
  areaSystems:
    | never[]
    | null
    | undefined
    | {
        area: Area;
        systems: GQLSystem[];
      }[];
  gqlSystems: never[]
  | null
  | undefined | GQLSystem[];
}): AppRoutePathsType => {
  const activeCompanyFragment = `/companies/${activeCompany?.id ?? ''}`;
  const siteFragment = `${activeCompanyFragment}/sites/${activeSite?.id ?? ''}`;
  const settingsFragment = `${activeCompanyFragment}/settings`;
  const systemFragment = `${siteFragment}/lines/${activeSystem?.id ?? ''}`;
  const companyExecSummaryFragment = `/companies/${
    activeCompany?.id ?? ''
  }/exec-summary/${activeSite?.id ?? ''}`;

  const paths: AppRoutePathsType = {
    activeCompany,
    activeSite,
    activeSystem,
    activeBlock,
    companiesListPath: `/companies`,
    companyAlertsPath: `${activeCompanyFragment}/alert-history`,
    // sites
    sitesListPath: `${activeCompanyFragment}/sites`,
    siteDetailsPath: `${siteFragment}}/overview`,
    siteAlertsPath: `${siteFragment}}/alert-history`,
    // systems
    systemLineStatusPath: `${systemFragment}/line-status`,
    systemLineStatusPathForSystem: (system: GQLSystem) =>
    `${siteFragment}/lines/${system?.id}/line-status`,
    systemStatisticsPath: `${systemFragment}/statistics`,
    systemStatisticsPathForSystem: (system: GQLSystem) =>
    `${siteFragment}/lines/${system?.id}/statistics`,
    systemFilterRunsPath: `${systemFragment}/filter-runs`,
    systemFilterRunsPathForSystem: (system: GQLSystem) =>
    `${siteFragment}/lines/${system?.id}/filter-runs`,
    systemDocumentationPath: `${systemFragment}/documentation`,
    systemDocumentationPathForSystem: (system: GQLSystem) =>
    `${siteFragment}/lines/${system?.id}/documentation`,
    systemReportsPath: `${systemFragment}/reports`,
    systemReportsPathForSystem: (system: GQLSystem) =>
    `${siteFragment}/lines/${system?.id}/reports`,
    // exec-summary
    siteExecSummaryPath: `${companyExecSummaryFragment}/summary`,
    systemExecSummaryPath: `${companyExecSummaryFragment}/lines/${activeSystem?.id}/summary`,
    // settings
    settingsAccountPath: `${settingsFragment}/account`,
    // settings - user management
    settingsUserManagementPath: `${settingsFragment}/user-management`,
    settingsNewUserPath: `${settingsFragment}/user-management-details/add-user`,
    settingsEditUserPath: (user: GQLUser) =>
      `${settingsFragment}/user-management-details/edit-user/${
        user?.userId ?? ''
      }`,
    // settings - beer brands
    settingsBeerBrandsPath: `${settingsFragment}/beer-brands`,
    settingsEditBeerBrandPath: (brand: Brand) =>
      `${settingsFragment}//beer-brands-details/edit-beer-brand/${
        brand?.id ?? ''
      }`,

    // settings - reports routes
    settingsReportingPath: `${settingsFragment}/reporting`,
    settingsNewReportPath: `${settingsFragment}/automatic-report-details/new-automatic-report`,
    settingsEditReportPathForReport: (automaticReport?: GQLReportDefinition) =>
      `${settingsFragment}/automatic-report-details/edit-automatic-report/${
        automaticReport?.id ?? ''
      }`,
    siteExecSummaryPathForSite: (site: GQLCompanySite) =>
      `/companies/${site?.companyId ?? ''}/exec-summary/${
        site?.id ?? ''
      }/summary`,
    systemExecSummaryPathForSystem: (system: GQLSystem) =>
      `/companies/${system?.companyId ?? ''}/exec-summary/${
        system?.companySiteId ?? ''
      }/lines/${system?.id}/summary`,
    siteDetailsPathForSite: (site: GQLCompanySite) =>
      `/companies/${site?.companyId ?? ''}/sites/${site?.id ?? ''}/overview`,
  };
  return paths;
};
export const appRoutePaths$ = activeEntities$.pipe(
  throttleTime(15, undefined, {leading: true, trailing: true}),
  map(appRoutePathsForActiveEntities),
  shareReplay({refCount: true, bufferSize: 1})
);

export const useAppRoutePaths = () => {
  const [paths] = useObservable(appRoutePaths$, defaultAppRoutePaths);
  return paths;
};
