import { StyleSheet } from '@react-pdf/renderer';

const tableBorderColor = '#ffffff';

export const reportStyles = StyleSheet.create({
  page: {
    padding: 45,
  },
  graphPageTitle: {
    marginBottom: 30,
  },
  coverHeader: {
    fontSize: 22,
    color: '#636262',
    flexDirection: 'row',
    alignItems: 'center',
  },
  coverPageText: {
    marginTop: 250,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    width: 300,
    fontSize: 14,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    right: 30,
    textAlign: 'right',
    color: '#636262',
  },
  table: {
    padding: 0,
  },
  tableBox: {
    flex: 1,
    padding: 3,
    borderRight: 1,
    borderColor: tableBorderColor,
  },
  lastTableBox: {
    borderRight: 0,
  },
  tableRow: {
    fontSize: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    textAlign: 'center',
    borderBottom: 1,
    borderColor: tableBorderColor,
  },
  tableHeaderRow: {
    backgroundColor: '#c8d4e3',
  },
  tableDataRow: {
    backgroundColor: '#ebf0f8',
  },
  tableOfContentsTitle: {
    fontSize: 22,
    marginBottom: 40,
  },
  reportSummaryTitle: {
    fontSize: 22,
    marginBottom: 40,
  },
  tableOfContentsRow: {
    marginBottom: 20,
    flexDirection: 'row',
  },
  tableOfContentsSpacer: {
    flex: 1,
    borderBottom: 2,
    borderBottomStyle: 'dotted',
  },
  comparableKPICard: {
    alignItems: 'center',
    flexDirection: 'row',
    padding: 12,
    backgroundColor: '#f4f5f6',
    borderRadius: 12,
    marginBottom: 20,
  },
  comparableKPITitle: {
    marginBottom: 10,
    fontSize: 12,
  },
  comparableKPIEntry: {
    fontSize: 11,
  },
  kpiPercentageDeltaContainer: {
    width: 70,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  kpiPercentageDeltaIcon: {
    marginRight: 'auto',
  },
  kpiPercentageDelta: {
    marginLeft: 'auto',
    fontSize: 15,
  },
});
