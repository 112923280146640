import { UseQueryResult, useMutation, useQuery, useQueryClient } from 'react-query';
import { ExportApi, TelemetryDataExport, TelemetryDataExportExportStatusEnum } from '../generated/api';
import { appConfiguration } from './configuration';
import { useRefetchReportDefinitions } from './lineReports';
import { setCompletedTelemetryExport } from '../state';

export const exportApi = new ExportApi(appConfiguration);

interface ExportsRequest {
  companyId: string;
  siteId: string;
  systemId: string;
  limit: number;
  skip?: number;
}

export async function getExports(request: ExportsRequest) {
  if (!request.companyId || !request.siteId || !request.systemId) return null;

  const res = await exportApi.getExports(
    request.companyId,
    request.siteId,
    request.systemId,
    request?.skip,
    request.limit,
   
  );
  return res.data;
}

export function useRefetchExports() {
  const queryClient = useQueryClient();
  return () => queryClient.refetchQueries('exports');
}

export function useGetExports(
  request: ExportsRequest
): UseQueryResult<TelemetryDataExport[]> {
  console.log(request)
  return useQuery(
    [
      'exports',
      request.companyId,
      request.siteId,
      request.systemId,
      request.limit,
      request?.skip,
    ],
    () =>
      getExports({
        companyId: request.companyId,
        siteId: request.siteId,
        systemId: request.systemId,
        limit: request.limit,
        skip: request?.skip,
      })
      , { refetchOnMount: false, }
  );
}

interface ExportUrlRequest {
  exportId: string;
  companyId: string;
  siteId: string;
  systemId: string;
}

async function getExportUrl(request: ExportUrlRequest) {
 const exportUrl = await exportApi.getExportURL(
    request.exportId,
    request.companyId, 
    request.siteId,
    request.systemId
  );
  return exportUrl.data.exportDownloadUrl;
}

export const useGetExportUrl = (
  onSuccess: (exportUrl: string | undefined) => void,
  onError: (err: string) => void
) => {
  return useMutation(
    async (request: ExportUrlRequest) => {
      const response = await getExportUrl(request);
      return response;
    },
    {
      onError: onError,
      onSuccess: onSuccess,
    }
  );
};

interface GenerateExportRequest {
  companyId: string;
  siteId: string;
  startTime: string;
  endTime: string;
  exportType: string;
  fileName: string;
  systemId: string;
}

async function postTelemetryExport(request: GenerateExportRequest) {
  if (
    !request.companyId ||
    !request.siteId ||
    !request.startTime ||
    !request.endTime ||
    !request.exportType ||
    !request.fileName ||
    !request.systemId
  )
    return null;
  const newExport = await exportApi.postExportGenerate(
    request.companyId,
    request.siteId,
    request.systemId,
    request.startTime,
    request.endTime,
    request.exportType,
    request.fileName
  );
  return newExport.data;
}

export const usePostTelemetryExport = (
  onSuccess: (url: TelemetryDataExport | undefined | null) => void,
  onError: (err: string) => void
) => {
  return useMutation(
    async (request: GenerateExportRequest) => {
      const response = await postTelemetryExport(request);
      setCompletedTelemetryExport({
        companyId: request.companyId,
        siteId: request.siteId,
        systemId: request.systemId,
        exportStatus: response?.exportStatus
      });

      if(response?.exportStatus === TelemetryDataExportExportStatusEnum.InProgress) {
        pollTelemetryExportStatus({
          ...request,
          exportId: response.id
        });
      }
      return response;
    },
    {
      onError: onError,
      onSuccess: onSuccess,
    }
  );
};

export const pollTelemetryExportStatus = async (request: GenerateExportRequest & {exportId?: string}, _skip = 0, _limit = 50) => {
  if(!request.exportId) return;

  setTimeout(async () => {
    if(!request.exportId) return;

    searchTelemetryExportStatus(request, _skip, _limit);
    
  }, 10000)
}


const searchTelemetryExportStatus = async (request: GenerateExportRequest & {exportId?: string}, _skip = 0, _limit = 50) => {
  if(!request.exportId) return;
    let skip = _skip;
    const limit = _limit;
    const response = await exportApi.getExports(
      request.companyId, 
      request.siteId,
      request.systemId,
      skip,
      limit
    );
    const data = response.data;
    const found = data.find((exportRecord) => {
      return exportRecord.id === request.exportId
    });
    if(found) {
      if(found.exportStatus === TelemetryDataExportExportStatusEnum.InProgress) {
        pollTelemetryExportStatus({
          ...request,
          exportId: found.id
        }, skip, limit);
      } else {
        setCompletedTelemetryExport({
          companyId: request.companyId,
          siteId: request.siteId,
          systemId: request.systemId,
          exportStatus: found.exportStatus ?? TelemetryDataExportExportStatusEnum.Error
        });
      }
    } else if(data.length == limit ) {
      skip += limit;
      searchTelemetryExportStatus(request, skip, limit);
    }
}



async function shareExport(exportId: string, companyId: string, siteId: string, systemId: string, emailAddress: string) {
  return await exportApi.exportShare(exportId, companyId, siteId, systemId, emailAddress);
}

export const useShareExport = (
  onSuccess?: () => void,
  onError?: (err: string) => void
) => {
  return useMutation(
    async ({ exportId, companyId, siteId, systemId, emailAddress}: { exportId: string, companyId: string, siteId: string, systemId: string, emailAddress: string}) => {
      const response = await shareExport(exportId, companyId, siteId, systemId, emailAddress);
      return response;
    },
    {
      onError: (err) => {
        onError?.(err as string);
      },
      onSuccess: () => {
        onSuccess?.();
      },
    }
  );
};