import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Divider, Grid, styled } from '@mui/material';
import { addDays, addMinutes, format, startOfDay } from 'date-fns';
import { GQLSystem } from '@beeriot/api-client';
import {
  Form,
  FormLabeledCheckbox,
  FormFooterButtons,
  SelectOption,
  FormDateTextField,
} from '../../form';
import {
  SectionControl,
  SectionFormSelect,
  SectionLabel,
  SectionTextInput,
} from '../../settings/settings-form';
import { useState } from 'react';

const StyledFormFooterButtons = styled(FormFooterButtons)({
  margin: '1rem',
});

const StyledGridContainer = styled(Grid)({
  paddingLeft: '1rem',
});

const StyledForm = styled(Form)({
width: '100%'
});



enum FilterTypes {
  allData = 'AllData',
  premadeFilters = 'PremadeFilters',
}

export type systemIdString = `systemId-${string}`;

export interface ExportFields {
  filter: {name:FilterTypes, value: FilterTypes};
  name: string;
  startDate: string;
  endDate: string;
  // [key: systemIdString]: boolean;
}

interface ExportDataFormProps {
  lines: GQLSystem[]
  | null
  | undefined;
  onSubmit: (data: ExportFields) => void;
  onClose: () => void;
  isLoading: boolean;
  hasError: boolean;
}

export function ExportDataForm({
  lines,
  onSubmit,
  onClose,
  isLoading,
  hasError,
}: ExportDataFormProps) {
  const intl = useIntl();

  const [dates, setDates] = useState({
    startDate: addDays(startOfDay(new Date()), -2),
    endDate: startOfDay(new Date())
  })

  const filterTypeLabel = intl.formatMessage({
    id: 'export-data-dialog-filter-type-label',
    description: 'filter type label',
    defaultMessage: 'Filter Type',
  });
  const filterTypePlaceholder = intl.formatMessage({
    id: 'export-data-dialog-filter-type-placeholder',
    description: 'filter type placeholder label',
    defaultMessage: 'Select Filter Type',
  });
  const premadeFilters = intl.formatMessage({
    id: 'export-data-dialog-export-type-premade-filters-label',
    defaultMessage: 'Premade Filters',
    description: 'Label for premade filters export type',
  });
  const profiFilters = intl.formatMessage({
    id: 'export-data-dialog-export-type-profi-filters-label',
    defaultMessage: 'PROFi Filters',
    description: 'Label for profi filters export type',
  });
  const cbsFilters = intl.formatMessage({
    id: 'export-data-dialog-export-type-cbs-filters-label',
    defaultMessage: 'CBS Filters',
    description: 'Label for CBS filters export type',
  });
  const cfsFilters = intl.formatMessage({
    id: 'export-data-dialog-export-type-cfs-filters-label',
    defaultMessage: 'CFS Filters',
    description: 'Label for CFS filters export type',
  });
  const kerafluxFilters = intl.formatMessage({
    id: 'export-data-dialog-export-type-keraflux-filters-label',
    defaultMessage: 'Keraflux Filters',
    description: 'Label for keraflux filters export type',
  });
  const allData = intl.formatMessage({
    id: 'export-data-dialog-export-type-all-data-label',
    defaultMessage: 'All Data',
    description: 'Label for all data export type',
  });
  const nameLabel = intl.formatMessage({
    id: 'export-data-dialog-name-label',
    description: 'label for data export name',
    defaultMessage: 'Name',
  });
  const namePlaceholder = intl.formatMessage({
    id: 'export-data-dialog-name-placeholder',
    description: 'placeholder for subject text input',
    defaultMessage: 'Enter Data Export Name',
  });
  const startDateLabel = intl.formatMessage({
    id: 'common-start-date-label',
    description: 'start date label',
    defaultMessage: 'Start Date',
  });
  const endDateLabel = intl.formatMessage({
    id: 'common-end-date-label',
    description: 'end date label',
    defaultMessage: 'End Date',
  });
  const exportLabel = intl.formatMessage({
    id: 'common-export-button',
    description: 'Button to export',
    defaultMessage: 'Export',
  });
  const exportingLabel = intl.formatMessage({
    id: 'common-exporting',
    description: 'Label to indicate data is exporting',
    defaultMessage: 'Exporting',
  });
  const machinesLabel = intl.formatMessage({
    id: 'reports-new-recurring-report-machines-label',
    description: 'label for machines',
    defaultMessage: 'Machine(s)',
  });

  const filterTypeOptions: SelectOption[] = [
    {
      value: 'AllData',
      label: allData,
    },
    {
      value: 'PremadeFilters',
      label: premadeFilters,
    },
  
  ];

  return (
    <Form onSubmit={onSubmit} className={'dataExportForm'}>

      <SectionControl>
        <SectionLabel>{filterTypeLabel}</SectionLabel>
        <SectionFormSelect
          name="filter"
          placeholder={filterTypePlaceholder}
          options={filterTypeOptions}
          required
        />
      </SectionControl>
      <Divider />
      <SectionControl>
        <SectionLabel>{nameLabel}</SectionLabel>
        <SectionTextInput name="name" placeholder={namePlaceholder} required />
      </SectionControl>
      <Divider />
      <SectionControl>
        <SectionLabel>{startDateLabel}</SectionLabel>
        <FormDateTextField
       
          name="startDate"
          defaultValue={format(
            dates.startDate,
            'yyyy-MM-dd'
          )}
          value={format(
            dates.startDate,
            'yyyy-MM-dd'
          )}
        
       
          onChange={(e) => {

            let d = new Date(e.target.value);
            const offset = d.getTimezoneOffset();
            d = addMinutes(d, offset);
 
            const max = addDays(startOfDay(d), 2)
            if(dates.endDate.getTime() > max.getTime() || dates.endDate.getTime() < d.getTime()) {
              setDates({endDate: max, startDate:d})
            }else {
              setDates({...dates, startDate: d})
            }
          }}
          required
        />
      </SectionControl>
      <SectionControl>
        <SectionLabel>{endDateLabel}</SectionLabel>
        <FormDateTextField
          name="endDate"
          value={format(
            dates.endDate,
            'yyyy-MM-dd'
          )}
          defaultValue={format(dates.endDate, 'yyyy-MM-dd')}
          onChange={(e) => {

            let d = new Date(e.target.value);
            const offset = d.getTimezoneOffset();
            d = addMinutes(d, offset);
            

            const max = addDays(startOfDay(d), -2)
            if(dates.startDate.getTime() < max.getTime() || dates.startDate.getTime() > d.getTime()) {
              setDates({startDate: max, endDate:d})
            }else {
              setDates({...dates, endDate: d})
            }
          }}
          required
        />
      </SectionControl>
      {/* <SectionControl>
        <SectionLabel>{machinesLabel}</SectionLabel>
        <StyledGridContainer container>
          {lines.length > 0
            ? lines.map((x) => (
                <Grid item xs={12} sm={6} key={x.id}>
                  <FormLabeledCheckbox
                    id={x.id}
                    name={`systemId-${x.id}`}
                    label={x.name}
                    $colorFilledCheckbox
                    $checkedColorKey="primary"
                  />
                </Grid>
              ))
            : null}
        </StyledGridContainer>
      </SectionControl> */}
      {hasError && (
        <Alert variant="filled" severity="info" sx={{ marginBottom: '1rem' }}>
          <FormattedMessage
            id="export-statistics-data-dialog-error-label"
            defaultMessage="An error occurred while exporting statistics data. Please try again later or contact support."
            description="Label for statistics export error"
          />
        </Alert>
      )}
      <StyledFormFooterButtons
        justify="center"
        onCancel={onClose}
        requireCancelConfirmation={false}
        submitLabel={exportLabel}
        isLoading={isLoading}
        loadingLabel={exportingLabel}
      />
    </Form>
    
  );
}
