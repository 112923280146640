import { useGQLSystemLiveStatus } from '@beeriot/api-client';
import { useResponsiveValues } from '../../../utils/responsive-design/useResponsiveSizes';
import {
  SystemCardBase,
  SystemCardBaseProps,
} from '../base-card-common/SystemCardBase';
import { SystemKpiGridAreaTemplate } from '../base-card-common/grids/SystemKpiHookProps';

const templateArea: SystemKpiGridAreaTemplate = [
  ['titleHeader', 'titleHeader', 'titleHeader'],
  ['runningState', 'brand', 'empty1'],
  ['timeSinceStart', 'processedVolume', 'operationalClusters'],
];

const mobileTemplateArea: SystemKpiGridAreaTemplate = [
  ['mobileTitleHeader', 'mobileTitleHeader', 'mobileTitleHeader'],
  ['brand', 'brand', 'brand'],
  ['timeSinceStart', 'processedVolume', 'operationalClusters'],
];

export const CFSSystemDetailCardBase = (props: SystemCardBaseProps) => {
  const { boldLabelTitles = false, system } = props;
  const gridTemplateAreas = useResponsiveValues<SystemKpiGridAreaTemplate>({
    xxs: mobileTemplateArea,
    md: templateArea,
  });
  useGQLSystemLiveStatus(system);
  return (
    <SystemCardBase
      {...props}
      gridTemplateAreas={props.gridTemplateAreas ?? gridTemplateAreas ?? []}
      boldLabelTitles={boldLabelTitles}
    />
  );
};
CFSSystemDetailCardBase.displayName = 'CFSSystemDetailCardBase';
