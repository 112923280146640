import { CardContent, Stack, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { GQLSystem, useGQLSystemLiveRegeneration } from '@beeriot/api-client';
import { AppTypography, AppCard } from '../../../base';
import { RunningStatusKpiElement } from './RunningStatusKpiElement';
import { recentStatusToTimelineData } from '../../../utils';

const TimelineContainer = styled(Stack)({
  padding: '0.25rem 0 0 0',
  width: '100%',
});

const StackContainer = styled(Stack)({
  justifyContent: 'space-between',
  width: '70%',
});

const StyledTypography = styled(AppTypography)({
  marginBottom: '0.5rem',
});

export interface CBSRegenerationCardProps {
  system: GQLSystem;
}

export function CBSRegenerationCard({ system }: CBSRegenerationCardProps) {
  useGQLSystemLiveRegeneration(system);
  return (
    <AppCard
      className={'cbs-regeneration-card'}
      data-element-id={'cbs-regeneration-card'}
    >
      <CardContent>
        <StackContainer>
          <StyledTypography variant="h6" $bold>
            <FormattedMessage
              id="common-cip-label"
              description="Label for CIP"
              defaultMessage="CIP"
            />
          </StyledTypography>
          <TimelineContainer>
            <RunningStatusKpiElement
              timelineData={recentStatusToTimelineData(
                system.liveRegeneration?.recentRunningStatus
              )}
            />
          </TimelineContainer>
        </StackContainer>
      </CardContent>
    </AppCard>
  );
}
