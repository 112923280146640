import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { IconLabel, LoadingPaper } from '../../base';

const StyledLoadingPaper = styled(LoadingPaper)({
  height: '50rem',
  width: '40rem',
});

const PushNotificationSettings = lazy(() =>
  import('./PushNotificationSettingsQuery').then(
    ({ PushNotificationSettingsQuery }) => ({
      default: PushNotificationSettingsQuery,
    })
  )
);

export function SuspensePushNotificationSettings() {
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <IconLabel
                label={
                  <FormattedMessage
                    id="settings-push-notification-settings-error-label"
                    description="Error message for push notification settings error"
                    defaultMessage="Push Notifications Unavailable"
                  />
                }
              />
            );
          }}
        >
          <Suspense fallback={<StyledLoadingPaper />}>
            <PushNotificationSettings />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
