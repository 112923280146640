import { styled, ToggleButton, ToggleButtonProps, darken } from '@mui/material';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { IconLabel } from './IconLabel';
import { AppTypography } from './AppTypography';

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  padding: '0.5rem 1rem',
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
  },
  '&.Mui-selected:hover': {
    backgroundColor: darken(theme.palette.primary.main, 0.05),
    color: darken(theme.palette.componentBase.main, 0.05),
  },
}));

export interface AppToggleButtonGroupItem {
  value: string;
  label: string;
  icon?: IconProp;
  iconSize?: SizeProp;
}

export const AppToggleButton = ({
  value,
  label,
  icon,
  iconSize,
  ...toggleButtonProps
}: ToggleButtonProps & AppToggleButtonGroupItem) => {
  return (
    <StyledToggleButton {...toggleButtonProps} value={value}>
      {icon ? (
        <IconLabel
          icon={icon}
          iconSize={iconSize}
          variant="body1"
          label={label}
        />
      ) : (
        <AppTypography variant="body1">{label}</AppTypography>
      )}
    </StyledToggleButton>
  );
};
