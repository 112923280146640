import { useState } from 'react';
import { useUpdateUserSettings, useIntlError } from '@beeriot/api-client';
import { SuspenseAccountSettings } from '../../settings';
import { SettingsPage } from './SettingsPage';

export function AccountSettingsPage() {
  const [error, setError] = useState<string | undefined>();
  const intlError = useIntlError();
  const saveAccountSettingsMutation = useUpdateUserSettings({
    onSuccess: () => {
      setError('');
    },
    onError: (err: string) => {
      setError(intlError(err));
    },
  });

  return (
    <SettingsPage>
      <SuspenseAccountSettings
        mutation={saveAccountSettingsMutation}
        error={error}
      />
    </SettingsPage>
  );
}
