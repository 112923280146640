import { Group, useUpdateGroup } from '@beeriot/api-client';
import { Dialog, DialogTitle, DialogContent, styled } from '@mui/material';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  AddEditGroupNameForm,
  AddEditGroupNameFormFields,
} from './AddEditGroupNameForm';

const StyledDialog = styled(Dialog)({
  '& .MuiDialogContent-root': {
    padding: '0rem 0rem 1.5rem 0rem',
  },
  '& .MuiDialog-paper': {
    width: '80vw',
  },
});

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.componentBase.main,
}));

export interface EditGroupNameDialogProps {
  group: Group | null;
  open: boolean;
  handleClose: () => void;
  isChildGroup?: boolean;
}

export function EditGroupNameDialog({
  group,
  open,
  handleClose,
  isChildGroup,
}: EditGroupNameDialogProps) {
  const updateGroup = useUpdateGroup();
  const intl = useIntl();

  const handleSubmit = useCallback(
    (data: AddEditGroupNameFormFields) => {
      if (group) {
        updateGroup.mutate({ ...group, name: data.name });
        handleClose();
      }
    },
    [group, updateGroup, handleClose]
  );

  const parentGroupTitle = intl.formatMessage({
    id: 'organize-sites-edit-group-type-dialog-title',
    defaultMessage: 'Edit Group Type -',
    description: 'Title for edit group type dialog',
  });

  const childGroupTitle = intl.formatMessage({
    id: 'organize-sites-edit-group-dialog-title',
    defaultMessage: 'Edit Group -',
    description: 'Title for edit group dialog',
  });

  return (
    <StyledDialog open={open}>
      <StyledDialogTitle>
        {isChildGroup ? childGroupTitle : parentGroupTitle}
        {' ' + group?.name ?? ''}
      </StyledDialogTitle>
      <DialogContent>
        <AddEditGroupNameForm
          mutation={updateGroup}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          editMode={true}
          isChildGroup={isChildGroup}
          defaultValue={group ? group.name : undefined}
        />
      </DialogContent>
    </StyledDialog>
  );
}
