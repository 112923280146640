import { useMutation } from 'react-query';
import { StatisticsViewingFilter } from '../../state/lineStatisticsStore';
import { CalendarMode, SystemStatsCategory } from '../../enums';
import { GQLFilterRun, useGetSystemStatsQuery } from '../../generated/gql';
import { getEndDate } from '../../utils';
import { SystemStatsApi } from '../../generated';
import { appConfiguration } from '../configuration';
import { toStatisticsGroups } from './statistic-groups/toStatisticsGroups';

export const systemStatsApi = new SystemStatsApi(appConfiguration);

export interface LineStatisticsItem {
  id: string;
  value?: number;
  compareValue?: number;
  units: string;
  localizerKey: string;
  percentageDelta?: number;
}

export interface LineStatisticsGroup {
  category: SystemStatsCategory;
  statistics: LineStatisticsItem[];
}

export interface ComparableSection {
  current: Record<string, number | null | undefined>;
  compare: Record<string, number | null | undefined>;
}

function toInterval(
  viewingFilter: StatisticsViewingFilter
): 'run' | 'weekly' | 'monthly' | 'yearly' {
  switch (viewingFilter) {
    case CalendarMode.Week:
      return 'weekly';
    case CalendarMode.Month:
      return 'monthly';
    case CalendarMode.Year:
      return 'yearly';
    case 'Run':
      return 'run';
    default:
      return 'weekly';
  }
}

export function useLineStatistics(
  systemType: string | undefined,
  systemId: string,
  startDate: string,
  compareStartDate: string,
  viewingFilter: StatisticsViewingFilter,
  filterRun: GQLFilterRun | null,
  compareFilterRun: GQLFilterRun | null
) {
  const isRunQuery = viewingFilter === 'Run';
  const startTime = isRunQuery ? filterRun?.startTime ?? '' : startDate;
  const endTime = isRunQuery
    ? filterRun?.endTime ?? ''
    : getEndDate(startDate, viewingFilter);
  const compareStartTime = isRunQuery
    ? compareFilterRun?.startTime ?? ''
    : compareStartDate;
  const compareEndTime = isRunQuery
    ? compareFilterRun?.endTime ?? ''
    : getEndDate(compareStartDate, viewingFilter);

  const variables = {
    systemId,
    startTime,
    endTime,
    compareStartTime,
    compareEndTime,
    interval: toInterval(viewingFilter),
    filterRunId: isRunQuery ? filterRun?.filterRunId : '',
    compareFilterRunId: isRunQuery ? compareFilterRun?.filterRunId : '',
  };

  const process = useGetSystemStatsQuery({
    ...variables,
    parameter: 'process',
  });

  const cleaningConsumption = useGetSystemStatsQuery({
    ...variables,
    parameter: 'cleaningConsumption',
  });

  const quality = useGetSystemStatsQuery({
    ...variables,
    parameter: 'quality',
  });

  const regeneration = useGetSystemStatsQuery({
    ...variables,
    parameter: 'regeneration',
  });

  return toStatisticsGroups(
    systemType,
    process.data,
    cleaningConsumption.data,
    quality.data,
    regeneration.data
  );
}

async function getStatisticsExport(request: StatisticsExportRequest) {
  if (
    !request.systemId ||
    (!request.filterRunId && (!request.startTime || !request.endTime))
  )
    return null;
  const res = await systemStatsApi.getSystemStatsExportStats(
    request.systemId,
    request.startTime,
    request.endTime,
    request.filterRunId
  );
  return res.data.presignedUrl;
}

interface StatisticsExportRequest {
  systemId?: string;
  startTime?: string;
  endTime?: string;
  filterRunId?: string;
}

export function useGetStatisticsExport(
  onSuccess: (url: string) => void,
  onError: (error: Error) => void
) {
  return useMutation(
    async (request: StatisticsExportRequest) => {
      return getStatisticsExport(request);
    },
    {
      onSuccess: (data) =>
        data ? onSuccess(data) : onError(new Error('No presigned url found')),
      onError: onError,
    }
  );
}
