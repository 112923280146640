import { styled, Stack, Divider, Grid } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { AppTypography } from '../../base';
import { SectionFormSelect } from '../../settings/settings-form/settings-form-section/SectionFormSelect';
import {
  Form,
  FormFooterButtons,
  FormLabeledCheckbox,
  SelectOption,
} from '../../form';
import {
  SectionControl,
  SectionLabel,
  SectionTextInput,
} from '../../settings/settings-form';
import { useIntlLanguage } from '../../enums';
import { getLanguageOptions } from '../../settings/Settings.models';
import {
  GQLSystem,
  ReportDefinition,
  ReportDefinitionBase,
  ReportFrequencyEnum,
  ReportTypeEnum,
} from '@beeriot/api-client';
import { UseMutationResult } from 'react-query';
import { useIntlReportFrequency } from '../../intl/useIntlReportFrequency';
import { useIntlReportType } from '../../intl/useIntlReportType';
import { OneTimeReportFormFields } from './OneTimeReportFormFields';

const StyledFormFooterButtons = styled(FormFooterButtons)({
  margin: '1rem',
});

const StyledSectionHeader = styled(Stack)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.componentBase.main,
  padding: '0.75rem 1.5rem',
}));

const StyledGridContainer = styled(Grid)({
  paddingLeft: '1rem',
});

export type systemIdString = `systemId-${string}`;

interface NewRecurringReportFormFields {
  type: SelectOption;
  frequency: SelectOption;
  name: string;
  startDate?: string;
  endDate?: string;
  aggregation?: SelectOption;
  emails: string[];
  language: SelectOption;
  [key: systemIdString]: boolean;
}

export interface NewRecurringReportDialogContentProps {
  onClose: () => void;
  onSubmit: (data: NewRecurringReportFormFields) => void;
  lines: GQLSystem[]
  | null
  | undefined;
  mutation: UseMutationResult<
    ReportDefinition,
    unknown,
    ReportDefinitionBase,
    unknown
  >;
}

export function NewRecurringReportDialogContent({
  onClose,
  onSubmit,
  lines,
  mutation,
}: NewRecurringReportDialogContentProps) {
  const intl = useIntl();
  const reportFrequencyIntl = useIntlReportFrequency();
  const reportTypeIntl = useIntlReportType();

  const reportTypeLabel = intl.formatMessage({
    id: 'reports-new-recurring-report-type-label',
    description: 'report type label',
    defaultMessage: 'Report Type',
  });

  const reportTypePlaceholder = intl.formatMessage({
    id: 'reports-new-recurring-report-type-placeholder',
    description: 'report type placeholder label',
    defaultMessage: 'Select Report Type',
  });

  const frequencyLabel = intl.formatMessage({
    id: 'reports-new-recurring-report-frequency-label',
    description: 'label for frequency',
    defaultMessage: 'Frequency',
  });
  const frequencyPlaceholder = intl.formatMessage({
    id: 'reports-new-recurring-report-frequency-placeholder',
    description: 'placeholder for frequency',
    defaultMessage: 'Select Frequency',
  });

  const reportOptionsHeader = intl.formatMessage({
    id: 'reports-new-recurring-report-report-options-header',
    description: 'header for report options section',
    defaultMessage: 'Report Options',
  });

  const machinesLabel = intl.formatMessage({
    id: 'reports-new-recurring-report-machines-label',
    description: 'label for machines',
    defaultMessage: 'Machine(s)',
  });

  const reportNameLabel = intl.formatMessage({
    id: 'reports-new-recurring-report-name-label',
    description: 'label for report name',
    defaultMessage: 'Report Name',
  });

  const reportNamePlaceholder = intl.formatMessage({
    id: 'reports-new-recurring-report-name-placeholder',
    description: 'placegholder for subject text input',
    defaultMessage: 'Enter Report Name',
  });

  const deliveryOptionsHeader = intl.formatMessage({
    id: 'reports-new-recurring-report-delivery-options-header',
    description: 'header for delivery options section',
    defaultMessage: 'Delivery Options',
  });

  const emailsLabel = intl.formatMessage({
    id: 'common-email-addresses-label',
    description: 'label for email addresses',
    defaultMessage: 'Email Address(s)',
  });

  const emailsPlaceholder = intl.formatMessage({
    id: 'common-emails-placeholder',
    defaultMessage: 'Enter emails',
    description: 'label for emails placeholder',
  });

  const languageLabel = intl.formatMessage({
    id: 'commmon-language-label',
    description: 'label for language',
    defaultMessage: 'Language',
  });

  const languagePlaceholder = intl.formatMessage({
    id: 'commmon-language-placeholder',
    description: 'label for language placeholder',
    defaultMessage: 'Select Language',
  });

  const submitButtonLabel = intl.formatMessage({
    id: 'reports-new-recurring-report-submit-button-label',
    description: 'label for dialog submit button',
    defaultMessage: 'Create Report',
  });

  const reportCreationSuccessLabel = intl.formatMessage({
    id: 'report-creation-success-label',
    description: 'Label for successfull creation of a one-time report',
    defaultMessage:
      'One-time report was created successfully. This report should be available in the reports table momentarily.',
  });
  const recurringReportCreationSuccessLabel = intl.formatMessage({
    id: 'report-recurring-creation-success-label',
    description: 'Label for successful creation of a recurring report',
    defaultMessage:
      'Recurring report was created successfully. This report should be available in the recurring reports table momentarily.',
  });

  const frequencyOptions: SelectOption[] = [
    {
      value: 'Daily',
      label: reportFrequencyIntl(ReportFrequencyEnum.Daily),
    },
    {
      value: 'Weekly',
      label: reportFrequencyIntl(ReportFrequencyEnum.Weekly),
    },
    {
      value: 'Monthly',
      label: reportFrequencyIntl(ReportFrequencyEnum.Monthly),
    },
    {
      value: 'Yearly',
      label: reportFrequencyIntl(ReportFrequencyEnum.Yearly),
    },
    {
      value: 'OneTime',
      label: reportFrequencyIntl(ReportFrequencyEnum.OneTime),
    },
  ];

  const reportTypeOptions: SelectOption[] = [
    {
      value: 'ConsumptionReport',
      label: reportTypeIntl(ReportTypeEnum.ConsumptionReport),
    },
    {
      value: 'CostReport',
      label: reportTypeIntl(ReportTypeEnum.CostReport),
    },
    {
      value: 'QualityReport',
      label: reportTypeIntl(ReportTypeEnum.QualityReport),
    },
  ];

  const intlLanguage = useIntlLanguage();
  const languageOptions = getLanguageOptions(intlLanguage);

  const defaultValues = {
    type: reportTypeOptions[0],
    frequency: frequencyOptions[0],
    name: '',
    machines: [],
    emails: [],
    language: languageOptions[0],
  };

  return (
    <Form<NewRecurringReportFormFields>
      onSubmit={onSubmit}
      formProps={{ defaultValues: defaultValues }}
    >
      <SectionControl>
        <SectionLabel>{reportTypeLabel}</SectionLabel>
        <SectionFormSelect
          name="type"
          placeholder={reportTypePlaceholder}
          options={reportTypeOptions}
          required
        />
      </SectionControl>
      <SectionControl>
        <SectionLabel>{frequencyLabel}</SectionLabel>
        <SectionFormSelect
          name="frequency"
          placeholder={frequencyPlaceholder}
          options={frequencyOptions}
          required
        />
      </SectionControl>
      <OneTimeReportFormFields />
      <SectionControl>
        <SectionLabel>{reportNameLabel}</SectionLabel>
        <SectionTextInput
          name="name"
          placeholder={reportNamePlaceholder}
          required
        />
      </SectionControl>
      <Divider />
      <StyledSectionHeader>
        <AppTypography variant="h6">{reportOptionsHeader}</AppTypography>
      </StyledSectionHeader>
      <SectionControl>
        <SectionLabel>{machinesLabel}</SectionLabel>
        <StyledGridContainer container>
          {lines && lines?.length > 0
            ? lines?.map((x) => (
                <Grid item xs={12} sm={6} key={x.id}>
                  <FormLabeledCheckbox
                    id={x.id}
                    name={`systemId-${x.id}`}
                    label={x.name}
                    $colorFilledCheckbox
                    $checkedColorKey="primary"
                  />
                </Grid>
              ))
            : null}
        </StyledGridContainer>
      </SectionControl>
      {/* TODO: Add back when email and language are available */}
      {/* TODO: There exists a bug with the email tag field where values are erased when clicking off of the input */}
      {/* <Divider />
      <StyledSectionHeader>
        <AppTypography variant="h6">{deliveryOptionsHeader}</AppTypography>
      </StyledSectionHeader>
      <SectionControl>
        <SectionLabel>{emailsLabel}</SectionLabel>
        <FormTagEmailField
          name="emails"
          placeholder={emailsPlaceholder}
          required
        />
      </SectionControl>
      <Divider />
      <SectionControl>
        <SectionLabel>{languageLabel}</SectionLabel>
        <SectionFormSelect
          name="langauge"
          placeholder={languagePlaceholder}
          options={languageOptions}
          required
        />
      </SectionControl> */}
      <Divider />
      <StyledFormFooterButtons
        isLoading={mutation.isLoading}
        requireCancelConfirmation={false}
        justify="center"
        onCancel={onClose}
        submitLabel={submitButtonLabel}
        isSuccess={mutation.isSuccess}
        successLabel={
          mutation.data?.frequency === 'One-Time'
            ? reportCreationSuccessLabel
            : recurringReportCreationSuccessLabel
        }
        onConfirmOkSuccessDialog={() => {
          mutation.reset();
          onClose();
        }}
      />
    </Form>
  );
}
