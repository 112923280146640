import { Company, GQLCompany } from '@beeriot/api-client';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  styled,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { UseMutationResult } from 'react-query';
import { EditCompanyForm } from './EditCompanyForm';

const StyledDialogContent = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 0,
});

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.base.main,
  borderTopRightRadius: theme.shape.borderRadius,
  borderTopLeftRadius: theme.shape.borderRadius,
}));

const DividerContainer = styled(Box)({
  padding: '0',
});

const StyledDivider = styled(Divider)({
  borderBottomWidth: '2px',
});

export interface EditCompanyDialogProps {
  mutation: UseMutationResult<Company, unknown, Company, unknown>;
  company: GQLCompany;
  open: boolean;
  confirm: (company: Company) => void;
  cancel: () => void;
}

export function EditCompanyDialog({
  mutation,
  company,
  open,
  confirm,
  cancel,
}: EditCompanyDialogProps) {
  return (
    <Dialog open={open}>
      <StyledDialogTitle>
        <FormattedMessage
          id="edit-companies-edit-company-dialog-title"
          description="Title for edit company"
          defaultMessage="Edit Company"
        />
      </StyledDialogTitle>
      <DividerContainer>
        <StyledDivider />
      </DividerContainer>
      <StyledDialogContent>
        <EditCompanyForm
          company={company}
          confirm={confirm}
          cancel={cancel}
          mutation={mutation}
        />
      </StyledDialogContent>
    </Dialog>
  );
}
