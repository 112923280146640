import { useMutation, useQueryClient } from 'react-query';
import { MembershipRoleNameEnum, UserApi, UserBase } from '../generated/api';
import {
  GQLListUsersQuery,
  GQLUser,
  useGetUserQuery,
  useInfiniteListUsersQuery,
} from '../generated/gql';
import { appConfiguration } from './configuration';
import {
  defaultPagingParams,
  removeFalsey,
  getNextPageParamHandler,
  insertToInfiniteData,
  optimisticUpdateInfiniteData,
} from '../utils';
import { MutationCallbackMethods } from './settings';
import { membershipsApi, useMembership, upsertMembership } from './permissions';

interface UserQueryVariables {
  skip: number;
  limit: number;
  status: string;
  companyId?: string | null;
}

const queryKeyPrefix = 'listUsers.infinite';
const queryVariables = (companyId?: string | null): UserQueryVariables => {
  return {
    companyId,
    status: 'active',
    ...defaultPagingParams,
  };
};
const queryKey = (companyId?: string | null) => [
  queryKeyPrefix,
  queryVariables(companyId),
];

export interface GQLUserRole extends GQLUser {
  role?: MembershipRoleNameEnum;
}

export const userApi = new UserApi(appConfiguration);

function toBaseUser(gqlUser: GQLUser): UserBase {
  return {
    companyId: gqlUser.companyId ?? '',
    email: gqlUser.email ?? '',
    firstName: gqlUser.firstName ?? '',
    lastName: gqlUser.lastName ?? '',
    // TODO: handle phone numbers if required
    // phoneNumber: gqlUser.phoneNumber ?? '',
    status: 'active',
  };
}

async function updateUser(gqlUser: GQLUser) {
  if (!gqlUser.userId) return null;
  const res = await userApi.updateUser(gqlUser.userId, toBaseUser(gqlUser));
  return res.data;
}

async function addUser(gqlUser: GQLUser) {
  if (!gqlUser.companyId) return null;
  const res = await userApi.createUser(toBaseUser(gqlUser));
  return res.data;
}

export function useGQLUser(userId?: string): GQLUser | undefined {
  const { data: user } = useGetUserQuery(
    { userId: userId ?? '' },
    { refetchInterval: 15000, enabled: !!userId }
  );

  return user?.getUser ?? undefined;
}

export function useGQLUsers(companyId?: string | null): GQLUser[] {
  const {
    data: users,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteListUsersQuery(queryVariables(companyId), {
    refetchInterval: 15000,
    getNextPageParam: getNextPageParamHandler(
      (query) => query?.listUsers?.length
    ),
  });

  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage();
  }

  const gqlUsers = removeFalsey<GQLUser>(
    users?.pages.flatMap((x) => x.listUsers)
  )
    .filter((user) => user.sub != undefined)
    .sort((a, b) => (a.lastName ?? '').localeCompare(b.lastName ?? ''));

  return gqlUsers;
}

export function useCreateUser(callbacks?: MutationCallbackMethods) {
  const queryClient = useQueryClient();
  return useMutation(
    async (user: GQLUserRole) => {
      if (!user.role) {
        callbacks?.onError('Missing role');
        return;
      }

      const response = await addUser(user);
      await upsertMembership(response?.sub ?? '', {
        userId: response?.sub ?? '',
        companyId: response?.companyId ?? '',
        roleName: user.role,
        status: 'active',
      });
      return response;
    },
    {
      onError: (err) => {
        callbacks?.onError(err as string);
      },
      onSuccess: async (user, gqlUser) => {
        await insertToInfiniteData<GQLListUsersQuery, GQLUser>(
          queryClient,
          queryKey(user?.companyId),
          'listUsers',
          { ...gqlUser, userId: user?.userId ?? '' }
        );
        callbacks?.onSuccess();
      },
    }
  );
}

export function useUpdateUser(callbacks?: MutationCallbackMethods) {
  const queryClient = useQueryClient();
  return useMutation(
    async (user: GQLUser) => {
      const response = await updateUser(user);
      return response;
    },
    {
      onMutate: async (user: GQLUser) => {
        return await optimisticUpdateInfiniteData<GQLListUsersQuery, GQLUser>(
          queryClient,
          queryKey(user.companyId),
          'listUsers',
          user,
          'userId'
        );
      },
      onError: (err, user, context) => {
        if (context?.previousState) {
          queryClient.setQueryData(
            queryKey(user.companyId),
            context.previousState
          );
        }
        callbacks?.onError(err as string);
      },
      onSuccess: (res) => {
        queryClient.invalidateQueries(['getUser', { userId: res?.userId }]);
        callbacks?.onSuccess();
      },
    }
  );
}

export function useUpdateMembership(callbacks?: MutationCallbackMethods) {
  const queryClient = useQueryClient();
  return useMutation(
    async (user: GQLUserRole) => {
      if (!user.role) {
        callbacks?.onError('Missing role');
        return;
      }

      const response = await upsertMembership(user?.sub ?? '', {
        userId: user?.sub ?? '',
        companyId: user?.companyId ?? '',
        status: 'active',
        roleName: user.role,
      });
      return response;
    },
    {
      onError: (err) => {
        callbacks?.onError(err as string);
      },
      onSuccess: (res) => {
        queryClient.invalidateQueries('memberships');
        queryClient.invalidateQueries(['membership', res?.userId]);
        callbacks?.onSuccess();
      },
    }
  );
}

export function useRevokeAccess(
  companyId?: string,
  onSuccess?: (userId: string) => void,
  onError?: (error: any) => void
) {
  const queryClient = useQueryClient();
  return useMutation(
    async (userId: string) => {
      if (!companyId) return;
      await membershipsApi.deleteMembership(userId);
    },
    {
      onError: onError,
      onSuccess: (_, userId) => {
        queryClient.invalidateQueries('memberships');
        queryClient.invalidateQueries(['membership', userId]);
        if (onSuccess) onSuccess(userId);
      },
    }
  );
}

export function useGQLUserRole(userId?: string): GQLUserRole | undefined {
  const user = useGQLUser(userId);
  const membership = useMembership(user?.sub ?? '');
  if (!user) return undefined;
  const membershipData = membership?.data;
  return {
    ...user,
    role: membershipData?.roleName,
  };
}

export const mapUsername = (user?: GQLUser) => {
  if (user === undefined || user?.lastName === undefined) return '';

  if (user?.firstName === undefined) return user.lastName;

  return `${user.firstName} ${user.lastName}`;
};
