const pushKey = process.env.NX_PUSH_VAPID_KEY ?? '';

export async function requestNotifications() {
  return new Promise<PushSubscription>((resolve, reject) => {
    navigator.serviceWorker.getRegistration('/web-push.serviceWorker.js').then(
      async (registration) => {
        if (!registration) {
          reject();
          return;
        }

        if (Notification.permission === 'granted') {
          await subscribeForNotifications(registration, resolve);
        } else {
          await Notification.requestPermission()
            .then(async (permission) => {
              if (permission === 'granted') {
                await subscribeForNotifications(registration, resolve);
              } else {
                reject();
              }
            })
            .catch((_err) => {
              reject();
            });
        }
      },
      (_err) => {
        reject();
      }
    );
  });
}

async function subscribeForNotifications(
  registration: ServiceWorkerRegistration,
  resolve: (subscription: PushSubscription) => void
) {
  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlB64ToUint8Array(pushKey),
  });
  resolve(subscription);
}

function urlB64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
