import { ColorKey, GQLSystem } from '@beeriot/api-client';
import { KerafluxSystemDetailCardBase } from './KerafluxSystemDetailCardBase';

export interface KerafluxSystemDetailCardProps {
  system: GQLSystem;
  cardTitle?: string;
  noStripe?: boolean;
  stripeColor?: ColorKey;
}

export function KerafluxSystemDetailCard({
  system,
  noStripe,
  stripeColor,
}: KerafluxSystemDetailCardProps) {
  return (
    <KerafluxSystemDetailCardBase
      noStripe={noStripe}
      stripeColor={stripeColor}
      system={system}
      boldLabelTitles={true}
    />
  );
}
