import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { Stack, Button, Typography, styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { LoadingPaper } from '../LoadingPaper';
import { LabeledSystemCountsBadgeListQueryProps } from './LabeledSystemCountsBadgeListQuery';

const MessageContainer = styled(Stack)({
  textAlign: 'center',
  justifyContent: 'center',
});

const ErrorContainer = styled(Stack)({
  alignItems: 'center',
});

const SystemLoadingPaper = styled(LoadingPaper)({
  height: '3rem',
  width: '10rem',
});

const LabeledSystemCountsBadgeList = lazy(() =>
  import('./LabeledSystemCountsBadgeListQuery').then(
    ({ LabeledSystemCountsBadgeListQuery }) => ({
      default: LabeledSystemCountsBadgeListQuery,
    })
  )
);

export function SuspenseLabeledSystemCountsBadgeList(
  props: LabeledSystemCountsBadgeListQueryProps
) {
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <ErrorContainer className={props.className}>
                <MessageContainer>
                  <Typography>
                    <FormattedMessage
                      id="system-counts-error-loading-label"
                      description="Label for system counts loading error"
                      defaultMessage="Error loading system counts"
                    />
                  </Typography>
                </MessageContainer>
              </ErrorContainer>
            );
          }}
        >
          <Suspense
            fallback={<SystemLoadingPaper className={props.className} />}
          >
            <LabeledSystemCountsBadgeList {...props} />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
