import { useMemo } from 'react';
import { KPIGridItem } from '../../kpi-grid/KPIGrid';
import { KPIVerticalLabels } from '../../kpi-grid/KPIVerticalLabel';
import { FormattedMessage } from 'react-intl';
import { SystemKpiHookProps } from '../../SystemKpiHookProps';
import { useShouldRenderKpi } from '../../useShouldRenderKpi';

export function useProcessedVolumeLabelGridItem(props: SystemKpiHookProps) {
  const { system, NA, boldLabelTitles, gridArea, intl } = props;
  const shouldRender = useShouldRenderKpi(props);

  return useMemo(() => {
    if (!shouldRender) {
      return undefined;
    }

    return (
      <KPIGridItem gridArea={gridArea ?? 'processedVolume'}>
        <KPIVerticalLabels
          label={
            system?.liveStatus?.processedVolumeHl != null
              ? `${intl.formatNumber(system?.liveStatus.processedVolumeHl)} hL`
              : NA
          }
          labelBold={boldLabelTitles}
          labelColorKey="success"
          subLabel={
            <FormattedMessage
              id="common-processed-volume"
              defaultMessage="Processed Volume"
              description="Label for processed amount"
            />
          }
        />
      </KPIGridItem>
    );
  }, [
    shouldRender,
    gridArea,
    system?.liveStatus?.processedVolumeHl,
    intl,
    NA,
    boldLabelTitles,
  ]);
}
