import { useIntl } from 'react-intl';
import { Dialog, DialogContent, Stack, styled, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { ColorKey } from '@beeriot/api-client';
import { DialogHeader } from '../../../dialogs/DialogHeader';
import { AppTypography } from '../../AppTypography';

const StyledTextField = styled(TextField)({
  marginLeft: 'auto',
});

const StyledStack = styled(Stack)({
  alignItems: 'center',
  gap: '1rem',
});

const TopStack = styled(StyledStack)({
  marginBottom: '1rem',
});

export interface RangedAppDatePickerDialogProps {
  open: boolean;
  onClose: () => void;
  startDate: Date | null;
  endDate: Date | null;
  onStartChange: (startDate: Date | null) => void;
  onEndChange: (endDate: Date | null) => void;
  colorKey: ColorKey;
}

export function RangedAppDatePickerDialog({
  open,
  onClose,
  startDate,
  endDate,
  onStartChange,
  onEndChange,
  colorKey,
}: RangedAppDatePickerDialogProps) {
  const intl = useIntl();
  const title = intl.formatMessage({
    id: 'ranged-date-picker-dialog-title',
    description: 'Title for ranged date picker dialog',
    defaultMessage: 'Select Dates',
  });
  const startDateLabel = intl.formatMessage({
    id: 'common-start-date-label',
    description: 'start date label',
    defaultMessage: 'Start Date',
  });
  const endDateLabel = intl.formatMessage({
    id: 'common-end-date-label',
    description: 'end date label',
    defaultMessage: 'End Date',
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader
        label={title}
        headerVariant="h6"
        colorKey={colorKey}
        onClose={onClose}
      />

      <DialogContent>
        <TopStack direction="row">
          <AppTypography>{startDateLabel}</AppTypography>
          <DatePicker
            disableFuture
            disableHighlightToday
            maxDate={endDate ?? undefined}
            value={startDate}
            onChange={onStartChange}
            renderInput={(props) => <StyledTextField {...props} />}
          />
        </TopStack>
        <StyledStack direction="row">
          <AppTypography>{endDateLabel}</AppTypography>
          <DatePicker
            disableFuture
            disableHighlightToday
            minDate={startDate ?? undefined}
            value={endDate}
            onChange={onEndChange}
            renderInput={(props) => <StyledTextField {...props} />}
          />
        </StyledStack>
      </DialogContent>
    </Dialog>
  );
}
