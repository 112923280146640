import { styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { AppTypography } from '../../base/AppTypography';
import { FormError } from '../../form';
import { RouterLink } from '../../navigation';
import { PallLogo } from '../PallLogo';
import { AuthSubmitButton } from '../AuthSubmitButton';
import { FormErrorContainer } from '../FormErrorContainer';
import { useCallback } from 'react';
import { UseMutationResult } from 'react-query';
import { LoadingButton } from '../../base/LoadingButton';
import { User } from '@beeriot/api-client';

const ResendEmailButton = styled(LoadingButton)(({ theme }) => ({
  width: '100%',
  textDecoration: 'underline',
  color: theme.palette.white.main,
  justifyContent: 'center',
  marginTop: '2rem',
}));
ResendEmailButton.displayName = 'ResendEmailButton';

const InstructionsLabel = styled(AppTypography)({
  marginTop: '0.5rem',
  marginBottom: '1rem',
});

interface ForgotPasswordResendProps {
  mutation: UseMutationResult<User, unknown, string, unknown>;
  email: string;
  error?: React.ReactNode;
}

export function ForgotPasswordResend({
  mutation,
  email,
  error,
}: ForgotPasswordResendProps) {
  const resendEmail = useCallback(() => {
    mutation.mutate(email);
  }, [mutation, email]);

  return (
    <div>
      <PallLogo />
      <AppTypography variant="body1" $bold $colorKey="white">
        <FormattedMessage
          id="forgot-password-email-sent-title-label"
          defaultMessage="Recovery Email Sent"
          description="Label for recovery email sent page title"
        />
      </AppTypography>
      <InstructionsLabel variant="subtitle2" $colorKey="white">
        <FormattedMessage
          id="forgot-password-email-sent-instructions-label"
          defaultMessage="Your password has been reset. Please follow the instruction provided in the recovery email sent to:"
          description="Label for forgot password recovery email"
        />
      </InstructionsLabel>
      <AppTypography variant="body1" $colorKey="white">
        {email}
      </AppTypography>
      <FormErrorContainer>
        <FormError error={error} color="info" />
      </FormErrorContainer>
      <RouterLink to="/auth">
        <AuthSubmitButton>
          <FormattedMessage
            id="forgot-password-return-to-login-button"
            defaultMessage="Return to login"
            description="Button to return to login page"
          />
        </AuthSubmitButton>
      </RouterLink>
      <ResendEmailButton
        onClick={resendEmail}
        isLoading={mutation.isLoading}
        loadingLabel={
          <FormattedMessage
            id="forgot-password-email-resend-email-resending-button"
            defaultMessage="Resending"
            description="Button label when resending email recovery link"
          />
        }
      >
        <FormattedMessage
          id="forgot-password-email-resend-email-button"
          defaultMessage="Resend Email"
          description="Button to resend email recovery link"
        />
      </ResendEmailButton>
    </div>
  );
}
