import React from 'react';
import { RegisterOptions } from 'react-hook-form';
import {
  styled,
  TextField,
  TextFieldProps,
  InputAdornment,
} from '@mui/material';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ColorKey } from '@beeriot/api-client';

import { useValidationRules } from './useValidationRules';
import { AppIcon } from '../base/AppIcon';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
});

export interface FormDateTextFieldProps
  extends Omit<TextFieldProps, 'onChange'> {
  name: string;
  required: boolean;
  defaultValue: string;
  id?: string;
  validationOptions?:
    | Partial<RegisterOptions>
    | ((values: Record<string, unknown>) => Partial<RegisterOptions>);
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  icon?: IconProp;
  $iconColorKey?: ColorKey;
}

export const FormDateTextField: React.FC<FormDateTextFieldProps> = ({
  name,
  required,
  defaultValue,
  validationOptions,
  id,
  onChange,
  icon,
  $iconColorKey,
  ...props
}) => {
  const { rules, register, setValue, trigger } = useValidationRules(
    required,
    validationOptions
  );
  const field = register(name, rules);

  return (
    <StyledTextField
      {...props}
      {...field}
      id={id}
      type="date"
      name={name}
      required={required}
    
      onChange={(event) => {
        setValue(name, event.target.value);
        if (onChange) onChange(event);
      }}
      defaultValue={defaultValue}
      InputProps={{
        endAdornment: icon ? (
          <InputAdornment position="end">
            <AppIcon icon={icon} $colorKey={$iconColorKey} />
          </InputAdornment>
        ) : null,
      }}
    />
  );
};
