import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useForgotPassword, useIntlError } from '@beeriot/api-client';
import { ForgotPasswordForm, ForgotPasswordResend } from '../../auth';
import { RootAuthPageStack } from './RootAuthPageStack';
import { AppDialog } from '../../base';

export function ForgotPasswordPage() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | undefined>();
  const intlError = useIntlError();

  const forgotPasswordMutation = useForgotPassword(
    (email) => {
      setError('');
      setEmail(email);
    },
    (err) => setError(intlError(err?.message))
  );

  return (
    <RootAuthPageStack>
      {email ? (
        <ForgotPasswordResend
          mutation={forgotPasswordMutation}
          email={email}
          error={error}
        />
      ) : (
        <ForgotPasswordForm mutation={forgotPasswordMutation} error={error} />
      )}
      <AppDialog
        open={dialogOpen}
        confirm={() => setDialogOpen(false)}
        showCancelButton={false}
        confirmLabel={
          <FormattedMessage
            id="common-ok-button"
            description="label for ok button"
            defaultMessage="Ok"
          />
        }
        dialogTitle={
          <FormattedMessage
            id="reset-password-resend-dialog-title"
            description="Title for resend email confirmation dialog"
            defaultMessage="Email Sent"
          />
        }
        dialogContent={
          <FormattedMessage
            id="reset-password-resend-dialog-message"
            description="Body for resend email confirmation dialog"
            defaultMessage="Another email has been sent to {email} with a link to reset your password."
            values={{
              email: email,
            }}
          />
        }
      />
    </RootAuthPageStack>
  );
}
