import { useObservable } from '@ngneat/use-observable';
import { map, shareReplay } from 'rxjs/operators';
import { activeEntities$ } from './useActiveAppEntities';

export const activeEntityRouteParams$ = activeEntities$.pipe(
  map(({ activeCompany, activeSite, activeSystem, activeBlock }) => {
    return {
      companyId: activeCompany?.id ?? '',
      siteId: activeSite?.id ?? '',
      lineId: activeSystem?.id ?? '',
      blockId: activeBlock?.id ?? '',
    };
  }),
  shareReplay(1)
);

export const useActiveEntityRouteParams = () => {
  const [routeParams] = useObservable(activeEntityRouteParams$);
  return routeParams;
};
