import React, { ReactElement } from 'react';
import { cleanWildcardPath } from '../../utils/cleanWildcardPath';
import { RouteTabObject } from '../models/RouteTabObject';
import { RouteTabLink } from '../RouteTabLink';
import { RouteTabProps } from '../RouteTab';

/**
 * Default renderer for TabButton Router Links,
 * renders a list of TabRouterLinks, can be used as a template
 * for other renderers
 *
 * @param route
 * @param index
 * @param array
 * @param _children
 * @returns
 */
export function defaultTabButtonRenderer(
  route: Partial<RouteTabObject>,
  index: number,
  array: Partial<RouteTabObject>[],
  _children: ReactElement<RouteTabProps>[]
):
  | React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>>
  | undefined {
  const tabRoute = _children[index];
  const buttonElement = tabRoute.props.buttonElement as ReactElement;
  const toPath = cleanWildcardPath(route.path);

  // console.log({
  //   toPath,
  //   buttonProps: buttonElement?.props,
  //   route: route
  // });
  // if we were handed a button element, then use that, and add labels and `to` paths to
  // the link if they were not provided on the element itself
  return buttonElement ? (
    React.cloneElement(buttonElement as ReactElement, {
      // spread props so this picks up click handlers, loaders, etc
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...((route as any) ?? {}),
      key: `${route.path}-${route.label}-tab`,
      to: buttonElement?.props?.to ?? toPath,
      label: buttonElement?.props?.label ?? route.label,
      params: {
        ...(route.params ?? {}),
        ...(buttonElement?.props?.params ?? {}),
      },
      value: `${index}`,
    })
  ) : (
    <RouteTabLink
      // spread props so this picks up click handlers, loaders, etc
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...((route as any) ?? {})}
      to={toPath ?? ''}
      key={`${route.path}-${route.label}-tab`}
      label={route.label}
      params={route.params}
      value={`${index}`}
    />
  );
}
