import { useMemo } from 'react';
import { styled, TextareaAutosize } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const StyledTextArea = styled(TextareaAutosize)(({ theme }) => ({
  padding: '0.5rem',
  minHeight: '5rem',
  borderRadius: '4px',
  border: 'none',
  fontFamily: theme.typography.fontFamily,
  backgroundColor: theme.palette.componentShade.main,
  resize: 'vertical',
}));

interface FormTextAreaProps {
  name: string;
  placeholder?: string;
  required?: boolean;
}

export function FormTextArea({
  name,
  placeholder,
  required,
}: FormTextAreaProps) {
  const { control } = useFormContext();

  const rules = useMemo(() => {
    return { required };
  }, [required]);

  return (
    <Controller
      name={name}
      rules={rules}
      render={({ field }) => (
        <StyledTextArea {...field} placeholder={placeholder} />
      )}
      control={control}
      defaultValue=""
    />
  );
}
