import { useCallback, useMemo, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Table, TableCell, Button, Stack, styled } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import {
  GQLReportDefinition,
  ReportFrequencyEnum,
  ReportTypeEnum,
  useDeleteReport,
} from '@beeriot/api-client';
import {
  AppDialog,
  AppTableBody,
  AppTableFooterPagination,
  AppTableHeaderRow,
  AppTableRow,
  getDefaultComparator,
  SortOrder,
} from '../../base';
import { ConfirmDeleteAutomaticReportButton } from './ConfirmDeleteAutomaticReportButton';
import { useAppRoutePaths } from '../../app-routing/utils';
import { useIntlReportFrequency } from '../../intl/useIntlReportFrequency';
import { useIntlReportType } from '../../intl/useIntlReportType';
import { ReportDeletionConfirmationDialog } from '../../dialogs/reports';

const StyledButton = styled(Button)({
  // Unfortunately necessary due to mui TableCell styling
  '&:hover': { cursor: 'pointer' },
});

const ButtonContainer = styled(Stack)({
  // margin: '0.5rem',
  '&:hover': { cursor: 'pointer' },
});

type SortableAutomaticReportColumns =
  | 'frequency'
  | 'reportType'
  | 'name'
  | 'createdBy';

export interface AutomaticReportSettingsTableProps {
  items: GQLReportDefinition[];
}

export const AutomaticReportSettingsTable = ({
  items,
}: AutomaticReportSettingsTableProps) => {
  const intl = useIntl();
  const reportFrequencyIntl = useIntlReportFrequency();
  const reportTypeIntl = useIntlReportType();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
  const [orderBy, setOrderBy] =
    useState<SortableAutomaticReportColumns>('name');
  const navigate = useNavigate();
  const appPaths = useAppRoutePaths();
  const deleteMutation = useDeleteReport();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'frequency',
        headerName: intl.formatMessage({
          id: 'automatic-report-settings-table-column-frequency',
          defaultMessage: 'Frequency',
          description: 'column header for frequency',
        }),
        flex: 1,
        sortable: true,
      },
      {
        field: 'reportType',
        headerName: intl.formatMessage({
          id: 'automatic-report-settings-table-column-report-type',
          defaultMessage: 'Report Type',
          description: 'column header for report type',
        }),
        flex: 1,
        sortable: true,
      },
      {
        field: 'name',
        headerName: intl.formatMessage({
          id: 'automatic-report-settings-table-column-name',
          defaultMessage: 'Name',
          description: 'column header for name',
        }),
        flex: 1,
        sortable: true,
      },
      // TODO: Add back createdBy when available, remember to update flex
      // {
      //   field: 'createdBy',
      //   headerName: intl.formatMessage({
      //     id: 'automatic-report-settings-table-column-create-by',
      //     defaultMessage: 'Created By',
      //     description: 'column header for created by',
      //   }),
      //   flex: 1,
      //   sortable: true,
      // },
      {
        field: '',
        flex: 3,
      },
    ],
    [intl]
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property as SortableAutomaticReportColumns);
  };

  const onDelete = useCallback(
    (automaticReportId: string) => {
      deleteMutation.mutate(automaticReportId);
    },
    [deleteMutation]
  );

  const showEditForm = useCallback(
    (automaticReport: GQLReportDefinition): void => {
      navigate(appPaths.settingsEditReportPathForReport(automaticReport));
    },
    [navigate, appPaths]
  );

  return (
    <>
      <Table>
        <AppTableHeaderRow<GQLReportDefinition>
          columns={columns}
          onRequestSort={handleRequestSort}
          orderBy={orderBy}
          sortOrder={sortOrder}
        />
        <AppTableBody>
          {items &&
            items
              .sort(getDefaultComparator(sortOrder, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => {
                const frequencyLabel = reportFrequencyIntl(
                  item.frequency as ReportFrequencyEnum
                );
                const reportTypeLabel = reportTypeIntl(
                  item.reportType as ReportTypeEnum
                );
                return (
                  <AppTableRow<GQLReportDefinition>
                    key={`automatic-report-table-row-${item.id}`}
                    data={item}
                  >
                    <TableCell
                      id={`automatic-report-table-row-frequency-${item.id}`}
                    >
                      {frequencyLabel}
                    </TableCell>
                    <TableCell
                      id={`automatic-report-table-row-report-type-${item.id}`}
                    >
                      {reportTypeLabel}
                    </TableCell>
                    <TableCell
                      id={`automatic-report-table-row-name-${item.id}`}
                    >
                      {item.reportName}
                    </TableCell>
                    {/* TODO: Integrate once available */}
                    {/* <TableCell
                      id={`automatic-report-table-row-created-by-${item.id}`}
                    >
                      {item.createdBy}
                    </TableCell> */}
                    <TableCell
                      id={`automatic-report-table-row-buttons-${item.id}`}
                    >
                      <ButtonContainer direction="row">
                        <StyledButton onClick={() => showEditForm(item)}>
                          <FormattedMessage
                            id="common-edit-button"
                            description="Button for edit"
                            defaultMessage="Edit"
                          />
                        </StyledButton>
                        <ConfirmDeleteAutomaticReportButton
                          selectedReport={item}
                          onDelete={() => onDelete(item.id)}
                        />
                        <ReportDeletionConfirmationDialog
                          deleteMutation={deleteMutation}
                        />
                      </ButtonContainer>
                    </TableCell>
                  </AppTableRow>
                );
              })}
        </AppTableBody>
      </Table>
      <AppTableFooterPagination
        page={page}
        rowsPerPage={rowsPerPage}
        totalRows={items?.length}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
};
