import { getRegistry } from '@ngneat/elf';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

export function useLogout() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return () => {
    getRegistry().forEach((store) => store.reset());
    queryClient.clear();
    navigate('/auth', { replace: true });
  };
}
