import { useMemo } from 'react';
import { KPIGridItem } from '../../kpi-grid/KPIGrid';
import { SystemKpiHookProps } from '../../SystemKpiHookProps';
import { useShouldRenderKpi } from '../../useShouldRenderKpi';
import { KPIVerticalLabels } from '../../kpi-grid/KPIVerticalLabel';
import { formatUnit } from '../../../../../../utils';
import { ThresholdValue } from '../../../../../../utils/threshold-color/thresholdValue';
import { useThresholdColor } from '../../../../../../utils/threshold-color/useThresholdColor';

export function useOxygenGridItem(props: SystemKpiHookProps) {
  const { system, gridArea, NA, boldLabelTitles } = props;
  const shouldRender = useShouldRenderKpi(props);
  const thresholdColor = useThresholdColor(system?.id);

  return useMemo(() => {
    if (!shouldRender) {
      return undefined;
    }

    const labelColor = thresholdColor(
      ThresholdValue.Oxygen,
      system?.liveKPI?.lineOxygenPpb
    );

    return (
      <KPIGridItem gridArea={gridArea ?? 'oxygen'}>
        <KPIVerticalLabels
          label={
            system?.liveKPI?.lineOxygenPpb != null
              ? formatUnit(system?.liveKPI?.lineOxygenPpb, 'ppb')
              : NA
          }
          labelBold
          subLabel="Oxygen"
          labelColorKey={labelColor}
        />
      </KPIGridItem>
    );
  }, [
    shouldRender,
    thresholdColor,
    system?.liveKPI?.lineOxygenPpb,
    gridArea,
    NA,
  ]);
}
