import { Alert, Dialog, DialogContent, Stack, styled } from '@mui/material';
import { FileWithPath } from 'react-dropzone';
import { Form, SelectOption } from '../../form';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { AppTypography } from '../../base';
import { UseMutationResult } from 'react-query';
import { AlertMappingPutData } from '@beeriot/api-client';
import { AlertTypeMappingFormContent } from './AlertTypeMappingFormContent';

interface UploadFormFields {
  system: SelectOption;
  files: FileWithPath[];
}

interface AlertTypeMappingUploadDialogProps {
  open: boolean;
  onClose: () => void;
  mutation: UseMutationResult<
    AlertMappingPutData | undefined,
    unknown,
    {
      systemId: string;
      file: FileWithPath;
    },
    unknown
  >;
}

export function AlertTypeMappingUploadDialog({
  open,
  onClose,
  mutation,
}: AlertTypeMappingUploadDialogProps) {
  const onSubmit = useCallback(
    (data: UploadFormFields) => {
      if (data.system && data.files && data.files.length > 0) {
        mutation.mutate({ systemId: data.system.value, file: data.files[0] });
      }
    },
    [mutation]
  );

  return (
    <Dialog open={open}>
      <DialogContent>
        <Stack sx={{ marginBottom: '1rem' }}>
          <AppTypography variant="h6" $bold>
            <FormattedMessage
              id="alert-mapping-upload-dialog-title"
              defaultMessage="Upload Alert Type Mapping"
              description="title for alert type mapping upload dialog"
            />
          </AppTypography>
          <Alert severity="warning">
            <AppTypography>
              <FormattedMessage
                id="alert-mapping-upload-dialog-warning-label"
                defaultMessage="The existing alert type mappings will be overridden."
                description="warning label for alert type mapping upload"
              />
            </AppTypography>
          </Alert>
        </Stack>
        <Form<UploadFormFields> onSubmit={onSubmit}>
          <AlertTypeMappingFormContent onClose={onClose} mutation={mutation} />
        </Form>
      </DialogContent>
    </Dialog>
  );
}
