import { useMemo } from 'react';
import { KPIGridItem } from '../../kpi-grid/KPIGrid';
import { KPIVerticalLabels } from '../../kpi-grid/KPIVerticalLabel';
import { FormattedMessage } from 'react-intl';
import { SystemKpiHookProps } from '../../SystemKpiHookProps';
import { useShouldRenderKpi } from '../../useShouldRenderKpi';

export function useDiaFiltrationCompleteGridItem(props: SystemKpiHookProps) {
  const { system, gridArea, intl, boldLabelTitles } = props;
  const shouldRender = useShouldRenderKpi(props);

  return useMemo(() => {
    if (!shouldRender) {
      return undefined;
    }
    const diaFiltrationOn = intl.formatMessage({
      id: 'keraflux-dia-filtration-on-label',
      defaultMessage: 'Active / On',
      description: 'Label for when dia filtration is on',
    });
    const diaFiltrationOff = intl.formatMessage({
      id: 'keraflux-dia-filtration-off-label',
      defaultMessage: 'Inactive / Off',
      description: 'Label for when dia filtration is off',
    });
    return (
      <KPIGridItem display="flex" gridArea={gridArea ?? 'diaFiltration'}>
        <KPIVerticalLabels
          label={
            system?.liveStatus?.diaFiltration
              ? diaFiltrationOn
              : diaFiltrationOff
          }
          labelBold
          labelColorKey={
            system?.liveStatus?.diaFiltration ? 'success' : 'warning'
          }
          subLabel={
            <FormattedMessage
              id="keraflux-dia-filtration"
              defaultMessage="Dia-Filtration"
              description="Label for dia filtration"
            />
          }
        />
      </KPIGridItem>
    );
  }, [shouldRender, intl, gridArea, system?.liveStatus?.diaFiltration]);
}
