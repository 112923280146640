import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Stack, Paper, styled } from '@mui/material';
import {
  AppTableHeader,
  AppTableHeaderControlsContainer,
} from '../../base/table';
import { SettingsHeaderButton } from '../settings-form';
import { SuspenseUserManagementSettingsList } from './SuspenseUserManagementSettingsList';
import { useResponsiveSizes } from '../../utils';
import { useAppRoutePaths } from '../../app-routing/utils';
import { PermissionKey, PermissionView } from '../../base';

const TableContainer = styled(Stack)({
  minWidth: '50rem',
  maxWidth: '80rem',
});

const StyledAppTableHeaderControlsContainer = styled(
  AppTableHeaderControlsContainer
)({
  justifyContent: 'end',
});

export const UserManagementSettingsPanel = () => {
  const { isMobileSize } = useResponsiveSizes();
  const navigate = useNavigate();

  const appPaths = useAppRoutePaths();
  const showAddForm = useCallback(() => {
    navigate(appPaths.settingsNewUserPath);
  }, [navigate, appPaths]);

  return isMobileSize ? (
    <>
      <PermissionView
        permissionKey={PermissionKey.SettingsUserManagementAddUser}
      >
        <SettingsHeaderButton
          onClick={showAddForm}
          label={
            <FormattedMessage
              id="settings-user-management-add-user-button"
              defaultMessage="Add User"
              description="button label for add user"
            />
          }
        />
      </PermissionView>
      <SuspenseUserManagementSettingsList />
    </>
  ) : (
    <TableContainer>
      <Paper>
        <AppTableHeader
          label={
            <FormattedMessage
              id="settings-user-management-header"
              defaultMessage="Users"
              description="header for user management table"
            />
          }
        >
          <StyledAppTableHeaderControlsContainer>
            <PermissionView
              permissionKey={PermissionKey.SettingsUserManagementAddUser}
            >
              <SettingsHeaderButton
                onClick={showAddForm}
                label={
                  <FormattedMessage
                    id="settings-user-management-add-user-button"
                    defaultMessage="Add User"
                    description="button label for add user"
                  />
                }
              />
            </PermissionView>
          </StyledAppTableHeaderControlsContainer>
        </AppTableHeader>
        <SuspenseUserManagementSettingsList />
      </Paper>
    </TableContainer>
  );
};
