import { useMemo } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

export function useValidationRules(
  required: boolean | undefined,
  validationOptions?:
    | Partial<RegisterOptions>
    | ((values: Record<string, unknown>) => Partial<RegisterOptions>)
) {
  const context = useFormContext();
  const { register, getValues, setValue } = context;
  const intl = useIntl();
  const fieldRequiredLabel = intl.formatMessage({
    id: 'common-field-required-message',
    defaultMessage: 'This field is required.',
    description: 'label for required field',
  });

  const rules = useMemo(() => {
    console.log(validationOptions)
    if (typeof validationOptions === 'object') return validationOptions;
    else if (validationOptions) return validationOptions(getValues());
    else if (required) return { required: fieldRequiredLabel };
    else return undefined;
  }, [validationOptions, getValues, required, fieldRequiredLabel]);

  return { ...context, rules, register, getValues, setValue };
}
