import { Suspense, lazy, MutableRefObject } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { IconLabel, LoadingBox } from '../../base';

const TableLoadingBox = styled(LoadingBox)({
  height: '100%',
  minHeight: '20rem',
  width: '100%',
  borderRadius: '0 0 0.5rem 0.5rem',
});

const ProvisioningTable = lazy(() =>
  import('./ProvisioningTableQuery').then(({ ProvisioningTableQuery }) => ({
    default: ProvisioningTableQuery,
  }))
);

interface SuspenseProvisioningTableProps {
  containerRef: MutableRefObject<null>;
  startSetup: (companyId?: string) => void;
}

export function SuspenseProvisioningTable({
  containerRef,
  startSetup,
}: SuspenseProvisioningTableProps) {
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <IconLabel
                label={
                  <FormattedMessage
                    id="settings-account-settings-error-label"
                    description="Error message for account settings error"
                    defaultMessage="Account Settings Unavailable"
                  />
                }
              />
            );
          }}
        >
          <Suspense fallback={<TableLoadingBox />}>
            <ProvisioningTable
              containerRef={containerRef}
              startSetup={startSetup}
            />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
