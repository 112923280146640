import { ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Stack } from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import { activeCompany$, useUserRole } from '@beeriot/api-client';
import { AppTypography } from '../../../base/AppTypography';
import { PermissionView } from '../../../base/user-permissions/PermissionView';
import { PermissionKey } from '../../../base/user-permissions/PermissionKey';
import { VerticalLabels } from '../../../base/VerticalLabels';
import { useResponsiveSizes } from '../../../utils/responsive-design/useResponsiveSizes';
import { useIntlRole } from '../../../intl/useIntlRole';

interface UserRoleProps {
  name?: ReactNode;
}

interface UserRoleDisplayProps {
  name?: ReactNode;
  companyName?: string;
  role?: ReactNode;
}

export function UserRoleQuery({ name }: UserRoleProps) {
  const [activeCompany] = useObservable(activeCompany$);
  const role = useUserRole();
  const intlRole = useIntlRole();

  return (
    <UserRoleDisplay
      name={name}
      companyName={activeCompany?.name || undefined}
      role={
        role ? (
          intlRole(role)
        ) : (
          <FormattedMessage
            id="user-info-role-error-label"
            description="Error message for user role error"
            defaultMessage="Role Unavailable"
          />
        )
      }
    />
  );
}

export function UserRoleDisplay({
  name,
  role,
  companyName,
}: UserRoleDisplayProps) {
  const { isDesktopSize, isMobileSize } = useResponsiveSizes();
  const isLightText = useMemo(
    () => isDesktopSize || isMobileSize,
    [isDesktopSize, isMobileSize]
  );
  return (
    <Stack>
      <VerticalLabels
        labelVariant="h6"
        labelColorKey={isLightText ? 'white' : 'darkText'}
        label={name}
        subLabelVariant="body1"
        subLabelColorKey={isLightText ? 'componentShade' : 'subtitleText'}
        subLabel={role}
      />
      <PermissionView permissionKey={PermissionKey.CompaniesList}>
        <AppTypography
          variant="caption"
          $colorKey={isLightText ? 'componentShade' : 'subtitleText'}
        >
          {companyName}
        </AppTypography>
      </PermissionView>
    </Stack>
  );
}
