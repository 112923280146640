import { Group, useSoftDeleteGroup } from '@beeriot/api-client';
import { Stack, styled, Button } from '@mui/material';
import {
  AppTypography,
  AppIcon,
  PermissionView,
  PermissionKey,
} from '../../../base';
import { FormattedMessage } from 'react-intl';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { useCallback, useState } from 'react';
import { ConfirmGroupDeleteDialog } from '../ConfirmGroupDeleteDialog';
import { EditGroupNameDialog } from '../EditGroupNameDialog';

const StyledStack = styled(Stack)({
  justifyContent: 'space-between',
  margin: '1rem',
  marginBottom: '1.5rem',
});

const StyledAppIcon = styled(AppIcon)({
  padding: '0rem 0.5rem',
});

export interface GroupHeaderProps {
  group: Group;
}

export function GroupHeader({ group }: GroupHeaderProps) {
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const deleteGroup = useSoftDeleteGroup();

  const handleDeleteGroup = useCallback(
    (groupToDelete: Group | null) => {
      if (groupToDelete) {
        deleteGroup.mutate(groupToDelete);
        while (deleteGroup.isLoading) {
          console.log('loading... ');
        }
        setTimeout(() => {
          setOpenDelete(false);
        }, 1500);
      }
    },
    [deleteGroup]
  );

  return (
    <StyledStack direction="row">
      <Button onClick={() => setOpenEdit(true)}>
        <AppTypography variant="h6" $colorKey="darkText" $bold>
          {group.name}
          <StyledAppIcon $colorKey="highlight3" icon={'pencil-alt'} />
        </AppTypography>
      </Button>
      <PermissionView
        permissionKey={PermissionKey.SitesOrganizeSitesDeleteRegionGroup}
      >
        <Button onClick={() => setOpenDelete(true)}>
          <AppTypography variant="body1" $colorKey="error" $bold>
            <StyledAppIcon $colorKey="error" icon={faTrashAlt} size="lg" />
            <FormattedMessage
              id="organize-sites-group-list-delete-group"
              defaultMessage="Delete Group"
              description="Button to delete the group"
            />
          </AppTypography>
        </Button>
      </PermissionView>
      <EditGroupNameDialog
        group={group}
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        isChildGroup={true}
      />
      <ConfirmGroupDeleteDialog
        mutation={deleteGroup}
        group={group}
        open={openDelete}
        confirm={handleDeleteGroup}
        cancel={() => setOpenDelete(false)}
        isGroupType={false}
      />
    </StyledStack>
  );
}
