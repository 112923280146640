import { FormattedMessage, useIntl } from 'react-intl';
import { UseMutationResult } from 'react-query';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  styled,
} from '@mui/material';
import { Brand, GQLBeerBrand } from '@beeriot/api-client';
import { AppTypography, LoadingButton } from '../../base';

const StyledDialogContent = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.base.main,
}));

const WarningContainer = styled(Box)({
  marginTop: '1rem',
});

const DividerContainer = styled(Box)({
  padding: '0',
});

const StyledDivider = styled(Divider)({
  borderBottomWidth: '2px',
});

export interface ConfirmBrandDeleteDialogProps {
  mutation: UseMutationResult<Brand | null, unknown, GQLBeerBrand, unknown>;
  brand: GQLBeerBrand;
  open: boolean;
  cancel: () => void;
}

export function ConfirmBrandDeleteDialog({
  mutation,
  brand,
  open,
  cancel,
}: ConfirmBrandDeleteDialogProps) {
  const intl = useIntl();

  const warningMessage = intl.formatMessage({
    id: 'common-delete-warning-message',
    description: 'Warning to let user know the effect deleting this will have',
    defaultMessage: 'Warning, this action cannot be undone.',
  });

  const confirmLabel = intl.formatMessage({
    id: 'common-remove-button',
    description: 'Label for confirmation button',
    defaultMessage: 'Yes, Remove',
  });

  const cancelLabel = intl.formatMessage({
    id: 'common-cancel-button',
    description: 'Label for cancel button',
    defaultMessage: 'Cancel',
  });

  return (
    <Dialog open={open}>
      <StyledDialogTitle>
        <FormattedMessage
          id="edit-companies-delete-brand-dialog-title"
          description="Title for brand deletion"
          defaultMessage="Remove Brand"
        />
      </StyledDialogTitle>
      <DividerContainer>
        <StyledDivider />
      </DividerContainer>
      <StyledDialogContent>
        <Box>
          <AppTypography>
            <FormattedMessage
              id="edit-companies-delete-brand-dialog-content"
              description="Content text for brand deletion"
              defaultMessage="Are you sure you want to remove the brand {brandName}?"
              values={{ brandName: `'${brand?.name}'` ?? '' }}
            />
          </AppTypography>
        </Box>
        <WarningContainer>
          <AppTypography $colorKey="warning">{warningMessage}</AppTypography>
        </WarningContainer>
        {mutation.isError && (
          <WarningContainer>
            <AppTypography $colorKey="error">
              {mutation.error as string}
            </AppTypography>
          </WarningContainer>
        )}
      </StyledDialogContent>
      <DialogActions>
        <Button color="shade" variant="contained" onClick={cancel}>
          {cancelLabel}
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          onClick={() => mutation.mutate(brand)}
          isLoading={mutation.isLoading}
        >
          {confirmLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
