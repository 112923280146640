import { GQLSystem } from '../../generated/gql';
import { CompletedTelemetryExportRecord, systemStore } from './systemStore';

export const setActiveSystem = (activeSystem?: GQLSystem) => {
  // systemStore.reset();
  systemStore.update((s) => ({ ...s, activeSystem: activeSystem ?? null }));
};


export const setCompletedTelemetryExport = (record?: CompletedTelemetryExportRecord) => {
  systemStore.update((s) => ({ ...s, completedTelemetryExport: record ?? null }));
};
