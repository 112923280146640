import { useMutation, useQuery } from 'react-query';
import { AnalyticsApi } from '../generated';
import { appConfiguration } from './configuration';
import { AnalyticMeasureName } from '../state/analyticsStore';

export const analyticsApi = new AnalyticsApi(appConfiguration);

export const aggregations = ['hourly', 'daily', 'weekly', 'monthly'] as const;
export type Aggregation = typeof aggregations[number];

export const measures: readonly AnalyticMeasureName[] = [
  AnalyticMeasureName.degreeOfEfficiency,
  AnalyticMeasureName.volumeFiltered,
  AnalyticMeasureName.totalStabilizedBeer,
  AnalyticMeasureName.waterConsumption,
  AnalyticMeasureName.electricalEnergy,
  AnalyticMeasureName.peroxideConsumption,
  AnalyticMeasureName.membraneCleanerKg,
  AnalyticMeasureName.netCapacity,
  AnalyticMeasureName.electricalCost,
  AnalyticMeasureName.waterCost,
  AnalyticMeasureName.dawaterCost,
  AnalyticMeasureName.causticCost,
  AnalyticMeasureName.peroxideCost,
  AnalyticMeasureName.acidicCost,
  AnalyticMeasureName.membraneCleanerCost,
  AnalyticMeasureName.coolingEnergyCost,
  AnalyticMeasureName.enzymaticCost,
  AnalyticMeasureName.totalCost,
  AnalyticMeasureName.avgCost,
  AnalyticMeasureName.clusterTestFailurePct,
  AnalyticMeasureName.hazeCentrifugeOut,
  AnalyticMeasureName.hazeFilterOut,
  AnalyticMeasureName.coolingEnergy,
  AnalyticMeasureName.enzymaticConsumption,
  AnalyticMeasureName.dawaterConsumption,
  AnalyticMeasureName.causticConsumption,
  AnalyticMeasureName.acidicChemical,
] as const;

async function getAnalytics(
  siteId: string | undefined,
  startDate: string,
  endDate: string,
  aggregation: Aggregation,
  systemIds?: string[],
  brandNames?: string[]
) {
  if (!siteId ) return null;
  const systemIdsStr =
    systemIds && systemIds.length > 0 ? systemIds.join() : undefined;
  const brandNamesStr =
    brandNames && brandNames.length > 0 ? brandNames.join() : undefined;

  const res = await analyticsApi.getAnalyticsByCompanySiteId(
    siteId,
    startDate,
    endDate,
    aggregation,
    systemIdsStr,
    brandNamesStr
  );
  return res.data;
}

export function useAnalytics(
  siteId: string | undefined,
  startDate: string,
  endDate: string,
  aggregation: Aggregation,
  systemIds?: string[],
  brandNames?: string[]
) {
  return useQuery(
    [
      'analytics',
      siteId,
      startDate,
      endDate,
      aggregation,
      systemIds,
      brandNames,
    ],
    () =>
      getAnalytics(
        siteId,
        startDate,
        endDate,
        aggregation,
        systemIds,
        brandNames
      )
  );
}

async function getAnalyticsExport(request: AnalyticsExportRequest) {
  if (!request.siteId) return null;
  const res = await analyticsApi.getAnalyticsExport(
    request.siteId,
    request.startDate,
    request.endDate,
    request.measures.join(),
    request.systemIds.join(),
    request.brandIds?.join()
  );
  return res.data.presignedUrl;
}

interface AnalyticsExportRequest {
  siteId?: string;
  startDate: string;
  endDate: string;
  measures: AnalyticMeasureName[];
  systemIds: string[];
  brandIds: string[];
}

export function useGetAnalyticsExport(
  onSuccess: (url: string) => void,
  onError: (error: Error) => void
) {
  return useMutation(
    async (request: AnalyticsExportRequest) => {
      return getAnalyticsExport(request);
    },
    {
      onSuccess: (data) =>
        data ? onSuccess(data) : onError(new Error('No presigned url found')),
      onError: onError,
    }
  );
}
