/* tslint:disable */
/* eslint-disable */
/**
 * Pall IoT Beer
 * IoT Beer Unified Api using Micro-services pattern <br/> <br/>  [Data Model](https://pall-corp.atlassian.net/wiki/spaces/IBD/pages/2059403265/Domain+Data+Model/) <br><br> [Application Design](https://pall-corp.atlassian.net/wiki/spaces/IBD/pages/2066612225/Application+Backend/) <br><br> [Pall Corporation Github](https://github.com/orgs/pallcorporation/dashboard/) <br><br> [Open API Specification - DEV - JSON](https://d382zc847wamcs.cloudfront.net/open-api.json) <br><br> [Open API Specification - DEV - YAML](https://d382zc847wamcs.cloudfront.net/open-api.yaml) <br><br> [Open API Specification - Integration - JSON](https://d1xykk5obgtp95.cloudfront.net/open-api.json) <br><br> [Open API Specification - Integration - YAML](https://d1xykk5obgtp95.cloudfront.net/open-api.yaml) <br><br> [Generate API Token - Link](https://github.com/mitchboulay/cognito-authentication/blob/main/README.md) <br><br> [Development Frontend - Link](https://dev.beeriot.pall.net/) <br><br> [Integration Frontend - Link](https://integration.beeriot.pall.net/)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: vladimir_khazin@pall.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from "./configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';

export const BASE_PATH = "https://dev-api.beeriot.pall.net".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: AxiosRequestConfig;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}
