import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useActiveAppEntities } from '@beeriot/api-client';
import { Button, Stack, styled } from '@mui/material';
import {
  ControlBox,
  IconLabel,
  PermissionView,
  PermissionKey,
  AppBreadcrumbsContainer,
  RoutingBreadcrumbs,
} from '../../../base';
import { RouterLink } from '../../../navigation';
import { useResponsiveSizes } from '../../../utils/responsive-design/useResponsiveSizes';
import { SitesFilterForm, useShowSiteFilterForm } from '../../../sites';
import { SitesFiltersFormButton } from '../../../sites/filters-dialog/SitesFiltersFormButton';

const StyledControlBox = styled(ControlBox)({
  gap: '1rem',
  padding: '0.5rem 1rem',
});

const EditOrganizeStack = styled(Stack)({
  marginLeft: 'auto',
  gap: '1rem',
});

const StyledLink = styled(RouterLink)({
  height: '100%',
});

const StyledButton = styled(Button)({
  height: '100%',
});

export function SitesControlBox() {
  const { isMobileSize, isSmallTabletSize } = useResponsiveSizes();
  const showFiltersForm = useShowSiteFilterForm();
  const intl = useIntl();
  const location = useLocation();
  const { activeCompany } = useActiveAppEntities();

  const isEditing = useMemo(
    () => location.pathname.includes('/edit-sites'),
    [location.pathname]
  );

  const editSites = intl.formatMessage({
    id: 'sites-edit-sites-button',
    description: 'Button to navigate to the edit sites page',
    defaultMessage: 'Edit Sites',
  });
  const edit = intl.formatMessage({
    id: 'common-edit-button',
    description: 'Button for edit',
    defaultMessage: 'Edit',
  });
  const organizeSites = intl.formatMessage({
    id: 'sites-organize-sites-button',
    description: 'Button to navigate to the site organization page',
    defaultMessage: 'Organize Sites',
  });
  const organize = intl.formatMessage({
    id: 'sites-organize-button',
    description: 'Button to navigate to the site organization page, shorthand',
    defaultMessage: 'Organize',
  });

  return (
    <Stack direction="row">
      {!isMobileSize && activeCompany && (
        <PermissionView permissionKey={PermissionKey.CompaniesList}>
          <AppBreadcrumbsContainer>
            <RoutingBreadcrumbs />
          </AppBreadcrumbsContainer>
        </PermissionView>
      )}
      <StyledControlBox>
        {showFiltersForm ? <SitesFilterForm /> : <SitesFiltersFormButton />}
        <EditOrganizeStack direction="row">
          <PermissionView permissionKey={PermissionKey.SitesEditSites}>
            {isEditing ? (
              <StyledLink to={`/companies/${activeCompany?.id}/sites`}>
                <StyledButton variant="contained" color={'highlight2'}>
                  <IconLabel
                    icon="check-double"
                    variant="body1"
                    label={
                      <FormattedMessage
                        id="sites-edit-sites-done-editing-button"
                        description="Button to navigate back to sites page when done editing"
                        defaultMessage="Done Editing"
                      />
                    }
                  />
                </StyledButton>
              </StyledLink>
            ) : (
              <StyledLink
                to={`/companies/${activeCompany?.id}/sites/edit-sites`}
              >
                <StyledButton variant="contained">
                  <IconLabel
                    $whiteSpace="nowrap"
                    icon="pen-to-square"
                    variant="body1"
                    label={isMobileSize || isSmallTabletSize ? edit : editSites}
                  />
                </StyledButton>
              </StyledLink>
            )}
          </PermissionView>
          {!isEditing && (
            <PermissionView permissionKey={PermissionKey.SitesOrganizeSites}>
              <StyledLink
                to={`/companies/${activeCompany?.id}/sites/organize-sites`}
              >
                <StyledButton variant="contained">
                  <IconLabel
                    $whiteSpace="nowrap"
                    icon="network-wired"
                    variant="body1"
                    label={
                      isMobileSize || isSmallTabletSize
                        ? organize
                        : organizeSites
                    }
                  />
                </StyledButton>
              </StyledLink>
            </PermissionView>
          )}
        </EditOrganizeStack>
      </StyledControlBox>
    </Stack>
  );
}
