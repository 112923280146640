import { FormattedMessage } from 'react-intl';
import { useObservable } from '@ngneat/use-observable';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  styled,
} from '@mui/material';
import { activeSystem$ } from '@beeriot/api-client';
import { EditSystemForm } from './EditSystemForm';

const StyledDialogContent = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 0,
});

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: '0.5rem 1rem',
  backgroundColor: theme.palette.base.main,
  borderTopRightRadius: theme.shape.borderRadius,
  borderTopLeftRadius: theme.shape.borderRadius,
}));

const DividerContainer = styled(Box)({
  padding: '0',
});

const StyledDivider = styled(Divider)({
  borderBottomWidth: '2px',
});

export interface EditSystemDialogProps {
  open: boolean;
  onClose: () => void;
}

export function EditSystemDialog({ open, onClose }: EditSystemDialogProps) {
  const [activeSystem] = useObservable(activeSystem$);

  return activeSystem ? (
    <Dialog open={open}>
      <StyledDialogTitle>
        <FormattedMessage
          id="edit-system-dialog-title"
          description="Title for edit system dialog"
          defaultMessage="Edit System"
        />
      </StyledDialogTitle>
      <DividerContainer>
        <StyledDivider />
      </DividerContainer>
      <StyledDialogContent>
        <EditSystemForm system={activeSystem} onClose={onClose} />
      </StyledDialogContent>
    </Dialog>
  ) : null;
}
