/* eslint-disable @typescript-eslint/no-explicit-any */
import { InfiniteData, QueryClient, QueryKey } from 'react-query';

// For use within an onMutate call of a react-query useMutation
// T is a GQL query e.g. GQLListBeerBrandsQuery
// K is the GQL model for T e.g. GQLBeerBrand
export async function optimisticUpdateInfiniteData<T extends object, K>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  listKey: string,
  mutatedModel: K,
  idKey = 'id'
) {
  await queryClient.cancelQueries(queryKey);
  const previousState = queryClient.getQueryData<InfiniteData<T>>(queryKey);
  const newData = previousState
    ? { ...previousState }
    : { pages: [], pageParams: [] };

  let updatedIdx = -1;
  const updatedPageIdx =
    previousState?.pages.findIndex((page) => {
      updatedIdx =
        (page as any)[listKey]?.findIndex(
          (x: K) => (x as any)?.[idKey] === (mutatedModel as any)[idKey]
        ) ?? -1;
      return updatedIdx !== -1;
    }) ?? -1;

  if (updatedPageIdx !== -1 && updatedIdx !== -1) {
    const newPage: (K | null)[] | null | undefined = (
      newData.pages[updatedPageIdx] as any
    )[listKey];
    if (newPage) {
      newPage[updatedIdx] = mutatedModel;
      (newData.pages[updatedPageIdx] as any)[listKey] = newPage;
      queryClient.setQueryData(queryKey, newData);
    }
  }

  return { previousState };
}
