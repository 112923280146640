import {
  TextareaAutosize,
  TextareaAutosizeProps,
  Stack,
  styled,
} from '@mui/material';
import { RegisterOptions } from 'react-hook-form';
import { useValidationRules } from '../../../form/useValidationRules';

const TextInputContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  padding: '0.2rem',
  borderRadius: '0.5rem',
  border: 'none',
  width: '100%',
  fontFamily: theme.typography.fontFamily,
}));

const StyledTextAreaInput = styled(TextareaAutosize)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  padding: '1rem',
  border: 'none',
  width: '100%',
  fontWeight: 'bold',
  fontFamily: theme.typography.fontFamily,
  '::placeholder': {
    color: theme.palette.lightText.main,
  },
}));

export interface SectionTextAreaInputProps extends TextareaAutosizeProps {
  name: string;
  className?: string;
  validationOptions?:
    | Partial<RegisterOptions>
    | ((values: Record<string, unknown>) => Partial<RegisterOptions>);
}

export function SectionTextAreaInput({
  name,
  className,
  validationOptions,
  placeholder,
  required,
  disabled,
  ...inputProps
}: SectionTextAreaInputProps) {
  const { rules, register } = useValidationRules(required, validationOptions);

  return (
    <TextInputContainer>
      <StyledTextAreaInput
        {...inputProps}
        {...register(name, rules)}
        disabled={disabled ?? false}
        className={className}
        placeholder={placeholder}
      />
    </TextInputContainer>
  );
}
