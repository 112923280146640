import { SettingsUsersLanguageEnum } from '@beeriot/api-client';

export interface Language {
  label: string;
  code: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messages: any;
}

export const languages = {
  german: {
    label: 'German',
    code: 'de',
    messages: import('../locales/de.json'),
  },
  english: {
    label: 'English',
    code: 'en',
    messages: import('../locales/en.json'),
  },
  englishUS: {
    label: 'English (American)',
    code: 'en-US',
    messages: import('../locales/en-US.json'),
  },
  spanish: {
    label: 'Spanish',
    code: 'es',
    messages: import('../locales/es.json'),
  },
  french: {
    label: 'French',
    code: 'fr',
    messages: import('../locales/fr.json'),
  },
  italian: {
    label: 'Italian',
    code: 'it',
    messages: import('../locales/it.json'),
  },
  japanese: {
    label: 'Japanese',
    code: 'ja',
    messages: import('../locales/ja.json'),
  },
  dutch: {
    label: 'Dutch',
    code: 'nl',
    messages: import('../locales/nl.json'),
  },
  russian: {
    label: 'Russian',
    code: 'ru',
    messages: import('../locales/ru.json'),
  },
  chinese: {
    label: 'Chinese',
    code: 'zh',
    messages: import('../locales/zh.json'),
  },
};

export function getLanguage(lang?: SettingsUsersLanguageEnum | null): Language {
  const langKey = Object.keys(languages).find(
    (x) => languages[x as keyof typeof languages].code === lang
  );
  return langKey
    ? languages[langKey as keyof typeof languages]
    : languages.englishUS;
}

export type LocaleMessages = Awaited<typeof languages.englishUS.messages>;
