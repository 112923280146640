import { SelectOption } from '../form';
import { Role } from '../enums';
import {
  SettingsSitesCurrencyEnum,
  SettingsSitesElectricUnitsEnum,
  SettingsSitesVolumeUnitsEnum,
  SettingsUsersPressureUnitsEnum,
  SettingsUsersLanguageEnum,
} from '@beeriot/api-client';
import { format } from 'date-fns';
import { fullDateFormats, monthYearFormats } from '../utils/fullDateFormats';

export const electricOptions: SelectOption[] = Object.entries(
  SettingsSitesElectricUnitsEnum
).map(([key, value]) => ({
  value: value,
  label: key,
}));

export const volumeOptions: SelectOption[] = Object.entries(
  SettingsSitesVolumeUnitsEnum
).map(([key, value]) => ({
  value: value,
  label: key,
}));

export const pressureOptions: SelectOption[] = Object.entries(
  SettingsUsersPressureUnitsEnum
).map(([key, value]) => ({
  value: value,
  label: key,
}));

export const currencyOptions: SelectOption[] = Object.entries(
  SettingsSitesCurrencyEnum
).map(([key, value]) => ({
  value: value,
  label: key,
}));

export function getLanguageOptions(
  intlLanguage: (value: SettingsUsersLanguageEnum) => string
): SelectOption[] {
  return Object.entries(SettingsUsersLanguageEnum).map(([key, value]) => ({
    value: value,
    label: intlLanguage(value as SettingsUsersLanguageEnum),
  }));
}

const exampleAM = new Date('2022-01-01T09:01:00');
const examplePM = new Date('2022-01-01T18:23:00');

export const formattedDateTimeFormatDropdownLabels = (
  values: readonly string[],
  timeFormat?: boolean
): SelectOption[] => {
  return values.map((x) => ({
    label: `${x} (${format(exampleAM, x)}${
      timeFormat ? ` / ${format(examplePM, x)}` : ''
    })`,
    value: x,
  }));
};

export const dateFormatOptions =
  formattedDateTimeFormatDropdownLabels(fullDateFormats);
export const monthFormatOptions =
  formattedDateTimeFormatDropdownLabels(monthYearFormats);

export const failureFrequencyAlertOptions = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
];

// TODO: replace with actual data options
// TODO: add localization
export const userRoleOptions = [
  { value: Role.Admin, label: 'Admin' },
  { value: Role.Operator, label: 'Operator' },
  { value: Role.Supervisor, label: 'Supervisor' },
];

export interface UnitOfMeasure {
  pressure: string;
  volume: string;
}

export enum PallNotificationType {
  ProductRecall = 'ProductRecall',
}

export enum PallNotificationMethod {
  Alert = 'Alert',
}

export enum PallNotificationRoles {
  Operators = 'Operators',
}

export const pallNotificationTypeOptions: SelectOption[] = Object.values(
  PallNotificationType
).map((x) => ({
  value: PallNotificationType[x],
  label: PallNotificationType[x],
}));

export const pallNotificationMethodOptions: SelectOption[] = Object.values(
  PallNotificationMethod
).map((x) => ({
  value: PallNotificationMethod[x],
  label: PallNotificationMethod[x],
}));

export const pallNotificationRolesOptions: SelectOption[] = Object.values(
  PallNotificationRoles
).map((x) => ({
  value: PallNotificationRoles[x],
  label: PallNotificationRoles[x],
}));
