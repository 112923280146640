import { activeCompany$, useCompanies } from '@beeriot/api-client';
import { AppTypography } from '../../../AppTypography';
import { Breadcrumb } from '../../Breadcrumb';
import { useObservable } from '@ngneat/use-observable';
import { BreadcrumbsCompanyDropdown } from '../../dropdown/BreadcrumbsCompanyDropdown';
import { useCallback } from 'react';

export function RoutingCompanySelectorBreadcrumb() {
  const [activeCompany] = useObservable(activeCompany$);
  const companies = useCompanies('');

  return (
    <Breadcrumb
      $isDropdown={true}
      $stripeColorKey={'highlight6'}
      renderDropdownContent={useCallback((open:boolean, setOpen:(o:boolean) => void) => (
        <BreadcrumbsCompanyDropdown
          companies={companies}
          onClose={() => setOpen(false)}
        />
      ), [companies])}
    >
      <AppTypography $bold={true}>{activeCompany?.name ?? ''}</AppTypography>
    </Breadcrumb>
  );
}
