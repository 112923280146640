import { useResponsiveValues } from '../../../utils';
import { SystemKpiGridAreaTemplate } from '../base-card-common/grids/SystemKpiHookProps';
import { CBSSystemDetailCardBase } from './CBSSystemDetailCardBase';
import { SystemCardBaseProps } from '../base-card-common/SystemCardBase';
import { useGQLSystemLiveKPI } from '@beeriot/api-client';

const templateArea: SystemKpiGridAreaTemplate = [
  ['commonDegreeOfEfficiency', 'throughputPerCassette'],
  ['stabilized', 'actualFlow'],
  ['cipCycles', 'actualFlow'],
  ['cipCyclesSinceRefill', 'actualFlow'],
];

const mobileTemplateArea: SystemKpiGridAreaTemplate = [
  ['commonDegreeOfEfficiency', 'throughputPerCassette'],
  ['stabilized', 'cipCycles'],
  ['cipCyclesSinceRefill', 'cipCyclesSinceRefill'],
  ['actualFlow', 'actualFlow'],
];

export function CBSProcessDetailCard(props: SystemCardBaseProps) {
  const { boldLabelTitles = true, system } = props;
  const gridTemplateAreas = useResponsiveValues<SystemKpiGridAreaTemplate>({
    xxs: mobileTemplateArea,
    md: templateArea,
  });
  useGQLSystemLiveKPI(system);
  return (
    <CBSSystemDetailCardBase
      data-element-id={'cbs-process-details'}
      {...props}
      boldLabelTitles={boldLabelTitles}
      gridTemplateAreas={gridTemplateAreas}
    />
  );
}
