import { styled, Stack } from '@mui/material';
import { RootPage } from '../RootPage';
import {
  AnalyticsControlBox,
  MobileAnalyticsFiltersForm,
} from './analytics-control-box';
import { GlobalFooter, ScrollStack } from '../../base';
import { useResponsiveSizes } from '../../utils/responsive-design/useResponsiveSizes';
import { SuspenseAnalyticsContent } from './SuspenseAnalyticsContent';

const ContentStack = styled(Stack)({
  overflow: 'hidden',
  height: '100%',
});

export function AnalyticsPage() {
  const { isMobileSize } = useResponsiveSizes();

  return (
    <RootPage>
      {isMobileSize ? <MobileAnalyticsFiltersForm /> : <AnalyticsControlBox />}
      <ContentStack>
        <ScrollStack>
          <SuspenseAnalyticsContent />
          <GlobalFooter />
        </ScrollStack>
      </ContentStack>
    </RootPage>
  );
}
