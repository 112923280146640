import { Paper, keyframes, styled } from '@mui/material';

const loadingShimmer = keyframes({
  '0%': {
    backgroundPosition: '-468px 0',
  },
  '100%': {
    backgroundPosition: '468px 0',
  },
});

export const LoadingPaper = styled(Paper)<{
  className?: string;
}>(({ theme, className }) => ({
  className: className,
  animation: `${loadingShimmer} 2s linear infinite`,
  animationFillMode: 'forwards',
  background: `linear-gradient(to right, ${theme.palette.componentBase.main} 8%, ${theme.palette.componentShade.main} 38%, ${theme.palette.componentBase.main} 54%)`,
  backgroundSize: '1000px 640px',
}));
