import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { UseMutationResult } from 'react-query';
import {
  ReportDefinition,
  useRefetchReportDefinitions,
} from '@beeriot/api-client';
import { AppDialog } from '../../base/AppDialog';
import { useAppRoutePaths } from '../../app-routing/utils';

interface ReportDeletionConfirmationDialogProps {
  deleteMutation: UseMutationResult<ReportDefinition, unknown, string, unknown>;
  shouldNavigate?: boolean;
}

export function ReportDeletionConfirmationDialog({
  deleteMutation,
  shouldNavigate,
}: ReportDeletionConfirmationDialogProps) {
  const navigate = useNavigate();
  const appPaths = useAppRoutePaths();
  const refetchReportDefinitions = useRefetchReportDefinitions();
  return (
    <AppDialog
      dialogTitle={
        deleteMutation.isSuccess ? (
          <FormattedMessage
            id="common-success-label"
            description="Label for a successful event"
            defaultMessage="Success!"
          />
        ) : (
          <FormattedMessage
            id="common-error-label"
            description="Label for an event error"
            defaultMessage="Error"
          />
        )
      }
      dialogContent={
        deleteMutation.isSuccess ? (
          <FormattedMessage
            id="recurring-report-dialog-confirm-delete-success"
            description="Label for the successful deletion of a recurring report"
            defaultMessage="Recurring report successfully deleted"
          />
        ) : (
          <FormattedMessage
            id="recurring-report-dialog-confirm-delete-error"
            description="Label for an error during deletion of a recurring report"
            defaultMessage="An error occurred while deleting the recurring report. Please try again later."
          />
        )
      }
      open={deleteMutation.isSuccess || deleteMutation.isError}
      confirm={() => {
        deleteMutation.reset();
        refetchReportDefinitions();
        if (shouldNavigate) {
          navigate(appPaths.settingsReportingPath);
        }
      }}
      confirmLabel={
        <FormattedMessage
          id="common-ok-button"
          defaultMessage="Ok"
          description="label for ok button"
        />
      }
      showCancelButton={false}
    />
  );
}
