import { createStore, select, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';

export interface LineDocumentsProps {
  documentsSearchText: string;
  showDeletedDocuments: boolean;
  documentUploadProgress: number;
}

export const lineDocumentsStore = createStore(
  { name: 'line-documentation' },
  withProps<LineDocumentsProps>({
    documentsSearchText: '',
    showDeletedDocuments: false,
    documentUploadProgress: 0,
  })
);

persistState(lineDocumentsStore, {
  key: 'line-documentation',
  storage: sessionStorageStrategy,
});

export const documentsSearchText$ = lineDocumentsStore.pipe(
  select((state) => {
    return state.documentsSearchText;
  })
);

export const documentUploadProgress$ = lineDocumentsStore.pipe(
  select((state) => {
    return state.documentUploadProgress;
  })
);

export const showDeletedDocuments$ = lineDocumentsStore.pipe(
  select((state) => {
    return state.showDeletedDocuments;
  })
);
