import { FormattedMessage } from 'react-intl';
import { Box, styled, Chip } from '@mui/material';
import { AppTypography } from '../../base/AppTypography';

const StyledGroupHeader = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const StyledTitle = styled(AppTypography)({
  marginRight: '1rem',
});

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.shade.main,
  color: theme.palette.white.main,
}));

interface SiteCardListSectionHeaderTextProps {
  siteCount: number;
}

function SiteCardListSectionHeaderText({
  siteCount,
}: SiteCardListSectionHeaderTextProps) {
  return siteCount === 1 ? (
    <FormattedMessage
      id="sites-card-list-section-header-singular"
      description="Section header for sites list in singular"
      defaultMessage="{siteCount} Site"
      values={{ siteCount: siteCount }}
    />
  ) : (
    <FormattedMessage
      id="sites-card-list-section-header-plural"
      description="Section header for sites list in plural"
      defaultMessage="{siteCount} Sites"
      values={{ siteCount: siteCount }}
    />
  );
}

interface SiteCardListSectionHeaderProps {
  siteCount: number;
  siteLetter: string;
}

export function SiteCardListSectionHeader({
  siteCount,
  siteLetter,
}: SiteCardListSectionHeaderProps) {
  return (
    <StyledGroupHeader>
      <StyledTitle variant="h6" $bold>
        {siteLetter}
      </StyledTitle>
      <StyledChip
        label={<SiteCardListSectionHeaderText siteCount={siteCount} />}
        size="small"
      />
    </StyledGroupHeader>
  );
}
