import { styled, Box } from '@mui/material';
import { Property } from 'csstype';
import { transientOptions } from '../utils/transientOptions';

export const BadgeListContainer = styled(
  Box,
  transientOptions
)<{ $gap?: Property.Gap; $useResponsiveSizing?: boolean }>(
  ({ theme, $gap, $useResponsiveSizing }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'end',
    gap: $gap ?? '1rem',
    [theme.breakpoints.down('md')]: {
      justifyContent: $useResponsiveSizing ? 'flex-end' : 'inherit',
    },
  })
);
