import { useMemo } from 'react';
import { useBatchSizeLabelGridItem } from './system-kpis/useBatchSizeLabelGridItem';
import {
  useBrandLabelGridItem,
  useEditBrandLabelGridItem,
} from './system-kpis/useBrandLabelGridItem';
import { useDiaFiltrationCompleteGridItem } from './system-kpis/useDiaFiltrationCompleteGridItem';
import { useOperationalClustersLabelGridItem } from './system-kpis/useOperationalClustersLabelGridItem';
import { useProcessedVolumeLabelGridItem } from './system-kpis/useProcessedVolumeLabelGridItem';
import { useProductionPercentCompleteGridItem } from './system-kpis/useProductionPercentCompleteGridItem';
import { useProgramLabelGridItem } from './system-kpis/useProgramLabelGridItem';
import { useRemainingVolumeLabelGridItem } from './system-kpis/useRemainingVolumeLabelGridItem';
import { useRunningStatusGridItem } from './system-kpis/useRunningStatusGridItem';
import { useRunningStatusTimelineGridItem } from './system-kpis/useRunningStatusTimelineGridItem';
import { useTimeRemainingLabelGridItem } from './system-kpis/useTimeRemainingLabelGridItem';
import { useTimeSinceStartGridItem } from './system-kpis/useTimeSinceStartGridItem';
import { SystemKpiHookProps } from '../SystemKpiHookProps';
import { useSystemKpiHookDefaultProps } from '../useSystemKpiHookDefaultProps';

export function useSystemKpiGridContent(
  props: Omit<
    SystemKpiHookProps,
    | 'showForSystemTypes'
    | 'hideOnMobileForSystemTypes'
    | 'NA'
    | 'gridArea'
    | 'activeKpis'
  >
) {
  const defaultHookProps = useSystemKpiHookDefaultProps(props);

  // kpi grid
  const batchSizeKpiItem = useBatchSizeLabelGridItem({
    ...defaultHookProps,
    gridArea: 'batchSize',
  });
  const brandNameKpiItem = useBrandLabelGridItem({
    ...defaultHookProps,
    gridArea: 'brand',
  });
  const editBrandNameKpiItem = useEditBrandLabelGridItem({
    ...defaultHookProps,
    gridArea: 'editBrand',
  });
  const diaFiltrationKpiItem = useDiaFiltrationCompleteGridItem({
    ...defaultHookProps,
    gridArea: 'diaFiltration',
  });
  const operationalClustersKpiItem = useOperationalClustersLabelGridItem({
    ...defaultHookProps,
    gridArea: 'operationalClusters',
  });
  const processedVolumeKpiItem = useProcessedVolumeLabelGridItem({
    ...defaultHookProps,
    gridArea: 'processedVolume',
  });
  const productionPercentCompleteKpiItem = useProductionPercentCompleteGridItem(
    {
      ...defaultHookProps,
      gridArea: 'percentCompleteIndicator',
    }
  );
  const programKpiItem = useProgramLabelGridItem({
    ...defaultHookProps,
    gridArea: 'program',
  });
  const remainingVolumeKpiItem = useRemainingVolumeLabelGridItem({
    ...defaultHookProps,
    gridArea: 'remainingVolume',
  });
  const runningStatusKpiItem = useRunningStatusGridItem({
    ...defaultHookProps,
    gridArea: 'runningState',
  });
  const prefiltrationRunningStatusKpiItem = useRunningStatusGridItem({
    ...defaultHookProps,
    gridArea: 'prefiltrationRunningState',
    prefiltrationState: true,
  });
  const finalFiltrationRunningStatusKpiItem = useRunningStatusGridItem({
    ...defaultHookProps,
    gridArea: 'finalFiltrationRunningState',
    finalFiltrationState: true,
  });
  const runningStatusTimelineKpiItem = useRunningStatusTimelineGridItem({
    ...defaultHookProps,
    gridArea: 'runningStatusTimeline',
  });
  const timeRemainingKpiItem = useTimeRemainingLabelGridItem({
    ...defaultHookProps,
    gridArea: 'timeRemaining',
  });
  const timeSinceStartKpiItem = useTimeSinceStartGridItem({
    ...defaultHookProps,
    gridArea: 'timeSinceStart',
  });

  const _gridContent = useMemo(() => {
    return (
      <>
        {batchSizeKpiItem}
        {brandNameKpiItem}
        {editBrandNameKpiItem}
        {diaFiltrationKpiItem}
        {operationalClustersKpiItem}
        {processedVolumeKpiItem}
        {productionPercentCompleteKpiItem}
        {programKpiItem}
        {remainingVolumeKpiItem}
        {runningStatusKpiItem}
        {prefiltrationRunningStatusKpiItem}
        {finalFiltrationRunningStatusKpiItem}
        {timeRemainingKpiItem}
        {timeSinceStartKpiItem}
        {runningStatusTimelineKpiItem}
      </>
    );
  }, [
    batchSizeKpiItem,
    brandNameKpiItem,
    editBrandNameKpiItem,
    diaFiltrationKpiItem,
    operationalClustersKpiItem,
    processedVolumeKpiItem,
    productionPercentCompleteKpiItem,
    programKpiItem,
    remainingVolumeKpiItem,
    runningStatusKpiItem,
    prefiltrationRunningStatusKpiItem,
    finalFiltrationRunningStatusKpiItem,
    timeRemainingKpiItem,
    timeSinceStartKpiItem,
    runningStatusTimelineKpiItem,
  ]);
  return _gridContent;
}
