import { ReactNode } from 'react';
import { AgnosticRouteObject } from '@remix-run/router';

export type RouteTabObject = AgnosticRouteObject & {
  path: string;
  label?: string;
  buttonElement?: ReactNode;
  hidden?: boolean;
  fallbackPath?: string;
  element: ReactNode;

  params?: {
    [k: string]: string | undefined;
  };
};

export const isRouteTabObject = function (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: RouteTabObject | any
): obj is RouteTabObject {
  if (obj.path || obj.label || obj.buttonElement || obj.element) {
    return true;
  }
  return false;
};
