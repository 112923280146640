import { useResponsiveValues } from '../../../../../utils/responsive-design/useResponsiveSizes';
import {
  CurrentStateLabel,
  CurrentStateLabelComponent,
} from '../../../../../base/CurrentStateLabel';
import { KPIGridItem } from '../kpi-grid/KPIGrid';
import { RunningState } from '../../../../../enums';
import { ReactNode, useMemo } from 'react';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { SystemCardTitle } from './SystemCardTitle';
import { Divider, styled } from '@mui/material';

const HeaderDivider = styled(Divider)({
  marginTop: '0.5rem',
});
HeaderDivider.displayName = 'SystemCardHeaderDivider';
const systemCardHeaderDivider = <HeaderDivider variant={'fullWidth'} />;

export interface SystemCardHeaderProps {
  gridArea?: string;
  cardTitle: string | undefined;
  runningState?: RunningState;
  showDivider?: boolean;
}
export interface SystemCardHeaderCustomContentProps {
  gridArea?: string;
  headerContent: ReactNode;
}
export function isCustomContentProps(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  x: any
): x is SystemCardHeaderCustomContentProps {
  if (x?.headerContent) {
    return true;
  }
  return false;
}

export const SystemCardHeader = (
  props: SystemCardHeaderProps | SystemCardHeaderCustomContentProps
) => {
  const { _headerContent, _cardTitle, _runningState, _gridArea, _showDivider } =
    extractPropsWithDefaults(props);
  const runningStatusIconSize = useResponsiveValues<SizeProp>({
    xxs: 'sm',
    md: 'lg',
  });

  const headerContent = useMemo(() => {
    const titleComponent = <SystemCardTitle cardTitle={_cardTitle} />;

    const _inner = _headerContent ?? (
      <>
        {!_runningState ? titleComponent : undefined}

        {_runningState ? (
          <CurrentStateLabel
            mainLabelOverride={titleComponent}
            labelComponentType={CurrentStateLabelComponent.KPIVerticalLabel}
            labelBold
            labelVariant={'subtitle2'}
            iconSize={runningStatusIconSize}
            state={_runningState}
            hideSubLabel={true}
          />
        ) : undefined}
      </>
    );

    return (
      <KPIGridItem gridArea={_gridArea}>
        {_inner}
        {_showDivider && systemCardHeaderDivider}
      </KPIGridItem>
    );
  }, [
    _headerContent,
    _cardTitle,
    _runningState,
    runningStatusIconSize,
    _gridArea,
    _showDivider,
  ]);

  return headerContent;
};
function extractPropsWithDefaults(
  props: SystemCardHeaderProps | SystemCardHeaderCustomContentProps
) {
  let _gridArea = 'header';
  let _headerContent!: ReactNode;
  let _cardTitle!: string | undefined;
  let _runningState!: RunningState | undefined;
  let _showDivider = false;
  if (isCustomContentProps(props)) {
    _headerContent = props.headerContent;
    _gridArea = props.gridArea ?? 'header';
  } else {
    _cardTitle = props.cardTitle;
    _runningState = props.runningState;
    _gridArea = props.gridArea ?? 'header';
    _showDivider = props.showDivider ?? false;
  }
  return { _headerContent, _cardTitle, _runningState, _gridArea, _showDivider };
}
