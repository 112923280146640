import {
  addMonths,
  addWeeks,
  addYears,
  getDay,
  getMonth,
  isFirstDayOfMonth,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { CalendarPickerView } from '@mui/x-date-pickers';
import { CalendarMode } from '@beeriot/api-client';

export const dateToStartOfView = (
  calendarMode: CalendarMode,
  selection: Date
) => {
  switch (calendarMode) {
    case CalendarMode.Year:
      return startOfYear(selection);
    case CalendarMode.Month:
      return startOfMonth(selection);
    case CalendarMode.Week:
      if (isFirstDayOfMonth(selection) && getMonth(selection) === 0) {
        const dayOfWeek = getDay(selection);
        return startOfWeek(selection, {
          weekStartsOn: dayOfWeek,
        });
      } else {
        return startOfWeek(selection);
      }
    default:
      return selection;
  }
};

export const previousPeriodFromCalendarMode = (
  calendarMode: CalendarMode,
  selection: Date
) => {
  switch (calendarMode) {
    case CalendarMode.Year:
      return addYears(selection, -1);
    case CalendarMode.Month:
      return addMonths(selection, -1);
    case CalendarMode.Week:
      return addWeeks(selection, -1);
    default:
      return selection;
  }
};

export const calendarModeCalendarPickerRecord: Record<
  CalendarMode,
  CalendarPickerView
> = {
  Day: 'day',
  Week: 'day',
  Month: 'month',
  Year: 'year',
};
