import type {} from '@mui/x-date-pickers/themeAugmentation';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { generateShadows } from './utils/generateShadows';

// import '../types/ColorKey';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxs: true;
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
    xxxl: true;
  }
}

export const colors = {
  white: '#ffffff',
  black: '#000000',
  lighterGrey: '#f4f5f6',
  lightGrey: '#e8e9eb',
  altLightGrey: '#e5e5e7',
  grey: '#909091',
  altDarkGrey: '#7b7a7a',
  darkGrey: '#636262',
  darkerGrey: '#2b2a2a',
  blue: '#0b66b1',
  componentBlue: '#3491ca',
  lighterBlue: '#d6e5f2',
  lightBlue: '#48acff',
  darkBlue: '#10385a',
  red: '#a74856',
  errorRed: '#eb1c1d',
  lightRed: '#e2c3c8',
  orange: '#f7a000',
  lightOrange: '#fce0ac',
  green: '#3fbb08',
  lightGreen: '#c0e9af',
  purple: '#cd1ceb',
  lightPurple: '#e2daff',
  pallOrange1: '#de9b51',
  pallOrange2: '#d07426',
  pallOrange3: '#d17530',
  pallBrown1: '#8e541d',
  pallBrown2: '#85471b',
  pallBrown3: '#4d2105',
  pallPurple1: '#702d6f',
  pallPurple2: '#75327d',
  pallPurple3: '#711852',
  pallTeal1: '#099fc4',
  pallTeal2: '#1295a1',
  pallTeal3: '#127475',
  pallTeal4: '#066073',
  pallTeal5: '#0c636b',
  pallTeal6: '#05495c',
  pallGreen1: '#3aa08b',
  pallGreen2: '#1e8d6f',
  pallGreen3: '#155d49',
  pallGreen4: '#03373d',
};

const baseTheme = responsiveFontSizes(
  createTheme({
    breakpoints: {
      keys: ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl'],
      values: {
        xxs: 0,
        xs: 425,
        sm: 600,
        md: 850,
        lg: 1100,
        xl: 1520,
        xxl: 1800,
        xxxl: 2200,
      },
    },
   
    shape: {
      borderRadius: 7,
    },
    typography: {
      fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
      button: {
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
      },
    },
    components: {
      MuiBackdrop: {
        styleOverrides: {
          root: {
            zIndex: 3,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            '&.MuiDialog-paper': {
              overflow: 'visible',
            },
          },
        },
      },
      MuiMonthPicker: {
        styleOverrides: {
          root: {
            '& .MuiTypography-root': {
              fontSize: '1rem',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '0.75rem 1rem',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            gap: '0.5rem',
          },
        },
      },
    },
    shadows: generateShadows(),
  })
);

export const appTheme = 
  createTheme(baseTheme, {
   
    palette: {
      background: {
        default: `linear-gradient(${colors.blue}, ${colors.componentBlue})`,
      },
      primary: { main: colors.blue },
      
      secondary: { main: colors.componentBlue, light: colors.lighterBlue },
      error: { main: colors.errorRed, light: colors.lightRed },
      warning: {
        main: colors.orange,
        light: colors.lightOrange,
        contrastText: colors.white,
      },
      info: { main: colors.blue },
      success: {
        main: colors.green,
        contrastText: colors.white,
        light: colors.lightGreen,
      },
      disabledPrimary: {
        main: colors.lighterBlue,
      },
      base: { main: colors.lightGrey },
      shade: {
        main: colors.altDarkGrey,
        dark: colors.darkGrey,
        contrastText: colors.white,
      },
      componentBase: { main: colors.lighterGrey },
      componentShade: { main: colors.altLightGrey },
      disabled: {
        main: colors.darkGrey,
        contrastText: colors.white,
      },
      highlight1: baseTheme.palette.augmentColor({ color: { main: colors.blue }, name: 'highlight1' }),
      highlight2: baseTheme.palette.augmentColor({ color: { main: colors.purple }, name: 'highlight2' }),
      highlight3: baseTheme.palette.augmentColor({ color: { main: colors.lightBlue }, name: 'highlight3' }),
      highlight4: baseTheme.palette.augmentColor({ color: { main: colors.red }, name: 'highlight4' }),
      highlight5: baseTheme.palette.augmentColor({ color: { main: colors.green }, name: 'highlight5' }),
      highlight6: baseTheme.palette.augmentColor({ color: { main: colors.orange }, name: 'highlight6' }),
      highlight7: baseTheme.palette.augmentColor({ color: { main: colors.darkBlue }, name: 'highlight7' }),
      highlight8: baseTheme.palette.augmentColor({ color: { main: colors.lightPurple }, name: 'highlight8' }),
      highlight9: baseTheme.palette.augmentColor({ color: { main: colors.grey }, name: 'highlight9' }),
      highlight10: baseTheme.palette.augmentColor({ color: { main: colors.darkerGrey }, name: 'highlight10' }),
      highlight11: baseTheme.palette.augmentColor({ color: { main: colors.pallOrange1 }, name: 'highlight11' }),
      highlight12: baseTheme.palette.augmentColor({ color: { main: colors.pallOrange2 }, name: 'highlight12' }),
      highlight13: baseTheme.palette.augmentColor({ color: { main: colors.pallOrange3 }, name: 'highlight13' }),
      highlight14: baseTheme.palette.augmentColor({ color: { main: colors.pallBrown1 }, name: 'highlight14' }),
      highlight15: baseTheme.palette.augmentColor({ color: { main: colors.pallBrown2 }, name: 'highlight15' }),
      highlight16: baseTheme.palette.augmentColor({ color: { main: colors.pallBrown3 }, name: 'highlight16' }),
      highlight17: baseTheme.palette.augmentColor({ color: { main: colors.pallPurple1 }, name: 'highlight17' }),
      highlight18: baseTheme.palette.augmentColor({ color: { main: colors.pallPurple2 }, name: 'highlight18' }),
      highlight19: baseTheme.palette.augmentColor({ color: { main: colors.pallPurple3 }, name: 'highlight19' }),
      highlight20: baseTheme.palette.augmentColor({ color: { main: colors.pallTeal1 }, name: 'highlight20' }),
      highlight21: baseTheme.palette.augmentColor({ color: { main: colors.pallTeal2 }, name: 'highlight21' }),
      highlight22: baseTheme.palette.augmentColor({ color: { main: colors.pallTeal3 }, name: 'highlight22' }),
      highlight23: baseTheme.palette.augmentColor({ color: { main: colors.pallTeal4 }, name: 'highlight23' }),
      highlight24: baseTheme.palette.augmentColor({ color: { main: colors.pallTeal5 }, name: 'highlight24' }),
      highlight25: baseTheme.palette.augmentColor({ color: { main: colors.pallTeal6 }, name: 'highlight25' }),
      highlight26: baseTheme.palette.augmentColor({ color: { main: colors.pallGreen1 }, name: 'highlight26' }),
      highlight27: baseTheme.palette.augmentColor({ color: { main: colors.pallGreen2 }, name: 'highlight27' }),
      highlight28: baseTheme.palette.augmentColor({ color: { main: colors.pallGreen3 }, name: 'highlight28' }),
      highlight29: baseTheme.palette.augmentColor({ color: { main: colors.pallGreen4 }, name: 'highlight29' }),
      highlight30: baseTheme.palette.augmentColor({ color: { main: colors.orange }, name: 'highlight30' }),
      highlight31: baseTheme.palette.augmentColor({ color: { main: colors.darkBlue }, name: 'highlight31' }),
      highlight32: baseTheme.palette.augmentColor({ color: { main: colors.lightPurple }, name: 'highlight32' }),
      link: { main: colors.lightBlue },
      lightText: { main: colors.grey },
      darkText: { main: colors.darkerGrey },
      subtitleText: { main: colors.grey },
      white: { main: colors.white },
      black: { main: colors.black },
    },
   
  });


appTheme.typography = {
  ...appTheme.typography,
  [appTheme.breakpoints.down('sm')]: {
    fontSize: 16,
    lineHeight: 1,
  },
};

appTheme.components = {
  ...appTheme.components,
  MuiCssBaseline: {
    ...appTheme.components?.MuiCssBaseline,
    styleOverrides: `
    body,#root {
      background: linear-gradient(${appTheme.palette.primary.main}, ${appTheme.palette.secondary.main});
      background-color: ${appTheme.palette.primary.main};
    }
    html {
      background-color: ${appTheme.palette.secondary.main};
    }
    `,
  },
};
