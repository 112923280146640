import { createStore, select, withProps } from '@ngneat/elf';
import { StateStorage, persistState } from '@ngneat/elf-persist-state';
import { ChartElementType } from './ChartElementType';
import * as localForage from 'localforage';
import { exampleReports } from './example-data/exampleReports';

interface GraphData extends Record<string, unknown> {
  name: string;
}

export interface ChartElement {
  dataKey: string;
  color?: string;
  type: ChartElementType;
  yAxis?: 'left' | 'right';
}

export interface GraphModel {
  id: string;
  title: string;
  graphHeight?: number;
  graphWidth?: number;
  data: GraphData[];
  renderedPng?: string;
  renderedPageNumber?: number;
  xAxisLabel?: string;
  xAxisTableHeader?: string;
  xAxisTickAngle?: number;
  xAxisTickAnchor?: 'start' | 'middle' | 'end';
  xAxisTicks?: string[];
  yAxisLabel?: string;
  rightYAxisLabel?: string;
  chartElements: ChartElement[];
  hideGraph?: boolean;
  hideTable?: boolean;
  landscape?: boolean;
}

export interface ReportCover {
  title?: string;
  siteName?: string;
  systemName?: string;
  customer?: string;
  createdBy?: string;
  dateRange?: string;
  generatedDate?: string;
}

export interface ReportSummaryComparableKPI {
  title: string;
  unit: string;
  currentValue: number;
  previousValue: number;
  percentageDelta: number;
  prependUnit?: boolean;
  noUnitSpace?: boolean;
  lowerPercentageDeltaGreen?: boolean;
}

export interface ReportSummary {
  kpis: ReportSummaryComparableKPI[];
  currentPeriod: string;
  previousPeriod: string;
}

export interface AlertsSummary {
  totalAlertsKpis: ReportSummaryComparableKPI[];
  totalRepeatedAlertsKpis: ReportSummaryComparableKPI[];
  topRepeatedMachineAlarms: ReportSummaryComparableKPI[];
  topRepeatedProgressAlerts: ReportSummaryComparableKPI[];
  topRepeatedSystemWarnings: ReportSummaryComparableKPI[];
  topRepeatedMaintenanceWarnings: ReportSummaryComparableKPI[];
  currentPeriod: string;
  previousPeriod: string;
}

export interface ReportMetadata {
  filename: string;
}

interface GraphModels {
  graphModels?: GraphModel[];
}

export interface ReportStoreProps {
  metadata?: ReportMetadata;
  reportCover?: ReportCover;
  reportSummary?: ReportSummary;
  graphModels?: GraphModels;
  alertsSummary?: AlertsSummary;
}

export const reportStore = createStore(
  { name: 'reports' },
  withProps<ReportStoreProps>({})
);

localForage.config({
  driver: localForage.INDEXEDDB,
  name: 'beeriot-reports',
  version: 1.0,
  storeName: 'reports',
});

persistState(reportStore, {
  key: 'reports',
  storage: localForage as unknown as StateStorage,
});

export const reportMetadata$ = reportStore.pipe(
  select((state) => {
    return state.metadata;
  })
);

export const reportCover$ = reportStore.pipe(
  select((state) => {
    return state.reportCover;
  })
);

export const reportSummary$ = reportStore.pipe(
  select((state) => {
    return state.reportSummary;
  })
);

export const reportGraphModels$ = reportStore.pipe(
  select((state) => {
    return state.graphModels;
  })
);

export const alertsSummary$ = reportStore.pipe(
  select((state) => {
    return state.alertsSummary;
  })
);
