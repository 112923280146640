import { Stack, StackProps, styled } from '@mui/material';
import { lazy, ReactNode, Suspense } from 'react';
import { QueryErrorResetBoundary } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { ParamIdReader } from '../base';

const RootPageStack = styled(Stack)(({ theme }) => ({
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  backgroundColor: theme.palette.base.main,
  boxShadow: theme.shadows[3],
}));



interface RootPageProps extends StackProps {
  children: ReactNode;
}

export function RootPage({ children, ...stackProps }: RootPageProps) {
  return (
    <RootPageStack {...stackProps}>
    <ParamIdReader />
  
      {/* <QueryErrorResetBoundary>
        {() => (
          <ErrorBoundary fallbackRender={() => null}>
            <Suspense fallback={null}>
              <ParamIdReader />
            </Suspense>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary> */}
      {children}
    </RootPageStack>
  );
}
