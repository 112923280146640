import { useMutation } from 'react-query';
import { sendPallNotification } from '@beeriot/api-client';
import {
  SendPallNotificationForm,
  SendPallNotificationFields,
} from '../../settings';
import { SettingsPage } from './SettingsPage';

export function PallNotificationsPage() {
  const mutation = useMutation((data: SendPallNotificationFields) =>
    sendPallNotification(data)
  );

  return (
    <SettingsPage>
      <SendPallNotificationForm mutation={mutation} />
    </SettingsPage>
  );
}
