import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { CalendarMode } from '@beeriot/api-client';

export function useIntlCalendarMode(): (calendarMode?: CalendarMode) => string {
  const intl = useIntl();
  const dayLabel = intl.formatMessage({
    id: 'common-day-label',
    defaultMessage: 'Day',
    description: 'Label to indicate a day',
  });
  const weekLabel = intl.formatMessage({
    id: 'common-week-label',
    defaultMessage: 'Week',
    description: 'Label to indicate a week',
  });
  const monthLabel = intl.formatMessage({
    id: 'common-month-label',
    defaultMessage: 'Month',
    description: 'Label to indicate a month',
  });
  const yearLabel = intl.formatMessage({
    id: 'common-year-label',
    defaultMessage: 'Year',
    description: 'Label to indicate a year',
  });
  const NA = intl.formatMessage({
    id: 'common-not-applicable',
    defaultMessage: 'N/A',
    description: "Label for when there isn't a value to display",
  });

  return useCallback(
    (calendarMode?: CalendarMode) => {
      if (!calendarMode) return NA;
      const calendarModeIntlRecord: Record<CalendarMode, string> = {
        [CalendarMode.Day]: dayLabel,
        [CalendarMode.Week]: weekLabel,
        [CalendarMode.Month]: monthLabel,
        [CalendarMode.Year]: yearLabel,
      };

      return calendarModeIntlRecord[calendarMode];
    },
    [NA, dayLabel, monthLabel, weekLabel, yearLabel]
  );
}
