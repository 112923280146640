import { useState } from 'react';
import { Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { CalendarMode, ColorKey } from '@beeriot/api-client';
import { DateLabel } from './DateLabel';
import { getDatePickerProps } from './getDatePickerProps';

export interface AppDatePickerProps {
  calendarMode: CalendarMode;
  value: Date | null;
  onChange: (date: Date | null) => void;
  subLabelOverride?: string;
  colorKey?: ColorKey;
}

export function AppDatePicker({
  calendarMode,
  value,
  onChange,
  subLabelOverride,
  colorKey = 'primary',
}: AppDatePickerProps) {
  const [pickerOpen, setPickerOpen] = useState(false);
  const handleClose = () => setPickerOpen(false);
  const datePickerProps = getDatePickerProps(calendarMode, value, onChange);

  return (
    <DatePicker
      {...datePickerProps}
      open={pickerOpen}
      onClose={handleClose}
      renderInput={({ inputRef }) => (
        <Button
          ref={inputRef}
          variant="contained"
          color={colorKey}
          onClick={() => setPickerOpen(true)}
        >
          <DateLabel
            calendarMode={calendarMode}
            value={value}
            subLabelOverride={subLabelOverride}
          />
        </Button>
      )}
    />
  );
}
