import { useIntl } from 'react-intl';
import { useObservable } from '@ngneat/use-observable';
import {
  activeSystem$,
  CalendarMode,
  runFilterKey,
  StatisticsViewingFilter,
} from '@beeriot/api-client';
import { useIntlCalendarMode } from '../../enums';
import { getEnumFromString } from '../../utils';
import { AppToggleButtonGroup, AppToggleButtonGroupItem } from '../../base';

const calendarModes = [
  CalendarMode.Week,
  CalendarMode.Month,
  CalendarMode.Year,
];

interface CalendarModePickerProps {
  value: StatisticsViewingFilter;
  onChange: (data: StatisticsViewingFilter) => void;
}

export function StatisticsCalendarModePicker({
  value,
  onChange,
}: CalendarModePickerProps) {
  const [activeSystem] = useObservable(activeSystem$);
  const intl = useIntl();
  const RunToggleItem = {
    value: runFilterKey,
    label: intl.formatMessage({
      id: 'statistics-viewing-filter-run-label',
      description: 'Statistics Viewing Filter Run label',
      defaultMessage: 'Run',
    }),
  };
  const getLabel = useIntlCalendarMode();
  const toggleItems: AppToggleButtonGroupItem[] =
    activeSystem?.type === 'cbs'
      ? calendarModes.map((x) => ({
          value: x,
          label: getLabel(x),
        }))
      : [RunToggleItem].concat(
          calendarModes.map((x) => ({
            value: x,
            label: getLabel(x),
          }))
        );
  const selectedItem = toggleItems.find((x) => x.value === value);
  const didSelectItem = (item?: AppToggleButtonGroupItem) => {
    const calendarMode = getEnumFromString(CalendarMode, item?.value);
    if (calendarMode && calendarMode !== CalendarMode.Day)
      onChange(calendarMode);
    else if (item?.value === runFilterKey) onChange(runFilterKey);
  };

  return (
    <AppToggleButtonGroup
      selectedItem={selectedItem}
      items={toggleItems}
      didSelectItem={didSelectItem}
    />
  );
}
