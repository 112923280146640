import { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UseMutationResult } from 'react-query';
import { Paper, Stack, styled } from '@mui/material';
import {
  SettingsUsers,
  SettingsSitesVolumeUnitsEnum,
  SettingsUsersPressureUnitsEnum,
  SettingsUsersLanguageEnum,
} from '@beeriot/api-client';
import { Form, FormError, SelectOption } from '../../form';
import {
  SectionControl,
  SectionLabel,
  SettingsFooterButtons,
  SectionFormSelect,
  TitleHeader,
} from '../settings-form';
import {
  dateFormatOptions,
  monthFormatOptions,
  getLanguageOptions,
} from '../Settings.models';
import { FormErrorContainer } from '../../auth/FormErrorContainer';
import { useIntlLanguage } from '../../enums';

const StyledPaper = styled(Paper)({
  maxWidth: '40rem',
});

const StyledFormErrorContainer = styled(FormErrorContainer)({
  margin: '1rem',
});

export interface AccountSettingsFormFields {
  language?: SelectOption;
  dateFormat?: SelectOption;
  monthFormat?: SelectOption;
  // TODO: Reintroduce when unit conversions are supported
  // pressure?: SelectOption;
  // volume?: SelectOption;
}

const mapDefaultValues = function (
  languageOptions: SelectOption[],
  accountSettings?: SettingsUsers
): AccountSettingsFormFields | undefined {
  if (accountSettings === undefined) return undefined;

  const defaultValues: AccountSettingsFormFields = {
    dateFormat: dateFormatOptions.find(
      (data) => data.value === accountSettings?.dateFormat
    ),
    language: languageOptions.find(
      (data) => data.value === accountSettings?.language
    ),

    monthFormat: monthFormatOptions.find(
      (data) => data.value === accountSettings?.monthFormat
    ),
    // TODO: Reintroduce when unit conversions are supported
    // pressure: pressureOptions.find(
    //   (data) => data.value === accountSettings?.pressureUnits
    // ),
    // volume: volumeOptions.find(
    //   (data) => data.value === accountSettings?.volumeUnits
    // ),
  };

  return defaultValues;
};

const fromFormFields = function (
  formFields: AccountSettingsFormFields,
  accountSettings?: SettingsUsers
): SettingsUsers {
  return {
    language: formFields.language?.value
      ? (formFields.language?.value as SettingsUsersLanguageEnum)
      : SettingsUsersLanguageEnum.EnUs,
    dateFormat: formFields.dateFormat?.value ?? '',
    monthFormat: formFields.monthFormat?.value ?? '',
    // TODO: Reintroduce when unit conversions are supported
    // pressureUnits: formFields.pressure?.value
    //   ? (formFields.pressure?.value as SettingsUsersPressureUnitsEnum)
    //   : SettingsUsersPressureUnitsEnum.Psi,
    // volumeUnits: formFields.volume?.value
    //   ? (formFields.volume?.value as SettingsSitesVolumeUnitsEnum)
    //   : SettingsSitesVolumeUnitsEnum.Gal,
    pressureUnits: SettingsUsersPressureUnitsEnum.Bar,
    volumeUnits: SettingsSitesVolumeUnitsEnum.HL,
    favorites: accountSettings?.favorites ?? [],
  };
};

export interface AccountSettingsProps {
  accountSettings?: SettingsUsers;
  mutation?: UseMutationResult<unknown, unknown, SettingsUsers, unknown>;
  error?: string;
}

export const AccountSettings = ({
  accountSettings,
  mutation,
  error,
}: AccountSettingsProps) => {
  const intl = useIntl();
  const intlLanguage = useIntlLanguage();
  const languageOptions = getLanguageOptions(intlLanguage);
  const formProps = useMemo(
    () => ({
      defaultValues: mapDefaultValues(languageOptions, accountSettings),
    }),
    [accountSettings, languageOptions]
  );

  const onSubmit = useCallback(
    (data: AccountSettingsFormFields) => {
      mutation?.mutate(fromFormFields(data, accountSettings));
    },
    [accountSettings, mutation]
  );

  return (
    <StyledPaper>
      <Form<AccountSettingsFormFields>
        onSubmit={onSubmit}
        formProps={formProps}
      >
        <Stack>
          <TitleHeader>
            <FormattedMessage
              id="settings-account-settings-header-title"
              defaultMessage="Account Settings"
              description="label for account settings header"
            />
          </TitleHeader>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="commmon-language-label"
                defaultMessage="Language"
                description="label for language"
              />
            </SectionLabel>
            <SectionFormSelect
              name="language"
              placeholder={intl.formatMessage({
                id: 'commmon-language-placeholder',
                defaultMessage: 'Select Language',
                description: 'label for language placeholder',
              })}
              options={languageOptions}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-account-settings-date-format-label"
                defaultMessage="Date Format"
                description="label for date format"
              />
            </SectionLabel>
            <SectionFormSelect
              name="dateFormat"
              placeholder={intl.formatMessage({
                id: 'settings-account-settings-date-format-placeholder',
                defaultMessage: 'Select Data Format',
                description: 'label for data format placeholder',
              })}
              options={dateFormatOptions}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-account-settings-month-format-label"
                defaultMessage="Month Format"
                description="label for month format"
              />
            </SectionLabel>
            <SectionFormSelect
              name="monthFormat"
              placeholder={intl.formatMessage({
                id: 'settings-account-settings-date-format-placeholder',
                defaultMessage: 'Select Data Format',
                description: 'label for data format placeholder',
              })}
              options={monthFormatOptions}
            />
          </SectionControl>
        </Stack>
        {/*
        TODO: Reintroduce when unit conversions are supported<Stack> 
        <Stack>
          <SectionHeader>
            <FormattedMessage
              id="settings-account-settings-units-of-measure-title"
              defaultMessage="Units of Measure"
              description="header for Units of Measure"
            />
          </SectionHeader>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-account-settings-pressure-label"
                defaultMessage="Pressure"
                description="label for pressure"
              />
            </SectionLabel>
            <SectionFormSelect
              name="pressure"
              placeholder={intl.formatMessage({
                id: 'settings-account-settings-pressure-placeholder',
                defaultMessage: 'Select Pressure',
                description: 'label for pressure placeholder',
              })}
              options={pressureOptions}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-account-settings-volume-label"
                defaultMessage="Volume"
                description="label for volume "
              />
            </SectionLabel>
            <SectionFormSelect
              name="volume"
              placeholder={intl.formatMessage({
                id: 'settings-account-settings-volume-placeholder',
                defaultMessage: 'Select Volume',
                description: 'label for volume placeholder',
              })}
              options={volumeOptions}
            />
          </SectionControl>
        </Stack> */}
        <StyledFormErrorContainer>
          <FormError error={error} color="info" />
        </StyledFormErrorContainer>
        <SettingsFooterButtons isLoading={mutation?.isLoading} />
      </Form>
    </StyledPaper>
  );
};
