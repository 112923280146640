import CognitoProvider from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { awsRegion, clientId, clientSecret } from '../lib/awsConstants';
import { authStore } from '../state/auth/authStore';

let refreshingCall: Promise<boolean>;

export function getRefreshToken(): Promise<boolean> {
  if (!awsRegion || !clientId || !clientSecret) {
    throw new Error('Missing cognito environment variables');
  }

  const state = authStore.getValue();
  if (state.isRefreshing && refreshingCall) {
    return refreshingCall;
  }

  const cognito = new CognitoProvider({
    region: awsRegion,
  });

  refreshingCall = new Promise((resolve, reject) => {
    cognito.initiateAuth(
      {
        AuthParameters: {
          REFRESH_TOKEN: state.refreshToken as string,
          SECRET_HASH: clientSecret,
        },
        AuthFlow: 'REFRESH_TOKEN_AUTH',
        ClientId: clientId,
      },
      (err, data) => {
        if (err) {
          reject(err.code);
          return;
        }

        // NOTE: There is no way to get a new refresh token besides executing a new authentication call
        const accessToken = data?.AuthenticationResult?.AccessToken;
        if (accessToken) {
          authStore.update((state) => ({
            ...state,
            accessToken,
            isRefreshing: false,
          }));
          resolve(true);
        } else {
          reject();
        }
      }
    );
  });

  authStore.update((state) => ({
    ...state,
    isRefreshing: true,
  }));

  return refreshingCall;
}
