import { ReactNode } from 'react';
import { Stack, styled } from '@mui/material';
import { RootPage } from './RootPage';
import { AlertsDrawer } from '../alerts';
import { PermissionView, PermissionKey } from '../base';

const ContentStack = styled(Stack)({
  overflow: 'hidden',
  height: '100%',
});

interface BaseAlertsSidebarPageProps {
  children: ReactNode;
  header?: ReactNode;
}

export function BaseAlertsSidebarPage({
  children,
  header,
}: BaseAlertsSidebarPageProps) {
  return (
    <RootPage>
      {header}
      <ContentStack direction="row">
        {children}
        <PermissionView permissionKey={PermissionKey.AlertWidget}>
          <AlertsDrawer />
        </PermissionView>
      </ContentStack>
    </RootPage>
  );
}
