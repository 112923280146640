import { GQLCompanySite, GQLSystem } from '@beeriot/api-client';
import { styled, Grid, GridProps } from '@mui/material';
import { transientOptions } from '../../../../../utils/transientOptions';
import { useEntityDataAttributes } from '../../../../../utils/entityDataAttributes';

export const KPIGrid = styled(
  Grid,
  transientOptions
)({
  display: 'grid',
  width: '100%',
});
KPIGrid.defaultProps = {
  container: true,
  rowSpacing: 1,
};
KPIGrid.displayName = 'KPIGrid';

const KPIGridItemInner = (
  props: GridProps & {
    site?: GQLCompanySite;
    system?: GQLSystem;
  }
) => {
  const { system, site } = props;
  const _entityDataAttributes = useEntityDataAttributes({
    system,
    site,
  });
  return (
    <Grid
      data-kpi-grid-area={props.gridArea}
      {..._entityDataAttributes}
      {...props}
      className={`kpiGridItem ${props.className ?? ''}`}
    />
  );
};
export const KPIGridItem = styled(KPIGridItemInner)({
  display: 'flex',
  // flexDirection: 'row',
  position: 'relative',
  minHeight: '3.25rem',
  alignItems: 'flex-end',
});
KPIGridItem.defaultProps = {
  item: true,
};
KPIGridItem.displayName = 'KPIGridItem';
