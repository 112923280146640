import { FormattedMessage } from 'react-intl';
import { Stack, styled, Button } from '@mui/material';
import { faFolderMedical } from '@fortawesome/pro-regular-svg-icons';
import { Group, GQLCompanySite } from '@beeriot/api-client';
import {
  AppIcon,
  AppTypography,
  PermissionKey,
  PermissionView,
} from '../../../base';
import { GroupCard } from './GroupCard';
import { useCallback, useState } from 'react';
import { AddGroupDialog } from './add-group';

const StyledContainer = styled(Stack)({
  width: '100%',
  padding: '2rem',
});

const NewGroupButton = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: '1rem',
  margin: '1rem 0',
  borderColor: `${theme.palette.lightText.main}80`, //80 denotes opacity
  borderStyle: 'dashed',
  borderWidth: '2px',
  backgroundColor: 'transparent',
  justifyContent: 'flex-start',
}));

const StyledAppIcon = styled(AppIcon)({
  padding: '0rem 0.5rem',
});

export interface GroupSites {
  group: Group;
  sites: GQLCompanySite[];
}

export interface GroupListProps {
  data: GroupSites[];
  activeParentGroupId: string;
}

export function GroupList({ data, activeParentGroupId }: GroupListProps) {
  const [open, setOpen] = useState<boolean>(false);

  const handleNewGroupClick = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <StyledContainer direction="column">
      {data
        ? data.map((x) => (
            <GroupCard key={x.group.id} group={x.group} sites={x.sites} />
          ))
        : null}
      {data.length < 1 ? (
        <AppTypography>
          <FormattedMessage
            id="organize-sites-group-type-list-no-groups-error"
            defaultMessage="No Groups Defined"
            description="Error message for no group"
          />
        </AppTypography>
      ) : null}
      {activeParentGroupId !== '' ? (
        <PermissionView
          permissionKey={PermissionKey.SitesOrganizeSitesCreateRegionGroup}
        >
          <NewGroupButton onClick={handleNewGroupClick}>
            <StyledAppIcon
              $colorKey="highlight3"
              icon={faFolderMedical}
              size="lg"
            />
            <AppTypography variant="button" $colorKey="highlight3">
              <FormattedMessage
                id="organize-sites-group-list-new-group"
                defaultMessage="New Group"
                description="New group label"
              />
            </AppTypography>
          </NewGroupButton>
        </PermissionView>
      ) : null}
      <AddGroupDialog
        open={open}
        parentGroupId={activeParentGroupId}
        handleClose={handleClose}
      />
    </StyledContainer>
  );
}
