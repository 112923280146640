import { TabsProps } from '@mui/material';
import React, {
  isValidElement,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useEffectDebugInfo, useDebugDepsChanges, useDebugPropChanges } from 'react-use-debug-hooks';
import {
  generatePath,
  Location,
  matchRoutes,
  NavigateFunction,
  resolvePath,
  RouteMatch,
  useLocation,
  useMatches,
} from 'react-router-dom';
import { from } from 'rxjs';
import { filter, map, reduce, toArray } from 'rxjs/operators';
import {
  useHasRenderedMatchAtPath,
  useNavigateCancellable,
  useRouteContext,
} from '../../hooks';
import { cleanWildcardPath } from '../../utils/cleanWildcardPath';
import { RouteTabObject, isRouteTabObject } from '../models/RouteTabObject';
import { TabsContext } from '../providers/TabsContext';
import { RouteTabProps } from '../RouteTab';
import { defaultTabButtonRenderer } from './defaultTabButtonRenderer';
import { defaultTabPanelRenderer } from './defaultTabPanelRenderer';
import { appRouterRef } from '../../../base';

export interface useTabsRoutesProps extends Pick<TabsProps, 'action'> {
  /**
   * An identifier which will be used by the TabsProvider/TabsContext to
   * track this set of tabs state
   */
  tabsKey: string;
  /**
   * a hint to the tab controller what its last used route was, so it can start in the same place when returning
   * after having navigated away.
   *
   * this prop allows the developer to override the context
   */
  lastRouteMatch?: string;
  /**
   * A collection of `TabPanelRoute` definitions, or a collection of `TabRouteObject`'s
   */
  routes:
    | RouteTabObject[]
    | ReactElement<RouteTabProps, JSXElementConstructor<RouteTabProps>>[];
  /**
   * Optional custom renderer for the Tab Buttons.
   * Can render anything but expects to be returned some form of Tab and or Router Link combination
   *
   * See `defaultTabButtonRenderer` function for an example implementation
   *
   * @param route a `TabRouteObject`
   * @param index
   */
  tabButtonRenderer?: (
    route: Partial<RouteTabObject>,
    index: number,
    array: Partial<RouteTabObject>[],
    _children: ReactNode[]
  ) => ReactNode;

  /**
   * Optional custom renderer for the Tab Panels.
   * Expects to be returned some form of TabPanel and or Route combination
   *
   * See `defaultTabPanelRenderer` for an example implementation
   */
  tabPanelRenderer?: (
    route: Partial<RouteTabObject>,
    index: number,
    array: Partial<RouteTabObject>[],
    _children: ReactNode[]
  ) => ReactNode;

  /**
   * Optional flag to ignore last matching route handling.
   *
   * Tabs will normally have their route updated to their latest child route.
   * e.g. The route sites/someId/lines/someId/blocks/someId is under sites tab. Navigating away
   * from the sites tab will result in that route being the "last matching route" for the sites tab
   * and will be stored and used when returning to the sites tab.
   *
   * In some cases this functionality may be undesireable or cause issues, in which case this flag should be used.
   */
  ignoreLastRouteMatch?: boolean;
}

type NavigateType = typeof appRouterRef.router.navigate;
let navigate!:NavigateType;
/**
 * Generic hook for handling tab state with react-router
 * @param param0
 * @returns
 */
export function useTabRoutingState(props: useTabsRoutesProps) {
  const {
    tabsKey: name,
    routes,
    tabButtonRenderer,
    tabPanelRenderer,
    lastRouteMatch,
    ignoreLastRouteMatch,
  } = props;
  const _refs = useRef({activeIdx: 0, activeIdxStr: '0'});
  const { tabs: tabsState, setLastRouteMatchForTabsNamed } =
    useContext(TabsContext);
  const location = useLocation();
  if(!navigate){
    navigate = appRouterRef.router.navigate.bind(appRouterRef.router);
  }
  // const { navigate } = useNavigateCancellable();
  // const routeContext = useRouteContext();
  // const parentMatches = useMatches();
  // const resolvedPath = resolvePath('.');

  const _matches = useHasRenderedMatchAtPath({ path: location.pathname });
  // console.log(`tabs found matches: `, _matches);

  
  // useEffectDebugInfo('useTabRoutingState - first useEffect',() => {
  useEffect(() => {
    const tabRoutes = extractRouteConfigs(routes ?? []);
    const matches =
      matchRoutes(tabRoutes, location) ?? _matches
        ? [_matches as RouteMatch]
        : null;
    const firstMatch = matches?.length ? matches[0] : undefined;
    const activeIdx = extractActiveIndex(tabRoutes, matches, location);

    // check the last route set by prop first for controlled components,
    // then checking the context state
    const lastMatch =
      _matches?.pathname ?? lastRouteMatch ?? tabsState[name]?.lastRouteMatch;

    // check if any tabs match the current route,
    // if they don't match then navigate to the `lastRouteMatch`
    // which is stored in the context
    if (needsToNavigate(activeIdx, matches, tabRoutes)) {
      navigateToMatchOrFallback(lastMatch, tabRoutes, location, navigate);
    } else {
      // if any tabs match the current route update the tab context
      updateProvider(
        tabRoutes,
        activeIdx,
        setLastRouteMatchForTabsNamed,
        name,
        firstMatch
      );
    }
  }, [
    lastRouteMatch,
    location,
    name,
    routes,
    tabsState,
    setLastRouteMatchForTabsNamed,
    _matches,
  ]);

  // useDebugDepsChanges([
  //   routes,
  //   location,
  //   name,
  //   tabsState,
  //   ignoreLastRouteMatch,
  //   tabButtonRenderer,
  //   tabPanelRenderer,
  //   _matches,
  // ])

  const model = useMemo(() => {
    // extract the children as an array so we have easy reference to the route elements
    const _children = React.Children.toArray(
      routes as ReactElement<
        RouteTabObject,
        JSXElementConstructor<RouteTabObject>
      >[]
    );
    // extract the props of the tab components
    const tabRoutes = extractRouteConfigs(routes ?? []);

    // check the location for a matching route,
    // extract the first match if there is one,
    // and determine what index it matches
    const matches =
      matchRoutes(tabRoutes, location) ?? _matches
        ? [_matches as RouteMatch]
        : null;

    const firstMatch = matches?.length ? matches[0] : undefined;
    let activeIdx = extractActiveIndex(tabRoutes, matches, location);
    const tabsStateKey = name;
    const tabState = tabsState[tabsStateKey];

    const notFound = activeIdx === -1;
    // console.log({
    //   tabRoutes,
    //   matches,
    //   location,
    //   activeIdx
    // })

    activeIdx = activeIdx !== -1 ? activeIdx : _refs.current.activeIdx;//0;
    const activeIdxStr = `${activeIdx}`;

    

    _refs.current.activeIdx = activeIdx;
    _refs.current.activeIdxStr = activeIdxStr;
    const { tabs, panels } = from(tabRoutes)
      .pipe(
        reduce(
          (acc, route, index) => {
            const _route = ignoreLastRouteMatch
              ? route
              : {
                  ...route,
                  // path:
                  //   tabState?.lastRoutesMatches[index] === location.pathname
                  //     ? route.path
                  //     : tabState?.lastRoutesMatches[index] ?? route.path,
                };
            return {
              tabs: [
                ...acc.tabs,
                tabButtonRenderer
                  ? tabButtonRenderer(_route, index, tabRoutes, _children)
                  : defaultTabButtonRenderer(
                      _route,
                      index,
                      tabRoutes,
                      _children as ReactElement[]
                    ),
              ],
              panels: [
                ...acc.panels,
                tabPanelRenderer
                  ? tabPanelRenderer(route, index, tabRoutes, _children)
                  : defaultTabPanelRenderer(
                      route,
                      index,
                      tabRoutes,
                      _children as ReactElement[]
                    ),
              ],
            };
          },
          { tabs: [] as ReactNode[], panels: [] as ReactNode[] }
        )
      )
      .syncFirstResult();

    // const tabs = tabRoutes
    //   // .filter((route) => !route.hidden)
    //   .map((route, index, arr) => {
    //     const _route = ignoreLastRouteMatch
    //       ? route
    //       : {
    //           ...route,
    //           // path:
    //           //   tabState?.lastRoutesMatches[index] === location.pathname
    //           //     ? route.path
    //           //     : tabState?.lastRoutesMatches[index] ?? route.path,
    //         };
    //     return tabButtonRenderer
    //       ? tabButtonRenderer(_route, index, arr, _children)
    //       : defaultTabButtonRenderer(
    //           _route,
    //           index,
    //           arr,
    //           _children as ReactElement[]
    //         );
    //   });

    const model = {
      notFound,
      activeIdx,
      activeIdxStr,
      tabs,
      tabRoutes,
      panels,
      // panels: tabRoutes.map((route, index, arr) =>
      //   tabPanelRenderer
      //     ? tabPanelRenderer(route, index, arr, _children)
      //     : defaultTabPanelRenderer(
      //         route,
      //         index,
      //         arr,
      //         _children as ReactElement[]
      //       )
      // ),
    };
    return model;
  }, [
    routes,
    location,
    name,
    tabsState,
    ignoreLastRouteMatch,
    tabButtonRenderer,
    tabPanelRenderer,
    _matches,
  ]);
  // console.log(model);

  return model
}

// PRAGMA: Context Helpers
function updateProvider(
  tabRoutes: Partial<RouteTabObject>[],
  activeIdx: number,
  setLastRouteMatchForTabsNamed: (props: {
    tabsName: string;
    route?: string;
    routes: RouteTabObject[];
    activeIdx?: number;
  }) => void,
  name: string,
  firstMatch?: RouteMatch<string> | undefined
) {
  const activeTabRoute = tabRoutes[activeIdx];
  if (activeTabRoute) {
    setLastRouteMatchForTabsNamed({
      tabsName: name,
      route: firstMatch?.pathname ?? activeTabRoute.path,
      routes: tabRoutes as RouteTabObject[],
      activeIdx,
    });
  }
}

// PRAGMA: Navigation Helpers
function needsToNavigate(
  activeIdx: number,
  matches: RouteMatch<string>[] | null,
  tabRoutes: Partial<RouteTabObject>[]
) {
  const needsNavigation =
    activeIdx === -1 && (matches?.length ?? 0) < 1 && tabRoutes.length > 0;
  return needsNavigation;
}

function navigateToMatchOrFallback(
  lastRouteMatch: string | undefined,
  tabRoutes: Partial<RouteTabObject>[],
  location: Location,
  navigate: NavigateFunction
) {
  let firstPath = lastRouteMatch ?? tabRoutes[0].path;
  if (firstPath) {
    const matches = matchRoutes(tabRoutes, location);
    // if we have a match by the time we get here.... then bail out
    if ((matches?.length ?? 0) <= 0) {
      firstPath = cleanWildcardPath(firstPath) as unknown as string;
      //setTimeout(() => {
      navigate(firstPath as string, {
        replace: true,
      });
      //}, 10);
    }
  }
}

function extractActiveIndex(
  tabRoutes: Partial<RouteTabObject>[],
  matches: RouteMatch[] | null,
  location: Location
) {
  type ArrIndexedRoute = Partial<RouteTabObject> & {
    arrIndex: number;
  };
  // find any matches and fetch their indexes,
  //  the find the one with the longest match
  const found = (tabRoutes as ArrIndexedRoute[] | null)
    // ?.filter((r: ArrIndexedRoute, idx) => {
    //   return !r.hidden;
    // })
    ?.map((r: ArrIndexedRoute, idx) => {
      return { ...r, arrIndex: idx };
    })
    .filter((v) => {
      const hydrated = generatePath(v?.path ?? '', v.params);
      const resolvedToBase = resolvePath(hydrated, '/').pathname;
      const resolvedToAncestor = resolvePath(
        hydrated,
        resolvePath('..', location.pathname).pathname
      ).pathname;

      if (matches) {
        const _found =
          (matches.findIndex((m) => {
            const routePaths = [hydrated, resolvedToBase, resolvedToAncestor];
            let matchPaths = [
              m.route?.path ?? '/',
              m.pathname,
              m.pathnameBase,
            ].map((x) => resolvePath(x).pathname);

            matchPaths = matchPaths.filter((x) => routePaths.indexOf(x) !== -1);
            return matchPaths.length > 0;
          }) ?? -1) > -1;
        if (_found) {
          return _found;
        }
      }

      let toPath = hydrated;
      if (v.path?.endsWith('*')) {
        toPath = toPath?.slice(0, toPath.length - 1);
      }
      if (!v.path?.startsWith('/')) {
        toPath = `/${toPath}`;
      }
      if (toPath && location.pathname === toPath) {
        return true;
      }
      if (toPath) {
        return (
          location.pathname.startsWith(toPath) ||
          location.pathname.endsWith(toPath)
        );
      }
      return false;
    });

  let idx = -1;
  const visibleLength = ((tabRoutes as ArrIndexedRoute[] | null) ?? [])?.filter(
    (r: ArrIndexedRoute, idx) => {
      return true; //!r.hidden;
    }
  ).length;
  const sorted = found?.sort((a, b) => {
    const _a = a?.path?.length ?? 0;
    const _b = b?.path?.length ?? 0;
    return _a - _b;
  });
  if (sorted?.length === 1) {
    idx = sorted?.[0].arrIndex ?? -1;
  }
  if ((sorted?.length ?? -1) > 1) {
    idx = sorted?.[sorted?.length - 1].arrIndex ?? -1;
  }
  return idx < visibleLength ? idx : -1;
  // const idx = sorted?.[sorted?.length - 1 ?? 0].arrIndex ?? -1;

  // return idx;
}

function extractRouteConfigs(
  routes:
    | RouteTabObject[]
    | ReactElement<RouteTabProps, JSXElementConstructor<RouteTabProps>>[]
) {
  const _routes = routes as ReactElement<
    RouteTabProps,
    JSXElementConstructor<RouteTabProps>
  >[];
  const tabRouteConfigs = from(React.Children.toArray(_routes))
    .pipe(
      map((x) => {
        if (isRouteTabObject(x)) {
          return x;
        } else {
          if (isValidElement(x)) {
            const routeProps = x.props;
            return routeProps;
          }
          return null;
        }
      }),
      filter((x: Partial<RouteTabObject>) => !!x),
      toArray()
    )
    .syncLastResult();
  return tabRouteConfigs;

  // const isAlreadyTabObject =
  //   routes.findIndex((obj) => !isRouteTabObject(obj)) === -1;
  // if (isAlreadyTabObject) {
  //   return routes as RouteTabObject[];
  // }

  // return (
  //   React.Children.map(_routes, (child, idx) => {
  //     if (isValidElement(child)) {
  //       const routeProps = child.props;
  //       return routeProps;
  //     }
  //     return null;
  //   })?.filter((x: Partial<RouteTabObject> | null) => !!x) ?? []
  // );
}
