import { useMemo } from 'react';
import { KPIGridItem } from '../../kpi-grid/KPIGrid';
import { KPIVerticalLabels } from '../../kpi-grid/KPIVerticalLabel';
import { FormattedMessage } from 'react-intl';
import { SystemKpiHookProps } from '../../SystemKpiHookProps';
import { useShouldRenderKpi } from '../../useShouldRenderKpi';
import { formatUnit } from '../../../../../../utils';

export function useBatchSizeLabelGridItem(props: SystemKpiHookProps) {
  const { system, NA, boldLabelTitles, gridArea } = props;
  const shouldRender = useShouldRenderKpi(props);

  return useMemo(() => {
    if (!shouldRender) {
      return undefined;
    }

    return (
      <KPIGridItem gridArea={gridArea ?? 'batchSize'}>
        <KPIVerticalLabels
          label={
            system?.liveStatus?.productionTargetVolumeHl != null
              ? formatUnit(
                  Math.trunc(system.liveStatus?.productionTargetVolumeHl),
                  'hL'
                )
              : NA
          }
          labelBold={boldLabelTitles}
          subLabel={
            <FormattedMessage
              id="site-line-detail-batch-size"
              defaultMessage="Batch Size"
              description="sub label for batch size"
            />
          }
        />
      </KPIGridItem>
    );
  }, [
    shouldRender,
    gridArea,
    system?.liveStatus?.productionTargetVolumeHl,
    NA,
    boldLabelTitles,
  ]);
}
