import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useObservable } from '@ngneat/use-observable';
import {
  filterRunsStore,
  selectedFilterRun$,
  GQLFilterRun,
  useActiveAppEntities,
} from '@beeriot/api-client';
import { AppDialog } from '../../base/AppDialog';
import { SuspenseFilterRunDialogContent } from './SuspenseFilterRunDialogContent';

export function FilterRunsDetailDialog() {
  const { activeSystem } = useActiveAppEntities();
  const [selectedFilterRun] = useObservable(selectedFilterRun$);
  const [closingFilterRun, setClosingFilterRun] = useState<GQLFilterRun | null>(
    null
  );
  const open = !closingFilterRun && selectedFilterRun !== null;
  const close = () => {
    setClosingFilterRun(selectedFilterRun);
  };
  const finishClose = () => {
    filterRunsStore.update((state) => ({ ...state, selectedFilterRun: null }));
    setClosingFilterRun(null);
  };
  return (
    <AppDialog
      open={open}
      confirm={close}
      showCancelButton={false}
      dialogProps={{
        maxWidth: 'lg',
        TransitionProps: {
          onExited: finishClose,
        },
      }}
      confirmLabel={
        <FormattedMessage
          id="filter-run-detail-confirm-button-label"
          defaultMessage="OK"
          description="confirm button label"
        />
      }
      dialogTitle={
        activeSystem?.type === 'keraflux' ? (
          <FormattedMessage
            id="batch-info-detail-header-title"
            defaultMessage="Batch Info Details"
            description="Batch Info details header title"
          />
        ) : (
          <FormattedMessage
            id="filter-run-detail-header-title"
            defaultMessage="Filter Run Details"
            description="header title"
          />
        )
      }
      dialogContent={<SuspenseFilterRunDialogContent />}
    />
  );
}
