import { Stack, styled } from '@mui/material';
import { RootPage } from '../../RootPage';
import { GlobalFooter, ScrollStack } from '../../../base';
import { SuspenseExecSummaryLinePageContent } from './SuspenseExecSummaryLinePageContent';
import { ExecSummaryFiltersPillBox } from './ExecSummaryFiltersPillBox';
import { ExecSummaryLineControlBox } from './line-control-box';
import { ExecSummaryLineSidebarDrawer } from './line-sidebar';
import { useResponsiveSizes } from '../../../utils/responsive-design/useResponsiveSizes';

const ContentStack = styled(Stack)({
  overflow: 'hidden',
  height: '100%',
});

export function ExecSummaryLinePage() {
  const { isMobileSize } = useResponsiveSizes();

  return (
    <RootPage>
      <ExecSummaryLineControlBox />
      <ContentStack direction="row">
        <ExecSummaryLineSidebarDrawer />
        <ScrollStack>
          {isMobileSize ? null : <ExecSummaryFiltersPillBox />}
          <SuspenseExecSummaryLinePageContent />
          <GlobalFooter />
        </ScrollStack>
      </ContentStack>
    </RootPage>
  );
}
