import { select } from '@ngneat/elf';
import { settingsStore } from './settingsStore';
import { SettingsUsersFavoritesTypeEnum } from '../../generated';

export const userSettings$ = settingsStore.pipe(
  select((state) => {
    return state.userSettings;
  })
);

export const userFavoriteCompanyIds$ = settingsStore.pipe(
  select((state) => {
    return (
      state.userSettings?.favorites
        ?.filter((x) => x.type === SettingsUsersFavoritesTypeEnum.Companies)
        ?.map((x) => x.id) ?? []
    );
  })
);
