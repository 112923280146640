import { useIntl } from 'react-intl';
import { styled, Stack } from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import {
  activeAreaId$,
  AreaSystem,
  GQLCompanySite,
  siteStore,
} from '@beeriot/api-client';
import { RunningStatusLegend, PermissionView, PermissionKey } from '../../base';
import { Form, SelectOption } from '../../form';
import { SiteCard } from '../SiteCard';
import { SiteDetailsSidebarRunningStatusView } from './SiteDetailsSidebarRunningStatusView';
import { AppTypography } from '../../base/AppTypography';
import { SiteDetailsSidebarAreaSelect } from './SiteDetailsSidebarAreaSelect';

const MenuContainer = styled(Stack)({
  display: 'flex',
  height: '100%',
  minWidth: '15rem',
  padding: '0.8rem',
  marginBottom: '1rem',
});

const StackContainer = styled(Stack)({
  marginBottom: '0.8rem',
});

const TimlineSectionsContainer = styled(Stack)({
  display: 'flex',
  overflowY: 'auto',
  flex: '1 1 1px',
  flexDirection: 'column',
  minHeight: '5rem',
});

const LegendContainer = styled(Stack)({
  marginTop: 'auto',
  position: 'sticky',
});

interface AreaFilterFormFields {
  area?: SelectOption;
}

export interface SiteDetailsSidebarMenuProps {
  site: GQLCompanySite;
  areas?: AreaSystem[] | null;
}

export function SiteDetailsSidebarMenu({
  site,
  areas,
}: SiteDetailsSidebarMenuProps) {
  const intl = useIntl();
  const [activeAreaId] = useObservable(activeAreaId$);

  const noAreaSystemsLabel = intl.formatMessage({
    id: 'site-details-no-systems-label',
    description: 'Label for when no systems are available',
    defaultMessage: 'No Systems Found',
  });

  return (
    <MenuContainer>
      <StackContainer>
        <StackContainer>
          <SiteCard
            site={site}
            stripeColorKey="highlight2"
            sizeVariant="small"
          />
        </StackContainer>
        {areas?.length === 0 ? (
          <Stack sx={{ alignItems: 'center' }}>
            <AppTypography $colorKey="lightText" $bold>
              {noAreaSystemsLabel}
            </AppTypography>
          </Stack>
        ) : null}
        {areas && areas.length > 0 && (
          <PermissionView
            permissionKey={PermissionKey.SitesSiteOverviewSidebarSelectAreas}
          >
            <StackContainer>
              <Form<AreaFilterFormFields>
                submitOnChange
                onSubmit={(data) => {
                  siteStore.update((state) => ({
                    ...state,
                    activeAreaId: data.area?.value ?? null,
                  }));
                }}
              >
                <SiteDetailsSidebarAreaSelect areas={areas} />
              </Form>
            </StackContainer>
          </PermissionView>
        )}
      </StackContainer>
      <TimlineSectionsContainer>
        {areas?.filter((x) => !activeAreaId || x.area.id === activeAreaId)
          .map((x: AreaSystem) => {
            return (
              <SiteDetailsSidebarRunningStatusView area={x} key={x.area.id} />
            );
          })}
      </TimlineSectionsContainer>
      <LegendContainer>
        <RunningStatusLegend />
      </LegendContainer>
    </MenuContainer>
  );
}
