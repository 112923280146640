import {
  groupStore,
  useUpdateSite,
  Group,
  GQLCompanySite,
  toCompanySiteBase,
} from '@beeriot/api-client';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { styled, Button } from '@mui/material';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AppIcon } from '../../base';
import { AppTypography } from '../../base/AppTypography';
import { AddOrEditSiteListDialog } from './group-site-list';

const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: '0.5rem 1rem',
  borderColor: `${theme.palette.lightText.main}80`, //80 denotes opacity
  borderStyle: 'dashed',
  borderWidth: '2px',
  backgroundColor: 'transparent',
  justifyContent: 'flex-start',
}));

const StyledAppIcon = styled(AppIcon)({
  padding: '0rem 0.5rem',
});

export interface AddSiteButtonProps {
  group: Group;
}

export function AddSiteButton({ group }: AddSiteButtonProps) {
  const [open, setOpen] = useState(false);
  const updateSite = useUpdateSite(group.companyId);

  const handleOpen = useCallback(() => {
    groupStore.update((state) => ({
      ...state,
      activeGroup: group,
    }));
    setOpen(true);
  }, [setOpen, group]);

  const handleCancel = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleConfirm = useCallback(
    (
      selectedSites: GQLCompanySite[],
      removedSites: GQLCompanySite[],
      groupId: string | null
    ) => {
      selectedSites.forEach(async (site) => {
        if (site?.groups && groupId && !site.groups.some((x) => x === groupId))
          site.groups.push(groupId);
        updateSite.mutateAsync({ ...toCompanySiteBase(site), id: site.id });
      });

      removedSites.forEach(async (site) => {
        if (site?.groups && groupId && site.groups.some((x) => x === groupId)) {
          const groupIndex = site.groups.findIndex((x) => x === groupId);
          if (groupIndex !== -1) site.groups.splice(groupIndex, 1);
        }
        updateSite.mutateAsync({ ...toCompanySiteBase(site), id: site.id });
      });
      setOpen(false);
    },
    [setOpen, updateSite]
  );

  return (
    <StyledButton onClick={handleOpen} disabled={open}>
      <StyledAppIcon $colorKey="highlight3" icon={faPlusCircle} size="2x" />
      <AppTypography variant="button" $colorKey="highlight3">
        <FormattedMessage
          id="organize-sites-group-list-add-site"
          defaultMessage="Add Site"
          description="Button that opens modal or page for creating a site"
        />
      </AppTypography>
      <AddOrEditSiteListDialog
        mutation={updateSite}
        open={open}
        confirm={handleConfirm}
        cancel={handleCancel}
        editMode={true}
      />
    </StyledButton>
  );
}
