import { createStore, select, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { Brand } from '../generated/api';

export interface BeerBrandsSettingsProps {
  activeBeerBrand: Brand | null;
}

export const beerBrandsSettingsStore = createStore(
  { name: 'beerBrandsSettings' },
  withProps<BeerBrandsSettingsProps>({
    activeBeerBrand: null,
  })
);

persistState(beerBrandsSettingsStore, {
  key: 'beerBrandsSettings',
  storage: sessionStorageStrategy,
});

export const activeBeerBrand$ = beerBrandsSettingsStore.pipe(
  select((state) => {
    return state.activeBeerBrand;
  })
);
