import { createStore, select, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { CalendarMode } from '../enums';

export interface IoTStatusStoreProps {
  selectedSites: string[] | null;
  selectedSystemTypes: string[] | null;
  search: string;
  calanderMode: CalendarMode;
}

export const iotStatusStore = createStore(
  { name: 'iot-status' },
  withProps<IoTStatusStoreProps>({
    selectedSites: null,
    selectedSystemTypes: null,
    search: '',
    calanderMode: CalendarMode.Day,
  })
);

persistState(iotStatusStore, {
  key: 'iot-status',
  storage: sessionStorageStrategy,
});

export const iotStatusSelectedSites$ = iotStatusStore.pipe(
  select((state) => {
    return state.selectedSites;
  })
);

export const iotStatusSelectedSystemTypes$ = iotStatusStore.pipe(
  select((state) => {
    return state.selectedSystemTypes;
  })
);

export const iotStatusSearch$ = iotStatusStore.pipe(
  select((state) => {
    return state.search;
  })
);

export const iotStatusCalendarMode$ = iotStatusStore.pipe(
  select((state) => {
    return state.calanderMode;
  })
);
