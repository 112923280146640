import { useMemo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UseMutationResult } from 'react-query';
import {
  GQLCompany,
  Company,
  CompanyBaseStatusEnum,
} from '@beeriot/api-client';
import { Box, Stack, styled } from '@mui/material';
import { AppTypography } from '../../base';
import { Form, FormFooterButtons } from '../../form';
import {
  SectionControl,
  SectionLabel,
  SectionTextInput,
} from '../../settings/settings-form';
import { useResponsiveSizes } from '../../utils';

const StyledFormFooterButtons = styled(FormFooterButtons)({
  padding: '1rem',
  width: '100%',
});

const WarningContainer = styled(Box)({
  marginTop: '1rem',
});

const mapDefaultValues = (
  company?: GQLCompany
): EditCompanyFormFields | undefined => {
  if (company === undefined) return undefined;

  const defaultValues: EditCompanyFormFields = {
    companyName: company.name ?? '',
    companyAddress1: company.address?.address1 ?? '',
    companyAddress2: company.address?.address2 ?? '',
    companyCity: company.address?.city ?? '',
    companyCountry: company.address?.country ?? '',
    companyState: company.address?.state ?? '',
    companyZipcode: company.address?.zipcode ?? '',
  };

  return defaultValues;
};

export interface EditCompanyFormFields {
  companyName?: string;
  companyAddress1?: string;
  companyAddress2?: string;
  companyCity?: string;
  companyCountry?: string;
  companyState?: string;
  companyZipcode?: string;
}

export interface EditCompanyFormProps {
  company: GQLCompany;
  mutation: UseMutationResult<Company, unknown, Company, unknown>;
  confirm: (company: Company) => void;
  cancel: () => void;
}

export const EditCompanyForm = ({
  company,
  mutation,
  confirm,
  cancel,
}: EditCompanyFormProps) => {
  const intl = useIntl();
  const defaultValues = useMemo(() => mapDefaultValues(company), [company]);
  const { isMobileSize } = useResponsiveSizes();

  const saveButtonLabel = intl.formatMessage({
    id: 'common-save-button',
    description: 'Save button',
    defaultMessage: 'Save',
  });

  const cancelLabel = intl.formatMessage({
    id: 'common-cancel-button',
    description: 'Label for cancel button',
    defaultMessage: 'Cancel',
  });

  const onSubmit = useCallback(
    (fields: EditCompanyFormFields) => {
      confirm({
        id: company.id,
        name: fields.companyName ?? '',
        address: {
          address1: fields.companyAddress1 ?? '',
          address2: fields.companyAddress2 ?? '',
          city: fields.companyCity ?? '',
          country: fields.companyCountry ?? '',
          state: fields.companyState ?? '',
          zipcode: fields.companyZipcode ?? '',
        },
        status: CompanyBaseStatusEnum.Active,
      });
    },
    [confirm, company]
  );

  return (
    <Form<EditCompanyFormFields>
      onSubmit={onSubmit}
      formProps={{ defaultValues: defaultValues }}
    >
      <Stack>
        <SectionControl>
          <SectionLabel>
            <FormattedMessage
              id="edit-companies-company-name-label"
              defaultMessage="Company Name"
              description="label for company name"
            />
          </SectionLabel>
          <SectionTextInput
            name="companyName"
            required
            placeholder={intl.formatMessage({
              id: 'edit-companies-company-name-placeholder',
              defaultMessage: 'Enter Company Name',
              description: 'label for company name placeholder',
            })}
            multiline={isMobileSize}
          />
        </SectionControl>
        <SectionControl>
          <SectionLabel>
            <FormattedMessage
              id="edit-companies-company-address-1-label"
              defaultMessage="Company Address 1"
              description="label for company address 1"
            />
          </SectionLabel>
          <SectionTextInput
            name="companyAddress1"
            required
            placeholder={intl.formatMessage({
              id: 'edit-companies-company-address-1-placeholder',
              defaultMessage: 'Enter Company Address 1',
              description: 'label for company address 1 placeholder',
            })}
          />
        </SectionControl>
        <SectionControl>
          <SectionLabel>
            <FormattedMessage
              id="edit-companies-company-address-2-label"
              defaultMessage="Company Address 2"
              description="label for company address 2"
            />
          </SectionLabel>
          <SectionTextInput
            name="companyAddress2"
            required
            placeholder={intl.formatMessage({
              id: 'edit-companies-company-address-2-placeholder',
              defaultMessage: 'Enter Company Address 2',
              description: 'label for company address 2 placeholder',
            })}
          />
        </SectionControl>
        <SectionControl>
          <SectionLabel>
            <FormattedMessage
              id="edit-companies-company-city-label"
              defaultMessage="Company City"
              description="label for company city"
            />
          </SectionLabel>
          <SectionTextInput
            name="companyCity"
            required
            placeholder={intl.formatMessage({
              id: 'edit-companies-company-city-placeholder',
              defaultMessage: 'Enter Company City',
              description: 'label for company city placeholder',
            })}
          />
        </SectionControl>
        <SectionControl>
          <SectionLabel>
            <FormattedMessage
              id="edit-companies-company-country-label"
              defaultMessage="Company Country"
              description="label for company country"
            />
          </SectionLabel>
          <SectionTextInput
            name="companyCountry"
            required
            placeholder={intl.formatMessage({
              id: 'edit-companies-company-country-placeholder',
              defaultMessage: 'Enter Company Country',
              description: 'label for company country placeholder',
            })}
          />
        </SectionControl>
        <SectionControl>
          <SectionLabel>
            <FormattedMessage
              id="edit-companies-company-state-label"
              defaultMessage="Company State"
              description="label for company state"
            />
          </SectionLabel>
          <SectionTextInput
            name="companyState"
            required
            placeholder={intl.formatMessage({
              id: 'edit-companies-company-state-placeholder',
              defaultMessage: 'Enter Company State',
              description: 'label for company state placeholder',
            })}
          />
        </SectionControl>
        <SectionControl>
          <SectionLabel>
            <FormattedMessage
              id="edit-companies-company-zipcode-label"
              defaultMessage="Company Zipcode"
              description="label for company zipcode"
            />
          </SectionLabel>
          <SectionTextInput
            name="companyZipcode"
            required
            placeholder={intl.formatMessage({
              id: 'edit-companies-company-zipcode-placeholder',
              defaultMessage: 'Enter Company Zipcode',
              description: 'label for company zipcode placeholder',
            })}
          />
        </SectionControl>
        {mutation.isError && (
          <WarningContainer>
            <AppTypography $colorKey="error">
              <FormattedMessage
                id="common-error-message-label"
                defaultMessage="An error occured"
                description="label for standard error message"
              />
            </AppTypography>
          </WarningContainer>
        )}
        <StyledFormFooterButtons
          justify="center"
          requireCancelConfirmation={false}
          submitLabel={saveButtonLabel}
          isLoading={mutation.isLoading}
          onCancel={cancel}
        />
      </Stack>
    </Form>
  );
};
