import {
  useCallback,
  useState,
  useMemo,
  Suspense,
  lazy,
  useEffect,
} from 'react';
import { useIntl } from 'react-intl';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
} from 'react-router-dom';
import {
  completedTelemetryExport$,
  setCompletedTelemetryExport,
  useActiveAppEntities,
  useActiveEntityRouteParams,
} from '@beeriot/api-client';
import { SiteLoader } from '../../utils/SiteLoader';
import { useAppRoutePaths } from '../utils';
import { useHasPermission } from '../../base/user-permissions/useHasPermission';
import { PermissionKey } from '../../base/user-permissions/PermissionKey';
import { RoutingCompanySelectorBreadcrumb } from '../../base/breadcrumbs/routing-breadcrumbs/breadcrumbs/RoutingCompanySelectorBreadcrumb';
import { RoutingSiteSelectorBreadcrumb } from '../../base/breadcrumbs/routing-breadcrumbs/breadcrumbs/RoutingSiteSelectorBreadcrumb';
import { RoutingLineSelectorBreadcrumb } from '../../base/breadcrumbs/routing-breadcrumbs/breadcrumbs/RoutingLineSelectorBreadcrumb';
import { RoutingBlockSelectorBreadcrumb } from '../../base/breadcrumbs/routing-breadcrumbs/breadcrumbs/RoutingBlockSelectorBreadcrumb';
import { RoutingRootBreadcrumb } from '../../base/breadcrumbs/routing-breadcrumbs/breadcrumbs/RoutingRootBreadcrumb';
import { RoutingGenericDropdownBreadcrumb } from '../../base/breadcrumbs/routing-breadcrumbs/breadcrumbs/RoutingGenericDropdownBreadcrumb';
import {
  useIsExecSummaryPage,
  useIsExecSummaryRoute,
  useIsSettingsPage,
  useIsSitesDetailsPage,
  useIsSitesPage,
  useIsSystemDetailsPage,
  useNavigateCancellable,
} from '../../navigation/hooks';
import {
  MainTabs,
  MainTabsButton,
  MobileTabsButton,
} from '../../navigation/main-tabs';
import {
  CompaniesPage,
  EditCompaniesPage,
  EditSitesPage,
  SitesDetailsPage,
  SitesPage,
  ExecSummaryLinePage,
  ExecSummarySitePage,
  LinePage,
  BlockDetailsPage,
  OrganizeSitesPage,
  AlertsHistoryPage,
  AnalyticsPage,
} from './MainTabsRoutes.pages';
import { RouteTab } from '../../navigation/tabs/RouteTab';
import { useResponsiveSizes } from '../../utils/responsive-design/useResponsiveSizes';
import { useDebugResponsiveSizes } from '../../utils/responsive-design/useDebugResponsiveSizes';
import { SettingsSidebarTabs } from '../settings-sidebar/SettingsSidebarTabs';
import { SuspenseAutomaticReportsSidebarTabs } from '../settings-sidebar/automatic-reports-sidebar-tabs/SuspenseAutomaticReportsSidebarTabs';
import { SuspenseUsersSidebarTabs } from '../settings-sidebar/users-sidebar/SuspenseUsersSidebarTabs';
import { SuspenseBeerTypesSidebarTabs } from '../settings-sidebar/beer-types-sidebar-tabs/SuspenseBeerTypesSidebarTabs';
import { RoutingSitesBreadcrumb } from '../../base/breadcrumbs/routing-breadcrumbs/breadcrumbs/RoutingSitesBreadcrumb';
import { BreadcrumbsSiteDropdown } from '../../base/breadcrumbs/dropdown/BreadcrumbsSiteDropdown';
import { SuspenseAlertsMedallion } from '../../alerts';
import { useMainTabRoutesTabButtonRenderer } from './useMainTabRoutesTabButtonRenderer';
import { useRenderSystemSelector } from './useRenderSystemSelector';
import { useLastMainTabRoutes } from './useLastMainTabRoutes';
import { TelemetryDataExportProgressPopup } from '../../lines';
import { useObservable } from '@ngneat/use-observable';
import { useEffectDebugInfo } from 'react-use-debug-hooks';
import { useDebounce } from 'use-debounce';
import equal from 'fast-deep-equal';
import { RouterRedirect } from '../RouterRedirect';

export const MainTabsRoutes = () => {
  //  this will log out the current size classes/active breakpoint
  //  ex: {
  //   breakpoint: 'xl',
  //   isMobileSize: true,
  //   isPhoneSize: true,
  //   isTabletSize: false,
  //   ...
  //  }
  // useDebugResponsiveSizes();

  // useEffect(() => {
  //   console.log('mount')
  //   return () => {
  //     console.log('unmount')
  //   }
  // })

  const intl = useIntl();

  const { navigate } = useNavigateCancellable();
  // const location = useLocation();
  const { isMobileSize } = useResponsiveSizes();

  const hasPermission = useHasPermission();

  const appPaths = useAppRoutePaths();
  const routeParams = useActiveEntityRouteParams();
  const {
    activeCompany,
    companies,
    activeSite,
    activeSystem,
    activeBlock,
    sites,
    groupedSites,
    areaSystems,
  } = useActiveAppEntities();
  const firstSite = sites?.[0];
  const firstCompany = companies?.[0];
  const [completedTelemetryExport] = useObservable(completedTelemetryExport$);

  const isSiteAlerts = useMatch(
    'companies/:companyId/sites/:siteId/alert-history'
  );
  const isSitesPage = useIsSitesPage();
  const isExecSummaryPage = useIsExecSummaryPage();
  const isSettingsPage = useIsSettingsPage();
  const isSiteDetailsPage = useIsSitesDetailsPage();
  const { isSystemDetailsPage, ...isSystemDetailsSubPage } =
    useIsSystemDetailsPage();

  // keep a "memory" of the deepest route reached in a main tab,
  //  then use that path as the main buttons path if you navigate away,
  //  that way you don't "lose your place" switching between main tabs
  const lastMainTabRoutes = useLastMainTabRoutes({ activeCompany });

  const [openCollapse, setOpenCollapse] = useState(false);
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);

  const tabButtonRenderer = useMainTabRoutesTabButtonRenderer({
    setOpenCollapse,
    openCollapse,
    setIsMobileMenuActive,
    isMobileSize,
  });

  const renderSystemSelector = useRenderSystemSelector({
    areaSystems,
    isSiteDetailsPage,
    isSystemDetailsSubPage,
    navigate,
  });

  const closeMobileMenu = useCallback(() => {
    setIsMobileMenuActive(false);
  }, []);

  const renderSiteSelector = useCallback(
    (open: boolean, setOpen: (isOpen: boolean) => void) => {
      return (
        <BreadcrumbsSiteDropdown
          groupedSites={groupedSites}
          parentPageName={'sites'}
          onClose={() => setOpen(false)}
        />
      );
    },
    [groupedSites]
  );

  const localizedLabels = useMemo(() => {
    const companiesLabel = intl.formatMessage({
      id: 'common-companies-label',
      description: 'Companies label',
      defaultMessage: 'Companies',
    });
    const sitesLabel = intl.formatMessage({
      id: 'common-sites-label',
      description: 'Sites label',
      defaultMessage: 'Sites',
    });
    const execSummaryLabel = intl.formatMessage({
      id: 'common-exec-summary-abbreviation-label',
      description:
        'Exec. summary label, where exec is an abbreviation for executive',
      defaultMessage: 'Exec. Summary',
    });
    const analyticsLabel = intl.formatMessage({
      id: 'common-analytics-label',
      description: 'Analytics label',
      defaultMessage: 'Analytics',
    });
    const settingsLabel = intl.formatMessage({
      id: 'common-settings-label',
      description: 'Settings label',
      defaultMessage: 'Settings',
    });
    const alertsLabel = intl.formatMessage({
      id: 'common-alerts-label',
      description: 'Label for alerts',
      defaultMessage: 'Alerts',
    });
    return {
      companiesLabel,
      sitesLabel,
      execSummaryLabel,
      analyticsLabel,
      settingsLabel,
      alertsLabel,
    };
  }, [intl]);

  // useEffectDebugInfo('maintabs', () => {
  //   // no-op
  // }, [hasPermission, lastMainTabRoutes?.sites, lastMainTabRoutes?.execSummary, lastMainTabRoutes?.settings, routeParams, localizedLabels.sitesLabel, localizedLabels.companiesLabel, localizedLabels.execSummaryLabel, localizedLabels.analyticsLabel, localizedLabels.settingsLabel, localizedLabels.alertsLabel, isMobileSize, closeMobileMenu, activeSite?.id, activeSite?.name, activeSystem?.name, renderSystemSelector, renderSiteSelector, appPaths.siteExecSummaryPath, appPaths.settingsBeerBrandsPath, appPaths.settingsReportingPath, appPaths.settingsUserManagementPath, appPaths.siteDetailsPath, appPaths.sitesListPath, isSiteDetailsPage, isSiteAlerts, isSitesPage, isSettingsPage, isExecSummaryPage]);

  const mainTabRoutes = useMemo(() => {
    const routes = [];

    const sitesBreadcrumbs = hasPermission(PermissionKey.SitesList)
      ? [<RoutingSitesBreadcrumb />, <RoutingSiteSelectorBreadcrumb />]
      : [<RoutingSiteSelectorBreadcrumb />];

    if (hasPermission(PermissionKey.CompaniesList)) {
      routes.push(
        <RouteTab
          path="companies/*"
          params={routeParams}
          label={localizedLabels.companiesLabel}
          buttonElement={
            isMobileSize ? (
              <MobileTabsButton onClick={closeMobileMenu} />
            ) : (
              <MainTabsButton />
            )
          }
          element={
            <Routes>
              <Route
                index={true}
                element={
                  <Suspense>
                    <CompaniesPage />
                  </Suspense>
                }
              />
              {hasPermission(PermissionKey.CompaniesEditCompanies) && (
                <Route
                  path="edit-companies"
                  element={
                    <Suspense>
                      <EditCompaniesPage />
                    </Suspense>
                  }
                />
              )}
            </Routes>
          }
        />
      );
    }

    const pathFrag = hasPermission(PermissionKey.SitesList)
      ? `/companies/${activeCompany?.id ?? firstCompany?.id ?? ''}/sites`
      : hasPermission(PermissionKey.SiteDetails) &&
        (activeSite?.id || firstSite?.id)
      ? `/companies/${activeCompany?.id ?? firstCompany?.id ?? ''}/sites/${
          activeSite?.id ?? firstSite?.id
        }/overview`
      : `/companies/${activeCompany?.id ?? firstCompany?.id ?? ''}/sites`;

    const lastSitesRoute =
      lastMainTabRoutes?.sites.length < 2
        ? lastMainTabRoutes?.sites[0]
        : lastMainTabRoutes?.sites?.slice(isSitesPage ? -2 : -1)?.[0];

    const nextSitesPath =
      !activeCompany?.id || !activeSite?.id ? pathFrag : lastSitesRoute.path;

    // const lastSitesRoute = lastMainTabRoutes?.sites?.slice(isSitesPage ? -2 : -1)?.[0];
    const clickSites = () => {
      lastMainTabRoutes?.sites.length > 1 && lastMainTabRoutes?.sites.pop();
      closeMobileMenu();
    };
    routes.push(
      <RouteTab
        path="companies/:companyId/sites/*"
        params={routeParams}
        label={localizedLabels.sitesLabel}
        buttonElement={
          isMobileSize ? (
            <MobileTabsButton onClick={clickSites} to={nextSitesPath} />
          ) : (
            <MainTabsButton to={nextSitesPath} onClick={clickSites} />
          )
        }
        element={
          <Routes>
            <Route path="*">
            
              <Route
                path=":siteId/overview"
                element={
                  // <Suspense>
                    <SitesDetailsPage />
                  // </Suspense>
                }
                handle={{
                  breadcrumbs: () => {
                    return sitesBreadcrumbs;
                  },
                }}
              />
  <Route
                index={true}
                element={
                  <Suspense>
                    {hasPermission(PermissionKey.SitesList) ? (
                      <SitesPage />
                    ) : (
                      <RouterRedirect from={'*'} to={pathFrag} />
                    )}
                  </Suspense>
                }
                handle={{
                  breadcrumbs: () => {
                    return <RoutingCompanySelectorBreadcrumb />;
                  },
                }}
              />

              <Route
                path=":siteId/lines/:lineId/*"
                element={
                  <Suspense>
                    <LinePage />
                  </Suspense>
                }
                handle={{
                  breadcrumbs: () => {
                    return [
                      <SiteLoader siteId={activeSite?.id} />,
                      <RoutingSitesBreadcrumb
                        showActiveSiteTitle={true}
                        disableBreadcrumb={
                          !hasPermission(PermissionKey.SiteDetails)
                        }
                      />,
                      <RoutingGenericDropdownBreadcrumb
                        label={activeSystem?.name ?? ''}
                        renderDropdownContent={renderSystemSelector}
                        $followingItemStripeColorKey={
                          isMobileSize ? 'highlight3' : undefined
                        }
                      />,
                      ...(isMobileSize
                        ? [
                            <RoutingGenericDropdownBreadcrumb
                              $stripeColorKey={'highlight3'}
                              $followingItemStripeColorKey={'highlight2'}
                            />,
                          ]
                        : []),
                    ];
                  },
                }}
              />
              <Route
                path=":siteId/lines/:lineId/*"
                handle={{
                  breadcrumbs: () => {
                    return [
                      <RoutingSitesBreadcrumb
                        showActiveSiteTitle={true}
                        disableBreadcrumb={
                          !hasPermission(PermissionKey.SiteDetails)
                        }
                      />,
                    ];
                  },
                }}
              >
                <Route
                  index={true}
                  element={<Navigate to="line-status" replace={true} />}
                />
                <Route
                  path="blocks/:blockId/*"
                  element={
                    <Suspense>
                      <BlockDetailsPage />
                    </Suspense>
                  }
                  handle={{
                    breadcrumbs: () => {
                      return [
                        <RoutingLineSelectorBreadcrumb />,
                        <RoutingBlockSelectorBreadcrumb />,
                      ];
                    },
                  }}
                />
              </Route>

              <Route
                path="edit-sites"
                element={
                  <Suspense>
                    <EditSitesPage />
                  </Suspense>
                }
              />
              {hasPermission(PermissionKey.SitesOrganizeSites) && (
                <Route
                  path="organize-sites"
                  element={
                    <Suspense>
                      <OrganizeSitesPage />
                    </Suspense>
                  }
                />
              )}
            </Route>
          </Routes>
        }
      />
    );

    if (hasPermission(PermissionKey.ExecutiveSummary)) {
      const pathFrag = `/companies/${
        activeCompany?.id ?? firstCompany?.id ?? ''
      }/exec-summary/${activeSite?.id ?? firstSite?.id ?? ''}/summary`;

      const lastExecRoute =
        lastMainTabRoutes?.execSummary.length < 2
          ? lastMainTabRoutes?.execSummary[0]
          : lastMainTabRoutes?.execSummary?.slice(
              isExecSummaryPage ? -2 : -1
            )?.[0];

      const nextExecRoute =
        !activeCompany?.id || !activeSite?.id ? pathFrag : lastExecRoute.path;
      const clickExec = () => {
        lastMainTabRoutes?.execSummary.length > 1 &&
          lastMainTabRoutes?.execSummary.pop();
        closeMobileMenu();
      };

      routes.push(
        <RouteTab
          path="companies/:companyId/exec-summary/*"
          params={routeParams}
          label={localizedLabels.execSummaryLabel}
          buttonElement={
            isMobileSize ? (
              <MobileTabsButton to={nextExecRoute} onClick={clickExec} />
            ) : (
              <MainTabsButton to={nextExecRoute} onClick={clickExec} />
            )
          }
          element={
            <Routes>
              <Route
                path="/"
                element={
                  <Suspense>
                    <ExecSummarySitePage />
                  </Suspense>
                }
              />
              <Route
                path=":siteId/summary"
                element={
                  <Suspense>
                    <ExecSummarySitePage />
                  </Suspense>
                }
                handle={{
                  breadcrumbs: () => {
                    return [
                      // <RoutingSiteSelectorBreadcrumb />,
                      <RoutingGenericDropdownBreadcrumb
                        label={activeSite?.name ?? ''}
                        renderDropdownContent={renderSiteSelector}
                      />,
                    ];
                  },
                }}
              />
              <Route
                path=":siteId/lines/:lineId/summary"
                element={
                  <Suspense>
                    <ExecSummaryLinePage />
                  </Suspense>
                }
                handle={{
                  breadcrumbs: () => {
                    return [
                      <RoutingRootBreadcrumb
                        label={activeSite?.name ?? ''}
                        to={appPaths.siteExecSummaryPath}
                      />,
                      // <RoutingLineSelectorBreadcrumb />,
                      <RoutingGenericDropdownBreadcrumb
                        label={activeSystem?.name ?? ''}
                        renderDropdownContent={renderSystemSelector}
                      />,
                    ];
                  },
                }}
              />
            </Routes>
          }
        />
      );
    }

    if (hasPermission(PermissionKey.Analytics)) {
      routes.push(
        <RouteTab
          path="companies/:companyId/analytics/*"
          label={localizedLabels.analyticsLabel}
          params={routeParams}
          buttonElement={
            isMobileSize ? (
              <MobileTabsButton onClick={closeMobileMenu} />
            ) : (
              <MainTabsButton />
            )
          }
          element={
            <Suspense>
              <AnalyticsPage />
            </Suspense>
          }
        />
      );
    }

    if (hasPermission(PermissionKey.Settings)) {
      const pathFrag = `/companies/${
        activeCompany?.id ?? firstCompany?.id ?? ''
      }/settings/`;
      if (lastMainTabRoutes?.settings?.[0].path === '') {
        lastMainTabRoutes.settings[0].path = pathFrag;
      }
      const lastSettingRoute =
        lastMainTabRoutes?.settings.length < 2
          ? lastMainTabRoutes?.settings[0]
          : lastMainTabRoutes?.settings?.slice(
              isExecSummaryPage ? -2 : -1
            )?.[0];


      // const lastSettingRoute = lastMainTabRoutes?.settings?.slice(isSettingsPage ? -2 : -1)?.[0];
      const clickSettings = () => {
        lastMainTabRoutes?.settings.length > 1 &&
          lastMainTabRoutes?.settings.pop();
        closeMobileMenu();
      };

      const nextSettingsRoute = !activeCompany?.id
        ? pathFrag
        : lastSettingRoute.path;

      // const lastSettingRoute = lastMainTabRoutes?.settings?.slice(-2)?.[0] ;
      const settingsPath = `/companies/${routeParams.companyId}/settings/*`;
      const baseSettingsBreadcrumbs = [
        ...(!isMobileSize
          ? [
              <RoutingRootBreadcrumb
                localizerKey="common-settings-label"
                to={settingsPath}
              />,
            ]
          : []),
        <RoutingGenericDropdownBreadcrumb />,
      ];
      routes.push(
        <RouteTab
          path="companies/:companyId/settings/*"
          params={routeParams}
          label={localizedLabels.settingsLabel}
          buttonElement={
            isMobileSize ? (
              <MobileTabsButton
                // to={settingsPath}
                to={nextSettingsRoute}
                onClick={clickSettings}
              />
            ) : (
              <MainTabsButton
                // to={settingsPath}
                to={nextSettingsRoute}
                onClick={clickSettings}
              />
            )
          }
          element={
            <Routes>
              <Route path="*">
                <Route
                  path="*"
                  element={
                    <Suspense>
                      <SettingsSidebarTabs />
                    </Suspense>
                  }
                  handle={{
                    breadcrumbs: () => {
                      return baseSettingsBreadcrumbs;
                    },
                  }}
                />
                <Route
                  path="beer-brands-details/*"
                  element={<SuspenseBeerTypesSidebarTabs />}
                  handle={{
                    breadcrumbs: () => {
                      return [
                        <RoutingRootBreadcrumb
                          localizerKey="common-beer-brands-label"
                          to={appPaths.settingsBeerBrandsPath}
                        />,
                        <RoutingGenericDropdownBreadcrumb />,
                      ];
                    },
                  }}
                />
                <Route
                  path="automatic-report-details/*"
                  element={<SuspenseAutomaticReportsSidebarTabs />}
                  handle={{
                    breadcrumbs: () => {
                      return [
                        <RoutingRootBreadcrumb
                          localizerKey="common-automatic-reports-label"
                          to={appPaths.settingsReportingPath}
                        />,
                        <RoutingGenericDropdownBreadcrumb />,
                      ];
                    },
                  }}
                />
                <Route
                  path="user-management-details/*"
                  element={<SuspenseUsersSidebarTabs />}
                  handle={{
                    breadcrumbs: () => {
                      return [
                        <RoutingRootBreadcrumb
                          localizerKey="common-user-management-label"
                          to={appPaths.settingsUserManagementPath}
                        />,
                        <RoutingGenericDropdownBreadcrumb />,
                      ];
                    },
                  }}
                />
              </Route>
            </Routes>
          }
        />
      );
    }

    if (hasPermission(PermissionKey.AlertHistory)) {
      if (isSiteDetailsPage || isSiteAlerts) {
        routes.push(
          <RouteTab
            path={'companies/:companyId/sites/:siteId/alert-history'}
            params={routeParams}
            label={localizedLabels.alertsLabel}
            buttonElement={
              isMobileSize ? (
                <MobileTabsButton onClick={closeMobileMenu} />
              ) : (
                <MainTabsButton
                  icon={<SuspenseAlertsMedallion />}
                  iconPosition={'start'}
                />
              )
            }
            element={
              <Suspense>
                <AlertsHistoryPage />
              </Suspense>
            }
            handle={{
              breadcrumbs: () => {
                return [
                  <RoutingRootBreadcrumb
                    label={activeSite?.name ?? ''}
                    to={appPaths.siteDetailsPath}
                  />,
                  <RoutingGenericDropdownBreadcrumb />,
                ];
              },
            }}
          />
        );
      }
      if (!isSiteDetailsPage) {
        routes.push(
          <RouteTab
            path={'companies/:companyId/alert-history'}
            params={routeParams}
            label={localizedLabels.alertsLabel}
            buttonElement={
              isMobileSize ? (
                <MobileTabsButton onClick={closeMobileMenu} />
              ) : (
                <MainTabsButton
                  icon={<SuspenseAlertsMedallion />}
                  iconPosition={'start'}
                />
              )
            }
            element={
              <Suspense>
                <AlertsHistoryPage />
              </Suspense>
            }
            handle={{
              breadcrumbs: () => {
                return [
                  <RoutingRootBreadcrumb
                    label={localizedLabels.sitesLabel}
                    to={appPaths.sitesListPath}
                  />,
                  <RoutingGenericDropdownBreadcrumb />,
                ];
              },
            }}
          />
        );
      }
    }

    return routes;
  }, [
    hasPermission,
    lastMainTabRoutes?.sites,
    lastMainTabRoutes?.execSummary,
    lastMainTabRoutes.settings,
    isSitesPage,
    routeParams,
    localizedLabels.sitesLabel,
    localizedLabels.companiesLabel,
    localizedLabels.execSummaryLabel,
    localizedLabels.analyticsLabel,
    localizedLabels.settingsLabel,
    localizedLabels.alertsLabel,
    isMobileSize,
    closeMobileMenu,
    activeSite?.id,
    activeSite?.name,
    activeSystem?.name,
    renderSystemSelector,
    activeCompany?.id,
    firstCompany?.id,
    firstSite?.id,
    isExecSummaryPage,
    renderSiteSelector,
    appPaths.siteExecSummaryPath,
    appPaths.settingsBeerBrandsPath,
    appPaths.settingsReportingPath,
    appPaths.settingsUserManagementPath,
    appPaths.siteDetailsPath,
    appPaths.sitesListPath,
    isSiteDetailsPage,
    isSiteAlerts,
  ]);

  return (
    <>
      <MainTabs
        tabsKey={'main'}
        tabButtonRenderer={tabButtonRenderer}
        isMobileMenuActive={isMobileMenuActive}
        setIsMobileMenuActive={setIsMobileMenuActive}
        routes={mainTabRoutes}
      />
      <TelemetryDataExportProgressPopup
        open={completedTelemetryExport !== null}
        completedTelemetryExport={completedTelemetryExport ?? undefined}
        completed={true}
        onClose={() => {
          setCompletedTelemetryExport();
        }}
      />
    </>
  );
};
