import { IoTStatusPanelHeader, SuspenseIoTStatusPanel } from '../../settings';
import { IoTStatusLegendFooter } from './IoTStatusLegendFooter';
import { SettingsPage } from './SettingsPage';

export function IoTStatusSettingsPage() {
  return (
    <SettingsPage
      header={<IoTStatusPanelHeader />}
      footer={<IoTStatusLegendFooter />}
    >
      <SuspenseIoTStatusPanel />
    </SettingsPage>
  );
}
