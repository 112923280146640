import { useIntl, FormattedMessage } from 'react-intl';
import { SystemTypeEnum } from '@beeriot/api-client';
import { styled } from '@mui/material';
import { FormSelect } from '../FormSelect';
import { SelectOption } from '../SelectOption';

const StyledFormSelect = styled(FormSelect)({
  height: '100%',
  minWidth: '14rem',
});

const systemTypeOptions: SelectOption[] = Object.values(SystemTypeEnum).map(
  (type) => ({
    label: type.toUpperCase(),
    value: type,
  })
);

interface SystemTypeDropdownListProps {
  name: string;
  isMulti?: boolean;
  defaultValue?: SystemTypeEnum;
}

export function SystemTypeDropdownList({
  name,
  isMulti,
  defaultValue,
}: SystemTypeDropdownListProps) {
  const intl = useIntl();

  const systemTypeSelectionPlaceholder = intl.formatMessage({
    id: 'systems-selection-placeholder',
    description: 'Placeholder for system selection',
    defaultMessage: 'Select System',
  });

  const defaultSystemTypeOption =
    systemTypeOptions.find((x) => x.value === defaultValue) ?? null;

  return (
    <StyledFormSelect
      isMulti={isMulti}
      name={name}
      options={systemTypeOptions}
      defaultValue={defaultSystemTypeOption}
      placeholder={systemTypeSelectionPlaceholder}
      innerLabel={
        <FormattedMessage
          id="systems-type-selection-form-select"
          description="Selection for system type"
          defaultMessage="System Type:"
        />
      }
    />
  );
}
