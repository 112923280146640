import { useMemo } from 'react';
import { KPIGridItem } from '../../kpi-grid/KPIGrid';
import { KPIVerticalLabels } from '../../kpi-grid/KPIVerticalLabel';
import { FormattedMessage } from 'react-intl';
import { SystemKpiHookProps } from '../../SystemKpiHookProps';
import { useShouldRenderKpi } from '../../useShouldRenderKpi';

export function useProgramLabelGridItem(props: SystemKpiHookProps) {
  const { system, NA, boldLabelTitles, gridArea } = props;
  const shouldRender = useShouldRenderKpi(props);

  return useMemo(() => {
    if (!shouldRender) {
      return undefined;
    }

    return (
      <KPIGridItem gridArea={gridArea ?? 'program'}>
        <KPIVerticalLabels
          // label={system?.liveStatus?.program ?? NA}
          label={NA}
          labelBold={boldLabelTitles}
          $flexWrap={'wrap'}
          $whiteSpace={'normal'}
          subLabel={
            <FormattedMessage
              id="common-program-label"
              defaultMessage="Program"
              description="sub label for program"
            />
          }
        />
      </KPIGridItem>
    );
  }, [shouldRender, gridArea, NA, boldLabelTitles]);
}
