import { useCallback } from 'react';
import { ColorKey, SettingsSystems } from '@beeriot/api-client';
import { ThresholdValue } from './thresholdValue';

export function useThresholdColorProcessor(
  systemSettings?: SettingsSystems | null
) {
  return useCallback(
    (
      field: ThresholdValue,
      value: string | number | boolean | undefined | null
    ): ColorKey => {
      if (systemSettings == null || value == null) return 'darkText';

      switch (field) {
        case ThresholdValue.DegreeOfEfficiency:
          return parseThreshold(
            value as number,
            systemSettings?.degreeOfEfficiency.green,
            systemSettings?.degreeOfEfficiency.red
          );
        case ThresholdValue.ActualFlow:
          return parsePercentageThreshold(
            value as number,
            systemSettings?.thresholdValues?.targetFlowRateHlh,
            systemSettings?.actualFlow.green,
            systemSettings?.actualFlow.red
          );
        case ThresholdValue.Cluster:
          return parseStringThreshold(
            value as string,
            'Passed',
            undefined,
            'Failed'
          );
        case ThresholdValue.OperationalCluster:
          return parseThreshold(
            value as number,
            systemSettings?.operationalCluster?.green,
            systemSettings?.operationalCluster?.red
          );
        case ThresholdValue.Turbidity:
          return parseThreshold(
            value as number,
            systemSettings?.turbidityEbc?.green,
            systemSettings?.turbidityEbc?.red,
            true
          );
        case ThresholdValue.Oxygen:
          return parseThreshold(
            value as number,
            systemSettings?.oxygen?.green,
            systemSettings?.oxygen?.red,
            true
          );
        case ThresholdValue.NetCapacity:
          return parseThreshold(
            value as number,
            systemSettings?.netCapacity?.green,
            systemSettings?.netCapacity?.red
          );
        case ThresholdValue.Opex:
          return parseThreshold(
            value as number,
            systemSettings?.opex?.green,
            systemSettings?.opex?.red,
            true
          );
        case ThresholdValue.ConcentrateStatus:
          return parseStringThreshold(
            value as string,
            'Good',
            undefined,
            'Low'
          );
        case ThresholdValue.Water:
          return parseThreshold(
            value as number,
            systemSettings?.waterHl?.green,
            systemSettings?.waterHl?.red,
            true
          );
        case ThresholdValue.DaWater:
          return parseThreshold(
            value as number,
            systemSettings?.daWaterHl?.green,
            systemSettings?.daWaterHl?.red,
            true
          );
        case ThresholdValue.Caustic:
          return parseBooleanThreshold(
            value as boolean,
            systemSettings?.caustic?.green,
            systemSettings?.caustic?.yellow,
            systemSettings?.caustic?.red
          );
        case ThresholdValue.Peroxide:
          return parseBooleanThreshold(
            value as boolean,
            systemSettings?.peroxyde?.green,
            systemSettings?.peroxyde?.yellow,
            systemSettings?.peroxyde?.red
          );
        case ThresholdValue.MembraneCleaner:
          return parseBooleanThreshold(
            value as boolean,
            systemSettings?.membraneCleaner?.green,
            systemSettings?.membraneCleaner?.yellow,
            systemSettings?.membraneCleaner?.red
          );
        case ThresholdValue.ActualDp:
          return parseThreshold(
            value as number,
            systemSettings?.daWaterHl?.green,
            systemSettings?.daWaterHl?.red,
            true
          );
        default:
          return 'darkText';
      }
    },
    [systemSettings]
  );
}

function parseThreshold(
  value: number,
  greenLimit: number | undefined,
  redLimit: number | undefined,
  lowGreen?: boolean
): ColorKey {
  if (greenLimit === undefined || redLimit === undefined) {
    return 'darkText';
  }
  const upperLimit = lowGreen ? redLimit : greenLimit;
  const lowerLimit = lowGreen ? greenLimit : redLimit;

  if (value > upperLimit) {
    return lowGreen ? 'error' : 'success';
  } else if (value < lowerLimit) {
    return lowGreen ? 'success' : 'error';
  } else {
    return 'warning';
  }
}

function parseBooleanThreshold(
  value: boolean,
  green: boolean | undefined,
  yellow: boolean | undefined,
  red: boolean | undefined
) {
  switch (value) {
    case green:
      return 'success';
    case yellow:
      return 'warning';
    case red:
      return 'error';
    default:
      return 'darkText';
  }
}

function parseStringThreshold(
  value: string,
  green: string | undefined,
  yellow: string | undefined,
  red: string | undefined
) {
  switch (value) {
    case green:
      return 'success';
    case yellow:
      return 'warning';
    case red:
      return 'error';
    default:
      return 'darkText';
  }
}

function parsePercentageThreshold(
  value: number,
  targetValue: number | undefined,
  green: number | undefined,
  red: number | undefined
) {
  if (green === undefined || red === undefined || targetValue === undefined) {
    return 'darkText';
  }

  const greenValueOffset = targetValue * (green / 100);
  const redValueOffset = targetValue * (red / 100);

  if (value >= targetValue - greenValueOffset) {
    return 'success';
  } else if (value <= targetValue - redValueOffset) {
    return 'error';
  } else {
    return 'warning';
  }
}
