import {
  GQLConsumable,
  useInfiniteListConsumablesQuery,
} from '../generated/gql';
import {
  defaultPagingParams,
  removeFalsey,
  getNextPageParamHandler,
} from '../utils';

export function useGQLConsumables(
  blockId?: string | null,
  systemId?: string | null
): GQLConsumable[] {
  const {
    data: consumables,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteListConsumablesQuery(
    {
      blockId,
      systemId,
      status: 'active',
      ...defaultPagingParams,
    },
    {
      enabled: !!blockId || !!systemId,
      refetchInterval: 15000,
      getNextPageParam: getNextPageParamHandler(
        (query) => query?.listConsumables?.length
      ),
    }
  );

  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage();
  }

  const gqlConsumables = removeFalsey<GQLConsumable>(
    consumables?.pages.flatMap((x) => x?.listConsumables)
  );
  return gqlConsumables;
}
