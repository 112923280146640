import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { IconLabel, LoadingBox } from '../../base';
import { TelemetryDataExportTableQueryProps } from './TelemetryDataExportTableQuery';

const TableLoadingBox = styled(LoadingBox)({
  height: '100%',
  minHeight: '20rem',
  width: '100%',
  borderRadius: '0 0 0.5rem 0.5rem',
});

const TelemetryDataExportTableContainerComponent = lazy(() =>
  import('./TelemetryDataExportTableQuery').then(
    ({ TelemetryDataExportTableQuery }) => ({
      default: TelemetryDataExportTableQuery,
    })
  )
);

export function SuspenseTelemetryDataExportTableContainer({
  downloadDataExport,
  shareDataExport,
  showActionsDialog,
}: TelemetryDataExportTableQueryProps) {
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <IconLabel
                label={
                  <FormattedMessage
                    id="telemetry-data-export-table-error-label"
                    description="Error message for telemetry data export table"
                    defaultMessage="Telemetry Data Export Repository Unavailable"
                  />
                }
              />
            );
          }}
        >
          <Suspense fallback={<TableLoadingBox />}>
            <TelemetryDataExportTableContainerComponent
              downloadDataExport={downloadDataExport}
              shareDataExport={shareDataExport}
              showActionsDialog={showActionsDialog}
            />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
