import { Document } from '@beeriot/api-client';
import { FormattedMessage } from 'react-intl';
import { UseMutationResult } from 'react-query';
import { AppDialog } from '../../base';

interface ConfirmUnDeleteDialogProps {
  open: boolean;
  item: Document;
  mutation: UseMutationResult<Document, unknown, Document, unknown>;
  onClose: () => void;
}

export function ConfirmUnDeleteDialog({
  open,
  item,
  mutation,
  onClose,
}: ConfirmUnDeleteDialogProps) {
  return (
    <AppDialog
      open={open}
      cancel={onClose}
      confirm={() => mutation.mutate(item)}
      confirmIsLoading={mutation.isLoading}
      dialogTitle={
        <FormattedMessage
          id="documentation-undelete-document-dialog-title"
          description="Title for document undelete dialog"
          defaultMessage="Undelete / Restore Document"
        />
      }
      dialogContent={
        <FormattedMessage
          id="documentation-undelete-document-dialog-description"
          description="Description for document undelete dialog"
          defaultMessage="Are you sure you want to undelete / restore {documentName}?"
          values={{ documentName: item.name }}
        />
      }
    />
  );
}
