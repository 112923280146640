import { Box, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ColorKey } from '@beeriot/api-client';
import { AppTypography, BadgeListContainer, LabeledBadge } from '../../base';

const StyledBox = styled(Box)({
  height: '2rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const LegendLabel = styled(AppTypography)({
  marginLeft: '0px',
  marginRight: '3rem',
  fontWeight: 'bold',
});


export function IoTStatusLegend() {
  return (
    <StyledBox>
      <LegendLabel>
        <FormattedMessage
          id="common-legend-label"
          defaultMessage="Legend"
          description="Label for data legend"
        />
      </LegendLabel>
      <BadgeListContainer gap="2rem">
          <LabeledBadge
            label={
              <FormattedMessage
                id="common-connected"
                defaultMessage="Connected"
                description="System is Connected"
              />}
            badgeColorKey={'shade'}
          />
          <LabeledBadge
            label={
              <FormattedMessage
                id="common-disconnected"
                defaultMessage="Disconnected"
                description="System is Disconnected"
              />}
            badgeColorKey={'error'}
          />
      </BadgeListContainer>
    </StyledBox>
  );
}
