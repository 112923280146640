import { Dialog, DialogTitle, Stack, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ShareReportDialogContent } from './ShareReportDialogContent';
import { GQLReport, useShareReport } from '@beeriot/api-client';
import { AppTypography } from '../../base';

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': { width: '100%' },
});

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.componentBase.main,
  borderTopRightRadius: theme.shape.borderRadius,
  borderTopLeftRadius: theme.shape.borderRadius,
}));

interface ShareReportDialogProps {
  open: boolean;
  onClose: () => void;
  report: GQLReport;
}

export function ShareReportDialog({
  open,
  onClose,
  report,
}: ShareReportDialogProps) {
  const shareReportMutation = useShareReport(() => {
    onClose();
  });
  return (
    <StyledDialog open={open}>
      <StyledDialogTitle>
        <FormattedMessage
          id="reports-share-dialog-title"
          description="Title for share report dialog"
          defaultMessage="Share Report"
        />
      </StyledDialogTitle>
      <AppTypography variant="body1" sx={{ padding: '1rem' }}>
        <FormattedMessage
          id="reports-share-description"
          description="label for report sharing description"
          defaultMessage={`This will share the "{reportName}" report with the email specified.`}
          values={{ reportName: report.name }}
        />
      </AppTypography>
      <ShareReportDialogContent
        onClose={onClose}
        onSubmit={(data) => {
          shareReportMutation.mutate({
            id: report.id,
            email: data.email,
          });
        }}
        isLoading={shareReportMutation.isLoading}
        isError={shareReportMutation.isError}
      />
    </StyledDialog>
  );
}
