import { QueryFunction, QueryKey, UseInfiniteQueryOptions, UseQueryOptions, useQuery } from 'react-query';
import { Membership, MembershipApi, RolesApi } from '../generated';
import { authStore } from '../state/auth/authStore';
import { appConfiguration } from './configuration';

export const rolesApi = new RolesApi(appConfiguration);
export const membershipsApi = new MembershipApi(appConfiguration);

export async function getRoles() {
  const res = await rolesApi.getRoles();
  return res.data;
}

export async function getMembership(userId: string | null) {
  if (!userId) return null;
  const res = await membershipsApi.getMembership(userId).catch(() => null);
  return res?.data;
}

async function getMemberships(companyId?: string) {
  const res = await membershipsApi.getMemberships(0, 999, companyId, 'active');
  return res.data;
}

export async function upsertMembership(
  userId: string,
  membership?: Membership
) {
  if (!membership) return null;

  const getRes = await membershipsApi.getMembership(userId, {
    validateStatus: (status) => {
      return status === 200 || status === 404;
    },
  });

  if (getRes.status === 404) {
    const addRes = await membershipsApi.addMembership(membership, {
      validateStatus: (status) => {
        return status === 201 || status === 404;
      },
    });
    return addRes.status === 404 ? null : addRes.data;
  } else {
    const updateRes = await membershipsApi.updateMembership(
      userId,
      membership,
      {
        validateStatus: (status) => {
          return status === 200 || status === 404;
        },
      }
    );

    return updateRes.status === 404 ? null : updateRes.data;
  }
}

export function useRoles() {
  return useQuery('roles', () => getRoles());
}


export type MembershipQueryOptions = Omit<UseQueryOptions<Membership | null | undefined, QueryFunction<Membership | null | undefined>,Membership | null | undefined, (string | null)[]>, "queryKey" | "queryFn">;
export function useMembership(userId: string | null, opts?: MembershipQueryOptions | undefined) {
  return useQuery(['membership', userId], () => getMembership(userId), {...opts});
}

export function useMemberships(companyId?: string) {
  return useQuery(['memberships', companyId], () => getMemberships(companyId));
}

export function useUserRole() {
  const userId = authStore.getValue().userId;
  const membership = useMembership(userId);
  const membershipData = membership?.data;
  if (!membershipData) return null;

  return membershipData.roleName;
}
