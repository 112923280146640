import { ColorKey } from '@beeriot/api-client';
import { Stack, styled } from '@mui/material';
import { transientOptions } from '../utils/transientOptions';

export const RunningStatusView = styled(
  Stack,
  transientOptions
)<{ $colorKey: ColorKey }>(({ theme, $colorKey }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '1rem',
  width: '100%',
  borderRadius: '0.5rem',
  overflow: 'hidden',
  backgroundColor: theme.palette[$colorKey].main,
}));
