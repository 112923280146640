import { ReactNode } from 'react';
import { styled, Stack } from '@mui/material';
import { AppTableHeader } from './AppTableHeader';
import {
  AppTablePagination,
  AppTablePaginationProps,
} from './AppTablePagination';

const PaginationContainer = styled(Stack)({
  width: '100%',
  flex: 1,
  alignItems: 'end',
});

interface AppTableHeaderPaginationProps extends AppTablePaginationProps {
  label: ReactNode;
}

export const AppTableHeaderPagination = ({
  label,
  page,
  pageSizes,
  rowsPerPage,
  totalRows,
  setPage,
  setRowsPerPage,
}: AppTableHeaderPaginationProps) => {
  return (
    <AppTableHeader label={label}>
      <PaginationContainer>
        <AppTablePagination
          page={page}
          pageSizes={pageSizes}
          rowsPerPage={rowsPerPage}
          totalRows={totalRows}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </PaginationContainer>
    </AppTableHeader>
  );
};
