import { FormattedMessage, useIntl } from 'react-intl';
import { CardContent, Stack, styled } from '@mui/material';
import { GQLSystem, useGQLSystemLiveRegeneration } from '@beeriot/api-client';
import {
  AppTypography,
  AppCard,
  RunningStatusTimelineView,
  VerticalLabels,
} from '../../../base';
import { recentStatusToTimelineData } from '../../../utils';

const TimelineContainer = styled(Stack)({
  padding: '0.25rem 0 0 0',
  width: '100%',
});

const StackContainer = styled(Stack)({
  gap: '1rem',
});

const StyledTypography = styled(AppTypography)({
  marginBottom: '0.5rem',
});

export interface CFSRegenerationCardProps {
  system: GQLSystem;
}

export function CFSRegenerationCard({ system }: CFSRegenerationCardProps) {
  const intl = useIntl();
  const NA = intl.formatMessage({
    id: 'common-not-applicable',
    defaultMessage: 'N/A',
    description: "Label for when there isn't a value to display",
  });
  useGQLSystemLiveRegeneration(system);
  return (
    <AppCard>
      <CardContent>
        <StackContainer>
          <Stack>
            <StyledTypography variant="h6" $bold>
              <FormattedMessage
                id="common-cip-label"
                description="Label for CIP"
                defaultMessage="CIP"
              />
            </StyledTypography>
            <TimelineContainer>
              <RunningStatusTimelineView
                data={recentStatusToTimelineData(
                  system.liveRegeneration?.recentRunningStatus
                )}
              />
              <AppTypography variant="subtitle2" $colorKey="lightText">
                <FormattedMessage
                  id="common-running-status-label"
                  description="Label for running status"
                  defaultMessage="Running Status"
                />
              </AppTypography>
            </TimelineContainer>
          </Stack>
          <VerticalLabels
            labelColorKey="warning"
            label={
              system.liveRegeneration?.phMeter != null
                ? system.liveRegeneration?.phMeter
                : NA
            }
            subLabel={
              <FormattedMessage
                id="cfs-ph-meter-label"
                defaultMessage="pH meter"
                description="Label for cfs meter value"
              />
            }
          />
        </StackContainer>
      </CardContent>
    </AppCard>
  );
}
