import { menuHeights$ } from '@beeriot/api-client';
import { useObservable } from '@ngneat/use-observable';
import {
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useMemo,
} from 'react';
import { MobileDrawer } from '../main-tabs/MobileDrawer';
import { FullsizeContainer } from './FullSizeContainer';

type MobileTabDrawerProps = PropsWithChildren<{
  isMobileSize?: boolean;
  isOpen: boolean;
  setIsOpen?: (open: boolean) => void;
  containerRef?: MutableRefObject<HTMLDivElement | null>;
  content?: JSX.Element;
}>;
export const MobileTabDrawer = ({
  isMobileSize,
  isOpen,
  containerRef,
  setIsOpen,
  children,
}: MobileTabDrawerProps) => {
  const [{ totalMenuHeight } = { totalMenuHeight: 0 }] =
    useObservable(menuHeights$);

  const closeDrawer = useCallback(() => {
    setIsOpen?.(false);
  }, [setIsOpen]);

  return useMemo(() => {
    return isMobileSize ? (
      <MobileDrawer
        anchor={'top'}
        open={isOpen}
        $toolbarHeight={totalMenuHeight}
        onClose={closeDrawer}
        ModalProps={{
          container: containerRef?.current,
        }}
      >
        <FullsizeContainer>{children}</FullsizeContainer>
      </MobileDrawer>
    ) : (
      (children as JSX.Element)
    );
  }, [
    closeDrawer,
    containerRef,
    children,
    isMobileSize,
    isOpen,
    totalMenuHeight,
  ]);
};
