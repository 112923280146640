import { JSXElementConstructor, ReactElement } from 'react';
import { RouteTabObject } from './models/RouteTabObject';

export type RouteTabProps = RouteTabObject;

const _RouteTab = (props: RouteTabProps) => {
  return (
    // return nothing, this element is for structural purposes
    // and is slurrped up by the a Tabs component
    //
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <span></span>
  );
};

export const RouteTab = _RouteTab as (
  props: RouteTabProps
) => ReactElement<typeof _RouteTab, JSXElementConstructor<typeof _RouteTab>>;
