import { styled, Stack } from '@mui/material';
import {
  GlobalFooter,
  PermissionKey,
  PermissionView,
  RunningStatusLegendRow,
  LegendContainer,
  ParamIdReader,
} from '../../../base';
import { SiteDetailsSidebarDrawer } from '../../../sites';
import { useResponsiveSizes } from '../../../utils/responsive-design/useResponsiveSizes';
import { BaseAlertsSidebarPage } from '../../BaseAlertsSidebarPage';
import { SiteDetailsControlBox } from './SiteDetailsControlBox';
import { SiteDetailsContent } from './SiteDetailsContent';
import { useObservable } from '@ngneat/use-observable';
import { activeSite$, useActiveEntityRouteParams, useAreas } from '@beeriot/api-client';

const SiteStack = styled(Stack)({
  overflow: 'auto',
  flex: '1 1 auto',
});

export function SitesDetailsPage() {
  const { isDesktopSize } = useResponsiveSizes();
  const [activeSite] = useObservable(activeSite$);
  
  const routeParams = useActiveEntityRouteParams();
  useAreas(activeSite?.id ?? routeParams.siteId);
  return (
    <>
      <ParamIdReader />
      <PermissionView redirect permissionKey={PermissionKey.SiteDetails}>
        <BaseAlertsSidebarPage header={<SiteDetailsControlBox />}>
          <PermissionView
            permissionKey={PermissionKey.SitesSiteOverviewSidebar}
          >
            <SiteDetailsSidebarDrawer />
          </PermissionView>
          <SiteStack>
            <SiteDetailsContent />
            <GlobalFooter
              topFooterComponent={
                !isDesktopSize ? (
                  <LegendContainer>
                    <RunningStatusLegendRow />
                  </LegendContainer>
                ) : null
              }
            />
          </SiteStack>
        </BaseAlertsSidebarPage>
      </PermissionView>
    </>
  );
}
