import { useCallback } from 'react';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import { FileWithPath } from 'react-dropzone';
import {
  useCreateSupportTicket,
  SupportTicketBase,
  userInfo$,
  activeCompany$,
  activeSite$,
} from '@beeriot/api-client';
import {
  ContactSupportForm,
  ContactSupportFormFields,
} from './ContactSupportForm';

function fileToBase64(file: FileWithPath): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file as File);
    reader.onload = () => {
      let encoded = reader?.result?.toString().replace(/^data:(.*,)?/, '');
      if (encoded && encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      if (encoded) resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
  });
}

function createAttachments(files: FileWithPath[]): Promise<Attachment[]> {
  const attachments = Promise.all(
    files.map(async (x): Promise<Attachment> => {
      const file = x ?? undefined;

      //Stripping the data:... portion of the URL is required to produce valid base64
      const fileFormatParts = file?.path?.split('.') ?? [];

      const fileFormat =
        fileFormatParts.length > 0
          ? fileFormatParts[fileFormatParts.length - 1]
          : undefined;
      const base64 = file ? await fileToBase64(file) : undefined;
      const item: Attachment = {
        name: file?.name ?? '',
        extension: fileFormat,
        encodedString: base64,
      };
      return item;
    })
  ).then((values) => {
    return values;
  });
  return attachments;
}

interface Attachment {
  name: string;
  extension: string | undefined;
  encodedString: string | undefined;
}

export interface ContactSupportDialogProps {
  open: boolean;
  onClose: () => void;
}

export function ContactSupportDialog({
  open,
  onClose,
}: ContactSupportDialogProps) {
  const theme = useTheme();
  const [activeCompany] = useObservable(activeCompany$);
  const [activeSite] = useObservable(activeSite$);
  const [userInfo] = useObservable(userInfo$);
  const createSupportTicket = useCreateSupportTicket();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const onSubmit = useCallback(
    async (data: ContactSupportFormFields) => {
      const attachments = data?.files
        ? await createAttachments(data.files)
        : [];

      const item: SupportTicketBase = {
        summary: `${data.site.label} - ${data.system.label}`,
        description: data.description,
        machineSerialNumber: data.system.value,
        companyId: activeCompany?.id ?? '',
        companySiteId: activeSite?.id ?? '',
        customerInfo: {
          name: userInfo?.name ?? '',
          siteName: data.site.label,
        },
        attachments: attachments,
      };
      createSupportTicket.mutate(item);
    },
    [activeCompany?.id, activeSite?.id, userInfo?.name, createSupportTicket]
  );
  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') onClose();
      }}
      maxWidth="lg"
      scroll={isSmall ? 'body' : undefined}
    >
      <ContactSupportForm
        onSubmit={onSubmit}
        onCancel={onClose}
        mutation={createSupportTicket}
      />
    </Dialog>
  );
}
