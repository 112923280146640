import { useCallback, useMemo, useState } from 'react';
import { Button, Paper, Stack, styled } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, SelectOption, FormSwitch, FormError } from '../../form';
import {
  SectionHeader,
  SectionControl,
  SectionLabel,
  SettingsFooterButtons,
  SectionFormSelect,
  SectionTextInput,
  TitleHeaderStack,
} from '../settings-form';
import {
  failureFrequencyAlertOptions,
  getLanguageOptions,
} from '../Settings.models';
import { useIntlLanguage } from '../../enums';
import { UseMutationResult } from 'react-query';
import {
  SettingsUsersAlerts,
  SettingsUsersAlertsDeliveryOptionsFailureFrequencyAlertEnum,
  SettingsUsersAlertsDeliveryOptionsLanguageEnum,
  useUploadAlertTypeMappings,
} from '@beeriot/api-client';
import { FormErrorContainer } from '../../auth/FormErrorContainer';
import {
  AppDialog,
  AppTypography,
  PermissionKey,
  PermissionView,
  useHasPermission,
} from '../../base';
import { AlertTypeMappingUploadDialog } from './AlertTypeMappingUploadDialog';

const StyledPaper = styled(Paper)({
  maxWidth: '40rem',
});

const StyledFormErrorContainer = styled(FormErrorContainer)({
  margin: '1rem',
});

interface AlertSettingsFormFields {
  email?: string;
  machineAlarms?: boolean;
  progressAlerts?: boolean;
  maintenanceWarnings?: boolean;
  systemWarnings?: boolean;
  language?: SelectOption;
  failureFrequencyAlert?: SelectOption;
}

const mapDefaultValues = function (
  languageOptions: SelectOption[],
  alertSettings?: AlertSettingsFields
): AlertSettingsFormFields | undefined {
  if (alertSettings === undefined) return undefined;

  const defaultValues: AlertSettingsFormFields = {
    email: alertSettings.email,
    machineAlarms: alertSettings.machineAlarms ?? false,
    progressAlerts: alertSettings.progressAlerts ?? false,
    maintenanceWarnings: alertSettings.maintenanceWarnings ?? false,
    systemWarnings: alertSettings.systemWarnings ?? false,
    language: languageOptions.find(
      (data) => data.value === alertSettings?.language
    ),
    failureFrequencyAlert: failureFrequencyAlertOptions.find(
      (data) => data.value === alertSettings?.failureFrequencyAlert
    ),
  };

  return defaultValues;
};

export interface AlertSettingsFields {
  email?: string;
  machineAlarms?: boolean;
  progressAlerts?: boolean;
  maintenanceWarnings?: boolean;
  systemWarnings?: boolean;
  language?: SettingsUsersAlertsDeliveryOptionsLanguageEnum;
  failureFrequencyAlert?: SettingsUsersAlertsDeliveryOptionsFailureFrequencyAlertEnum;
}

const fromFormFields = function (
  id: string,
  entityId: string,
  formFields: AlertSettingsFormFields
): SettingsUsersAlerts {
  return {
    id: id,
    entityId: entityId,
    type: 'alerts',
    alertTypes: {
      machineAlarms: formFields.machineAlarms ?? true,
      progressAlerts: formFields.progressAlerts ?? true,
      maintenanceWarnings: formFields.maintenanceWarnings ?? true,
      systemWarnings: formFields.systemWarnings ?? true,
    },
    deliveryOptions: {
      language: formFields.language
        ?.value as SettingsUsersAlertsDeliveryOptionsLanguageEnum,
      failureFrequencyAlert: formFields.failureFrequencyAlert
        ?.value as SettingsUsersAlertsDeliveryOptionsFailureFrequencyAlertEnum,
    },
  };
};

export interface AlertSettingsProps {
  id: string;
  entityId: string;
  alertSettings?: AlertSettingsFields;
  mutation?: UseMutationResult<unknown, unknown, SettingsUsersAlerts, unknown>;
  error?: string;
}

export const AlertSettings = ({
  id,
  entityId,
  alertSettings,
  mutation,
  error,
}: AlertSettingsProps) => {
  const intlLanguage = useIntlLanguage();
  const languageOptions = getLanguageOptions(intlLanguage);
  const hasPermission = useHasPermission();
  const defaultValues = useMemo(
    () => mapDefaultValues(languageOptions, alertSettings),
    [alertSettings, languageOptions]
  );
  const intl = useIntl();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const alertMappingUploadMutation = useUploadAlertTypeMappings(() => {
    alertMappingUploadMutation.reset();
    setDialogOpen(false);
    setSuccessDialogOpen(true);
  });

  const onSubmit = useCallback(
    (data: AlertSettingsFormFields) => {
      mutation?.mutate(fromFormFields(id, entityId, data));
    },
    [entityId, id, mutation]
  );

  const readOnlyView = !hasPermission(PermissionKey.SettingsAlertsEdit);

  return (
    <StyledPaper>
      <Form<AlertSettingsFormFields>
        onSubmit={onSubmit}
        formProps={{ defaultValues: defaultValues }}
      >
        <Stack>
          <TitleHeaderStack
            direction="row"
            sx={{ alignItems: 'center', justifyContent: 'space-between' }}
          >
            <AppTypography variant="h6" $bold>
              <FormattedMessage
                id="settings-alert-settings-header-title"
                defaultMessage="Alert Settings"
                description="label for alert settings header"
              />
            </AppTypography>
            <Button variant="contained" onClick={() => setDialogOpen(true)}>
              <FormattedMessage
                id="settings-alert-settings-header-upload-type-mapping-button"
                defaultMessage="Upload Type Mapping"
                description="button for uploading alert type mapping"
              />
            </Button>
          </TitleHeaderStack>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-alert-settings-email-label"
                defaultMessage="Email"
                description="label for Email"
              />
            </SectionLabel>
            <SectionTextInput
              name="email"
              type="email"
              disabled
              placeholder={intl.formatMessage({
                id: 'settings-alert-settings-email-placeholder',
                defaultMessage: 'Enter Email',
                description: 'label for Email placeholder',
              })}
              readOnly={!hasPermission(PermissionKey.SettingsAlertsEdit)}
            />
          </SectionControl>
        </Stack>
        <Stack>
          <SectionHeader>
            <FormattedMessage
              id="settings-alert-settings-alert-types-title"
              defaultMessage="Alert Types"
              description="header for Alert Types"
            />
          </SectionHeader>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-alert-settings-machine-alarms-label"
                defaultMessage="Machine Alarms"
                description="label for Machine Alarms"
              />
            </SectionLabel>
            <FormSwitch
              name="machineAlarms"
              defaultValue={defaultValues?.machineAlarms}
              readOnly={!hasPermission(PermissionKey.SettingsAlertsEdit)}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-alert-settings-progress-alerts-label"
                defaultMessage="Progress Alerts"
                description="label for Progress Alerts"
              />
            </SectionLabel>
            <FormSwitch
              name="progressAlerts"
              defaultValue={defaultValues?.progressAlerts}
              readOnly={!hasPermission(PermissionKey.SettingsAlertsEdit)}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-alert-settings-maintenance-warnings-label"
                defaultMessage="Maintenance Warnings"
                description="label for Maintenance Warnings"
              />
            </SectionLabel>
            <FormSwitch
              name="maintenanceWarnings"
              defaultValue={defaultValues?.maintenanceWarnings}
              readOnly={!hasPermission(PermissionKey.SettingsAlertsEdit)}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-alert-settings-system-warnings-label"
                defaultMessage="System Warnings"
                description="label for System Warnings"
              />
            </SectionLabel>
            <FormSwitch
              name="systemWarnings"
              defaultValue={defaultValues?.systemWarnings}
              readOnly={!hasPermission(PermissionKey.SettingsAlertsEdit)}
            />
          </SectionControl>
        </Stack>
        <Stack>
          <SectionHeader>
            <FormattedMessage
              id="settings-alert-settings-delivery-options-title"
              defaultMessage="Delivery Options"
              description="header for Delivery Options"
            />
          </SectionHeader>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="commmon-language-label"
                defaultMessage="Language"
                description="label for language"
              />
            </SectionLabel>
            {readOnlyView ? (
              <SectionLabel>
                <AppTypography>{defaultValues?.language?.label}</AppTypography>
              </SectionLabel>
            ) : null}
            <PermissionView permissionKey={PermissionKey.SettingsAlertsEdit}>
              <SectionFormSelect
                name="language"
                placeholder={intl.formatMessage({
                  id: 'commmon-language-placeholder',
                  defaultMessage: 'Select Language',
                  description: 'label for language placeholder',
                })}
                options={languageOptions}
                defaultValue={defaultValues?.language}
              />
            </PermissionView>
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-alert-settings-failure-frequency-alert-label"
                defaultMessage="Failure Frequency Alert"
                description="label for Failure Frequency Alert"
              />
            </SectionLabel>
            {readOnlyView ? (
              <SectionLabel>
                {defaultValues?.failureFrequencyAlert?.label}
              </SectionLabel>
            ) : null}
            <PermissionView permissionKey={PermissionKey.SettingsAlertsEdit}>
              <SectionFormSelect
                name="failureFrequencyAlert"
                placeholder={intl.formatMessage({
                  id: 'settings-alert-settings-failure-frequency-alert-placeholder',
                  defaultMessage: 'Select Failure Frequency Alert',
                  description: 'label for Failure Frequency Alert placeholder',
                })}
                options={failureFrequencyAlertOptions}
                defaultValue={defaultValues?.failureFrequencyAlert}
              />
            </PermissionView>
          </SectionControl>
        </Stack>
        <StyledFormErrorContainer>
          <FormError error={error} color="info" />
        </StyledFormErrorContainer>
        <SettingsFooterButtons
          isLoading={mutation?.isLoading}
          submitPermissionKey={PermissionKey.SettingsAlertsEdit}
        />
      </Form>
      <AppDialog
        dialogTitle={
          <FormattedMessage
            id="common-success-label"
            description="Label for a successful event"
            defaultMessage="Success!"
          />
        }
        dialogContent={
          <AppTypography>
            <FormattedMessage
              id="settings-alert-settings-upload-success-label"
              defaultMessage="Alert Type Mapping successfully uploaded and processed."
              description="Label for alert type mapping upload success message"
            />
          </AppTypography>
        }
        open={successDialogOpen}
        confirm={() => setSuccessDialogOpen(false)}
        confirmLabel={intl.formatMessage({
          id: 'common-ok-button',
          defaultMessage: 'Ok',
          description: 'label for ok button',
        })}
        showCancelButton={false}
      />
      <AlertTypeMappingUploadDialog
        mutation={alertMappingUploadMutation}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </StyledPaper>
  );
};
