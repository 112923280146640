import { ReactElement } from 'react';
import { Stack, Box, styled } from '@mui/material';
import { AppTypography } from '../AppTypography';
import { AppHideSidebarButton } from './AppHideSidebarButton';
import { AppShowSidebarButton } from './AppShowSidebarButton';

const StyledOpenHeaderStack = styled(Stack)({
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0.8rem 0.8rem 0rem 0.8rem',
});

const StyledStack = styled(Stack)({
  marginTop: '1rem',
  alignItems: 'center',
  alignSelf: 'center',
});

const VerticalText = styled(AppTypography)({
  writingMode: 'vertical-rl',
  transform: 'rotate(-180deg)',
  padding: '0.5rem 0',
});

export interface AppSidebarDrawerHeaderProps {
  isOpen: boolean;
  toggleOpen: () => void;
  hideToggleButton?: boolean;
  headerLabel: string;
  anchor?: 'left' | 'right';
  openHeaderLabelOverride?: ReactElement;
}

export function AppSidebarDrawerHeader({
  isOpen,
  toggleOpen,
  hideToggleButton,
  headerLabel,
  anchor = 'left',
  openHeaderLabelOverride,
}: AppSidebarDrawerHeaderProps) {
  const shouldShowToggleButton =
    hideToggleButton === undefined || !hideToggleButton;

  return isOpen ? (
    <StyledOpenHeaderStack direction="row">
      {shouldShowToggleButton && anchor === 'right' && (
        <AppHideSidebarButton anchor={anchor} toggleOpen={toggleOpen} />
      )}
      <Box>
        {openHeaderLabelOverride ?? (
          <AppTypography $bold variant="h6">
            {headerLabel}
          </AppTypography>
        )}
      </Box>
      {shouldShowToggleButton && anchor === 'left' && (
        <AppHideSidebarButton anchor={anchor} toggleOpen={toggleOpen} />
      )}
    </StyledOpenHeaderStack>
  ) : (
    <StyledStack>
      {shouldShowToggleButton && (
        <AppShowSidebarButton anchor={anchor} toggleOpen={toggleOpen} />
      )}
      <VerticalText $bold>{headerLabel}</VerticalText>
    </StyledStack>
  );
}
