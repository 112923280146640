/* eslint-disable @typescript-eslint/no-explicit-any */
import { InfiniteData, QueryClient, QueryKey } from 'react-query';
import { removeFalsey } from './removeFalsey';

// For use within an onSuccess call of a react-query useMutation
// T is a GQL query e.g. GQLListBeerBrandsQuery
// K is the GQL model for T e.g. GQLBeerBrand
export async function insertToInfiniteData<T extends object, K>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  listKey: string,
  mutatedModel: K
) {
  await queryClient.cancelQueries(queryKey);
  const previousState = queryClient.getQueryData<InfiniteData<T>>(queryKey);
  const newData = previousState
    ? { ...previousState }
    : { pages: [], pageParams: [] };

  if (newData.pages.length > 0) {
    const lastPage = removeFalsey<K>(
      (newData.pages[newData.pages.length - 1] as any)[listKey]
    );
    lastPage.push(mutatedModel);
    (newData.pages[newData.pages.length - 1] as any)[listKey] = lastPage;
    queryClient.setQueryData(queryKey, newData);
  }

  return { previousState };
}
