import { createStore, select, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { addMonths } from 'date-fns';
import { GQLBeerBrand, GQLSystem } from '../generated/gql';

export enum AnalyticMeasureName {
  degreeOfEfficiency = 'degreeOfEfficiency',
  volumeFiltered = 'volumeFiltered',
  totalStabilizedBeer = 'totalStabilizedBeer',
  waterConsumption = 'waterConsumption',
  electricalEnergy = 'electricalEnergy',
  peroxideConsumption = 'peroxideConsumption',
  membraneCleanerKg = 'membraneCleanerKg',
  netCapacity = 'netCapacity',
  electricalCost = 'electricalCost',
  waterCost = 'waterCost',
  dawaterCost = 'dawaterCost',
  causticCost = 'causticCost',
  peroxideCost = 'peroxideCost',
  acidicCost = 'acidicCost',
  membraneCleanerCost = 'membraneCleanerCost',
  coolingEnergyCost = 'coolingEnergyCost',
  enzymaticCost = 'enzymaticCost',
  totalCost = 'totalCost',
  avgCost = 'avgCost',
  clusterTestFailurePct = 'clusterTestFailurePct',
  hazeCentrifugeOut = 'hazeCentrifugeOut',
  hazeFilterOut = 'hazeFilterOut',
  coolingEnergy = 'coolingEnergy',
  enzymaticConsumption = 'enzymaticConsumption',
  dawaterConsumption = 'dawaterConsumption',
  causticConsumption = 'causticConsumption',
  acidicChemical = 'acidicChemical',
}

export interface AnalyticsMetric {
  id: string;
  category: string;
  value: string;
  highlight: boolean;
}

export interface AnalyticsStoreProps {
  measures: AnalyticMeasureName[];
  systems: GQLSystem[];
  beerBrands: GQLBeerBrand[];
  startDate: string;
  endDate: string;
  showMissingData: boolean;
}

const lastMonth = addMonths(new Date(), -1);

export const analyticsStore = createStore(
  { name: 'analytics' },
  withProps<AnalyticsStoreProps>({
    measures: [],
    systems: [],
    beerBrands: [],
    startDate: lastMonth.toISOString(),
    endDate: new Date().toISOString(),
    showMissingData: false,
  })
);

persistState(analyticsStore, {
  key: 'analytics',
  storage: sessionStorageStrategy,
});

export const analyticsMeasures$ = analyticsStore.pipe(
  select((state) => {
    return state.measures;
  })
);

export const analyticsSystems$ = analyticsStore.pipe(
  select((state) => {
    return state.systems;
  })
);

export const analyticsBeerBrands$ = analyticsStore.pipe(
  select((state) => {
    return state.beerBrands;
  })
);

export const analyticsStartDate$ = analyticsStore.pipe(
  select((state) => {
    return state.startDate;
  })
);

export const analyticsEndDate$ = analyticsStore.pipe(
  select((state) => {
    return state.endDate;
  })
);

export const showMissingAnalyticsData$ = analyticsStore.pipe(
  select((state) => {
    return state.showMissingData;
  })
);
