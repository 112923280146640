import { useCallback } from 'react';
import { UseMutationResult } from 'react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { Stack, Paper, styled } from '@mui/material';
import { IotProvisioningUrl, useActiveAppEntities } from '@beeriot/api-client';
import {
  SectionControl,
  SectionFormSelect,
  SectionLabel,
  SettingsFooterButtons,
  TitleHeader,
} from '../settings-form';
import { Form, FormError, SelectOption } from '../../form';
import { FormErrorContainer } from '../../auth/FormErrorContainer';
import { SuspenseCertificateProvisioningSystemFields } from './SuspenseCertificateProvisioningSystemFields';
import { CertificateDownloadButton } from './CertificateDownloadButton';
import { CertificateProvisioningFormFields } from './CertificateProvisioningFormFields';
import { PermissionKey } from '../../base';

const StyledPaper = styled(Paper)({
  maxWidth: '40rem',
});

const StyledFormErrorContainer = styled(FormErrorContainer)({
  margin: '1rem',
});

interface CertificateProvisioningFormProps {
  getCertificateMutation: UseMutationResult<
    IotProvisioningUrl,
    unknown,
    {
      companyId?: string | undefined;
      iotId?: string | undefined;
    },
    unknown
  >;
  clearError: () => void;
  error?: string;
}

export function CertificateProvisioningForm({
  getCertificateMutation,
  clearError,
  error,
}: CertificateProvisioningFormProps) {
  const { activeCompany } = useActiveAppEntities();
  const intl = useIntl();
  const standalone = intl.formatMessage({
    id: 'certificate-provisioning-deployment-type-standalone',
    defaultMessage: 'Standalone',
    description: 'Label for the standalone deployment type dropdown option',
  });
  const scada = intl.formatMessage({
    id: 'certificate-provisioning-deployment-type-scada',
    defaultMessage: 'SCADA',
    description: 'Label for the SCADA deployment type dropdown option',
  });
  const deploymentOptions: SelectOption[] = [
    { label: standalone, value: 'Standalone' },
    { label: scada, value: 'SCADA' },
  ];

  const onSubmit = useCallback(
    (data: CertificateProvisioningFormFields) => {
      clearError();
      getCertificateMutation.mutate({
        companyId: activeCompany?.id,
        iotId:
          data.deploymentType.value === 'Standalone'
            ? data.system?.value
            : data.site.value,
      });
    },
    [activeCompany?.id, clearError, getCertificateMutation]
  );

  return (
    <StyledPaper>
      <Form<CertificateProvisioningFormFields> onSubmit={onSubmit}>
        <Stack>
          <TitleHeader>
            <FormattedMessage
              id="certificate-provisioning-header-title"
              defaultMessage="Certificate Provisioning"
              description="label for certificate provisioning header"
            />
          </TitleHeader>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="certificate-provisioning-deployment-type-label"
                defaultMessage="Deployment Type"
                description="label for deployment type dropdown"
              />
            </SectionLabel>
            <SectionFormSelect
              required
              name="deploymentType"
              placeholder={intl.formatMessage({
                id: 'certificate-provisioning-deployment-type-placeholder',
                defaultMessage: 'Select Deployment Type',
                description: 'label for deployment type placeholder',
              })}
              options={deploymentOptions}
            />
          </SectionControl>
          <SuspenseCertificateProvisioningSystemFields />
          <StyledFormErrorContainer>
            <FormError error={error} color="info" />
          </StyledFormErrorContainer>
          {getCertificateMutation.data?.url ? (
            <CertificateDownloadButton
              getCertificateMutation={getCertificateMutation}
            />
          ) : (
            <SettingsFooterButtons
              submitLabel={intl.formatMessage({
                id: 'certificate-provisioning-generate-certs-button',
                defaultMessage: 'Generate Certs',
                description:
                  'Button for submitting form for certificate generation',
              })}
              isLoading={getCertificateMutation.isLoading}
              submitPermissionKey={
                PermissionKey.SettingsCertificateProvisioningGenerate
              }
            />
          )}
        </Stack>
      </Form>
    </StyledPaper>
  );
}
