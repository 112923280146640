import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { Stack, Button, Typography, styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { ControlBox, LoadingPaper } from '../../../../base';

const OrganizeSitesControlBox = lazy(() =>
  import('./OrganizeSitesControlBoxQuery').then(
    ({ OrganizeSitesControlBoxQuery }) => ({
      default: OrganizeSitesControlBoxQuery,
    })
  )
);

const ControlBoxLoadingPaper = styled(LoadingPaper)({
  height: '4rem',
  width: '100%',
  margin: '1rem',
});

const ErrorContainer = styled(Stack)({
  width: '100%',
  padding: '1rem',
  alignItems: 'center',
});

const MessageContainer = styled(Stack)({
  textAlign: 'center',
  justifyContent: 'center',
});

const StyledControlBox = styled(ControlBox)({
  padding: '0px',
});

function ControlBoxLoadingPapers() {
  return (
    <Stack direction="row">
      <ControlBoxLoadingPaper />
    </Stack>
  );
}

export function SuspenseOrganizeSitesControlBox() {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ resetErrorBoundary }) => {
            return (
              <StyledControlBox>
                <ErrorContainer>
                  <MessageContainer>
                    <Typography>
                      <FormattedMessage
                        id="organize-sites-group-type-list-no-group-types-error"
                        description="Error message for no group types"
                        defaultMessage="No Group Types Defined"
                      />
                    </Typography>
                    <Button onClick={() => resetErrorBoundary()}>
                      <FormattedMessage
                        id="common-try-again-button"
                        description="Button for trying an action again"
                        defaultMessage="Try Again"
                      />
                    </Button>
                  </MessageContainer>
                </ErrorContainer>
              </StyledControlBox>
            );
          }}
          onReset={reset}
        >
          <Suspense fallback={<ControlBoxLoadingPapers />}>
            <OrganizeSitesControlBox />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
