import { useHasPermission, PermissionKey } from '../base';
import { useResponsiveSizes } from '../utils';

export function useShowSiteFilterForm() {
  const hasPermission = useHasPermission();
  const showCompanySelect = hasPermission(PermissionKey.CompaniesList);
  const { isDesktopSize, isXLSize } = useResponsiveSizes();
  const showFiltersForm = isXLSize || (isDesktopSize && !showCompanySelect);
  return showFiltersForm;
}
