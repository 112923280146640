import { ReactNode, useState } from 'react';
import { Button, Stack, styled } from '@mui/material';
import { AppTypography } from './AppTypography';
import { FormattedMessage } from 'react-intl';

const StyledButton = styled(Button)(({ theme }) => ({
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
  padding: '1rem',
}));

const ContainerStack = styled(Stack)({
  gap: '1rem',
});

const TableStack = styled(Stack)(({ theme }) => ({
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.white.main,
  '& .item:nth-of-type(even)': {
    backgroundColor: theme.palette.componentBase.main,
  },
}));

interface MobileTableProps {
  title: string;
  noItemsMessage: string;
  items: ReactNode[];
}

export function MobileTable({
  title,
  noItemsMessage,
  items,
}: MobileTableProps) {
  const [displayCount, setDisplayCount] = useState(10);
  const displayItems = items.slice(0, displayCount);
  return (
    <ContainerStack>
      <AppTypography $bold>{title}</AppTypography>
      {items.length === 0 && <AppTypography>{noItemsMessage}</AppTypography>}
      <TableStack>
        {displayItems.map((item, idx) => (
          <Stack key={`${idx}`} className="item">
            {item}
          </Stack>
        ))}
        {items.length > displayCount && (
          <Stack>
            <StyledButton onClick={() => setDisplayCount(displayCount + 10)}>
              <FormattedMessage
                id="common-show-more-button"
                defaultMessage="Show More"
                description="Button to show more information"
              />
            </StyledButton>
          </Stack>
        )}
      </TableStack>
    </ContainerStack>
  );
}
