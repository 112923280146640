import { Button, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { AppIcon } from '../AppIcon';
import { AppTypography } from '../AppTypography';

const StyledShowButton = styled(Button)({
  minWidth: '0',
  flexDirection: 'column',
});

const VerticalText = styled(AppTypography)({
  writingMode: 'vertical-rl',
  transform: 'rotate(-180deg)',
  padding: '0.5rem 0',
});

export interface AppShowSidebarButtonProps {
  toggleOpen: () => void;
  anchor: 'left' | 'right';
}

export function AppShowSidebarButton({
  toggleOpen,
  anchor,
}: AppShowSidebarButtonProps) {
  return (
    <StyledShowButton onClick={toggleOpen}>
      <AppIcon
        icon={anchor === 'left' ? 'angles-right' : 'angles-left'}
        $colorKey={'subtitleText'}
        size={'lg'}
      />
      <VerticalText $colorKey="lightText" variant="subtitle2">
        <FormattedMessage
          id="common-show-button"
          defaultMessage="Show"
          description="Button to perform the show action"
        />
      </VerticalText>
    </StyledShowButton>
  );
}
