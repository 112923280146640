import { FormattedMessage, useIntl } from 'react-intl';
import {
  ButtonBase,
  ButtonBaseProps,
  Divider,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import {
  activeSystem$,
  AreaSystem,
  ColorKey,
  GQLSystem,
} from '@beeriot/api-client';
import {
  RunningState,
  RunningStateColorKey,
  RunningStateIcon,
  useIntlRunningState,
} from '../../../enums';
import { transientOptions } from '../../../utils/transientOptions';
import { formatTimeFromSeconds } from '../../../utils/formatUnits';
import { getEnumFromString } from '../../../utils/getEnumFromString';
import { AppIcon } from '../../AppIcon';
import { AppMedallion } from '../../AppMedallion';
import { IconLabel } from '../../IconLabel';
import { VerticalLabels } from '../../VerticalLabels';
import { useCallback } from 'react';
import { ParentPageName } from '../types/ParentPageName';
import {
  useIsExecSummaryPage,
  useNavigateCancellable,
} from '../../../navigation/hooks';

const AreaMedallion = styled(AppMedallion)({
  width: '1rem',
  height: '1rem',
});

const NoSystemsTypography = styled(Typography)({
  textAlign: 'center',
  padding: '1rem',
});

const ContainerStack = styled(Stack)({
  width: '20rem',
  maxHeight: '50rem',
});

const StyledIconLabel = styled(IconLabel)(({ theme }) => ({
  padding: '0.25rem 0.5rem',
  textTransform: 'uppercase',
  backgroundColor: theme.palette.componentShade.main,
}));

const SiteButton = styled(
  ButtonBase,
  transientOptions
)<ButtonBaseProps & { $selected: boolean }>(({ theme, $selected }) => ({
  alignItems: 'center',
  padding: '0.25rem 0.5rem',
  backgroundColor: $selected ? theme.palette.disabledPrimary.main : 'inherit',
}));

const SiteSelectedIcon = styled(AppIcon)({
  marginRight: '0.5rem',
});

const TimeRemainingStack = styled(Stack)({
  marginLeft: 'auto',
});

interface BreadcrumbsDropdownProps {
  areas?: AreaSystem[] | null;
  showSelectedLine?: boolean;
  parentPageName?: ParentPageName;
  onClose: () => void;
  onSelectSystem?: (system: GQLSystem) => void;
}

const colorKeys: ColorKey[] = [
  'highlight3',
  'highlight2',
  'highlight4',
  'highlight5',
  'highlight7',
  'highlight1',
];

export function BreadcrumbsLineDropdown({
  areas,
  showSelectedLine,
  onClose,
  onSelectSystem,
}: BreadcrumbsDropdownProps) {
  const { navigate } = useNavigateCancellable();
  const [activeSystem] = useObservable(activeSystem$);
  const intlRunningState = useIntlRunningState();
  const isExecSummary = useIsExecSummaryPage();

  const selectSystem = useCallback(
    (system: GQLSystem) => {
      onClose();
      if (onSelectSystem) {
        onSelectSystem(system);
      } else {
        const route = isExecSummary
          ? `/companies/${system.companyId}/exec-summary/${system.companySiteId}/lines/${system.id}/summary`
          : `/companies/${system.companyId}/sites/${system.companySiteId}/lines/${system.id}`;
        navigate(route, { replace: true });
      }
    },
    [onClose, onSelectSystem, isExecSummary, navigate]
  );

  const intl = useIntl();
  const NA = intl.formatMessage({
    id: 'common-not-applicable',
    defaultMessage: 'N/A',
    description: "Label for when there isn't a value to display",
  });

  return (
    <ContainerStack>
      {areas?.length === 0 && (
        <NoSystemsTypography>
          <FormattedMessage
            id="systems-no-systems-found-label"
            description="Label for when there are no systems found"
            defaultMessage="No Systems Found"
          />
        </NoSystemsTypography>
      )}
      {areas?.map((areaSystems, i) => {
        const areaColorKey = colorKeys[i % colorKeys.length];
        return (
          <Stack key={areaSystems.area.id}>
            <StyledIconLabel
              bold
              gap="0.5rem"
              variant="subtitle2"
              colorKey="subtitleText"
              label={areaSystems.area.name}
              customIcon={<AreaMedallion colorKey={areaColorKey} />}
            />
            {areaSystems.systems.map((system) => {
              const selected =
                !!showSelectedLine && system.id === activeSystem?.id;
              const runningState =
                getEnumFromString(
                  RunningState,
                  system.liveStatus?.currentSystemStatus
                ) ?? RunningState.Off;
              return (
                <Stack key={system.id}>
                  <SiteButton
                    $selected={selected}
                    onClick={() => selectSystem(system)}
                  >
                    {selected && (
                      <SiteSelectedIcon icon="check" $colorKey="primary" />
                    )}
                    <VerticalLabels
                      labelBold
                      labelVariant="body1"
                      labelColorKey={selected ? 'primary' : 'darkText'}
                      label={system.name}
                      subLabelBold
                      subLabelVariant="subtitle2"
                      subLabel={intlRunningState(runningState)}
                      subLabelColorKey={RunningStateColorKey[runningState]}
                      subLabelIcon={RunningStateIcon[runningState]}
                    />
                    <TimeRemainingStack>
                      <VerticalLabels
                        labelBold
                        labelVariant="body1"
                        labelColorKey="primary"
                        label={
                          system?.liveStatus?.remainingTimeSeconds != null
                            ? formatTimeFromSeconds(
                                system.liveStatus.remainingTimeSeconds
                              )
                            : NA
                        }
                        subLabelVariant="subtitle2"
                        subLabel={
                          <FormattedMessage
                            id="common-time-remaining-label"
                            defaultMessage="Time Remaining"
                            description="Label for time remaining"
                          />
                        }
                      />
                    </TimeRemainingStack>
                  </SiteButton>
                  <Divider />
                </Stack>
              );
            })}
          </Stack>
        );
      })}
    </ContainerStack>
  );
}
