import { setDropdownText } from '@beeriot/api-client';
import { useEffect } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

export function useDropdownLabelText(message: MessageDescriptor) {
  const intl = useIntl();
  const lineStatusLabel = intl.formatMessage(message);
  useEffect(() => {
    setDropdownText(lineStatusLabel);
  }, [lineStatusLabel]);
}
