import { Button, styled } from '@mui/material';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  DateFormat,
  formatDateRangeLabel,
  useFormatDate,
} from '../../utils/formatUnits';
import { AppToggleButtonGroupItem } from '../AppToggleButton';
import { IconLabel } from '../IconLabel';
import { MobileCombinedDatePickerDialog } from './MobileCombinedDatePickerDialog';

const StyledButton = styled(Button)({
  width: '100%',
});

export interface MobileCombinedDatePickerButtonProps {
  className?: string;
  isRanged: boolean;
  startDate: Date;
  endDate: Date;
  onSubmit: (
    selectedToggleItem: string,
    newStartDate: string,
    newEndDate: string | null
  ) => void;
  initToggleButtonGroupSelectedItem: AppToggleButtonGroupItem;
  toggleButtonGroupItems: AppToggleButtonGroupItem[];
  singleDateToggleItems: string[]; //Array of enum values that need a single date input
  buttonLabel?: string;
  dateFormat?: DateFormat;
  dateRangeLabelFormatString?: string;
  alternateForm?: ReactNode;
  alternateFormToggleItem?: AppToggleButtonGroupItem;
  altFormClosedDialog?: boolean;
  setAltFormClosedDialog?: (val: boolean) => void;
  $tallDialog?: boolean;
}

export function MobileCombinedDatePickerButton({
  className,
  isRanged,
  startDate,
  endDate,
  onSubmit,
  initToggleButtonGroupSelectedItem,
  toggleButtonGroupItems,
  singleDateToggleItems,
  buttonLabel,
  dateFormat,
  dateRangeLabelFormatString,
  alternateForm,
  alternateFormToggleItem,
  altFormClosedDialog,
  setAltFormClosedDialog,
}: MobileCombinedDatePickerButtonProps) {
  const [open, setOpen] = useState(false);
  const formatDate = useFormatDate(dateFormat);

  useEffect(() => {
    if (altFormClosedDialog === true) {
      setOpen(false);
      setAltFormClosedDialog?.(false);
    }
  }, [altFormClosedDialog, setAltFormClosedDialog]);

  const defaultButtonLabel = useMemo(() => {
    const dateRangeLabel = formatDateRangeLabel(
      startDate,
      endDate,
      dateRangeLabelFormatString ?? 'MMM dd'
    );

    return `${initToggleButtonGroupSelectedItem.label} | ${
      isRanged ? dateRangeLabel : formatDate(startDate)
    }`;
  }, [
    dateRangeLabelFormatString,
    endDate,
    formatDate,
    initToggleButtonGroupSelectedItem.label,
    isRanged,
    startDate,
  ]);

  return (
    <>
      <StyledButton
        className={className}
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        <IconLabel
          icon={'calendar-days'}
          iconSize="lg"
          iconColorKey={'white'}
          label={buttonLabel ?? defaultButtonLabel}
          variant={'subtitle1'}
          colorKey={'white'}
          gap="0.25rem"
        />
      </StyledButton>
      <MobileCombinedDatePickerDialog
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={(
          selectedToggleItem: string,
          newStartDate: string,
          newEndDate: string | null
        ) => {
          onSubmit(selectedToggleItem, newStartDate, newEndDate);
        }}
        startDate={startDate.toISOString()}
        endDate={endDate.toISOString()}
        initToggleButtonGroupSelectedItem={initToggleButtonGroupSelectedItem}
        toggleButtonGroupItems={toggleButtonGroupItems}
        singleDateToggleItems={singleDateToggleItems}
        alternateForm={alternateForm}
        alternateFormToggleItem={alternateFormToggleItem}
      />
    </>
  );
}
