import { Box, Paper, styled } from '@mui/material';
import { ClusterLiveStatusTestResultEnum, ColorKey } from '@beeriot/api-client';
import { TestResultLabel } from '../../../base/TestResultLabel';
import { AppTypography } from '../../../base/AppTypography';
import { ThresholdValue } from '../../../utils/threshold-color/thresholdValue';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  padding: '0.75rem',
  borderRadius: '8px',
}));

const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export interface ClusterTestLabelProps {
  name: string;
  getThresholdColor: (
    field: ThresholdValue,
    value: string | number | boolean | null | undefined
  ) => ColorKey;
  result?: ClusterLiveStatusTestResultEnum | '-';
}

export const ClusterTestLabel = ({
  name,
  getThresholdColor,
  result,
}: ClusterTestLabelProps) => {
  return (
    <StyledPaper elevation={1}>
      <StyledBox>
        <AppTypography variant="body1">{name}</AppTypography>
        <TestResultLabel
          getThresholdColor={getThresholdColor}
          result={result}
        />
      </StyledBox>
    </StyledPaper>
  );
};
