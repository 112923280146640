import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Stack, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { format } from 'date-fns';
import { GQLCompany, GQLCompanySite, Area } from '@beeriot/api-client';
import {
  FormAutocompleteTextField,
  FormFooterButtons,
  SelectOption,
} from '../../form';
import {
  SectionControl,
  SectionFormSelect,
  SectionLabel,
  SectionTextInput,
  TitleHeader,
} from '../settings-form';
import { transientOptions, useResponsiveSizes } from '../../utils';
import { FormDateTextField } from '../../form/FormDateTextField';

const squared = '\u00B2';

const FormButtonContainer = styled(
  Stack,
  transientOptions
)<{ $isMobile: boolean }>(({ $isMobile }) => ({
  margin: '1rem',
  flexDirection: 'row',
  justifyContent: $isMobile ? 'center' : 'end',
  flex: 1,
  flexWrap: 'wrap',
}));

const StyledFormButtons = styled(FormFooterButtons)({ margin: '0.5rem' });

export interface ProvisioningSetupFormFields {
  companyName?: string | { label: string };
  companyAddress1?: string;
  companyAddress2?: string;
  companyCity?: string;
  companyState?: string;
  companyZipcode?: string;
  companyCountry?: string;
  siteName?: string | { label: string };
  siteAddress1?: string;
  siteAddress2?: string;
  siteCity?: string;
  siteState?: string;
  siteZipcode?: string;
  siteCountry?: string;
  areaName?: string | { label: string };
  machineType: SelectOption;
  machineName: string;
  machineSerialNumber: string;
  deploymentDate: string;
  blocksQuantity?: string;
  blockClustersQuantity?: string;
  squareMetersPerModule?: string;
  modulePartNumber?: string;
  numberOfModules?: string;
  moduleMembraneArea?: string;
  installationDate?: string;
  columnsQuantity?: string;
  columnsCassettes?: string;
  columnsPosition?: string;
  columnsClustersQuantity?: string;
}

export interface ProvisioningSetupFormProps {
  companies: GQLCompany[];
  sites: GQLCompanySite[];
  areas: Area[];
  isLoading: boolean;
  onCancel: () => void;
  setSelectedCompany: (value: string | null) => void;
  setSelectedSite: (value: string | null) => void;
}

export function ProvisioningSetupForm({
  companies,
  sites,
  areas,
  isLoading,
  onCancel,
  setSelectedCompany,
  setSelectedSite,
}: ProvisioningSetupFormProps) {
  const [newCompanyCreated, setNewCompanyCreated] = useState<boolean>(false);
  const [newSiteCreated, setNewSiteCreated] = useState<boolean>(false);

  const intl = useIntl();
  const { isMobileSize } = useResponsiveSizes();

  const { watch, resetField } = useFormContext();

  const companyName = watch('companyName');
  const siteName = watch('siteName');
  const machineType = watch('machineType');

  useEffect(() => {
    resetField('areaName');
  }, [resetField, siteName]);

  useEffect(() => {
    setSelectedCompany(companyName);
    setSelectedSite(siteName);
  }, [companyName, siteName, setSelectedCompany, setSelectedSite]);

  const machineTypes = [
    { value: 'profi', label: 'PROFi' },
    { value: 'cbs', label: 'CBS' },
    { value: 'cfs', label: 'CFS' },
    { value: 'keraflux', label: 'Keraflux' },
  ];

  const companyNamePlaceholder = intl.formatMessage({
    id: 'settings-provisioning-setup-dialog-company-placeholder',
    defaultMessage: 'Enter Company Name',
    description: 'label for company name placeholder',
  });

  const siteNamePlaceholder = intl.formatMessage({
    id: 'settings-provisioning-setup-dialog-site-placeholder',
    defaultMessage: 'Enter Site Name',
    description: 'label for site name placeholder',
  });

  const addressOnePlaceholder = intl.formatMessage({
    id: 'settings-provisioning-setup-dialog-address-one-placeholder',
    defaultMessage: 'Enter Address 1',
    description: 'label for address one placeholder',
  });

  const addressTwoPlaceholder = intl.formatMessage({
    id: 'settings-provisioning-setup-dialog-address-two-placeholder',
    defaultMessage: 'Enter Address 2',
    description: 'label for address two placeholder',
  });

  const cityPlaceholder = intl.formatMessage({
    id: 'settings-provisioning-setup-dialog-city-placeholder',
    defaultMessage: 'Enter City',
    description: 'label for city placeholder',
  });

  const statePlaceholder = intl.formatMessage({
    id: 'settings-provisioning-setup-dialog-address-state-placeholder',
    defaultMessage: 'Enter State',
    description: 'label for state placeholder',
  });

  const zipcodePlaceholder = intl.formatMessage({
    id: 'settings-provisioning-setup-dialog-zipcode-placeholder',
    defaultMessage: 'Enter Zipcode',
    description: 'label for zipcode placeholder',
  });

  const countryPlaceholder = intl.formatMessage({
    id: 'settings-provisioning-setup-dialog-country-placeholder',
    defaultMessage: 'Enter Country',
    description: 'label for country placeholder',
  });

  const areaNamePlaceholder = intl.formatMessage({
    id: 'settings-provisioning-setup-dialog-area-placeholder',
    defaultMessage: 'Select Area',
    description: 'label for area name placeholder',
  });

  const machineNamePlaceholder = intl.formatMessage({
    id: 'settings-provisioning-setup-dialog-machine-name-placeholder',
    defaultMessage: 'Enter Machine Name',
    description: 'label for machine name placeholder',
  });

  const machineSerialNumberPlaceholder = intl.formatMessage({
    id: 'settings-provisioning-setup-dialog-machine-serial-number-placeholder',
    defaultMessage: 'Enter Machine Serial Number',
    description: 'label for machine serial number placeholder',
  });

  const machineTypePlaceholder = intl.formatMessage({
    id: 'settings-provisioning-setup-dialog-machine-type-placeholder',
    defaultMessage: 'Select Machine Type',
    description: 'label for machine type placeholder',
  });

  const blocksPlaceholder = intl.formatMessage({
    id: 'settings-provisioning-setup-dialog-blocks-quantity-placeholder',
    defaultMessage: 'Enter Number of Blocks',
    description: 'label for blocks quantity',
  });

  const clustersPlaceholder = intl.formatMessage({
    id: 'settings-provisioning-setup-dialog-clusters-quantity-placeholder',
    defaultMessage: 'Enter Number of Clusters',
    description: 'label for block clusters quantity placeholder',
  });
  const modulesPerClusterPlaceholder = intl.formatMessage({
    id: 'settings-provisioning-setup-dialog-modules-per-cluster-placeholder',
    defaultMessage: 'Enter Modules Per Cluster',
    description: 'label for modules per cluster placeholder',
  });
  const squareMetersPerModulePlaceholder = intl.formatMessage({
    id: 'settings-provisioning-setup-dialog-square-meters-per-module-placeholder',
    defaultMessage: 'Enter Square Meters Per Module',
    description: 'label for square meters per module placeholder',
  });
  const modulePartNumberPlaceholder = intl.formatMessage({
    id: 'settings-provisioning-setup-dialog-module-part-number-placeholder',
    defaultMessage: 'Enter Module Part Number',
    description: 'label for module part number placeholder',
  });
  const numberOfModulesPlaceholder = intl.formatMessage({
    id: 'settings-provisioning-setup-dialog-number-of-modules-placeholder',
    defaultMessage: 'Enter Number of Modules',
    description: 'label for number of modules placeholder',
  });
  const moduleMembraneAreaPlaceholder = intl.formatMessage({
    id: 'settings-provisioning-setup-dialog-module-membrane-area-placeholder',
    defaultMessage: 'Enter Module Membrane Area',
    description: 'label for module membrane area placeholder',
  });

  const columnsQuantityPlaceholder = intl.formatMessage({
    id: 'settings-provisioning-setup-dialog-columns-quantity-placeholder',
    defaultMessage: 'Enter Number of Columns',
    description: 'label for columns quantity placeholder',
  });

  const columnsCassettesPlaceholder = intl.formatMessage({
    id: 'settings-provisioning-setup-dialog-columns-cassettes-quantity-placeholder',
    defaultMessage: 'Cassettes',
    description: 'label for columns cassettes placeholder',
  });

  const columnsPositionPlaceholder = intl.formatMessage({
    id: 'settings-provisioning-setup-dialog-columns-position-quantity-placeholder',
    defaultMessage: 'Position',
    description: 'label for columns position placeholder',
  });

  const submitButtonLabel = intl.formatMessage({
    id: 'settings-provisioning-setup-add-system-button',
    defaultMessage: 'Add System',
    description: 'Button for adding system',
  });
  return (
    <Stack>
      <SectionControl>
        <SectionLabel>
          <FormattedMessage
            id="common-company-label"
            defaultMessage="Company"
            description="label for company"
          />
        </SectionLabel>
        <FormAutocompleteTextField
          name="companyName"
          options={companies.map((option) => option.name)}
          itemTypeLabel="company"
          isOptionEqualToValue={(option, value) => option === value}
          placeholder={companyNamePlaceholder}
          required
          setNewObjectCreated={setNewCompanyCreated}
        />
      </SectionControl>
      {newCompanyCreated ? (
        <>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-company-address-one-label"
                defaultMessage="Company Address 1"
                description="label for company address one"
              />
            </SectionLabel>
            <SectionTextInput
              name="companyAddress1"
              placeholder={addressOnePlaceholder}
              required
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-company-address-two-label"
                defaultMessage="Company Address 2"
                description="label for company address two"
              />
            </SectionLabel>
            <SectionTextInput
              name="companyAddress2"
              placeholder={addressTwoPlaceholder}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-company-city-label"
                defaultMessage="Company City"
                description="label for company city"
              />
            </SectionLabel>
            <SectionTextInput
              name="companyCity"
              placeholder={cityPlaceholder}
              required
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-company-state-label"
                defaultMessage="Company State"
                description="label for company state"
              />
            </SectionLabel>
            <SectionTextInput
              name="companyState"
              placeholder={statePlaceholder}
              required
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-company-zipcode-label"
                defaultMessage="Company Zipcode"
                description="label for company zipcode"
              />
            </SectionLabel>
            <SectionTextInput
              name="companyZipcode"
              placeholder={zipcodePlaceholder}
              required
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-company-country-label"
                defaultMessage="Company Country"
                description="label for company country"
              />
            </SectionLabel>
            <SectionTextInput
              name="companyCountry"
              placeholder={countryPlaceholder}
              required
            />
          </SectionControl>
        </>
      ) : null}
      <SectionControl>
        <SectionLabel>
          <FormattedMessage
            id="settings-provisioning-setup-dialog-site-label"
            defaultMessage="Site"
            description="label for site"
          />
        </SectionLabel>
        <FormAutocompleteTextField
          name="siteName"
          options={sites.map((option) => option.name)}
          itemTypeLabel="site"
          isOptionEqualToValue={(option, value) => option === value}
          placeholder={siteNamePlaceholder}
          required
          setNewObjectCreated={setNewSiteCreated}
        />
      </SectionControl>
      {newSiteCreated ? (
        <>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-site-address-one-label"
                defaultMessage="Site Address 1"
                description="label for site address one"
              />
            </SectionLabel>
            <SectionTextInput
              name="siteAddress1"
              placeholder={addressOnePlaceholder}
              required
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-site-address-two-label"
                defaultMessage="Site Address 2"
                description="label for site address two"
              />
            </SectionLabel>
            <SectionTextInput
              name="siteAddress2"
              placeholder={addressTwoPlaceholder}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-site-city-label"
                defaultMessage="Site City"
                description="label for site city"
              />
            </SectionLabel>
            <SectionTextInput
              name="siteCity"
              placeholder={cityPlaceholder}
              required
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-site-state-label"
                defaultMessage="Site State"
                description="label for site state"
              />
            </SectionLabel>
            <SectionTextInput
              name="siteState"
              placeholder={statePlaceholder}
              required
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-site-zipcode-label"
                defaultMessage="Site Zipcode"
                description="label for site zipcode"
              />
            </SectionLabel>
            <SectionTextInput
              name="siteZipcode"
              placeholder={zipcodePlaceholder}
              required
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-site-country-label"
                defaultMessage="Site Country"
                description="label for site country"
              />
            </SectionLabel>
            <SectionTextInput
              name="siteCountry"
              placeholder={countryPlaceholder}
              required
            />
          </SectionControl>
        </>
      ) : null}
      <SectionControl>
        <SectionLabel>
          <FormattedMessage
            id="settings-provisioning-setup-dialog-machine-type-label"
            defaultMessage="Machine Type"
            description="label for machine type"
          />
        </SectionLabel>
        <SectionFormSelect
          name="machineType"
          placeholder={machineTypePlaceholder}
          options={machineTypes}
          required
        />
      </SectionControl>
      {machineType !== undefined ? (
        <SectionControl>
          <SectionLabel>
            <FormattedMessage
              id="settings-provisioning-setup-dialog-area-label"
              defaultMessage="Area"
              description="label for area"
            />
          </SectionLabel>
          <FormAutocompleteTextField
            name="areaName"
            options={areas.map((option) => option.name)}
            itemTypeLabel="area"
            isOptionEqualToValue={(option, value) => option === value}
            placeholder={areaNamePlaceholder}
            required
          />
        </SectionControl>
      ) : null}
      <SectionControl>
        <SectionLabel>
          <FormattedMessage
            id="settings-provisioning-setup-dialog-machine-name-label"
            defaultMessage="Machine Name"
            description="label for machine name"
          />
        </SectionLabel>
        <SectionTextInput
          name="machineName"
          placeholder={machineNamePlaceholder}
          required
        />
      </SectionControl>
      <SectionControl>
        <SectionLabel>
          <FormattedMessage
            id="settings-provisioning-setup-dialog-machine-serial-number-label"
            defaultMessage="Serial Number"
            description="label for machine serial number"
          />
        </SectionLabel>
        <SectionTextInput
          name="machineSerialNumber"
          placeholder={machineSerialNumberPlaceholder}
          type="search"
          required
        />
      </SectionControl>
      <SectionControl>
        <SectionLabel>
          <FormattedMessage
            id="settings-provisioning-setup-dialog-deployment-date-label"
            defaultMessage="Date of Deployment"
            description="label for deployment date"
          />
        </SectionLabel>
        <FormDateTextField
          name="deploymentDate"
          defaultValue={format(new Date(), 'yyyy-MM-dd')}
          required
        />
      </SectionControl>
      {machineType &&
      (machineType.value === 'profi' || machineType.value === 'cfs') ? (
        <>
          <TitleHeader>
            <FormattedMessage
              id="settings-provisioning-setup-dialog-blocks-quantity-label"
              defaultMessage="Blocks"
              description="label for blocks"
            />
          </TitleHeader>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-blocks-quantity-label"
                defaultMessage="Blocks"
                description="label for blocks"
              />
            </SectionLabel>
            <SectionTextInput
              required
              type="number"
              name="blocksQuantity"
              inputProps={{ step: '1' }}
              placeholder={blocksPlaceholder}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-clusters-quantity-label"
                defaultMessage="Clusters"
                description="label for clusters"
              />
            </SectionLabel>
            <SectionTextInput
              required
              type="number"
              name="blockClustersQuantity"
              inputProps={{ step: '1' }}
              placeholder={clustersPlaceholder}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-modules-per-cluster-label"
                defaultMessage="Modules Per Cluster"
                description="label for modules per cluster"
              />
            </SectionLabel>
            <SectionTextInput
              required
              type="number"
              name="numberOfModules"
              inputProps={{ step: '1' }}
              placeholder={modulesPerClusterPlaceholder}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-square-meters-per-module-label"
                defaultMessage="Square Meters Per Module"
                description="label for square meters per module"
              />
            </SectionLabel>
            <SectionTextInput
              required
              type="number"
              name="squareMetersPerModule"
              inputProps={{ step: '1' }}
              placeholder={squareMetersPerModulePlaceholder}
            />
          </SectionControl>
        </>
      ) : null}
      {machineType && machineType.value === 'keraflux' ? (
        <>
          <TitleHeader>
            <FormattedMessage
              id="settings-provisioning-setup-dialog-modules-label"
              defaultMessage="Modules"
              description="label for modules header"
            />
          </TitleHeader>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-module-part-number-label"
                defaultMessage="Module Part Number"
                description="label for module part number"
              />
            </SectionLabel>
            <SectionTextInput
              required
              name="modulePartNumber"
              placeholder={modulePartNumberPlaceholder}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-number-of-mobules-label"
                defaultMessage="Number of Modules"
                description="label for number of modules"
              />
            </SectionLabel>
            <SectionTextInput
              required
              type="number"
              name="numberOfModules"
              inputProps={{ step: '1' }}
              placeholder={numberOfModulesPlaceholder}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-module-membrane-area-label"
                defaultMessage="Module Membrane Area (m{squared})"
                description="label for module membrane area"
                values={{ squared: squared }}
              />
            </SectionLabel>
            <SectionTextInput
              required
              type="number"
              name="moduleMembraneArea"
              inputProps={{ step: '1' }}
              placeholder={moduleMembraneAreaPlaceholder}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-installation-date-label"
                defaultMessage="Installation Date"
                description="label for installation date"
              />
            </SectionLabel>
            <FormDateTextField
              name="installationDate"
              defaultValue={format(new Date(), 'yyyy-MM-dd')}
              required
            />
          </SectionControl>
        </>
      ) : null}
      {machineType && machineType.value === 'cbs' ? (
        <>
          <TitleHeader>
            <FormattedMessage
              id="settings-provisioning-setup-dialog-columns-label"
              defaultMessage="Columns"
              description="label for columns header"
            />
          </TitleHeader>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-columns-quantity-label"
                defaultMessage="Columns Quantity"
                description="label for columns quantity"
              />
            </SectionLabel>
            <SectionTextInput
              required
              type="number"
              name="columnsQuantity"
              inputProps={{ step: '1' }}
              placeholder={columnsQuantityPlaceholder}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-columns-cassettes-quantity-label"
                defaultMessage="Cassettes"
                description="label for column cassettes"
              />
            </SectionLabel>
            <SectionTextInput
              required
              type="number"
              name="columnsCassettes"
              inputProps={{ step: '1' }}
              placeholder={columnsCassettesPlaceholder}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-columns-position-quantity-label"
                defaultMessage="Position"
                description="label for column position"
              />
            </SectionLabel>
            <SectionTextInput
              required
              type="number"
              name="columnsPosition"
              inputProps={{ step: '1' }}
              placeholder={columnsPositionPlaceholder}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-provisioning-setup-dialog-clusters-quantity-label"
                defaultMessage="Clusters"
                description="label for clusters"
              />
            </SectionLabel>
            <SectionTextInput
              required
              type="number"
              name="columnsClustersQuantity"
              inputProps={{ step: '1' }}
              placeholder={clustersPlaceholder}
            />
          </SectionControl>
        </>
      ) : null}
      <FormButtonContainer $isMobile={isMobileSize}>
        <StyledFormButtons
          isLoading={isLoading}
          submitLabel={submitButtonLabel}
          onCancel={onCancel}
          requireCancelConfirmation={false}
        />
      </FormButtonContainer>
    </Stack>
  );
}
