import { Grid, styled } from '@mui/material';
import { useResponsiveSizes } from '../../utils/responsive-design/useResponsiveSizes';
import { SuspenseSiteOverviewHeader } from './SuspenseSiteOverviewHeader';

const HeaderGridItem = styled(Grid)({});

const HeaderContainer = styled(Grid)({});

export function SiteKPIHeader() {
  const { isDesktopSize } = useResponsiveSizes();
  // const { siteKpiColumnWidth } = useSiteDetailsColumnWidth({});
  return (
    <HeaderContainer
      container
      item
      spacing={3}
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      {!isDesktopSize && (
        <HeaderGridItem item xs={12} sm={12} md={12} lg={6} xl={6}>
          <SuspenseSiteOverviewHeader />
        </HeaderGridItem>
      )}
    </HeaderContainer>
  );
}
