import { useMemo } from 'react';
import { Input, styled, alpha } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const StyledTextField = styled(Input)(({ theme, error }) => ({
  display: 'block',
  padding: '0.5rem',
  borderRadius: '8px',
  border: 'none',
  color: error ? theme.palette.error.dark : theme.palette.white.main,
  backgroundColor: theme.palette.secondary.main,
}));

const TopTextField = styled(StyledTextField)(({ theme }) => ({
  borderBottomRightRadius: '0',
  borderBottomLeftRadius: '0',
  borderBottom: `2px solid ${alpha(theme.palette.black.main, 0.1)} `,
}));

const BottomTextField = styled(StyledTextField)({
  borderTopRightRadius: '0',
  borderTopLeftRadius: '0',
});

interface LoginTextInputProps {
  name: string;
  placeholder: string;
  type?: string;
  required?: boolean;
  validate?: (value: string) => boolean;
  position?: 'top' | 'bottom';
}

export function LoginTextInput({
  name,
  placeholder,
  type,
  required,
  validate,
  position,
}: LoginTextInputProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const rules = useMemo(() => {
    return { required, validate };
  }, [required, validate]);

  const TextField = useMemo(() => {
    if (position) return position === 'top' ? TopTextField : BottomTextField;
    return StyledTextField;
  }, [position]);

  return (
    <Controller
      name={name}
      rules={rules}
      render={({ field }) => (
        <TextField
          {...field}
          error={!!errors?.[name]}
          type={type}
          placeholder={placeholder}
          disableUnderline
        />
      )}
      control={control}
      defaultValue=""
    />
  );
}
