import { FormGroup, Stack, styled, Divider } from '@mui/material';
import { ColorKey } from '@beeriot/api-client';
import { AppMedallion } from '../base/AppMedallion';
import { AppTypography } from '../base/AppTypography';
import { transientOptions } from '../utils/transientOptions';
import {
  FormLabeledCheckbox,
  FormLabeledCheckboxProps,
} from './FormLabeledCheckbox';
import { useFormContext } from 'react-hook-form';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

const colorKeys: ColorKey[] = [
  'highlight3',
  'highlight2',
  'highlight4',
  'highlight5',
  'highlight7',
  'highlight1',
];

const GroupMedallion = styled(AppMedallion)({
  height: '1rem',
  width: '1rem',
});

const GroupTitleContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.componentBase.main,
  padding: '1rem',
  textTransform: 'uppercase',
  alignItems: 'center',
  gap: '0.5rem',
}));

const LabeledCheckboxContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  flexDirection: 'column',
  backgroundColor: theme.palette.white.main,
  '& hr:first-of-type': {
    padding: '0px',
  },
}));

const StyledDivider = styled(Divider)({
  width: '100%',
});

const CheckboxStack = styled(
  Stack,
  transientOptions
)<{ $disabled?: boolean }>(({ $disabled, theme }) => ({
  padding: '0.5rem 1rem',
  backgroundColor: $disabled ? theme.palette.componentBase.main : 'unset',
}));

export interface CheckboxGroup {
  groupName?: string;
  groupCheckboxes: FormLabeledCheckboxProps[];
}

export interface FormCheckboxGroupProps {
  groups: CheckboxGroup[] | null | undefined;
  max?: number;
}

export function FormCheckboxGroup({ groups, max }: FormCheckboxGroupProps) {
  const { getValues } = useFormContext();
  const values = getValues();

  const totalChecked = useMemo(() => {
    const keys = Object.keys(values);
    return keys.reduce((acc, curr) => {
      if (values[curr] === true) acc++;
      return acc;
    }, 0);
  }, [values]);

  const shouldDisable = (id: string) =>
    !!max && values[id] === false && totalChecked >= max;

  return (
    <Stack>
      {groups
        ?.filter((x) => x.groupCheckboxes.length > 0)
        .map((x, i) => {
          const groupColorKey = colorKeys[i % colorKeys.length];
          return (
            <Stack key={x.groupName ?? 'group'}>
              {x.groupName && (
                <GroupTitleContainer direction="row">
                  <GroupMedallion colorKey={groupColorKey} />
                  <AppTypography $colorKey="lightText" $bold variant="body2">
                    {x.groupName}
                  </AppTypography>
                </GroupTitleContainer>
              )}
              <StyledDivider />
              <FormGroup>
                {x.groupCheckboxes.map((x) => {
                  const disabled = x.disabled || shouldDisable(x.id);
                  return (
                    <LabeledCheckboxContainer key={x.id}>
                      <CheckboxStack $disabled={disabled}>
                        <FormLabeledCheckbox {...x} disabled={disabled} />
                      </CheckboxStack>
                      <StyledDivider />
                    </LabeledCheckboxContainer>
                  );
                })}
              </FormGroup>
            </Stack>
          );
        })}
      {max && totalChecked >= max && (
        <CheckboxStack>
          <AppTypography>
            <FormattedMessage
              id="common-max-selections-reached-label"
              defaultMessage="Maximum number of selections reached ({max})"
              description="Label to indicate max number of selections has been reached"
              values={{ max }}
            />
          </AppTypography>
        </CheckboxStack>
      )}
    </Stack>
  );
}
