import { useState } from 'react';
import { Alert, styled } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { AppIcon, AppTypography } from '../../base';
import { CertificateProvisioningForm } from '../../settings';
import { SettingsPage } from './SettingsPage';
import { useProvisioningCertificate } from '@beeriot/api-client';

interface NetworkError {
  toJSON: () => StatusError;
}

interface StatusError {
  status: number;
}

function isNetworkError(error: unknown): error is NetworkError {
  return (
    !!error &&
    typeof error === 'object' &&
    Object.keys(error).includes('toJSON')
  );
}

const WarningAlert = styled(Alert)(({ theme }) => ({
  maxWidth: '40rem',
  marginBottom: '1rem',
  border: `1px solid ${theme.palette.warning.main}`,
}));

export function CertificateProvisioningPage() {
  const intl = useIntl();
  const [error, setError] = useState<string | undefined>();

  const iotDeviceNotFoundError = intl.formatMessage({
    id: 'certificate-provisioning-device-not-found-error-label',
    defaultMessage: 'IoT Device Not Found',
    description: 'Error label for when an iot device is not found',
  });

  const getCertificateMutation = useProvisioningCertificate();
  if (
    !error &&
    getCertificateMutation.isError &&
    isNetworkError(getCertificateMutation.error)
  ) {
    const status = getCertificateMutation.error.toJSON().status;
    if (status === 404) {
      setError(iotDeviceNotFoundError);
    }
  }

  return (
    <SettingsPage>
      <WarningAlert
        severity="warning"
        icon={
          <AppIcon
            icon={'circle-exclamation'}
            $colorKey={'warning'}
            size="1x"
          />
        }
      >
        <AppTypography $bold variant="subtitle2">
          <FormattedMessage
            id="certificate-provisioning-warning"
            description="Warning for certificate provisioning"
            defaultMessage="Certificates are only available for download once, please ensure they are stored in a secure location."
          />
        </AppTypography>
      </WarningAlert>
      <CertificateProvisioningForm
        getCertificateMutation={getCertificateMutation}
        clearError={() => setError(undefined)}
        error={error}
      />
    </SettingsPage>
  );
}
