import { BaseAlertsSidebarPage } from '../BaseAlertsSidebarPage';
import { BlockDetailsControlBox } from './BlockDetailsControlBox';
import { BlockDetailsContent } from './BlockDetailsContent';
import { BlockDetailsSidebarDrawer } from '../../blocks';
import { GlobalFooter, ScrollStack } from '../../base';

export function BlockDetailsPage() {
  return (
    <BaseAlertsSidebarPage header={<BlockDetailsControlBox />}>
      <BlockDetailsSidebarDrawer />
      <ScrollStack>
        <BlockDetailsContent />
        <GlobalFooter />
      </ScrollStack>
    </BaseAlertsSidebarPage>
  );
}
