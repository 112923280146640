import { iotStatusStore } from '@beeriot/api-client';
import { Grid, styled } from '@mui/material';
import { ControlBox } from '../../base';
import {
  Form,
  FormTextInput,
  SelectOption,
  SuspenseSiteDropdown,
  SystemTypeDropdownList,
} from '../../form';
import { useIntl } from 'react-intl';

const StyledForm = styled(Form)({
  display: 'flex',
  gap: '2rem',
  height: '100%',
}) as typeof Form;

const StyledSearch = styled(FormTextInput)({
  width: '20rem',
}) as typeof FormTextInput;

interface SiteSystemTypeSelectionFormFields {
  site: SelectOption[];
  systemTypes: SelectOption[];
  search: string;
}

export const IoTStatusPanelHeader = () => {
  const intl = useIntl();

  const selectSystemPlaceholder = intl.formatMessage({
    id: 'system-search-placeholder',
    description: 'Placeholder for system search input',
    defaultMessage: 'Search by system name, device ID',
  });

  return (
    <ControlBox>
      <StyledForm<SiteSystemTypeSelectionFormFields>
        submitOnChange
        formProps={{
          defaultValues: {
            site: [],
            systemTypes: [],
            search: '',
          },
        }}
        onSubmit={(data) => {
          if (
            data.site !== undefined &&
            data.systemTypes !== undefined &&
            data.search !== undefined
          ) {
            iotStatusStore.update((state) => ({
              ...state,
              selectedSites: data.site.map((x) => x.value),
              selectedSystemTypes: data.systemTypes.map((x) => x.value),
              search: data.search,
            }));
          }
        }}
      >
        <Grid container spacing={2}>
          <Grid item sm={5} md={4} lg={4}>
            <SuspenseSiteDropdown isMulti />
          </Grid>
          <Grid item sm={5} md={4} lg={4}>
            <SystemTypeDropdownList isMulti name="systemTypes" />
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <StyledSearch
              defaultValue={''}
              name="search"
              placeholder={selectSystemPlaceholder}
              icon="magnifying-glass"
            />
          </Grid>
        </Grid>
      </StyledForm>
    </ControlBox>
  );
};
