import { FormattedMessage } from 'react-intl';
import { Stack, styled } from '@mui/material';
import {
  SuspenseBlockMembrane,
  SuspenseBlockOverviewCard,
  SuspenseClusterList,
} from '../../blocks';
import { useResponsiveSizes } from '../../utils/responsive-design/useResponsiveSizes';
import { AppTypography } from '../../base/AppTypography';
import { PermissionKey, PermissionView } from '../../base';

const MainStack = styled(Stack)({
  padding: '2rem',
  gap: '1rem',
});

export function BlockDetailsContent() {
  const { isTabletSize, isMobileSize } = useResponsiveSizes();

  return (
    <MainStack>
      {(isTabletSize || isMobileSize) && <SuspenseBlockOverviewCard />}
      <PermissionView permissionKey={PermissionKey.SitesPROFiClusters}>
        <AppTypography $bold variant="h6">
          <FormattedMessage
            id="block-details-clusters"
            description="Label for block clusters"
            defaultMessage="Clusters"
          />
        </AppTypography>
      </PermissionView>
      <PermissionView permissionKey={PermissionKey.SitesPROFiClusters}>
        <SuspenseClusterList />
      </PermissionView>
      <PermissionView permissionKey={PermissionKey.SitesPROFiMembraneInfo}>
        <AppTypography $bold variant="h6">
          <FormattedMessage
            id="block-details-membrane-info"
            description="Label for block membrane info"
            defaultMessage="Membrane Info"
          />
        </AppTypography>
      </PermissionView>
      <PermissionView permissionKey={PermissionKey.SitesPROFiMembraneInfo}>
        <SuspenseBlockMembrane />
      </PermissionView>
    </MainStack>
  );
}
