import {
  intervalToDuration,
  milliseconds,
  millisecondsToHours,
  parseISO,
} from 'date-fns';
import {
  GQLBlock,
  GQLBlockLiveStatus,
  GQLCluster,
  GQLGetBlockQuery,
  useGetBlockQuery,
  useInfiniteListBlocksQuery,
} from '../generated/gql';
import {
  defaultPagingParams,
  removeFalsey,
  getNextPageParamHandler,
} from '../utils';
import { QueryKey, UseQueryOptions } from 'react-query';

export interface BlockCluster {
  block: GQLBlock;
  clusters: GQLCluster[];
}

export function useGQLBlock(blockId?: string, options?: UseQueryOptions<GQLGetBlockQuery, unknown, GQLGetBlockQuery, QueryKey> | undefined): GQLBlock | undefined {
  const { data: block } = useGetBlockQuery(
    {
      blockId: blockId ?? '',
    },
    { refetchInterval: 15000, enabled: !!blockId, ...(options || {}) },
  );
  return block?.getBlock ?? undefined;
}

export function useGQLBlocks(systemId?: string | null): GQLBlock[] {
  const {
    data: blocks,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteListBlocksQuery(
    {
      systemId,
      status: 'active',
      ...defaultPagingParams,
    },
    {
      enabled: !!systemId,
      refetchInterval: 15000,
      getNextPageParam: getNextPageParamHandler(
        (query) => query?.listBlocks?.length
      ),
    }
  );

  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage();
  }

  const gqlBlocks = removeFalsey<GQLBlock>(
    blocks?.pages.flatMap((x) => x.listBlocks)
  );
  return gqlBlocks.sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0));
}

export function getSequenceProgress(status?: GQLBlockLiveStatus | null) {
  if (!status?.sequenceStartTime || !status?.sequenceEstEndTime)
    return undefined;

  const start = parseISO(status.sequenceStartTime).getTime();
  const end = parseISO(status.sequenceEstEndTime).getTime();
  const today = new Date().getTime();
  const total = end - start;
  const current = today - start;
  const remaining = intervalToDuration({
    start: today,
    end: end,
  });
  const remainingHours = millisecondsToHours(milliseconds(remaining));
  const remainingString = `${remainingHours}h ${remaining.minutes}m`;
  const percentage = (current / total) * 100;
  const boundPercentage = Math.floor(Math.min(Math.max(percentage, 0), 100));
  return { percentage: boundPercentage, remaining: remainingString };
}
