import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { MembershipRoleNameEnum } from '@beeriot/api-client';

export function useIntlRole(): (role?: MembershipRoleNameEnum) => string {
  const intl = useIntl();
  const superUser = intl.formatMessage({
    id: 'role-super-user-label',
    defaultMessage: 'Super User',
    description: 'Label for the Super User role',
  });
  const pallAdmin = intl.formatMessage({
    id: 'role-pall-admin-label',
    defaultMessage: 'Pall Admin',
    description: 'Label for the Pall Admin role',
  });
  const customerAdmin = intl.formatMessage({
    id: 'role-customer-admin-label',
    defaultMessage: 'Customer Admin',
    description: 'Label for the Customer Admin role',
  });
  const executiveSupervisor = intl.formatMessage({
    id: 'role-executive-supervisor-label',
    defaultMessage: 'Executive Supervisor',
    description: 'Label for the Executive Supervisor role',
  });
  const supervisor = intl.formatMessage({
    id: 'role-supervisor-label',
    defaultMessage: 'Supervisor',
    description: 'Label for the Supervisor role',
  });
  const operator = intl.formatMessage({
    id: 'role-operator-label',
    defaultMessage: 'Operator',
    description: 'Label for the Operator role',
  });

  return useCallback(
    (role?: MembershipRoleNameEnum) => {
      if (!role) return '-';

      const roleIntlRecord: Record<MembershipRoleNameEnum, string> = {
        [MembershipRoleNameEnum.Admin]: pallAdmin,
        [MembershipRoleNameEnum.SuperUser]: superUser,
        [MembershipRoleNameEnum.CustomerAdmin]: customerAdmin,
        [MembershipRoleNameEnum.ExecutiveSupervisor]: executiveSupervisor,
        [MembershipRoleNameEnum.Supervisor]: supervisor,
        [MembershipRoleNameEnum.Operator]: operator,
      };

      return roleIntlRecord[role];
    },
    [
      customerAdmin,
      executiveSupervisor,
      operator,
      pallAdmin,
      superUser,
      supervisor,
    ]
  );
}
