import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  addDays,
  differenceInSeconds,
  endOfWeek,
  format,
  getMonth,
  isDate,
  isFirstDayOfMonth,
  parseISO,
} from 'date-fns';
import {
  CalendarMode,
  useGQLUserSettings,
  useUserSettings,
} from '@beeriot/api-client';
import { fullDateFormats, monthYearFormats } from './fullDateFormats';

const defaultFullDateFormat = 'MMM d yyyy';
const defaultMonthDateFormat = 'MMM d';

// TODO: needs to change when real data and data type is determined
export function formatTime(totalMinutes?: number | null): string | undefined {
  if (typeof totalMinutes !== 'number') {
    return undefined;
  }
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours}h ${minutes}m`;
}

export function formatTimeFromSeconds(
  totalSeconds?: number | null,
  includeSeconds?: boolean
): string | undefined {
  if (typeof totalSeconds !== 'number') {
    return undefined;
  }
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  let timeString = `${hours}h ${minutes}m`;
  if (includeSeconds) {
    timeString += ` ${seconds}s`;
  }
  return timeString;
}

export function formatTimeToISODate(
  dateStr?: string | null
): string | undefined {
  if (typeof dateStr !== 'string') {
    return undefined;
  }
  const date = parseISO(dateStr);
  if (!isDate(date)) return undefined;
  const now = new Date();
  const secondsToDate = differenceInSeconds(date, now);
  if (secondsToDate < 0) return '0h 0m';
  return formatTimeFromSeconds(secondsToDate);
}

// TODO: needs to change when real data and data type is determined
export function formatUnit(magnitude?: number | null, unit?: string): string {
  // TODO: localization will be determined later
  const numberFormat = magnitude?.toLocaleString('en-us') ?? '-';
  return `${numberFormat} ${unit || '-'}`;
}

function formatDate(date: string | Date, formatString?: string): string {
  const convertedDate = typeof date === 'string' ? new Date(date) : date;
  return format(convertedDate, formatString || defaultFullDateFormat);
}

export function useFormatDateRangeLabel() {
  const formatDateFn = useFormatDate();

  return useCallback(
    (start?: string | Date | null, end?: string | Date | null) => {
      const startStr = formatDateFn(start);
      const endStr = formatDateFn(end);
      return `${startStr} - ${endStr}`;
    },
    [formatDateFn]
  );
}

export function formatDateRangeLabel(
  start: Date | string,
  end: Date | string,
  formatString?: string
): string {
  return (
    formatDate(start, formatString) + ' - ' + formatDate(end, formatString)
  );
}

export type DateFormat = typeof fullDateFormats[number];

export function useFormatDate(formatString?: DateFormat) {
  const intl = useIntl();
  const NA = intl.formatMessage({
    id: 'common-not-applicable',
    defaultMessage: 'N/A',
    description: "Label for when there isn't a value to display",
  });
  // const { data: userSettings } = useUserSettings();
  const { data: gqlUser } = useGQLUserSettings();
  const userSettings = gqlUser?.getUser?.settingsInfo;
  const activeFormat =
    formatString || userSettings?.dateFormat || defaultFullDateFormat;

  return useCallback(
    (date?: string | Date | null) => {
      if (!date) return NA;
      return formatDate(date, activeFormat);
    },
    [activeFormat, NA]
  );
}

export function useFormatDateTime(formatString?: DateFormat) {
  const formatDateFn = useFormatDate(formatString);

  return useCallback(
    (date?: string | Date | null) => {
      if (date == null) return '';
      const formattedDate = formatDateFn(date);
      const convertedDate = typeof date === 'string' ? new Date(date) : date;
      const formattedTime = convertedDate?.toLocaleTimeString?.('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      });

      return `${formattedDate} - ${formattedTime}`;
    },
    [formatDateFn]
  );
}

export type MonthFormat = typeof monthYearFormats[number];

export function useFormatMonth(formatString?: MonthFormat) {
  const intl = useIntl();
  const NA = intl.formatMessage({
    id: 'common-not-applicable',
    defaultMessage: 'N/A',
    description: "Label for when there isn't a value to display",
  });
  // const { data: userSettings } = useUserSettings();
  const { data: gqlUser } = useGQLUserSettings();
  const userSettings = gqlUser?.getUser?.settingsInfo;
  const activeFormat =
    formatString || userSettings?.monthFormat || defaultMonthDateFormat;

  return useCallback(
    (date?: string | Date | null) => {
      if (!date) return NA;
      return formatDate(date, activeFormat);
    },
    [activeFormat, NA]
  );
}

export function useFormatCalendarModeDate(
  calendarMode?: CalendarMode,
  value?: Date | null
) {
  const formatDate = useFormatDate();
  const formattedValue = formatDate(value);

  if (!calendarMode || !value) return '-';
  switch (calendarMode) {
    case CalendarMode.Year:
      return format(value, 'yyyy');
    case CalendarMode.Month:
      return format(value, 'MMMM');
    case CalendarMode.Week:
      if (isFirstDayOfMonth(value) && getMonth(value) === 0) {
        return `${formattedValue} - ${formatDate(addDays(value, 6))}`;
      } else {
        return `${formattedValue} - ${formatDate(endOfWeek(value))}`;
      }
    default:
      return formattedValue;
  }
}
