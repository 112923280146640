import { useIntl } from 'react-intl';
import { DrawerType } from '../../../../enums';
import { AppDrawer } from '../../../../base';
import { SuspenseExecSummaryLineSidebarMenu } from './SuspenseExecSummaryLineSidebarMenu';

export function ExecSummaryLineSidebarDrawer() {
  const intl = useIntl();
  const headerLabel = intl.formatMessage({
    id: 'common-line-status-label',
    defaultMessage: 'Line Status',
    description: 'Line status label',
  });

  return (
    <AppDrawer
      type={DrawerType.ExecSummaryLineDetails}
      headerLabel={headerLabel}
      drawerContent={<SuspenseExecSummaryLineSidebarMenu />}
      $width="25rem"
    />
  );
}
