import { Button, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ColorKey } from '@beeriot/api-client';
import { AppTypography } from '../base/AppTypography';

const ShowPasswordButton = styled(Button)({
  fontWeight: 'inherit',
});

interface ShowHidePasswordButtonProps {
  $colorKey?: ColorKey;
  showPassword: boolean;
  setShowPassword: (show: boolean) => void;
}

export const ShowHidePasswordButton = ({
  $colorKey,
  showPassword,
  setShowPassword,
}: ShowHidePasswordButtonProps) => {
  const colorKeyValue = $colorKey ?? 'white';
  return (
    <ShowPasswordButton onClick={() => setShowPassword(!showPassword)}>
      <AppTypography $colorKey={colorKeyValue} variant="subtitle2">
        {showPassword ? (
          <FormattedMessage
            id="reset-password-hide-password-button"
            defaultMessage="Hide password"
            description="Hide password button"
          />
        ) : (
          <FormattedMessage
            id="reset-password-show-password-button"
            defaultMessage="Show password"
            description="Show password button"
          />
        )}
      </AppTypography>
    </ShowPasswordButton>
  );
};
