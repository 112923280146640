/* tslint:disable */
/* eslint-disable */
/**
 * Pall IoT Beer
 * IoT Beer Unified Api using Micro-services pattern <br/> <br/>  [Data Model](https://pall-corp.atlassian.net/wiki/spaces/IBD/pages/2059403265/Domain+Data+Model/) <br><br> [Application Design](https://pall-corp.atlassian.net/wiki/spaces/IBD/pages/2066612225/Application+Backend/) <br><br> [Pall Corporation Github](https://github.com/orgs/pallcorporation/dashboard/) <br><br> [Open API Specification - DEV - JSON](https://d382zc847wamcs.cloudfront.net/open-api.json) <br><br> [Open API Specification - DEV - YAML](https://d382zc847wamcs.cloudfront.net/open-api.yaml) <br><br> [Open API Specification - Integration - JSON](https://d1xykk5obgtp95.cloudfront.net/open-api.json) <br><br> [Open API Specification - Integration - YAML](https://d1xykk5obgtp95.cloudfront.net/open-api.yaml) <br><br> [Generate API Token - Link](https://github.com/mitchboulay/cognito-authentication/blob/main/README.md) <br><br> [Development Frontend - Link](https://dev.beeriot.pall.net/) <br><br> [Integration Frontend - Link](https://integration.beeriot.pall.net/)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: vladimir_khazin@pall.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'address1': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'address2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'zipcode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country': string;
}
/**
 * 
 * @export
 * @interface AlertCount
 */
export interface AlertCount {
    /**
     * 
     * @type {number}
     * @memberof AlertCount
     */
    'alertsCount'?: number;
}
/**
 * 
 * @export
 * @interface AlertDismissal
 */
export interface AlertDismissal {
    /**
     * 
     * @type {string}
     * @memberof AlertDismissal
     */
    'thumbprint'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDismissal
     */
    'userSub'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDismissal
     */
    'userFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDismissal
     */
    'userLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDismissal
     */
    'userEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDismissal
     */
    'createdAt'?: string;
}
/**
 * 
 * @export
 * @interface AlertMappingPutData
 */
export interface AlertMappingPutData {
    /**
     * 
     * @type {string}
     * @memberof AlertMappingPutData
     */
    'url'?: string;
    /**
     * 
     * @type {DocumentPutDataFields}
     * @memberof AlertMappingPutData
     */
    'fields'?: DocumentPutDataFields;
}
/**
 * 
 * @export
 * @interface AlertMappingUrl
 */
export interface AlertMappingUrl {
    /**
     * 
     * @type {string}
     * @memberof AlertMappingUrl
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface Analytic
 */
export interface Analytic {
    /**
     * A reference to company
     * @type {string}
     * @memberof Analytic
     */
    'companySiteId'?: string;
    /**
     * A list of beer brands, separated by comma
     * @type {string}
     * @memberof Analytic
     */
    'beerBrands'?: string;
    /**
     * 
     * @type {string}
     * @memberof Analytic
     */
    'aggregation'?: AnalyticAggregationEnum;
    /**
     * 
     * @type {Array<AnalyticAllOfData>}
     * @memberof Analytic
     */
    'data'?: Array<AnalyticAllOfData>;
}

export const AnalyticAggregationEnum = {
    Hourly: 'hourly',
    Daily: 'daily',
    Weekly: 'weekly',
    Monthly: 'monthly'
} as const;

export type AnalyticAggregationEnum = typeof AnalyticAggregationEnum[keyof typeof AnalyticAggregationEnum];

/**
 * 
 * @export
 * @interface AnalyticAllOf
 */
export interface AnalyticAllOf {
    /**
     * A reference to company
     * @type {string}
     * @memberof AnalyticAllOf
     */
    'companySiteId'?: string;
    /**
     * A list of beer brands, separated by comma
     * @type {string}
     * @memberof AnalyticAllOf
     */
    'beerBrands'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticAllOf
     */
    'aggregation'?: AnalyticAllOfAggregationEnum;
    /**
     * 
     * @type {Array<AnalyticAllOfData>}
     * @memberof AnalyticAllOf
     */
    'data'?: Array<AnalyticAllOfData>;
}

export const AnalyticAllOfAggregationEnum = {
    Hourly: 'hourly',
    Daily: 'daily',
    Weekly: 'weekly',
    Monthly: 'monthly'
} as const;

export type AnalyticAllOfAggregationEnum = typeof AnalyticAllOfAggregationEnum[keyof typeof AnalyticAllOfAggregationEnum];

/**
 * 
 * @export
 * @interface AnalyticAllOfData
 */
export interface AnalyticAllOfData {
    /**
     * 
     * @type {string}
     * @memberof AnalyticAllOfData
     */
    'systemId': string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticAllOfData
     */
    'beerBrand'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticAllOfData
     */
    'key'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'degreeOfEfficiency'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'volumeFiltered'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'totalStabilizedBeer'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'waterConsumption'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'electricalEnergy'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'peroxideConsumption'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'membraneCleanerKg'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'netCapacity'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'electricalCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'waterCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'dawaterCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'causticCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'peroxideCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'acidicCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'membraneCleanerCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'coolingEnergyCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'enzymaticCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'totalCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'avgCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'clusterTestFailurePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'hazeCentrifugeOut'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'hazeFilterOut'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'coolingEnergy'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'enzymaticConsumption'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'dawaterConsumption'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'causticConsumption'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'acidicChemical'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'oxygen'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticAllOfData
     */
    'processedVolume'?: number;
}
/**
 * 
 * @export
 * @interface AnalyticExport
 */
export interface AnalyticExport {
    /**
     * 
     * @type {string}
     * @memberof AnalyticExport
     */
    'siteId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticExport
     */
    'systemIds'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticExport
     */
    'beerBrands'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticExport
     */
    'kpiList'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticExport
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticExport
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticExport
     */
    'presignedUrl'?: string;
}
/**
 * 
 * @export
 * @interface Area
 */
export interface Area {
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'companySiteId': string;
    /**
     * 
     * @type {number}
     * @memberof Area
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'status'?: AreaStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'id'?: string;
}

export const AreaStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type AreaStatusEnum = typeof AreaStatusEnum[keyof typeof AreaStatusEnum];

/**
 * 
 * @export
 * @interface AreaAllOf
 */
export interface AreaAllOf {
    /**
     * 
     * @type {string}
     * @memberof AreaAllOf
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface AreaBase
 */
export interface AreaBase {
    /**
     * 
     * @type {string}
     * @memberof AreaBase
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AreaBase
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof AreaBase
     */
    'companySiteId': string;
    /**
     * 
     * @type {number}
     * @memberof AreaBase
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof AreaBase
     */
    'status'?: AreaBaseStatusEnum;
}

export const AreaBaseStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type AreaBaseStatusEnum = typeof AreaBaseStatusEnum[keyof typeof AreaBaseStatusEnum];

/**
 * 
 * @export
 * @interface BlockInfo
 */
export interface BlockInfo {
    /**
     * 
     * @type {string}
     * @memberof BlockInfo
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof BlockInfo
     */
    'companySiteId': string;
    /**
     * 
     * @type {string}
     * @memberof BlockInfo
     */
    'areaId': string;
    /**
     * 
     * @type {string}
     * @memberof BlockInfo
     */
    'systemId': string;
    /**
     * 
     * @type {string}
     * @memberof BlockInfo
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof BlockInfo
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof BlockInfo
     */
    'status': BlockInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BlockInfo
     */
    'telemetryNumber'?: BlockInfoTelemetryNumberEnum;
    /**
     * 
     * @type {BlockInfoBaseProvisioning}
     * @memberof BlockInfo
     */
    'provisioning'?: BlockInfoBaseProvisioning;
    /**
     * 
     * @type {string}
     * @memberof BlockInfo
     */
    'id': string;
}

export const BlockInfoStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type BlockInfoStatusEnum = typeof BlockInfoStatusEnum[keyof typeof BlockInfoStatusEnum];
export const BlockInfoTelemetryNumberEnum = {
    _01: '01',
    _02: '02',
    _03: '03',
    _04: '04'
} as const;

export type BlockInfoTelemetryNumberEnum = typeof BlockInfoTelemetryNumberEnum[keyof typeof BlockInfoTelemetryNumberEnum];

/**
 * 
 * @export
 * @interface BlockInfoBase
 */
export interface BlockInfoBase {
    /**
     * 
     * @type {string}
     * @memberof BlockInfoBase
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof BlockInfoBase
     */
    'companySiteId': string;
    /**
     * 
     * @type {string}
     * @memberof BlockInfoBase
     */
    'areaId': string;
    /**
     * 
     * @type {string}
     * @memberof BlockInfoBase
     */
    'systemId': string;
    /**
     * 
     * @type {string}
     * @memberof BlockInfoBase
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof BlockInfoBase
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof BlockInfoBase
     */
    'status': BlockInfoBaseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BlockInfoBase
     */
    'telemetryNumber'?: BlockInfoBaseTelemetryNumberEnum;
    /**
     * 
     * @type {BlockInfoBaseProvisioning}
     * @memberof BlockInfoBase
     */
    'provisioning'?: BlockInfoBaseProvisioning;
}

export const BlockInfoBaseStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type BlockInfoBaseStatusEnum = typeof BlockInfoBaseStatusEnum[keyof typeof BlockInfoBaseStatusEnum];
export const BlockInfoBaseTelemetryNumberEnum = {
    _01: '01',
    _02: '02',
    _03: '03',
    _04: '04'
} as const;

export type BlockInfoBaseTelemetryNumberEnum = typeof BlockInfoBaseTelemetryNumberEnum[keyof typeof BlockInfoBaseTelemetryNumberEnum];

/**
 * 
 * @export
 * @interface BlockInfoBaseProvisioning
 */
export interface BlockInfoBaseProvisioning {
    /**
     * 
     * @type {BlockInfoBaseProvisioningClusters}
     * @memberof BlockInfoBaseProvisioning
     */
    'clusters': BlockInfoBaseProvisioningClusters;
}
/**
 * 
 * @export
 * @interface BlockInfoBaseProvisioningClusters
 */
export interface BlockInfoBaseProvisioningClusters {
    /**
     * 
     * @type {number}
     * @memberof BlockInfoBaseProvisioningClusters
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface BlockLiveKPI
 */
export interface BlockLiveKPI {
    /**
     * 
     * @type {number}
     * @memberof BlockLiveKPI
     */
    'flowHlH': number;
    /**
     * 
     * @type {string}
     * @memberof BlockLiveKPI
     */
    'tmpBar': string;
    /**
     * 
     * @type {number}
     * @memberof BlockLiveKPI
     */
    'estCapacityLm2': number;
    /**
     * 
     * @type {string}
     * @memberof BlockLiveKPI
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockLiveKPI
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface BlockLiveKPIBase
 */
export interface BlockLiveKPIBase {
    /**
     * 
     * @type {number}
     * @memberof BlockLiveKPIBase
     */
    'flowHlH': number;
    /**
     * 
     * @type {string}
     * @memberof BlockLiveKPIBase
     */
    'tmpBar': string;
    /**
     * 
     * @type {number}
     * @memberof BlockLiveKPIBase
     */
    'estCapacityLm2': number;
    /**
     * 
     * @type {string}
     * @memberof BlockLiveKPIBase
     */
    'timestamp'?: string;
}
/**
 * 
 * @export
 * @interface BlockLiveStatus
 */
export interface BlockLiveStatus {
    /**
     * 
     * @type {string}
     * @memberof BlockLiveStatus
     */
    'sequenceDescription': string;
    /**
     * 
     * @type {string}
     * @memberof BlockLiveStatus
     */
    'sequenceStatus': string;
    /**
     * 
     * @type {string}
     * @memberof BlockLiveStatus
     */
    'sequenceStartTime': string;
    /**
     * 
     * @type {boolean}
     * @memberof BlockLiveStatus
     */
    'calibrationStatus'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BlockLiveStatus
     */
    'degreeOfEfficiencyMebak'?: number;
    /**
     * 
     * @type {number}
     * @memberof BlockLiveStatus
     */
    'timeToMaxdPSeconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof BlockLiveStatus
     */
    'sequenceEstEndTime': string;
    /**
     * 
     * @type {string}
     * @memberof BlockLiveStatus
     */
    'timestamp'?: string;
    /**
     * 
     * @type {Array<BlockLiveStatusBaseRecentSystemStatus>}
     * @memberof BlockLiveStatus
     */
    'recentSystemStatus'?: Array<BlockLiveStatusBaseRecentSystemStatus>;
    /**
     * 
     * @type {Array<BlockLiveStatusBaseRecentSystemStatus>}
     * @memberof BlockLiveStatus
     */
    'recentRunningStatus'?: Array<BlockLiveStatusBaseRecentSystemStatus>;
    /**
     * 
     * @type {string}
     * @memberof BlockLiveStatus
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface BlockLiveStatusBase
 */
export interface BlockLiveStatusBase {
    /**
     * 
     * @type {string}
     * @memberof BlockLiveStatusBase
     */
    'sequenceDescription': string;
    /**
     * 
     * @type {string}
     * @memberof BlockLiveStatusBase
     */
    'sequenceStatus': string;
    /**
     * 
     * @type {string}
     * @memberof BlockLiveStatusBase
     */
    'sequenceStartTime': string;
    /**
     * 
     * @type {boolean}
     * @memberof BlockLiveStatusBase
     */
    'calibrationStatus'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BlockLiveStatusBase
     */
    'degreeOfEfficiencyMebak'?: number;
    /**
     * 
     * @type {number}
     * @memberof BlockLiveStatusBase
     */
    'timeToMaxdPSeconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof BlockLiveStatusBase
     */
    'sequenceEstEndTime': string;
    /**
     * 
     * @type {string}
     * @memberof BlockLiveStatusBase
     */
    'timestamp'?: string;
    /**
     * 
     * @type {Array<BlockLiveStatusBaseRecentSystemStatus>}
     * @memberof BlockLiveStatusBase
     */
    'recentSystemStatus'?: Array<BlockLiveStatusBaseRecentSystemStatus>;
    /**
     * 
     * @type {Array<BlockLiveStatusBaseRecentSystemStatus>}
     * @memberof BlockLiveStatusBase
     */
    'recentRunningStatus'?: Array<BlockLiveStatusBaseRecentSystemStatus>;
}
/**
 * 
 * @export
 * @interface BlockLiveStatusBaseRecentSystemStatus
 */
export interface BlockLiveStatusBaseRecentSystemStatus {
    /**
     * 
     * @type {string}
     * @memberof BlockLiveStatusBaseRecentSystemStatus
     */
    'status': BlockLiveStatusBaseRecentSystemStatusStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof BlockLiveStatusBaseRecentSystemStatus
     */
    'percent': number;
}

export const BlockLiveStatusBaseRecentSystemStatusStatusEnum = {
    Production: 'production',
    Cleaning: 'cleaning',
    Idle: 'idle'
} as const;

export type BlockLiveStatusBaseRecentSystemStatusStatusEnum = typeof BlockLiveStatusBaseRecentSystemStatusStatusEnum[keyof typeof BlockLiveStatusBaseRecentSystemStatusStatusEnum];

/**
 * 
 * @export
 * @interface Brand
 */
export interface Brand {
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    'companySiteId': string;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    'rawMaterial'?: string;
    /**
     * 
     * @type {number}
     * @memberof Brand
     */
    'plato'?: number;
    /**
     * 
     * @type {number}
     * @memberof Brand
     */
    'abv'?: number;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    'fermented'?: string;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    'beerType'?: string;
    /**
     * 
     * @type {number}
     * @memberof Brand
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    'status'?: BrandStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    'id'?: string;
}

export const BrandStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type BrandStatusEnum = typeof BrandStatusEnum[keyof typeof BrandStatusEnum];

/**
 * 
 * @export
 * @interface BrandBase
 */
export interface BrandBase {
    /**
     * 
     * @type {string}
     * @memberof BrandBase
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BrandBase
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof BrandBase
     */
    'companySiteId': string;
    /**
     * 
     * @type {string}
     * @memberof BrandBase
     */
    'rawMaterial'?: string;
    /**
     * 
     * @type {number}
     * @memberof BrandBase
     */
    'plato'?: number;
    /**
     * 
     * @type {number}
     * @memberof BrandBase
     */
    'abv'?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandBase
     */
    'fermented'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandBase
     */
    'beerType'?: string;
    /**
     * 
     * @type {number}
     * @memberof BrandBase
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandBase
     */
    'status'?: BrandBaseStatusEnum;
}

export const BrandBaseStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type BrandBaseStatusEnum = typeof BrandBaseStatusEnum[keyof typeof BrandBaseStatusEnum];

/**
 * 
 * @export
 * @interface ClusterInfo
 */
export interface ClusterInfo {
    /**
     * 
     * @type {string}
     * @memberof ClusterInfo
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfo
     */
    'companySiteId': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfo
     */
    'areaId': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfo
     */
    'systemId': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfo
     */
    'blockId': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfo
     */
    'columnId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfo
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfo
     */
    'calibrationStatus'?: ClusterInfoCalibrationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfo
     */
    'testResult'?: ClusterInfoTestResultEnum;
    /**
     * 
     * @type {number}
     * @memberof ClusterInfo
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfo
     */
    'status': ClusterInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfo
     */
    'telemetryNumber'?: ClusterInfoTelemetryNumberEnum;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfo
     */
    'blockTelemetryNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfo
     */
    'id': string;
}

export const ClusterInfoCalibrationStatusEnum = {
    Calibrated: 'Calibrated',
    NotCalibrated: 'NotCalibrated'
} as const;

export type ClusterInfoCalibrationStatusEnum = typeof ClusterInfoCalibrationStatusEnum[keyof typeof ClusterInfoCalibrationStatusEnum];
export const ClusterInfoTestResultEnum = {
    Pass: 'Pass',
    Fail: 'Fail'
} as const;

export type ClusterInfoTestResultEnum = typeof ClusterInfoTestResultEnum[keyof typeof ClusterInfoTestResultEnum];
export const ClusterInfoStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type ClusterInfoStatusEnum = typeof ClusterInfoStatusEnum[keyof typeof ClusterInfoStatusEnum];
export const ClusterInfoTelemetryNumberEnum = {
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4'
} as const;

export type ClusterInfoTelemetryNumberEnum = typeof ClusterInfoTelemetryNumberEnum[keyof typeof ClusterInfoTelemetryNumberEnum];

/**
 * 
 * @export
 * @interface ClusterInfoBase
 */
export interface ClusterInfoBase {
    /**
     * 
     * @type {string}
     * @memberof ClusterInfoBase
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfoBase
     */
    'companySiteId': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfoBase
     */
    'areaId': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfoBase
     */
    'systemId': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfoBase
     */
    'blockId': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfoBase
     */
    'columnId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfoBase
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfoBase
     */
    'calibrationStatus'?: ClusterInfoBaseCalibrationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfoBase
     */
    'testResult'?: ClusterInfoBaseTestResultEnum;
    /**
     * 
     * @type {number}
     * @memberof ClusterInfoBase
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfoBase
     */
    'status': ClusterInfoBaseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfoBase
     */
    'telemetryNumber'?: ClusterInfoBaseTelemetryNumberEnum;
    /**
     * 
     * @type {string}
     * @memberof ClusterInfoBase
     */
    'blockTelemetryNumber'?: string;
}

export const ClusterInfoBaseCalibrationStatusEnum = {
    Calibrated: 'Calibrated',
    NotCalibrated: 'NotCalibrated'
} as const;

export type ClusterInfoBaseCalibrationStatusEnum = typeof ClusterInfoBaseCalibrationStatusEnum[keyof typeof ClusterInfoBaseCalibrationStatusEnum];
export const ClusterInfoBaseTestResultEnum = {
    Pass: 'Pass',
    Fail: 'Fail'
} as const;

export type ClusterInfoBaseTestResultEnum = typeof ClusterInfoBaseTestResultEnum[keyof typeof ClusterInfoBaseTestResultEnum];
export const ClusterInfoBaseStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type ClusterInfoBaseStatusEnum = typeof ClusterInfoBaseStatusEnum[keyof typeof ClusterInfoBaseStatusEnum];
export const ClusterInfoBaseTelemetryNumberEnum = {
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4'
} as const;

export type ClusterInfoBaseTelemetryNumberEnum = typeof ClusterInfoBaseTelemetryNumberEnum[keyof typeof ClusterInfoBaseTelemetryNumberEnum];

/**
 * 
 * @export
 * @interface ClusterIntegrityStatus
 */
export interface ClusterIntegrityStatus {
    /**
     * 
     * @type {string}
     * @memberof ClusterIntegrityStatus
     */
    'testResult': ClusterIntegrityStatusTestResultEnum;
    /**
     * 
     * @type {string}
     * @memberof ClusterIntegrityStatus
     */
    'timestamp': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterIntegrityStatus
     */
    'id': string;
}

export const ClusterIntegrityStatusTestResultEnum = {
    Failed: 'Failed',
    Passed: 'Passed'
} as const;

export type ClusterIntegrityStatusTestResultEnum = typeof ClusterIntegrityStatusTestResultEnum[keyof typeof ClusterIntegrityStatusTestResultEnum];

/**
 * 
 * @export
 * @interface ClusterIntegrityStatusAllOf
 */
export interface ClusterIntegrityStatusAllOf {
    /**
     * 
     * @type {string}
     * @memberof ClusterIntegrityStatusAllOf
     */
    'testResult': ClusterIntegrityStatusAllOfTestResultEnum;
    /**
     * 
     * @type {string}
     * @memberof ClusterIntegrityStatusAllOf
     */
    'timestamp': string;
}

export const ClusterIntegrityStatusAllOfTestResultEnum = {
    Failed: 'Failed',
    Passed: 'Passed'
} as const;

export type ClusterIntegrityStatusAllOfTestResultEnum = typeof ClusterIntegrityStatusAllOfTestResultEnum[keyof typeof ClusterIntegrityStatusAllOfTestResultEnum];

/**
 * 
 * @export
 * @interface ClusterLiveStatus
 */
export interface ClusterLiveStatus {
    /**
     * 
     * @type {string}
     * @memberof ClusterLiveStatus
     */
    'currentStatus': ClusterLiveStatusCurrentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ClusterLiveStatus
     */
    'testResult': ClusterLiveStatusTestResultEnum;
    /**
     * 
     * @type {string}
     * @memberof ClusterLiveStatus
     */
    'testTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterLiveStatus
     */
    'id': string;
}

export const ClusterLiveStatusCurrentStatusEnum = {
    Running: 'Running',
    Cleaning: 'Cleaning',
    Idle: 'Idle',
    NotInService: 'Not in Service'
} as const;

export type ClusterLiveStatusCurrentStatusEnum = typeof ClusterLiveStatusCurrentStatusEnum[keyof typeof ClusterLiveStatusCurrentStatusEnum];
export const ClusterLiveStatusTestResultEnum = {
    Failed: 'Failed',
    Passed: 'Passed'
} as const;

export type ClusterLiveStatusTestResultEnum = typeof ClusterLiveStatusTestResultEnum[keyof typeof ClusterLiveStatusTestResultEnum];

/**
 * 
 * @export
 * @interface ClusterLiveStatusBase
 */
export interface ClusterLiveStatusBase {
    /**
     * 
     * @type {string}
     * @memberof ClusterLiveStatusBase
     */
    'currentStatus': ClusterLiveStatusBaseCurrentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ClusterLiveStatusBase
     */
    'testResult': ClusterLiveStatusBaseTestResultEnum;
    /**
     * 
     * @type {string}
     * @memberof ClusterLiveStatusBase
     */
    'testTime'?: string;
}

export const ClusterLiveStatusBaseCurrentStatusEnum = {
    Running: 'Running',
    Cleaning: 'Cleaning',
    Idle: 'Idle',
    NotInService: 'Not in Service'
} as const;

export type ClusterLiveStatusBaseCurrentStatusEnum = typeof ClusterLiveStatusBaseCurrentStatusEnum[keyof typeof ClusterLiveStatusBaseCurrentStatusEnum];
export const ClusterLiveStatusBaseTestResultEnum = {
    Failed: 'Failed',
    Passed: 'Passed'
} as const;

export type ClusterLiveStatusBaseTestResultEnum = typeof ClusterLiveStatusBaseTestResultEnum[keyof typeof ClusterLiveStatusBaseTestResultEnum];

/**
 * 
 * @export
 * @interface Column
 */
export interface Column {
    /**
     * 
     * @type {string}
     * @memberof Column
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof Column
     */
    'companySiteId': string;
    /**
     * 
     * @type {string}
     * @memberof Column
     */
    'areaId': string;
    /**
     * 
     * @type {string}
     * @memberof Column
     */
    'systemId': string;
    /**
     * 
     * @type {string}
     * @memberof Column
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Column
     */
    'cassettes': number;
    /**
     * 
     * @type {BlockInfoBaseProvisioning}
     * @memberof Column
     */
    'provisioning'?: BlockInfoBaseProvisioning;
    /**
     * 
     * @type {number}
     * @memberof Column
     */
    'sortOrder': number;
    /**
     * 
     * @type {string}
     * @memberof Column
     */
    'status': ColumnStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Column
     */
    'id': string;
}

export const ColumnStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type ColumnStatusEnum = typeof ColumnStatusEnum[keyof typeof ColumnStatusEnum];

/**
 * 
 * @export
 * @interface ColumnBase
 */
export interface ColumnBase {
    /**
     * 
     * @type {string}
     * @memberof ColumnBase
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof ColumnBase
     */
    'companySiteId': string;
    /**
     * 
     * @type {string}
     * @memberof ColumnBase
     */
    'areaId': string;
    /**
     * 
     * @type {string}
     * @memberof ColumnBase
     */
    'systemId': string;
    /**
     * 
     * @type {string}
     * @memberof ColumnBase
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ColumnBase
     */
    'cassettes': number;
    /**
     * 
     * @type {BlockInfoBaseProvisioning}
     * @memberof ColumnBase
     */
    'provisioning'?: BlockInfoBaseProvisioning;
    /**
     * 
     * @type {number}
     * @memberof ColumnBase
     */
    'sortOrder': number;
    /**
     * 
     * @type {string}
     * @memberof ColumnBase
     */
    'status': ColumnBaseStatusEnum;
}

export const ColumnBaseStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type ColumnBaseStatusEnum = typeof ColumnBaseStatusEnum[keyof typeof ColumnBaseStatusEnum];

/**
 * 
 * @export
 * @interface ColumnRecentStatus
 */
export interface ColumnRecentStatus {
    /**
     * 
     * @type {string}
     * @memberof ColumnRecentStatus
     */
    'timestamp'?: string;
    /**
     * 
     * @type {Array<ColumnRecentStatusBaseRecentRunningStatus>}
     * @memberof ColumnRecentStatus
     */
    'recentRunningStatus': Array<ColumnRecentStatusBaseRecentRunningStatus>;
    /**
     * 
     * @type {string}
     * @memberof ColumnRecentStatus
     */
    'currentColumnStatus'?: ColumnRecentStatusCurrentColumnStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ColumnRecentStatus
     */
    'id': string;
}

export const ColumnRecentStatusCurrentColumnStatusEnum = {
    Running: 'Running',
    Idle: 'Idle',
    Off: 'Off',
    Regeneration: 'Regeneration'
} as const;

export type ColumnRecentStatusCurrentColumnStatusEnum = typeof ColumnRecentStatusCurrentColumnStatusEnum[keyof typeof ColumnRecentStatusCurrentColumnStatusEnum];

/**
 * 
 * @export
 * @interface ColumnRecentStatusBase
 */
export interface ColumnRecentStatusBase {
    /**
     * 
     * @type {string}
     * @memberof ColumnRecentStatusBase
     */
    'timestamp'?: string;
    /**
     * 
     * @type {Array<ColumnRecentStatusBaseRecentRunningStatus>}
     * @memberof ColumnRecentStatusBase
     */
    'recentRunningStatus': Array<ColumnRecentStatusBaseRecentRunningStatus>;
    /**
     * 
     * @type {string}
     * @memberof ColumnRecentStatusBase
     */
    'currentColumnStatus'?: ColumnRecentStatusBaseCurrentColumnStatusEnum;
}

export const ColumnRecentStatusBaseCurrentColumnStatusEnum = {
    Running: 'Running',
    Idle: 'Idle',
    Off: 'Off',
    Regeneration: 'Regeneration'
} as const;

export type ColumnRecentStatusBaseCurrentColumnStatusEnum = typeof ColumnRecentStatusBaseCurrentColumnStatusEnum[keyof typeof ColumnRecentStatusBaseCurrentColumnStatusEnum];

/**
 * 
 * @export
 * @interface ColumnRecentStatusBaseRecentRunningStatus
 */
export interface ColumnRecentStatusBaseRecentRunningStatus {
    /**
     * 
     * @type {string}
     * @memberof ColumnRecentStatusBaseRecentRunningStatus
     */
    'status': ColumnRecentStatusBaseRecentRunningStatusStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ColumnRecentStatusBaseRecentRunningStatus
     */
    'percent': number;
}

export const ColumnRecentStatusBaseRecentRunningStatusStatusEnum = {
    Running: 'Running',
    Idle: 'Idle',
    Off: 'Off',
    Regeneration: 'Regeneration'
} as const;

export type ColumnRecentStatusBaseRecentRunningStatusStatusEnum = typeof ColumnRecentStatusBaseRecentRunningStatusStatusEnum[keyof typeof ColumnRecentStatusBaseRecentRunningStatusStatusEnum];

/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'name': string;
    /**
     * 
     * @type {Address}
     * @memberof Company
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'status'?: CompanyStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'id': string;
}

export const CompanyStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type CompanyStatusEnum = typeof CompanyStatusEnum[keyof typeof CompanyStatusEnum];

/**
 * 
 * @export
 * @interface CompanyAllOf
 */
export interface CompanyAllOf {
    /**
     * 
     * @type {string}
     * @memberof CompanyAllOf
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CompanyBase
 */
export interface CompanyBase {
    /**
     * 
     * @type {string}
     * @memberof CompanyBase
     */
    'name': string;
    /**
     * 
     * @type {Address}
     * @memberof CompanyBase
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof CompanyBase
     */
    'status'?: CompanyBaseStatusEnum;
}

export const CompanyBaseStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type CompanyBaseStatusEnum = typeof CompanyBaseStatusEnum[keyof typeof CompanyBaseStatusEnum];

/**
 * 
 * @export
 * @interface CompanyLiveStatus
 */
export interface CompanyLiveStatus {
    /**
     * 
     * @type {number}
     * @memberof CompanyLiveStatus
     */
    'sitesCount'?: number;
    /**
     * 
     * @type {Array<CompanyLiveStatusBaseSystems>}
     * @memberof CompanyLiveStatus
     */
    'systems': Array<CompanyLiveStatusBaseSystems>;
    /**
     * 
     * @type {string}
     * @memberof CompanyLiveStatus
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CompanyLiveStatusBase
 */
export interface CompanyLiveStatusBase {
    /**
     * 
     * @type {number}
     * @memberof CompanyLiveStatusBase
     */
    'sitesCount'?: number;
    /**
     * 
     * @type {Array<CompanyLiveStatusBaseSystems>}
     * @memberof CompanyLiveStatusBase
     */
    'systems': Array<CompanyLiveStatusBaseSystems>;
}
/**
 * 
 * @export
 * @interface CompanyLiveStatusBaseSystems
 */
export interface CompanyLiveStatusBaseSystems {
    /**
     * 
     * @type {string}
     * @memberof CompanyLiveStatusBaseSystems
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof CompanyLiveStatusBaseSystems
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface CompanySite
 */
export interface CompanySite {
    /**
     * A reference to company
     * @type {string}
     * @memberof CompanySite
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof CompanySite
     */
    'name': string;
    /**
     * 
     * @type {Address}
     * @memberof CompanySite
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof CompanySite
     */
    'longitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanySite
     */
    'latitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanySite
     */
    'status': CompanySiteStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanySite
     */
    'groups'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CompanySite
     */
    'id': string;
}

export const CompanySiteStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type CompanySiteStatusEnum = typeof CompanySiteStatusEnum[keyof typeof CompanySiteStatusEnum];

/**
 * 
 * @export
 * @interface CompanySiteBase
 */
export interface CompanySiteBase {
    /**
     * A reference to company
     * @type {string}
     * @memberof CompanySiteBase
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof CompanySiteBase
     */
    'name': string;
    /**
     * 
     * @type {Address}
     * @memberof CompanySiteBase
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof CompanySiteBase
     */
    'longitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanySiteBase
     */
    'latitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanySiteBase
     */
    'status': CompanySiteBaseStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanySiteBase
     */
    'groups'?: Array<string>;
}

export const CompanySiteBaseStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type CompanySiteBaseStatusEnum = typeof CompanySiteBaseStatusEnum[keyof typeof CompanySiteBaseStatusEnum];

/**
 * 
 * @export
 * @interface CompanySiteLiveKPI
 */
export interface CompanySiteLiveKPI {
    /**
     * 
     * @type {number}
     * @memberof CompanySiteLiveKPI
     */
    'siteEfficiencyPct': number;
    /**
     * 
     * @type {number}
     * @memberof CompanySiteLiveKPI
     */
    'productionCumIdleStandbyTimeMinutes': number;
    /**
     * 
     * @type {number}
     * @memberof CompanySiteLiveKPI
     */
    'productionCumCleanTimeMinutes': number;
    /**
     * 
     * @type {number}
     * @memberof CompanySiteLiveKPI
     */
    'productionCumProductionTimeMinutes': number;
    /**
     * 
     * @type {string}
     * @memberof CompanySiteLiveKPI
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanySiteLiveKPI
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof CompanySiteLiveKPI
     */
    'efficiencyRank'?: number;
}
/**
 * 
 * @export
 * @interface CompanySiteLiveKPIAllOf
 */
export interface CompanySiteLiveKPIAllOf {
    /**
     * 
     * @type {string}
     * @memberof CompanySiteLiveKPIAllOf
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof CompanySiteLiveKPIAllOf
     */
    'efficiencyRank'?: number;
}
/**
 * 
 * @export
 * @interface CompanySiteLiveKPIBase
 */
export interface CompanySiteLiveKPIBase {
    /**
     * 
     * @type {number}
     * @memberof CompanySiteLiveKPIBase
     */
    'siteEfficiencyPct': number;
    /**
     * 
     * @type {number}
     * @memberof CompanySiteLiveKPIBase
     */
    'productionCumIdleStandbyTimeMinutes': number;
    /**
     * 
     * @type {number}
     * @memberof CompanySiteLiveKPIBase
     */
    'productionCumCleanTimeMinutes': number;
    /**
     * 
     * @type {number}
     * @memberof CompanySiteLiveKPIBase
     */
    'productionCumProductionTimeMinutes': number;
    /**
     * 
     * @type {string}
     * @memberof CompanySiteLiveKPIBase
     */
    'timestamp'?: string;
}
/**
 * 
 * @export
 * @interface CompanySiteLiveStatus
 */
export interface CompanySiteLiveStatus {
    /**
     * 
     * @type {number}
     * @memberof CompanySiteLiveStatus
     */
    'activeAlertCount': number;
    /**
     * 
     * @type {Array<CompanyLiveStatusBaseSystems>}
     * @memberof CompanySiteLiveStatus
     */
    'systems': Array<CompanyLiveStatusBaseSystems>;
    /**
     * 
     * @type {string}
     * @memberof CompanySiteLiveStatus
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CompanySiteLiveStatusBase
 */
export interface CompanySiteLiveStatusBase {
    /**
     * 
     * @type {number}
     * @memberof CompanySiteLiveStatusBase
     */
    'activeAlertCount': number;
    /**
     * 
     * @type {Array<CompanyLiveStatusBaseSystems>}
     * @memberof CompanySiteLiveStatusBase
     */
    'systems': Array<CompanyLiveStatusBaseSystems>;
}
/**
 * 
 * @export
 * @interface ConsumableInfo
 */
export interface ConsumableInfo {
    /**
     * 
     * @type {string}
     * @memberof ConsumableInfo
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof ConsumableInfo
     */
    'companySiteId': string;
    /**
     * 
     * @type {string}
     * @memberof ConsumableInfo
     */
    'areaId': string;
    /**
     * 
     * @type {string}
     * @memberof ConsumableInfo
     */
    'systemId': string;
    /**
     * 
     * @type {string}
     * @memberof ConsumableInfo
     */
    'blockId': string;
    /**
     * 
     * @type {string}
     * @memberof ConsumableInfo
     */
    'clusterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumableInfo
     */
    'productNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ConsumableInfo
     */
    'status'?: ConsumableInfoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ConsumableInfo
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConsumableInfo
     */
    'id': string;
}

export const ConsumableInfoStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type ConsumableInfoStatusEnum = typeof ConsumableInfoStatusEnum[keyof typeof ConsumableInfoStatusEnum];

/**
 * 
 * @export
 * @interface ConsumableInfoBase
 */
export interface ConsumableInfoBase {
    /**
     * 
     * @type {string}
     * @memberof ConsumableInfoBase
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof ConsumableInfoBase
     */
    'companySiteId': string;
    /**
     * 
     * @type {string}
     * @memberof ConsumableInfoBase
     */
    'areaId': string;
    /**
     * 
     * @type {string}
     * @memberof ConsumableInfoBase
     */
    'systemId': string;
    /**
     * 
     * @type {string}
     * @memberof ConsumableInfoBase
     */
    'blockId': string;
    /**
     * 
     * @type {string}
     * @memberof ConsumableInfoBase
     */
    'clusterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumableInfoBase
     */
    'productNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ConsumableInfoBase
     */
    'status'?: ConsumableInfoBaseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ConsumableInfoBase
     */
    'sortOrder'?: number;
}

export const ConsumableInfoBaseStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type ConsumableInfoBaseStatusEnum = typeof ConsumableInfoBaseStatusEnum[keyof typeof ConsumableInfoBaseStatusEnum];

/**
 * 
 * @export
 * @interface ConsumableLiveKPI
 */
export interface ConsumableLiveKPI {
    /**
     * 
     * @type {number}
     * @memberof ConsumableLiveKPI
     */
    'totalFilteredL'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConsumableLiveKPI
     */
    'totalCipCycles': number;
    /**
     * 
     * @type {string}
     * @memberof ConsumableLiveKPI
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ConsumableLiveKPIBase
 */
export interface ConsumableLiveKPIBase {
    /**
     * 
     * @type {number}
     * @memberof ConsumableLiveKPIBase
     */
    'totalFilteredL'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConsumableLiveKPIBase
     */
    'totalCipCycles': number;
}
/**
 * 
 * @export
 * @interface ConsumableLiveStatus
 */
export interface ConsumableLiveStatus {
    /**
     * 
     * @type {string}
     * @memberof ConsumableLiveStatus
     */
    'moduleId': string;
    /**
     * 
     * @type {string}
     * @memberof ConsumableLiveStatus
     */
    'serialNumber': string;
    /**
     * 
     * @type {number}
     * @memberof ConsumableLiveStatus
     */
    'placeNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ConsumableLiveStatus
     */
    'clusterNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ConsumableLiveStatus
     */
    'moduleNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ConsumableLiveStatus
     */
    'blockNumber': number;
    /**
     * 
     * @type {string}
     * @memberof ConsumableLiveStatus
     */
    'installationTime': string;
    /**
     * 
     * @type {number}
     * @memberof ConsumableLiveStatus
     */
    'pinCount': number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ConsumableLiveStatus
     */
    'pins': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ConsumableLiveStatus
     */
    'timestamp': string;
    /**
     * 
     * @type {string}
     * @memberof ConsumableLiveStatus
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ConsumableLiveStatusBase
 */
export interface ConsumableLiveStatusBase {
    /**
     * 
     * @type {string}
     * @memberof ConsumableLiveStatusBase
     */
    'moduleId': string;
    /**
     * 
     * @type {string}
     * @memberof ConsumableLiveStatusBase
     */
    'serialNumber': string;
    /**
     * 
     * @type {number}
     * @memberof ConsumableLiveStatusBase
     */
    'placeNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ConsumableLiveStatusBase
     */
    'clusterNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ConsumableLiveStatusBase
     */
    'moduleNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ConsumableLiveStatusBase
     */
    'blockNumber': number;
    /**
     * 
     * @type {string}
     * @memberof ConsumableLiveStatusBase
     */
    'installationTime': string;
    /**
     * 
     * @type {number}
     * @memberof ConsumableLiveStatusBase
     */
    'pinCount': number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ConsumableLiveStatusBase
     */
    'pins': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ConsumableLiveStatusBase
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * A reference to company
     * @type {string}
     * @memberof Document
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'systemId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'fileFormat': DocumentFileFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'acceptedMimeType'?: DocumentAcceptedMimeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'status'?: DocumentStatusEnum;
}

export const DocumentFileFormatEnum = {
    Jpg: 'jpg',
    Jpeg: 'jpeg',
    Png: 'png',
    Gif: 'gif',
    Bmp: 'bmp',
    Svg: 'svg',
    Webp: 'webp',
    Pdf: 'pdf',
    Doc: 'doc',
    Docx: 'docx',
    Xls: 'xls',
    Xlsx: 'xlsx',
    Ppt: 'ppt',
    Pptx: 'pptx',
    Txt: 'txt',
    Rtf: 'rtf',
    Mp3: 'mp3',
    Wav: 'wav',
    Ogg: 'ogg',
    Mp4: 'mp4',
    Mov: 'mov',
    Webm: 'webm'
} as const;

export type DocumentFileFormatEnum = typeof DocumentFileFormatEnum[keyof typeof DocumentFileFormatEnum];
export const DocumentAcceptedMimeTypeEnum = {
    ImageJpeg: 'image/jpeg',
    ImagePng: 'image/png',
    ImageGif: 'image/gif',
    ImageBmp: 'image/bmp',
    ImageSvgxml: 'image/svg+xml',
    ImageWebp: 'image/webp',
    ApplicationPdf: 'application/pdf',
    ApplicationMsword: 'application/msword',
    ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlDocument: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ApplicationVndMsExcel: 'application/vnd.ms-excel',
    ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ApplicationVndMsPowerpoint: 'application/vnd.ms-powerpoint',
    ApplicationVndOpenxmlformatsOfficedocumentPresentationmlPresentation: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    TextPlain: 'text/plain',
    ApplicationRtf: 'application/rtf',
    AudioMpeg: 'audio/mpeg',
    AudioWav: 'audio/wav',
    AudioOgg: 'audio/ogg',
    VideoMp4: 'video/mp4',
    VideoQuicktime: 'video/quicktime',
    VideoWebm: 'video/webm'
} as const;

export type DocumentAcceptedMimeTypeEnum = typeof DocumentAcceptedMimeTypeEnum[keyof typeof DocumentAcceptedMimeTypeEnum];
export const DocumentStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type DocumentStatusEnum = typeof DocumentStatusEnum[keyof typeof DocumentStatusEnum];

/**
 * 
 * @export
 * @interface DocumentAllOf
 */
export interface DocumentAllOf {
    /**
     * 
     * @type {string}
     * @memberof DocumentAllOf
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentAllOf
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentAllOf
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentAllOf
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentAllOf
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentAllOf
     */
    'status'?: DocumentAllOfStatusEnum;
}

export const DocumentAllOfStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type DocumentAllOfStatusEnum = typeof DocumentAllOfStatusEnum[keyof typeof DocumentAllOfStatusEnum];

/**
 * 
 * @export
 * @interface DocumentBase
 */
export interface DocumentBase {
    /**
     * A reference to company
     * @type {string}
     * @memberof DocumentBase
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentBase
     */
    'systemId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentBase
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentBase
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentBase
     */
    'fileFormat': DocumentBaseFileFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof DocumentBase
     */
    'acceptedMimeType'?: DocumentBaseAcceptedMimeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DocumentBase
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentBase
     */
    'note'?: string;
}

export const DocumentBaseFileFormatEnum = {
    Jpg: 'jpg',
    Jpeg: 'jpeg',
    Png: 'png',
    Gif: 'gif',
    Bmp: 'bmp',
    Svg: 'svg',
    Webp: 'webp',
    Pdf: 'pdf',
    Doc: 'doc',
    Docx: 'docx',
    Xls: 'xls',
    Xlsx: 'xlsx',
    Ppt: 'ppt',
    Pptx: 'pptx',
    Txt: 'txt',
    Rtf: 'rtf',
    Mp3: 'mp3',
    Wav: 'wav',
    Ogg: 'ogg',
    Mp4: 'mp4',
    Mov: 'mov',
    Webm: 'webm'
} as const;

export type DocumentBaseFileFormatEnum = typeof DocumentBaseFileFormatEnum[keyof typeof DocumentBaseFileFormatEnum];
export const DocumentBaseAcceptedMimeTypeEnum = {
    ImageJpeg: 'image/jpeg',
    ImagePng: 'image/png',
    ImageGif: 'image/gif',
    ImageBmp: 'image/bmp',
    ImageSvgxml: 'image/svg+xml',
    ImageWebp: 'image/webp',
    ApplicationPdf: 'application/pdf',
    ApplicationMsword: 'application/msword',
    ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlDocument: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ApplicationVndMsExcel: 'application/vnd.ms-excel',
    ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ApplicationVndMsPowerpoint: 'application/vnd.ms-powerpoint',
    ApplicationVndOpenxmlformatsOfficedocumentPresentationmlPresentation: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    TextPlain: 'text/plain',
    ApplicationRtf: 'application/rtf',
    AudioMpeg: 'audio/mpeg',
    AudioWav: 'audio/wav',
    AudioOgg: 'audio/ogg',
    VideoMp4: 'video/mp4',
    VideoQuicktime: 'video/quicktime',
    VideoWebm: 'video/webm'
} as const;

export type DocumentBaseAcceptedMimeTypeEnum = typeof DocumentBaseAcceptedMimeTypeEnum[keyof typeof DocumentBaseAcceptedMimeTypeEnum];

/**
 * 
 * @export
 * @interface DocumentPutData
 */
export interface DocumentPutData {
    /**
     * 
     * @type {string}
     * @memberof DocumentPutData
     */
    'url'?: string;
    /**
     * 
     * @type {DocumentPutDataFields}
     * @memberof DocumentPutData
     */
    'fields'?: DocumentPutDataFields;
}
/**
 * 
 * @export
 * @interface DocumentPutDataFields
 */
export interface DocumentPutDataFields {
    /**
     * 
     * @type {string}
     * @memberof DocumentPutDataFields
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentPutDataFields
     */
    'AWSAccessKeyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentPutDataFields
     */
    'x-amz-security-token'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentPutDataFields
     */
    'policy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentPutDataFields
     */
    'signature'?: string;
}
/**
 * 
 * @export
 * @interface DocumentUrl
 */
export interface DocumentUrl {
    /**
     * 
     * @type {string}
     * @memberof DocumentUrl
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface ExecutiveSummary
 */
export interface ExecutiveSummary {
    /**
     * 
     * @type {string}
     * @memberof ExecutiveSummary
     */
    'companySiteId': string;
    /**
     * 
     * @type {Array<ExecutiveSummaryData>}
     * @memberof ExecutiveSummary
     */
    'data'?: Array<ExecutiveSummaryData>;
}
/**
 * 
 * @export
 * @interface ExecutiveSummaryData
 */
export interface ExecutiveSummaryData {
    /**
     * 
     * @type {string}
     * @memberof ExecutiveSummaryData
     */
    'systemId': string;
    /**
     * 
     * @type {number}
     * @memberof ExecutiveSummaryData
     */
    'kWHl'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecutiveSummaryData
     */
    'totalCIPs'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecutiveSummaryData
     */
    'membraneCleaner'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecutiveSummaryData
     */
    'electricalEnergy'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecutiveSummaryData
     */
    'totalStabilizedBeer'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecutiveSummaryData
     */
    'waterConsumption'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecutiveSummaryData
     */
    'causticConsumption'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecutiveSummaryData
     */
    'netCapacity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecutiveSummaryData
     */
    'stabilizationCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecutiveSummaryData
     */
    'beerLossByFilterLoss'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecutiveSummaryData
     */
    'degreeOfEfficiency'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecutiveSummaryData
     */
    'dawaterConsumption'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecutiveSummaryData
     */
    'volumeFiltered'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecutiveSummaryData
     */
    'acidicChemical'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecutiveSummaryData
     */
    'peroxideConsumption'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecutiveSummaryData
     */
    'productionDuration'?: number;
}
/**
 * 
 * @export
 * @interface FilterRun
 */
export interface FilterRun {
    /**
     * 
     * @type {string}
     * @memberof FilterRun
     */
    'systemId': string;
    /**
     * 
     * @type {string}
     * @memberof FilterRun
     */
    'filterRunId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterRun
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof FilterRun
     */
    'endTime': string;
    /**
     * 
     * @type {number}
     * @memberof FilterRun
     */
    'volumeFiltered'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterRun
     */
    'totalRecoveredBeer'?: number;
    /**
     * 
     * @type {string}
     * @memberof FilterRun
     */
    'brandName'?: string;
}
/**
 * 
 * @export
 * @interface FilterRunAllOf
 */
export interface FilterRunAllOf {
    /**
     * 
     * @type {string}
     * @memberof FilterRunAllOf
     */
    'filterRunId'?: string;
}
/**
 * 
 * @export
 * @interface FilterRunBase
 */
export interface FilterRunBase {
    /**
     * 
     * @type {string}
     * @memberof FilterRunBase
     */
    'systemId': string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunBase
     */
    'filterRunId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunBase
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunBase
     */
    'endTime': string;
    /**
     * 
     * @type {number}
     * @memberof FilterRunBase
     */
    'volumeFiltered'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterRunBase
     */
    'totalRecoveredBeer'?: number;
    /**
     * 
     * @type {string}
     * @memberof FilterRunBase
     */
    'brandName'?: string;
}
/**
 * 
 * @export
 * @interface FilterRunBatchDetails
 */
export interface FilterRunBatchDetails {
    /**
     * 
     * @type {string}
     * @memberof FilterRunBatchDetails
     */
    'systemId': string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunBatchDetails
     */
    'filterRunId': string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunBatchDetails
     */
    'batchRunId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunBatchDetails
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunBatchDetails
     */
    'endTime': string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunBatchDetails
     */
    'batchStartTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunBatchDetails
     */
    'batchEndTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunBatchDetails
     */
    'blockId'?: string;
    /**
     * 
     * @type {number}
     * @memberof FilterRunBatchDetails
     */
    'endVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterRunBatchDetails
     */
    'startTMP'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterRunBatchDetails
     */
    'endTMP'?: number;
}
/**
 * 
 * @export
 * @interface FilterRunBatchDetailsBase
 */
export interface FilterRunBatchDetailsBase {
    /**
     * 
     * @type {string}
     * @memberof FilterRunBatchDetailsBase
     */
    'systemId': string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunBatchDetailsBase
     */
    'filterRunId': string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunBatchDetailsBase
     */
    'batchRunId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunBatchDetailsBase
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunBatchDetailsBase
     */
    'endTime': string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunBatchDetailsBase
     */
    'batchStartTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunBatchDetailsBase
     */
    'batchEndTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunBatchDetailsBase
     */
    'blockId'?: string;
    /**
     * 
     * @type {number}
     * @memberof FilterRunBatchDetailsBase
     */
    'endVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterRunBatchDetailsBase
     */
    'startTMP'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterRunBatchDetailsBase
     */
    'endTMP'?: number;
}
/**
 * 
 * @export
 * @interface FilterRunDetails
 */
export interface FilterRunDetails {
    /**
     * 
     * @type {string}
     * @memberof FilterRunDetails
     */
    'systemId': string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunDetails
     */
    'filterRunId': string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunDetails
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunDetails
     */
    'endTime': string;
    /**
     * 
     * @type {number}
     * @memberof FilterRunDetails
     */
    'volumeFiltered'?: number;
    /**
     * 
     * @type {string}
     * @memberof FilterRunDetails
     */
    'brandName'?: string;
    /**
     * 
     * @type {number}
     * @memberof FilterRunDetails
     */
    'totalRunTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterRunDetails
     */
    'throughput'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterRunDetails
     */
    'netCapacity'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterRunDetails
     */
    'turbidity'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterRunDetails
     */
    'totalCIPS'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterRunDetails
     */
    'oxygen'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterRunDetails
     */
    'degreeOfEfficiency'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterRunDetails
     */
    'averageFlow'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterRunDetails
     */
    'fluxRate'?: number;
}
/**
 * 
 * @export
 * @interface FilterRunDetailsBase
 */
export interface FilterRunDetailsBase {
    /**
     * 
     * @type {string}
     * @memberof FilterRunDetailsBase
     */
    'systemId': string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunDetailsBase
     */
    'filterRunId': string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunDetailsBase
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof FilterRunDetailsBase
     */
    'endTime': string;
    /**
     * 
     * @type {number}
     * @memberof FilterRunDetailsBase
     */
    'volumeFiltered'?: number;
    /**
     * 
     * @type {string}
     * @memberof FilterRunDetailsBase
     */
    'brandName'?: string;
    /**
     * 
     * @type {number}
     * @memberof FilterRunDetailsBase
     */
    'totalRunTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterRunDetailsBase
     */
    'throughput'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterRunDetailsBase
     */
    'netCapacity'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterRunDetailsBase
     */
    'turbidity'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterRunDetailsBase
     */
    'totalCIPS'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterRunDetailsBase
     */
    'oxygen'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterRunDetailsBase
     */
    'degreeOfEfficiency'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterRunDetailsBase
     */
    'averageFlow'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterRunDetailsBase
     */
    'fluxRate'?: number;
}
/**
 * 
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * A reference to company
     * @type {string}
     * @memberof Group
     */
    'companyId': string;
    /**
     * An optional reference to a parent group
     * @type {string}
     * @memberof Group
     */
    'parentGroupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'status': GroupStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'id': string;
}

export const GroupStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type GroupStatusEnum = typeof GroupStatusEnum[keyof typeof GroupStatusEnum];

/**
 * 
 * @export
 * @interface GroupBase
 */
export interface GroupBase {
    /**
     * A reference to company
     * @type {string}
     * @memberof GroupBase
     */
    'companyId': string;
    /**
     * An optional reference to a parent group
     * @type {string}
     * @memberof GroupBase
     */
    'parentGroupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupBase
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GroupBase
     */
    'status': GroupBaseStatusEnum;
}

export const GroupBaseStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type GroupBaseStatusEnum = typeof GroupBaseStatusEnum[keyof typeof GroupBaseStatusEnum];

/**
 * 
 * @export
 * @interface Id
 */
export interface Id {
    /**
     * 
     * @type {string}
     * @memberof Id
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    'thumbprint'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse200
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200
     */
    'alertTypeCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    'errorOnDeleteFile'?: string;
}
/**
 * 
 * @export
 * @interface IntegrityTestBase
 */
export interface IntegrityTestBase {
    /**
     * 
     * @type {string}
     * @memberof IntegrityTestBase
     */
    'systemId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrityTestBase
     */
    'subsystemId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrityTestBase
     */
    'systemType'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrityTestBase
     */
    'testCompletionTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrityTestBase
     */
    'testId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrityTestBase
     */
    'testResult'?: boolean;
}
/**
 * 
 * @export
 * @interface IoTStatsBase
 */
export interface IoTStatsBase {
    /**
     * 
     * @type {string}
     * @memberof IoTStatsBase
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IoTStatsBase
     */
    'siteId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IoTStatsBase
     */
    'systemId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IoTStatsBase
     */
    'systemType'?: string;
    /**
     * 
     * @type {string}
     * @memberof IoTStatsBase
     */
    'systemName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IoTStatsBase
     */
    'online'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IoTStatsBase
     */
    'offlineTime'?: number;
    /**
     * 
     * @type {string}
     * @memberof IoTStatsBase
     */
    'lastSeen'?: string;
    /**
     * 
     * @type {Array<IoTStatsBaseConnectivityStatus>}
     * @memberof IoTStatsBase
     */
    'connectivityStatus'?: Array<IoTStatsBaseConnectivityStatus>;
}
/**
 * 
 * @export
 * @interface IoTStatsBaseConnectivityStatus
 */
export interface IoTStatsBaseConnectivityStatus {
    /**
     * 
     * @type {string}
     * @memberof IoTStatsBaseConnectivityStatus
     */
    'key': string;
    /**
     * 
     * @type {boolean}
     * @memberof IoTStatsBaseConnectivityStatus
     */
    'online': boolean;
}
/**
 * 
 * @export
 * @interface IotProvisioning
 */
export interface IotProvisioning {
    /**
     * A reference to company
     * @type {string}
     * @memberof IotProvisioning
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof IotProvisioning
     */
    'companySiteId': string;
    /**
     * References to systems
     * @type {string}
     * @memberof IotProvisioning
     */
    'systemIds'?: string;
    /**
     * 
     * @type {string}
     * @memberof IotProvisioning
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IotProvisioning
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof IotProvisioning
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof IotProvisioning
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof IotProvisioning
     */
    'status'?: IotProvisioningStatusEnum;
}

export const IotProvisioningStatusEnum = {
    Provisioning: 'provisioning',
    Provisioned: 'provisioned'
} as const;

export type IotProvisioningStatusEnum = typeof IotProvisioningStatusEnum[keyof typeof IotProvisioningStatusEnum];

/**
 * 
 * @export
 * @interface IotProvisioningAllOf
 */
export interface IotProvisioningAllOf {
    /**
     * 
     * @type {string}
     * @memberof IotProvisioningAllOf
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IotProvisioningAllOf
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof IotProvisioningAllOf
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof IotProvisioningAllOf
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof IotProvisioningAllOf
     */
    'status'?: IotProvisioningAllOfStatusEnum;
}

export const IotProvisioningAllOfStatusEnum = {
    Provisioning: 'provisioning',
    Provisioned: 'provisioned'
} as const;

export type IotProvisioningAllOfStatusEnum = typeof IotProvisioningAllOfStatusEnum[keyof typeof IotProvisioningAllOfStatusEnum];

/**
 * 
 * @export
 * @interface IotProvisioningBase
 */
export interface IotProvisioningBase {
    /**
     * A reference to company
     * @type {string}
     * @memberof IotProvisioningBase
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof IotProvisioningBase
     */
    'companySiteId': string;
    /**
     * References to systems
     * @type {string}
     * @memberof IotProvisioningBase
     */
    'systemIds'?: string;
}
/**
 * 
 * @export
 * @interface IotProvisioningUrl
 */
export interface IotProvisioningUrl {
    /**
     * 
     * @type {string}
     * @memberof IotProvisioningUrl
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof IotProvisioningUrl
     */
    'filename'?: string;
}
/**
 * 
 * @export
 * @interface Membership
 */
export interface Membership {
    /**
     * 
     * @type {string}
     * @memberof Membership
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof Membership
     */
    'status': MembershipStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Membership
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof Membership
     */
    'roleName': MembershipRoleNameEnum;
}

export const MembershipStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type MembershipStatusEnum = typeof MembershipStatusEnum[keyof typeof MembershipStatusEnum];
export const MembershipRoleNameEnum = {
    Admin: 'admin',
    SuperUser: 'super_user',
    ExecutiveSupervisor: 'executive_supervisor',
    Supervisor: 'supervisor',
    Operator: 'operator',
    CustomerAdmin: 'customer_admin'
} as const;

export type MembershipRoleNameEnum = typeof MembershipRoleNameEnum[keyof typeof MembershipRoleNameEnum];

/**
 * 
 * @export
 * @interface MembershipAllOf
 */
export interface MembershipAllOf {
    /**
     * 
     * @type {string}
     * @memberof MembershipAllOf
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface MembershipBase
 */
export interface MembershipBase {
    /**
     * 
     * @type {string}
     * @memberof MembershipBase
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof MembershipBase
     */
    'status': MembershipBaseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MembershipBase
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof MembershipBase
     */
    'roleName': MembershipBaseRoleNameEnum;
}

export const MembershipBaseStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type MembershipBaseStatusEnum = typeof MembershipBaseStatusEnum[keyof typeof MembershipBaseStatusEnum];
export const MembershipBaseRoleNameEnum = {
    Admin: 'admin',
    SuperUser: 'super_user',
    ExecutiveSupervisor: 'executive_supervisor',
    Supervisor: 'supervisor',
    Operator: 'operator',
    CustomerAdmin: 'customer_admin'
} as const;

export type MembershipBaseRoleNameEnum = typeof MembershipBaseRoleNameEnum[keyof typeof MembershipBaseRoleNameEnum];

/**
 * 
 * @export
 * @interface Module
 */
export interface Module {
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'companySiteId': string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'areaId': string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'systemId': string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'blockId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'clusterId'?: string;
    /**
     * 
     * @type {number}
     * @memberof Module
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'status': ModuleStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'id': string;
}

export const ModuleStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type ModuleStatusEnum = typeof ModuleStatusEnum[keyof typeof ModuleStatusEnum];

/**
 * 
 * @export
 * @interface ModuleBase
 */
export interface ModuleBase {
    /**
     * 
     * @type {string}
     * @memberof ModuleBase
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleBase
     */
    'companySiteId': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleBase
     */
    'areaId': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleBase
     */
    'systemId': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleBase
     */
    'blockId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModuleBase
     */
    'clusterId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ModuleBase
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleBase
     */
    'status': ModuleBaseStatusEnum;
}

export const ModuleBaseStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type ModuleBaseStatusEnum = typeof ModuleBaseStatusEnum[keyof typeof ModuleBaseStatusEnum];

/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'generatedOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'type'?: ReportTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'frequency': ReportFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'aggregation'?: ReportAggregationEnum;
    /**
     * 
     * @type {Array<ReportSystems>}
     * @memberof Report
     */
    'systems': Array<ReportSystems>;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'reportS3Key'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'status'?: ReportStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'errorDetails'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'statusLastUpdate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'reportDownloadUrl'?: string;
}

export const ReportTypeEnum = {
    ConsumptionReport: 'ConsumptionReport',
    CostReport: 'CostReport',
    PerformanceReport: 'PerformanceReport',
    QualityReport: 'QualityReport',
    ModuleManagementReport: 'ModuleManagement Report',
    ServiceReport: 'ServiceReport'
} as const;

export type ReportTypeEnum = typeof ReportTypeEnum[keyof typeof ReportTypeEnum];
export const ReportFrequencyEnum = {
    OneTime: 'One-Time',
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Yearly: 'Yearly'
} as const;

export type ReportFrequencyEnum = typeof ReportFrequencyEnum[keyof typeof ReportFrequencyEnum];
export const ReportAggregationEnum = {
    Hourly: 'hourly',
    Daily: 'daily',
    Weekly: 'weekly',
    Monthly: 'monthly'
} as const;

export type ReportAggregationEnum = typeof ReportAggregationEnum[keyof typeof ReportAggregationEnum];
export const ReportStatusEnum = {
    InProgress: 'In-progress',
    Completed: 'Completed',
    Error: 'Error'
} as const;

export type ReportStatusEnum = typeof ReportStatusEnum[keyof typeof ReportStatusEnum];

/**
 * 
 * @export
 * @interface ReportDefinition
 */
export interface ReportDefinition {
    /**
     * 
     * @type {string}
     * @memberof ReportDefinition
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDefinition
     */
    'reportName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDefinition
     */
    'reportType': ReportDefinitionReportTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportDefinition
     */
    'frequency': ReportDefinitionFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportDefinition
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportDefinition
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportDefinition
     */
    'aggregation'?: ReportDefinitionAggregationEnum;
    /**
     * 
     * @type {Array<ReportSystems>}
     * @memberof ReportDefinition
     */
    'systems': Array<ReportSystems>;
    /**
     * 
     * @type {string}
     * @memberof ReportDefinition
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDefinition
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportDefinition
     */
    'createdBy'?: string;
}

export const ReportDefinitionReportTypeEnum = {
    ConsumptionReport: 'ConsumptionReport',
    CostReport: 'CostReport',
    PerformanceReport: 'PerformanceReport',
    QualityReport: 'QualityReport',
    ModuleManagementReport: 'ModuleManagement Report',
    ServiceReport: 'ServiceReport'
} as const;

export type ReportDefinitionReportTypeEnum = typeof ReportDefinitionReportTypeEnum[keyof typeof ReportDefinitionReportTypeEnum];
export const ReportDefinitionFrequencyEnum = {
    OneTime: 'One-Time',
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Yearly: 'Yearly'
} as const;

export type ReportDefinitionFrequencyEnum = typeof ReportDefinitionFrequencyEnum[keyof typeof ReportDefinitionFrequencyEnum];
export const ReportDefinitionAggregationEnum = {
    Hourly: 'hourly',
    Daily: 'daily',
    Weekly: 'weekly',
    Monthly: 'monthly'
} as const;

export type ReportDefinitionAggregationEnum = typeof ReportDefinitionAggregationEnum[keyof typeof ReportDefinitionAggregationEnum];

/**
 * 
 * @export
 * @interface ReportDefinitionBase
 */
export interface ReportDefinitionBase {
    /**
     * 
     * @type {string}
     * @memberof ReportDefinitionBase
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDefinitionBase
     */
    'reportName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDefinitionBase
     */
    'reportType': ReportDefinitionBaseReportTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportDefinitionBase
     */
    'frequency': ReportDefinitionBaseFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportDefinitionBase
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportDefinitionBase
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportDefinitionBase
     */
    'aggregation'?: ReportDefinitionBaseAggregationEnum;
    /**
     * 
     * @type {Array<ReportSystems>}
     * @memberof ReportDefinitionBase
     */
    'systems': Array<ReportSystems>;
}

export const ReportDefinitionBaseReportTypeEnum = {
    ConsumptionReport: 'ConsumptionReport',
    CostReport: 'CostReport',
    PerformanceReport: 'PerformanceReport',
    QualityReport: 'QualityReport',
    ModuleManagementReport: 'ModuleManagement Report',
    ServiceReport: 'ServiceReport'
} as const;

export type ReportDefinitionBaseReportTypeEnum = typeof ReportDefinitionBaseReportTypeEnum[keyof typeof ReportDefinitionBaseReportTypeEnum];
export const ReportDefinitionBaseFrequencyEnum = {
    OneTime: 'One-Time',
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Yearly: 'Yearly'
} as const;

export type ReportDefinitionBaseFrequencyEnum = typeof ReportDefinitionBaseFrequencyEnum[keyof typeof ReportDefinitionBaseFrequencyEnum];
export const ReportDefinitionBaseAggregationEnum = {
    Hourly: 'hourly',
    Daily: 'daily',
    Weekly: 'weekly',
    Monthly: 'monthly'
} as const;

export type ReportDefinitionBaseAggregationEnum = typeof ReportDefinitionBaseAggregationEnum[keyof typeof ReportDefinitionBaseAggregationEnum];

/**
 * 
 * @export
 * @interface ReportSystems
 */
export interface ReportSystems {
    /**
     * 
     * @type {string}
     * @memberof ReportSystems
     */
    'companySiteId': string;
    /**
     * 
     * @type {string}
     * @memberof ReportSystems
     */
    'systemId': string;
}
/**
 * 
 * @export
 * @interface Roles
 */
export interface Roles {
    /**
     * 
     * @type {string}
     * @memberof Roles
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Roles
     */
    'roleName'?: RolesRoleNameEnum;
}

export const RolesRoleNameEnum = {
    Admin: 'admin',
    CustomerAdmin: 'customer_admin',
    ExecutiveSupervisor: 'executive_supervisor',
    Supervisor: 'supervisor',
    Operator: 'operator'
} as const;

export type RolesRoleNameEnum = typeof RolesRoleNameEnum[keyof typeof RolesRoleNameEnum];

/**
 * 
 * @export
 * @interface RolesBase
 */
export interface RolesBase {
    /**
     * 
     * @type {string}
     * @memberof RolesBase
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RolesBase
     */
    'roleName'?: RolesBaseRoleNameEnum;
}

export const RolesBaseRoleNameEnum = {
    Admin: 'admin',
    CustomerAdmin: 'customer_admin',
    ExecutiveSupervisor: 'executive_supervisor',
    Supervisor: 'supervisor',
    Operator: 'operator'
} as const;

export type RolesBaseRoleNameEnum = typeof RolesBaseRoleNameEnum[keyof typeof RolesBaseRoleNameEnum];

/**
 * 
 * @export
 * @interface SettingsPushNotifications
 */
export interface SettingsPushNotifications {
    /**
     * 
     * @type {boolean}
     * @memberof SettingsPushNotifications
     */
    'pushNotificationsEnabled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SettingsPushNotifications
     */
    'pushNotificationSystemIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SettingsPushNotifications
     */
    'pushNotificationChannels': Array<string>;
    /**
     * 
     * @type {Array<SettingsPushNotificationsPushNotificationSubscriptions>}
     * @memberof SettingsPushNotifications
     */
    'pushNotificationSubscriptions': Array<SettingsPushNotificationsPushNotificationSubscriptions>;
}
/**
 * 
 * @export
 * @interface SettingsPushNotificationsKeys
 */
export interface SettingsPushNotificationsKeys {
    /**
     * 
     * @type {string}
     * @memberof SettingsPushNotificationsKeys
     */
    'p256dh'?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsPushNotificationsKeys
     */
    'auth'?: string;
}
/**
 * 
 * @export
 * @interface SettingsPushNotificationsPushNotificationSubscriptions
 */
export interface SettingsPushNotificationsPushNotificationSubscriptions {
    /**
     * 
     * @type {string}
     * @memberof SettingsPushNotificationsPushNotificationSubscriptions
     */
    'endpoint'?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsPushNotificationsPushNotificationSubscriptions
     */
    'expirationTime'?: string;
    /**
     * 
     * @type {SettingsPushNotificationsKeys}
     * @memberof SettingsPushNotificationsPushNotificationSubscriptions
     */
    'keys'?: SettingsPushNotificationsKeys;
}
/**
 * 
 * @export
 * @interface SettingsSites
 */
export interface SettingsSites {
    /**
     * 
     * @type {string}
     * @memberof SettingsSites
     */
    'electricUnits': SettingsSitesElectricUnitsEnum;
    /**
     * 
     * @type {string}
     * @memberof SettingsSites
     */
    'volumeUnits': SettingsSitesVolumeUnitsEnum;
    /**
     * 
     * @type {string}
     * @memberof SettingsSites
     */
    'currency': SettingsSitesCurrencyEnum;
    /**
     * 
     * @type {number}
     * @memberof SettingsSites
     */
    'electricCost': number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSites
     */
    'coldWaterCost': number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSites
     */
    'hotWaterCost': number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSites
     */
    'daWaterCost': number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSites
     */
    'chemCausticCost': number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSites
     */
    'chemHydrogenPeroxideCost': number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSites
     */
    'chemAcidCost': number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSites
     */
    'chemSpecialCleanerCost': number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSites
     */
    'pvppCost': number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSites
     */
    'enzymaticCost': number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSites
     */
    'coolingEnergyCost': number;
}

export const SettingsSitesElectricUnitsEnum = {
    KWh: 'kWh'
} as const;

export type SettingsSitesElectricUnitsEnum = typeof SettingsSitesElectricUnitsEnum[keyof typeof SettingsSitesElectricUnitsEnum];
export const SettingsSitesVolumeUnitsEnum = {
    Gal: 'gal',
    HL: 'hL'
} as const;

export type SettingsSitesVolumeUnitsEnum = typeof SettingsSitesVolumeUnitsEnum[keyof typeof SettingsSitesVolumeUnitsEnum];
export const SettingsSitesCurrencyEnum = {
    Aud: 'aud',
    Cad: 'cad',
    Eur: 'eur',
    Gbp: 'gbp',
    Nzd: 'nzd',
    Usd: 'usd',
    Zar: 'zar',
    Cny: 'cny'
} as const;

export type SettingsSitesCurrencyEnum = typeof SettingsSitesCurrencyEnum[keyof typeof SettingsSitesCurrencyEnum];

/**
 * 
 * @export
 * @interface SettingsSystems
 */
export interface SettingsSystems {
    /**
     * 
     * @type {SettingsSystemsConsumption}
     * @memberof SettingsSystems
     */
    'consumption'?: SettingsSystemsConsumption;
    /**
     * 
     * @type {SettingsSystemsEvery10thCycle}
     * @memberof SettingsSystems
     */
    'every10thCycle'?: SettingsSystemsEvery10thCycle;
    /**
     * 
     * @type {SettingsSystemsThresholdValues}
     * @memberof SettingsSystems
     */
    'thresholdValues'?: SettingsSystemsThresholdValues;
    /**
     * 
     * @type {SettingsSystemsDegreeOfEfficiency}
     * @memberof SettingsSystems
     */
    'degreeOfEfficiency': SettingsSystemsDegreeOfEfficiency;
    /**
     * 
     * @type {SettingsSystemsActualFlow}
     * @memberof SettingsSystems
     */
    'actualFlow': SettingsSystemsActualFlow;
    /**
     * 
     * @type {SettingsSystemsActualFlow}
     * @memberof SettingsSystems
     */
    'actualDp'?: SettingsSystemsActualFlow;
    /**
     * 
     * @type {SettingsSystemsCluster}
     * @memberof SettingsSystems
     */
    'cluster'?: SettingsSystemsCluster;
    /**
     * 
     * @type {SettingsSystemsActualFlow}
     * @memberof SettingsSystems
     */
    'turbidityEbc'?: SettingsSystemsActualFlow;
    /**
     * 
     * @type {SettingsSystemsActualFlow}
     * @memberof SettingsSystems
     */
    'oxygen'?: SettingsSystemsActualFlow;
    /**
     * 
     * @type {SettingsSystemsActualFlow}
     * @memberof SettingsSystems
     */
    'netCapacity'?: SettingsSystemsActualFlow;
    /**
     * 
     * @type {SettingsSystemsActualFlow}
     * @memberof SettingsSystems
     */
    'opex'?: SettingsSystemsActualFlow;
    /**
     * 
     * @type {SettingsSystemsConcentrateStatus}
     * @memberof SettingsSystems
     */
    'concentrateStatus'?: SettingsSystemsConcentrateStatus;
    /**
     * 
     * @type {SettingsSystemsActualFlow}
     * @memberof SettingsSystems
     */
    'waterHl'?: SettingsSystemsActualFlow;
    /**
     * 
     * @type {SettingsSystemsActualFlow}
     * @memberof SettingsSystems
     */
    'daWaterHl'?: SettingsSystemsActualFlow;
    /**
     * 
     * @type {SettingsSystemsCaustic}
     * @memberof SettingsSystems
     */
    'caustic'?: SettingsSystemsCaustic;
    /**
     * 
     * @type {SettingsSystemsCaustic}
     * @memberof SettingsSystems
     */
    'peroxyde'?: SettingsSystemsCaustic;
    /**
     * 
     * @type {SettingsSystemsCaustic}
     * @memberof SettingsSystems
     */
    'membraneCleaner'?: SettingsSystemsCaustic;
    /**
     * 
     * @type {SettingsSystemsActualFlow}
     * @memberof SettingsSystems
     */
    'operationalCluster'?: SettingsSystemsActualFlow;
    /**
     * 
     * @type {}
     * @memberof SettingsSystems
     */
    'serviceSettings': SettingsSystemsServiceSettingsEnum;
}

export const SettingsSystemsServiceSettingsEnum = {
    Profi: 'profi',
    Cbs: 'cbs',
    Cfs: 'cfs',
    Keraflux: 'keraflux'
} as const;

export type SettingsSystemsServiceSettingsEnum = typeof SettingsSystemsServiceSettingsEnum[keyof typeof SettingsSystemsServiceSettingsEnum];

/**
 * 
 * @export
 * @interface SettingsSystemsActualFlow
 */
export interface SettingsSystemsActualFlow {
    /**
     * 
     * @type {number}
     * @memberof SettingsSystemsActualFlow
     */
    'green': number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSystemsActualFlow
     */
    'red': number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSystemsActualFlow
     */
    'yellow': number;
}
/**
 * 
 * @export
 * @interface SettingsSystemsCaustic
 */
export interface SettingsSystemsCaustic {
    /**
     * 
     * @type {boolean}
     * @memberof SettingsSystemsCaustic
     */
    'green': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsSystemsCaustic
     */
    'red': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsSystemsCaustic
     */
    'yellow': boolean;
}
/**
 * 
 * @export
 * @interface SettingsSystemsCluster
 */
export interface SettingsSystemsCluster {
    /**
     * 
     * @type {string}
     * @memberof SettingsSystemsCluster
     */
    'green': SettingsSystemsClusterGreenEnum;
    /**
     * 
     * @type {string}
     * @memberof SettingsSystemsCluster
     */
    'red': SettingsSystemsClusterRedEnum;
}

export const SettingsSystemsClusterGreenEnum = {
    Pass: 'Pass',
    Fail: 'Fail'
} as const;

export type SettingsSystemsClusterGreenEnum = typeof SettingsSystemsClusterGreenEnum[keyof typeof SettingsSystemsClusterGreenEnum];
export const SettingsSystemsClusterRedEnum = {
    Pass: 'Pass',
    Fail: 'Fail'
} as const;

export type SettingsSystemsClusterRedEnum = typeof SettingsSystemsClusterRedEnum[keyof typeof SettingsSystemsClusterRedEnum];

/**
 * 
 * @export
 * @interface SettingsSystemsConcentrateStatus
 */
export interface SettingsSystemsConcentrateStatus {
    /**
     * 
     * @type {string}
     * @memberof SettingsSystemsConcentrateStatus
     */
    'green': SettingsSystemsConcentrateStatusGreenEnum;
    /**
     * 
     * @type {string}
     * @memberof SettingsSystemsConcentrateStatus
     */
    'red': SettingsSystemsConcentrateStatusRedEnum;
}

export const SettingsSystemsConcentrateStatusGreenEnum = {
    Good: 'Good',
    Low: 'Low'
} as const;

export type SettingsSystemsConcentrateStatusGreenEnum = typeof SettingsSystemsConcentrateStatusGreenEnum[keyof typeof SettingsSystemsConcentrateStatusGreenEnum];
export const SettingsSystemsConcentrateStatusRedEnum = {
    Good: 'Good',
    Low: 'Low'
} as const;

export type SettingsSystemsConcentrateStatusRedEnum = typeof SettingsSystemsConcentrateStatusRedEnum[keyof typeof SettingsSystemsConcentrateStatusRedEnum];

/**
 * 
 * @export
 * @interface SettingsSystemsConsumption
 */
export interface SettingsSystemsConsumption {
    /**
     * 
     * @type {number}
     * @memberof SettingsSystemsConsumption
     */
    'waterL': number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSystemsConsumption
     */
    'daWater'?: number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSystemsConsumption
     */
    'dawCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSystemsConsumption
     */
    'platoDegree'?: number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSystemsConsumption
     */
    'airKg'?: number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSystemsConsumption
     */
    'co2Kg'?: number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSystemsConsumption
     */
    'beerLostL'?: number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSystemsConsumption
     */
    'causticKg'?: number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSystemsConsumption
     */
    'acidicChemicalL'?: number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSystemsConsumption
     */
    'enzymaticChemicalL'?: number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSystemsConsumption
     */
    'nitricAcidicChemicalL'?: number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSystemsConsumption
     */
    'membraneCleanerKg'?: number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSystemsConsumption
     */
    'peroxideL'?: number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSystemsConsumption
     */
    'cipEnergykWh': number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSystemsConsumption
     */
    'stablizationConsthL'?: number;
}
/**
 * 
 * @export
 * @interface SettingsSystemsDegreeOfEfficiency
 */
export interface SettingsSystemsDegreeOfEfficiency {
    /**
     * 
     * @type {number}
     * @memberof SettingsSystemsDegreeOfEfficiency
     */
    'green': number;
    /**
     * 
     * @type {number}
     * @memberof SettingsSystemsDegreeOfEfficiency
     */
    'red': number;
}
/**
 * 
 * @export
 * @interface SettingsSystemsEvery10thCycle
 */
export interface SettingsSystemsEvery10thCycle {
    /**
     * 
     * @type {number}
     * @memberof SettingsSystemsEvery10thCycle
     */
    'acidicChemicalL': number;
}
/**
 * 
 * @export
 * @interface SettingsSystemsThresholdValues
 */
export interface SettingsSystemsThresholdValues {
    /**
     * 
     * @type {number}
     * @memberof SettingsSystemsThresholdValues
     */
    'targetFlowRateHlh': number;
}
/**
 * 
 * @export
 * @interface SettingsUsers
 */
export interface SettingsUsers {
    /**
     * 
     * @type {string}
     * @memberof SettingsUsers
     */
    'language': SettingsUsersLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof SettingsUsers
     */
    'dateFormat': string;
    /**
     * 
     * @type {string}
     * @memberof SettingsUsers
     */
    'monthFormat': string;
    /**
     * 
     * @type {string}
     * @memberof SettingsUsers
     */
    'pressureUnits': SettingsUsersPressureUnitsEnum;
    /**
     * 
     * @type {string}
     * @memberof SettingsUsers
     */
    'volumeUnits': SettingsUsersVolumeUnitsEnum;
    /**
     * 
     * @type {Array<SettingsUsersFavorites>}
     * @memberof SettingsUsers
     */
    'favorites'?: Array<SettingsUsersFavorites>;
}

export const SettingsUsersLanguageEnum = {
    De: 'de',
    En: 'en',
    EnUs: 'en-US',
    Es: 'es',
    Fr: 'fr',
    It: 'it',
    Ja: 'ja',
    Nl: 'nl',
    Ru: 'ru',
    Zh: 'zh'
} as const;

export type SettingsUsersLanguageEnum = typeof SettingsUsersLanguageEnum[keyof typeof SettingsUsersLanguageEnum];
export const SettingsUsersPressureUnitsEnum = {
    Ba: 'Ba',
    Bar: 'bar',
    KPa: 'kPa',
    Mbar: 'mbar',
    Psi: 'psi'
} as const;

export type SettingsUsersPressureUnitsEnum = typeof SettingsUsersPressureUnitsEnum[keyof typeof SettingsUsersPressureUnitsEnum];
export const SettingsUsersVolumeUnitsEnum = {
    Gal: 'gal',
    HL: 'hL'
} as const;

export type SettingsUsersVolumeUnitsEnum = typeof SettingsUsersVolumeUnitsEnum[keyof typeof SettingsUsersVolumeUnitsEnum];

/**
 * 
 * @export
 * @interface SettingsUsersAlerts
 */
export interface SettingsUsersAlerts {
    /**
     * 
     * @type {string}
     * @memberof SettingsUsersAlerts
     */
    'id': string;
    /**
     * 
     * @type {SettingsUsersAlertsAlertTypes}
     * @memberof SettingsUsersAlerts
     */
    'alertTypes': SettingsUsersAlertsAlertTypes;
    /**
     * 
     * @type {SettingsUsersAlertsDeliveryOptions}
     * @memberof SettingsUsersAlerts
     */
    'deliveryOptions': SettingsUsersAlertsDeliveryOptions;
    /**
     * Company Id for the alert settings
     * @type {string}
     * @memberof SettingsUsersAlerts
     */
    'entityId': string;
    /**
     * 
     * @type {string}
     * @memberof SettingsUsersAlerts
     */
    'type': SettingsUsersAlertsTypeEnum;
}

export const SettingsUsersAlertsTypeEnum = {
    Alerts: 'alerts'
} as const;

export type SettingsUsersAlertsTypeEnum = typeof SettingsUsersAlertsTypeEnum[keyof typeof SettingsUsersAlertsTypeEnum];

/**
 * 
 * @export
 * @interface SettingsUsersAlertsAlertTypes
 */
export interface SettingsUsersAlertsAlertTypes {
    /**
     * 
     * @type {boolean}
     * @memberof SettingsUsersAlertsAlertTypes
     */
    'machineAlarms': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsUsersAlertsAlertTypes
     */
    'progressAlerts': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsUsersAlertsAlertTypes
     */
    'maintenanceWarnings': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsUsersAlertsAlertTypes
     */
    'systemWarnings': boolean;
}
/**
 * 
 * @export
 * @interface SettingsUsersAlertsDeliveryOptions
 */
export interface SettingsUsersAlertsDeliveryOptions {
    /**
     * 
     * @type {string}
     * @memberof SettingsUsersAlertsDeliveryOptions
     */
    'language': SettingsUsersAlertsDeliveryOptionsLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof SettingsUsersAlertsDeliveryOptions
     */
    'failureFrequencyAlert': SettingsUsersAlertsDeliveryOptionsFailureFrequencyAlertEnum;
}

export const SettingsUsersAlertsDeliveryOptionsLanguageEnum = {
    De: 'de',
    En: 'en',
    EnUs: 'en-US',
    Es: 'es',
    Fr: 'fr',
    It: 'it',
    Ja: 'ja',
    Nl: 'nl',
    Ru: 'ru',
    Zh: 'zh'
} as const;

export type SettingsUsersAlertsDeliveryOptionsLanguageEnum = typeof SettingsUsersAlertsDeliveryOptionsLanguageEnum[keyof typeof SettingsUsersAlertsDeliveryOptionsLanguageEnum];
export const SettingsUsersAlertsDeliveryOptionsFailureFrequencyAlertEnum = {
    Daily: 'daily',
    Weekly: 'weekly',
    Monthly: 'monthly'
} as const;

export type SettingsUsersAlertsDeliveryOptionsFailureFrequencyAlertEnum = typeof SettingsUsersAlertsDeliveryOptionsFailureFrequencyAlertEnum[keyof typeof SettingsUsersAlertsDeliveryOptionsFailureFrequencyAlertEnum];

/**
 * 
 * @export
 * @interface SettingsUsersFavorites
 */
export interface SettingsUsersFavorites {
    /**
     * 
     * @type {string}
     * @memberof SettingsUsersFavorites
     */
    'type': SettingsUsersFavoritesTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SettingsUsersFavorites
     */
    'id': string;
}

export const SettingsUsersFavoritesTypeEnum = {
    Companies: 'companies',
    Blocks: 'blocks',
    Consumables: 'consumables',
    Policies: 'policies',
    Memberships: 'memberships',
    Permissions: 'permissions',
    Sites: 'sites',
    Systems: 'systems',
    Groups: 'groups'
} as const;

export type SettingsUsersFavoritesTypeEnum = typeof SettingsUsersFavoritesTypeEnum[keyof typeof SettingsUsersFavoritesTypeEnum];

/**
 * 
 * @export
 * @interface Statement
 */
export interface Statement {
    /**
     * 
     * @type {string}
     * @memberof Statement
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Statement
     */
    'permissions': Array<string>;
    /**
     * 
     * @type {Array<StatementScopes>}
     * @memberof Statement
     */
    'scopes': Array<StatementScopes>;
}
/**
 * 
 * @export
 * @interface StatementScopes
 */
export interface StatementScopes {
    /**
     * 
     * @type {string}
     * @memberof StatementScopes
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatementScopes
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface SupportTicket
 */
export interface SupportTicket {
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    'summary': string;
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    'reproductionCase'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    'machineSerialNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    'companySiteId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    'systemId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    'serviceDeskKey'?: string;
    /**
     * 
     * @type {SupportTicketBaseCustomerInfo}
     * @memberof SupportTicket
     */
    'customerInfo': SupportTicketBaseCustomerInfo;
    /**
     * 
     * @type {SupportTicketAttachment}
     * @memberof SupportTicket
     */
    'attachments'?: SupportTicketAttachment;
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface SupportTicketAttachment
 */
export interface SupportTicketAttachment {
}
/**
 * 
 * @export
 * @interface SupportTicketBase
 */
export interface SupportTicketBase {
    /**
     * 
     * @type {string}
     * @memberof SupportTicketBase
     */
    'summary': string;
    /**
     * 
     * @type {string}
     * @memberof SupportTicketBase
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof SupportTicketBase
     */
    'reproductionCase'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportTicketBase
     */
    'machineSerialNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SupportTicketBase
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof SupportTicketBase
     */
    'companySiteId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportTicketBase
     */
    'systemId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportTicketBase
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportTicketBase
     */
    'serviceDeskKey'?: string;
    /**
     * 
     * @type {SupportTicketBaseCustomerInfo}
     * @memberof SupportTicketBase
     */
    'customerInfo': SupportTicketBaseCustomerInfo;
    /**
     * 
     * @type {SupportTicketAttachment}
     * @memberof SupportTicketBase
     */
    'attachments'?: SupportTicketAttachment;
}
/**
 * 
 * @export
 * @interface SupportTicketBaseCustomerInfo
 */
export interface SupportTicketBaseCustomerInfo {
    /**
     * 
     * @type {string}
     * @memberof SupportTicketBaseCustomerInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SupportTicketBaseCustomerInfo
     */
    'siteName': string;
}
/**
 * 
 * @export
 * @interface System
 */
export interface System {
    /**
     * 
     * @type {string}
     * @memberof System
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof System
     */
    'companySiteId': string;
    /**
     * 
     * @type {string}
     * @memberof System
     */
    'areaId': string;
    /**
     * 
     * @type {string}
     * @memberof System
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof System
     */
    'modelNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof System
     */
    'deploymentDate': string;
    /**
     * 
     * @type {string}
     * @memberof System
     */
    'membraneArea'?: string;
    /**
     * 
     * @type {number}
     * @memberof System
     */
    'squareMetersPerModule'?: number;
    /**
     * An optional reference to a brew brand associated with system
     * @type {string}
     * @memberof System
     */
    'brandId'?: string;
    /**
     * 
     * @type {string}
     * @memberof System
     */
    'serialNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof System
     */
    'type': SystemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof System
     */
    'plcType'?: SystemPlcTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof System
     */
    'blockCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof System
     */
    'clusterCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof System
     */
    'moduleCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof System
     */
    'columnCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof System
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof System
     */
    'status'?: SystemStatusEnum;
    /**
     * 
     * @type {SystemBaseProvisioning}
     * @memberof System
     */
    'provisioning'?: SystemBaseProvisioning;
    /**
     * 
     * @type {string}
     * @memberof System
     */
    'id': string;
}

export const SystemTypeEnum = {
    Profi: 'profi',
    Cbs: 'cbs',
    Cfs: 'cfs',
    Keraflux: 'keraflux'
} as const;

export type SystemTypeEnum = typeof SystemTypeEnum[keyof typeof SystemTypeEnum];
export const SystemPlcTypeEnum = {
    ProfiPlc: 'Profi PLC',
    SiemensS7400: 'Siemens S7 - 400',
    SiemensS71200: 'Siemens S7 - 1200',
    SiemensS71500: 'Siemens S7 - 1500'
} as const;

export type SystemPlcTypeEnum = typeof SystemPlcTypeEnum[keyof typeof SystemPlcTypeEnum];
export const SystemStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type SystemStatusEnum = typeof SystemStatusEnum[keyof typeof SystemStatusEnum];

/**
 * 
 * @export
 * @interface SystemBase
 */
export interface SystemBase {
    /**
     * 
     * @type {string}
     * @memberof SystemBase
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof SystemBase
     */
    'companySiteId': string;
    /**
     * 
     * @type {string}
     * @memberof SystemBase
     */
    'areaId': string;
    /**
     * 
     * @type {string}
     * @memberof SystemBase
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemBase
     */
    'modelNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemBase
     */
    'deploymentDate': string;
    /**
     * 
     * @type {string}
     * @memberof SystemBase
     */
    'membraneArea'?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemBase
     */
    'squareMetersPerModule'?: number;
    /**
     * An optional reference to a brew brand associated with system
     * @type {string}
     * @memberof SystemBase
     */
    'brandId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemBase
     */
    'serialNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemBase
     */
    'type': SystemBaseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SystemBase
     */
    'plcType'?: SystemBasePlcTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SystemBase
     */
    'blockCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemBase
     */
    'clusterCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemBase
     */
    'moduleCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemBase
     */
    'columnCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemBase
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof SystemBase
     */
    'status'?: SystemBaseStatusEnum;
    /**
     * 
     * @type {SystemBaseProvisioning}
     * @memberof SystemBase
     */
    'provisioning'?: SystemBaseProvisioning;
}

export const SystemBaseTypeEnum = {
    Profi: 'profi',
    Cbs: 'cbs',
    Cfs: 'cfs',
    Keraflux: 'keraflux'
} as const;

export type SystemBaseTypeEnum = typeof SystemBaseTypeEnum[keyof typeof SystemBaseTypeEnum];
export const SystemBasePlcTypeEnum = {
    ProfiPlc: 'Profi PLC',
    SiemensS7400: 'Siemens S7 - 400',
    SiemensS71200: 'Siemens S7 - 1200',
    SiemensS71500: 'Siemens S7 - 1500'
} as const;

export type SystemBasePlcTypeEnum = typeof SystemBasePlcTypeEnum[keyof typeof SystemBasePlcTypeEnum];
export const SystemBaseStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type SystemBaseStatusEnum = typeof SystemBaseStatusEnum[keyof typeof SystemBaseStatusEnum];

/**
 * @type SystemBaseProvisioning
 * @export
 */
export type SystemBaseProvisioning = any;

/**
 * 
 * @export
 * @interface SystemBaseProvisioningBlocks
 */
export interface SystemBaseProvisioningBlocks {
    /**
     * 
     * @type {number}
     * @memberof SystemBaseProvisioningBlocks
     */
    'quantity': number;
    /**
     * 
     * @type {SystemBaseProvisioningBlocksClusters}
     * @memberof SystemBaseProvisioningBlocks
     */
    'clusters': SystemBaseProvisioningBlocksClusters;
}
/**
 * 
 * @export
 * @interface SystemBaseProvisioningBlocksClusters
 */
export interface SystemBaseProvisioningBlocksClusters {
    /**
     * 
     * @type {number}
     * @memberof SystemBaseProvisioningBlocksClusters
     */
    'quantity': number;
    /**
     * 
     * @type {SystemBaseProvisioningBlocksClustersModules}
     * @memberof SystemBaseProvisioningBlocksClusters
     */
    'modules': SystemBaseProvisioningBlocksClustersModules;
    /**
     * 
     * @type {SystemBaseProvisioningBlocksClustersConsumables}
     * @memberof SystemBaseProvisioningBlocksClusters
     */
    'consumables': SystemBaseProvisioningBlocksClustersConsumables;
}
/**
 * 
 * @export
 * @interface SystemBaseProvisioningBlocksClustersConsumables
 */
export interface SystemBaseProvisioningBlocksClustersConsumables {
    /**
     * 
     * @type {number}
     * @memberof SystemBaseProvisioningBlocksClustersConsumables
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof SystemBaseProvisioningBlocksClustersConsumables
     */
    'productNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SystemBaseProvisioningBlocksClustersConsumables
     */
    'installDate': string;
}
/**
 * 
 * @export
 * @interface SystemBaseProvisioningBlocksClustersModules
 */
export interface SystemBaseProvisioningBlocksClustersModules {
    /**
     * 
     * @type {number}
     * @memberof SystemBaseProvisioningBlocksClustersModules
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof SystemBaseProvisioningBlocksClustersModules
     */
    'partNumber': string;
}
/**
 * 
 * @export
 * @interface SystemBaseProvisioningColumns
 */
export interface SystemBaseProvisioningColumns {
    /**
     * 
     * @type {number}
     * @memberof SystemBaseProvisioningColumns
     */
    'cassettes': number;
    /**
     * 
     * @type {number}
     * @memberof SystemBaseProvisioningColumns
     */
    'position': number;
    /**
     * 
     * @type {BlockInfoBaseProvisioningClusters}
     * @memberof SystemBaseProvisioningColumns
     */
    'clusters': BlockInfoBaseProvisioningClusters;
}
/**
 * 
 * @export
 * @interface SystemCleanUpLiveKPI
 */
export interface SystemCleanUpLiveKPI {
    /**
     * 
     * @type {number}
     * @memberof SystemCleanUpLiveKPI
     */
    'waterConsumptionM3Hl': number;
    /**
     * 
     * @type {boolean}
     * @memberof SystemCleanUpLiveKPI
     */
    'causticLow': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemCleanUpLiveKPI
     */
    'acidLow': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemCleanUpLiveKPI
     */
    'peroxideLow': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemCleanUpLiveKPI
     */
    'membraneCleanerLow': boolean;
    /**
     * 
     * @type {string}
     * @memberof SystemCleanUpLiveKPI
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemCleanUpLiveKPI
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface SystemCleanUpLiveKPIBase
 */
export interface SystemCleanUpLiveKPIBase {
    /**
     * 
     * @type {number}
     * @memberof SystemCleanUpLiveKPIBase
     */
    'waterConsumptionM3Hl': number;
    /**
     * 
     * @type {boolean}
     * @memberof SystemCleanUpLiveKPIBase
     */
    'causticLow': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemCleanUpLiveKPIBase
     */
    'acidLow': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemCleanUpLiveKPIBase
     */
    'peroxideLow': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemCleanUpLiveKPIBase
     */
    'membraneCleanerLow': boolean;
    /**
     * 
     * @type {string}
     * @memberof SystemCleanUpLiveKPIBase
     */
    'timestamp'?: string;
}
/**
 * 
 * @export
 * @interface SystemCleanUpLiveStatus
 */
export interface SystemCleanUpLiveStatus {
    /**
     * 
     * @type {Array<SystemLiveStatusBaseOneOfRecentSystemStatus>}
     * @memberof SystemCleanUpLiveStatus
     */
    'recentCleanUpUnitStatus'?: Array<SystemLiveStatusBaseOneOfRecentSystemStatus>;
    /**
     * 
     * @type {string}
     * @memberof SystemCleanUpLiveStatus
     */
    'currentCleanUpUnitStatus': string;
    /**
     * 
     * @type {string}
     * @memberof SystemCleanUpLiveStatus
     */
    'passivationStartTime': string;
    /**
     * 
     * @type {number}
     * @memberof SystemCleanUpLiveStatus
     */
    'runsUntilNextPassivation'?: number;
    /**
     * 
     * @type {string}
     * @memberof SystemCleanUpLiveStatus
     */
    'nextpassivationStartTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemCleanUpLiveStatus
     */
    'productionsBeforePassivation': number;
    /**
     * 
     * @type {string}
     * @memberof SystemCleanUpLiveStatus
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemCleanUpLiveStatus
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface SystemCleanUpLiveStatusBase
 */
export interface SystemCleanUpLiveStatusBase {
    /**
     * 
     * @type {Array<SystemLiveStatusBaseOneOfRecentSystemStatus>}
     * @memberof SystemCleanUpLiveStatusBase
     */
    'recentCleanUpUnitStatus'?: Array<SystemLiveStatusBaseOneOfRecentSystemStatus>;
    /**
     * 
     * @type {string}
     * @memberof SystemCleanUpLiveStatusBase
     */
    'currentCleanUpUnitStatus': string;
    /**
     * 
     * @type {string}
     * @memberof SystemCleanUpLiveStatusBase
     */
    'passivationStartTime': string;
    /**
     * 
     * @type {number}
     * @memberof SystemCleanUpLiveStatusBase
     */
    'runsUntilNextPassivation'?: number;
    /**
     * 
     * @type {string}
     * @memberof SystemCleanUpLiveStatusBase
     */
    'nextpassivationStartTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemCleanUpLiveStatusBase
     */
    'productionsBeforePassivation': number;
    /**
     * 
     * @type {string}
     * @memberof SystemCleanUpLiveStatusBase
     */
    'timestamp'?: string;
}
/**
 * 
 * @export
 * @interface SystemClusterStatus
 */
export interface SystemClusterStatus {
    /**
     * 
     * @type {string}
     * @memberof SystemClusterStatus
     */
    'currentStatus': SystemClusterStatusCurrentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SystemClusterStatus
     */
    'testResult': SystemClusterStatusTestResultEnum;
    /**
     * 
     * @type {string}
     * @memberof SystemClusterStatus
     */
    'testTime': string;
    /**
     * 
     * @type {string}
     * @memberof SystemClusterStatus
     */
    'blockLabel': SystemClusterStatusBlockLabelEnum;
    /**
     * 
     * @type {string}
     * @memberof SystemClusterStatus
     */
    'clusterLabel': SystemClusterStatusClusterLabelEnum;
    /**
     * 
     * @type {string}
     * @memberof SystemClusterStatus
     */
    'id': string;
}

export const SystemClusterStatusCurrentStatusEnum = {
    Running: 'Running',
    Cleaning: 'Cleaning',
    Idle: 'Idle',
    NotInService: 'Not in Service'
} as const;

export type SystemClusterStatusCurrentStatusEnum = typeof SystemClusterStatusCurrentStatusEnum[keyof typeof SystemClusterStatusCurrentStatusEnum];
export const SystemClusterStatusTestResultEnum = {
    Failed: 'Failed',
    Passed: 'Passed'
} as const;

export type SystemClusterStatusTestResultEnum = typeof SystemClusterStatusTestResultEnum[keyof typeof SystemClusterStatusTestResultEnum];
export const SystemClusterStatusBlockLabelEnum = {
    _1: '1',
    _2: '2'
} as const;

export type SystemClusterStatusBlockLabelEnum = typeof SystemClusterStatusBlockLabelEnum[keyof typeof SystemClusterStatusBlockLabelEnum];
export const SystemClusterStatusClusterLabelEnum = {
    _01: '01',
    _02: '02',
    _03: '03',
    _04: '04',
    _05: '05',
    _06: '06',
    _07: '07',
    _08: '08',
    _09: '09',
    _10: '10',
    _11: '11',
    _12: '12',
    _13: '13',
    _14: '14',
    _15: '15',
    _16: '16',
    _17: '17',
    _18: '18',
    _19: '19',
    _20: '20'
} as const;

export type SystemClusterStatusClusterLabelEnum = typeof SystemClusterStatusClusterLabelEnum[keyof typeof SystemClusterStatusClusterLabelEnum];

/**
 * 
 * @export
 * @interface SystemClusterStatusBase
 */
export interface SystemClusterStatusBase {
    /**
     * 
     * @type {string}
     * @memberof SystemClusterStatusBase
     */
    'currentStatus': SystemClusterStatusBaseCurrentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SystemClusterStatusBase
     */
    'testResult': SystemClusterStatusBaseTestResultEnum;
    /**
     * 
     * @type {string}
     * @memberof SystemClusterStatusBase
     */
    'testTime': string;
    /**
     * 
     * @type {string}
     * @memberof SystemClusterStatusBase
     */
    'blockLabel': SystemClusterStatusBaseBlockLabelEnum;
    /**
     * 
     * @type {string}
     * @memberof SystemClusterStatusBase
     */
    'clusterLabel': SystemClusterStatusBaseClusterLabelEnum;
}

export const SystemClusterStatusBaseCurrentStatusEnum = {
    Running: 'Running',
    Cleaning: 'Cleaning',
    Idle: 'Idle',
    NotInService: 'Not in Service'
} as const;

export type SystemClusterStatusBaseCurrentStatusEnum = typeof SystemClusterStatusBaseCurrentStatusEnum[keyof typeof SystemClusterStatusBaseCurrentStatusEnum];
export const SystemClusterStatusBaseTestResultEnum = {
    Failed: 'Failed',
    Passed: 'Passed'
} as const;

export type SystemClusterStatusBaseTestResultEnum = typeof SystemClusterStatusBaseTestResultEnum[keyof typeof SystemClusterStatusBaseTestResultEnum];
export const SystemClusterStatusBaseBlockLabelEnum = {
    _1: '1',
    _2: '2'
} as const;

export type SystemClusterStatusBaseBlockLabelEnum = typeof SystemClusterStatusBaseBlockLabelEnum[keyof typeof SystemClusterStatusBaseBlockLabelEnum];
export const SystemClusterStatusBaseClusterLabelEnum = {
    _01: '01',
    _02: '02',
    _03: '03',
    _04: '04',
    _05: '05',
    _06: '06',
    _07: '07',
    _08: '08',
    _09: '09',
    _10: '10',
    _11: '11',
    _12: '12',
    _13: '13',
    _14: '14',
    _15: '15',
    _16: '16',
    _17: '17',
    _18: '18',
    _19: '19',
    _20: '20'
} as const;

export type SystemClusterStatusBaseClusterLabelEnum = typeof SystemClusterStatusBaseClusterLabelEnum[keyof typeof SystemClusterStatusBaseClusterLabelEnum];

/**
 * 
 * @export
 * @interface SystemCount
 */
export interface SystemCount {
    /**
     * 
     * @type {string}
     * @memberof SystemCount
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemCount
     */
    'companySiteId'?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemCount
     */
    'count'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemCount
     */
    'profi'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemCount
     */
    'cbs'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemCount
     */
    'cfs'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemCount
     */
    'keraflux'?: number;
}
/**
 * 
 * @export
 * @interface SystemCountBase
 */
export interface SystemCountBase {
    /**
     * 
     * @type {string}
     * @memberof SystemCountBase
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemCountBase
     */
    'companySiteId'?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemCountBase
     */
    'count'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemCountBase
     */
    'profi'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemCountBase
     */
    'cbs'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemCountBase
     */
    'cfs'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemCountBase
     */
    'keraflux'?: number;
}
/**
 * 
 * @export
 * @interface SystemLiveKPI
 */
export interface SystemLiveKPI {
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPI
     */
    'efficiencyPct': number;
    /**
     * 
     * @type {boolean}
     * @memberof SystemLiveKPI
     */
    'preFiltration'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemLiveKPI
     */
    'finalFiltration'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPI
     */
    'productionCumVolumeHl'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPI
     */
    'productionCumFlowHlh'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPI
     */
    'productCumThroughputHlm2'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPI
     */
    'productionNetCapacityLmh'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPI
     */
    'lineOxygenPpb'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPI
     */
    'throughputHlCassette'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPI
     */
    'hlStablized'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPI
     */
    'tmpBar'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPI
     */
    'cipCyclesSinceLastRefill'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPI
     */
    'productionCipCycles'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPI
     */
    'productionCumThroughputHlM2'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPI
     */
    'lineTurbidityEbc'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPI
     */
    'actualDp'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPI
     */
    'actualCol1Dp'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPI
     */
    'actualCol2Dp'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPI
     */
    'actualCol3Dp'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPI
     */
    'actualFlow'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPI
     */
    'lastHourAverageFlow'?: number;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveKPI
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveKPI
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface SystemLiveKPIBase
 */
export interface SystemLiveKPIBase {
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPIBase
     */
    'efficiencyPct': number;
    /**
     * 
     * @type {boolean}
     * @memberof SystemLiveKPIBase
     */
    'preFiltration'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemLiveKPIBase
     */
    'finalFiltration'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPIBase
     */
    'productionCumVolumeHl'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPIBase
     */
    'productionCumFlowHlh'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPIBase
     */
    'productCumThroughputHlm2'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPIBase
     */
    'productionNetCapacityLmh'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPIBase
     */
    'lineOxygenPpb'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPIBase
     */
    'throughputHlCassette'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPIBase
     */
    'hlStablized'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPIBase
     */
    'tmpBar'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPIBase
     */
    'cipCyclesSinceLastRefill'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPIBase
     */
    'productionCipCycles'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPIBase
     */
    'productionCumThroughputHlM2'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPIBase
     */
    'lineTurbidityEbc'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPIBase
     */
    'actualDp'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPIBase
     */
    'actualCol1Dp'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPIBase
     */
    'actualCol2Dp'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPIBase
     */
    'actualCol3Dp'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPIBase
     */
    'actualFlow'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveKPIBase
     */
    'lastHourAverageFlow'?: number;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveKPIBase
     */
    'timestamp'?: string;
}
/**
 * 
 * @export
 * @interface SystemLiveRegeneration
 */
export interface SystemLiveRegeneration {
    /**
     * 
     * @type {Array<SystemLiveRegenerationBaseRecentRunningStatus>}
     * @memberof SystemLiveRegeneration
     */
    'recentRunningStatus': Array<SystemLiveRegenerationBaseRecentRunningStatus>;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveRegeneration
     */
    'phMeter'?: number;
    /**
     * 
     * @type {SystemLiveRegenerationBaseCleanTypeSanitation}
     * @memberof SystemLiveRegeneration
     */
    'cleanTypeSanitation'?: SystemLiveRegenerationBaseCleanTypeSanitation;
    /**
     * 
     * @type {SystemLiveRegenerationBaseCleanTypeSanitation}
     * @memberof SystemLiveRegeneration
     */
    'cleanTypeCaustic'?: SystemLiveRegenerationBaseCleanTypeSanitation;
    /**
     * 
     * @type {SystemLiveRegenerationBaseCleanTypeSanitation}
     * @memberof SystemLiveRegeneration
     */
    'cleanTypeEnzymatic'?: SystemLiveRegenerationBaseCleanTypeSanitation;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveRegeneration
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface SystemLiveRegenerationBase
 */
export interface SystemLiveRegenerationBase {
    /**
     * 
     * @type {Array<SystemLiveRegenerationBaseRecentRunningStatus>}
     * @memberof SystemLiveRegenerationBase
     */
    'recentRunningStatus': Array<SystemLiveRegenerationBaseRecentRunningStatus>;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveRegenerationBase
     */
    'phMeter'?: number;
    /**
     * 
     * @type {SystemLiveRegenerationBaseCleanTypeSanitation}
     * @memberof SystemLiveRegenerationBase
     */
    'cleanTypeSanitation'?: SystemLiveRegenerationBaseCleanTypeSanitation;
    /**
     * 
     * @type {SystemLiveRegenerationBaseCleanTypeSanitation}
     * @memberof SystemLiveRegenerationBase
     */
    'cleanTypeCaustic'?: SystemLiveRegenerationBaseCleanTypeSanitation;
    /**
     * 
     * @type {SystemLiveRegenerationBaseCleanTypeSanitation}
     * @memberof SystemLiveRegenerationBase
     */
    'cleanTypeEnzymatic'?: SystemLiveRegenerationBaseCleanTypeSanitation;
}
/**
 * 
 * @export
 * @interface SystemLiveRegenerationBaseCleanTypeSanitation
 */
export interface SystemLiveRegenerationBaseCleanTypeSanitation {
    /**
     * 
     * @type {string}
     * @memberof SystemLiveRegenerationBaseCleanTypeSanitation
     */
    'lastClean': string;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveRegenerationBaseCleanTypeSanitation
     */
    'processedVolumeHl': number;
}
/**
 * 
 * @export
 * @interface SystemLiveRegenerationBaseRecentRunningStatus
 */
export interface SystemLiveRegenerationBaseRecentRunningStatus {
    /**
     * 
     * @type {string}
     * @memberof SystemLiveRegenerationBaseRecentRunningStatus
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveRegenerationBaseRecentRunningStatus
     */
    'percent'?: number;
}
/**
 * 
 * @export
 * @interface SystemLiveStatus
 */
export interface SystemLiveStatus {
    /**
     * 
     * @type {Array<SystemLiveStatusBaseOneOfRecentSystemStatus>}
     * @memberof SystemLiveStatus
     */
    'recentSystemStatus'?: Array<SystemLiveStatusBaseOneOfRecentSystemStatus>;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveStatus
     */
    'currentSystemStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveStatus
     */
    'productionStartTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatus
     */
    'processedVolumeHl'?: number;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveStatus
     */
    'lastAlarm'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveStatus
     */
    'lastServiceDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatus
     */
    'serviceOverdue'?: number;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveStatus
     */
    'timestamp'?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatus
     */
    'remainingTimeSeconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatus
     */
    'productionPercentCompletePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatus
     */
    'productionRemainingVolumeHl'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatus
     */
    'productionTargetVolumeHl'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatus
     */
    'operationalClusters'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatus
     */
    'installedClusters'?: number;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveStatus
     */
    'productionBrand'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SystemLiveStatus
     */
    'preFiltration'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemLiveStatus
     */
    'finalFiltration'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemLiveStatus
     */
    'diaFiltration'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveStatus
     */
    'id': string;
}
/**
 * @type SystemLiveStatusBase
 * @export
 */
export type SystemLiveStatusBase = SystemLiveStatusBaseOneOf | SystemLiveStatusBaseOneOf1;

/**
 * 
 * @export
 * @interface SystemLiveStatusBaseOneOf
 */
export interface SystemLiveStatusBaseOneOf {
    /**
     * 
     * @type {Array<SystemLiveStatusBaseOneOfRecentSystemStatus>}
     * @memberof SystemLiveStatusBaseOneOf
     */
    'recentSystemStatus'?: Array<SystemLiveStatusBaseOneOfRecentSystemStatus>;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveStatusBaseOneOf
     */
    'currentSystemStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveStatusBaseOneOf
     */
    'productionStartTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatusBaseOneOf
     */
    'processedVolumeHl'?: number;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveStatusBaseOneOf
     */
    'lastAlarm'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveStatusBaseOneOf
     */
    'lastServiceDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatusBaseOneOf
     */
    'serviceOverdue'?: number;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveStatusBaseOneOf
     */
    'timestamp'?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatusBaseOneOf
     */
    'remainingTimeSeconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatusBaseOneOf
     */
    'productionPercentCompletePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatusBaseOneOf
     */
    'productionRemainingVolumeHl'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatusBaseOneOf
     */
    'productionTargetVolumeHl'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatusBaseOneOf
     */
    'operationalClusters'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatusBaseOneOf
     */
    'installedClusters'?: number;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveStatusBaseOneOf
     */
    'productionBrand'?: string;
}
/**
 * 
 * @export
 * @interface SystemLiveStatusBaseOneOf1
 */
export interface SystemLiveStatusBaseOneOf1 {
    /**
     * 
     * @type {Array<SystemLiveStatusBaseOneOfRecentSystemStatus>}
     * @memberof SystemLiveStatusBaseOneOf1
     */
    'recentSystemStatus'?: Array<SystemLiveStatusBaseOneOfRecentSystemStatus>;
    /**
     * 
     * @type {boolean}
     * @memberof SystemLiveStatusBaseOneOf1
     */
    'preFiltration'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemLiveStatusBaseOneOf1
     */
    'finalFiltration'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveStatusBaseOneOf1
     */
    'currentSystemStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveStatusBaseOneOf1
     */
    'productionStartTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatusBaseOneOf1
     */
    'processedVolumeHl'?: number;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveStatusBaseOneOf1
     */
    'lastAlarm'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveStatusBaseOneOf1
     */
    'lastServiceDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatusBaseOneOf1
     */
    'serviceOverdue'?: number;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveStatusBaseOneOf1
     */
    'timestamp'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SystemLiveStatusBaseOneOf1
     */
    'diaFiltration'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatusBaseOneOf1
     */
    'remainingTimeSeconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatusBaseOneOf1
     */
    'productionPercentCompletePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatusBaseOneOf1
     */
    'productionRemainingVolumeHl'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatusBaseOneOf1
     */
    'productionTargetVolumeHl'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatusBaseOneOf1
     */
    'operationalClusters'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatusBaseOneOf1
     */
    'installedClusters'?: number;
    /**
     * 
     * @type {string}
     * @memberof SystemLiveStatusBaseOneOf1
     */
    'productionBrand'?: string;
}
/**
 * 
 * @export
 * @interface SystemLiveStatusBaseOneOfRecentSystemStatus
 */
export interface SystemLiveStatusBaseOneOfRecentSystemStatus {
    /**
     * 
     * @type {string}
     * @memberof SystemLiveStatusBaseOneOfRecentSystemStatus
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemLiveStatusBaseOneOfRecentSystemStatus
     */
    'percent'?: number;
}
/**
 * 
 * @export
 * @interface SystemStatsCleaningConsumption
 */
export interface SystemStatsCleaningConsumption {
    /**
     * 
     * @type {string}
     * @memberof SystemStatsCleaningConsumption
     */
    'systemId': string;
    /**
     * 
     * @type {SystemStatsCleaningConsumptionData}
     * @memberof SystemStatsCleaningConsumption
     */
    'current'?: SystemStatsCleaningConsumptionData;
    /**
     * 
     * @type {SystemStatsCleaningConsumptionData}
     * @memberof SystemStatsCleaningConsumption
     */
    'compare'?: SystemStatsCleaningConsumptionData;
}
/**
 * 
 * @export
 * @interface SystemStatsCleaningConsumptionData
 */
export interface SystemStatsCleaningConsumptionData {
    /**
     * 
     * @type {object}
     * @memberof SystemStatsCleaningConsumptionData
     */
    'category'?: object;
}
/**
 * 
 * @export
 * @interface SystemStatsExportStats
 */
export interface SystemStatsExportStats {
    /**
     * 
     * @type {string}
     * @memberof SystemStatsExportStats
     */
    'systemId': string;
    /**
     * 
     * @type {string}
     * @memberof SystemStatsExportStats
     */
    'systemType'?: SystemStatsExportStatsSystemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SystemStatsExportStats
     */
    'interval'?: SystemStatsExportStatsIntervalEnum;
    /**
     * 
     * @type {string}
     * @memberof SystemStatsExportStats
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemStatsExportStats
     */
    'endTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemStatsExportStats
     */
    'presignedUrl'?: string;
}

export const SystemStatsExportStatsSystemTypeEnum = {
    Profi: 'profi',
    Cbs: 'cbs',
    Cfs: 'cfs',
    Keraflux: 'keraflux'
} as const;

export type SystemStatsExportStatsSystemTypeEnum = typeof SystemStatsExportStatsSystemTypeEnum[keyof typeof SystemStatsExportStatsSystemTypeEnum];
export const SystemStatsExportStatsIntervalEnum = {
    Dates: 'dates',
    Run: 'run'
} as const;

export type SystemStatsExportStatsIntervalEnum = typeof SystemStatsExportStatsIntervalEnum[keyof typeof SystemStatsExportStatsIntervalEnum];

/**
 * 
 * @export
 * @interface SystemStatsExportTelemetry
 */
export interface SystemStatsExportTelemetry {
    /**
     * 
     * @type {string}
     * @memberof SystemStatsExportTelemetry
     */
    'systemId': string;
    /**
     * 
     * @type {string}
     * @memberof SystemStatsExportTelemetry
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemStatsExportTelemetry
     */
    'endTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemStatsExportTelemetry
     */
    'presignedUrl'?: string;
}
/**
 * 
 * @export
 * @interface SystemStatsProcess
 */
export interface SystemStatsProcess {
    /**
     * 
     * @type {string}
     * @memberof SystemStatsProcess
     */
    'systemId': string;
    /**
     * 
     * @type {SystemStatsProcessData}
     * @memberof SystemStatsProcess
     */
    'current'?: SystemStatsProcessData;
    /**
     * 
     * @type {SystemStatsProcessData}
     * @memberof SystemStatsProcess
     */
    'compare'?: SystemStatsProcessData;
}
/**
 * 
 * @export
 * @interface SystemStatsProcessData
 */
export interface SystemStatsProcessData {
    /**
     * 
     * @type {SystemStatsProcessDataAnyOfProfi}
     * @memberof SystemStatsProcessData
     */
    'Profi'?: SystemStatsProcessDataAnyOfProfi;
    /**
     * 
     * @type {SystemStatsProcessDataAnyOf1CFS}
     * @memberof SystemStatsProcessData
     */
    'CFS'?: SystemStatsProcessDataAnyOf1CFS;
    /**
     * 
     * @type {SystemStatsProcessDataAnyOf2CBS}
     * @memberof SystemStatsProcessData
     */
    'CBS'?: SystemStatsProcessDataAnyOf2CBS;
    /**
     * 
     * @type {SystemStatsProcessDataAnyOf3Keraflux}
     * @memberof SystemStatsProcessData
     */
    'Keraflux'?: SystemStatsProcessDataAnyOf3Keraflux;
}
/**
 * 
 * @export
 * @interface SystemStatsProcessDataAnyOf
 */
export interface SystemStatsProcessDataAnyOf {
    /**
     * 
     * @type {SystemStatsProcessDataAnyOfProfi}
     * @memberof SystemStatsProcessDataAnyOf
     */
    'Profi'?: SystemStatsProcessDataAnyOfProfi;
}
/**
 * 
 * @export
 * @interface SystemStatsProcessDataAnyOf1
 */
export interface SystemStatsProcessDataAnyOf1 {
    /**
     * 
     * @type {SystemStatsProcessDataAnyOf1CFS}
     * @memberof SystemStatsProcessDataAnyOf1
     */
    'CFS'?: SystemStatsProcessDataAnyOf1CFS;
}
/**
 * 
 * @export
 * @interface SystemStatsProcessDataAnyOf1CFS
 */
export interface SystemStatsProcessDataAnyOf1CFS {
    /**
     * 
     * @type {string}
     * @memberof SystemStatsProcessDataAnyOf1CFS
     */
    'week'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemStatsProcessDataAnyOf1CFS
     */
    'month'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemStatsProcessDataAnyOf1CFS
     */
    'year'?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf1CFS
     */
    'totalColdFilteredBeer'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf1CFS
     */
    'averageFlow'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf1CFS
     */
    'systemUtilization'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf1CFS
     */
    'totalCIPS'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf1CFS
     */
    'fluxRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf1CFS
     */
    'beerLossByFilterLoss'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf1CFS
     */
    'sanitationCycles'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf1CFS
     */
    'causticCycles'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf1CFS
     */
    'enzymaticCycles'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf1CFS
     */
    'totalCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf1CFS
     */
    'totalProcessingTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf1CFS
     */
    'avgProcessedVolume'?: number;
}
/**
 * 
 * @export
 * @interface SystemStatsProcessDataAnyOf2
 */
export interface SystemStatsProcessDataAnyOf2 {
    /**
     * 
     * @type {SystemStatsProcessDataAnyOf2CBS}
     * @memberof SystemStatsProcessDataAnyOf2
     */
    'CBS'?: SystemStatsProcessDataAnyOf2CBS;
}
/**
 * 
 * @export
 * @interface SystemStatsProcessDataAnyOf2CBS
 */
export interface SystemStatsProcessDataAnyOf2CBS {
    /**
     * 
     * @type {string}
     * @memberof SystemStatsProcessDataAnyOf2CBS
     */
    'week'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemStatsProcessDataAnyOf2CBS
     */
    'month'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemStatsProcessDataAnyOf2CBS
     */
    'year'?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf2CBS
     */
    'totalStabilizedBeer'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf2CBS
     */
    'averageFlow'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf2CBS
     */
    'degreeOfEfficiency'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf2CBS
     */
    'totalCIPS'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf2CBS
     */
    'totalCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf2CBS
     */
    'totalProcessingTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf2CBS
     */
    'avgProcessedVolume'?: number;
}
/**
 * 
 * @export
 * @interface SystemStatsProcessDataAnyOf3
 */
export interface SystemStatsProcessDataAnyOf3 {
    /**
     * 
     * @type {SystemStatsProcessDataAnyOf3Keraflux}
     * @memberof SystemStatsProcessDataAnyOf3
     */
    'Keraflux'?: SystemStatsProcessDataAnyOf3Keraflux;
}
/**
 * 
 * @export
 * @interface SystemStatsProcessDataAnyOf3Keraflux
 */
export interface SystemStatsProcessDataAnyOf3Keraflux {
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf3Keraflux
     */
    'totalRecoveredBeer'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf3Keraflux
     */
    'averageFlow'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf3Keraflux
     */
    'degreeOfEfficiency'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf3Keraflux
     */
    'tmpIncrease'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf3Keraflux
     */
    'fluxRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf3Keraflux
     */
    'diaFiltrationYield'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf3Keraflux
     */
    'beerYield'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf3Keraflux
     */
    'totalCIPS'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf3Keraflux
     */
    'plato'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf3Keraflux
     */
    'averageTemperature'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf3Keraflux
     */
    'totalCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf3Keraflux
     */
    'totalProcessingTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOf3Keraflux
     */
    'avgProcessedVolume'?: number;
}
/**
 * 
 * @export
 * @interface SystemStatsProcessDataAnyOfProfi
 */
export interface SystemStatsProcessDataAnyOfProfi {
    /**
     * 
     * @type {string}
     * @memberof SystemStatsProcessDataAnyOfProfi
     */
    'week'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemStatsProcessDataAnyOfProfi
     */
    'month'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemStatsProcessDataAnyOfProfi
     */
    'year'?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOfProfi
     */
    'volumeFiltered'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOfProfi
     */
    'averageFlow'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOfProfi
     */
    'degreeOfEfficiency'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOfProfi
     */
    'tmpIncrease'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOfProfi
     */
    'filtrationOperatedToFinalTMP'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOfProfi
     */
    'capacityFullRunsTillTMPBar'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOfProfi
     */
    'filterLineUtilization'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOfProfi
     */
    'netCapacity'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOfProfi
     */
    'totalCIPS'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOfProfi
     */
    'beerLossByFilterLoss'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOfProfi
     */
    'totalCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOfProfi
     */
    'totalProcessingTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsProcessDataAnyOfProfi
     */
    'avgProcessedVolume'?: number;
}
/**
 * 
 * @export
 * @interface SystemStatsQuality
 */
export interface SystemStatsQuality {
    /**
     * 
     * @type {string}
     * @memberof SystemStatsQuality
     */
    'systemId': string;
    /**
     * 
     * @type {SystemStatsQualityData}
     * @memberof SystemStatsQuality
     */
    'current'?: SystemStatsQualityData;
    /**
     * 
     * @type {SystemStatsQualityData}
     * @memberof SystemStatsQuality
     */
    'compare'?: SystemStatsQualityData;
}
/**
 * 
 * @export
 * @interface SystemStatsQualityData
 */
export interface SystemStatsQualityData {
    /**
     * 
     * @type {SystemStatsQualityDataAnyOfProfi}
     * @memberof SystemStatsQualityData
     */
    'Profi'?: SystemStatsQualityDataAnyOfProfi;
    /**
     * 
     * @type {object}
     * @memberof SystemStatsQualityData
     */
    'CFS'?: object;
    /**
     * 
     * @type {object}
     * @memberof SystemStatsQualityData
     */
    'CBS'?: object;
    /**
     * 
     * @type {object}
     * @memberof SystemStatsQualityData
     */
    'Keraflux'?: object;
}
/**
 * 
 * @export
 * @interface SystemStatsQualityDataAnyOf
 */
export interface SystemStatsQualityDataAnyOf {
    /**
     * 
     * @type {SystemStatsQualityDataAnyOfProfi}
     * @memberof SystemStatsQualityDataAnyOf
     */
    'Profi'?: SystemStatsQualityDataAnyOfProfi;
}
/**
 * 
 * @export
 * @interface SystemStatsQualityDataAnyOf1
 */
export interface SystemStatsQualityDataAnyOf1 {
    /**
     * 
     * @type {object}
     * @memberof SystemStatsQualityDataAnyOf1
     */
    'CFS'?: object;
}
/**
 * 
 * @export
 * @interface SystemStatsQualityDataAnyOf2
 */
export interface SystemStatsQualityDataAnyOf2 {
    /**
     * 
     * @type {object}
     * @memberof SystemStatsQualityDataAnyOf2
     */
    'CBS'?: object;
}
/**
 * 
 * @export
 * @interface SystemStatsQualityDataAnyOf3
 */
export interface SystemStatsQualityDataAnyOf3 {
    /**
     * 
     * @type {object}
     * @memberof SystemStatsQualityDataAnyOf3
     */
    'Keraflux'?: object;
}
/**
 * 
 * @export
 * @interface SystemStatsQualityDataAnyOfProfi
 */
export interface SystemStatsQualityDataAnyOfProfi {
    /**
     * 
     * @type {string}
     * @memberof SystemStatsQualityDataAnyOfProfi
     */
    'week'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemStatsQualityDataAnyOfProfi
     */
    'month'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemStatsQualityDataAnyOfProfi
     */
    'year'?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsQualityDataAnyOfProfi
     */
    'oxygen'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsQualityDataAnyOfProfi
     */
    'hazeCentrifugeOut'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsQualityDataAnyOfProfi
     */
    'hazeFilterOut'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsQualityDataAnyOfProfi
     */
    'clusterTestFailurePct'?: number;
}
/**
 * 
 * @export
 * @interface SystemStatsRegeneration
 */
export interface SystemStatsRegeneration {
    /**
     * 
     * @type {string}
     * @memberof SystemStatsRegeneration
     */
    'systemId': string;
    /**
     * 
     * @type {SystemStatsRegenerationData}
     * @memberof SystemStatsRegeneration
     */
    'current'?: SystemStatsRegenerationData;
    /**
     * 
     * @type {SystemStatsRegenerationData}
     * @memberof SystemStatsRegeneration
     */
    'compare'?: SystemStatsRegenerationData;
}
/**
 * 
 * @export
 * @interface SystemStatsRegenerationData
 */
export interface SystemStatsRegenerationData {
    /**
     * 
     * @type {SystemStatsRegenerationDataAnyOfCBS}
     * @memberof SystemStatsRegenerationData
     */
    'CBS'?: SystemStatsRegenerationDataAnyOfCBS;
    /**
     * 
     * @type {SystemStatsRegenerationDataAnyOf1CFS}
     * @memberof SystemStatsRegenerationData
     */
    'CFS'?: SystemStatsRegenerationDataAnyOf1CFS;
}
/**
 * 
 * @export
 * @interface SystemStatsRegenerationDataAnyOf
 */
export interface SystemStatsRegenerationDataAnyOf {
    /**
     * 
     * @type {SystemStatsRegenerationDataAnyOfCBS}
     * @memberof SystemStatsRegenerationDataAnyOf
     */
    'CBS'?: SystemStatsRegenerationDataAnyOfCBS;
}
/**
 * 
 * @export
 * @interface SystemStatsRegenerationDataAnyOf1
 */
export interface SystemStatsRegenerationDataAnyOf1 {
    /**
     * 
     * @type {SystemStatsRegenerationDataAnyOf1CFS}
     * @memberof SystemStatsRegenerationDataAnyOf1
     */
    'CFS'?: SystemStatsRegenerationDataAnyOf1CFS;
}
/**
 * 
 * @export
 * @interface SystemStatsRegenerationDataAnyOf1CFS
 */
export interface SystemStatsRegenerationDataAnyOf1CFS {
    /**
     * 
     * @type {string}
     * @memberof SystemStatsRegenerationDataAnyOf1CFS
     */
    'week'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemStatsRegenerationDataAnyOf1CFS
     */
    'month'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemStatsRegenerationDataAnyOf1CFS
     */
    'year'?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsRegenerationDataAnyOf1CFS
     */
    'water'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsRegenerationDataAnyOf1CFS
     */
    'daWater'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsRegenerationDataAnyOf1CFS
     */
    'caustic'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsRegenerationDataAnyOf1CFS
     */
    'acidicChemical'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsRegenerationDataAnyOf1CFS
     */
    'enzymaticConsumption'?: number;
}
/**
 * 
 * @export
 * @interface SystemStatsRegenerationDataAnyOfCBS
 */
export interface SystemStatsRegenerationDataAnyOfCBS {
    /**
     * 
     * @type {string}
     * @memberof SystemStatsRegenerationDataAnyOfCBS
     */
    'week'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemStatsRegenerationDataAnyOfCBS
     */
    'month'?: string;
    /**
     * 
     * @type {string}
     * @memberof SystemStatsRegenerationDataAnyOfCBS
     */
    'year'?: string;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsRegenerationDataAnyOfCBS
     */
    'water'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsRegenerationDataAnyOfCBS
     */
    'daWater'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsRegenerationDataAnyOfCBS
     */
    'electrical'?: number;
    /**
     * 
     * @type {number}
     * @memberof SystemStatsRegenerationDataAnyOfCBS
     */
    'actualStabilizationCost'?: number;
}
/**
 * 
 * @export
 * @interface TelemetryDataExport
 */
export interface TelemetryDataExport {
    /**
     * 
     * @type {string}
     * @memberof TelemetryDataExport
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryDataExport
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryDataExport
     */
    'generatedOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryDataExport
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryDataExport
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryDataExport
     */
    'siteId': string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryDataExport
     */
    'systemId': string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryDataExport
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryDataExport
     */
    'type'?: TelemetryDataExportTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TelemetryDataExport
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryDataExport
     */
    'endTime': string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryDataExport
     */
    'exportS3Key'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryDataExport
     */
    'exportStatus'?: TelemetryDataExportExportStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TelemetryDataExport
     */
    'errorDetails'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryDataExport
     */
    'statusLastUpdate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryDataExport
     */
    'exportDownloadUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryDataExport
     */
    'response'?: string;
}

export const TelemetryDataExportTypeEnum = {
    AllData: 'AllData',
    ProfiFilter: 'Profi_filter',
    CbsFilter: 'CBS_filter',
    CfsFilter: 'CFS_filter',
    KerafluxFilter: 'Keraflux_filter'
} as const;

export type TelemetryDataExportTypeEnum = typeof TelemetryDataExportTypeEnum[keyof typeof TelemetryDataExportTypeEnum];
export const TelemetryDataExportExportStatusEnum = {
    InProgress: 'In-progress',
    Completed: 'Completed',
    Error: 'Error'
} as const;

export type TelemetryDataExportExportStatusEnum = typeof TelemetryDataExportExportStatusEnum[keyof typeof TelemetryDataExportExportStatusEnum];

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'status': UserStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'sub': string;
}

export const UserStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type UserStatusEnum = typeof UserStatusEnum[keyof typeof UserStatusEnum];

/**
 * 
 * @export
 * @interface UserAllOf
 */
export interface UserAllOf {
    /**
     * 
     * @type {string}
     * @memberof UserAllOf
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof UserAllOf
     */
    'sub': string;
}
/**
 * 
 * @export
 * @interface UserBase
 */
export interface UserBase {
    /**
     * 
     * @type {string}
     * @memberof UserBase
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBase
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserBase
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBase
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBase
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBase
     */
    'status': UserBaseStatusEnum;
}

export const UserBaseStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type UserBaseStatusEnum = typeof UserBaseStatusEnum[keyof typeof UserBaseStatusEnum];

/**
 * 
 * @export
 * @interface UserConfirmForgotPassword
 */
export interface UserConfirmForgotPassword {
    /**
     * 
     * @type {string}
     * @memberof UserConfirmForgotPassword
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof UserConfirmForgotPassword
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface UserLiveStatus
 */
export interface UserLiveStatus {
    /**
     * 
     * @type {string}
     * @memberof UserLiveStatus
     */
    'lastActivityRouteKey': string;
    /**
     * 
     * @type {string}
     * @memberof UserLiveStatus
     */
    'lastActivityTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof UserLiveStatus
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface UserLiveStatusAllOf
 */
export interface UserLiveStatusAllOf {
    /**
     * 
     * @type {string}
     * @memberof UserLiveStatusAllOf
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface UserLiveStatusBase
 */
export interface UserLiveStatusBase {
    /**
     * 
     * @type {string}
     * @memberof UserLiveStatusBase
     */
    'lastActivityRouteKey': string;
    /**
     * 
     * @type {string}
     * @memberof UserLiveStatusBase
     */
    'lastActivityTimestamp': string;
}
/**
 * 
 * @export
 * @interface UserPassword
 */
export interface UserPassword {
    /**
     * 
     * @type {string}
     * @memberof UserPassword
     */
    'password': string;
}

/**
 * AlertApi - axios parameter creator
 * @export
 */
export const AlertApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Update the status of an alert for a user
         * @summary Add alert dismissal
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAlertDismissal: async (inlineObject: InlineObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject' is not null or undefined
            assertParamExists('addAlertDismissal', 'inlineObject', inlineObject)
            const localVarPath = `/alerts/dismissal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Active Alerts
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [siteId] Site Id
         * @param {string} [systemId] System Id
         * @param {string} [alertType] Type
         * @param {string} [fromDate] From datetime
         * @param {string} [toDate] To datetime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveAlerts: async (skip?: number, limit?: number, companyId?: string, siteId?: string, systemId?: string, alertType?: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/alerts/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (systemId !== undefined) {
                localVarQueryParameter['systemId'] = systemId;
            }

            if (alertType !== undefined) {
                localVarQueryParameter['alertType'] = alertType;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the count of active alerts
         * @param {string} [companyId] Company Id
         * @param {string} [siteId] Site Id
         * @param {string} [systemId] System Id
         * @param {string} [alertType] Type
         * @param {string} [fromDate] From datetime
         * @param {string} [toDate] To datetime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveAlertsCount: async (companyId?: string, siteId?: string, systemId?: string, alertType?: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/alerts/activeCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (systemId !== undefined) {
                localVarQueryParameter['systemId'] = systemId;
            }

            if (alertType !== undefined) {
                localVarQueryParameter['alertType'] = alertType;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get alert type mappings
         * @summary Get alert type mappings download url
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertTypeMappings: async (systemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('getAlertTypeMappings', 'systemId', systemId)
            const localVarPath = `/alerts/mapping/{systemId}/url`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get upload url for alert types
         * @summary Get upload url for alert types
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertTypeUploadUrl: async (systemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('getAlertTypeUploadUrl', 'systemId', systemId)
            const localVarPath = `/alerts/mapping/{systemId}/url`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Alerts History
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [siteId] Site Id
         * @param {string} [systemId] System Id
         * @param {string} [alertType] Type
         * @param {string} [fromDate] From datetime
         * @param {string} [toDate] To datetime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertsHistory: async (skip?: number, limit?: number, companyId?: string, siteId?: string, systemId?: string, alertType?: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/alerts/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (systemId !== undefined) {
                localVarQueryParameter['systemId'] = systemId;
            }

            if (alertType !== undefined) {
                localVarQueryParameter['alertType'] = alertType;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Process alert types
         * @summary Process alert types
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAlertTypes: async (systemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('processAlertTypes', 'systemId', systemId)
            const localVarPath = `/alerts/mapping/{systemId}/process`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlertApi - functional programming interface
 * @export
 */
export const AlertApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AlertApiAxiosParamCreator(configuration)
    return {
        /**
         * Update the status of an alert for a user
         * @summary Add alert dismissal
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAlertDismissal(inlineObject: InlineObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlertDismissal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAlertDismissal(inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Active Alerts
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [siteId] Site Id
         * @param {string} [systemId] System Id
         * @param {string} [alertType] Type
         * @param {string} [fromDate] From datetime
         * @param {string} [toDate] To datetime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveAlerts(skip?: number, limit?: number, companyId?: string, siteId?: string, systemId?: string, alertType?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Array<object>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveAlerts(skip, limit, companyId, siteId, systemId, alertType, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the count of active alerts
         * @param {string} [companyId] Company Id
         * @param {string} [siteId] Site Id
         * @param {string} [systemId] System Id
         * @param {string} [alertType] Type
         * @param {string} [fromDate] From datetime
         * @param {string} [toDate] To datetime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveAlertsCount(companyId?: string, siteId?: string, systemId?: string, alertType?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AlertCount>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveAlertsCount(companyId, siteId, systemId, alertType, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get alert type mappings
         * @summary Get alert type mappings download url
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlertTypeMappings(systemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlertMappingUrl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAlertTypeMappings(systemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get upload url for alert types
         * @summary Get upload url for alert types
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlertTypeUploadUrl(systemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlertMappingPutData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAlertTypeUploadUrl(systemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Alerts History
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [siteId] Site Id
         * @param {string} [systemId] System Id
         * @param {string} [alertType] Type
         * @param {string} [fromDate] From datetime
         * @param {string} [toDate] To datetime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlertsHistory(skip?: number, limit?: number, companyId?: string, siteId?: string, systemId?: string, alertType?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Array<object>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAlertsHistory(skip, limit, companyId, siteId, systemId, alertType, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Process alert types
         * @summary Process alert types
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processAlertTypes(systemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processAlertTypes(systemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AlertApi - factory interface
 * @export
 */
export const AlertApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AlertApiFp(configuration)
    return {
        /**
         * Update the status of an alert for a user
         * @summary Add alert dismissal
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAlertDismissal(inlineObject: InlineObject, options?: any): AxiosPromise<AlertDismissal> {
            return localVarFp.addAlertDismissal(inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Active Alerts
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [siteId] Site Id
         * @param {string} [systemId] System Id
         * @param {string} [alertType] Type
         * @param {string} [fromDate] From datetime
         * @param {string} [toDate] To datetime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveAlerts(skip?: number, limit?: number, companyId?: string, siteId?: string, systemId?: string, alertType?: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<Array<object>>> {
            return localVarFp.getActiveAlerts(skip, limit, companyId, siteId, systemId, alertType, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the count of active alerts
         * @param {string} [companyId] Company Id
         * @param {string} [siteId] Site Id
         * @param {string} [systemId] System Id
         * @param {string} [alertType] Type
         * @param {string} [fromDate] From datetime
         * @param {string} [toDate] To datetime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveAlertsCount(companyId?: string, siteId?: string, systemId?: string, alertType?: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<AlertCount>> {
            return localVarFp.getActiveAlertsCount(companyId, siteId, systemId, alertType, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Get alert type mappings
         * @summary Get alert type mappings download url
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertTypeMappings(systemId: string, options?: any): AxiosPromise<AlertMappingUrl> {
            return localVarFp.getAlertTypeMappings(systemId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get upload url for alert types
         * @summary Get upload url for alert types
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertTypeUploadUrl(systemId: string, options?: any): AxiosPromise<AlertMappingPutData> {
            return localVarFp.getAlertTypeUploadUrl(systemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Alerts History
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [siteId] Site Id
         * @param {string} [systemId] System Id
         * @param {string} [alertType] Type
         * @param {string} [fromDate] From datetime
         * @param {string} [toDate] To datetime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertsHistory(skip?: number, limit?: number, companyId?: string, siteId?: string, systemId?: string, alertType?: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<Array<object>>> {
            return localVarFp.getAlertsHistory(skip, limit, companyId, siteId, systemId, alertType, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Process alert types
         * @summary Process alert types
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAlertTypes(systemId: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.processAlertTypes(systemId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AlertApi - object-oriented interface
 * @export
 * @class AlertApi
 * @extends {BaseAPI}
 */
export class AlertApi extends BaseAPI {
    /**
     * Update the status of an alert for a user
     * @summary Add alert dismissal
     * @param {InlineObject} inlineObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertApi
     */
    public addAlertDismissal(inlineObject: InlineObject, options?: AxiosRequestConfig) {
        return AlertApiFp(this.configuration).addAlertDismissal(inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Active Alerts
     * @param {number} [skip] Number of rows to skip
     * @param {number} [limit] Max number of rows to return
     * @param {string} [companyId] Company Id
     * @param {string} [siteId] Site Id
     * @param {string} [systemId] System Id
     * @param {string} [alertType] Type
     * @param {string} [fromDate] From datetime
     * @param {string} [toDate] To datetime
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertApi
     */
    public getActiveAlerts(skip?: number, limit?: number, companyId?: string, siteId?: string, systemId?: string, alertType?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return AlertApiFp(this.configuration).getActiveAlerts(skip, limit, companyId, siteId, systemId, alertType, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the count of active alerts
     * @param {string} [companyId] Company Id
     * @param {string} [siteId] Site Id
     * @param {string} [systemId] System Id
     * @param {string} [alertType] Type
     * @param {string} [fromDate] From datetime
     * @param {string} [toDate] To datetime
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertApi
     */
    public getActiveAlertsCount(companyId?: string, siteId?: string, systemId?: string, alertType?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return AlertApiFp(this.configuration).getActiveAlertsCount(companyId, siteId, systemId, alertType, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get alert type mappings
     * @summary Get alert type mappings download url
     * @param {string} systemId System Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertApi
     */
    public getAlertTypeMappings(systemId: string, options?: AxiosRequestConfig) {
        return AlertApiFp(this.configuration).getAlertTypeMappings(systemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get upload url for alert types
     * @summary Get upload url for alert types
     * @param {string} systemId System Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertApi
     */
    public getAlertTypeUploadUrl(systemId: string, options?: AxiosRequestConfig) {
        return AlertApiFp(this.configuration).getAlertTypeUploadUrl(systemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Alerts History
     * @param {number} [skip] Number of rows to skip
     * @param {number} [limit] Max number of rows to return
     * @param {string} [companyId] Company Id
     * @param {string} [siteId] Site Id
     * @param {string} [systemId] System Id
     * @param {string} [alertType] Type
     * @param {string} [fromDate] From datetime
     * @param {string} [toDate] To datetime
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertApi
     */
    public getAlertsHistory(skip?: number, limit?: number, companyId?: string, siteId?: string, systemId?: string, alertType?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return AlertApiFp(this.configuration).getAlertsHistory(skip, limit, companyId, siteId, systemId, alertType, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Process alert types
     * @summary Process alert types
     * @param {string} systemId System Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertApi
     */
    public processAlertTypes(systemId: string, options?: AxiosRequestConfig) {
        return AlertApiFp(this.configuration).processAlertTypes(systemId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnalyticsApi - axios parameter creator
 * @export
 */
export const AnalyticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return analytic information by company site id
         * @summary Analytics
         * @param {string} companySiteId Company Site Id
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {'hourly' | 'daily' | 'weekly' | 'monthly'} aggregation 
         * @param {string} [systemIds] List of systemIds separated by commas
         * @param {string} [beerBrands] List of beer brands separated by commas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsByCompanySiteId: async (companySiteId: string, startDate: string, endDate: string, aggregation: 'hourly' | 'daily' | 'weekly' | 'monthly', systemIds?: string, beerBrands?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companySiteId' is not null or undefined
            assertParamExists('getAnalyticsByCompanySiteId', 'companySiteId', companySiteId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAnalyticsByCompanySiteId', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAnalyticsByCompanySiteId', 'endDate', endDate)
            // verify required parameter 'aggregation' is not null or undefined
            assertParamExists('getAnalyticsByCompanySiteId', 'aggregation', aggregation)
            const localVarPath = `/analytics/{companySiteId}`
                .replace(`{${"companySiteId"}}`, encodeURIComponent(String(companySiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (aggregation !== undefined) {
                localVarQueryParameter['aggregation'] = aggregation;
            }

            if (systemIds !== undefined) {
                localVarQueryParameter['systemIds'] = systemIds;
            }

            if (beerBrands !== undefined) {
                localVarQueryParameter['beerBrands'] = beerBrands;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve Export Pre-signed URL for Analyticss Data.  Note: Either `systemIds` OR `beerBrands` OR both must be provided.
         * @summary Analytics Export
         * @param {string} companySiteId Company Site Id
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} kpiList List of KPIs separated by commas
         * @param {string} [systemIds] List of systemIds separated by commas
         * @param {string} [beerBrands] List of beer brands separated by commas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsExport: async (companySiteId: string, startDate: string, endDate: string, kpiList: string, systemIds?: string, beerBrands?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companySiteId' is not null or undefined
            assertParamExists('getAnalyticsExport', 'companySiteId', companySiteId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAnalyticsExport', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAnalyticsExport', 'endDate', endDate)
            // verify required parameter 'kpiList' is not null or undefined
            assertParamExists('getAnalyticsExport', 'kpiList', kpiList)
            const localVarPath = `/analytics/{companySiteId}/export`
                .replace(`{${"companySiteId"}}`, encodeURIComponent(String(companySiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (systemIds !== undefined) {
                localVarQueryParameter['systemIds'] = systemIds;
            }

            if (beerBrands !== undefined) {
                localVarQueryParameter['beerBrands'] = beerBrands;
            }

            if (kpiList !== undefined) {
                localVarQueryParameter['kpiList'] = kpiList;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyticsApi - functional programming interface
 * @export
 */
export const AnalyticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalyticsApiAxiosParamCreator(configuration)
    return {
        /**
         * Return analytic information by company site id
         * @summary Analytics
         * @param {string} companySiteId Company Site Id
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {'hourly' | 'daily' | 'weekly' | 'monthly'} aggregation 
         * @param {string} [systemIds] List of systemIds separated by commas
         * @param {string} [beerBrands] List of beer brands separated by commas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalyticsByCompanySiteId(companySiteId: string, startDate: string, endDate: string, aggregation: 'hourly' | 'daily' | 'weekly' | 'monthly', systemIds?: string, beerBrands?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Analytic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalyticsByCompanySiteId(companySiteId, startDate, endDate, aggregation, systemIds, beerBrands, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve Export Pre-signed URL for Analyticss Data.  Note: Either `systemIds` OR `beerBrands` OR both must be provided.
         * @summary Analytics Export
         * @param {string} companySiteId Company Site Id
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} kpiList List of KPIs separated by commas
         * @param {string} [systemIds] List of systemIds separated by commas
         * @param {string} [beerBrands] List of beer brands separated by commas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalyticsExport(companySiteId: string, startDate: string, endDate: string, kpiList: string, systemIds?: string, beerBrands?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalyticsExport(companySiteId, startDate, endDate, kpiList, systemIds, beerBrands, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalyticsApi - factory interface
 * @export
 */
export const AnalyticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalyticsApiFp(configuration)
    return {
        /**
         * Return analytic information by company site id
         * @summary Analytics
         * @param {string} companySiteId Company Site Id
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {'hourly' | 'daily' | 'weekly' | 'monthly'} aggregation 
         * @param {string} [systemIds] List of systemIds separated by commas
         * @param {string} [beerBrands] List of beer brands separated by commas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsByCompanySiteId(companySiteId: string, startDate: string, endDate: string, aggregation: 'hourly' | 'daily' | 'weekly' | 'monthly', systemIds?: string, beerBrands?: string, options?: any): AxiosPromise<Analytic> {
            return localVarFp.getAnalyticsByCompanySiteId(companySiteId, startDate, endDate, aggregation, systemIds, beerBrands, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve Export Pre-signed URL for Analyticss Data.  Note: Either `systemIds` OR `beerBrands` OR both must be provided.
         * @summary Analytics Export
         * @param {string} companySiteId Company Site Id
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} kpiList List of KPIs separated by commas
         * @param {string} [systemIds] List of systemIds separated by commas
         * @param {string} [beerBrands] List of beer brands separated by commas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsExport(companySiteId: string, startDate: string, endDate: string, kpiList: string, systemIds?: string, beerBrands?: string, options?: any): AxiosPromise<AnalyticExport> {
            return localVarFp.getAnalyticsExport(companySiteId, startDate, endDate, kpiList, systemIds, beerBrands, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalyticsApi - object-oriented interface
 * @export
 * @class AnalyticsApi
 * @extends {BaseAPI}
 */
export class AnalyticsApi extends BaseAPI {
    /**
     * Return analytic information by company site id
     * @summary Analytics
     * @param {string} companySiteId Company Site Id
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {'hourly' | 'daily' | 'weekly' | 'monthly'} aggregation 
     * @param {string} [systemIds] List of systemIds separated by commas
     * @param {string} [beerBrands] List of beer brands separated by commas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getAnalyticsByCompanySiteId(companySiteId: string, startDate: string, endDate: string, aggregation: 'hourly' | 'daily' | 'weekly' | 'monthly', systemIds?: string, beerBrands?: string, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).getAnalyticsByCompanySiteId(companySiteId, startDate, endDate, aggregation, systemIds, beerBrands, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve Export Pre-signed URL for Analyticss Data.  Note: Either `systemIds` OR `beerBrands` OR both must be provided.
     * @summary Analytics Export
     * @param {string} companySiteId Company Site Id
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {string} kpiList List of KPIs separated by commas
     * @param {string} [systemIds] List of systemIds separated by commas
     * @param {string} [beerBrands] List of beer brands separated by commas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getAnalyticsExport(companySiteId: string, startDate: string, endDate: string, kpiList: string, systemIds?: string, beerBrands?: string, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).getAnalyticsExport(companySiteId, startDate, endDate, kpiList, systemIds, beerBrands, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AreaApi - axios parameter creator
 * @export
 */
export const AreaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new Area
         * @param {AreaBase} areaBase Area to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createArea: async (areaBase: AreaBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaBase' is not null or undefined
            assertParamExists('createArea', 'areaBase', areaBase)
            const localVarPath = `/areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(areaBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an existing Area
         * @param {string} areaId Area Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArea: async (areaId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('deleteArea', 'areaId', areaId)
            const localVarPath = `/areas/{areaId}`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Area Details
         * @summary Get Area Details.
         * @param {string} areaId Area Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArea: async (areaId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('getArea', 'areaId', areaId)
            const localVarPath = `/areas/{areaId}`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Area
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [companySiteId] Company Site Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAreas: async (skip?: number, limit?: number, companyId?: string, companySiteId?: string, status?: 'active' | 'inactive', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (companySiteId !== undefined) {
                localVarQueryParameter['companySiteId'] = companySiteId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing Area
         * @param {string} areaId Area Id
         * @param {AreaBase} areaBase Area to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArea: async (areaId: string, areaBase: AreaBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('updateArea', 'areaId', areaId)
            // verify required parameter 'areaBase' is not null or undefined
            assertParamExists('updateArea', 'areaBase', areaBase)
            const localVarPath = `/areas/{areaId}`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(areaBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AreaApi - functional programming interface
 * @export
 */
export const AreaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AreaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new Area
         * @param {AreaBase} areaBase Area to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createArea(areaBase: AreaBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Area>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createArea(areaBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an existing Area
         * @param {string} areaId Area Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteArea(areaId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Area>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteArea(areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Area Details
         * @summary Get Area Details.
         * @param {string} areaId Area Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArea(areaId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Area>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArea(areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Area
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [companySiteId] Company Site Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAreas(skip?: number, limit?: number, companyId?: string, companySiteId?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Area>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAreas(skip, limit, companyId, companySiteId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing Area
         * @param {string} areaId Area Id
         * @param {AreaBase} areaBase Area to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateArea(areaId: string, areaBase: AreaBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Area>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateArea(areaId, areaBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AreaApi - factory interface
 * @export
 */
export const AreaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AreaApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new Area
         * @param {AreaBase} areaBase Area to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createArea(areaBase: AreaBase, options?: any): AxiosPromise<Area> {
            return localVarFp.createArea(areaBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an existing Area
         * @param {string} areaId Area Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArea(areaId: string, options?: any): AxiosPromise<Area> {
            return localVarFp.deleteArea(areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Area Details
         * @summary Get Area Details.
         * @param {string} areaId Area Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArea(areaId: string, options?: any): AxiosPromise<Area> {
            return localVarFp.getArea(areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Area
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [companySiteId] Company Site Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAreas(skip?: number, limit?: number, companyId?: string, companySiteId?: string, status?: 'active' | 'inactive', options?: any): AxiosPromise<Array<Area>> {
            return localVarFp.getAreas(skip, limit, companyId, companySiteId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing Area
         * @param {string} areaId Area Id
         * @param {AreaBase} areaBase Area to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArea(areaId: string, areaBase: AreaBase, options?: any): AxiosPromise<Area> {
            return localVarFp.updateArea(areaId, areaBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AreaApi - object-oriented interface
 * @export
 * @class AreaApi
 * @extends {BaseAPI}
 */
export class AreaApi extends BaseAPI {
    /**
     * 
     * @summary Create a new Area
     * @param {AreaBase} areaBase Area to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public createArea(areaBase: AreaBase, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).createArea(areaBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an existing Area
     * @param {string} areaId Area Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public deleteArea(areaId: string, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).deleteArea(areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Area Details
     * @summary Get Area Details.
     * @param {string} areaId Area Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public getArea(areaId: string, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).getArea(areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Area
     * @param {number} [skip] Number of rows to skip
     * @param {number} [limit] Max number of rows to return
     * @param {string} [companyId] Company Id
     * @param {string} [companySiteId] Company Site Id
     * @param {'active' | 'inactive'} [status] Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public getAreas(skip?: number, limit?: number, companyId?: string, companySiteId?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).getAreas(skip, limit, companyId, companySiteId, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing Area
     * @param {string} areaId Area Id
     * @param {AreaBase} areaBase Area to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public updateArea(areaId: string, areaBase: AreaBase, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).updateArea(areaId, areaBase, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BlockApi - axios parameter creator
 * @export
 */
export const BlockApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new block
         * @summary Create a new block.
         * @param {BlockInfoBase} blockInfoBase BlockInfo to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBlock: async (blockInfoBase: BlockInfoBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blockInfoBase' is not null or undefined
            assertParamExists('createBlock', 'blockInfoBase', blockInfoBase)
            const localVarPath = `/blocks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(blockInfoBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete block
         * @summary Delete block, suitable for testing purposes only, controlled by a permission.
         * @param {string} blockId block Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBlock: async (blockId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blockId' is not null or undefined
            assertParamExists('deleteBlock', 'blockId', blockId)
            const localVarPath = `/blocks/{blockId}`
                .replace(`{${"blockId"}}`, encodeURIComponent(String(blockId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * BlockInfo
         * @summary Get BlockInfo Details.
         * @param {string} blockId Block Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlockInfo: async (blockId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blockId' is not null or undefined
            assertParamExists('getBlockInfo', 'blockId', blockId)
            const localVarPath = `/blocks/{blockId}`
                .replace(`{${"blockId"}}`, encodeURIComponent(String(blockId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * BlockLiveKPI
         * @summary Get BlockLiveKPI Details.
         * @param {string} blockId BlockId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlockLiveKPI: async (blockId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blockId' is not null or undefined
            assertParamExists('getBlockLiveKPI', 'blockId', blockId)
            const localVarPath = `/blocks/{blockId}/liveKPI`
                .replace(`{${"blockId"}}`, encodeURIComponent(String(blockId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * BlockLiveStatus
         * @summary Get BlockLiveStatus Details.
         * @param {string} blockId Block Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlockLiveStatus: async (blockId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blockId' is not null or undefined
            assertParamExists('getBlockLiveStatus', 'blockId', blockId)
            const localVarPath = `/blocks/{blockId}/liveStatus`
                .replace(`{${"blockId"}}`, encodeURIComponent(String(blockId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of blocks with filter
         * @summary List of blocks with filter.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] 
         * @param {string} [companySiteId] 
         * @param {string} [areaId] 
         * @param {string} [systemId] 
         * @param {string} [description] 
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlocks: async (skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, systemId?: string, description?: string, status?: 'active' | 'inactive', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/blocks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (companySiteId !== undefined) {
                localVarQueryParameter['companySiteId'] = companySiteId;
            }

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }

            if (systemId !== undefined) {
                localVarQueryParameter['systemId'] = systemId;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing block
         * @summary Update an existing block.
         * @param {string} blockId block Id
         * @param {BlockInfoBase} blockInfoBase BlockInfo to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBlock: async (blockId: string, blockInfoBase: BlockInfoBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blockId' is not null or undefined
            assertParamExists('updateBlock', 'blockId', blockId)
            // verify required parameter 'blockInfoBase' is not null or undefined
            assertParamExists('updateBlock', 'blockInfoBase', blockInfoBase)
            const localVarPath = `/blocks/{blockId}`
                .replace(`{${"blockId"}}`, encodeURIComponent(String(blockId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(blockInfoBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlockApi - functional programming interface
 * @export
 */
export const BlockApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BlockApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new block
         * @summary Create a new block.
         * @param {BlockInfoBase} blockInfoBase BlockInfo to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBlock(blockInfoBase: BlockInfoBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBlock(blockInfoBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete block
         * @summary Delete block, suitable for testing purposes only, controlled by a permission.
         * @param {string} blockId block Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBlock(blockId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBlock(blockId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * BlockInfo
         * @summary Get BlockInfo Details.
         * @param {string} blockId Block Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBlockInfo(blockId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBlockInfo(blockId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * BlockLiveKPI
         * @summary Get BlockLiveKPI Details.
         * @param {string} blockId BlockId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBlockLiveKPI(blockId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockLiveKPI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBlockLiveKPI(blockId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * BlockLiveStatus
         * @summary Get BlockLiveStatus Details.
         * @param {string} blockId Block Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBlockLiveStatus(blockId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockLiveStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBlockLiveStatus(blockId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of blocks with filter
         * @summary List of blocks with filter.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] 
         * @param {string} [companySiteId] 
         * @param {string} [areaId] 
         * @param {string} [systemId] 
         * @param {string} [description] 
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBlocks(skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, systemId?: string, description?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BlockInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBlocks(skip, limit, companyId, companySiteId, areaId, systemId, description, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing block
         * @summary Update an existing block.
         * @param {string} blockId block Id
         * @param {BlockInfoBase} blockInfoBase BlockInfo to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBlock(blockId: string, blockInfoBase: BlockInfoBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBlock(blockId, blockInfoBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BlockApi - factory interface
 * @export
 */
export const BlockApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BlockApiFp(configuration)
    return {
        /**
         * Create a new block
         * @summary Create a new block.
         * @param {BlockInfoBase} blockInfoBase BlockInfo to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBlock(blockInfoBase: BlockInfoBase, options?: any): AxiosPromise<BlockInfo> {
            return localVarFp.createBlock(blockInfoBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete block
         * @summary Delete block, suitable for testing purposes only, controlled by a permission.
         * @param {string} blockId block Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBlock(blockId: string, options?: any): AxiosPromise<BlockInfo> {
            return localVarFp.deleteBlock(blockId, options).then((request) => request(axios, basePath));
        },
        /**
         * BlockInfo
         * @summary Get BlockInfo Details.
         * @param {string} blockId Block Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlockInfo(blockId: string, options?: any): AxiosPromise<BlockInfo> {
            return localVarFp.getBlockInfo(blockId, options).then((request) => request(axios, basePath));
        },
        /**
         * BlockLiveKPI
         * @summary Get BlockLiveKPI Details.
         * @param {string} blockId BlockId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlockLiveKPI(blockId: string, options?: any): AxiosPromise<BlockLiveKPI> {
            return localVarFp.getBlockLiveKPI(blockId, options).then((request) => request(axios, basePath));
        },
        /**
         * BlockLiveStatus
         * @summary Get BlockLiveStatus Details.
         * @param {string} blockId Block Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlockLiveStatus(blockId: string, options?: any): AxiosPromise<BlockLiveStatus> {
            return localVarFp.getBlockLiveStatus(blockId, options).then((request) => request(axios, basePath));
        },
        /**
         * List of blocks with filter
         * @summary List of blocks with filter.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] 
         * @param {string} [companySiteId] 
         * @param {string} [areaId] 
         * @param {string} [systemId] 
         * @param {string} [description] 
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlocks(skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, systemId?: string, description?: string, status?: 'active' | 'inactive', options?: any): AxiosPromise<Array<BlockInfo>> {
            return localVarFp.getBlocks(skip, limit, companyId, companySiteId, areaId, systemId, description, status, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing block
         * @summary Update an existing block.
         * @param {string} blockId block Id
         * @param {BlockInfoBase} blockInfoBase BlockInfo to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBlock(blockId: string, blockInfoBase: BlockInfoBase, options?: any): AxiosPromise<BlockInfo> {
            return localVarFp.updateBlock(blockId, blockInfoBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BlockApi - object-oriented interface
 * @export
 * @class BlockApi
 * @extends {BaseAPI}
 */
export class BlockApi extends BaseAPI {
    /**
     * Create a new block
     * @summary Create a new block.
     * @param {BlockInfoBase} blockInfoBase BlockInfo to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockApi
     */
    public createBlock(blockInfoBase: BlockInfoBase, options?: AxiosRequestConfig) {
        return BlockApiFp(this.configuration).createBlock(blockInfoBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete block
     * @summary Delete block, suitable for testing purposes only, controlled by a permission.
     * @param {string} blockId block Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockApi
     */
    public deleteBlock(blockId: string, options?: AxiosRequestConfig) {
        return BlockApiFp(this.configuration).deleteBlock(blockId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * BlockInfo
     * @summary Get BlockInfo Details.
     * @param {string} blockId Block Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockApi
     */
    public getBlockInfo(blockId: string, options?: AxiosRequestConfig) {
        return BlockApiFp(this.configuration).getBlockInfo(blockId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * BlockLiveKPI
     * @summary Get BlockLiveKPI Details.
     * @param {string} blockId BlockId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockApi
     */
    public getBlockLiveKPI(blockId: string, options?: AxiosRequestConfig) {
        return BlockApiFp(this.configuration).getBlockLiveKPI(blockId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * BlockLiveStatus
     * @summary Get BlockLiveStatus Details.
     * @param {string} blockId Block Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockApi
     */
    public getBlockLiveStatus(blockId: string, options?: AxiosRequestConfig) {
        return BlockApiFp(this.configuration).getBlockLiveStatus(blockId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of blocks with filter
     * @summary List of blocks with filter.
     * @param {number} [skip] Number of rows to skip
     * @param {number} [limit] Max number of rows to return
     * @param {string} [companyId] 
     * @param {string} [companySiteId] 
     * @param {string} [areaId] 
     * @param {string} [systemId] 
     * @param {string} [description] 
     * @param {'active' | 'inactive'} [status] Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockApi
     */
    public getBlocks(skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, systemId?: string, description?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig) {
        return BlockApiFp(this.configuration).getBlocks(skip, limit, companyId, companySiteId, areaId, systemId, description, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing block
     * @summary Update an existing block.
     * @param {string} blockId block Id
     * @param {BlockInfoBase} blockInfoBase BlockInfo to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockApi
     */
    public updateBlock(blockId: string, blockInfoBase: BlockInfoBase, options?: AxiosRequestConfig) {
        return BlockApiFp(this.configuration).updateBlock(blockId, blockInfoBase, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BrandApi - axios parameter creator
 * @export
 */
export const BrandApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new Brand
         * @param {BrandBase} brandBase Brand to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBrand: async (brandBase: BrandBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandBase' is not null or undefined
            assertParamExists('createBrand', 'brandBase', brandBase)
            const localVarPath = `/brands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brandBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an existing Brand
         * @param {string} brandId Brand Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBrand: async (brandId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('deleteBrand', 'brandId', brandId)
            const localVarPath = `/brands/{brandId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Brand Details
         * @summary Get Brand Details.
         * @param {string} brandId Brand Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrand: async (brandId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('getBrand', 'brandId', brandId)
            const localVarPath = `/brands/{brandId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Brand
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [companySiteId] Company Site Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrands: async (skip?: number, limit?: number, companyId?: string, companySiteId?: string, status?: 'active' | 'inactive', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (companySiteId !== undefined) {
                localVarQueryParameter['companySiteId'] = companySiteId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing Brand
         * @param {string} brandId Brand Id
         * @param {BrandBase} brandBase Brand to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBrand: async (brandId: string, brandBase: BrandBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('updateBrand', 'brandId', brandId)
            // verify required parameter 'brandBase' is not null or undefined
            assertParamExists('updateBrand', 'brandBase', brandBase)
            const localVarPath = `/brands/{brandId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brandBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrandApi - functional programming interface
 * @export
 */
export const BrandApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrandApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new Brand
         * @param {BrandBase} brandBase Brand to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBrand(brandBase: BrandBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Brand>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBrand(brandBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an existing Brand
         * @param {string} brandId Brand Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBrand(brandId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Brand>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBrand(brandId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Brand Details
         * @summary Get Brand Details.
         * @param {string} brandId Brand Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrand(brandId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Brand>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrand(brandId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Brand
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [companySiteId] Company Site Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrands(skip?: number, limit?: number, companyId?: string, companySiteId?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Brand>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrands(skip, limit, companyId, companySiteId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing Brand
         * @param {string} brandId Brand Id
         * @param {BrandBase} brandBase Brand to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBrand(brandId: string, brandBase: BrandBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Brand>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBrand(brandId, brandBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrandApi - factory interface
 * @export
 */
export const BrandApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrandApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new Brand
         * @param {BrandBase} brandBase Brand to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBrand(brandBase: BrandBase, options?: any): AxiosPromise<Brand> {
            return localVarFp.createBrand(brandBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an existing Brand
         * @param {string} brandId Brand Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBrand(brandId: string, options?: any): AxiosPromise<Brand> {
            return localVarFp.deleteBrand(brandId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Brand Details
         * @summary Get Brand Details.
         * @param {string} brandId Brand Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrand(brandId: string, options?: any): AxiosPromise<Brand> {
            return localVarFp.getBrand(brandId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Brand
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [companySiteId] Company Site Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrands(skip?: number, limit?: number, companyId?: string, companySiteId?: string, status?: 'active' | 'inactive', options?: any): AxiosPromise<Array<Brand>> {
            return localVarFp.getBrands(skip, limit, companyId, companySiteId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing Brand
         * @param {string} brandId Brand Id
         * @param {BrandBase} brandBase Brand to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBrand(brandId: string, brandBase: BrandBase, options?: any): AxiosPromise<Brand> {
            return localVarFp.updateBrand(brandId, brandBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrandApi - object-oriented interface
 * @export
 * @class BrandApi
 * @extends {BaseAPI}
 */
export class BrandApi extends BaseAPI {
    /**
     * 
     * @summary Create a new Brand
     * @param {BrandBase} brandBase Brand to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public createBrand(brandBase: BrandBase, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).createBrand(brandBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an existing Brand
     * @param {string} brandId Brand Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public deleteBrand(brandId: string, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).deleteBrand(brandId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Brand Details
     * @summary Get Brand Details.
     * @param {string} brandId Brand Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public getBrand(brandId: string, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).getBrand(brandId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Brand
     * @param {number} [skip] Number of rows to skip
     * @param {number} [limit] Max number of rows to return
     * @param {string} [companyId] Company Id
     * @param {string} [companySiteId] Company Site Id
     * @param {'active' | 'inactive'} [status] Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public getBrands(skip?: number, limit?: number, companyId?: string, companySiteId?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).getBrands(skip, limit, companyId, companySiteId, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing Brand
     * @param {string} brandId Brand Id
     * @param {BrandBase} brandBase Brand to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public updateBrand(brandId: string, brandBase: BrandBase, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).updateBrand(brandId, brandBase, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClusterApi - axios parameter creator
 * @export
 */
export const ClusterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new cluster
         * @summary Create a new cluster.
         * @param {ClusterInfoBase} clusterInfoBase Cluster to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCluster: async (clusterInfoBase: ClusterInfoBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterInfoBase' is not null or undefined
            assertParamExists('createCluster', 'clusterInfoBase', clusterInfoBase)
            const localVarPath = `/clusters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusterInfoBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete cluster
         * @summary Delete cluster, suitable for testing purposes only, controlled by a permission.
         * @param {string} clusterId cluster Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCluster: async (clusterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('deleteCluster', 'clusterId', clusterId)
            const localVarPath = `/clusters/{clusterId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ClusterInfo
         * @summary Get ClusterInfo Details.
         * @param {string} clusterId Cluster Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterInfo: async (clusterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('getClusterInfo', 'clusterId', clusterId)
            const localVarPath = `/clusters/{clusterId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Cluster Integrity Status
         * @summary Get Cluster Integrity Status.
         * @param {string} clusterId Cluster Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterIntegrityStatus: async (clusterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('getClusterIntegrityStatus', 'clusterId', clusterId)
            const localVarPath = `/clusters/{clusterId}/integrityStatus`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Cluster Live Status
         * @summary Get Cluster Live Status.
         * @param {string} clusterId Cluster Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterLiveStatus: async (clusterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('getClusterLiveStatus', 'clusterId', clusterId)
            const localVarPath = `/clusters/{clusterId}/liveStatus`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of clusters with filter
         * @summary List of clusters with filter.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] 
         * @param {string} [companySiteId] 
         * @param {string} [areaId] 
         * @param {string} [systemId] 
         * @param {string} [blockId] 
         * @param {'active' | 'inactive'} [status] cluster status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusters: async (skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, systemId?: string, blockId?: string, status?: 'active' | 'inactive', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clusters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (companySiteId !== undefined) {
                localVarQueryParameter['companySiteId'] = companySiteId;
            }

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }

            if (systemId !== undefined) {
                localVarQueryParameter['systemId'] = systemId;
            }

            if (blockId !== undefined) {
                localVarQueryParameter['blockId'] = blockId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get System Cluster Status
         * @summary Get System Cluster Status.
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemClusterStatus: async (systemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('getSystemClusterStatus', 'systemId', systemId)
            const localVarPath = `/systems/{systemId}/clusters`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing cluster
         * @summary Update an existing cluster.
         * @param {string} clusterId cluster Id
         * @param {ClusterInfoBase} clusterInfoBase Cluster to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCluster: async (clusterId: string, clusterInfoBase: ClusterInfoBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('updateCluster', 'clusterId', clusterId)
            // verify required parameter 'clusterInfoBase' is not null or undefined
            assertParamExists('updateCluster', 'clusterInfoBase', clusterInfoBase)
            const localVarPath = `/clusters/{clusterId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusterInfoBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Cluster Integrity Status
         * @summary Update an existing Cluster Integrity Status.
         * @param {string} clusterId Cluster Id
         * @param {ClusterIntegrityStatus} clusterIntegrityStatus Cluster Integrity Status to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClusterIntegrityStatus: async (clusterId: string, clusterIntegrityStatus: ClusterIntegrityStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('updateClusterIntegrityStatus', 'clusterId', clusterId)
            // verify required parameter 'clusterIntegrityStatus' is not null or undefined
            assertParamExists('updateClusterIntegrityStatus', 'clusterIntegrityStatus', clusterIntegrityStatus)
            const localVarPath = `/clusters/{clusterId}/integrityStatus`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusterIntegrityStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClusterApi - functional programming interface
 * @export
 */
export const ClusterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClusterApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new cluster
         * @summary Create a new cluster.
         * @param {ClusterInfoBase} clusterInfoBase Cluster to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCluster(clusterInfoBase: ClusterInfoBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCluster(clusterInfoBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete cluster
         * @summary Delete cluster, suitable for testing purposes only, controlled by a permission.
         * @param {string} clusterId cluster Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCluster(clusterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCluster(clusterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ClusterInfo
         * @summary Get ClusterInfo Details.
         * @param {string} clusterId Cluster Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusterInfo(clusterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusterInfo(clusterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Cluster Integrity Status
         * @summary Get Cluster Integrity Status.
         * @param {string} clusterId Cluster Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusterIntegrityStatus(clusterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterIntegrityStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusterIntegrityStatus(clusterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Cluster Live Status
         * @summary Get Cluster Live Status.
         * @param {string} clusterId Cluster Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusterLiveStatus(clusterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterLiveStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusterLiveStatus(clusterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of clusters with filter
         * @summary List of clusters with filter.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] 
         * @param {string} [companySiteId] 
         * @param {string} [areaId] 
         * @param {string} [systemId] 
         * @param {string} [blockId] 
         * @param {'active' | 'inactive'} [status] cluster status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusters(skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, systemId?: string, blockId?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClusterInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusters(skip, limit, companyId, companySiteId, areaId, systemId, blockId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get System Cluster Status
         * @summary Get System Cluster Status.
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemClusterStatus(systemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SystemClusterStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystemClusterStatus(systemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing cluster
         * @summary Update an existing cluster.
         * @param {string} clusterId cluster Id
         * @param {ClusterInfoBase} clusterInfoBase Cluster to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCluster(clusterId: string, clusterInfoBase: ClusterInfoBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCluster(clusterId, clusterInfoBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing Cluster Integrity Status
         * @summary Update an existing Cluster Integrity Status.
         * @param {string} clusterId Cluster Id
         * @param {ClusterIntegrityStatus} clusterIntegrityStatus Cluster Integrity Status to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClusterIntegrityStatus(clusterId: string, clusterIntegrityStatus: ClusterIntegrityStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterIntegrityStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClusterIntegrityStatus(clusterId, clusterIntegrityStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClusterApi - factory interface
 * @export
 */
export const ClusterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClusterApiFp(configuration)
    return {
        /**
         * Create a new cluster
         * @summary Create a new cluster.
         * @param {ClusterInfoBase} clusterInfoBase Cluster to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCluster(clusterInfoBase: ClusterInfoBase, options?: any): AxiosPromise<ClusterInfo> {
            return localVarFp.createCluster(clusterInfoBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete cluster
         * @summary Delete cluster, suitable for testing purposes only, controlled by a permission.
         * @param {string} clusterId cluster Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCluster(clusterId: string, options?: any): AxiosPromise<ClusterInfo> {
            return localVarFp.deleteCluster(clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * ClusterInfo
         * @summary Get ClusterInfo Details.
         * @param {string} clusterId Cluster Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterInfo(clusterId: string, options?: any): AxiosPromise<ClusterInfo> {
            return localVarFp.getClusterInfo(clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Cluster Integrity Status
         * @summary Get Cluster Integrity Status.
         * @param {string} clusterId Cluster Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterIntegrityStatus(clusterId: string, options?: any): AxiosPromise<ClusterIntegrityStatus> {
            return localVarFp.getClusterIntegrityStatus(clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Cluster Live Status
         * @summary Get Cluster Live Status.
         * @param {string} clusterId Cluster Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterLiveStatus(clusterId: string, options?: any): AxiosPromise<ClusterLiveStatus> {
            return localVarFp.getClusterLiveStatus(clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * List of clusters with filter
         * @summary List of clusters with filter.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] 
         * @param {string} [companySiteId] 
         * @param {string} [areaId] 
         * @param {string} [systemId] 
         * @param {string} [blockId] 
         * @param {'active' | 'inactive'} [status] cluster status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusters(skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, systemId?: string, blockId?: string, status?: 'active' | 'inactive', options?: any): AxiosPromise<Array<ClusterInfo>> {
            return localVarFp.getClusters(skip, limit, companyId, companySiteId, areaId, systemId, blockId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * Get System Cluster Status
         * @summary Get System Cluster Status.
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemClusterStatus(systemId: string, options?: any): AxiosPromise<Array<SystemClusterStatus>> {
            return localVarFp.getSystemClusterStatus(systemId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing cluster
         * @summary Update an existing cluster.
         * @param {string} clusterId cluster Id
         * @param {ClusterInfoBase} clusterInfoBase Cluster to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCluster(clusterId: string, clusterInfoBase: ClusterInfoBase, options?: any): AxiosPromise<ClusterInfo> {
            return localVarFp.updateCluster(clusterId, clusterInfoBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Cluster Integrity Status
         * @summary Update an existing Cluster Integrity Status.
         * @param {string} clusterId Cluster Id
         * @param {ClusterIntegrityStatus} clusterIntegrityStatus Cluster Integrity Status to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClusterIntegrityStatus(clusterId: string, clusterIntegrityStatus: ClusterIntegrityStatus, options?: any): AxiosPromise<ClusterIntegrityStatus> {
            return localVarFp.updateClusterIntegrityStatus(clusterId, clusterIntegrityStatus, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClusterApi - object-oriented interface
 * @export
 * @class ClusterApi
 * @extends {BaseAPI}
 */
export class ClusterApi extends BaseAPI {
    /**
     * Create a new cluster
     * @summary Create a new cluster.
     * @param {ClusterInfoBase} clusterInfoBase Cluster to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public createCluster(clusterInfoBase: ClusterInfoBase, options?: AxiosRequestConfig) {
        return ClusterApiFp(this.configuration).createCluster(clusterInfoBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete cluster
     * @summary Delete cluster, suitable for testing purposes only, controlled by a permission.
     * @param {string} clusterId cluster Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public deleteCluster(clusterId: string, options?: AxiosRequestConfig) {
        return ClusterApiFp(this.configuration).deleteCluster(clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ClusterInfo
     * @summary Get ClusterInfo Details.
     * @param {string} clusterId Cluster Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public getClusterInfo(clusterId: string, options?: AxiosRequestConfig) {
        return ClusterApiFp(this.configuration).getClusterInfo(clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Cluster Integrity Status
     * @summary Get Cluster Integrity Status.
     * @param {string} clusterId Cluster Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public getClusterIntegrityStatus(clusterId: string, options?: AxiosRequestConfig) {
        return ClusterApiFp(this.configuration).getClusterIntegrityStatus(clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Cluster Live Status
     * @summary Get Cluster Live Status.
     * @param {string} clusterId Cluster Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public getClusterLiveStatus(clusterId: string, options?: AxiosRequestConfig) {
        return ClusterApiFp(this.configuration).getClusterLiveStatus(clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of clusters with filter
     * @summary List of clusters with filter.
     * @param {number} [skip] Number of rows to skip
     * @param {number} [limit] Max number of rows to return
     * @param {string} [companyId] 
     * @param {string} [companySiteId] 
     * @param {string} [areaId] 
     * @param {string} [systemId] 
     * @param {string} [blockId] 
     * @param {'active' | 'inactive'} [status] cluster status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public getClusters(skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, systemId?: string, blockId?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig) {
        return ClusterApiFp(this.configuration).getClusters(skip, limit, companyId, companySiteId, areaId, systemId, blockId, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get System Cluster Status
     * @summary Get System Cluster Status.
     * @param {string} systemId System Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public getSystemClusterStatus(systemId: string, options?: AxiosRequestConfig) {
        return ClusterApiFp(this.configuration).getSystemClusterStatus(systemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing cluster
     * @summary Update an existing cluster.
     * @param {string} clusterId cluster Id
     * @param {ClusterInfoBase} clusterInfoBase Cluster to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public updateCluster(clusterId: string, clusterInfoBase: ClusterInfoBase, options?: AxiosRequestConfig) {
        return ClusterApiFp(this.configuration).updateCluster(clusterId, clusterInfoBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Cluster Integrity Status
     * @summary Update an existing Cluster Integrity Status.
     * @param {string} clusterId Cluster Id
     * @param {ClusterIntegrityStatus} clusterIntegrityStatus Cluster Integrity Status to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public updateClusterIntegrityStatus(clusterId: string, clusterIntegrityStatus: ClusterIntegrityStatus, options?: AxiosRequestConfig) {
        return ClusterApiFp(this.configuration).updateClusterIntegrityStatus(clusterId, clusterIntegrityStatus, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ColumnApi - axios parameter creator
 * @export
 */
export const ColumnApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Column
         * @summary Create a new Column.
         * @param {ColumnBase} columnBase Column to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createColumn: async (columnBase: ColumnBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'columnBase' is not null or undefined
            assertParamExists('createColumn', 'columnBase', columnBase)
            const localVarPath = `/columns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(columnBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Column
         * @summary Delete column, suitable for testing purposes only, controlled by a permission.
         * @param {string} columnId Column Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteColumn: async (columnId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'columnId' is not null or undefined
            assertParamExists('deleteColumn', 'columnId', columnId)
            const localVarPath = `/columns/{columnId}`
                .replace(`{${"columnId"}}`, encodeURIComponent(String(columnId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Column
         * @summary Get Column Details.
         * @param {string} columnId column Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getColumn: async (columnId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'columnId' is not null or undefined
            assertParamExists('getColumn', 'columnId', columnId)
            const localVarPath = `/columns/{columnId}`
                .replace(`{${"columnId"}}`, encodeURIComponent(String(columnId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ColumnRunningStatus
         * @summary Get ColumnRunningStatus Details.
         * @param {string} columnId Column Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getColumnRunningStatus: async (columnId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'columnId' is not null or undefined
            assertParamExists('getColumnRunningStatus', 'columnId', columnId)
            const localVarPath = `/columns/{columnId}/recentRunningStatus`
                .replace(`{${"columnId"}}`, encodeURIComponent(String(columnId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of columns with filter
         * @summary List of columns with filter.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] 
         * @param {string} [companySiteId] 
         * @param {string} [areaId] 
         * @param {string} [systemId] 
         * @param {string} [name] 
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getColumns: async (skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, systemId?: string, name?: string, status?: 'active' | 'inactive', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/columns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (companySiteId !== undefined) {
                localVarQueryParameter['companySiteId'] = companySiteId;
            }

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }

            if (systemId !== undefined) {
                localVarQueryParameter['systemId'] = systemId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing column
         * @summary Update an existing column.
         * @param {string} columnId Column Id
         * @param {ColumnBase} columnBase Column to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateColumn: async (columnId: string, columnBase: ColumnBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'columnId' is not null or undefined
            assertParamExists('updateColumn', 'columnId', columnId)
            // verify required parameter 'columnBase' is not null or undefined
            assertParamExists('updateColumn', 'columnBase', columnBase)
            const localVarPath = `/columns/{columnId}`
                .replace(`{${"columnId"}}`, encodeURIComponent(String(columnId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(columnBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ColumnApi - functional programming interface
 * @export
 */
export const ColumnApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ColumnApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Column
         * @summary Create a new Column.
         * @param {ColumnBase} columnBase Column to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createColumn(columnBase: ColumnBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Column>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createColumn(columnBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Column
         * @summary Delete column, suitable for testing purposes only, controlled by a permission.
         * @param {string} columnId Column Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteColumn(columnId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Column>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteColumn(columnId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Column
         * @summary Get Column Details.
         * @param {string} columnId column Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getColumn(columnId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Column>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getColumn(columnId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ColumnRunningStatus
         * @summary Get ColumnRunningStatus Details.
         * @param {string} columnId Column Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getColumnRunningStatus(columnId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ColumnRecentStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getColumnRunningStatus(columnId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of columns with filter
         * @summary List of columns with filter.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] 
         * @param {string} [companySiteId] 
         * @param {string} [areaId] 
         * @param {string} [systemId] 
         * @param {string} [name] 
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getColumns(skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, systemId?: string, name?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Column>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getColumns(skip, limit, companyId, companySiteId, areaId, systemId, name, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing column
         * @summary Update an existing column.
         * @param {string} columnId Column Id
         * @param {ColumnBase} columnBase Column to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateColumn(columnId: string, columnBase: ColumnBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Column>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateColumn(columnId, columnBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ColumnApi - factory interface
 * @export
 */
export const ColumnApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ColumnApiFp(configuration)
    return {
        /**
         * Create a new Column
         * @summary Create a new Column.
         * @param {ColumnBase} columnBase Column to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createColumn(columnBase: ColumnBase, options?: any): AxiosPromise<Column> {
            return localVarFp.createColumn(columnBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Column
         * @summary Delete column, suitable for testing purposes only, controlled by a permission.
         * @param {string} columnId Column Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteColumn(columnId: string, options?: any): AxiosPromise<Column> {
            return localVarFp.deleteColumn(columnId, options).then((request) => request(axios, basePath));
        },
        /**
         * Column
         * @summary Get Column Details.
         * @param {string} columnId column Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getColumn(columnId: string, options?: any): AxiosPromise<Column> {
            return localVarFp.getColumn(columnId, options).then((request) => request(axios, basePath));
        },
        /**
         * ColumnRunningStatus
         * @summary Get ColumnRunningStatus Details.
         * @param {string} columnId Column Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getColumnRunningStatus(columnId: string, options?: any): AxiosPromise<ColumnRecentStatus> {
            return localVarFp.getColumnRunningStatus(columnId, options).then((request) => request(axios, basePath));
        },
        /**
         * List of columns with filter
         * @summary List of columns with filter.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] 
         * @param {string} [companySiteId] 
         * @param {string} [areaId] 
         * @param {string} [systemId] 
         * @param {string} [name] 
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getColumns(skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, systemId?: string, name?: string, status?: 'active' | 'inactive', options?: any): AxiosPromise<Array<Column>> {
            return localVarFp.getColumns(skip, limit, companyId, companySiteId, areaId, systemId, name, status, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing column
         * @summary Update an existing column.
         * @param {string} columnId Column Id
         * @param {ColumnBase} columnBase Column to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateColumn(columnId: string, columnBase: ColumnBase, options?: any): AxiosPromise<Column> {
            return localVarFp.updateColumn(columnId, columnBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ColumnApi - object-oriented interface
 * @export
 * @class ColumnApi
 * @extends {BaseAPI}
 */
export class ColumnApi extends BaseAPI {
    /**
     * Create a new Column
     * @summary Create a new Column.
     * @param {ColumnBase} columnBase Column to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColumnApi
     */
    public createColumn(columnBase: ColumnBase, options?: AxiosRequestConfig) {
        return ColumnApiFp(this.configuration).createColumn(columnBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Column
     * @summary Delete column, suitable for testing purposes only, controlled by a permission.
     * @param {string} columnId Column Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColumnApi
     */
    public deleteColumn(columnId: string, options?: AxiosRequestConfig) {
        return ColumnApiFp(this.configuration).deleteColumn(columnId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Column
     * @summary Get Column Details.
     * @param {string} columnId column Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColumnApi
     */
    public getColumn(columnId: string, options?: AxiosRequestConfig) {
        return ColumnApiFp(this.configuration).getColumn(columnId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ColumnRunningStatus
     * @summary Get ColumnRunningStatus Details.
     * @param {string} columnId Column Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColumnApi
     */
    public getColumnRunningStatus(columnId: string, options?: AxiosRequestConfig) {
        return ColumnApiFp(this.configuration).getColumnRunningStatus(columnId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of columns with filter
     * @summary List of columns with filter.
     * @param {number} [skip] Number of rows to skip
     * @param {number} [limit] Max number of rows to return
     * @param {string} [companyId] 
     * @param {string} [companySiteId] 
     * @param {string} [areaId] 
     * @param {string} [systemId] 
     * @param {string} [name] 
     * @param {'active' | 'inactive'} [status] Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColumnApi
     */
    public getColumns(skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, systemId?: string, name?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig) {
        return ColumnApiFp(this.configuration).getColumns(skip, limit, companyId, companySiteId, areaId, systemId, name, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing column
     * @summary Update an existing column.
     * @param {string} columnId Column Id
     * @param {ColumnBase} columnBase Column to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColumnApi
     */
    public updateColumn(columnId: string, columnBase: ColumnBase, options?: AxiosRequestConfig) {
        return ColumnApiFp(this.configuration).updateColumn(columnId, columnBase, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Company
         * @summary Create a new Company.
         * @param {CompanyBase} companyBase Company to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany: async (companyBase: CompanyBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyBase' is not null or undefined
            assertParamExists('createCompany', 'companyBase', companyBase)
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Company
         * @summary Delete Company, suitable for testing purposes only, controlled by a permission.
         * @param {string} companyId Company Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompany: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteCompany', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of companies with filter
         * @summary List of companies with filter.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [name] Company name starts with
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesList: async (skip?: number, limit?: number, name?: string, status?: 'active' | 'inactive', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Company Details
         * @summary Get Company Details.
         * @param {string} companyId Company Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompany', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Company Live Status
         * @summary Get Company Live Status.
         * @param {string} companyId Company Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyLiveStatus: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyLiveStatus', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/liveStatus`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Company
         * @summary Update an existing Company.
         * @param {string} companyId Company Id
         * @param {CompanyBase} companyBase Company to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany: async (companyId: string, companyBase: CompanyBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateCompany', 'companyId', companyId)
            // verify required parameter 'companyBase' is not null or undefined
            assertParamExists('updateCompany', 'companyBase', companyBase)
            const localVarPath = `/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Company Live Status
         * @summary Update an existing Company Live Status.
         * @param {string} companyId Company Id
         * @param {CompanyLiveStatusBase} companyLiveStatusBase Company Live Status to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyLiveStatus: async (companyId: string, companyLiveStatusBase: CompanyLiveStatusBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateCompanyLiveStatus', 'companyId', companyId)
            // verify required parameter 'companyLiveStatusBase' is not null or undefined
            assertParamExists('updateCompanyLiveStatus', 'companyLiveStatusBase', companyLiveStatusBase)
            const localVarPath = `/companies/{companyId}/liveStatus`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyLiveStatusBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Company
         * @summary Create a new Company.
         * @param {CompanyBase} companyBase Company to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompany(companyBase: CompanyBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompany(companyBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Company
         * @summary Delete Company, suitable for testing purposes only, controlled by a permission.
         * @param {string} companyId Company Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompany(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompany(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of companies with filter
         * @summary List of companies with filter.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [name] Company name starts with
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesList(skip?: number, limit?: number, name?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Company>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesList(skip, limit, name, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Company Details
         * @summary Get Company Details.
         * @param {string} companyId Company Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompany(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompany(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Company Live Status
         * @summary Get Company Live Status.
         * @param {string} companyId Company Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyLiveStatus(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyLiveStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyLiveStatus(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing Company
         * @summary Update an existing Company.
         * @param {string} companyId Company Id
         * @param {CompanyBase} companyBase Company to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompany(companyId: string, companyBase: CompanyBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompany(companyId, companyBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing Company Live Status
         * @summary Update an existing Company Live Status.
         * @param {string} companyId Company Id
         * @param {CompanyLiveStatusBase} companyLiveStatusBase Company Live Status to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyLiveStatus(companyId: string, companyLiveStatusBase: CompanyLiveStatusBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyLiveStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyLiveStatus(companyId, companyLiveStatusBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * Create a new Company
         * @summary Create a new Company.
         * @param {CompanyBase} companyBase Company to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany(companyBase: CompanyBase, options?: any): AxiosPromise<Company> {
            return localVarFp.createCompany(companyBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Company
         * @summary Delete Company, suitable for testing purposes only, controlled by a permission.
         * @param {string} companyId Company Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompany(companyId: string, options?: any): AxiosPromise<Company> {
            return localVarFp.deleteCompany(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * List of companies with filter
         * @summary List of companies with filter.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [name] Company name starts with
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesList(skip?: number, limit?: number, name?: string, status?: 'active' | 'inactive', options?: any): AxiosPromise<Array<Company>> {
            return localVarFp.getCompaniesList(skip, limit, name, status, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Company Details
         * @summary Get Company Details.
         * @param {string} companyId Company Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany(companyId: string, options?: any): AxiosPromise<Company> {
            return localVarFp.getCompany(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Company Live Status
         * @summary Get Company Live Status.
         * @param {string} companyId Company Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyLiveStatus(companyId: string, options?: any): AxiosPromise<CompanyLiveStatus> {
            return localVarFp.getCompanyLiveStatus(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Company
         * @summary Update an existing Company.
         * @param {string} companyId Company Id
         * @param {CompanyBase} companyBase Company to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany(companyId: string, companyBase: CompanyBase, options?: any): AxiosPromise<Company> {
            return localVarFp.updateCompany(companyId, companyBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Company Live Status
         * @summary Update an existing Company Live Status.
         * @param {string} companyId Company Id
         * @param {CompanyLiveStatusBase} companyLiveStatusBase Company Live Status to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyLiveStatus(companyId: string, companyLiveStatusBase: CompanyLiveStatusBase, options?: any): AxiosPromise<CompanyLiveStatus> {
            return localVarFp.updateCompanyLiveStatus(companyId, companyLiveStatusBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * Create a new Company
     * @summary Create a new Company.
     * @param {CompanyBase} companyBase Company to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public createCompany(companyBase: CompanyBase, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).createCompany(companyBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Company
     * @summary Delete Company, suitable for testing purposes only, controlled by a permission.
     * @param {string} companyId Company Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public deleteCompany(companyId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).deleteCompany(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of companies with filter
     * @summary List of companies with filter.
     * @param {number} [skip] Number of rows to skip
     * @param {number} [limit] Max number of rows to return
     * @param {string} [name] Company name starts with
     * @param {'active' | 'inactive'} [status] Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompaniesList(skip?: number, limit?: number, name?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getCompaniesList(skip, limit, name, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Company Details
     * @summary Get Company Details.
     * @param {string} companyId Company Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompany(companyId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getCompany(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Company Live Status
     * @summary Get Company Live Status.
     * @param {string} companyId Company Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompanyLiveStatus(companyId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getCompanyLiveStatus(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Company
     * @summary Update an existing Company.
     * @param {string} companyId Company Id
     * @param {CompanyBase} companyBase Company to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public updateCompany(companyId: string, companyBase: CompanyBase, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).updateCompany(companyId, companyBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Company Live Status
     * @summary Update an existing Company Live Status.
     * @param {string} companyId Company Id
     * @param {CompanyLiveStatusBase} companyLiveStatusBase Company Live Status to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public updateCompanyLiveStatus(companyId: string, companyLiveStatusBase: CompanyLiveStatusBase, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).updateCompanyLiveStatus(companyId, companyLiveStatusBase, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanySiteApi - axios parameter creator
 * @export
 */
export const CompanySiteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new Company Site
         * @param {CompanySiteBase} companySiteBase Company Site to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanySite: async (companySiteBase: CompanySiteBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companySiteBase' is not null or undefined
            assertParamExists('createCompanySite', 'companySiteBase', companySiteBase)
            const localVarPath = `/sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companySiteBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an existing Company Site
         * @param {string} companySiteId Company Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanySite: async (companySiteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companySiteId' is not null or undefined
            assertParamExists('deleteCompanySite', 'companySiteId', companySiteId)
            const localVarPath = `/sites/{companySiteId}`
                .replace(`{${"companySiteId"}}`, encodeURIComponent(String(companySiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Company Site Details
         * @summary Get Company Site Details.
         * @param {string} companySiteId Company Site Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySite: async (companySiteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companySiteId' is not null or undefined
            assertParamExists('getCompanySite', 'companySiteId', companySiteId)
            const localVarPath = `/sites/{companySiteId}`
                .replace(`{${"companySiteId"}}`, encodeURIComponent(String(companySiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get count of Company Sites
         * @summary Get count of Company Sites.
         * @param {string} companyId Company Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySiteCount: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanySiteCount', 'companyId', companyId)
            const localVarPath = `/sites/{companyId}/count`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Company Site Live KPI
         * @summary Get Company Site Live KPI.
         * @param {string} companySiteId Company Site Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySiteLiveKPI: async (companySiteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companySiteId' is not null or undefined
            assertParamExists('getCompanySiteLiveKPI', 'companySiteId', companySiteId)
            const localVarPath = `/sites/{companySiteId}/liveKPI`
                .replace(`{${"companySiteId"}}`, encodeURIComponent(String(companySiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Company Site Live Status
         * @summary Get Company Site Live Status.
         * @param {string} companySiteId Company Site Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySiteLiveStatus: async (companySiteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companySiteId' is not null or undefined
            assertParamExists('getCompanySiteLiveStatus', 'companySiteId', companySiteId)
            const localVarPath = `/sites/{companySiteId}/liveStatus`
                .replace(`{${"companySiteId"}}`, encodeURIComponent(String(companySiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Company Sites
         * @summary Get Company Sites.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [name] Site name starts with
         * @param {string} [group] Group Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySites: async (skip?: number, limit?: number, companyId?: string, name?: string, group?: string, status?: 'active' | 'inactive', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (group !== undefined) {
                localVarQueryParameter['group'] = group;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing Company Site
         * @param {string} companySiteId Company Id
         * @param {CompanySiteBase} companySiteBase Company Site to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanySite: async (companySiteId: string, companySiteBase: CompanySiteBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companySiteId' is not null or undefined
            assertParamExists('updateCompanySite', 'companySiteId', companySiteId)
            // verify required parameter 'companySiteBase' is not null or undefined
            assertParamExists('updateCompanySite', 'companySiteBase', companySiteBase)
            const localVarPath = `/sites/{companySiteId}`
                .replace(`{${"companySiteId"}}`, encodeURIComponent(String(companySiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companySiteBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Company Site Live KPI
         * @summary Update an existing Company Site Live KPI.
         * @param {string} companySiteId Company Site Id
         * @param {CompanySiteLiveKPIBase} companySiteLiveKPIBase Company Live Status to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanySiteLiveKPI: async (companySiteId: string, companySiteLiveKPIBase: CompanySiteLiveKPIBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companySiteId' is not null or undefined
            assertParamExists('updateCompanySiteLiveKPI', 'companySiteId', companySiteId)
            // verify required parameter 'companySiteLiveKPIBase' is not null or undefined
            assertParamExists('updateCompanySiteLiveKPI', 'companySiteLiveKPIBase', companySiteLiveKPIBase)
            const localVarPath = `/sites/{companySiteId}/liveKPI`
                .replace(`{${"companySiteId"}}`, encodeURIComponent(String(companySiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companySiteLiveKPIBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Company Site Live Status
         * @summary Update an existing Company Site Live Status.
         * @param {string} companySiteId Company Site Id
         * @param {CompanySiteLiveStatusBase} companySiteLiveStatusBase Company Site Live Status to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanySiteLiveStatus: async (companySiteId: string, companySiteLiveStatusBase: CompanySiteLiveStatusBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companySiteId' is not null or undefined
            assertParamExists('updateCompanySiteLiveStatus', 'companySiteId', companySiteId)
            // verify required parameter 'companySiteLiveStatusBase' is not null or undefined
            assertParamExists('updateCompanySiteLiveStatus', 'companySiteLiveStatusBase', companySiteLiveStatusBase)
            const localVarPath = `/sites/{companySiteId}/liveStatus`
                .replace(`{${"companySiteId"}}`, encodeURIComponent(String(companySiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companySiteLiveStatusBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanySiteApi - functional programming interface
 * @export
 */
export const CompanySiteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanySiteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new Company Site
         * @param {CompanySiteBase} companySiteBase Company Site to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompanySite(companySiteBase: CompanySiteBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompanySite(companySiteBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an existing Company Site
         * @param {string} companySiteId Company Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompanySite(companySiteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompanySite(companySiteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Company Site Details
         * @summary Get Company Site Details.
         * @param {string} companySiteId Company Site Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanySite(companySiteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanySite(companySiteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get count of Company Sites
         * @summary Get count of Company Sites.
         * @param {string} companyId Company Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanySiteCount(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanySiteCount(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Company Site Live KPI
         * @summary Get Company Site Live KPI.
         * @param {string} companySiteId Company Site Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanySiteLiveKPI(companySiteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySiteLiveKPI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanySiteLiveKPI(companySiteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Company Site Live Status
         * @summary Get Company Site Live Status.
         * @param {string} companySiteId Company Site Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanySiteLiveStatus(companySiteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySiteLiveStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanySiteLiveStatus(companySiteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Company Sites
         * @summary Get Company Sites.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [name] Site name starts with
         * @param {string} [group] Group Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanySites(skip?: number, limit?: number, companyId?: string, name?: string, group?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanySite>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanySites(skip, limit, companyId, name, group, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing Company Site
         * @param {string} companySiteId Company Id
         * @param {CompanySiteBase} companySiteBase Company Site to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanySite(companySiteId: string, companySiteBase: CompanySiteBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanySite(companySiteId, companySiteBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing Company Site Live KPI
         * @summary Update an existing Company Site Live KPI.
         * @param {string} companySiteId Company Site Id
         * @param {CompanySiteLiveKPIBase} companySiteLiveKPIBase Company Live Status to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanySiteLiveKPI(companySiteId: string, companySiteLiveKPIBase: CompanySiteLiveKPIBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySiteLiveKPI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanySiteLiveKPI(companySiteId, companySiteLiveKPIBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing Company Site Live Status
         * @summary Update an existing Company Site Live Status.
         * @param {string} companySiteId Company Site Id
         * @param {CompanySiteLiveStatusBase} companySiteLiveStatusBase Company Site Live Status to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanySiteLiveStatus(companySiteId: string, companySiteLiveStatusBase: CompanySiteLiveStatusBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySiteLiveStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanySiteLiveStatus(companySiteId, companySiteLiveStatusBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanySiteApi - factory interface
 * @export
 */
export const CompanySiteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanySiteApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new Company Site
         * @param {CompanySiteBase} companySiteBase Company Site to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanySite(companySiteBase: CompanySiteBase, options?: any): AxiosPromise<CompanySite> {
            return localVarFp.createCompanySite(companySiteBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an existing Company Site
         * @param {string} companySiteId Company Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanySite(companySiteId: string, options?: any): AxiosPromise<CompanySite> {
            return localVarFp.deleteCompanySite(companySiteId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Company Site Details
         * @summary Get Company Site Details.
         * @param {string} companySiteId Company Site Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySite(companySiteId: string, options?: any): AxiosPromise<CompanySite> {
            return localVarFp.getCompanySite(companySiteId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get count of Company Sites
         * @summary Get count of Company Sites.
         * @param {string} companyId Company Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySiteCount(companyId: string, options?: any): AxiosPromise<object> {
            return localVarFp.getCompanySiteCount(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Company Site Live KPI
         * @summary Get Company Site Live KPI.
         * @param {string} companySiteId Company Site Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySiteLiveKPI(companySiteId: string, options?: any): AxiosPromise<CompanySiteLiveKPI> {
            return localVarFp.getCompanySiteLiveKPI(companySiteId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Company Site Live Status
         * @summary Get Company Site Live Status.
         * @param {string} companySiteId Company Site Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySiteLiveStatus(companySiteId: string, options?: any): AxiosPromise<CompanySiteLiveStatus> {
            return localVarFp.getCompanySiteLiveStatus(companySiteId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Company Sites
         * @summary Get Company Sites.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [name] Site name starts with
         * @param {string} [group] Group Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySites(skip?: number, limit?: number, companyId?: string, name?: string, group?: string, status?: 'active' | 'inactive', options?: any): AxiosPromise<Array<CompanySite>> {
            return localVarFp.getCompanySites(skip, limit, companyId, name, group, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing Company Site
         * @param {string} companySiteId Company Id
         * @param {CompanySiteBase} companySiteBase Company Site to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanySite(companySiteId: string, companySiteBase: CompanySiteBase, options?: any): AxiosPromise<CompanySite> {
            return localVarFp.updateCompanySite(companySiteId, companySiteBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Company Site Live KPI
         * @summary Update an existing Company Site Live KPI.
         * @param {string} companySiteId Company Site Id
         * @param {CompanySiteLiveKPIBase} companySiteLiveKPIBase Company Live Status to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanySiteLiveKPI(companySiteId: string, companySiteLiveKPIBase: CompanySiteLiveKPIBase, options?: any): AxiosPromise<CompanySiteLiveKPI> {
            return localVarFp.updateCompanySiteLiveKPI(companySiteId, companySiteLiveKPIBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Company Site Live Status
         * @summary Update an existing Company Site Live Status.
         * @param {string} companySiteId Company Site Id
         * @param {CompanySiteLiveStatusBase} companySiteLiveStatusBase Company Site Live Status to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanySiteLiveStatus(companySiteId: string, companySiteLiveStatusBase: CompanySiteLiveStatusBase, options?: any): AxiosPromise<CompanySiteLiveStatus> {
            return localVarFp.updateCompanySiteLiveStatus(companySiteId, companySiteLiveStatusBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanySiteApi - object-oriented interface
 * @export
 * @class CompanySiteApi
 * @extends {BaseAPI}
 */
export class CompanySiteApi extends BaseAPI {
    /**
     * 
     * @summary Create a new Company Site
     * @param {CompanySiteBase} companySiteBase Company Site to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanySiteApi
     */
    public createCompanySite(companySiteBase: CompanySiteBase, options?: AxiosRequestConfig) {
        return CompanySiteApiFp(this.configuration).createCompanySite(companySiteBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an existing Company Site
     * @param {string} companySiteId Company Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanySiteApi
     */
    public deleteCompanySite(companySiteId: string, options?: AxiosRequestConfig) {
        return CompanySiteApiFp(this.configuration).deleteCompanySite(companySiteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Company Site Details
     * @summary Get Company Site Details.
     * @param {string} companySiteId Company Site Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanySiteApi
     */
    public getCompanySite(companySiteId: string, options?: AxiosRequestConfig) {
        return CompanySiteApiFp(this.configuration).getCompanySite(companySiteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get count of Company Sites
     * @summary Get count of Company Sites.
     * @param {string} companyId Company Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanySiteApi
     */
    public getCompanySiteCount(companyId: string, options?: AxiosRequestConfig) {
        return CompanySiteApiFp(this.configuration).getCompanySiteCount(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Company Site Live KPI
     * @summary Get Company Site Live KPI.
     * @param {string} companySiteId Company Site Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanySiteApi
     */
    public getCompanySiteLiveKPI(companySiteId: string, options?: AxiosRequestConfig) {
        return CompanySiteApiFp(this.configuration).getCompanySiteLiveKPI(companySiteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Company Site Live Status
     * @summary Get Company Site Live Status.
     * @param {string} companySiteId Company Site Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanySiteApi
     */
    public getCompanySiteLiveStatus(companySiteId: string, options?: AxiosRequestConfig) {
        return CompanySiteApiFp(this.configuration).getCompanySiteLiveStatus(companySiteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Company Sites
     * @summary Get Company Sites.
     * @param {number} [skip] Number of rows to skip
     * @param {number} [limit] Max number of rows to return
     * @param {string} [companyId] Company Id
     * @param {string} [name] Site name starts with
     * @param {string} [group] Group Id
     * @param {'active' | 'inactive'} [status] Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanySiteApi
     */
    public getCompanySites(skip?: number, limit?: number, companyId?: string, name?: string, group?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig) {
        return CompanySiteApiFp(this.configuration).getCompanySites(skip, limit, companyId, name, group, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing Company Site
     * @param {string} companySiteId Company Id
     * @param {CompanySiteBase} companySiteBase Company Site to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanySiteApi
     */
    public updateCompanySite(companySiteId: string, companySiteBase: CompanySiteBase, options?: AxiosRequestConfig) {
        return CompanySiteApiFp(this.configuration).updateCompanySite(companySiteId, companySiteBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Company Site Live KPI
     * @summary Update an existing Company Site Live KPI.
     * @param {string} companySiteId Company Site Id
     * @param {CompanySiteLiveKPIBase} companySiteLiveKPIBase Company Live Status to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanySiteApi
     */
    public updateCompanySiteLiveKPI(companySiteId: string, companySiteLiveKPIBase: CompanySiteLiveKPIBase, options?: AxiosRequestConfig) {
        return CompanySiteApiFp(this.configuration).updateCompanySiteLiveKPI(companySiteId, companySiteLiveKPIBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Company Site Live Status
     * @summary Update an existing Company Site Live Status.
     * @param {string} companySiteId Company Site Id
     * @param {CompanySiteLiveStatusBase} companySiteLiveStatusBase Company Site Live Status to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanySiteApi
     */
    public updateCompanySiteLiveStatus(companySiteId: string, companySiteLiveStatusBase: CompanySiteLiveStatusBase, options?: AxiosRequestConfig) {
        return CompanySiteApiFp(this.configuration).updateCompanySiteLiveStatus(companySiteId, companySiteLiveStatusBase, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConsumableApi - axios parameter creator
 * @export
 */
export const ConsumableApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new Consumable
         * @param {ConsumableInfoBase} consumableInfoBase Consumable to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsumable: async (consumableInfoBase: ConsumableInfoBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consumableInfoBase' is not null or undefined
            assertParamExists('createConsumable', 'consumableInfoBase', consumableInfoBase)
            const localVarPath = `/consumables`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consumableInfoBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Consumable
         * @summary Delete Consumable, suitable for testing purposes only, controlled by a permission.
         * @param {string} consumableId Consumable Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConsumable: async (consumableId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consumableId' is not null or undefined
            assertParamExists('deleteConsumable', 'consumableId', consumableId)
            const localVarPath = `/consumables/{consumableId}`
                .replace(`{${"consumableId"}}`, encodeURIComponent(String(consumableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConsumableInfo
         * @summary Get ConsumableInfo Details.
         * @param {string} consumableId Consumable Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsumableInfo: async (consumableId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consumableId' is not null or undefined
            assertParamExists('getConsumableInfo', 'consumableId', consumableId)
            const localVarPath = `/consumables/{consumableId}`
                .replace(`{${"consumableId"}}`, encodeURIComponent(String(consumableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConsumableLiveKPI
         * @summary Get ConsumableInfo Details.
         * @param {string} consumableId Consumable Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsumableLiveKPI: async (consumableId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consumableId' is not null or undefined
            assertParamExists('getConsumableLiveKPI', 'consumableId', consumableId)
            const localVarPath = `/consumables/{consumableId}/liveKPI`
                .replace(`{${"consumableId"}}`, encodeURIComponent(String(consumableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ConsumableLiveStatus
         * @summary Get ConsumableInfo Details.
         * @param {string} consumableId Consumable Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsumableLiveStatus: async (consumableId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consumableId' is not null or undefined
            assertParamExists('getConsumableLiveStatus', 'consumableId', consumableId)
            const localVarPath = `/consumables/{consumableId}/liveStatus`
                .replace(`{${"consumableId"}}`, encodeURIComponent(String(consumableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Consumables
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [companySiteId] Company Site Id
         * @param {string} [areaId] Area Id
         * @param {string} [systemId] System Id
         * @param {string} [blockId] Block Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsumables: async (skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, systemId?: string, blockId?: string, status?: 'active' | 'inactive', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/consumables`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (companySiteId !== undefined) {
                localVarQueryParameter['companySiteId'] = companySiteId;
            }

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }

            if (systemId !== undefined) {
                localVarQueryParameter['systemId'] = systemId;
            }

            if (blockId !== undefined) {
                localVarQueryParameter['blockId'] = blockId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Consumable
         * @summary Update an existing Consumable.
         * @param {string} consumableId Consumable Id
         * @param {ConsumableInfoBase} consumableInfoBase Consumable to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConsumable: async (consumableId: string, consumableInfoBase: ConsumableInfoBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consumableId' is not null or undefined
            assertParamExists('updateConsumable', 'consumableId', consumableId)
            // verify required parameter 'consumableInfoBase' is not null or undefined
            assertParamExists('updateConsumable', 'consumableInfoBase', consumableInfoBase)
            const localVarPath = `/consumables/{consumableId}`
                .replace(`{${"consumableId"}}`, encodeURIComponent(String(consumableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consumableInfoBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConsumableApi - functional programming interface
 * @export
 */
export const ConsumableApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConsumableApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new Consumable
         * @param {ConsumableInfoBase} consumableInfoBase Consumable to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConsumable(consumableInfoBase: ConsumableInfoBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsumableInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConsumable(consumableInfoBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Consumable
         * @summary Delete Consumable, suitable for testing purposes only, controlled by a permission.
         * @param {string} consumableId Consumable Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConsumable(consumableId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsumableInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConsumable(consumableId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ConsumableInfo
         * @summary Get ConsumableInfo Details.
         * @param {string} consumableId Consumable Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConsumableInfo(consumableId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsumableInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConsumableInfo(consumableId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ConsumableLiveKPI
         * @summary Get ConsumableInfo Details.
         * @param {string} consumableId Consumable Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConsumableLiveKPI(consumableId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsumableLiveKPI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConsumableLiveKPI(consumableId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ConsumableLiveStatus
         * @summary Get ConsumableInfo Details.
         * @param {string} consumableId Consumable Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConsumableLiveStatus(consumableId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsumableLiveStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConsumableLiveStatus(consumableId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Consumables
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [companySiteId] Company Site Id
         * @param {string} [areaId] Area Id
         * @param {string} [systemId] System Id
         * @param {string} [blockId] Block Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConsumables(skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, systemId?: string, blockId?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConsumableInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConsumables(skip, limit, companyId, companySiteId, areaId, systemId, blockId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing Consumable
         * @summary Update an existing Consumable.
         * @param {string} consumableId Consumable Id
         * @param {ConsumableInfoBase} consumableInfoBase Consumable to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConsumable(consumableId: string, consumableInfoBase: ConsumableInfoBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsumableInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConsumable(consumableId, consumableInfoBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConsumableApi - factory interface
 * @export
 */
export const ConsumableApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConsumableApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new Consumable
         * @param {ConsumableInfoBase} consumableInfoBase Consumable to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsumable(consumableInfoBase: ConsumableInfoBase, options?: any): AxiosPromise<ConsumableInfo> {
            return localVarFp.createConsumable(consumableInfoBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Consumable
         * @summary Delete Consumable, suitable for testing purposes only, controlled by a permission.
         * @param {string} consumableId Consumable Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConsumable(consumableId: string, options?: any): AxiosPromise<ConsumableInfo> {
            return localVarFp.deleteConsumable(consumableId, options).then((request) => request(axios, basePath));
        },
        /**
         * ConsumableInfo
         * @summary Get ConsumableInfo Details.
         * @param {string} consumableId Consumable Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsumableInfo(consumableId: string, options?: any): AxiosPromise<ConsumableInfo> {
            return localVarFp.getConsumableInfo(consumableId, options).then((request) => request(axios, basePath));
        },
        /**
         * ConsumableLiveKPI
         * @summary Get ConsumableInfo Details.
         * @param {string} consumableId Consumable Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsumableLiveKPI(consumableId: string, options?: any): AxiosPromise<ConsumableLiveKPI> {
            return localVarFp.getConsumableLiveKPI(consumableId, options).then((request) => request(axios, basePath));
        },
        /**
         * ConsumableLiveStatus
         * @summary Get ConsumableInfo Details.
         * @param {string} consumableId Consumable Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsumableLiveStatus(consumableId: string, options?: any): AxiosPromise<ConsumableLiveStatus> {
            return localVarFp.getConsumableLiveStatus(consumableId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Consumables
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [companySiteId] Company Site Id
         * @param {string} [areaId] Area Id
         * @param {string} [systemId] System Id
         * @param {string} [blockId] Block Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsumables(skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, systemId?: string, blockId?: string, status?: 'active' | 'inactive', options?: any): AxiosPromise<Array<ConsumableInfo>> {
            return localVarFp.getConsumables(skip, limit, companyId, companySiteId, areaId, systemId, blockId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Consumable
         * @summary Update an existing Consumable.
         * @param {string} consumableId Consumable Id
         * @param {ConsumableInfoBase} consumableInfoBase Consumable to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConsumable(consumableId: string, consumableInfoBase: ConsumableInfoBase, options?: any): AxiosPromise<ConsumableInfo> {
            return localVarFp.updateConsumable(consumableId, consumableInfoBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConsumableApi - object-oriented interface
 * @export
 * @class ConsumableApi
 * @extends {BaseAPI}
 */
export class ConsumableApi extends BaseAPI {
    /**
     * 
     * @summary Create a new Consumable
     * @param {ConsumableInfoBase} consumableInfoBase Consumable to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumableApi
     */
    public createConsumable(consumableInfoBase: ConsumableInfoBase, options?: AxiosRequestConfig) {
        return ConsumableApiFp(this.configuration).createConsumable(consumableInfoBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Consumable
     * @summary Delete Consumable, suitable for testing purposes only, controlled by a permission.
     * @param {string} consumableId Consumable Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumableApi
     */
    public deleteConsumable(consumableId: string, options?: AxiosRequestConfig) {
        return ConsumableApiFp(this.configuration).deleteConsumable(consumableId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConsumableInfo
     * @summary Get ConsumableInfo Details.
     * @param {string} consumableId Consumable Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumableApi
     */
    public getConsumableInfo(consumableId: string, options?: AxiosRequestConfig) {
        return ConsumableApiFp(this.configuration).getConsumableInfo(consumableId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConsumableLiveKPI
     * @summary Get ConsumableInfo Details.
     * @param {string} consumableId Consumable Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumableApi
     */
    public getConsumableLiveKPI(consumableId: string, options?: AxiosRequestConfig) {
        return ConsumableApiFp(this.configuration).getConsumableLiveKPI(consumableId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ConsumableLiveStatus
     * @summary Get ConsumableInfo Details.
     * @param {string} consumableId Consumable Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumableApi
     */
    public getConsumableLiveStatus(consumableId: string, options?: AxiosRequestConfig) {
        return ConsumableApiFp(this.configuration).getConsumableLiveStatus(consumableId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Consumables
     * @param {number} [skip] Number of rows to skip
     * @param {number} [limit] Max number of rows to return
     * @param {string} [companyId] Company Id
     * @param {string} [companySiteId] Company Site Id
     * @param {string} [areaId] Area Id
     * @param {string} [systemId] System Id
     * @param {string} [blockId] Block Id
     * @param {'active' | 'inactive'} [status] Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumableApi
     */
    public getConsumables(skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, systemId?: string, blockId?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig) {
        return ConsumableApiFp(this.configuration).getConsumables(skip, limit, companyId, companySiteId, areaId, systemId, blockId, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Consumable
     * @summary Update an existing Consumable.
     * @param {string} consumableId Consumable Id
     * @param {ConsumableInfoBase} consumableInfoBase Consumable to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumableApi
     */
    public updateConsumable(consumableId: string, consumableInfoBase: ConsumableInfoBase, options?: AxiosRequestConfig) {
        return ConsumableApiFp(this.configuration).updateConsumable(consumableId, consumableInfoBase, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Document
         * @summary Create a new Document.
         * @param {DocumentBase} documentBase Document to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocument: async (documentBase: DocumentBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentBase' is not null or undefined
            assertParamExists('createDocument', 'documentBase', documentBase)
            const localVarPath = `/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Document
         * @summary Delete Document, suitable for testing purposes only, controlled by a permission.
         * @param {string} documentId Document Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument: async (documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('deleteDocument', 'documentId', documentId)
            const localVarPath = `/documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Document
         * @summary Get Document Details.
         * @param {string} documentId Document Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument: async (documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocument', 'documentId', documentId)
            const localVarPath = `/documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Document
         * @summary Restore document from recycle.
         * @param {string} documentId Document Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentRestore: async (documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocumentRestore', 'documentId', documentId)
            const localVarPath = `/documents/{documentId}/restore`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Document
         * @summary Get Document Pre-signed url for upload.
         * @param {string} documentId Document Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentUploadUrl: async (documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocumentUploadUrl', 'documentId', documentId)
            const localVarPath = `/documents/{documentId}/url`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Document
         * @summary Get Document Pre-signed url.
         * @param {string} documentId Document Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentUrl: async (documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocumentUrl', 'documentId', documentId)
            const localVarPath = `/documents/{documentId}/url`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of Documents with filter
         * @summary List of Documents with filter.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [systemId] 
         * @param {string} [companyId] 
         * @param {string} [name] 
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocuments: async (skip?: number, limit?: number, systemId?: string, companyId?: string, name?: string, status?: 'active' | 'inactive', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (systemId !== undefined) {
                localVarQueryParameter['systemId'] = systemId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Document
         * @summary Update an existing Document.
         * @param {string} documentId Document Id
         * @param {DocumentBase} documentBase Document to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocument: async (documentId: string, documentBase: DocumentBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateDocument', 'documentId', documentId)
            // verify required parameter 'documentBase' is not null or undefined
            assertParamExists('updateDocument', 'documentBase', documentBase)
            const localVarPath = `/documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Document
         * @summary Create a new Document.
         * @param {DocumentBase} documentBase Document to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDocument(documentBase: DocumentBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDocument(documentBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Document
         * @summary Delete Document, suitable for testing purposes only, controlled by a permission.
         * @param {string} documentId Document Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocument(documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocument(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Document
         * @summary Get Document Details.
         * @param {string} documentId Document Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocument(documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocument(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Document
         * @summary Restore document from recycle.
         * @param {string} documentId Document Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentRestore(documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentRestore(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Document
         * @summary Get Document Pre-signed url for upload.
         * @param {string} documentId Document Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentUploadUrl(documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentPutData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentUploadUrl(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Document
         * @summary Get Document Pre-signed url.
         * @param {string} documentId Document Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentUrl(documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentUrl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentUrl(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of Documents with filter
         * @summary List of Documents with filter.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [systemId] 
         * @param {string} [companyId] 
         * @param {string} [name] 
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocuments(skip?: number, limit?: number, systemId?: string, companyId?: string, name?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Document>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocuments(skip, limit, systemId, companyId, name, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing Document
         * @summary Update an existing Document.
         * @param {string} documentId Document Id
         * @param {DocumentBase} documentBase Document to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDocument(documentId: string, documentBase: DocumentBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocument(documentId, documentBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentApiFp(configuration)
    return {
        /**
         * Create a new Document
         * @summary Create a new Document.
         * @param {DocumentBase} documentBase Document to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocument(documentBase: DocumentBase, options?: any): AxiosPromise<Document> {
            return localVarFp.createDocument(documentBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Document
         * @summary Delete Document, suitable for testing purposes only, controlled by a permission.
         * @param {string} documentId Document Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument(documentId: string, options?: any): AxiosPromise<Document> {
            return localVarFp.deleteDocument(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Document
         * @summary Get Document Details.
         * @param {string} documentId Document Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument(documentId: string, options?: any): AxiosPromise<Document> {
            return localVarFp.getDocument(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Document
         * @summary Restore document from recycle.
         * @param {string} documentId Document Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentRestore(documentId: string, options?: any): AxiosPromise<Document> {
            return localVarFp.getDocumentRestore(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Document
         * @summary Get Document Pre-signed url for upload.
         * @param {string} documentId Document Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentUploadUrl(documentId: string, options?: any): AxiosPromise<DocumentPutData> {
            return localVarFp.getDocumentUploadUrl(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Document
         * @summary Get Document Pre-signed url.
         * @param {string} documentId Document Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentUrl(documentId: string, options?: any): AxiosPromise<DocumentUrl> {
            return localVarFp.getDocumentUrl(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * List of Documents with filter
         * @summary List of Documents with filter.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [systemId] 
         * @param {string} [companyId] 
         * @param {string} [name] 
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocuments(skip?: number, limit?: number, systemId?: string, companyId?: string, name?: string, status?: 'active' | 'inactive', options?: any): AxiosPromise<Array<Document>> {
            return localVarFp.getDocuments(skip, limit, systemId, companyId, name, status, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Document
         * @summary Update an existing Document.
         * @param {string} documentId Document Id
         * @param {DocumentBase} documentBase Document to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocument(documentId: string, documentBase: DocumentBase, options?: any): AxiosPromise<Document> {
            return localVarFp.updateDocument(documentId, documentBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI {
    /**
     * Create a new Document
     * @summary Create a new Document.
     * @param {DocumentBase} documentBase Document to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public createDocument(documentBase: DocumentBase, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).createDocument(documentBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Document
     * @summary Delete Document, suitable for testing purposes only, controlled by a permission.
     * @param {string} documentId Document Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public deleteDocument(documentId: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).deleteDocument(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Document
     * @summary Get Document Details.
     * @param {string} documentId Document Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public getDocument(documentId: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).getDocument(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Document
     * @summary Restore document from recycle.
     * @param {string} documentId Document Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public getDocumentRestore(documentId: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).getDocumentRestore(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Document
     * @summary Get Document Pre-signed url for upload.
     * @param {string} documentId Document Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public getDocumentUploadUrl(documentId: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).getDocumentUploadUrl(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Document
     * @summary Get Document Pre-signed url.
     * @param {string} documentId Document Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public getDocumentUrl(documentId: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).getDocumentUrl(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of Documents with filter
     * @summary List of Documents with filter.
     * @param {number} [skip] Number of rows to skip
     * @param {number} [limit] Max number of rows to return
     * @param {string} [systemId] 
     * @param {string} [companyId] 
     * @param {string} [name] 
     * @param {'active' | 'inactive'} [status] Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public getDocuments(skip?: number, limit?: number, systemId?: string, companyId?: string, name?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).getDocuments(skip, limit, systemId, companyId, name, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Document
     * @summary Update an existing Document.
     * @param {string} documentId Document Id
     * @param {DocumentBase} documentBase Document to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public updateDocument(documentId: string, documentBase: DocumentBase, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).updateDocument(documentId, documentBase, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExecutiveSummaryApi - axios parameter creator
 * @export
 */
export const ExecutiveSummaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return executive summary information by company site id
         * @summary Executive Summary
         * @param {string} companySiteId Company Site Id
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {'hourly' | 'daily' | 'weekly' | 'monthly'} aggregation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExecutiveByCompanySiteId: async (companySiteId: string, startDate: string, endDate: string, aggregation: 'hourly' | 'daily' | 'weekly' | 'monthly', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companySiteId' is not null or undefined
            assertParamExists('getExecutiveByCompanySiteId', 'companySiteId', companySiteId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getExecutiveByCompanySiteId', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getExecutiveByCompanySiteId', 'endDate', endDate)
            // verify required parameter 'aggregation' is not null or undefined
            assertParamExists('getExecutiveByCompanySiteId', 'aggregation', aggregation)
            const localVarPath = `/executiveSummary/{companySiteId}`
                .replace(`{${"companySiteId"}}`, encodeURIComponent(String(companySiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (aggregation !== undefined) {
                localVarQueryParameter['aggregation'] = aggregation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExecutiveSummaryApi - functional programming interface
 * @export
 */
export const ExecutiveSummaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExecutiveSummaryApiAxiosParamCreator(configuration)
    return {
        /**
         * Return executive summary information by company site id
         * @summary Executive Summary
         * @param {string} companySiteId Company Site Id
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {'hourly' | 'daily' | 'weekly' | 'monthly'} aggregation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExecutiveByCompanySiteId(companySiteId: string, startDate: string, endDate: string, aggregation: 'hourly' | 'daily' | 'weekly' | 'monthly', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExecutiveSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExecutiveByCompanySiteId(companySiteId, startDate, endDate, aggregation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExecutiveSummaryApi - factory interface
 * @export
 */
export const ExecutiveSummaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExecutiveSummaryApiFp(configuration)
    return {
        /**
         * Return executive summary information by company site id
         * @summary Executive Summary
         * @param {string} companySiteId Company Site Id
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {'hourly' | 'daily' | 'weekly' | 'monthly'} aggregation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExecutiveByCompanySiteId(companySiteId: string, startDate: string, endDate: string, aggregation: 'hourly' | 'daily' | 'weekly' | 'monthly', options?: any): AxiosPromise<ExecutiveSummary> {
            return localVarFp.getExecutiveByCompanySiteId(companySiteId, startDate, endDate, aggregation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExecutiveSummaryApi - object-oriented interface
 * @export
 * @class ExecutiveSummaryApi
 * @extends {BaseAPI}
 */
export class ExecutiveSummaryApi extends BaseAPI {
    /**
     * Return executive summary information by company site id
     * @summary Executive Summary
     * @param {string} companySiteId Company Site Id
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {'hourly' | 'daily' | 'weekly' | 'monthly'} aggregation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExecutiveSummaryApi
     */
    public getExecutiveByCompanySiteId(companySiteId: string, startDate: string, endDate: string, aggregation: 'hourly' | 'daily' | 'weekly' | 'monthly', options?: AxiosRequestConfig) {
        return ExecutiveSummaryApiFp(this.configuration).getExecutiveByCompanySiteId(companySiteId, startDate, endDate, aggregation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExportApi - axios parameter creator
 * @export
 */
export const ExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Export File
         * @param {string} exportId Export Id
         * @param {string} companyId Company Id
         * @param {string} siteId System Site Id
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExport: async (exportId: string, companyId: string, siteId: string, systemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportId' is not null or undefined
            assertParamExists('deleteExport', 'exportId', exportId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteExport', 'companyId', companyId)
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('deleteExport', 'siteId', siteId)
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('deleteExport', 'systemId', systemId)
            const localVarPath = `/exports/{exportId}`
                .replace(`{${"exportId"}}`, encodeURIComponent(String(exportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (systemId !== undefined) {
                localVarQueryParameter['systemId'] = systemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Share Export by attaching it to email
         * @summary Share Export
         * @param {string} exportId Export Id
         * @param {string} companyId Company Id
         * @param {string} siteId System Site Id
         * @param {string} systemId System Id
         * @param {string} emailAddress Email Address to send the export to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportShare: async (exportId: string, companyId: string, siteId: string, systemId: string, emailAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportId' is not null or undefined
            assertParamExists('exportShare', 'exportId', exportId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('exportShare', 'companyId', companyId)
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('exportShare', 'siteId', siteId)
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('exportShare', 'systemId', systemId)
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('exportShare', 'emailAddress', emailAddress)
            const localVarPath = `/exports/{exportId}/share`
                .replace(`{${"exportId"}}`, encodeURIComponent(String(exportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (systemId !== undefined) {
                localVarQueryParameter['systemId'] = systemId;
            }

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Export URL
         * @param {string} exportId Export Id
         * @param {string} companyId Company Id
         * @param {string} siteId System Site Id
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExportURL: async (exportId: string, companyId: string, siteId: string, systemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportId' is not null or undefined
            assertParamExists('getExportURL', 'exportId', exportId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getExportURL', 'companyId', companyId)
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getExportURL', 'siteId', siteId)
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('getExportURL', 'systemId', systemId)
            const localVarPath = `/exports/{exportId}`
                .replace(`{${"exportId"}}`, encodeURIComponent(String(exportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (systemId !== undefined) {
                localVarQueryParameter['systemId'] = systemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Exports
         * @param {string} [companyId] Company Id
         * @param {string} [siteId] System Site Id
         * @param {string} [systemId] System Id
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExports: async (companyId?: string, siteId?: string, systemId?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/exports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (systemId !== undefined) {
                localVarQueryParameter['systemId'] = systemId;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generates a request for system telemtry data. Request returns with DynamoDB Table \'export\' entries.  Request also starts generation of the export file. Export Type Options: \'AllData\', \'PremadeFilters\'
         * @summary Request System Telemetry Data export file
         * @param {string} companyId Company Id
         * @param {string} siteId Site Id
         * @param {string} systemId System Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} exportType 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExportGenerate: async (companyId: string, siteId: string, systemId: string, startTime: string, endTime: string, exportType: string, fileName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postExportGenerate', 'companyId', companyId)
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('postExportGenerate', 'siteId', siteId)
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('postExportGenerate', 'systemId', systemId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('postExportGenerate', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('postExportGenerate', 'endTime', endTime)
            // verify required parameter 'exportType' is not null or undefined
            assertParamExists('postExportGenerate', 'exportType', exportType)
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('postExportGenerate', 'fileName', fileName)
            const localVarPath = `/exports/{systemId}/generateExport`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (exportType !== undefined) {
                localVarQueryParameter['exportType'] = exportType;
            }

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExportApi - functional programming interface
 * @export
 */
export const ExportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Export File
         * @param {string} exportId Export Id
         * @param {string} companyId Company Id
         * @param {string} siteId System Site Id
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteExport(exportId: string, companyId: string, siteId: string, systemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TelemetryDataExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExport(exportId, companyId, siteId, systemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Share Export by attaching it to email
         * @summary Share Export
         * @param {string} exportId Export Id
         * @param {string} companyId Company Id
         * @param {string} siteId System Site Id
         * @param {string} systemId System Id
         * @param {string} emailAddress Email Address to send the export to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportShare(exportId: string, companyId: string, siteId: string, systemId: string, emailAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TelemetryDataExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportShare(exportId, companyId, siteId, systemId, emailAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Export URL
         * @param {string} exportId Export Id
         * @param {string} companyId Company Id
         * @param {string} siteId System Site Id
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExportURL(exportId: string, companyId: string, siteId: string, systemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TelemetryDataExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExportURL(exportId, companyId, siteId, systemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Exports
         * @param {string} [companyId] Company Id
         * @param {string} [siteId] System Site Id
         * @param {string} [systemId] System Id
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExports(companyId?: string, siteId?: string, systemId?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TelemetryDataExport>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExports(companyId, siteId, systemId, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generates a request for system telemtry data. Request returns with DynamoDB Table \'export\' entries.  Request also starts generation of the export file. Export Type Options: \'AllData\', \'PremadeFilters\'
         * @summary Request System Telemetry Data export file
         * @param {string} companyId Company Id
         * @param {string} siteId Site Id
         * @param {string} systemId System Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} exportType 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postExportGenerate(companyId: string, siteId: string, systemId: string, startTime: string, endTime: string, exportType: string, fileName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TelemetryDataExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postExportGenerate(companyId, siteId, systemId, startTime, endTime, exportType, fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExportApi - factory interface
 * @export
 */
export const ExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExportApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Export File
         * @param {string} exportId Export Id
         * @param {string} companyId Company Id
         * @param {string} siteId System Site Id
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExport(exportId: string, companyId: string, siteId: string, systemId: string, options?: any): AxiosPromise<TelemetryDataExport> {
            return localVarFp.deleteExport(exportId, companyId, siteId, systemId, options).then((request) => request(axios, basePath));
        },
        /**
         * Share Export by attaching it to email
         * @summary Share Export
         * @param {string} exportId Export Id
         * @param {string} companyId Company Id
         * @param {string} siteId System Site Id
         * @param {string} systemId System Id
         * @param {string} emailAddress Email Address to send the export to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportShare(exportId: string, companyId: string, siteId: string, systemId: string, emailAddress: string, options?: any): AxiosPromise<TelemetryDataExport> {
            return localVarFp.exportShare(exportId, companyId, siteId, systemId, emailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Export URL
         * @param {string} exportId Export Id
         * @param {string} companyId Company Id
         * @param {string} siteId System Site Id
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExportURL(exportId: string, companyId: string, siteId: string, systemId: string, options?: any): AxiosPromise<TelemetryDataExport> {
            return localVarFp.getExportURL(exportId, companyId, siteId, systemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Exports
         * @param {string} [companyId] Company Id
         * @param {string} [siteId] System Site Id
         * @param {string} [systemId] System Id
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExports(companyId?: string, siteId?: string, systemId?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<TelemetryDataExport>> {
            return localVarFp.getExports(companyId, siteId, systemId, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Generates a request for system telemtry data. Request returns with DynamoDB Table \'export\' entries.  Request also starts generation of the export file. Export Type Options: \'AllData\', \'PremadeFilters\'
         * @summary Request System Telemetry Data export file
         * @param {string} companyId Company Id
         * @param {string} siteId Site Id
         * @param {string} systemId System Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} exportType 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExportGenerate(companyId: string, siteId: string, systemId: string, startTime: string, endTime: string, exportType: string, fileName: string, options?: any): AxiosPromise<TelemetryDataExport> {
            return localVarFp.postExportGenerate(companyId, siteId, systemId, startTime, endTime, exportType, fileName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExportApi - object-oriented interface
 * @export
 * @class ExportApi
 * @extends {BaseAPI}
 */
export class ExportApi extends BaseAPI {
    /**
     * 
     * @summary Delete Export File
     * @param {string} exportId Export Id
     * @param {string} companyId Company Id
     * @param {string} siteId System Site Id
     * @param {string} systemId System Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public deleteExport(exportId: string, companyId: string, siteId: string, systemId: string, options?: AxiosRequestConfig) {
        return ExportApiFp(this.configuration).deleteExport(exportId, companyId, siteId, systemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Share Export by attaching it to email
     * @summary Share Export
     * @param {string} exportId Export Id
     * @param {string} companyId Company Id
     * @param {string} siteId System Site Id
     * @param {string} systemId System Id
     * @param {string} emailAddress Email Address to send the export to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public exportShare(exportId: string, companyId: string, siteId: string, systemId: string, emailAddress: string, options?: AxiosRequestConfig) {
        return ExportApiFp(this.configuration).exportShare(exportId, companyId, siteId, systemId, emailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Export URL
     * @param {string} exportId Export Id
     * @param {string} companyId Company Id
     * @param {string} siteId System Site Id
     * @param {string} systemId System Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public getExportURL(exportId: string, companyId: string, siteId: string, systemId: string, options?: AxiosRequestConfig) {
        return ExportApiFp(this.configuration).getExportURL(exportId, companyId, siteId, systemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Exports
     * @param {string} [companyId] Company Id
     * @param {string} [siteId] System Site Id
     * @param {string} [systemId] System Id
     * @param {number} [skip] Number of rows to skip
     * @param {number} [limit] Max number of rows to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public getExports(companyId?: string, siteId?: string, systemId?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return ExportApiFp(this.configuration).getExports(companyId, siteId, systemId, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generates a request for system telemtry data. Request returns with DynamoDB Table \'export\' entries.  Request also starts generation of the export file. Export Type Options: \'AllData\', \'PremadeFilters\'
     * @summary Request System Telemetry Data export file
     * @param {string} companyId Company Id
     * @param {string} siteId Site Id
     * @param {string} systemId System Id
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} exportType 
     * @param {string} fileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public postExportGenerate(companyId: string, siteId: string, systemId: string, startTime: string, endTime: string, exportType: string, fileName: string, options?: AxiosRequestConfig) {
        return ExportApiFp(this.configuration).postExportGenerate(companyId, siteId, systemId, startTime, endTime, exportType, fileName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FilterRunApi - axios parameter creator
 * @export
 */
export const FilterRunApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * FilterRun
         * @summary Get Filter Run Details.
         * @param {string} systemId System Id
         * @param {string} filterRunId Filter Run Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterRun: async (systemId: string, filterRunId: string, startTime: string, endTime: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('getFilterRun', 'systemId', systemId)
            // verify required parameter 'filterRunId' is not null or undefined
            assertParamExists('getFilterRun', 'filterRunId', filterRunId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getFilterRun', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getFilterRun', 'endTime', endTime)
            const localVarPath = `/filterruns/{systemId}/{filterRunId}`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)))
                .replace(`{${"filterRunId"}}`, encodeURIComponent(String(filterRunId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Batch details for a given Filter Run ID
         * @summary Get Filter Run Details.
         * @param {string} systemId System Id
         * @param {string} filterRunId Filter Run Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterRunBatchDetails: async (systemId: string, filterRunId: string, startTime: string, endTime: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('getFilterRunBatchDetails', 'systemId', systemId)
            // verify required parameter 'filterRunId' is not null or undefined
            assertParamExists('getFilterRunBatchDetails', 'filterRunId', filterRunId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getFilterRunBatchDetails', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getFilterRunBatchDetails', 'endTime', endTime)
            const localVarPath = `/filterruns-batchdetails/{systemId}/{filterRunId}`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)))
                .replace(`{${"filterRunId"}}`, encodeURIComponent(String(filterRunId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of Filter Runs for system  with filter
         * @summary List of Filter Runs for system with filter.
         * @param {string} systemId System Id
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterRuns: async (systemId: string, startTime?: string, endTime?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('getFilterRuns', 'systemId', systemId)
            const localVarPath = `/filterruns/{systemId}`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilterRunApi - functional programming interface
 * @export
 */
export const FilterRunApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilterRunApiAxiosParamCreator(configuration)
    return {
        /**
         * FilterRun
         * @summary Get Filter Run Details.
         * @param {string} systemId System Id
         * @param {string} filterRunId Filter Run Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilterRun(systemId: string, filterRunId: string, startTime: string, endTime: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FilterRunDetails>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilterRun(systemId, filterRunId, startTime, endTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Batch details for a given Filter Run ID
         * @summary Get Filter Run Details.
         * @param {string} systemId System Id
         * @param {string} filterRunId Filter Run Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilterRunBatchDetails(systemId: string, filterRunId: string, startTime: string, endTime: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FilterRunBatchDetails>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilterRunBatchDetails(systemId, filterRunId, startTime, endTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of Filter Runs for system  with filter
         * @summary List of Filter Runs for system with filter.
         * @param {string} systemId System Id
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilterRuns(systemId: string, startTime?: string, endTime?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FilterRun>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilterRuns(systemId, startTime, endTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilterRunApi - factory interface
 * @export
 */
export const FilterRunApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilterRunApiFp(configuration)
    return {
        /**
         * FilterRun
         * @summary Get Filter Run Details.
         * @param {string} systemId System Id
         * @param {string} filterRunId Filter Run Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterRun(systemId: string, filterRunId: string, startTime: string, endTime: string, options?: any): AxiosPromise<Array<FilterRunDetails>> {
            return localVarFp.getFilterRun(systemId, filterRunId, startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * Batch details for a given Filter Run ID
         * @summary Get Filter Run Details.
         * @param {string} systemId System Id
         * @param {string} filterRunId Filter Run Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterRunBatchDetails(systemId: string, filterRunId: string, startTime: string, endTime: string, options?: any): AxiosPromise<Array<FilterRunBatchDetails>> {
            return localVarFp.getFilterRunBatchDetails(systemId, filterRunId, startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * List of Filter Runs for system  with filter
         * @summary List of Filter Runs for system with filter.
         * @param {string} systemId System Id
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterRuns(systemId: string, startTime?: string, endTime?: string, options?: any): AxiosPromise<Array<FilterRun>> {
            return localVarFp.getFilterRuns(systemId, startTime, endTime, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilterRunApi - object-oriented interface
 * @export
 * @class FilterRunApi
 * @extends {BaseAPI}
 */
export class FilterRunApi extends BaseAPI {
    /**
     * FilterRun
     * @summary Get Filter Run Details.
     * @param {string} systemId System Id
     * @param {string} filterRunId Filter Run Id
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterRunApi
     */
    public getFilterRun(systemId: string, filterRunId: string, startTime: string, endTime: string, options?: AxiosRequestConfig) {
        return FilterRunApiFp(this.configuration).getFilterRun(systemId, filterRunId, startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Batch details for a given Filter Run ID
     * @summary Get Filter Run Details.
     * @param {string} systemId System Id
     * @param {string} filterRunId Filter Run Id
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterRunApi
     */
    public getFilterRunBatchDetails(systemId: string, filterRunId: string, startTime: string, endTime: string, options?: AxiosRequestConfig) {
        return FilterRunApiFp(this.configuration).getFilterRunBatchDetails(systemId, filterRunId, startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of Filter Runs for system  with filter
     * @summary List of Filter Runs for system with filter.
     * @param {string} systemId System Id
     * @param {string} [startTime] 
     * @param {string} [endTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterRunApi
     */
    public getFilterRuns(systemId: string, startTime?: string, endTime?: string, options?: AxiosRequestConfig) {
        return FilterRunApiFp(this.configuration).getFilterRuns(systemId, startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupApi - axios parameter creator
 * @export
 */
export const GroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new Group
         * @param {GroupBase} groupBase Group to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup: async (groupBase: GroupBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupBase' is not null or undefined
            assertParamExists('createGroup', 'groupBase', groupBase)
            const localVarPath = `/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an existing Group
         * @param {string} groupId Group Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup: async (groupId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('deleteGroup', 'groupId', groupId)
            const localVarPath = `/groups/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Group Details
         * @summary Get Group Details.
         * @param {string} groupId Group Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroup: async (groupId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('getGroup', 'groupId', groupId)
            const localVarPath = `/groups/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Groups
         * @param {string} [companyId] Company Id
         * @param {string} [parentGroupId] Parent Group Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups: async (companyId?: string, parentGroupId?: string, status?: 'active' | 'inactive', skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (parentGroupId !== undefined) {
                localVarQueryParameter['parentGroupId'] = parentGroupId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing Group
         * @param {string} groupId Group Id
         * @param {GroupBase} groupBase Group to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup: async (groupId: string, groupBase: GroupBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('updateGroup', 'groupId', groupId)
            // verify required parameter 'groupBase' is not null or undefined
            assertParamExists('updateGroup', 'groupBase', groupBase)
            const localVarPath = `/groups/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupApi - functional programming interface
 * @export
 */
export const GroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new Group
         * @param {GroupBase} groupBase Group to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGroup(groupBase: GroupBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGroup(groupBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an existing Group
         * @param {string} groupId Group Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGroup(groupId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGroup(groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Group Details
         * @summary Get Group Details.
         * @param {string} groupId Group Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroup(groupId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroup(groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Groups
         * @param {string} [companyId] Company Id
         * @param {string} [parentGroupId] Parent Group Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroups(companyId?: string, parentGroupId?: string, status?: 'active' | 'inactive', skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Group>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroups(companyId, parentGroupId, status, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing Group
         * @param {string} groupId Group Id
         * @param {GroupBase} groupBase Group to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroup(groupId: string, groupBase: GroupBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroup(groupId, groupBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupApi - factory interface
 * @export
 */
export const GroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new Group
         * @param {GroupBase} groupBase Group to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup(groupBase: GroupBase, options?: any): AxiosPromise<Group> {
            return localVarFp.createGroup(groupBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an existing Group
         * @param {string} groupId Group Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup(groupId: string, options?: any): AxiosPromise<Group> {
            return localVarFp.deleteGroup(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Group Details
         * @summary Get Group Details.
         * @param {string} groupId Group Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroup(groupId: string, options?: any): AxiosPromise<Group> {
            return localVarFp.getGroup(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Groups
         * @param {string} [companyId] Company Id
         * @param {string} [parentGroupId] Parent Group Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups(companyId?: string, parentGroupId?: string, status?: 'active' | 'inactive', skip?: number, limit?: number, options?: any): AxiosPromise<Array<Group>> {
            return localVarFp.getGroups(companyId, parentGroupId, status, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing Group
         * @param {string} groupId Group Id
         * @param {GroupBase} groupBase Group to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(groupId: string, groupBase: GroupBase, options?: any): AxiosPromise<Group> {
            return localVarFp.updateGroup(groupId, groupBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupApi - object-oriented interface
 * @export
 * @class GroupApi
 * @extends {BaseAPI}
 */
export class GroupApi extends BaseAPI {
    /**
     * 
     * @summary Create a new Group
     * @param {GroupBase} groupBase Group to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public createGroup(groupBase: GroupBase, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).createGroup(groupBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an existing Group
     * @param {string} groupId Group Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public deleteGroup(groupId: string, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).deleteGroup(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Group Details
     * @summary Get Group Details.
     * @param {string} groupId Group Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public getGroup(groupId: string, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).getGroup(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Groups
     * @param {string} [companyId] Company Id
     * @param {string} [parentGroupId] Parent Group Id
     * @param {'active' | 'inactive'} [status] Status
     * @param {number} [skip] Number of rows to skip
     * @param {number} [limit] Max number of rows to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public getGroups(companyId?: string, parentGroupId?: string, status?: 'active' | 'inactive', skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).getGroups(companyId, parentGroupId, status, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing Group
     * @param {string} groupId Group Id
     * @param {GroupBase} groupBase Group to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public updateGroup(groupId: string, groupBase: GroupBase, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).updateGroup(groupId, groupBase, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IntegrityTestApi - axios parameter creator
 * @export
 */
export const IntegrityTestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List of Integrity Test
         * @summary List of Integrity Test
         * @param {string} systemId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrityTest: async (systemId: string, startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('getIntegrityTest', 'systemId', systemId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getIntegrityTest', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getIntegrityTest', 'endDate', endDate)
            const localVarPath = `/integrity-test/{systemId}`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrityTestApi - functional programming interface
 * @export
 */
export const IntegrityTestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrityTestApiAxiosParamCreator(configuration)
    return {
        /**
         * List of Integrity Test
         * @summary List of Integrity Test
         * @param {string} systemId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntegrityTest(systemId: string, startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntegrityTestBase>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntegrityTest(systemId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntegrityTestApi - factory interface
 * @export
 */
export const IntegrityTestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrityTestApiFp(configuration)
    return {
        /**
         * List of Integrity Test
         * @summary List of Integrity Test
         * @param {string} systemId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrityTest(systemId: string, startDate: string, endDate: string, options?: any): AxiosPromise<Array<IntegrityTestBase>> {
            return localVarFp.getIntegrityTest(systemId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrityTestApi - object-oriented interface
 * @export
 * @class IntegrityTestApi
 * @extends {BaseAPI}
 */
export class IntegrityTestApi extends BaseAPI {
    /**
     * List of Integrity Test
     * @summary List of Integrity Test
     * @param {string} systemId 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrityTestApi
     */
    public getIntegrityTest(systemId: string, startDate: string, endDate: string, options?: AxiosRequestConfig) {
        return IntegrityTestApiFp(this.configuration).getIntegrityTest(systemId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IoTStatsApi - axios parameter creator
 * @export
 */
export const IoTStatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List of IoT status with filter
         * @summary List of IoT status with filter.
         * @param {string} companyId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} aggregation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIoTStats: async (companyId: string, startDate: string, endDate: string, aggregation: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getIoTStats', 'companyId', companyId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getIoTStats', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getIoTStats', 'endDate', endDate)
            // verify required parameter 'aggregation' is not null or undefined
            assertParamExists('getIoTStats', 'aggregation', aggregation)
            const localVarPath = `/iot-stats/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (aggregation !== undefined) {
                localVarQueryParameter['aggregation'] = aggregation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IoTStatsApi - functional programming interface
 * @export
 */
export const IoTStatsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IoTStatsApiAxiosParamCreator(configuration)
    return {
        /**
         * List of IoT status with filter
         * @summary List of IoT status with filter.
         * @param {string} companyId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} aggregation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIoTStats(companyId: string, startDate: string, endDate: string, aggregation: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IoTStatsBase>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIoTStats(companyId, startDate, endDate, aggregation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IoTStatsApi - factory interface
 * @export
 */
export const IoTStatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IoTStatsApiFp(configuration)
    return {
        /**
         * List of IoT status with filter
         * @summary List of IoT status with filter.
         * @param {string} companyId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} aggregation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIoTStats(companyId: string, startDate: string, endDate: string, aggregation: string, options?: any): AxiosPromise<Array<IoTStatsBase>> {
            return localVarFp.getIoTStats(companyId, startDate, endDate, aggregation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IoTStatsApi - object-oriented interface
 * @export
 * @class IoTStatsApi
 * @extends {BaseAPI}
 */
export class IoTStatsApi extends BaseAPI {
    /**
     * List of IoT status with filter
     * @summary List of IoT status with filter.
     * @param {string} companyId 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {string} aggregation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IoTStatsApi
     */
    public getIoTStats(companyId: string, startDate: string, endDate: string, aggregation: string, options?: AxiosRequestConfig) {
        return IoTStatsApiFp(this.configuration).getIoTStats(companyId, startDate, endDate, aggregation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IotProvisioningApi - axios parameter creator
 * @export
 */
export const IotProvisioningApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Thing
         * @summary Create a new Thing.
         * @param {IotProvisioningBase} iotProvisioningBase Thing to be provisioned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createThing: async (iotProvisioningBase: IotProvisioningBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iotProvisioningBase' is not null or undefined
            assertParamExists('createThing', 'iotProvisioningBase', iotProvisioningBase)
            const localVarPath = `/iot-provisioning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iotProvisioningBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Thing
         * @summary Delete Thing, suitable for testing purposes only, controlled by a permission.
         * @param {string} thingId Thing Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteThing: async (thingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'thingId' is not null or undefined
            assertParamExists('deleteThing', 'thingId', thingId)
            const localVarPath = `/iot-provisioning/{thingId}`
                .replace(`{${"thingId"}}`, encodeURIComponent(String(thingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Thing
         * @summary Get Thing Details.
         * @param {string} thingId Thing Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThing: async (thingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'thingId' is not null or undefined
            assertParamExists('getThing', 'thingId', thingId)
            const localVarPath = `/iot-provisioning/{thingId}`
                .replace(`{${"thingId"}}`, encodeURIComponent(String(thingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provisioned Thing certificates
         * @summary Get Provisioned Thing certificates Pre-signed url.
         * @param {string} thingId Thing Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThingUrl: async (thingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'thingId' is not null or undefined
            assertParamExists('getThingUrl', 'thingId', thingId)
            const localVarPath = `/iot-provisioning/{thingId}/url`
                .replace(`{${"thingId"}}`, encodeURIComponent(String(thingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of Things with filter
         * @summary List of Things with filter.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companySiteId] 
         * @param {string} [companyId] 
         * @param {string} [name] 
         * @param {'provisioning' | 'provisioned'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThings: async (skip?: number, limit?: number, companySiteId?: string, companyId?: string, name?: string, status?: 'provisioning' | 'provisioned', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/iot-provisioning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (companySiteId !== undefined) {
                localVarQueryParameter['companySiteId'] = companySiteId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IotProvisioningApi - functional programming interface
 * @export
 */
export const IotProvisioningApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IotProvisioningApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Thing
         * @summary Create a new Thing.
         * @param {IotProvisioningBase} iotProvisioningBase Thing to be provisioned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createThing(iotProvisioningBase: IotProvisioningBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IotProvisioning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createThing(iotProvisioningBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Thing
         * @summary Delete Thing, suitable for testing purposes only, controlled by a permission.
         * @param {string} thingId Thing Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteThing(thingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IotProvisioning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteThing(thingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Thing
         * @summary Get Thing Details.
         * @param {string} thingId Thing Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThing(thingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IotProvisioning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThing(thingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Provisioned Thing certificates
         * @summary Get Provisioned Thing certificates Pre-signed url.
         * @param {string} thingId Thing Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThingUrl(thingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IotProvisioningUrl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThingUrl(thingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of Things with filter
         * @summary List of Things with filter.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companySiteId] 
         * @param {string} [companyId] 
         * @param {string} [name] 
         * @param {'provisioning' | 'provisioned'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThings(skip?: number, limit?: number, companySiteId?: string, companyId?: string, name?: string, status?: 'provisioning' | 'provisioned', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IotProvisioning>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThings(skip, limit, companySiteId, companyId, name, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IotProvisioningApi - factory interface
 * @export
 */
export const IotProvisioningApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IotProvisioningApiFp(configuration)
    return {
        /**
         * Create a new Thing
         * @summary Create a new Thing.
         * @param {IotProvisioningBase} iotProvisioningBase Thing to be provisioned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createThing(iotProvisioningBase: IotProvisioningBase, options?: any): AxiosPromise<IotProvisioning> {
            return localVarFp.createThing(iotProvisioningBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Thing
         * @summary Delete Thing, suitable for testing purposes only, controlled by a permission.
         * @param {string} thingId Thing Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteThing(thingId: string, options?: any): AxiosPromise<IotProvisioning> {
            return localVarFp.deleteThing(thingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Thing
         * @summary Get Thing Details.
         * @param {string} thingId Thing Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThing(thingId: string, options?: any): AxiosPromise<IotProvisioning> {
            return localVarFp.getThing(thingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Provisioned Thing certificates
         * @summary Get Provisioned Thing certificates Pre-signed url.
         * @param {string} thingId Thing Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThingUrl(thingId: string, options?: any): AxiosPromise<IotProvisioningUrl> {
            return localVarFp.getThingUrl(thingId, options).then((request) => request(axios, basePath));
        },
        /**
         * List of Things with filter
         * @summary List of Things with filter.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companySiteId] 
         * @param {string} [companyId] 
         * @param {string} [name] 
         * @param {'provisioning' | 'provisioned'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThings(skip?: number, limit?: number, companySiteId?: string, companyId?: string, name?: string, status?: 'provisioning' | 'provisioned', options?: any): AxiosPromise<Array<IotProvisioning>> {
            return localVarFp.getThings(skip, limit, companySiteId, companyId, name, status, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IotProvisioningApi - object-oriented interface
 * @export
 * @class IotProvisioningApi
 * @extends {BaseAPI}
 */
export class IotProvisioningApi extends BaseAPI {
    /**
     * Create a new Thing
     * @summary Create a new Thing.
     * @param {IotProvisioningBase} iotProvisioningBase Thing to be provisioned
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IotProvisioningApi
     */
    public createThing(iotProvisioningBase: IotProvisioningBase, options?: AxiosRequestConfig) {
        return IotProvisioningApiFp(this.configuration).createThing(iotProvisioningBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Thing
     * @summary Delete Thing, suitable for testing purposes only, controlled by a permission.
     * @param {string} thingId Thing Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IotProvisioningApi
     */
    public deleteThing(thingId: string, options?: AxiosRequestConfig) {
        return IotProvisioningApiFp(this.configuration).deleteThing(thingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Thing
     * @summary Get Thing Details.
     * @param {string} thingId Thing Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IotProvisioningApi
     */
    public getThing(thingId: string, options?: AxiosRequestConfig) {
        return IotProvisioningApiFp(this.configuration).getThing(thingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provisioned Thing certificates
     * @summary Get Provisioned Thing certificates Pre-signed url.
     * @param {string} thingId Thing Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IotProvisioningApi
     */
    public getThingUrl(thingId: string, options?: AxiosRequestConfig) {
        return IotProvisioningApiFp(this.configuration).getThingUrl(thingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of Things with filter
     * @summary List of Things with filter.
     * @param {number} [skip] Number of rows to skip
     * @param {number} [limit] Max number of rows to return
     * @param {string} [companySiteId] 
     * @param {string} [companyId] 
     * @param {string} [name] 
     * @param {'provisioning' | 'provisioned'} [status] Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IotProvisioningApi
     */
    public getThings(skip?: number, limit?: number, companySiteId?: string, companyId?: string, name?: string, status?: 'provisioning' | 'provisioned', options?: AxiosRequestConfig) {
        return IotProvisioningApiFp(this.configuration).getThings(skip, limit, companySiteId, companyId, name, status, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MembershipApi - axios parameter creator
 * @export
 */
export const MembershipApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a new Membership
         * @param {Membership} membership Membership to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMembership: async (membership: Membership, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'membership' is not null or undefined
            assertParamExists('addMembership', 'membership', membership)
            const localVarPath = `/memberships`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(membership, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Membership
         * @param {string} userId User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMembership: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteMembership', 'userId', userId)
            const localVarPath = `/memberships/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Membership Details
         * @param {string} userId User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembership: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getMembership', 'userId', userId)
            const localVarPath = `/memberships/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of memberships
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberships: async (skip?: number, limit?: number, companyId?: string, status?: 'active' | 'inactive', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/memberships`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing Membership
         * @param {string} userId User Id
         * @param {MembershipBase} membershipBase Membership to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMembership: async (userId: string, membershipBase: MembershipBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateMembership', 'userId', userId)
            // verify required parameter 'membershipBase' is not null or undefined
            assertParamExists('updateMembership', 'membershipBase', membershipBase)
            const localVarPath = `/memberships/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(membershipBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User active Permissions
         * @param {string} userId User identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userActivePermissions: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userActivePermissions', 'userId', userId)
            const localVarPath = `/membership/{userId}/permissions`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MembershipApi - functional programming interface
 * @export
 */
export const MembershipApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MembershipApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a new Membership
         * @param {Membership} membership Membership to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMembership(membership: Membership, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Membership>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMembership(membership, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Membership
         * @param {string} userId User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMembership(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Membership>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMembership(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Membership Details
         * @param {string} userId User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMembership(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Membership>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMembership(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of memberships
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberships(skip?: number, limit?: number, companyId?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Membership>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberships(skip, limit, companyId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing Membership
         * @param {string} userId User Id
         * @param {MembershipBase} membershipBase Membership to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMembership(userId: string, membershipBase: MembershipBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Membership>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMembership(userId, membershipBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User active Permissions
         * @param {string} userId User identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userActivePermissions(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userActivePermissions(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MembershipApi - factory interface
 * @export
 */
export const MembershipApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MembershipApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a new Membership
         * @param {Membership} membership Membership to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMembership(membership: Membership, options?: any): AxiosPromise<Membership> {
            return localVarFp.addMembership(membership, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Membership
         * @param {string} userId User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMembership(userId: string, options?: any): AxiosPromise<Membership> {
            return localVarFp.deleteMembership(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Membership Details
         * @param {string} userId User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembership(userId: string, options?: any): AxiosPromise<Membership> {
            return localVarFp.getMembership(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of memberships
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberships(skip?: number, limit?: number, companyId?: string, status?: 'active' | 'inactive', options?: any): AxiosPromise<Array<Membership>> {
            return localVarFp.getMemberships(skip, limit, companyId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing Membership
         * @param {string} userId User Id
         * @param {MembershipBase} membershipBase Membership to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMembership(userId: string, membershipBase: MembershipBase, options?: any): AxiosPromise<Membership> {
            return localVarFp.updateMembership(userId, membershipBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User active Permissions
         * @param {string} userId User identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userActivePermissions(userId: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.userActivePermissions(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MembershipApi - object-oriented interface
 * @export
 * @class MembershipApi
 * @extends {BaseAPI}
 */
export class MembershipApi extends BaseAPI {
    /**
     * 
     * @summary Add a new Membership
     * @param {Membership} membership Membership to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembershipApi
     */
    public addMembership(membership: Membership, options?: AxiosRequestConfig) {
        return MembershipApiFp(this.configuration).addMembership(membership, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Membership
     * @param {string} userId User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembershipApi
     */
    public deleteMembership(userId: string, options?: AxiosRequestConfig) {
        return MembershipApiFp(this.configuration).deleteMembership(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Membership Details
     * @param {string} userId User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembershipApi
     */
    public getMembership(userId: string, options?: AxiosRequestConfig) {
        return MembershipApiFp(this.configuration).getMembership(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of memberships
     * @param {number} [skip] Number of rows to skip
     * @param {number} [limit] Max number of rows to return
     * @param {string} [companyId] Company Id
     * @param {'active' | 'inactive'} [status] Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembershipApi
     */
    public getMemberships(skip?: number, limit?: number, companyId?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig) {
        return MembershipApiFp(this.configuration).getMemberships(skip, limit, companyId, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing Membership
     * @param {string} userId User Id
     * @param {MembershipBase} membershipBase Membership to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembershipApi
     */
    public updateMembership(userId: string, membershipBase: MembershipBase, options?: AxiosRequestConfig) {
        return MembershipApiFp(this.configuration).updateMembership(userId, membershipBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User active Permissions
     * @param {string} userId User identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembershipApi
     */
    public userActivePermissions(userId: string, options?: AxiosRequestConfig) {
        return MembershipApiFp(this.configuration).userActivePermissions(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ModuleApi - axios parameter creator
 * @export
 */
export const ModuleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Module
         * @summary Create a new Module.
         * @param {ModuleBase} moduleBase Module to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createModule: async (moduleBase: ModuleBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'moduleBase' is not null or undefined
            assertParamExists('createModule', 'moduleBase', moduleBase)
            const localVarPath = `/modules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moduleBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Module
         * @summary Delete Module, suitable for testing purposes only, controlled by a permission.
         * @param {string} moduleId Module Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModule: async (moduleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'moduleId' is not null or undefined
            assertParamExists('deleteModule', 'moduleId', moduleId)
            const localVarPath = `/modules/{moduleId}`
                .replace(`{${"moduleId"}}`, encodeURIComponent(String(moduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Module
         * @summary Get Module Details.
         * @param {string} moduleId Module Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModule: async (moduleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'moduleId' is not null or undefined
            assertParamExists('getModule', 'moduleId', moduleId)
            const localVarPath = `/modules/{moduleId}`
                .replace(`{${"moduleId"}}`, encodeURIComponent(String(moduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of Modules with filter
         * @summary List of Modules with filter.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] 
         * @param {string} [companySiteId] 
         * @param {string} [areaId] 
         * @param {string} [systemId] 
         * @param {string} [blockId] 
         * @param {string} [clusterId] 
         * @param {'active' | 'inactive'} [status] Module status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModules: async (skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, systemId?: string, blockId?: string, clusterId?: string, status?: 'active' | 'inactive', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/modules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (companySiteId !== undefined) {
                localVarQueryParameter['companySiteId'] = companySiteId;
            }

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }

            if (systemId !== undefined) {
                localVarQueryParameter['systemId'] = systemId;
            }

            if (blockId !== undefined) {
                localVarQueryParameter['blockId'] = blockId;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Module
         * @summary Update an existing Module.
         * @param {string} moduleId Module Id
         * @param {ModuleBase} moduleBase Module to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModule: async (moduleId: string, moduleBase: ModuleBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'moduleId' is not null or undefined
            assertParamExists('updateModule', 'moduleId', moduleId)
            // verify required parameter 'moduleBase' is not null or undefined
            assertParamExists('updateModule', 'moduleBase', moduleBase)
            const localVarPath = `/modules/{moduleId}`
                .replace(`{${"moduleId"}}`, encodeURIComponent(String(moduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moduleBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModuleApi - functional programming interface
 * @export
 */
export const ModuleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModuleApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Module
         * @summary Create a new Module.
         * @param {ModuleBase} moduleBase Module to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createModule(moduleBase: ModuleBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Module>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createModule(moduleBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Module
         * @summary Delete Module, suitable for testing purposes only, controlled by a permission.
         * @param {string} moduleId Module Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteModule(moduleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Module>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteModule(moduleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Module
         * @summary Get Module Details.
         * @param {string} moduleId Module Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModule(moduleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Module>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModule(moduleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of Modules with filter
         * @summary List of Modules with filter.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] 
         * @param {string} [companySiteId] 
         * @param {string} [areaId] 
         * @param {string} [systemId] 
         * @param {string} [blockId] 
         * @param {string} [clusterId] 
         * @param {'active' | 'inactive'} [status] Module status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModules(skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, systemId?: string, blockId?: string, clusterId?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Module>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModules(skip, limit, companyId, companySiteId, areaId, systemId, blockId, clusterId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing Module
         * @summary Update an existing Module.
         * @param {string} moduleId Module Id
         * @param {ModuleBase} moduleBase Module to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateModule(moduleId: string, moduleBase: ModuleBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Module>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateModule(moduleId, moduleBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ModuleApi - factory interface
 * @export
 */
export const ModuleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModuleApiFp(configuration)
    return {
        /**
         * Create a new Module
         * @summary Create a new Module.
         * @param {ModuleBase} moduleBase Module to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createModule(moduleBase: ModuleBase, options?: any): AxiosPromise<Module> {
            return localVarFp.createModule(moduleBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Module
         * @summary Delete Module, suitable for testing purposes only, controlled by a permission.
         * @param {string} moduleId Module Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModule(moduleId: string, options?: any): AxiosPromise<Module> {
            return localVarFp.deleteModule(moduleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Module
         * @summary Get Module Details.
         * @param {string} moduleId Module Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModule(moduleId: string, options?: any): AxiosPromise<Module> {
            return localVarFp.getModule(moduleId, options).then((request) => request(axios, basePath));
        },
        /**
         * List of Modules with filter
         * @summary List of Modules with filter.
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] 
         * @param {string} [companySiteId] 
         * @param {string} [areaId] 
         * @param {string} [systemId] 
         * @param {string} [blockId] 
         * @param {string} [clusterId] 
         * @param {'active' | 'inactive'} [status] Module status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModules(skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, systemId?: string, blockId?: string, clusterId?: string, status?: 'active' | 'inactive', options?: any): AxiosPromise<Array<Module>> {
            return localVarFp.getModules(skip, limit, companyId, companySiteId, areaId, systemId, blockId, clusterId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Module
         * @summary Update an existing Module.
         * @param {string} moduleId Module Id
         * @param {ModuleBase} moduleBase Module to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModule(moduleId: string, moduleBase: ModuleBase, options?: any): AxiosPromise<Module> {
            return localVarFp.updateModule(moduleId, moduleBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModuleApi - object-oriented interface
 * @export
 * @class ModuleApi
 * @extends {BaseAPI}
 */
export class ModuleApi extends BaseAPI {
    /**
     * Create a new Module
     * @summary Create a new Module.
     * @param {ModuleBase} moduleBase Module to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleApi
     */
    public createModule(moduleBase: ModuleBase, options?: AxiosRequestConfig) {
        return ModuleApiFp(this.configuration).createModule(moduleBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Module
     * @summary Delete Module, suitable for testing purposes only, controlled by a permission.
     * @param {string} moduleId Module Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleApi
     */
    public deleteModule(moduleId: string, options?: AxiosRequestConfig) {
        return ModuleApiFp(this.configuration).deleteModule(moduleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Module
     * @summary Get Module Details.
     * @param {string} moduleId Module Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleApi
     */
    public getModule(moduleId: string, options?: AxiosRequestConfig) {
        return ModuleApiFp(this.configuration).getModule(moduleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of Modules with filter
     * @summary List of Modules with filter.
     * @param {number} [skip] Number of rows to skip
     * @param {number} [limit] Max number of rows to return
     * @param {string} [companyId] 
     * @param {string} [companySiteId] 
     * @param {string} [areaId] 
     * @param {string} [systemId] 
     * @param {string} [blockId] 
     * @param {string} [clusterId] 
     * @param {'active' | 'inactive'} [status] Module status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleApi
     */
    public getModules(skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, systemId?: string, blockId?: string, clusterId?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig) {
        return ModuleApiFp(this.configuration).getModules(skip, limit, companyId, companySiteId, areaId, systemId, blockId, clusterId, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Module
     * @summary Update an existing Module.
     * @param {string} moduleId Module Id
     * @param {ModuleBase} moduleBase Module to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleApi
     */
    public updateModule(moduleId: string, moduleBase: ModuleBase, options?: AxiosRequestConfig) {
        return ModuleApiFp(this.configuration).updateModule(moduleId, moduleBase, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new Report Definition
         * @param {ReportDefinitionBase} reportDefinitionBase Report Definition to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportDefinition: async (reportDefinitionBase: ReportDefinitionBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportDefinitionBase' is not null or undefined
            assertParamExists('createReportDefinition', 'reportDefinitionBase', reportDefinitionBase)
            const localVarPath = `/reportDefinitions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportDefinitionBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an existing Report Definition
         * @param {string} reportDefinitionId Report Definition Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportDefinition: async (reportDefinitionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportDefinitionId' is not null or undefined
            assertParamExists('deleteReportDefinition', 'reportDefinitionId', reportDefinitionId)
            const localVarPath = `/reportDefinitions/{reportDefinitionId}`
                .replace(`{${"reportDefinitionId"}}`, encodeURIComponent(String(reportDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate report
         * @param {ReportDefinitionBase} reportDefinitionBase Report Definition to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReport: async (reportDefinitionBase: ReportDefinitionBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportDefinitionBase' is not null or undefined
            assertParamExists('generateReport', 'reportDefinitionBase', reportDefinitionBase)
            const localVarPath = `/reports/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportDefinitionBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Report
         * @summary Get Report
         * @param {string} reportId Report Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReport', 'reportId', reportId)
            const localVarPath = `/reports/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Report Definition
         * @summary Get Report Definition.
         * @param {string} reportDefinitionId Report Definition Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportDefinition: async (reportDefinitionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportDefinitionId' is not null or undefined
            assertParamExists('getReportDefinition', 'reportDefinitionId', reportDefinitionId)
            const localVarPath = `/reportDefinitions/{reportDefinitionId}`
                .replace(`{${"reportDefinitionId"}}`, encodeURIComponent(String(reportDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Report Definitions
         * @param {string} [companyId] Company Id
         * @param {string} [companySiteId] Company Site Id
         * @param {string} [systemId] System Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportDefinitions: async (companyId?: string, companySiteId?: string, systemId?: string, status?: 'active' | 'inactive', skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reportDefinitions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (companySiteId !== undefined) {
                localVarQueryParameter['companySiteId'] = companySiteId;
            }

            if (systemId !== undefined) {
                localVarQueryParameter['systemId'] = systemId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Reports
         * @param {string} [companyId] Company Id
         * @param {string} [companySiteId] Company Site Id
         * @param {string} [systemId] System Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReports: async (companyId?: string, companySiteId?: string, systemId?: string, status?: 'active' | 'inactive', skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (companySiteId !== undefined) {
                localVarQueryParameter['companySiteId'] = companySiteId;
            }

            if (systemId !== undefined) {
                localVarQueryParameter['systemId'] = systemId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Share Report by attaching it to email
         * @summary Share Report
         * @param {string} reportId Report Id
         * @param {string} emailAddress Email Address to send the report to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportShare: async (reportId: string, emailAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportShare', 'reportId', reportId)
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('reportShare', 'emailAddress', emailAddress)
            const localVarPath = `/reports/{reportId}/share`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing Report Definition
         * @param {string} reportDefinitionId Report Definition Id
         * @param {ReportDefinitionBase} reportDefinitionBase Report Definition to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportDefinition: async (reportDefinitionId: string, reportDefinitionBase: ReportDefinitionBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportDefinitionId' is not null or undefined
            assertParamExists('updateReportDefinition', 'reportDefinitionId', reportDefinitionId)
            // verify required parameter 'reportDefinitionBase' is not null or undefined
            assertParamExists('updateReportDefinition', 'reportDefinitionBase', reportDefinitionBase)
            const localVarPath = `/reportDefinitions/{reportDefinitionId}`
                .replace(`{${"reportDefinitionId"}}`, encodeURIComponent(String(reportDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportDefinitionBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new Report Definition
         * @param {ReportDefinitionBase} reportDefinitionBase Report Definition to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReportDefinition(reportDefinitionBase: ReportDefinitionBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportDefinition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReportDefinition(reportDefinitionBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an existing Report Definition
         * @param {string} reportDefinitionId Report Definition Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReportDefinition(reportDefinitionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportDefinition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReportDefinition(reportDefinitionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate report
         * @param {ReportDefinitionBase} reportDefinitionBase Report Definition to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateReport(reportDefinitionBase: ReportDefinitionBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateReport(reportDefinitionBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Report
         * @summary Get Report
         * @param {string} reportId Report Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReport(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReport(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Report Definition
         * @summary Get Report Definition.
         * @param {string} reportDefinitionId Report Definition Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportDefinition(reportDefinitionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportDefinition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportDefinition(reportDefinitionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Report Definitions
         * @param {string} [companyId] Company Id
         * @param {string} [companySiteId] Company Site Id
         * @param {string} [systemId] System Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportDefinitions(companyId?: string, companySiteId?: string, systemId?: string, status?: 'active' | 'inactive', skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportDefinition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportDefinitions(companyId, companySiteId, systemId, status, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Reports
         * @param {string} [companyId] Company Id
         * @param {string} [companySiteId] Company Site Id
         * @param {string} [systemId] System Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReports(companyId?: string, companySiteId?: string, systemId?: string, status?: 'active' | 'inactive', skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Report>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReports(companyId, companySiteId, systemId, status, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Share Report by attaching it to email
         * @summary Share Report
         * @param {string} reportId Report Id
         * @param {string} emailAddress Email Address to send the report to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportShare(reportId: string, emailAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportShare(reportId, emailAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing Report Definition
         * @param {string} reportDefinitionId Report Definition Id
         * @param {ReportDefinitionBase} reportDefinitionBase Report Definition to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReportDefinition(reportDefinitionId: string, reportDefinitionBase: ReportDefinitionBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportDefinition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReportDefinition(reportDefinitionId, reportDefinitionBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new Report Definition
         * @param {ReportDefinitionBase} reportDefinitionBase Report Definition to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportDefinition(reportDefinitionBase: ReportDefinitionBase, options?: any): AxiosPromise<ReportDefinition> {
            return localVarFp.createReportDefinition(reportDefinitionBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an existing Report Definition
         * @param {string} reportDefinitionId Report Definition Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportDefinition(reportDefinitionId: string, options?: any): AxiosPromise<ReportDefinition> {
            return localVarFp.deleteReportDefinition(reportDefinitionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate report
         * @param {ReportDefinitionBase} reportDefinitionBase Report Definition to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReport(reportDefinitionBase: ReportDefinitionBase, options?: any): AxiosPromise<Report> {
            return localVarFp.generateReport(reportDefinitionBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Report
         * @summary Get Report
         * @param {string} reportId Report Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport(reportId: string, options?: any): AxiosPromise<Report> {
            return localVarFp.getReport(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Report Definition
         * @summary Get Report Definition.
         * @param {string} reportDefinitionId Report Definition Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportDefinition(reportDefinitionId: string, options?: any): AxiosPromise<ReportDefinition> {
            return localVarFp.getReportDefinition(reportDefinitionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Report Definitions
         * @param {string} [companyId] Company Id
         * @param {string} [companySiteId] Company Site Id
         * @param {string} [systemId] System Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportDefinitions(companyId?: string, companySiteId?: string, systemId?: string, status?: 'active' | 'inactive', skip?: number, limit?: number, options?: any): AxiosPromise<Array<ReportDefinition>> {
            return localVarFp.getReportDefinitions(companyId, companySiteId, systemId, status, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Reports
         * @param {string} [companyId] Company Id
         * @param {string} [companySiteId] Company Site Id
         * @param {string} [systemId] System Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReports(companyId?: string, companySiteId?: string, systemId?: string, status?: 'active' | 'inactive', skip?: number, limit?: number, options?: any): AxiosPromise<Array<Report>> {
            return localVarFp.getReports(companyId, companySiteId, systemId, status, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Share Report by attaching it to email
         * @summary Share Report
         * @param {string} reportId Report Id
         * @param {string} emailAddress Email Address to send the report to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportShare(reportId: string, emailAddress: string, options?: any): AxiosPromise<Report> {
            return localVarFp.reportShare(reportId, emailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing Report Definition
         * @param {string} reportDefinitionId Report Definition Id
         * @param {ReportDefinitionBase} reportDefinitionBase Report Definition to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportDefinition(reportDefinitionId: string, reportDefinitionBase: ReportDefinitionBase, options?: any): AxiosPromise<ReportDefinition> {
            return localVarFp.updateReportDefinition(reportDefinitionId, reportDefinitionBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * 
     * @summary Create a new Report Definition
     * @param {ReportDefinitionBase} reportDefinitionBase Report Definition to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public createReportDefinition(reportDefinitionBase: ReportDefinitionBase, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).createReportDefinition(reportDefinitionBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an existing Report Definition
     * @param {string} reportDefinitionId Report Definition Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public deleteReportDefinition(reportDefinitionId: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).deleteReportDefinition(reportDefinitionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate report
     * @param {ReportDefinitionBase} reportDefinitionBase Report Definition to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public generateReport(reportDefinitionBase: ReportDefinitionBase, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).generateReport(reportDefinitionBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Report
     * @summary Get Report
     * @param {string} reportId Report Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public getReport(reportId: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).getReport(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Report Definition
     * @summary Get Report Definition.
     * @param {string} reportDefinitionId Report Definition Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public getReportDefinition(reportDefinitionId: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).getReportDefinition(reportDefinitionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Report Definitions
     * @param {string} [companyId] Company Id
     * @param {string} [companySiteId] Company Site Id
     * @param {string} [systemId] System Id
     * @param {'active' | 'inactive'} [status] Status
     * @param {number} [skip] Number of rows to skip
     * @param {number} [limit] Max number of rows to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public getReportDefinitions(companyId?: string, companySiteId?: string, systemId?: string, status?: 'active' | 'inactive', skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).getReportDefinitions(companyId, companySiteId, systemId, status, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Reports
     * @param {string} [companyId] Company Id
     * @param {string} [companySiteId] Company Site Id
     * @param {string} [systemId] System Id
     * @param {'active' | 'inactive'} [status] Status
     * @param {number} [skip] Number of rows to skip
     * @param {number} [limit] Max number of rows to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public getReports(companyId?: string, companySiteId?: string, systemId?: string, status?: 'active' | 'inactive', skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).getReports(companyId, companySiteId, systemId, status, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Share Report by attaching it to email
     * @summary Share Report
     * @param {string} reportId Report Id
     * @param {string} emailAddress Email Address to send the report to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportShare(reportId: string, emailAddress: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportShare(reportId, emailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing Report Definition
     * @param {string} reportDefinitionId Report Definition Id
     * @param {ReportDefinitionBase} reportDefinitionBase Report Definition to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public updateReportDefinition(reportDefinitionId: string, reportDefinitionBase: ReportDefinitionBase, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).updateReportDefinition(reportDefinitionId, reportDefinitionBase, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Available application roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Available application roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Roles>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Available application roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles(options?: any): AxiosPromise<Array<Roles>> {
            return localVarFp.getRoles(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * 
     * @summary Get Available application roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public getRoles(options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).getRoles(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete settings for Users Push Notifications
         * @summary Delete User Push Notifications Setting.
         * @param {string} id Entity id
         * @param {'push-notifications'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSettingsPushNotifications: async (id: string, type?: 'push-notifications', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSettingsPushNotifications', 'id', id)
            const localVarPath = `/settings/push-notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete settings for Sites
         * @summary Delete site settings.
         * @param {string} id Entity id
         * @param {'sites'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSettingsSites: async (id: string, type?: 'sites', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSettingsSites', 'id', id)
            const localVarPath = `/settings/sites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete settings for Systems
         * @summary Delete User Setting.
         * @param {string} id Entity id
         * @param {'systems'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSettingsSystems: async (id: string, type?: 'systems', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSettingsSystems', 'id', id)
            const localVarPath = `/settings/systems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete settings for Users
         * @summary Delete User Setting.
         * @param {string} id Entity id
         * @param {'users'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSettingsUsers: async (id: string, type?: 'users', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSettingsUsers', 'id', id)
            const localVarPath = `/settings/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete settings for Users Alerts
         * @summary Delete User Alerts Setting.
         * @param {string} id Entity id
         * @param {'alerts'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSettingsUsersAlerts: async (id: string, type?: 'alerts', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSettingsUsersAlerts', 'id', id)
            const localVarPath = `/settings/alerts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get settings for Users Push Notifications
         * @summary Settings for User Push Notifications
         * @param {string} id Entity id
         * @param {'push-notifications'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsPushNotifications: async (id: string, type?: 'push-notifications', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSettingsPushNotifications', 'id', id)
            const localVarPath = `/settings/push-notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get settings for Sites
         * @summary Settings
         * @param {string} id Entity id
         * @param {'sites'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsSites: async (id: string, type?: 'sites', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSettingsSites', 'id', id)
            const localVarPath = `/settings/sites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get settings for Systems
         * @summary Settings
         * @param {string} id Entity id
         * @param {'systems'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsSystems: async (id: string, type?: 'systems', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSettingsSystems', 'id', id)
            const localVarPath = `/settings/systems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get settings for Users
         * @summary Settings
         * @param {string} id Entity id
         * @param {'users'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsUsers: async (id: string, type?: 'users', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSettingsUsers', 'id', id)
            const localVarPath = `/settings/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get settings for Users Alerts
         * @summary Settings for User Alerts
         * @param {string} id Entity id
         * @param {'alerts'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsUsersAlerts: async (id: string, type?: 'alerts', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSettingsUsersAlerts', 'id', id)
            const localVarPath = `/settings/alerts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Test Users Push Notifications
         * @summary Test Push for User Push Notifications
         * @param {string} id Entity id
         * @param {'push-notifications'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestPushNotifications: async (id: string, type?: 'push-notifications', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendTestPushNotifications', 'id', id)
            const localVarPath = `/settings/push-notifications/{id}/test-push`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update settings for Sites
         * @summary Setting updates.
         * @param {string} id Entity id
         * @param {SettingsSites} settingsSites Update/create site settings
         * @param {'sites'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings: async (id: string, settingsSites: SettingsSites, type?: 'sites', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSettings', 'id', id)
            // verify required parameter 'settingsSites' is not null or undefined
            assertParamExists('updateSettings', 'settingsSites', settingsSites)
            const localVarPath = `/settings/sites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingsSites, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update settings for Users Push Notifications
         * @summary User Push Notifications Setting updates.
         * @param {string} id Entity id
         * @param {SettingsPushNotifications} settingsPushNotifications Update/create push notification settings
         * @param {'push-notifications'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettingsPushNotifications: async (id: string, settingsPushNotifications: SettingsPushNotifications, type?: 'push-notifications', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSettingsPushNotifications', 'id', id)
            // verify required parameter 'settingsPushNotifications' is not null or undefined
            assertParamExists('updateSettingsPushNotifications', 'settingsPushNotifications', settingsPushNotifications)
            const localVarPath = `/settings/push-notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingsPushNotifications, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update settings for Systems
         * @summary User Setting updates.
         * @param {string} id Entity id
         * @param {SettingsSystems} settingsSystems Update/create systems settings
         * @param {'systems'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettingsSystems: async (id: string, settingsSystems: SettingsSystems, type?: 'systems', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSettingsSystems', 'id', id)
            // verify required parameter 'settingsSystems' is not null or undefined
            assertParamExists('updateSettingsSystems', 'settingsSystems', settingsSystems)
            const localVarPath = `/settings/systems/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingsSystems, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update settings for Users
         * @summary User Setting updates.
         * @param {string} id Entity id
         * @param {SettingsUsers} settingsUsers Update/create site settings
         * @param {'users'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettingsUsers: async (id: string, settingsUsers: SettingsUsers, type?: 'users', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSettingsUsers', 'id', id)
            // verify required parameter 'settingsUsers' is not null or undefined
            assertParamExists('updateSettingsUsers', 'settingsUsers', settingsUsers)
            const localVarPath = `/settings/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingsUsers, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update settings for Users Alerts
         * @summary User Alert Setting updates.
         * @param {string} id Entity id
         * @param {SettingsUsersAlerts} settingsUsersAlerts Update/create user alert settings
         * @param {'alerts'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettingsUsersAlerts: async (id: string, settingsUsersAlerts: SettingsUsersAlerts, type?: 'alerts', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSettingsUsersAlerts', 'id', id)
            // verify required parameter 'settingsUsersAlerts' is not null or undefined
            assertParamExists('updateSettingsUsersAlerts', 'settingsUsersAlerts', settingsUsersAlerts)
            const localVarPath = `/settings/alerts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingsUsersAlerts, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete settings for Users Push Notifications
         * @summary Delete User Push Notifications Setting.
         * @param {string} id Entity id
         * @param {'push-notifications'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSettingsPushNotifications(id: string, type?: 'push-notifications', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsPushNotifications>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSettingsPushNotifications(id, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete settings for Sites
         * @summary Delete site settings.
         * @param {string} id Entity id
         * @param {'sites'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSettingsSites(id: string, type?: 'sites', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsSites>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSettingsSites(id, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete settings for Systems
         * @summary Delete User Setting.
         * @param {string} id Entity id
         * @param {'systems'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSettingsSystems(id: string, type?: 'systems', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsSystems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSettingsSystems(id, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete settings for Users
         * @summary Delete User Setting.
         * @param {string} id Entity id
         * @param {'users'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSettingsUsers(id: string, type?: 'users', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsUsers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSettingsUsers(id, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete settings for Users Alerts
         * @summary Delete User Alerts Setting.
         * @param {string} id Entity id
         * @param {'alerts'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSettingsUsersAlerts(id: string, type?: 'alerts', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsUsersAlerts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSettingsUsersAlerts(id, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get settings for Users Push Notifications
         * @summary Settings for User Push Notifications
         * @param {string} id Entity id
         * @param {'push-notifications'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettingsPushNotifications(id: string, type?: 'push-notifications', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsPushNotifications>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettingsPushNotifications(id, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get settings for Sites
         * @summary Settings
         * @param {string} id Entity id
         * @param {'sites'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettingsSites(id: string, type?: 'sites', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsSites>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettingsSites(id, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get settings for Systems
         * @summary Settings
         * @param {string} id Entity id
         * @param {'systems'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettingsSystems(id: string, type?: 'systems', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsSystems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettingsSystems(id, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get settings for Users
         * @summary Settings
         * @param {string} id Entity id
         * @param {'users'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettingsUsers(id: string, type?: 'users', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsUsers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettingsUsers(id, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get settings for Users Alerts
         * @summary Settings for User Alerts
         * @param {string} id Entity id
         * @param {'alerts'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettingsUsersAlerts(id: string, type?: 'alerts', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsUsersAlerts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettingsUsersAlerts(id, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Test Users Push Notifications
         * @summary Test Push for User Push Notifications
         * @param {string} id Entity id
         * @param {'push-notifications'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendTestPushNotifications(id: string, type?: 'push-notifications', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsPushNotifications>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendTestPushNotifications(id, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update settings for Sites
         * @summary Setting updates.
         * @param {string} id Entity id
         * @param {SettingsSites} settingsSites Update/create site settings
         * @param {'sites'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSettings(id: string, settingsSites: SettingsSites, type?: 'sites', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsSites>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSettings(id, settingsSites, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update settings for Users Push Notifications
         * @summary User Push Notifications Setting updates.
         * @param {string} id Entity id
         * @param {SettingsPushNotifications} settingsPushNotifications Update/create push notification settings
         * @param {'push-notifications'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSettingsPushNotifications(id: string, settingsPushNotifications: SettingsPushNotifications, type?: 'push-notifications', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsPushNotifications>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSettingsPushNotifications(id, settingsPushNotifications, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update settings for Systems
         * @summary User Setting updates.
         * @param {string} id Entity id
         * @param {SettingsSystems} settingsSystems Update/create systems settings
         * @param {'systems'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSettingsSystems(id: string, settingsSystems: SettingsSystems, type?: 'systems', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsSystems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSettingsSystems(id, settingsSystems, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update settings for Users
         * @summary User Setting updates.
         * @param {string} id Entity id
         * @param {SettingsUsers} settingsUsers Update/create site settings
         * @param {'users'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSettingsUsers(id: string, settingsUsers: SettingsUsers, type?: 'users', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsUsers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSettingsUsers(id, settingsUsers, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update settings for Users Alerts
         * @summary User Alert Setting updates.
         * @param {string} id Entity id
         * @param {SettingsUsersAlerts} settingsUsersAlerts Update/create user alert settings
         * @param {'alerts'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSettingsUsersAlerts(id: string, settingsUsersAlerts: SettingsUsersAlerts, type?: 'alerts', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsUsers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSettingsUsersAlerts(id, settingsUsersAlerts, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsApiFp(configuration)
    return {
        /**
         * Delete settings for Users Push Notifications
         * @summary Delete User Push Notifications Setting.
         * @param {string} id Entity id
         * @param {'push-notifications'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSettingsPushNotifications(id: string, type?: 'push-notifications', options?: any): AxiosPromise<SettingsPushNotifications> {
            return localVarFp.deleteSettingsPushNotifications(id, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete settings for Sites
         * @summary Delete site settings.
         * @param {string} id Entity id
         * @param {'sites'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSettingsSites(id: string, type?: 'sites', options?: any): AxiosPromise<SettingsSites> {
            return localVarFp.deleteSettingsSites(id, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete settings for Systems
         * @summary Delete User Setting.
         * @param {string} id Entity id
         * @param {'systems'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSettingsSystems(id: string, type?: 'systems', options?: any): AxiosPromise<SettingsSystems> {
            return localVarFp.deleteSettingsSystems(id, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete settings for Users
         * @summary Delete User Setting.
         * @param {string} id Entity id
         * @param {'users'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSettingsUsers(id: string, type?: 'users', options?: any): AxiosPromise<SettingsUsers> {
            return localVarFp.deleteSettingsUsers(id, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete settings for Users Alerts
         * @summary Delete User Alerts Setting.
         * @param {string} id Entity id
         * @param {'alerts'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSettingsUsersAlerts(id: string, type?: 'alerts', options?: any): AxiosPromise<SettingsUsersAlerts> {
            return localVarFp.deleteSettingsUsersAlerts(id, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Get settings for Users Push Notifications
         * @summary Settings for User Push Notifications
         * @param {string} id Entity id
         * @param {'push-notifications'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsPushNotifications(id: string, type?: 'push-notifications', options?: any): AxiosPromise<SettingsPushNotifications> {
            return localVarFp.getSettingsPushNotifications(id, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Get settings for Sites
         * @summary Settings
         * @param {string} id Entity id
         * @param {'sites'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsSites(id: string, type?: 'sites', options?: any): AxiosPromise<SettingsSites> {
            return localVarFp.getSettingsSites(id, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Get settings for Systems
         * @summary Settings
         * @param {string} id Entity id
         * @param {'systems'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsSystems(id: string, type?: 'systems', options?: any): AxiosPromise<SettingsSystems> {
            return localVarFp.getSettingsSystems(id, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Get settings for Users
         * @summary Settings
         * @param {string} id Entity id
         * @param {'users'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsUsers(id: string, type?: 'users', options?: any): AxiosPromise<SettingsUsers> {
            return localVarFp.getSettingsUsers(id, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Get settings for Users Alerts
         * @summary Settings for User Alerts
         * @param {string} id Entity id
         * @param {'alerts'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsUsersAlerts(id: string, type?: 'alerts', options?: any): AxiosPromise<SettingsUsersAlerts> {
            return localVarFp.getSettingsUsersAlerts(id, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Test Users Push Notifications
         * @summary Test Push for User Push Notifications
         * @param {string} id Entity id
         * @param {'push-notifications'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestPushNotifications(id: string, type?: 'push-notifications', options?: any): AxiosPromise<SettingsPushNotifications> {
            return localVarFp.sendTestPushNotifications(id, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Update settings for Sites
         * @summary Setting updates.
         * @param {string} id Entity id
         * @param {SettingsSites} settingsSites Update/create site settings
         * @param {'sites'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings(id: string, settingsSites: SettingsSites, type?: 'sites', options?: any): AxiosPromise<SettingsSites> {
            return localVarFp.updateSettings(id, settingsSites, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Update settings for Users Push Notifications
         * @summary User Push Notifications Setting updates.
         * @param {string} id Entity id
         * @param {SettingsPushNotifications} settingsPushNotifications Update/create push notification settings
         * @param {'push-notifications'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettingsPushNotifications(id: string, settingsPushNotifications: SettingsPushNotifications, type?: 'push-notifications', options?: any): AxiosPromise<SettingsPushNotifications> {
            return localVarFp.updateSettingsPushNotifications(id, settingsPushNotifications, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Update settings for Systems
         * @summary User Setting updates.
         * @param {string} id Entity id
         * @param {SettingsSystems} settingsSystems Update/create systems settings
         * @param {'systems'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettingsSystems(id: string, settingsSystems: SettingsSystems, type?: 'systems', options?: any): AxiosPromise<SettingsSystems> {
            return localVarFp.updateSettingsSystems(id, settingsSystems, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Update settings for Users
         * @summary User Setting updates.
         * @param {string} id Entity id
         * @param {SettingsUsers} settingsUsers Update/create site settings
         * @param {'users'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettingsUsers(id: string, settingsUsers: SettingsUsers, type?: 'users', options?: any): AxiosPromise<SettingsUsers> {
            return localVarFp.updateSettingsUsers(id, settingsUsers, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Update settings for Users Alerts
         * @summary User Alert Setting updates.
         * @param {string} id Entity id
         * @param {SettingsUsersAlerts} settingsUsersAlerts Update/create user alert settings
         * @param {'alerts'} [type] Entity type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettingsUsersAlerts(id: string, settingsUsersAlerts: SettingsUsersAlerts, type?: 'alerts', options?: any): AxiosPromise<SettingsUsers> {
            return localVarFp.updateSettingsUsersAlerts(id, settingsUsersAlerts, type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * Delete settings for Users Push Notifications
     * @summary Delete User Push Notifications Setting.
     * @param {string} id Entity id
     * @param {'push-notifications'} [type] Entity type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public deleteSettingsPushNotifications(id: string, type?: 'push-notifications', options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).deleteSettingsPushNotifications(id, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete settings for Sites
     * @summary Delete site settings.
     * @param {string} id Entity id
     * @param {'sites'} [type] Entity type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public deleteSettingsSites(id: string, type?: 'sites', options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).deleteSettingsSites(id, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete settings for Systems
     * @summary Delete User Setting.
     * @param {string} id Entity id
     * @param {'systems'} [type] Entity type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public deleteSettingsSystems(id: string, type?: 'systems', options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).deleteSettingsSystems(id, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete settings for Users
     * @summary Delete User Setting.
     * @param {string} id Entity id
     * @param {'users'} [type] Entity type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public deleteSettingsUsers(id: string, type?: 'users', options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).deleteSettingsUsers(id, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete settings for Users Alerts
     * @summary Delete User Alerts Setting.
     * @param {string} id Entity id
     * @param {'alerts'} [type] Entity type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public deleteSettingsUsersAlerts(id: string, type?: 'alerts', options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).deleteSettingsUsersAlerts(id, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get settings for Users Push Notifications
     * @summary Settings for User Push Notifications
     * @param {string} id Entity id
     * @param {'push-notifications'} [type] Entity type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getSettingsPushNotifications(id: string, type?: 'push-notifications', options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).getSettingsPushNotifications(id, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get settings for Sites
     * @summary Settings
     * @param {string} id Entity id
     * @param {'sites'} [type] Entity type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getSettingsSites(id: string, type?: 'sites', options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).getSettingsSites(id, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get settings for Systems
     * @summary Settings
     * @param {string} id Entity id
     * @param {'systems'} [type] Entity type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getSettingsSystems(id: string, type?: 'systems', options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).getSettingsSystems(id, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get settings for Users
     * @summary Settings
     * @param {string} id Entity id
     * @param {'users'} [type] Entity type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getSettingsUsers(id: string, type?: 'users', options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).getSettingsUsers(id, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get settings for Users Alerts
     * @summary Settings for User Alerts
     * @param {string} id Entity id
     * @param {'alerts'} [type] Entity type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getSettingsUsersAlerts(id: string, type?: 'alerts', options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).getSettingsUsersAlerts(id, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Test Users Push Notifications
     * @summary Test Push for User Push Notifications
     * @param {string} id Entity id
     * @param {'push-notifications'} [type] Entity type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public sendTestPushNotifications(id: string, type?: 'push-notifications', options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).sendTestPushNotifications(id, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update settings for Sites
     * @summary Setting updates.
     * @param {string} id Entity id
     * @param {SettingsSites} settingsSites Update/create site settings
     * @param {'sites'} [type] Entity type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public updateSettings(id: string, settingsSites: SettingsSites, type?: 'sites', options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).updateSettings(id, settingsSites, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update settings for Users Push Notifications
     * @summary User Push Notifications Setting updates.
     * @param {string} id Entity id
     * @param {SettingsPushNotifications} settingsPushNotifications Update/create push notification settings
     * @param {'push-notifications'} [type] Entity type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public updateSettingsPushNotifications(id: string, settingsPushNotifications: SettingsPushNotifications, type?: 'push-notifications', options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).updateSettingsPushNotifications(id, settingsPushNotifications, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update settings for Systems
     * @summary User Setting updates.
     * @param {string} id Entity id
     * @param {SettingsSystems} settingsSystems Update/create systems settings
     * @param {'systems'} [type] Entity type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public updateSettingsSystems(id: string, settingsSystems: SettingsSystems, type?: 'systems', options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).updateSettingsSystems(id, settingsSystems, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update settings for Users
     * @summary User Setting updates.
     * @param {string} id Entity id
     * @param {SettingsUsers} settingsUsers Update/create site settings
     * @param {'users'} [type] Entity type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public updateSettingsUsers(id: string, settingsUsers: SettingsUsers, type?: 'users', options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).updateSettingsUsers(id, settingsUsers, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update settings for Users Alerts
     * @summary User Alert Setting updates.
     * @param {string} id Entity id
     * @param {SettingsUsersAlerts} settingsUsersAlerts Update/create user alert settings
     * @param {'alerts'} [type] Entity type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public updateSettingsUsersAlerts(id: string, settingsUsersAlerts: SettingsUsersAlerts, type?: 'alerts', options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).updateSettingsUsersAlerts(id, settingsUsersAlerts, type, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SupportTicketApi - axios parameter creator
 * @export
 */
export const SupportTicketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add attachment to Jira Service Desk ticket
         * @summary Add attachment to Jira Service Desk ticket
         * @param {string} supportTicketId Support Ticket Id
         * @param {Array<object>} requestBody Attachment to be added to a Jira Service Desk ticket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAttachmentServiceDesk: async (supportTicketId: string, requestBody: Array<object>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'supportTicketId' is not null or undefined
            assertParamExists('addAttachmentServiceDesk', 'supportTicketId', supportTicketId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('addAttachmentServiceDesk', 'requestBody', requestBody)
            const localVarPath = `/support/{supportTicketId}/attachments`
                .replace(`{${"supportTicketId"}}`, encodeURIComponent(String(supportTicketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new SupportTicket
         * @param {SupportTicketBase} supportTicketBase SupportTicket to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupportTicket: async (supportTicketBase: SupportTicketBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'supportTicketBase' is not null or undefined
            assertParamExists('createSupportTicket', 'supportTicketBase', supportTicketBase)
            const localVarPath = `/support`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(supportTicketBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an existing SupportTicket, this will transition the ticket in Jira to closed.
         * @param {string} supportTicketId SupportTicket Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupportTicket: async (supportTicketId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'supportTicketId' is not null or undefined
            assertParamExists('deleteSupportTicket', 'supportTicketId', supportTicketId)
            const localVarPath = `/support/{supportTicketId}`
                .replace(`{${"supportTicketId"}}`, encodeURIComponent(String(supportTicketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get SupportTicket Details
         * @summary Get SupportTicket Details.
         * @param {string} supportTicketId SupportTicket Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportTicket: async (supportTicketId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'supportTicketId' is not null or undefined
            assertParamExists('getSupportTicket', 'supportTicketId', supportTicketId)
            const localVarPath = `/support/{supportTicketId}`
                .replace(`{${"supportTicketId"}}`, encodeURIComponent(String(supportTicketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get SupportTicket
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [companySiteId] Company Site Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportTickets: async (skip?: number, limit?: number, companyId?: string, companySiteId?: string, status?: 'active' | 'inactive', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/support`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (companySiteId !== undefined) {
                localVarQueryParameter['companySiteId'] = companySiteId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing support ticket status with the latest from Jira
         * @summary Update an existing support ticket status with the latest from Jira.
         * @param {string} supportTicketId Support Ticket Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupportTicketCurrentStatus: async (supportTicketId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'supportTicketId' is not null or undefined
            assertParamExists('updateSupportTicketCurrentStatus', 'supportTicketId', supportTicketId)
            const localVarPath = `/support/{supportTicketId}/currentStatus`
                .replace(`{${"supportTicketId"}}`, encodeURIComponent(String(supportTicketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupportTicketApi - functional programming interface
 * @export
 */
export const SupportTicketApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupportTicketApiAxiosParamCreator(configuration)
    return {
        /**
         * Add attachment to Jira Service Desk ticket
         * @summary Add attachment to Jira Service Desk ticket
         * @param {string} supportTicketId Support Ticket Id
         * @param {Array<object>} requestBody Attachment to be added to a Jira Service Desk ticket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAttachmentServiceDesk(supportTicketId: string, requestBody: Array<object>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupportTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAttachmentServiceDesk(supportTicketId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new SupportTicket
         * @param {SupportTicketBase} supportTicketBase SupportTicket to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSupportTicket(supportTicketBase: SupportTicketBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupportTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSupportTicket(supportTicketBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an existing SupportTicket, this will transition the ticket in Jira to closed.
         * @param {string} supportTicketId SupportTicket Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSupportTicket(supportTicketId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupportTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSupportTicket(supportTicketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get SupportTicket Details
         * @summary Get SupportTicket Details.
         * @param {string} supportTicketId SupportTicket Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupportTicket(supportTicketId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupportTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupportTicket(supportTicketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get SupportTicket
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [companySiteId] Company Site Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupportTickets(skip?: number, limit?: number, companyId?: string, companySiteId?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SupportTicket>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupportTickets(skip, limit, companyId, companySiteId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing support ticket status with the latest from Jira
         * @summary Update an existing support ticket status with the latest from Jira.
         * @param {string} supportTicketId Support Ticket Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSupportTicketCurrentStatus(supportTicketId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupportTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSupportTicketCurrentStatus(supportTicketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SupportTicketApi - factory interface
 * @export
 */
export const SupportTicketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupportTicketApiFp(configuration)
    return {
        /**
         * Add attachment to Jira Service Desk ticket
         * @summary Add attachment to Jira Service Desk ticket
         * @param {string} supportTicketId Support Ticket Id
         * @param {Array<object>} requestBody Attachment to be added to a Jira Service Desk ticket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAttachmentServiceDesk(supportTicketId: string, requestBody: Array<object>, options?: any): AxiosPromise<SupportTicket> {
            return localVarFp.addAttachmentServiceDesk(supportTicketId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new SupportTicket
         * @param {SupportTicketBase} supportTicketBase SupportTicket to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupportTicket(supportTicketBase: SupportTicketBase, options?: any): AxiosPromise<SupportTicket> {
            return localVarFp.createSupportTicket(supportTicketBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an existing SupportTicket, this will transition the ticket in Jira to closed.
         * @param {string} supportTicketId SupportTicket Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupportTicket(supportTicketId: string, options?: any): AxiosPromise<SupportTicket> {
            return localVarFp.deleteSupportTicket(supportTicketId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get SupportTicket Details
         * @summary Get SupportTicket Details.
         * @param {string} supportTicketId SupportTicket Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportTicket(supportTicketId: string, options?: any): AxiosPromise<SupportTicket> {
            return localVarFp.getSupportTicket(supportTicketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get SupportTicket
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [companySiteId] Company Site Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportTickets(skip?: number, limit?: number, companyId?: string, companySiteId?: string, status?: 'active' | 'inactive', options?: any): AxiosPromise<Array<SupportTicket>> {
            return localVarFp.getSupportTickets(skip, limit, companyId, companySiteId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing support ticket status with the latest from Jira
         * @summary Update an existing support ticket status with the latest from Jira.
         * @param {string} supportTicketId Support Ticket Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupportTicketCurrentStatus(supportTicketId: string, options?: any): AxiosPromise<SupportTicket> {
            return localVarFp.updateSupportTicketCurrentStatus(supportTicketId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupportTicketApi - object-oriented interface
 * @export
 * @class SupportTicketApi
 * @extends {BaseAPI}
 */
export class SupportTicketApi extends BaseAPI {
    /**
     * Add attachment to Jira Service Desk ticket
     * @summary Add attachment to Jira Service Desk ticket
     * @param {string} supportTicketId Support Ticket Id
     * @param {Array<object>} requestBody Attachment to be added to a Jira Service Desk ticket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketApi
     */
    public addAttachmentServiceDesk(supportTicketId: string, requestBody: Array<object>, options?: AxiosRequestConfig) {
        return SupportTicketApiFp(this.configuration).addAttachmentServiceDesk(supportTicketId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new SupportTicket
     * @param {SupportTicketBase} supportTicketBase SupportTicket to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketApi
     */
    public createSupportTicket(supportTicketBase: SupportTicketBase, options?: AxiosRequestConfig) {
        return SupportTicketApiFp(this.configuration).createSupportTicket(supportTicketBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an existing SupportTicket, this will transition the ticket in Jira to closed.
     * @param {string} supportTicketId SupportTicket Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketApi
     */
    public deleteSupportTicket(supportTicketId: string, options?: AxiosRequestConfig) {
        return SupportTicketApiFp(this.configuration).deleteSupportTicket(supportTicketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get SupportTicket Details
     * @summary Get SupportTicket Details.
     * @param {string} supportTicketId SupportTicket Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketApi
     */
    public getSupportTicket(supportTicketId: string, options?: AxiosRequestConfig) {
        return SupportTicketApiFp(this.configuration).getSupportTicket(supportTicketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get SupportTicket
     * @param {number} [skip] Number of rows to skip
     * @param {number} [limit] Max number of rows to return
     * @param {string} [companyId] Company Id
     * @param {string} [companySiteId] Company Site Id
     * @param {'active' | 'inactive'} [status] Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketApi
     */
    public getSupportTickets(skip?: number, limit?: number, companyId?: string, companySiteId?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig) {
        return SupportTicketApiFp(this.configuration).getSupportTickets(skip, limit, companyId, companySiteId, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing support ticket status with the latest from Jira
     * @summary Update an existing support ticket status with the latest from Jira.
     * @param {string} supportTicketId Support Ticket Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketApi
     */
    public updateSupportTicketCurrentStatus(supportTicketId: string, options?: AxiosRequestConfig) {
        return SupportTicketApiFp(this.configuration).updateSupportTicketCurrentStatus(supportTicketId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new System
         * @param {SystemBase} systemBase System to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSystem: async (systemBase: SystemBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemBase' is not null or undefined
            assertParamExists('createSystem', 'systemBase', systemBase)
            const localVarPath = `/systems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an existing System
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSystem: async (systemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('deleteSystem', 'systemId', systemId)
            const localVarPath = `/systems/{systemId}`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get System Details
         * @summary Get System Details.
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystem: async (systemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('getSystem', 'systemId', systemId)
            const localVarPath = `/systems/{systemId}`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get System CleanUp Live KPI
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemCleanUpKPI: async (systemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('getSystemCleanUpKPI', 'systemId', systemId)
            const localVarPath = `/systems/{systemId}/cleanUpLiveKPI`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get System CleanUp Live Status
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemCleanUpStatus: async (systemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('getSystemCleanUpStatus', 'systemId', systemId)
            const localVarPath = `/systems/{systemId}/cleanUpLiveStatus`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get System count of systems
         * @param {string} entityId companyId or companySiteId
         * @param {'company' | 'companySite'} entityType companyId or companySiteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemCount: async (entityId: string, entityType: 'company' | 'companySite', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getSystemCount', 'entityId', entityId)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getSystemCount', 'entityType', entityType)
            const localVarPath = `/systems/{entityId}/count`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entityType !== undefined) {
                localVarQueryParameter['entityType'] = entityType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get System Live KPI
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemLiveKPI: async (systemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('getSystemLiveKPI', 'systemId', systemId)
            const localVarPath = `/systems/{systemId}/liveKPI`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get System Live Status
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemLiveStatus: async (systemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('getSystemLiveStatus', 'systemId', systemId)
            const localVarPath = `/systems/{systemId}/liveStatus`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get System Live Regeneration
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemRegeneration: async (systemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('getSystemRegeneration', 'systemId', systemId)
            const localVarPath = `/systems/{systemId}/systemLiveRegeneration`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Company Systems
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [companySiteId] Company Site Id
         * @param {string} [areaId] Area Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystems: async (skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, status?: 'active' | 'inactive', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/systems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (companySiteId !== undefined) {
                localVarQueryParameter['companySiteId'] = companySiteId;
            }

            if (areaId !== undefined) {
                localVarQueryParameter['areaId'] = areaId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing System
         * @param {string} systemId System Id
         * @param {SystemBase} systemBase System to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSystem: async (systemId: string, systemBase: SystemBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('updateSystem', 'systemId', systemId)
            // verify required parameter 'systemBase' is not null or undefined
            assertParamExists('updateSystem', 'systemBase', systemBase)
            const localVarPath = `/systems/{systemId}`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new System
         * @param {SystemBase} systemBase System to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSystem(systemBase: SystemBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<System>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSystem(systemBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an existing System
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSystem(systemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<System>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSystem(systemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get System Details
         * @summary Get System Details.
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystem(systemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<System>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystem(systemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get System CleanUp Live KPI
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemCleanUpKPI(systemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemCleanUpLiveKPI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystemCleanUpKPI(systemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get System CleanUp Live Status
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemCleanUpStatus(systemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemCleanUpLiveStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystemCleanUpStatus(systemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get System count of systems
         * @param {string} entityId companyId or companySiteId
         * @param {'company' | 'companySite'} entityType companyId or companySiteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemCount(entityId: string, entityType: 'company' | 'companySite', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystemCount(entityId, entityType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get System Live KPI
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemLiveKPI(systemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemLiveKPI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystemLiveKPI(systemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get System Live Status
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemLiveStatus(systemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemLiveStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystemLiveStatus(systemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get System Live Regeneration
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemRegeneration(systemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemLiveRegeneration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystemRegeneration(systemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Company Systems
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [companySiteId] Company Site Id
         * @param {string} [areaId] Area Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystems(skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<System>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystems(skip, limit, companyId, companySiteId, areaId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing System
         * @param {string} systemId System Id
         * @param {SystemBase} systemBase System to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSystem(systemId: string, systemBase: SystemBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<System>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSystem(systemId, systemBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new System
         * @param {SystemBase} systemBase System to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSystem(systemBase: SystemBase, options?: any): AxiosPromise<System> {
            return localVarFp.createSystem(systemBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an existing System
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSystem(systemId: string, options?: any): AxiosPromise<System> {
            return localVarFp.deleteSystem(systemId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get System Details
         * @summary Get System Details.
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystem(systemId: string, options?: any): AxiosPromise<System> {
            return localVarFp.getSystem(systemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get System CleanUp Live KPI
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemCleanUpKPI(systemId: string, options?: any): AxiosPromise<SystemCleanUpLiveKPI> {
            return localVarFp.getSystemCleanUpKPI(systemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get System CleanUp Live Status
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemCleanUpStatus(systemId: string, options?: any): AxiosPromise<SystemCleanUpLiveStatus> {
            return localVarFp.getSystemCleanUpStatus(systemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get System count of systems
         * @param {string} entityId companyId or companySiteId
         * @param {'company' | 'companySite'} entityType companyId or companySiteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemCount(entityId: string, entityType: 'company' | 'companySite', options?: any): AxiosPromise<SystemCount> {
            return localVarFp.getSystemCount(entityId, entityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get System Live KPI
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemLiveKPI(systemId: string, options?: any): AxiosPromise<SystemLiveKPI> {
            return localVarFp.getSystemLiveKPI(systemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get System Live Status
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemLiveStatus(systemId: string, options?: any): AxiosPromise<SystemLiveStatus> {
            return localVarFp.getSystemLiveStatus(systemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get System Live Regeneration
         * @param {string} systemId System Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemRegeneration(systemId: string, options?: any): AxiosPromise<SystemLiveRegeneration> {
            return localVarFp.getSystemRegeneration(systemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Company Systems
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [companySiteId] Company Site Id
         * @param {string} [areaId] Area Id
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystems(skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, status?: 'active' | 'inactive', options?: any): AxiosPromise<Array<System>> {
            return localVarFp.getSystems(skip, limit, companyId, companySiteId, areaId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing System
         * @param {string} systemId System Id
         * @param {SystemBase} systemBase System to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSystem(systemId: string, systemBase: SystemBase, options?: any): AxiosPromise<System> {
            return localVarFp.updateSystem(systemId, systemBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * 
     * @summary Create a new System
     * @param {SystemBase} systemBase System to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public createSystem(systemBase: SystemBase, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).createSystem(systemBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an existing System
     * @param {string} systemId System Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public deleteSystem(systemId: string, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).deleteSystem(systemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get System Details
     * @summary Get System Details.
     * @param {string} systemId System Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getSystem(systemId: string, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).getSystem(systemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get System CleanUp Live KPI
     * @param {string} systemId System Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getSystemCleanUpKPI(systemId: string, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).getSystemCleanUpKPI(systemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get System CleanUp Live Status
     * @param {string} systemId System Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getSystemCleanUpStatus(systemId: string, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).getSystemCleanUpStatus(systemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get System count of systems
     * @param {string} entityId companyId or companySiteId
     * @param {'company' | 'companySite'} entityType companyId or companySiteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getSystemCount(entityId: string, entityType: 'company' | 'companySite', options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).getSystemCount(entityId, entityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get System Live KPI
     * @param {string} systemId System Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getSystemLiveKPI(systemId: string, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).getSystemLiveKPI(systemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get System Live Status
     * @param {string} systemId System Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getSystemLiveStatus(systemId: string, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).getSystemLiveStatus(systemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get System Live Regeneration
     * @param {string} systemId System Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getSystemRegeneration(systemId: string, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).getSystemRegeneration(systemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Company Systems
     * @param {number} [skip] Number of rows to skip
     * @param {number} [limit] Max number of rows to return
     * @param {string} [companyId] Company Id
     * @param {string} [companySiteId] Company Site Id
     * @param {string} [areaId] Area Id
     * @param {'active' | 'inactive'} [status] Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getSystems(skip?: number, limit?: number, companyId?: string, companySiteId?: string, areaId?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).getSystems(skip, limit, companyId, companySiteId, areaId, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing System
     * @param {string} systemId System Id
     * @param {SystemBase} systemBase System to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public updateSystem(systemId: string, systemBase: SystemBase, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).updateSystem(systemId, systemBase, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SystemStatsApi - axios parameter creator
 * @export
 */
export const SystemStatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List of System Stats Cleaning Consumption
         * @summary List of System Stats Cleaning Consumption
         * @param {string} systemId System Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} compareStartTime 
         * @param {string} compareEndTime 
         * @param {'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'} [interval] Interval
         * @param {string} [filterRunId] 
         * @param {string} [compareFilterRunId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemStatsCleaningConsumption: async (systemId: string, startTime: string, endTime: string, compareStartTime: string, compareEndTime: string, interval?: 'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly', filterRunId?: string, compareFilterRunId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('getSystemStatsCleaningConsumption', 'systemId', systemId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getSystemStatsCleaningConsumption', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getSystemStatsCleaningConsumption', 'endTime', endTime)
            // verify required parameter 'compareStartTime' is not null or undefined
            assertParamExists('getSystemStatsCleaningConsumption', 'compareStartTime', compareStartTime)
            // verify required parameter 'compareEndTime' is not null or undefined
            assertParamExists('getSystemStatsCleaningConsumption', 'compareEndTime', compareEndTime)
            const localVarPath = `/system-stats/{systemId}/cleaningConsumption`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (compareStartTime !== undefined) {
                localVarQueryParameter['compareStartTime'] = (compareStartTime as any instanceof Date) ?
                    (compareStartTime as any).toISOString() :
                    compareStartTime;
            }

            if (compareEndTime !== undefined) {
                localVarQueryParameter['compareEndTime'] = (compareEndTime as any instanceof Date) ?
                    (compareEndTime as any).toISOString() :
                    compareEndTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (filterRunId !== undefined) {
                localVarQueryParameter['filterRunId'] = filterRunId;
            }

            if (compareFilterRunId !== undefined) {
                localVarQueryParameter['compareFilterRunId'] = compareFilterRunId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve Export Pre-signed URL for Statistics Data.  Note: Either `filterRunId` OR both `startTime` and `endTime` must be provided.
         * @summary Retrieve Export Pre-signed URL for Statistics Data
         * @param {string} systemId System Id
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {string} [filterRunId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemStatsExportStats: async (systemId: string, startTime?: string, endTime?: string, filterRunId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('getSystemStatsExportStats', 'systemId', systemId)
            const localVarPath = `/system-stats/{systemId}/exportStats`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (filterRunId !== undefined) {
                localVarQueryParameter['filterRunId'] = filterRunId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of System Stats Process
         * @summary List of System Stats Process
         * @param {string} systemId System Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} compareStartTime 
         * @param {string} compareEndTime 
         * @param {'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'} [interval] Interval
         * @param {string} [filterRunId] 
         * @param {string} [compareFilterRunId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemStatsProcess: async (systemId: string, startTime: string, endTime: string, compareStartTime: string, compareEndTime: string, interval?: 'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly', filterRunId?: string, compareFilterRunId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('getSystemStatsProcess', 'systemId', systemId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getSystemStatsProcess', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getSystemStatsProcess', 'endTime', endTime)
            // verify required parameter 'compareStartTime' is not null or undefined
            assertParamExists('getSystemStatsProcess', 'compareStartTime', compareStartTime)
            // verify required parameter 'compareEndTime' is not null or undefined
            assertParamExists('getSystemStatsProcess', 'compareEndTime', compareEndTime)
            const localVarPath = `/system-stats/{systemId}/process`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (compareStartTime !== undefined) {
                localVarQueryParameter['compareStartTime'] = (compareStartTime as any instanceof Date) ?
                    (compareStartTime as any).toISOString() :
                    compareStartTime;
            }

            if (compareEndTime !== undefined) {
                localVarQueryParameter['compareEndTime'] = (compareEndTime as any instanceof Date) ?
                    (compareEndTime as any).toISOString() :
                    compareEndTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (filterRunId !== undefined) {
                localVarQueryParameter['filterRunId'] = filterRunId;
            }

            if (compareFilterRunId !== undefined) {
                localVarQueryParameter['compareFilterRunId'] = compareFilterRunId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of System Stats Quality
         * @summary List of System Stats Quality
         * @param {string} systemId System Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} compareStartTime 
         * @param {string} compareEndTime 
         * @param {'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'} [interval] Interval
         * @param {string} [filterRunId] 
         * @param {string} [compareFilterRunId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemStatsQuality: async (systemId: string, startTime: string, endTime: string, compareStartTime: string, compareEndTime: string, interval?: 'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly', filterRunId?: string, compareFilterRunId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('getSystemStatsQuality', 'systemId', systemId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getSystemStatsQuality', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getSystemStatsQuality', 'endTime', endTime)
            // verify required parameter 'compareStartTime' is not null or undefined
            assertParamExists('getSystemStatsQuality', 'compareStartTime', compareStartTime)
            // verify required parameter 'compareEndTime' is not null or undefined
            assertParamExists('getSystemStatsQuality', 'compareEndTime', compareEndTime)
            const localVarPath = `/system-stats/{systemId}/quality`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (compareStartTime !== undefined) {
                localVarQueryParameter['compareStartTime'] = (compareStartTime as any instanceof Date) ?
                    (compareStartTime as any).toISOString() :
                    compareStartTime;
            }

            if (compareEndTime !== undefined) {
                localVarQueryParameter['compareEndTime'] = (compareEndTime as any instanceof Date) ?
                    (compareEndTime as any).toISOString() :
                    compareEndTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (filterRunId !== undefined) {
                localVarQueryParameter['filterRunId'] = filterRunId;
            }

            if (compareFilterRunId !== undefined) {
                localVarQueryParameter['compareFilterRunId'] = compareFilterRunId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of System Stats  Regeneration
         * @summary List of System Stats  Regeneration
         * @param {string} systemId System Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} compareStartTime 
         * @param {string} compareEndTime 
         * @param {'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'} [interval] Interval
         * @param {string} [filterRunId] 
         * @param {string} [compareFilterRunId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemStatsRegeneration: async (systemId: string, startTime: string, endTime: string, compareStartTime: string, compareEndTime: string, interval?: 'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly', filterRunId?: string, compareFilterRunId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('getSystemStatsRegeneration', 'systemId', systemId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getSystemStatsRegeneration', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getSystemStatsRegeneration', 'endTime', endTime)
            // verify required parameter 'compareStartTime' is not null or undefined
            assertParamExists('getSystemStatsRegeneration', 'compareStartTime', compareStartTime)
            // verify required parameter 'compareEndTime' is not null or undefined
            assertParamExists('getSystemStatsRegeneration', 'compareEndTime', compareEndTime)
            const localVarPath = `/system-stats/{systemId}/regeneration`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (compareStartTime !== undefined) {
                localVarQueryParameter['compareStartTime'] = (compareStartTime as any instanceof Date) ?
                    (compareStartTime as any).toISOString() :
                    compareStartTime;
            }

            if (compareEndTime !== undefined) {
                localVarQueryParameter['compareEndTime'] = (compareEndTime as any instanceof Date) ?
                    (compareEndTime as any).toISOString() :
                    compareEndTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (filterRunId !== undefined) {
                localVarQueryParameter['filterRunId'] = filterRunId;
            }

            if (compareFilterRunId !== undefined) {
                localVarQueryParameter['compareFilterRunId'] = compareFilterRunId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemStatsApi - functional programming interface
 * @export
 */
export const SystemStatsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemStatsApiAxiosParamCreator(configuration)
    return {
        /**
         * List of System Stats Cleaning Consumption
         * @summary List of System Stats Cleaning Consumption
         * @param {string} systemId System Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} compareStartTime 
         * @param {string} compareEndTime 
         * @param {'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'} [interval] Interval
         * @param {string} [filterRunId] 
         * @param {string} [compareFilterRunId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemStatsCleaningConsumption(systemId: string, startTime: string, endTime: string, compareStartTime: string, compareEndTime: string, interval?: 'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly', filterRunId?: string, compareFilterRunId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemStatsCleaningConsumption>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystemStatsCleaningConsumption(systemId, startTime, endTime, compareStartTime, compareEndTime, interval, filterRunId, compareFilterRunId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve Export Pre-signed URL for Statistics Data.  Note: Either `filterRunId` OR both `startTime` and `endTime` must be provided.
         * @summary Retrieve Export Pre-signed URL for Statistics Data
         * @param {string} systemId System Id
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {string} [filterRunId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemStatsExportStats(systemId: string, startTime?: string, endTime?: string, filterRunId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemStatsExportStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystemStatsExportStats(systemId, startTime, endTime, filterRunId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of System Stats Process
         * @summary List of System Stats Process
         * @param {string} systemId System Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} compareStartTime 
         * @param {string} compareEndTime 
         * @param {'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'} [interval] Interval
         * @param {string} [filterRunId] 
         * @param {string} [compareFilterRunId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemStatsProcess(systemId: string, startTime: string, endTime: string, compareStartTime: string, compareEndTime: string, interval?: 'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly', filterRunId?: string, compareFilterRunId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemStatsProcess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystemStatsProcess(systemId, startTime, endTime, compareStartTime, compareEndTime, interval, filterRunId, compareFilterRunId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of System Stats Quality
         * @summary List of System Stats Quality
         * @param {string} systemId System Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} compareStartTime 
         * @param {string} compareEndTime 
         * @param {'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'} [interval] Interval
         * @param {string} [filterRunId] 
         * @param {string} [compareFilterRunId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemStatsQuality(systemId: string, startTime: string, endTime: string, compareStartTime: string, compareEndTime: string, interval?: 'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly', filterRunId?: string, compareFilterRunId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemStatsQuality>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystemStatsQuality(systemId, startTime, endTime, compareStartTime, compareEndTime, interval, filterRunId, compareFilterRunId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of System Stats  Regeneration
         * @summary List of System Stats  Regeneration
         * @param {string} systemId System Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} compareStartTime 
         * @param {string} compareEndTime 
         * @param {'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'} [interval] Interval
         * @param {string} [filterRunId] 
         * @param {string} [compareFilterRunId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemStatsRegeneration(systemId: string, startTime: string, endTime: string, compareStartTime: string, compareEndTime: string, interval?: 'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly', filterRunId?: string, compareFilterRunId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemStatsRegeneration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystemStatsRegeneration(systemId, startTime, endTime, compareStartTime, compareEndTime, interval, filterRunId, compareFilterRunId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemStatsApi - factory interface
 * @export
 */
export const SystemStatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemStatsApiFp(configuration)
    return {
        /**
         * List of System Stats Cleaning Consumption
         * @summary List of System Stats Cleaning Consumption
         * @param {string} systemId System Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} compareStartTime 
         * @param {string} compareEndTime 
         * @param {'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'} [interval] Interval
         * @param {string} [filterRunId] 
         * @param {string} [compareFilterRunId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemStatsCleaningConsumption(systemId: string, startTime: string, endTime: string, compareStartTime: string, compareEndTime: string, interval?: 'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly', filterRunId?: string, compareFilterRunId?: string, options?: any): AxiosPromise<SystemStatsCleaningConsumption> {
            return localVarFp.getSystemStatsCleaningConsumption(systemId, startTime, endTime, compareStartTime, compareEndTime, interval, filterRunId, compareFilterRunId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve Export Pre-signed URL for Statistics Data.  Note: Either `filterRunId` OR both `startTime` and `endTime` must be provided.
         * @summary Retrieve Export Pre-signed URL for Statistics Data
         * @param {string} systemId System Id
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {string} [filterRunId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemStatsExportStats(systemId: string, startTime?: string, endTime?: string, filterRunId?: string, options?: any): AxiosPromise<SystemStatsExportStats> {
            return localVarFp.getSystemStatsExportStats(systemId, startTime, endTime, filterRunId, options).then((request) => request(axios, basePath));
        },
        /**
         * List of System Stats Process
         * @summary List of System Stats Process
         * @param {string} systemId System Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} compareStartTime 
         * @param {string} compareEndTime 
         * @param {'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'} [interval] Interval
         * @param {string} [filterRunId] 
         * @param {string} [compareFilterRunId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemStatsProcess(systemId: string, startTime: string, endTime: string, compareStartTime: string, compareEndTime: string, interval?: 'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly', filterRunId?: string, compareFilterRunId?: string, options?: any): AxiosPromise<SystemStatsProcess> {
            return localVarFp.getSystemStatsProcess(systemId, startTime, endTime, compareStartTime, compareEndTime, interval, filterRunId, compareFilterRunId, options).then((request) => request(axios, basePath));
        },
        /**
         * List of System Stats Quality
         * @summary List of System Stats Quality
         * @param {string} systemId System Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} compareStartTime 
         * @param {string} compareEndTime 
         * @param {'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'} [interval] Interval
         * @param {string} [filterRunId] 
         * @param {string} [compareFilterRunId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemStatsQuality(systemId: string, startTime: string, endTime: string, compareStartTime: string, compareEndTime: string, interval?: 'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly', filterRunId?: string, compareFilterRunId?: string, options?: any): AxiosPromise<SystemStatsQuality> {
            return localVarFp.getSystemStatsQuality(systemId, startTime, endTime, compareStartTime, compareEndTime, interval, filterRunId, compareFilterRunId, options).then((request) => request(axios, basePath));
        },
        /**
         * List of System Stats  Regeneration
         * @summary List of System Stats  Regeneration
         * @param {string} systemId System Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} compareStartTime 
         * @param {string} compareEndTime 
         * @param {'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'} [interval] Interval
         * @param {string} [filterRunId] 
         * @param {string} [compareFilterRunId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemStatsRegeneration(systemId: string, startTime: string, endTime: string, compareStartTime: string, compareEndTime: string, interval?: 'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly', filterRunId?: string, compareFilterRunId?: string, options?: any): AxiosPromise<SystemStatsRegeneration> {
            return localVarFp.getSystemStatsRegeneration(systemId, startTime, endTime, compareStartTime, compareEndTime, interval, filterRunId, compareFilterRunId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemStatsApi - object-oriented interface
 * @export
 * @class SystemStatsApi
 * @extends {BaseAPI}
 */
export class SystemStatsApi extends BaseAPI {
    /**
     * List of System Stats Cleaning Consumption
     * @summary List of System Stats Cleaning Consumption
     * @param {string} systemId System Id
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} compareStartTime 
     * @param {string} compareEndTime 
     * @param {'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'} [interval] Interval
     * @param {string} [filterRunId] 
     * @param {string} [compareFilterRunId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemStatsApi
     */
    public getSystemStatsCleaningConsumption(systemId: string, startTime: string, endTime: string, compareStartTime: string, compareEndTime: string, interval?: 'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly', filterRunId?: string, compareFilterRunId?: string, options?: AxiosRequestConfig) {
        return SystemStatsApiFp(this.configuration).getSystemStatsCleaningConsumption(systemId, startTime, endTime, compareStartTime, compareEndTime, interval, filterRunId, compareFilterRunId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve Export Pre-signed URL for Statistics Data.  Note: Either `filterRunId` OR both `startTime` and `endTime` must be provided.
     * @summary Retrieve Export Pre-signed URL for Statistics Data
     * @param {string} systemId System Id
     * @param {string} [startTime] 
     * @param {string} [endTime] 
     * @param {string} [filterRunId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemStatsApi
     */
    public getSystemStatsExportStats(systemId: string, startTime?: string, endTime?: string, filterRunId?: string, options?: AxiosRequestConfig) {
        return SystemStatsApiFp(this.configuration).getSystemStatsExportStats(systemId, startTime, endTime, filterRunId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of System Stats Process
     * @summary List of System Stats Process
     * @param {string} systemId System Id
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} compareStartTime 
     * @param {string} compareEndTime 
     * @param {'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'} [interval] Interval
     * @param {string} [filterRunId] 
     * @param {string} [compareFilterRunId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemStatsApi
     */
    public getSystemStatsProcess(systemId: string, startTime: string, endTime: string, compareStartTime: string, compareEndTime: string, interval?: 'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly', filterRunId?: string, compareFilterRunId?: string, options?: AxiosRequestConfig) {
        return SystemStatsApiFp(this.configuration).getSystemStatsProcess(systemId, startTime, endTime, compareStartTime, compareEndTime, interval, filterRunId, compareFilterRunId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of System Stats Quality
     * @summary List of System Stats Quality
     * @param {string} systemId System Id
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} compareStartTime 
     * @param {string} compareEndTime 
     * @param {'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'} [interval] Interval
     * @param {string} [filterRunId] 
     * @param {string} [compareFilterRunId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemStatsApi
     */
    public getSystemStatsQuality(systemId: string, startTime: string, endTime: string, compareStartTime: string, compareEndTime: string, interval?: 'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly', filterRunId?: string, compareFilterRunId?: string, options?: AxiosRequestConfig) {
        return SystemStatsApiFp(this.configuration).getSystemStatsQuality(systemId, startTime, endTime, compareStartTime, compareEndTime, interval, filterRunId, compareFilterRunId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of System Stats  Regeneration
     * @summary List of System Stats  Regeneration
     * @param {string} systemId System Id
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} compareStartTime 
     * @param {string} compareEndTime 
     * @param {'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'} [interval] Interval
     * @param {string} [filterRunId] 
     * @param {string} [compareFilterRunId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemStatsApi
     */
    public getSystemStatsRegeneration(systemId: string, startTime: string, endTime: string, compareStartTime: string, compareEndTime: string, interval?: 'run' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly', filterRunId?: string, compareFilterRunId?: string, options?: AxiosRequestConfig) {
        return SystemStatsApiFp(this.configuration).getSystemStatsRegeneration(systemId, startTime, endTime, compareStartTime, compareEndTime, interval, filterRunId, compareFilterRunId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SystemTelemetryExportApi - axios parameter creator
 * @export
 */
export const SystemTelemetryExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve Export Pre-signed URL for Telemetry Data.
         * @summary Retrieve Export Pre-signed URL for Telemetry Data.
         * @param {string} systemId System Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemStatsExportTelemetry: async (systemId: string, startTime: string, endTime: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemId' is not null or undefined
            assertParamExists('getSystemStatsExportTelemetry', 'systemId', systemId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getSystemStatsExportTelemetry', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getSystemStatsExportTelemetry', 'endTime', endTime)
            const localVarPath = `/system-stats/{systemId}/exportTelemetry`
                .replace(`{${"systemId"}}`, encodeURIComponent(String(systemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemTelemetryExportApi - functional programming interface
 * @export
 */
export const SystemTelemetryExportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemTelemetryExportApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve Export Pre-signed URL for Telemetry Data.
         * @summary Retrieve Export Pre-signed URL for Telemetry Data.
         * @param {string} systemId System Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemStatsExportTelemetry(systemId: string, startTime: string, endTime: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemStatsExportTelemetry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystemStatsExportTelemetry(systemId, startTime, endTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemTelemetryExportApi - factory interface
 * @export
 */
export const SystemTelemetryExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemTelemetryExportApiFp(configuration)
    return {
        /**
         * Retrieve Export Pre-signed URL for Telemetry Data.
         * @summary Retrieve Export Pre-signed URL for Telemetry Data.
         * @param {string} systemId System Id
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemStatsExportTelemetry(systemId: string, startTime: string, endTime: string, options?: any): AxiosPromise<SystemStatsExportTelemetry> {
            return localVarFp.getSystemStatsExportTelemetry(systemId, startTime, endTime, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemTelemetryExportApi - object-oriented interface
 * @export
 * @class SystemTelemetryExportApi
 * @extends {BaseAPI}
 */
export class SystemTelemetryExportApi extends BaseAPI {
    /**
     * Retrieve Export Pre-signed URL for Telemetry Data.
     * @summary Retrieve Export Pre-signed URL for Telemetry Data.
     * @param {string} systemId System Id
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemTelemetryExportApi
     */
    public getSystemStatsExportTelemetry(systemId: string, startTime: string, endTime: string, options?: AxiosRequestConfig) {
        return SystemTelemetryExportApiFp(this.configuration).getSystemStatsExportTelemetry(systemId, startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Confirm forgot password flow and update password
         * @param {string} userId User Id
         * @param {UserConfirmForgotPassword} userConfirmForgotPassword Use to update users password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmForgotPassword: async (userId: string, userConfirmForgotPassword: UserConfirmForgotPassword, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('confirmForgotPassword', 'userId', userId)
            // verify required parameter 'userConfirmForgotPassword' is not null or undefined
            assertParamExists('confirmForgotPassword', 'userConfirmForgotPassword', userConfirmForgotPassword)
            const localVarPath = `/users/{userId}/confirmforgotpassword`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userConfirmForgotPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new User
         * @param {UserBase} userBase User to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userBase: UserBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userBase' is not null or undefined
            assertParamExists('createUser', 'userBase', userBase)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete User
         * @summary Delete User, suitable for testing purposes only, controlled by a permission.
         * @param {string} userId User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get User Details
         * @summary Get User Details.
         * @param {string} userId User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUser', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get User Live Status
         * @summary Get User Live Status.
         * @param {string} userId User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLiveStatus: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserLiveStatus', 'userId', userId)
            const localVarPath = `/users/{userId}/liveStatus`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Users
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [email] Email
         * @param {string} [firstName] First name
         * @param {string} [lastName] Last name
         * @param {string} [phoneNumber] Phone number
         * @param {string} [sub] User sub
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (skip?: number, limit?: number, companyId?: string, email?: string, firstName?: string, lastName?: string, phoneNumber?: string, sub?: string, status?: 'active' | 'inactive', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }

            if (sub !== undefined) {
                localVarQueryParameter['sub'] = sub;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Trigger forgot password flow
         * @param {string} userId User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerForgotPassword: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('triggerForgotPassword', 'userId', userId)
            const localVarPath = `/users/{userId}/forgotpassword`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing User
         * @summary Update an existing User.
         * @param {string} userId User Id
         * @param {UserBase} userBase User to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, userBase: UserBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            // verify required parameter 'userBase' is not null or undefined
            assertParamExists('updateUser', 'userBase', userBase)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing User Live Status
         * @summary Update an existing User Live Status.
         * @param {string} userId User Id
         * @param {UserLiveStatusBase} userLiveStatusBase User Live Status to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserLiveStatus: async (userId: string, userLiveStatusBase: UserLiveStatusBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserLiveStatus', 'userId', userId)
            // verify required parameter 'userLiveStatusBase' is not null or undefined
            assertParamExists('updateUserLiveStatus', 'userLiveStatusBase', userLiveStatusBase)
            const localVarPath = `/users/{userId}/liveStatus`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userLiveStatusBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing User Password
         * @param {string} userId User Id
         * @param {UserPassword} userPassword Use to update users password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPassword: async (userId: string, userPassword: UserPassword, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserPassword', 'userId', userId)
            // verify required parameter 'userPassword' is not null or undefined
            assertParamExists('updateUserPassword', 'userPassword', userPassword)
            const localVarPath = `/users/{userId}/password`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Confirm forgot password flow and update password
         * @param {string} userId User Id
         * @param {UserConfirmForgotPassword} userConfirmForgotPassword Use to update users password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmForgotPassword(userId: string, userConfirmForgotPassword: UserConfirmForgotPassword, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmForgotPassword(userId, userConfirmForgotPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new User
         * @param {UserBase} userBase User to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userBase: UserBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete User
         * @summary Delete User, suitable for testing purposes only, controlled by a permission.
         * @param {string} userId User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get User Details
         * @summary Get User Details.
         * @param {string} userId User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get User Live Status
         * @summary Get User Live Status.
         * @param {string} userId User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserLiveStatus(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLiveStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserLiveStatus(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Users
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [email] Email
         * @param {string} [firstName] First name
         * @param {string} [lastName] Last name
         * @param {string} [phoneNumber] Phone number
         * @param {string} [sub] User sub
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(skip?: number, limit?: number, companyId?: string, email?: string, firstName?: string, lastName?: string, phoneNumber?: string, sub?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(skip, limit, companyId, email, firstName, lastName, phoneNumber, sub, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Trigger forgot password flow
         * @param {string} userId User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerForgotPassword(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerForgotPassword(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing User
         * @summary Update an existing User.
         * @param {string} userId User Id
         * @param {UserBase} userBase User to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, userBase: UserBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, userBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing User Live Status
         * @summary Update an existing User Live Status.
         * @param {string} userId User Id
         * @param {UserLiveStatusBase} userLiveStatusBase User Live Status to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserLiveStatus(userId: string, userLiveStatusBase: UserLiveStatusBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLiveStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserLiveStatus(userId, userLiveStatusBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing User Password
         * @param {string} userId User Id
         * @param {UserPassword} userPassword Use to update users password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPassword(userId: string, userPassword: UserPassword, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPassword(userId, userPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Confirm forgot password flow and update password
         * @param {string} userId User Id
         * @param {UserConfirmForgotPassword} userConfirmForgotPassword Use to update users password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmForgotPassword(userId: string, userConfirmForgotPassword: UserConfirmForgotPassword, options?: any): AxiosPromise<User> {
            return localVarFp.confirmForgotPassword(userId, userConfirmForgotPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new User
         * @param {UserBase} userBase User to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userBase: UserBase, options?: any): AxiosPromise<User> {
            return localVarFp.createUser(userBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete User
         * @summary Delete User, suitable for testing purposes only, controlled by a permission.
         * @param {string} userId User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: string, options?: any): AxiosPromise<User> {
            return localVarFp.deleteUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get User Details
         * @summary Get User Details.
         * @param {string} userId User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: string, options?: any): AxiosPromise<User> {
            return localVarFp.getUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get User Live Status
         * @summary Get User Live Status.
         * @param {string} userId User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLiveStatus(userId: string, options?: any): AxiosPromise<UserLiveStatus> {
            return localVarFp.getUserLiveStatus(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Users
         * @param {number} [skip] Number of rows to skip
         * @param {number} [limit] Max number of rows to return
         * @param {string} [companyId] Company Id
         * @param {string} [email] Email
         * @param {string} [firstName] First name
         * @param {string} [lastName] Last name
         * @param {string} [phoneNumber] Phone number
         * @param {string} [sub] User sub
         * @param {'active' | 'inactive'} [status] Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(skip?: number, limit?: number, companyId?: string, email?: string, firstName?: string, lastName?: string, phoneNumber?: string, sub?: string, status?: 'active' | 'inactive', options?: any): AxiosPromise<Array<User>> {
            return localVarFp.getUsers(skip, limit, companyId, email, firstName, lastName, phoneNumber, sub, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Trigger forgot password flow
         * @param {string} userId User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerForgotPassword(userId: string, options?: any): AxiosPromise<User> {
            return localVarFp.triggerForgotPassword(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing User
         * @summary Update an existing User.
         * @param {string} userId User Id
         * @param {UserBase} userBase User to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, userBase: UserBase, options?: any): AxiosPromise<User> {
            return localVarFp.updateUser(userId, userBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing User Live Status
         * @summary Update an existing User Live Status.
         * @param {string} userId User Id
         * @param {UserLiveStatusBase} userLiveStatusBase User Live Status to be created/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserLiveStatus(userId: string, userLiveStatusBase: UserLiveStatusBase, options?: any): AxiosPromise<UserLiveStatus> {
            return localVarFp.updateUserLiveStatus(userId, userLiveStatusBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing User Password
         * @param {string} userId User Id
         * @param {UserPassword} userPassword Use to update users password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPassword(userId: string, userPassword: UserPassword, options?: any): AxiosPromise<User> {
            return localVarFp.updateUserPassword(userId, userPassword, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Confirm forgot password flow and update password
     * @param {string} userId User Id
     * @param {UserConfirmForgotPassword} userConfirmForgotPassword Use to update users password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public confirmForgotPassword(userId: string, userConfirmForgotPassword: UserConfirmForgotPassword, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).confirmForgotPassword(userId, userConfirmForgotPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new User
     * @param {UserBase} userBase User to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUser(userBase: UserBase, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).createUser(userBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete User
     * @summary Delete User, suitable for testing purposes only, controlled by a permission.
     * @param {string} userId User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(userId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get User Details
     * @summary Get User Details.
     * @param {string} userId User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(userId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get User Live Status
     * @summary Get User Live Status.
     * @param {string} userId User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserLiveStatus(userId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserLiveStatus(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Users
     * @param {number} [skip] Number of rows to skip
     * @param {number} [limit] Max number of rows to return
     * @param {string} [companyId] Company Id
     * @param {string} [email] Email
     * @param {string} [firstName] First name
     * @param {string} [lastName] Last name
     * @param {string} [phoneNumber] Phone number
     * @param {string} [sub] User sub
     * @param {'active' | 'inactive'} [status] Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsers(skip?: number, limit?: number, companyId?: string, email?: string, firstName?: string, lastName?: string, phoneNumber?: string, sub?: string, status?: 'active' | 'inactive', options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUsers(skip, limit, companyId, email, firstName, lastName, phoneNumber, sub, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Trigger forgot password flow
     * @param {string} userId User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public triggerForgotPassword(userId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).triggerForgotPassword(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing User
     * @summary Update an existing User.
     * @param {string} userId User Id
     * @param {UserBase} userBase User to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(userId: string, userBase: UserBase, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUser(userId, userBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing User Live Status
     * @summary Update an existing User Live Status.
     * @param {string} userId User Id
     * @param {UserLiveStatusBase} userLiveStatusBase User Live Status to be created/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserLiveStatus(userId: string, userLiveStatusBase: UserLiveStatusBase, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserLiveStatus(userId, userLiveStatusBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing User Password
     * @param {string} userId User Id
     * @param {UserPassword} userPassword Use to update users password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserPassword(userId: string, userPassword: UserPassword, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserPassword(userId, userPassword, options).then((request) => request(this.axios, this.basePath));
    }
}


