import { createStore, select, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { Group } from '../generated/api';

export interface GroupProps {
  activeGroup: Group | null;
  activeGroupType: Group | null;
}

export const groupStore = createStore(
  { name: 'group' },
  withProps<GroupProps>({
    activeGroup: null,
    activeGroupType: null,
  })
);

persistState(groupStore, {
  key: 'group',
  storage: sessionStorageStrategy,
});

export const activeGroup$ = groupStore.pipe(
  select((state) => {
    return state.activeGroup;
  })
);

export const activeGroupType$ = groupStore.pipe(
  select((state) => {
    return state.activeGroupType;
  })
);
