import { useCallback } from 'react';
import { MembershipRoleNameEnum, useUserRole } from '@beeriot/api-client';
import { PermissionKey } from './PermissionKey';
import {
  pallAdminPermissionKeys,
  customerAdminPermissionKeys,
  executiveSupervisorPermissionKeys,
  operatorPermissionKeys,
  supervisorPermissionKeys,
} from './role-permissions';

const appViewPermissions: Record<MembershipRoleNameEnum, PermissionKey[]> = {
  [MembershipRoleNameEnum.SuperUser]: [], // Super User is manually granted access to all permissions
  [MembershipRoleNameEnum.Admin]: pallAdminPermissionKeys,
  [MembershipRoleNameEnum.Supervisor]: supervisorPermissionKeys,
  [MembershipRoleNameEnum.Operator]: operatorPermissionKeys,
  [MembershipRoleNameEnum.CustomerAdmin]: customerAdminPermissionKeys,
  [MembershipRoleNameEnum.ExecutiveSupervisor]:
    executiveSupervisorPermissionKeys,
};

function hasPermission(
  role: MembershipRoleNameEnum | null,
  permissionKey: PermissionKey
) {
  if (role === null || role === undefined) return false;
  if (role === MembershipRoleNameEnum.SuperUser) return true;

  const permissions = appViewPermissions[role];
  const confirmed =  permissions.includes(permissionKey);

  if(!confirmed) {
    console.log(`Permission denied: ${permissionKey}`);
    console.log(`Not in ${permissions}`);
    
  }
  return confirmed;
}

export function useHasPermission() {
  const role = useUserRole();
  return useCallback(
    (permissionKey: PermissionKey) => {
      return hasPermission(role, permissionKey);
    },
    [role]
  );
}
