import { PropsWithChildren, forwardRef, useRef } from 'react';
import { useIntl } from 'react-intl';
import { styled, Stack } from '@mui/material';
import { TabList, TabContext } from '@mui/lab';
import { Property } from 'csstype';
import { useActiveAppEntities } from '@beeriot/api-client';
import {
  useTabRoutingState,
  useTabsRoutesProps,
} from '../tabs/hooks/useTabRoutingState';
import { RoutesCustom } from '../RoutesCustom';
import { PallBeerIotLogo } from './PallBeerIoTLogo';
import { UserInfoBox } from './user-info-box/UserInfoBox';
import { transientOptions } from '../../utils/transientOptions';
import { useResponsiveSizes } from '../../utils/responsive-design/useResponsiveSizes';
import { useHasPermission } from '../../base/user-permissions';
import { MobileNavToolbar } from './MobileNavToolbar';
import { RoutesContainer } from '../RoutesContainer';
import { DefaultRouteNavigation } from './DefaultRouteNavigation';
import { useDebounce } from 'use-debounce';
import { useDebugPropChanges } from 'react-use-debug-hooks';

const TabsContainer = styled(Stack)(({ theme }) => ({
  zIndex: theme.zIndex.appBar + 2,
  boxShadow: theme.shadows[3],
  backgroundColor: theme.palette.primary.main,
}));

const StyledTabs = styled(
  TabList,
  transientOptions
)(({ theme }) => ({
  display: 'inline-flex',
  '& .MuiTabs-flexContainer': {
    height: '100%',
  },
  '& .MuiTypography-root': {
    fontSize: '1.25rem',
    padding: '1.5rem',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.white.main,
    height: '0.5rem',
    bottom: null,
    top: 0,
  },
  '& .MuiTabs-scrollButtons': {
    color: theme.palette.white.main,
  },
  '& .MuiTabs-scrollButtons.Mui-disabled': {
    opacity: 0.3,
  },
}));

const LogoContainer = styled(
  Stack,
  transientOptions
)<{ $marginRight: Property.MarginRight }>(({ $marginRight }) => ({
  display: 'inline-flex',
  margin: `auto ${$marginRight} auto 1rem`,
}));

const UserInfoContainer = styled(Stack)({
  marginLeft: 'auto',
});

interface MainTabsProps extends PropsWithChildren<useTabsRoutesProps> {
  isMobileMenuActive: boolean;
  setIsMobileMenuActive: (isActive: boolean) => void;
}

export const MainTabs = forwardRef(
  (
    props: MainTabsProps,
    ref
  ) => {
    const {
      tabsKey: name,
      routes,
      lastRouteMatch,
      tabButtonRenderer,
      tabPanelRenderer,
      isMobileMenuActive,
      setIsMobileMenuActive,
    } = props
    // const hasPermission = useHasPermission();
    // const { activeCompany } = useActiveAppEntities();
    // const tabStateValue ={
    //   tabsKey: name,
    //   lastRouteMatch,
    //   routes: routes,
    //   tabButtonRenderer,
    //   tabPanelRenderer,
    // };
    
    // const [tabStateValue] = useDebounce({
    //   tabsKey: name,
    //   lastRouteMatch,
    //   routes: routes,
    //   tabButtonRenderer,
    //   tabPanelRenderer,
    // },100, {leading:false, trailing: true})

    const [_tabsKey] = useDebounce(name, 30, {leading:false, trailing: true});
    const [_lastRouteMatch] = useDebounce(lastRouteMatch, 10, {leading:false, trailing: true});
    const [_routes] = useDebounce(routes, 30, {leading:false, trailing: true});
    const [_tabButtonRenderer] = useDebounce(tabButtonRenderer, 10, {leading:false, trailing: true});
    const [_tabPanelRenderer] = useDebounce(tabPanelRenderer, 10, {leading:false, trailing: true});
    // useDebugPropChanges({...props, })
    const { notFound, activeIdx, activeIdxStr, tabs, panels } =
      useTabRoutingState(
        {
          tabsKey: _tabsKey,
          lastRouteMatch:_lastRouteMatch,
          routes: _routes,
          tabButtonRenderer:_tabButtonRenderer,
          tabPanelRenderer:_tabPanelRenderer,
        }
      );
    const routesContainerRef = useRef<HTMLDivElement | undefined>(undefined);

    const { isMobileSize, isDesktopSize } = useResponsiveSizes();
    const intl = useIntl();
    const tabsAriaLabel = intl.formatMessage({
      id: 'common-tabs-aria-label',
      description: 'Main navigation tabs aria label',
      defaultMessage: 'Main Navigation Tabs',
    });

    const [_activeIdxStr] = useDebounce(activeIdxStr, 30, { leading: false, trailing: true});
    const [_activeIdx] =  useDebounce(activeIdx, 30, { leading: false, trailing: true});
    // const [_notFound] = useDebounce(notFound, 50, { leading: false, trailing: true});
    if (notFound) {
      return <DefaultRouteNavigation />;
    }

    return (
      <TabContext value={_activeIdxStr}>
        <TabsContainer direction="row" ref={ref}>
          <LogoContainer $marginRight={isDesktopSize ? '3rem' : '1rem'}>
            <PallBeerIotLogo
              variant={isDesktopSize ? 'h5' : 'h6'}
              logoWidth={isDesktopSize ? '6rem' : '5rem'}
            />
          </LogoContainer>

          {isMobileSize ? (
            <MobileNavToolbar
              tabs={tabs}
              activeIdx={_activeIdx}
              isMobileMenuActive={isMobileMenuActive}
              setIsMobileMenuActive={setIsMobileMenuActive}
              modalContainerNode={routesContainerRef}
            />
          ) : (
            <>
              <StyledTabs
                aria-label={tabsAriaLabel}
                variant={'scrollable'}
                scrollButtons={false}
              >
                {tabs}
              </StyledTabs>
              <UserInfoContainer>
                <UserInfoBox />
              </UserInfoContainer>
            </>
          )}
        </TabsContainer>
        <RoutesContainer ref={routesContainerRef}>
          <RoutesCustom>{panels}</RoutesCustom>
        </RoutesContainer>
      </TabContext>
    );
  }
);
