import { Stack, styled } from '@mui/material';
import { CalendarMode } from '@beeriot/api-client';
import { useIntlCalendarModeSubLabel } from '../../enums';
import { useFormatCalendarModeDate } from '../../utils';
import { VerticalLabels } from '../VerticalLabels';
import { AppIcon } from '../AppIcon';

const StyledStack = styled(Stack)({
  gap: '2rem',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

export interface DateLabelProps {
  calendarMode: CalendarMode;
  value: Date | null;
  subLabelOverride?: string;
}

export function DateLabel({
  calendarMode,
  value,
  subLabelOverride,
}: DateLabelProps) {
  const getSubLabel = useIntlCalendarModeSubLabel();
  const subLabel = subLabelOverride ?? getSubLabel(calendarMode);
  const dateLabel = useFormatCalendarModeDate(calendarMode, value);

  return (
    <StyledStack direction="row">
      <VerticalLabels
        labelBold
        $lineHeight="1.15"
        labelVariant="body1"
        labelColorKey="white"
        label={dateLabel}
        subLabelColorKey="base"
        subLabelVariant="caption"
        subLabel={subLabel}
      />
      <AppIcon size="lg" icon="calendar-days" $colorKey="white" />
    </StyledStack>
  );
}
