import { Variant } from '@mui/material/styles/createTypography';
import { FormattedMessage } from 'react-intl';
import { ColorKey } from '@beeriot/api-client';
import { VerticalLabels } from './VerticalLabels';
import { RadialGauge, Flow, GaugeSection } from './RadialGauge';

import { betterForwardRef } from '../utils/betterForwardRef';

export interface FlowGaugeProps {
  className?: string;
  flow: Flow;
  radius?: number;
  gaugeMap?: Map<number, GaugeSection>;
  warningLimit?: number;
  successLimit?: number;
  labelVariant?: Variant;
  subLabelVariant?: Variant;
  backgroundColor?: string;
  bold?: boolean;
  gaugeOverrideColorKey?: ColorKey;
  gaugeBackgroundOverrideColorKey?: ColorKey;
  displayLabelAsDash?: boolean;
}

export const FlowGauge = betterForwardRef<HTMLDivElement, FlowGaugeProps>(
  (
    {
      className,
      flow,
      radius,
      gaugeMap,
      warningLimit,
      successLimit,
      labelVariant,
      subLabelVariant,
      backgroundColor,
      bold,
      gaugeOverrideColorKey,
      gaugeBackgroundOverrideColorKey,
      displayLabelAsDash,
    }: FlowGaugeProps,
    ref
  ) => {
    return (
      <VerticalLabels
        ref={ref}
        className={className}
        label={
          <RadialGauge
            radius={radius}
            flow={{
              amount: displayLabelAsDash ? 0 : flow.amount,
              unit: flow.unit,
              max: flow.max,
            }}
            gaugeMap={gaugeMap}
            warningLimit={warningLimit}
            successLimit={successLimit}
            labelVariant={labelVariant}
            subLabelVariant={subLabelVariant}
            backgroundColor={backgroundColor}
            gaugeOverrideColorKey={gaugeOverrideColorKey}
            gaugeBackgroundOverrideColorKey={gaugeBackgroundOverrideColorKey}
            displayLabelAsDash={displayLabelAsDash}
          />
        }
        labelBold={bold}
        subLabel={
          <FormattedMessage
            id="site-common-actual-flow"
            defaultMessage="Actual Flow"
            description="sublabel for actual flow"
          />
        }
        subLabelBold={bold}
        justifyContent="center"
      />
    );
  }
);
FlowGauge.displayName = 'FlowGauge';
