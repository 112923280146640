import { SystemStatsCategory } from '../../../enums';
import {
  ComparableSection,
  LineStatisticsGroup,
  LineStatisticsItem,
} from '../lineStatistics';
import { systemStatsDecimalPlaces } from '../systemStatsDecimalPlaces';
import { systemStatsUnits } from '../systemStatsUnits';

export function processSystemStatsSection(
  category: SystemStatsCategory,
  section?: ComparableSection | null
): LineStatisticsGroup {
  const currentKeys = section?.current ? Object.keys(section.current) : [];

  const statistics: LineStatisticsItem[] = currentKeys.map((key) => {
    const decimalPlaces = systemStatsDecimalPlaces[key] ?? 0;
    const currentValue = section?.current?.[key] ?? undefined;
    const compareValue = section?.compare?.[key] ?? undefined;
    const percentageDelta = getPercentageDelta(currentValue, compareValue);

    return {
      id: key,
      value: currentValue
        ? parseFloat(currentValue.toFixed(decimalPlaces))
        : currentValue,
      compareValue: compareValue
        ? parseFloat(compareValue.toFixed(decimalPlaces))
        : compareValue,
      units: systemStatsUnits[key],
      localizerKey: key,
      percentageDelta,
    };
  });

  const group: LineStatisticsGroup = {
    category,
    statistics,
  };

  return group;
}

function getPercentageDelta(currentValue?: number, compareValue?: number) {
  if (currentValue == null || compareValue == null) return undefined;
  if (currentValue === 0 && compareValue == 0) return 0;
  return Math.round(
    ((currentValue - compareValue) / Math.abs(compareValue)) * 100
  );
}
