import { useState } from 'react';
import { Button } from '@mui/material';
import { CalendarMode, ColorKey } from '@beeriot/api-client';
import { DateLabel } from '../DateLabel';
import { CombinedCalendarModePickerDialog } from './CombinedCalendarModePickerDialog';

export interface CombinedCalendarModePickerProps {
  calendarMode: CalendarMode;
  onCalendarModeChange: (view: CalendarMode) => void;
  value: Date | null;
  onChange: (date: Date | null) => void;
  colorKey?: ColorKey;
  fullWidth?: boolean;
}

export function CombinedCalendarModePicker({
  calendarMode,
  onCalendarModeChange,
  value,
  onChange,
  colorKey = 'primary',
  fullWidth,
}: CombinedCalendarModePickerProps) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        fullWidth={fullWidth}
        variant="contained"
        color={colorKey}
        onClick={() => setOpen(true)}
      >
        <DateLabel calendarMode={calendarMode} value={value} />
      </Button>
      <CombinedCalendarModePickerDialog
        calendarMode={calendarMode}
        onCalendarModeChange={onCalendarModeChange}
        open={open}
        onClose={() => setOpen(false)}
        value={value}
        onChange={onChange}
        colorKey={colorKey}
      />
    </>
  );
}
