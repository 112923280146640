import { useCallback } from 'react';
import { NavigateFunction, RouteMatch } from 'react-router-dom';
import { GQLSystem, activeEntities$, AreaSystem, useActiveAppEntities, useGQLSites, useAreas } from '@beeriot/api-client';
import { appRoutePathsForActiveEntities, useAppRoutePaths } from '../utils';
import { BreadcrumbsLineDropdown } from '../../base/breadcrumbs/dropdown/BreadcrumbsLineDropdown';
import { take, tap, map } from 'rxjs/operators';
import { useIsExecSummaryPage } from '../../navigation';

export function useRenderSystemSelector({
  areaSystems,
  isSiteDetailsPage,
  isSystemDetailsSubPage,
  navigate,
}: {
  areaSystems: AreaSystem[] | null | undefined;
  isSiteDetailsPage?: RouteMatch;
  isSystemDetailsSubPage?: {
    isLineStatus: boolean;
    isStats: boolean;
    isFilterRuns: boolean;
    isDocumentation: boolean;
    isReports: boolean;
  };
  navigate: NavigateFunction;
}) {

  const { activeSite, activeCompany } = useActiveAppEntities();
  // const isExecSummary = useIsExecSummaryPage();
  const appRoutes = useAppRoutePaths();
  const { query } = useGQLSites(activeCompany?.id);
  const areaQuery = useAreas(activeSite?.id);


  return useCallback(
    (open: boolean, setOpen: (isOpen: boolean) => void) => (
      <BreadcrumbsLineDropdown
        areas={areaSystems}
        showSelectedLine={true}
        parentPageName={'sites'}
        onClose={() => setOpen(false)}
        onSelectSystem={(system: GQLSystem) => {
          let route = appRoutes.systemLineStatusPathForSystem(system);
          if (isSiteDetailsPage) {
            if (isSystemDetailsSubPage?.isLineStatus) {
              route = appRoutes.systemLineStatusPathForSystem(system);
            } else if (isSystemDetailsSubPage?.isStats) {
              route = appRoutes.systemStatisticsPathForSystem(system);
            } else if (isSystemDetailsSubPage?.isFilterRuns) {
              route =
                system.type === 'profi' ||
                system.type === 'cfs' ||
                system.type === 'keraflux'
                  ? appRoutes.systemFilterRunsPathForSystem(system)
                  : appRoutes.systemLineStatusPathForSystem(system);
            } else if (isSystemDetailsSubPage?.isDocumentation) {
              route = appRoutes.systemDocumentationPathForSystem(system);
            } else if (isSystemDetailsSubPage?.isReports) {
              route = appRoutes.systemReportsPathForSystem(system);
            }
          } else {
            route = appRoutes.systemExecSummaryPathForSystem(system);
          }
          navigate(route, { replace: false });
          // activeEntities$
          //   .pipe(
          //     map((x) => ({
          //       ...x,
          //       activeSystem: system,
          //     })),
          //     map(appRoutePathsForActiveEntities),
          //     take(1),
          //     tap((appPaths) => {
          //       let route = appPaths.systemLineStatusPath;
          //       if (isSiteDetailsPage) {
          //         if (isSystemDetailsSubPage?.isLineStatus) {
          //           route = appPaths.systemLineStatusPath;
          //         } else if (isSystemDetailsSubPage?.isStats) {
          //           route = appPaths.systemStatisticsPath;
          //         } else if (isSystemDetailsSubPage?.isFilterRuns) {
          //           route =
          //             system.type === 'profi' ||
          //             system.type === 'cfs' ||
          //             system.type === 'keraflux'
          //               ? appPaths.systemFilterRunsPath
          //               : appPaths.systemLineStatusPath;
          //         } else if (isSystemDetailsSubPage?.isDocumentation) {
          //           route = appPaths.systemDocumentationPath;
          //         } else if (isSystemDetailsSubPage?.isReports) {
          //           route = appPaths.systemReportsPath;
          //         }
          //       } else {
          //         route = appPaths.systemExecSummaryPath;
          //       }
               
          //       navigate(route, { replace: true });
          //     })
          //   )
          //   .subscribe();
        }}
      />
    ),
    [
      areaSystems,
      isSiteDetailsPage,
      isSystemDetailsSubPage?.isDocumentation,
      isSystemDetailsSubPage?.isFilterRuns,
      isSystemDetailsSubPage?.isLineStatus,
      isSystemDetailsSubPage?.isReports,
      isSystemDetailsSubPage?.isStats,
      appRoutes,
      navigate,
    ]
  );
}
