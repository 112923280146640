import { useActiveEntityRouteParams } from '@beeriot/api-client';

import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { PermissionKey, useHasPermission } from '../../base/user-permissions';
import { RouteTab } from '../../navigation/tabs/RouteTab';
import { SidebarTabsButton } from '../../navigation/sidebar-tabs/SidebarTabsButton';
import { AccountSettingsPage } from '../../pages/settings/AccountSettingsPage';
import { UserManagementSettingsPage } from '../../pages/settings/UserManagementSettingsPage';
import { AlertSettingsPage } from '../../pages/settings/AlertSettingsPage';
import { AutomaticReportSettingsPage } from '../../pages/settings/AutomaticReportSettingsPage';
import { ChangePasswordPage } from '../../pages/settings/ChangePasswordPage';
import { LocalUtilityCostsSettingsPage } from '../../pages/settings/LocalUtilityCostsSettingsPage';
import { PallNotificationsPage } from '../../pages/settings/PallNotificationsPage';
import { ProvisioningSettingsPage } from '../../pages/settings/ProvisioningSettingsPage';
import { BeerTypesPage } from '../../pages/settings/BeerTypesPage';
import { useResponsiveSizes } from '../../utils/responsive-design/useResponsiveSizes';
import { getFilteredRoutes } from './getFilteredRoutes';
import { ThresholdSettingsPage } from '../../pages/settings/ThresholdSettingsPage';
import { CertificateProvisioningPage } from '../../pages/settings/CertificateProvisioningPage';
import { IoTStatusSettingsPage } from '../../pages/settings/IoTStatusSettingsPage';
import { PushNotificationSettingsPage } from '../../pages/settings/PushNotificationSettingsPage';

export function useSettingsSidebarTabRoutes(): React.ReactNode[] {
  const intl = useIntl();
  const { isMobileSize } = useResponsiveSizes();
  const hasPermission = useHasPermission();

  const routeParams = useActiveEntityRouteParams();

  const provisioiningLabel = intl.formatMessage({
    id: 'common-provisioning-label',
    description: 'Provisioning label',
    defaultMessage: 'Provisioning',
  });
  const accountLabel = intl.formatMessage({
    id: 'common-account-label',
    description: 'Account label',
    defaultMessage: 'Account',
  });
  const userManagementLabel = intl.formatMessage({
    id: 'common-user-management-label',
    description: 'User management label',
    defaultMessage: 'User Management',
  });
  const alertsLabel = intl.formatMessage({
    id: 'common-alerts-label',
    description: 'Label for alerts',
    defaultMessage: 'Alerts',
  });
  const pushNotifiactionsLabel = intl.formatMessage({
    id: 'common-push-notifications-label',
    description: 'Label for push notifications',
    defaultMessage: 'Push Notifications',
  });
  const reportingLabel = intl.formatMessage({
    id: 'common-reporting-label',
    description: 'Reporting label',
    defaultMessage: 'Reporting',
  });
  const changePasswordLabel = intl.formatMessage({
    id: 'common-change-password-label',
    description: 'Change password label',
    defaultMessage: 'Change Password',
  });
  const localUtilityCostsLabel = intl.formatMessage({
    id: 'common-local-utility-costs-label',
    description: 'Local utility costs label',
    defaultMessage: 'Local Utility Costs',
  });
  const iotStatusLabel = intl.formatMessage({
    id: 'common-iot-status-label',
    description: 'IoT status label',
    defaultMessage: 'IoT Status',
  });
  const pallNotificationsLabel = intl.formatMessage({
    id: 'common-pall-notifications-label',
    description: 'Pall notifications label',
    defaultMessage: 'Pall Notifications',
  });
  const beerTypesLabel = intl.formatMessage({
    id: 'common-beer-brands-label',
    description: 'Beer brands label',
    defaultMessage: 'Beer Brands',
  });
  const thresholdsUsageLabel = intl.formatMessage({
    id: 'common-thresholds-usage-label',
    description: 'Thresholds and usage label',
    defaultMessage: 'Thresholds & Usage',
  });
  const certificateProvisioningLabel = intl.formatMessage({
    id: 'common-certificate-provisioning-label',
    description: 'Certificate provisioning label',
    defaultMessage: 'Certificate Provisioning',
  });

  const buttonElement = useMemo(
    () => (
      <SidebarTabsButton
        $isMobile={isMobileSize}
        $showViewingIndicator={true}
      />
    ),
    [isMobileSize]
  );

  const routes = useMemo(
    () =>
      [
        {
          key: PermissionKey.SettingsProvisioning,
          route: {
            path: 'provisioning/*',
            params: routeParams,
            key: 'provisioning',
            label: provisioiningLabel,
            buttonElement: buttonElement,
            element: <ProvisioningSettingsPage />,
          },
        },
        {
          key: PermissionKey.SettingsCertificateProvisioning,
          route: {
            path: 'certificate-provisioning/*',
            params: routeParams,
            key: 'certificate-provisioning',
            label: certificateProvisioningLabel,
            buttonElement: buttonElement,
            element: <CertificateProvisioningPage />,
          },
        },
        {
          key: PermissionKey.SettingsAccount,
          route: {
            path: 'account/*',
            params: routeParams,
            key: 'account',
            label: accountLabel,
            buttonElement: buttonElement,
            element: <AccountSettingsPage />,
          },
        },
        {
          key: PermissionKey.SettingsUserManagement,
          route: {
            path: 'user-management/*',
            params: routeParams,
            key: 'user-management',
            label: userManagementLabel,
            buttonElement: buttonElement,
            element: <UserManagementSettingsPage />,
          },
        },

        {
          key: PermissionKey.SettingsAlerts,
          route: {
            path: 'alerts/*',
            params: routeParams,
            key: 'alerts',
            label: alertsLabel,
            buttonElement: buttonElement,
            element: <AlertSettingsPage />,
          },
        },

        {
          key: PermissionKey.SettingsPushNotifications,
          route: {
            path: 'push-notifications/*',
            params: routeParams,
            key: 'push-notifications',
            label: pushNotifiactionsLabel,
            buttonElement: buttonElement,
            element: <PushNotificationSettingsPage />,
          },
        },

        {
          key: PermissionKey.SettingsReporting,
          route: {
            path: 'reporting/*',
            params: routeParams,
            key: 'reporting',
            label: reportingLabel,
            buttonElement: buttonElement,
            element: <AutomaticReportSettingsPage />,
          },
        },

        {
          key: PermissionKey.SettingsChangePassword,
          route: {
            path: 'change-password/*',
            params: routeParams,
            key: 'change-password',
            label: changePasswordLabel,
            buttonElement: buttonElement,
            element: <ChangePasswordPage />,
          },
        },
        {
          key: PermissionKey.SettingsLocalUtilityCosts,
          route: {
            path: 'local-utility-costs/*',
            params: routeParams,
            key: 'local-utility-costs',
            label: localUtilityCostsLabel,
            buttonElement: buttonElement,
            element: <LocalUtilityCostsSettingsPage />,
          },
        },
        {
          key: PermissionKey.SettingsIoTStatus,
          route: {
            path: 'iot-status/*',
            params: routeParams,
            key: 'iot-status',
            label: iotStatusLabel,
            buttonElement: buttonElement,
            element: <IoTStatusSettingsPage />,
          },
        },
        {
          key: PermissionKey.SettingsPallNotifications,
          route: {
            path: 'pall-notifications/*',
            params: routeParams,
            key: 'pall-notifications',
            label: pallNotificationsLabel,
            buttonElement: buttonElement,
            element: <PallNotificationsPage />,
          },
        },
        {
          key: PermissionKey.SettingsBeerTypes,
          route: {
            path: 'beer-brands/*',
            params: routeParams,
            key: 'beer-brands',
            label: beerTypesLabel,
            buttonElement: buttonElement,
            element: <BeerTypesPage />,
          },
        },
        {
          key: PermissionKey.SettingsThresholds,
          route: {
            path: 'thresholds/*',
            params: routeParams,
            key: 'thresholds',
            label: thresholdsUsageLabel,
            buttonElement: buttonElement,
            element: <ThresholdSettingsPage />,
          },
        },
      ].map((x) => {
        return {
          ...x,
          route: (
            <RouteTab
              {...x.route}
              // path={x.route.path}
              // key={x.route.key}
              // label={x.route.label}
              // buttonElement={x.route.buttonElement}
              // element={x.route.element}
            />
          ),
        };
      }),
    [
      accountLabel,
      alertsLabel,
      beerTypesLabel,
      buttonElement,
      certificateProvisioningLabel,
      changePasswordLabel,
      iotStatusLabel,
      localUtilityCostsLabel,
      pallNotificationsLabel,
      provisioiningLabel,
      reportingLabel,
      routeParams,
      thresholdsUsageLabel,
      userManagementLabel,
    ]
  );

  return getFilteredRoutes(hasPermission, routes);
}
