export const privacyPolicyURL =
  'https://www.pall.com/en/about-pall/codes-and-policies/pall-corporation-privacy-policy.html';

export const termsOfServiceURL =
  'https://www.pall.com/en/about-pall/terms.html';

export const termsAndConditionsURL =
  'https://www.pall.com/en/about-pall/terms/terms_conditions.html';

export const legalNoticesURL =
  'https://www.pall.com/en/about-pall/legal-notices.html';

export const privacyActURL =
  'https://www.pall.com/en/about-pall/privacy-act.html';

export const youtubeSocialsURL =
  'https://www.youtube.com/channel/UCWccTN9LWiLlBh-3rB0F14A';
