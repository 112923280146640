import { useMutation } from 'react-query';
import { IotProvisioningApi } from '../generated';
import { appConfiguration } from './configuration';

const iotProvisioningApi = new IotProvisioningApi(appConfiguration);

export async function getDevice(companyId: string, iotId: string) {
  const res = await iotProvisioningApi.getThings(0, 1, iotId, companyId);
  if (res.data.length > 0) {
    return res.data[0];
  } else {
    throw Error('No device found');
  }
}

export async function provisionDevice(companyId?: string, iotId?: string) {
  if (!companyId || !iotId) return null;
  const res = await iotProvisioningApi.createThing({
    companyId: companyId,
    companySiteId: iotId,
  });
  return res.data;
}

async function getProvisioningCertificate(companyId?: string, iotId?: string) {
  if (!companyId || !iotId) {
    throw Error('Missing request data');
  }
  const device = await getDevice(companyId, iotId);
  const res = await iotProvisioningApi.getThingUrl(device?.id);
  return res.data;
}

export function useProvisioningCertificate() {
  return useMutation((req: { companyId?: string; iotId?: string }) =>
    getProvisioningCertificate(req.companyId, req.iotId)
  );
}
