import { useMutation, useQueryClient } from 'react-query';
import globalAxios from 'axios';
import {
  GQLAlertActive,
  GQLAlertHistory,
  useInfiniteListAlertsHistoricalQuery,
  useInfiniteListAlertsQuery,
} from '../generated/gql';
import {
  LONG_POLLING_INTERVAL,
  defaultPagingParams,
  // defaultPagingParams,
  getNextPageParamHandler,
  removeFalsey,
} from '../utils';
import { AlertApi, AlertDismissal, AlertMappingPutData } from '../generated';
import { appConfiguration } from './configuration';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import { FileWithPath } from 'react-dropzone';
import { alertStore } from '../state/alertStore';
import { useMemo } from 'react';

const alertsApi = new AlertApi(appConfiguration);

export function useGQLAlerts(
  companyId?: string | null,
  siteId?: string | null,
  systemId?: string | null,
  alertType?: string | null,
  enabled?: boolean,
): GQLAlertActive[] {
  const startDate = startOfDay(addDays(new Date(), -7)).toISOString();
  const endDate = endOfDay(new Date()).toISOString();

  const q = useInfiniteListAlertsQuery({
    fromDate: startDate,
    toDate: endDate,
    companyId: companyId,
    siteId: siteId,
    systemId: systemId,
    alertType: alertType,
    ...defaultPagingParams,
    
  },{enabled,  getNextPageParam: getNextPageParamHandler(
    (query) => query?.listActiveAlerts?.length
  ),});

  const {
    data: alerts,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = q;

  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage();
  }

  const gqlAlerts = useMemo(() => removeFalsey<GQLAlertActive>(
    alerts?.pages.flatMap((x) => x?.listActiveAlerts)
  ).sort((a, b) => (a.time ?? '').localeCompare(b.time ?? '')), [alerts]);

  return gqlAlerts
}

export function useGQLAlertsHistorical(
  startDate: string,
  endDate: string,
  companyId?: string | null,
  siteId?: string | null,
  systemId?: string | null,
  alertType?: string | null
): GQLAlertHistory[] {
  const {
    data: alerts,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteListAlertsHistoricalQuery(
    {
      companyId: companyId,
      siteId: siteId,
      systemId: systemId,
      alertType: alertType,
      fromDate: startDate,
      toDate: endDate,
      ...defaultPagingParams,
    },
    {
      refetchInterval: LONG_POLLING_INTERVAL,
      getNextPageParam: getNextPageParamHandler(
        (query) => query?.listAlertsHistory?.length
      ),
    }
  );

  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage();
  }

  // const gqlAlerts = removeFalsey<GQLAlertHistory>(
  //   alerts?.pages.flatMap((x) => x?.listAlertsHistory)
  // );

  // return gqlAlerts.sort((a, b) => (a.time ?? '').localeCompare(b.time ?? ''));

  const gqlAlerts = useMemo(() => removeFalsey<GQLAlertActive>(
    alerts?.pages.flatMap((x) => x?.listAlertsHistory)
  ).sort((a, b) => (a.time ?? '').localeCompare(b.time ?? '')), [alerts]);

  return gqlAlerts
}

async function dismissAlert(thumbprint: string) {
  const res = await alertsApi.addAlertDismissal({ thumbprint });
  return res.data;
}

export function useDismissAlert(
  onSuccess: (alertDismissal: AlertDismissal | null) => void,
  onError?: (err: string) => void
) {
  return useMutation(
    async (thumbprint: string) => {
      const response = await dismissAlert(thumbprint);
      return response;
    },
    {
      onError: (err) => {
        onError?.(err as string);
      },
      onSuccess: async (alertDismissal) => {
        onSuccess(alertDismissal);
      },
    }
  );
}

export function useUploadAlertTypeMappings(
  onSuccess?: () => void,
  onError?: (err: string) => void
) {
  return useMutation(
    async (req: { systemId: string; file: FileWithPath }) => {
      if (!req.systemId) return undefined;
      const alertTypeUploadResponse = await alertsApi.getAlertTypeUploadUrl(
        req.systemId
      );

      if (alertTypeUploadResponse) {
        await uploadDocument(req.file, alertTypeUploadResponse.data).then(
          async () =>
            await alertsApi.processAlertTypes(req.systemId).catch(() => {
              throw new Error('Could not process alert types');
            })
        );
      }

      return alertTypeUploadResponse.data;
    },
    { onSuccess: onSuccess, onError: onError }
  );
}

async function uploadDocument(file: FileWithPath, res: AlertMappingPutData) {
  const uploadUrl = res.url;
  if (!uploadUrl || !res.fields || !file) {
    console.error('Unable to obtain document upload url');
    return;
  }
  const data = new FormData();

  data.append('Content-Type', file?.type);
  data.append('key', res.fields?.key ?? '');
  data.append('AWSAccessKeyId', res.fields?.AWSAccessKeyId ?? '');
  data.append(
    'x-amz-security-token',
    res.fields?.['x-amz-security-token'] ?? ''
  );
  data.append('policy', res.fields?.policy ?? '');
  data.append('signature', res.fields?.signature ?? '');
  data.append('file', file);

  await globalAxios.post(uploadUrl, data, {
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / progressEvent.total) * 100;
      alertStore.update((state) => ({
        ...state,
        alertMappingUploadProgress: progress,
      }));
    },
  });
}
