import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  activeSite$,
  activeCompany$,
  groupSitesByLetter,
  useGQLSites,
  useActiveAppEntities,
} from '@beeriot/api-client';
import { AppTypography } from '../../../AppTypography';
import { Breadcrumb } from '../../Breadcrumb';
import { BreadcrumbsSiteDropdown } from '../../dropdown/BreadcrumbsSiteDropdown';
import { useObservable } from '@ngneat/use-observable';
import { useIntl } from 'react-intl';
import { RoutingBreadcrumbsProps } from '../RoutingBreadCrumbs';

export function RoutingSiteSelectorBreadcrumb(
  props: Partial<RoutingBreadcrumbsProps>
) {
  const navigate = useNavigate();
  const {activeSite, activeCompany} = useActiveAppEntities();
  // const [activeSite] = useObservable(activeSite$);
  // const [activeCompany] = useObservable(activeCompany$);
  const { sortedData: sites } = useGQLSites(activeCompany?.id);
  const groupedSites = sites ? groupSitesByLetter(sites) : [];

  const intl = useIntl();

  const onClick = useCallback(() => {
    navigate(
      `/companies/${activeSite?.companyId ?? ''}/sites/${
        activeSite?.id ?? ''
      }/overview`
    );
  }, [activeSite, navigate]);

  return (
    <Breadcrumb
      $stripeColorKey={'highlight2'}
      $followingItemStripeColorKey={'highlight3'}
      {...props}
      $isDropdown={true}
      renderDropdownContent={(open, setOpen) => (
        <BreadcrumbsSiteDropdown
          groupedSites={groupedSites}
          parentPageName={'sites'}
          onClose={() => setOpen(false)}
        />
      )}
      onClick={onClick}
    >
      <AppTypography $bold={true} $colorKey={undefined}>
        {activeSite?.name}
      </AppTypography>
    </Breadcrumb>
  );
}
