import { FormattedMessage } from 'react-intl';
import { useLogout } from '@beeriot/api-client';
import { AppDialog } from '../base/AppDialog';

interface LogoutDialogProps {
  open: boolean;
  onClose: () => void;
}

export function LogoutDialog({ open, onClose }: LogoutDialogProps) {
  const logout = useLogout();
  return (
    <AppDialog
      open={open}
      cancel={onClose}
      confirm={logout}
      dialogTitle={
        <FormattedMessage
          id="logout-dialog-title"
          description="Title for logout dialog"
          defaultMessage="Log Out"
        />
      }
      dialogContent={
        <FormattedMessage
          id="logout-dialog-description"
          description="Description for logout dialog"
          defaultMessage="Are you sure you want to log out?"
        />
      }
    />
  );
}
