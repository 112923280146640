import { useMemo, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { Button, Paper, Stack, styled } from '@mui/material';
import { GQLUserRole, MembershipRoleNameEnum, Roles } from '@beeriot/api-client';
import { UseFormProps } from 'react-hook-form';
import { Form, SelectOption } from '../../form';
import {
  SectionControl,
  SectionFormSelect,
  SectionLabel,
  SectionTextInput,
  SettingsFooterButtons,
  TitleHeader,
} from '../settings-form';
import { AppIcon } from '../../base';
import { ConfirmRevokeDialog } from './ConfirmRevokeDialog';
import { useAppRoutePaths } from '../../app-routing/utils';
import { useIntlRole } from '../../intl/useIntlRole';

const StyledPaper = styled(Paper)({
  maxWidth: '40rem',
});

export interface UserSettingsFormFields {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  role?: SelectOption | null;
}

const fromFormFields = (
  formFields: UserSettingsFormFields,
  user?: GQLUserRole
): GQLUserRole => ({
  ...user,
  userId: user?.userId ?? '',
  firstName: formFields.firstName ?? '',
  lastName: formFields.lastName ?? '',
  email: formFields.email ?? '',
  phoneNumber: formFields.phoneNumber ?? '',
  role: formFields.role?.value as MembershipRoleNameEnum,
});

export type UserMode = 'add' | 'edit';
export interface UserSettingsProps {
  user?: GQLUserRole;
  userMode?: UserMode;
  onSave?: (user: GQLUserRole) => void;
  onRevoke?: (userId: string) => void;
  isLoading?: boolean;
  roles: Roles[];
}

export function UserDetailSettings({
  user,
  userMode = 'add',
  onSave,
  onRevoke,
  isLoading,
  roles,
}: UserSettingsProps) {
  const intl = useIntl();
  const intlRole = useIntlRole();
  const navigate = useNavigate();
  const appPaths = useAppRoutePaths();
  const [revokeDialogOpen, setRevokeDialogOpen] = useState(false);

  const selectOptions = useMemo(() => {
    if (!roles) {
      return [];
    }

    const options = roles.map((role) => ({
      label: intlRole(role.roleName as MembershipRoleNameEnum),
      value: role.roleName ?? '',
    }));

    return options;
  }, [intlRole, roles]);

  const onSubmit = useCallback(
    (userSettingFormFields: UserSettingsFormFields) => {
      if (onSave) onSave(fromFormFields(userSettingFormFields, user));
    },
    [onSave, user]
  );

  const onRevokeClick = () => setRevokeDialogOpen(true);
  const onRevokeCancel = () => setRevokeDialogOpen(false);

  const onRevokeConfirmation = useCallback(() => {
    setRevokeDialogOpen(false);
    if (user?.sub && onRevoke) {
      onRevoke(user.sub);
    }
  }, [user?.sub, onRevoke]);

  const onCancel = useCallback(() => {
    // Return to user list when canceling
    navigate(appPaths.settingsUserManagementPath);
  }, [navigate, appPaths]);

  const formProps: UseFormProps<UserSettingsFormFields> = useMemo(() => {
    return {
      defaultValues: {
        firstName: user?.firstName ?? '',
        lastName: user?.lastName ?? '',
        email: user?.email ?? '',
        phoneNumber: user?.phoneNumber ?? '',
        role: user?.role
          ? selectOptions.find((x) => x.value === user.role)
          : null,
      },
    };
  }, [
    selectOptions,
    user?.email,
    user?.firstName,
    user?.lastName,
    user?.phoneNumber,
    user?.role,
  ]);

  return (
    <>
      <StyledPaper>
        <Form<UserSettingsFormFields> onSubmit={onSubmit} formProps={formProps}>
          <Stack>
            <TitleHeader>
              <Stack direction="row" justifyContent="space-between">
                <FormattedMessage
                  id="settings-user-settings-header-title"
                  defaultMessage="User Details"
                  description="label for user details header"
                />
                {userMode === 'edit' && (
                  <Button
                    color="error"
                    startIcon={<AppIcon icon={['far', 'trash-alt']} />}
                    disabled={!user?.role}
                    onClick={onRevokeClick}
                  >
                    <FormattedMessage
                      id="settings-user-settings-revoke-access-label"
                      defaultMessage="Revoke Access"
                      description="label for revoke access button"
                    />
                  </Button>
                )}
              </Stack>
            </TitleHeader>
            <SectionControl>
              <SectionLabel>
                <FormattedMessage
                  id="settings-user-settings-first-name-label"
                  defaultMessage="First Name"
                  description="label for first name"
                />
              </SectionLabel>
              <SectionTextInput
                name="firstName"
                placeholder={intl.formatMessage({
                  id: 'settings-user-settings-first-name-placeholder',
                  defaultMessage: 'Enter First Name',
                  description: 'label for first name placeholder',
                })}
              />
            </SectionControl>
            <SectionControl>
              <SectionLabel>
                <FormattedMessage
                  id="settings-user-settings-last-name-label"
                  defaultMessage="Last Name"
                  description="label for last name"
                />
              </SectionLabel>
              <SectionTextInput
                name="lastName"
                placeholder={intl.formatMessage({
                  id: 'settings-user-settings-last-name-placeholder',
                  defaultMessage: 'Enter Last Name',
                  description: 'label for last name placeholder',
                })}
              />
            </SectionControl>
            <SectionControl>
              <SectionLabel>
                <FormattedMessage
                  id="settings-user-settings-email-address-label"
                  defaultMessage="Email Address"
                  description="label for email address"
                />
              </SectionLabel>
              <SectionTextInput
                name="email"
                type="email"
                disabled={userMode === 'edit'}
                placeholder={intl.formatMessage({
                  id: 'settings-user-settings-email-address-placeholder',
                  defaultMessage: 'Enter Email Address',
                  description: 'label for email address placeholder',
                })}
              />
            </SectionControl>
            {/* Commented out until phone number integration resumes */}
            {/* <SectionControl>
              <SectionLabel>
                <FormattedMessage
                  id="settings-user-settings-phone-number-label"
                  defaultMessage="Phone Number"
                  description="label for phone number"
                />
              </SectionLabel>
              <SectionTextInput
                name="phoneNumber"
                type="tel"
                placeholder={intl.formatMessage({
                  id: 'settings-user-settings-phone-number-placeholder',
                  defaultMessage: 'Enter Phone Number',
                  description: 'label for phone number placeholder',
                })}
              />
            </SectionControl> */}
            <SectionControl>
              <SectionLabel>
                <FormattedMessage
                  id="settings-user-settings-roles-label"
                  defaultMessage="Roles"
                  description="label for roles"
                />
              </SectionLabel>
              <SectionFormSelect
                name="role"
                placeholder={intl.formatMessage({
                  id: 'settings-user-settings-roles-placeholder',
                  defaultMessage: 'Select Role',
                  description: 'label for roles placeholder',
                })}
                options={selectOptions}
              />
            </SectionControl>
          </Stack>
          <SettingsFooterButtons
            isLoading={isLoading}
            onCancel={onCancel}
            submitLabel={intl.formatMessage({
              id: 'settings-user-settings-save-button',
              defaultMessage: 'Save User',
              description: 'Button for saving user',
            })}
          />
        </Form>
      </StyledPaper>
      <ConfirmRevokeDialog
        username={user?.email}
        open={revokeDialogOpen}
        confirm={onRevokeConfirmation}
        cancel={onRevokeCancel}
      />
    </>
  );
}
