import { select } from '@ngneat/elf';
import { mergeMap } from 'rxjs';
import { getUserInfo } from '../../lib/getUserInfo';
import { appConfiguration } from '../../lib/configuration';
import { AuthProps } from './authStore.model';
import { authStore, persist } from './authStore';

export const userId$ = authStore.pipe(
  select((state) => {
    if (!state.userId && state.accessToken) {
      getUserInfo(authStore);
      return null;
    }
    return state.userId;
  })
);

export const userInfo$ = authStore.pipe(
  select((state) => {
    if (!state.userId && state.accessToken) {
      getUserInfo(authStore);
      return null;
    }
    return getUserInfoState(state);
  })
);
function getUserInfoState(state: AuthProps) {
  return {
    sub: state.userId,
    email: state.email,
    name: `${state.firstName} ${state.lastName}`,
    initials: `${state.firstName?.[0]}${state.lastName?.[0]}`,
  };
}

export const isAuthenticated$ = persist.initialized$.pipe(
  mergeMap(() =>
    authStore.pipe(
      select((state) => {
        const accessToken = state.accessToken;
        if (accessToken) {
          appConfiguration.accessToken = accessToken;
        }
        return !!accessToken;
      })
    )
  )
);
