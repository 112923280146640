import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Button, ButtonProps } from '@mui/material';
import { Loading } from './Loading';

export interface LoadingButtonProps extends ButtonProps {
  isLoading?: boolean;
  loadingLabel?: ReactNode;
}

export const LoadingButton = ({
  isLoading = false,
  loadingLabel,
  children,
  ...buttonProps
}: LoadingButtonProps) => {
  const intl = useIntl();
  const defaultLoadingLabel = intl.formatMessage({
    id: 'common-saving',
    defaultMessage: 'Saving',
    description: 'Label to indicate saving is in progress',
  });

  return (
    <Button
      {...buttonProps}
      startIcon={isLoading ? <Loading /> : buttonProps.startIcon}
    >
      {isLoading ? loadingLabel ?? defaultLoadingLabel : children}
    </Button>
  );
};
