import { useGQLSystem, useGQLSystemLiveStatus } from '@beeriot/api-client';
import { useResponsiveValues } from '../../../utils/responsive-design/useResponsiveSizes';
import {
  SystemCardBase,
  SystemCardBaseProps,
} from '../base-card-common/SystemCardBase';
import { SystemKpiGridAreaTemplate } from '../base-card-common/grids/SystemKpiHookProps';

// prettier-ignore
const templateArea: SystemKpiGridAreaTemplate = [
['titleHeader', 'titleHeader', 'titleHeader'],
['runningState', 'brand', 'brand'],
['timeSinceStart', 'timeRemaining', 'percentCompleteIndicator'],
['batchSize', 'remainingVolume', 'percentCompleteIndicator'],
];

// prettier-ignore
const mobileTemplateArea: SystemKpiGridAreaTemplate = [
  ['mobileTitleHeader', 'mobileTitleHeader', 'mobileTitleHeader'],
  ['brand', 'brand', 'brand'],
  ['timeSinceStart', 'timeRemaining', 'percentCompleteIndicator'],
  ['batchSize', 'remainingVolume', 'percentCompleteIndicator'],
  ['runningStatusTimeline', 'runningStatusTimeline', 'runningStatusTimeline'],
];

export const ProfiSystemDetailCardBase = (props: SystemCardBaseProps) => {
  const { boldLabelTitles = false, system } = props;
  const gridTemplateAreas = useResponsiveValues<SystemKpiGridAreaTemplate>({
    xxs: mobileTemplateArea,
    md: templateArea,
  });

  useGQLSystemLiveStatus(system);
  return (
    <SystemCardBase
      {...props}
      gridTemplateAreas={props.gridTemplateAreas ?? gridTemplateAreas ?? []}
      boldLabelTitles={boldLabelTitles}
    />
  );
};
ProfiSystemDetailCardBase.displayName = 'ProfiSystemDetailCardBase';
