import { useIntl } from 'react-intl';
import { styled } from '@mui/material';
import { lineDocumentsStore } from '@beeriot/api-client';
import { Form, FormTextInput } from '../../form';

const DocumentationSearchForm = styled(Form)({
  width: '100%',
  maxWidth: '25rem',
  padding: '1rem 0',
}) as typeof Form;

const SearchInput = styled(FormTextInput)({
  padding: '0.2rem 0.5rem',
});

interface DocumentationSearchFormFields {
  documentsSearchText: string;
}

export function DocumentationSearch() {
  const intl = useIntl();

  return (
    <DocumentationSearchForm<DocumentationSearchFormFields>
      submitOnChange
      onSubmit={(data) => {
        lineDocumentsStore.update((state) => ({
          ...state,
          documentsSearchText: data.documentsSearchText,
        }));
      }}
    >
      <SearchInput
        name="documentsSearchText"
        placeholder={intl.formatMessage({
          id: 'documentation-search-placeholder',
          description: 'Placeholder for documentation search input',
          defaultMessage: 'Search document name',
        })}
        icon="magnifying-glass"
        iconSize="sm"
      />
    </DocumentationSearchForm>
  );
}
