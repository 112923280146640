import {
  GQLCluster,
  GQLColumn,
  GQLCompany,
  GQLCompanySite,
  GQLSystem,
} from '@beeriot/api-client';
import { useMemo } from 'react';

const PROP_KEYS = [
  'type',
  'name',
  'id',
  'companySiteId',
  'companyId',
  'systemId',
  'clusterId',
  'columnId',
];
const reduceAttributes = (
  acc: Record<string, string | undefined>,
  [entityKey, val]: [
    string,
    GQLCompanySite | GQLSystem | GQLColumn | GQLCluster
  ]
): Record<string, string | undefined> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _val = val as any;
  _val &&
    PROP_KEYS.forEach((propKey) => {
      conditionallyAddDataAttribute(_val, propKey, acc, entityKey);
    });
  return acc;
};

type EntityDataAttributesProps = {
  company?: GQLCompany | undefined;
  site?: GQLCompanySite | undefined;
  system?: GQLSystem | undefined;
  column?: GQLColumn | undefined;
  cluster?: GQLCluster | undefined;
};
export function entityDataAttributes(props: EntityDataAttributesProps = {}) {
  const attrs = Object.entries(props).reduce(
    reduceAttributes,
    {} as Record<string, string | undefined>
  );
  return attrs;
}
export function useEntityDataAttributes({
  company,
  site,
  system,
  column,
  cluster,
}: EntityDataAttributesProps) {
  return useMemo(
    () =>
      entityDataAttributes({
        company,
        site,
        system,
        cluster,
        column,
      }),
    [cluster, column, company, site, system]
  );
}

function conditionallyAddDataAttribute(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _val: any,
  propKey: string,
  acc: Record<string, string | undefined>,
  entityKey: string
) {
  _val[propKey] &&
    (acc[`data-${entityKey}-${propKey}`.toLowerCase()] = _val[propKey]);
}
