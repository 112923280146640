import { createStore, select, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { shareReplay } from 'rxjs';

export interface MobileNavigationState {
  parentPageTitle: string;
  parentPagePath: string;
  dropdownPlaceholderText: string;
  dropdownText: string;
  isNavigationMenuOpen: boolean;
  toolbarHeight: number;
  subMenuHeight: number;
}

export const mobileNavigationStore = createStore(
  { name: 'mobile-nav' },
  withProps<MobileNavigationState>({
    parentPageTitle: '',
    parentPagePath: '',
    dropdownPlaceholderText: '',
    dropdownText: '',
    isNavigationMenuOpen: false,
    toolbarHeight: 0,
    subMenuHeight: 0,
  })
);

persistState(mobileNavigationStore, {
  key: 'company',
  storage: sessionStorageStrategy,
});

export const isNavigationMenuOpen$ = mobileNavigationStore.pipe(
  select((state) => {
    return state.isNavigationMenuOpen;
  }),
  shareReplay({ refCount: false, bufferSize: 1 })
);

export const dropdownText$ = mobileNavigationStore.pipe(
  select((state) => {
    return state.dropdownText;
  }),
  shareReplay({ refCount: false, bufferSize: 1 })
);

export const dropdownPlaceholderText$ = mobileNavigationStore.pipe(
  select((state) => {
    return state.dropdownPlaceholderText;
  }),
  shareReplay({ refCount: false, bufferSize: 1 })
);

export const toolbarHeight$ = mobileNavigationStore.pipe(
  select((state) => {
    return state.toolbarHeight;
  }),
  shareReplay({ refCount: false, bufferSize: 1 })
);

export const subMenuHeight$ = mobileNavigationStore.pipe(
  select((state) => {
    return state.subMenuHeight;
  }),
  shareReplay({ refCount: false, bufferSize: 1 })
);

export const menuHeights$ = mobileNavigationStore.pipe(
  select((state) => {
    return {
      subMenuHeight: state.subMenuHeight,
      toolbarHeight: state.toolbarHeight,
      totalMenuHeight: state.subMenuHeight + state.toolbarHeight,
    };
  }),
  shareReplay({ refCount: false, bufferSize: 1 })
);

export const setIsNavigationMenuOpen = (isNavigationMenuOpen: boolean) => {
  mobileNavigationStore.update((state) => ({
    ...state,
    isNavigationMenuOpen,
  }));
};

export const setDropdownPlaceholderText = (dropdownPlaceholderText: string) => {
  mobileNavigationStore.update((state) => ({
    ...state,
    dropdownPlaceholderText,
  }));
};

export const setDropdownText = (dropdownText: string) => {
  mobileNavigationStore.update((state) => ({
    ...state,
    dropdownText,
  }));
};

export const setToolbarHeight = (toolbarHeight: number) => {
  mobileNavigationStore.update((state) => ({
    ...state,
    toolbarHeight,
  }));
};

export const setSubMenuHeight = (subMenuHeight: number) => {
  mobileNavigationStore.update((state) => ({
    ...state,
    subMenuHeight,
  }));
};
