import { Box, styled } from '@mui/material';
import { LineLegend } from '../companies';
import { lineTypes } from './companies/companiesPageDummyData';

const LineLegendContainer = styled(Box)(({ theme }) => ({
  zIndex: 1,
  width: '100%',
  backgroundColor: theme.palette.componentBase.main,
  padding: '0.5rem 1rem',
  boxShadow: theme.shadows[2],
  overflow: 'auto',
}));

export function LineLegendFooter() {
  return (
    <LineLegendContainer>
      <LineLegend lineTypes={lineTypes} />
    </LineLegendContainer>
  );
}
