import { dropdownPlaceholderText$, dropdownText$ } from '@beeriot/api-client';
import { AppTypography } from '../../../AppTypography';
import { Breadcrumb } from '../../Breadcrumb';
import { useObservable } from '@ngneat/use-observable';
import { RoutingBreadcrumbProps } from './RoutingRootBreadcrumb';
import { ReactNode } from 'react';

type RoutingGenericDropdownBreadcrumb = RoutingBreadcrumbProps & {
  renderDropdownContent?: (
    open: boolean,
    setOpen: (open: boolean) => void
  ) => ReactNode;
};

export function RoutingGenericDropdownBreadcrumb(
  props: RoutingGenericDropdownBreadcrumb
) {
  const { localizerKey, label, to, renderDropdownContent } = props;
  const [dropdownText] = useObservable(dropdownText$);
  const [dropdownPlaceholderText] = useObservable(dropdownPlaceholderText$);

  return (
    <Breadcrumb $stripeColorKey={'highlight2'} {...props} $isDropdown={true}>
      <AppTypography $bold={true} $colorKey={undefined}>
        {label ?? dropdownText ?? dropdownPlaceholderText}
      </AppTypography>
    </Breadcrumb>
  );
}
