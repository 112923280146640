import { ReactNode } from 'react';
import { Button, Stack, styled } from '@mui/material';
import { AppIcon } from '../AppIcon';
import { AppTypography } from '../AppTypography';
import { GraphSelect } from './GraphSelect';
import { SelectOption } from '../../form/SelectOption';
import { GridItemStatus } from '../../types/GridItemStatus';
import { useResponsiveSizes } from '../../utils/responsive-design/useResponsiveSizes';
import { Form } from '../../form';

const StyledStack = styled(Stack)({
  justifyContent: 'space-between',
});

const GraphTitle = styled(AppTypography)({
  padding: '0.5rem',
});

const ExpandGraphIcon = styled(AppIcon)({
  padding: '0rem',
});

export interface GraphHeaderProps {
  title?: ReactNode;
  status: GridItemStatus;
  formOnSubmit?: (data: SelectOption) => void;
  options?: SelectOption[];
  defaultValue?: SelectOption;
  onClick?: () => void;
}

export function GraphHeader({
  title,
  status,
  formOnSubmit,
  options,
  defaultValue,
  onClick,
}: GraphHeaderProps) {
  const { isMobileSize } = useResponsiveSizes();
  return (
    <Stack direction="column">
      <StyledStack direction="row">
        <GraphTitle variant="h6" $bold>
          {title}
        </GraphTitle>
        <Stack direction="row">
          {!isMobileSize && options && formOnSubmit && (
            <GraphSelect
              options={options}
              defaultValue={defaultValue}
              formOnSubmit={formOnSubmit}
            />
          )}
          <Button variant="text" onClick={onClick}>
            <ExpandGraphIcon
              icon={
                status === 'selected'
                  ? ['far', 'arrows-minimize']
                  : ['far', 'arrows-maximize']
              }
              $colorKey="lightText"
              size="2x"
            />
          </Button>
        </Stack>
      </StyledStack>
      {isMobileSize && options && formOnSubmit && (
        <GraphSelect
          options={options}
          defaultValue={defaultValue}
          formOnSubmit={formOnSubmit}
        />
      )}
    </Stack>
  );
}
