import { useIntl } from 'react-intl';
import { useMediaQuery, useTheme } from '@mui/material';
import { GQLCompanyLiveStatusSystem, GQLCompanySystemsCount } from '@beeriot/api-client';
import { getSystemColor, getSystemAbbrv } from '../types';
import { BadgeListContainer } from './BadgeListContainer';
import { LabeledBadge } from './LabeledBadge';

interface SystemCountsBadgeListProps {
  systemCounts?: GQLCompanySystemsCount;
  systems?: (GQLCompanyLiveStatusSystem | undefined | null)[] | undefined | null;
}

export function SystemCountsBadgeList({ systems, systemCounts }: SystemCountsBadgeListProps) {
  const theme = useTheme();
  const intl = useIntl();
  const NA = intl.formatMessage({
    id: 'common-not-applicable',
    defaultMessage: 'N/A',
    description: "Label for when there isn't a value to display",
  });

  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const counts = ['profi', 'cbs', 'cfs', 'keraflux'].map(k => {
    return {
      type: k,
      count: (systemCounts as Record<string, string | undefined>)?.[k],
    };
  }) ;
  

  return (
    <BadgeListContainer
      $gap={isSmall ? '0.5rem' : undefined}
      $useResponsiveSizing={isSmall ? true : false}
    >
      {counts && counts.length > 0
        ? counts?.map((systemSummary) => {
            const colorKey = getSystemColor(systemSummary?.type);
            const abbrv = getSystemAbbrv(systemSummary?.type);
            return (
              <LabeledBadge
                key={systemSummary?.type}
                label={systemSummary?.count}
                labelColorKey={colorKey}
                badgeContent={abbrv}
                badgeColorKey={colorKey}
              />
            );
          })
        : NA}
    </BadgeListContainer>
  );
}
