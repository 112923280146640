import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { styled, Stack, IconButton, Toolbar } from '@mui/material';
import { setToolbarHeight } from '@beeriot/api-client';
import { AppIcon } from '../../base/AppIcon';
import { MobileNavMenu, MobileNavMenuProps } from './MobileNavMenu';
import { useAppRoutePaths } from '../../app-routing/utils';
import { useIsSitesDetailsPage } from '../hooks';
import { useDomNodeHeight } from '../../utils/layout/useDomNodeSizes';
import { SuspenseMobileAlertButton } from '../../alerts/mobile-alert-button/SuspenseMobileAlertButton';
import { MobileDrawer } from './MobileDrawer';

const MobileNavItemsContainer = styled(Stack)({
  alignItems: 'end',
  width: '100%',
  flex: 1,
});

const MobileIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

export const MobileNavToolbar = ({
  tabs,
  activeIdx,
  isMobileMenuActive,
  setIsMobileMenuActive,

  modalContainerNode,
}: MobileNavMenuProps) => {
  const navigate = useNavigate();

  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const toolbarHeight = useDomNodeHeight({
    nodeRef: toolbarRef,
    defaultValue: 48,
    watchWindowResize: true,
    cachedValueIdentifier: 'MobileNavMenu-ToolbarHeight',
  });
  useEffect(() => {
    setToolbarHeight(toolbarHeight);
  }, [toolbarHeight]);

  const toggleMenu = useCallback(() => {
    setIsMobileMenuActive(!isMobileMenuActive);
  }, [isMobileMenuActive, setIsMobileMenuActive]);

  const isSitesDetailsPage = useIsSitesDetailsPage();
  const { companyAlertsPath, siteAlertsPath } = useAppRoutePaths();
  const openAlerts = useCallback(() => {
    navigate(isSitesDetailsPage ? siteAlertsPath : companyAlertsPath);
  }, [navigate, isSitesDetailsPage, companyAlertsPath, siteAlertsPath]);

  return (
    <>
      <MobileNavItemsContainer>
        <Toolbar ref={toolbarRef} disableGutters>
          {isMobileMenuActive ? null : (
            <SuspenseMobileAlertButton openAlerts={openAlerts} />
          )}
          <MenuButton
            toggleMenu={toggleMenu}
            isMenuActive={isMobileMenuActive}
          />
        </Toolbar>
      </MobileNavItemsContainer>
      <MobileDrawer
        anchor={'top'}
        open={isMobileMenuActive}
        $toolbarHeight={toolbarHeight}
        onClose={() => setIsMobileMenuActive(false)}
        ModalProps={{ container: modalContainerNode?.current }}
      >
        <MobileNavMenu
          tabs={tabs}
          activeIdx={activeIdx}
          isMobileMenuActive={isMobileMenuActive}
          setIsMobileMenuActive={setIsMobileMenuActive}
        />
      </MobileDrawer>
    </>
  );
};

interface MenuButtonProps {
  toggleMenu: () => void;
  isMenuActive: boolean;
}

const MenuButton = ({ toggleMenu, isMenuActive }: MenuButtonProps) => {
  return (
    <MobileIconButton
      size="large"
      edge="start"
      color="white"
      aria-label="menu"
      onClick={toggleMenu}
    >
      {isMenuActive ? <AppIcon icon="close" /> : <AppIcon icon="bars" />}
    </MobileIconButton>
  );
};
