import { styled, Tab, TabProps } from '@mui/material';
import React, { ForwardedRef, memo } from 'react';
import { generatePath } from 'react-router-dom';
import { transientOptions } from '../../utils/transientOptions';
import { RouterLink, RouterLinkProps } from '../RouterLink';

export type RouteTabLinkProps = TabProps &
  Omit<RouterLinkProps, 'to'> & {
    to?: string;
    selected?: boolean;
    $showViewingIndicator?: boolean;
  };

export const UnstyledRouteTabLink = React.forwardRef(
  (props: RouteTabLinkProps, ref: ForwardedRef<HTMLAnchorElement>) => {
    let linkTo = props.to ?? '';
    if (props.params) {
      linkTo = generatePath(linkTo, props.params);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const strippedProps: any = {
      ...props,
    };
    delete strippedProps.buttonElement;
    return (
      <Tab
        ref={ref}
        component={RouterLink}
        {...strippedProps}
        to={linkTo}
        value={`${props.value}`}
      />
    );
  }
);

export const RouteTabLink = styled(
  memo(UnstyledRouteTabLink),
  transientOptions
)(({ hidden }) => {
  return {
    ...(hidden ? { display: 'none' } : {}),
  };
});
