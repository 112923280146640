import { useIntl } from 'react-intl';
import { Stack, styled } from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import { siteSearch$, siteStore } from '@beeriot/api-client';
import { transientOptions } from '../utils';
import { Form, FormTextInput, SelectOption } from '../form';
import { SuspenseGroupFilters } from './group-filters';

const StyledStack = styled(Stack)({
  gap: '2rem',
});

const StyledFormTextInput = styled(
  FormTextInput,
  transientOptions
)<{ $fullWidth?: boolean }>(({ $fullWidth }) => ({
  flex: 1,
  minWidth: '10rem',
  maxWidth: $fullWidth ? 'unset' : '25rem',
}));

interface SitesSearchFormFields {
  siteName: string;
  groupBy: SelectOption;
  group: SelectOption;
}

interface SitesFilterFormProps {
  direction?: 'row' | 'column';
}

export function SitesFilterForm({ direction = 'row' }: SitesFilterFormProps) {
  const [searchText] = useObservable(siteSearch$);
  const intl = useIntl();
  const selectSitePlaceholder = intl.formatMessage({
    id: 'sites-search-placeholder',
    description: 'Placeholder for sites search input',
    defaultMessage: 'Search by site name',
  });
  return (
    <Form<SitesSearchFormFields>
      submitOnChange
      onSubmit={(data: SitesSearchFormFields) => {
        siteStore.update((state) => ({
          ...state,
          siteSearch: data.siteName ?? state.siteSearch,
          groupBy: data.groupBy?.value ?? state.groupBy,
          group: data.group?.value ?? state.group,
        }));
      }}
    >
      <StyledStack direction={direction}>
        <SuspenseGroupFilters />
        <StyledFormTextInput
          $fullWidth={direction === 'column'}
          defaultValue={searchText}
          name="siteName"
          placeholder={selectSitePlaceholder}
          icon="magnifying-glass"
        />
      </StyledStack>
    </Form>
  );
}
