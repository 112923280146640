import { AppTypography } from '../../../base/AppTypography';

export interface FilterRunsVolumeFieldProps {
  volume: number;
  volumeUnit: string;
}

export function FilterRunsVolumeField({
  volume,
  volumeUnit,
}: FilterRunsVolumeFieldProps) {
  return (
    <AppTypography>
      {volume === 0 ? '-' : `${volume} ${volumeUnit}`}
    </AppTypography>
  );
}
