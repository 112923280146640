import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import { GroupBase, StylesConfig } from 'react-select';
import { QueryErrorResetBoundary } from 'react-query';
import { styled } from '@mui/material';
import { LoadingPaper } from '../../base/LoadingPaper';
import { IconLabel } from '../../base/IconLabel';

const SiteDropdownLoadingPaper = styled(LoadingPaper)({
  height: '2rem',
  width: '15rem',
  margin: '0.5rem',
});

const SitesDropdownList = lazy(() =>
  import('./SitesDropdownListQuery').then(({ SitesDropdownListQuery }) => ({
    default: SitesDropdownListQuery,
  }))
);

interface SuspenseSiteDropdownProps {
  selectStyles?: StylesConfig<unknown, boolean, GroupBase<unknown>> | undefined;
  isMulti?: boolean;
  showAllOption?: boolean;
  defaultValue?: string;
}

export function SuspenseSiteDropdown({
  selectStyles,
  isMulti,
  showAllOption,
  defaultValue,
}: SuspenseSiteDropdownProps) {
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <IconLabel
                label={
                  <FormattedMessage
                    id="sites-dropdown-error-label"
                    description="Error message for sites dropdown error"
                    defaultMessage="Sites Unavailable"
                  />
                }
              />
            );
          }}
        >
          <Suspense fallback={<SiteDropdownLoadingPaper />}>
            <SitesDropdownList
              selectStyles={selectStyles}
              isMulti={isMulti}
              showAllOption={showAllOption}
              defaultValue={defaultValue}
            />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
