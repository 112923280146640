import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { IconLabel, LoadingBox } from '../../base';
import { AlertCountQueryProps } from './AlertCountQuery';

const MedallionLoadingBox = styled(LoadingBox)({
  height: '1.5rem',
  width: '1.5rem',
  borderRadius: '50%',
});

const AlertCount = lazy(() =>
  import('./AlertCountQuery').then(({ AlertCountQuery }) => ({
    default: AlertCountQuery,
  }))
);

export function SuspenseAlertCount(props: AlertCountQueryProps) {
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <IconLabel
                label={
                  <FormattedMessage
                    id="alerts-error-label"
                    description="Error message for alerts"
                    defaultMessage="Alerts Unavailable"
                  />
                }
              />
            );
          }}
        >
          <Suspense fallback={<MedallionLoadingBox />}>
            <AlertCount {...props} />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
