import { FormattedMessage } from 'react-intl';
import { UseMutationResult } from 'react-query';
import { GQLReportDefinition, ReportDefinition } from '@beeriot/api-client';
import { AppDialog } from '../../base/AppDialog';
import { AppTypography } from '../../base/AppTypography';
import { ReportDeletionConfirmationDialog } from './ReportDeletionConfirmationDialog';

interface DeleteRecurringReportDialogProps {
  open: boolean;
  selectedReport: GQLReportDefinition;
  deleteMutation: UseMutationResult<ReportDefinition, unknown, string, unknown>;
  onClose: () => void;
}

export function DeleteRecurringReportDialog({
  open,
  selectedReport,
  deleteMutation,
  onClose,
}: DeleteRecurringReportDialogProps) {
  return (
    <>
      <ReportDeletionConfirmationDialog deleteMutation={deleteMutation} />
      <AppDialog
        open={open}
        dialogTitle={
          <FormattedMessage
            id="recurring-report-dialog-confirm-delete-dialog-title-label"
            description="Label for the recurring report form delete confirmation dialog title"
            defaultMessage="Delete Recurring Report?"
          />
        }
        dialogContent={
          <AppTypography>
            <FormattedMessage
              id="recurring-report-dialog-confirm-delete-dialog-message-label"
              description="Label for the recurring report form delete confirmation dialog message"
              defaultMessage={`Are you sure you wish to delete the recurring report "{reportName}"?`}
              values={{ reportName: selectedReport.reportName }}
            />
          </AppTypography>
        }
        confirm={() => {
          deleteMutation.mutate(selectedReport.id);
        }}
        confirmLabel={
          <FormattedMessage
            id="common-yes-delete-button"
            description="label for confirm delete button"
            defaultMessage="Yes, Delete"
          />
        }
        showCancelButton
        cancel={onClose}
      />
    </>
  );
}
