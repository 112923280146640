import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { AreaSystem } from '@beeriot/api-client';
import { FormSelect, SelectOption } from '../../form';

interface SiteDetailsSidebarAreaSelectProps {
  areas: AreaSystem[];
}

export function SiteDetailsSidebarAreaSelect({
  areas,
}: SiteDetailsSidebarAreaSelectProps) {
  const { watch, setValue } = useFormContext();
  const area = watch('area');

  const intl = useIntl();
  const selectPlaceholder = intl.formatMessage({
    id: 'area-select-placeholder',
    description: 'Placeholder for area select',
    defaultMessage: 'Select Area',
  });

  const commonArea = intl.formatMessage({
    id: 'common-area',
    description: 'Label for area',
    defaultMessage: 'Area',
  });

  const areaOptions: SelectOption[] = [
    {
      label: 'All Areas',
      value: '',
    },
  ].concat(
    areas.map((x: AreaSystem) => ({
      label: x.area.name,
      value: x.area.id ?? '',
    }))
  );

  useEffect(() => {
    if (area) {
      const option = areaOptions.find((x) => x.value === area.value);
      if (option && option.label !== area.label) {
        setValue('area', option);
      }
    }
  }, [area, areaOptions, setValue]);

  return (
    <FormSelect
      name="area"
      options={areaOptions}
      placeholder={selectPlaceholder}
      innerLabel={`${commonArea}:`}
    />
  );
}
