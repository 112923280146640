import { useMemo } from 'react';

import { SystemKpiHookProps } from '../SystemKpiHookProps';
import { useSystemKpiHookDefaultProps } from '../useSystemKpiHookDefaultProps';
import {
  useSystemCompactTitleHeaderGridItem,
  useSystemTitleHeaderGridItem,
} from './system-kpis/useSystemTitleHeaderGridItem';

export function useSystemCardHeaderGridContent(
  props: Omit<
    SystemKpiHookProps,
    | 'showForSystemTypes'
    | 'hideOnMobileForSystemTypes'
    | 'NA'
    | 'gridArea'
    | 'activeKpis'
  >
) {
  const defaultHookProps = useSystemKpiHookDefaultProps(props);

  // title headers
  const desktopTitleHeaderItem = useSystemTitleHeaderGridItem({
    ...defaultHookProps,
    gridArea: 'titleHeader',
  });
  const mobileTitleHeaderItem = useSystemCompactTitleHeaderGridItem({
    ...defaultHookProps,
    gridArea: 'mobileTitleHeader',
  });

  const _gridContent = useMemo(() => {
    return (
      <>
        {desktopTitleHeaderItem}
        {mobileTitleHeaderItem}
      </>
    );
  }, [desktopTitleHeaderItem, mobileTitleHeaderItem]);
  return _gridContent;
}
