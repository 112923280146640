import { Button, styled, Stack } from '@mui/material';
import { useCallback, useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useObservable } from '@ngneat/use-observable';
import { useSoftDeleteGroup, Group, activeCompany$ } from '@beeriot/api-client';
import {
  AppIcon,
  AppTypography,
  ControlBox,
  IconLabel,
  Breadcrumb,
  PermissionKey,
  PermissionView,
} from '../../../../base';
import {
  ConfirmGroupDeleteDialog,
  EditGroupNameDialog,
} from '../../../../sites';
import { transientOptions, useResponsiveSizes } from '../../../../utils';

const StyledEditNameDialog = styled(EditGroupNameDialog)({
  width: '70vw',
});

const EditNameContainer = styled(
  Stack,
  transientOptions
)<{ $isMobile: boolean }>(({ $isMobile }) => ({
  marginLeft: $isMobile ? 'auto' : '2rem',
}));

const DeleteButtonContainer = styled(Stack)({
  padding: '0.75rem 1rem',
  marginLeft: 'auto',
});

const StyledControlBox = styled(ControlBox)({
  padding: '0px',
});

const EditGroupTypeButtonText = styled(AppTypography)({
  margin: '0rem 0.5rem',
});

const StyledBreadcrumb = styled(Breadcrumb)({
  width: '15rem',
});

interface OrganizeSitesControlBoxProps {
  types: Group[];
  activeGroupType: Group | null;
}

export function OrganizeSitesControlBox({
  types,
  activeGroupType,
}: OrganizeSitesControlBoxProps) {
  const [activeCompany] = useObservable(activeCompany$);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const { isMobileSize } = useResponsiveSizes();
  const deleteGroup = useSoftDeleteGroup();
  const navigate = useNavigate();

  const currGroup = useMemo(() => {
    const type = activeGroupType
      ? types.find((x) => x.id === activeGroupType.id)
      : null;
    return type ?? null;
  }, [types, activeGroupType]);

  const handleDeleteGroup = useCallback(
    (groupToDelete: Group | null) => {
      if (groupToDelete) {
        deleteGroup.mutate(groupToDelete);
        setOpenDelete(false);
      }
    },
    [deleteGroup]
  );

  return (
    <StyledControlBox>
      <StyledBreadcrumb
        $stripeColorKey={'primary'}
        $isDropdown={false}
        $followingItemStripeColorKey={'componentBase'}
        onClick={() => navigate(`/companies/${activeCompany?.id}/sites`)}
        children={
          <AppTypography $bold={true} $colorKey={'primary'}>
            <FormattedMessage
              id="common-sites-label"
              defaultMessage="Sites"
              description="Sites label"
            />
          </AppTypography>
        }
      />
      <EditNameContainer direction="row" $isMobile={isMobileSize}>
        <AppTypography
          sx={{ whiteSpace: 'nowrap', padding: '0 1rem 0 0' }}
          variant={isMobileSize ? 'body2' : 'h6'}
          $colorKey="lightText"
        >
          <FormattedMessage
            id="organize-sites-control-box-group-type-label"
            defaultMessage="Group Type:"
            description="Group type label"
          />
          {currGroup ? (
            <Button onClick={() => setOpenEdit(true)}>
              <EditGroupTypeButtonText
                variant={isMobileSize ? 'body2' : 'h6'}
                $colorKey="darkText"
                $bold
              >
                {currGroup.name}
              </EditGroupTypeButtonText>
              <AppIcon $colorKey="highlight3" icon={'pencil-alt'} />
            </Button>
          ) : null}
        </AppTypography>
        {currGroup === null ? (
          <EditGroupTypeButtonText variant="h6" $colorKey="darkText" $bold>
            <FormattedMessage
              id="common-not-applicable"
              defaultMessage="N/A"
              description="Label for when there isn't a value to display"
            />
          </EditGroupTypeButtonText>
        ) : null}
      </EditNameContainer>
      {!isMobileSize ? (
        <PermissionView
          permissionKey={PermissionKey.SitesOrganizeSitesDeleteRegionGroupType}
        >
          <DeleteButtonContainer>
            <Button
              variant="contained"
              color="error"
              onClick={() => setOpenDelete(true)}
            >
              <IconLabel
                gap="0.5rem"
                icon={['far', 'trash-alt']}
                variant="body1"
                iconSize="lg"
                label={
                  <FormattedMessage
                    id="sites-organize-sites-delete-group-type-button"
                    description="Button to delete a group type"
                    defaultMessage="Delete Group Type"
                  />
                }
              />
            </Button>
          </DeleteButtonContainer>
        </PermissionView>
      ) : null}
      <StyledEditNameDialog
        group={currGroup}
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
      />
      <ConfirmGroupDeleteDialog
        mutation={deleteGroup}
        group={currGroup}
        open={openDelete}
        confirm={handleDeleteGroup}
        cancel={() => setOpenDelete(false)}
        isGroupType={true}
      />
    </StyledControlBox>
  );
}
