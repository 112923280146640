import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { InputBase, InputBaseProps, Stack, styled } from '@mui/material';
import { useCurrencySymbol } from '../../../utils';
import {
  useHasPermission,
  PermissionKey,
} from '../../../base/user-permissions';

const TextInputContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  padding: '0.5rem',
  borderRadius: '0.5rem',
  border: 'none',
  width: '100%',
  marginLeft: '1rem',
  fontFamily: theme.typography.fontFamily,
}));

const StyledTextInput = styled(InputBase)({
  flexGrow: 1,
  fontWeight: 'bold',
});

export interface SectionCurrencyInputProps extends InputBaseProps {
  name: string;
  currency: string;
  langCode: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
}

export function SectionCurrencyInput({
  name,
  placeholder,
  disabled,
  className,
  currency,
  langCode,
  defaultValue,
}: SectionCurrencyInputProps) {
  const { control } = useFormContext();
  const currencySymbol = useCurrencySymbol(langCode, currency);
  const hasPermission = useHasPermission();

  return (
    <TextInputContainer>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ field: { onChange, name, value } }) => {
          return (
            <NumberFormat
              prefix={currencySymbol}
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              disabled={disabled ?? false}
              className={className}
              placeholder={placeholder}
              customInput={StyledTextInput}
              value={value}
              onValueChange={(values: NumberFormatValues) => {
                if (values.floatValue === undefined) {
                  onChange(0);
                  return;
                }
                onChange(values.floatValue);
              }}
              readOnly={
                !hasPermission(PermissionKey.SettingsLocalUtilityCostsEdit)
              }
            />
          );
        }}
      />
    </TextInputContainer>
  );
}
