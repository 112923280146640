export enum PermissionKey {
  // Companies List Page
  CompaniesList = 'CompaniesList',
  CompaniesEditCompanies = 'CompaniesEditCompanies',

  // Sites List Page
  SitesList = 'SitesList',
  SitesListPreviewInfo = 'SitesListPreviewInfo',

  // Edit Sites Page
  SitesEditSites = 'SitesEditSites',

  // Organize Sites Page
  SitesOrganizeSites = 'SitesOrganizeSites',
  SitesOrganizeSitesAddSiteToRegionGroup = 'SitesOrganizeSitesAddSiteToRegionGroup',
  SitesOrganizeSitesRemoveSiteFromRegionGroup = 'SitesOrganizeSitesRemoveSiteFromRegionGroup',
  SitesOrganizeSitesCreateRegionGroup = 'SitesOrganizeSitesCreateRegionGroup',
  SitesOrganizeSitesCreateRegionGroupType = 'SitesOrganizeSitesCreateRegionGroupType',
  SitesOrganizeSitesDeleteRegionGroup = 'SitesOrganizeSitesDeleteRegionGroup',
  SitesOrganizeSitesDeleteRegionGroupType = 'SitesOrganizeSitesDeleteRegionGroupType',

  // Site Details
  SiteDetails = 'SiteDetails',
  SitesSiteOverviewSidebar = 'SitesSiteOverviewSidebar',
  SitesSiteOverviewToggleSidebar = 'SitesSiteOverviewToggleSidebar',
  SitesSiteOverviewSidebarSelectAreas = 'SitesSiteOverviewSidebarSelectAreas',
  SitesSiteOverviewUpdateFiltrationInfo = 'SitesSiteOverviewUpdateFiltrationInfo',
  SitesSiteOverviewUpdateStabilizationInfo = 'SitesSiteOverviewUpdateStabilizationInfo',
  SitesSiteOverviewUpdatePackingInfo = 'SitesSiteOverviewUpdatePackingInfo',
  SitesSiteOverviewUpdateBeerRecoveryInfo = 'SitesSiteOverviewUpdateBeerRecoveryInfo',

  SitesPROFiLineStatus = 'SitesPROFiLineStatus',
  SitesPROFiLineStatusDetailsCard = 'SitesPROFiLineStatusDetailsCard',
  SitesPROFiLineStatusWidget = 'SitesPROFiLineStatusWidget',
  SitesPROFiLineStatusWidgetHide = 'SitesPROFiLineStatusWidgetHide',
  SitesPROFiStatistics = 'SitesPROFiStatistics',
  SitesPROFiStatisticsDataExport = 'SitesPROFiStatisticsDataExport',
  SitesPROFiFilterRuns = 'SitesPROFiFilterRuns',
  SitesPROFiFilterRunsDataExport = 'SitesPROFiFilterRunsDataExport',
  SitesPROFiDocumentation = 'SitesPROFiDocumentation',
  SitesPROFiDocumentationAdd = 'SitesPROFiDocumentationAdd',
  SitesPROFiDocumentationDelete = 'SitesPROFiDocumentationDelete',
  SitesPROFiReports = 'SitesPROFiReports',
  SitesPROFiReportsAdd = 'SitesPROFiReportsAdd',
  SitesPROFiBrandEdit = 'SitesPROFiBrandEdit',
  SitesPROFiClusters = 'SitesPROFiClusters',
  SitesPROFiMembraneInfo = 'SitesPROFiMembraneInfo',
  SitesPROFiSystemEdit = 'SitesPROFiSystemEdit',

  SitesCBSLineStatus = 'SitesCBSLineStatus',
  SitesCBSLineStatusDetailsCard = 'SitesCBSLineStatusDetailsCard',
  SitesCBSLineStatusWidget = 'SitesCBSLineStatusWidget',
  SitesCBSLineStatusWidgetHide = 'SitesCBSLineStatusWidgetHide',
  SitesCBSStatistics = 'SitesCBSStatistics',
  SitesCBSStatisticsDataExport = 'SitesCBSStatisticsDataExport',
  SitesCBSDocumentation = 'SitesCBSDocumentation',
  SitesCBSDocumentationAdd = 'SitesCBSDocumentationAdd',
  SitesCBSDocumentationDelete = 'SitesCBSDocumentationDelete',
  SitesCBSReports = 'SitesCBSReports',
  SitesCBSReportsAdd = 'SitesCBSReportsAdd',
  SitesCBSBrandEdit = 'SitesCBSBrandEdit',
  SitesCBSSystemEdit = 'SitesCBSSystemEdit',

  SitesCFSLineStatus = 'SitesCFSLineStatus',
  SitesCFSLineStatusDetailsCard = 'SitesCFSLineStatusDetailsCard',
  SitesCFSLineStatusWidget = 'SitesCFSLineStatusWidget',
  SitesCFSLineStatusWidgetHide = 'SitesCFSLineStatusWidgetHide',
  SitesCFSFilterRuns = 'SitesCFSFilterRuns',
  SitesCFSStatistics = 'SitesCFSStatistics',
  SitesCFSStatisticsDataExport = 'SitesCFSStatisticsDataExport',
  SitesCFSDocumentation = 'SitesCFSDocumentation',
  SitesCFSDocumentationAdd = 'SitesCFSDocumentationAdd',
  SitesCFSDocumentationDelete = 'SitesCFSDocumentationDelete',
  SitesCFSReports = 'SitesCFSReports',
  SitesCFSReportsAdd = 'SitesCFSReportsAdd',
  SitesCFSBrandEdit = 'SitesCFSBrandEdit',
  SitesCFSSystemEdit = 'SitesCFSSystemEdit',

  SitesKerafluxLineStatus = 'SitesKerafluxLineStatus',
  SitesKerafluxLineStatusDetailsCard = 'SitesKerafluxLineStatusDetailsCard',
  SitesKerafluxLineStatusWidget = 'SitesKerafluxLineStatusWidget',
  SitesKerafluxLineStatusWidgetHide = 'SitesKerafluxLineStatusWidgetHide',
  SitesKerafluxBatchInfo = 'SitesKerafluxBatchInfo',
  SitesKerafluxStatistics = 'SitesKerafluxStatistics',
  SitesKerafluxStatisticsDataExport = 'SitesKerafluxStatisticsDataExport',
  SitesKerafluxDocumentation = 'SitesKerafluxDocumentation',
  SitesKerafluxDocumentationAdd = 'SitesKerafluxDocumentationAdd',
  SitesKerafluxDocumentationDelete = 'SitesKerafluxDocumentationDelete',
  SitesKerafluxReports = 'SitesKerafluxReports',
  SitesKerafluxReportsAdd = 'SitesKerafluxReportsAdd',
  SitesKerafluxBrandEdit = 'SitesKerafluxBrandEdit',
  SitesKerafluxSystemEdit = 'SitesKerafluxSystemEdit',

  // Executive Summary Page
  ExecutiveSummary = 'ExecutiveSummary',

  ExecutiveSummaryMachine = 'ExecutiveSummaryMachine',
  ExecutiveSummaryMachineTimeframeSelection = 'ExecutiveSummaryMachineTimeframeSelection',
  ExecutiveSummaryMachineDataExport = 'ExecutiveSummaryMachineDataExport',

  ExecutiveSummaryMembraneBlock = 'ExecutiveSummaryMembraneBlock',
  ExecutiveSummaryMembraneBlockDataExport = 'ExecutiveSummaryMembraneBlockDataExport',
  ExecutiveSummaryMembraneBlockEquipmentEffectiveGraph = 'ExecutiveSummaryMembraneBlockDataExport',

  ExecutiveSummaryCompareLines = 'ExecutiveSummaryCompareLines',
  ExecutiveSummaryCompareLinesComparedGraphsDataExport = 'ExecutiveSummaryCompareLinesComparedGraphsDataExport',
  ExecutiveSummaryCompareLinesBeerProductionGraphDataExport = 'ExecutiveSummaryCompareLinesBeerProductionGraphDataExport',
  ExecutiveSummaryCompareLinesFilterByLines = 'ExecutiveSummaryCompareLinesDataExport',
  ExecutiveSummaryCompareBrands = 'ExecutiveSummaryCompareBrands',

  // Analytics Page
  Analytics = 'Analytics',
  AnalyticsExportData = 'AnalyticsExportData',

  //Alerts Widget
  AlertWidget = 'AlertWidget',

  // Alert History Page
  AlertHistory = 'AlertHistory',
  AlertHistoryDetails = 'AlertHistoryDetails',
  AlertHistoryDismiss = 'AlertHistoryDismiss',

  // Settings Pages
  Settings = 'Settings',

  // Provisioning Settings Page
  SettingsProvisioning = 'SettingsProvisioning',
  SettingsProvisioningStartSetup = 'SettingsProvisioningStartSetup',
  // Certificate Provisioning Page
  SettingsCertificateProvisioning = 'CertificateProvisioning',
  SettingsCertificateProvisioningGenerate = 'CertificateProvisioningGenerate',

  // Account Settings Page
  SettingsAccount = 'SettingsAccount',
  SettingsAccountEdit = 'SettingsAccountEdit',

  // User MGMT Settings Page
  SettingsUserManagement = 'SettingsUserManagement',
  SettingsUserManagementAddUser = 'SettingsUserManagementAddUser',

  // Alerts Settings Page
  SettingsAlerts = 'SettingsAlerts',
  SettingsAlertsEdit = 'SettingsAlertEdit',

  // Alerts Settings Page
  SettingsPushNotifications = 'SettingsPushNotifications',

  // Automatic Reports Settings Page
  SettingsReporting = 'SettingsReporting',
  SettingsReportingCreate = 'SettingsReportingCreate',
  SettingsReportingEdit = 'SettingsReportingEdit',
  SettingsReportingDelete = 'SettingsReportingDelete',

  // Change Password Settings Page
  SettingsChangePassword = 'SettingsChangePassword',

  // Local Utility Costs Settings Page
  SettingsLocalUtilityCosts = 'SettingsLocalUtilityCosts',
  SettingsLocalUtilityCostsEdit = 'SettingsLocalUtilityCostsEdit',
  SettingsLocalUtilityBillingUnitsEdit = 'SettingsLocalUtilityBillingUnitsEdit',

  // IoT Status Settings Page
  SettingsIoTStatus = 'SettingsIoTStatus',

  // Pall Notifications Settings Page
  SettingsPallNotifications = 'SettingsPallNotifications',
  SettingsPallNotificationsSelectRoles = 'SettingsPallNotificationsSelectRoles',

  // Beer Types Settings Page
  SettingsBeerTypes = 'SettingsBeerTypes',
  SettingsBeerTypesAdd = 'SettingsBeerTypesAdd',
  SettingsBeerTypesDelete = 'SettingsBeerTypesDelete',

  // Thresholds Settings Page
  SettingsThresholds = 'SettingsThresholds',
  SettingsThresholdsEdit = 'SettingsThresholdsEdit',
  SettingsThresholdsConsumptionCalulationEdit = 'SettingsThresholdsConsumptionCalulationEdit',
  SettingsThresholdsCloneSettings = 'SettingsThresholdsCloneSettings',
}
