import { CSSObjectWithLabel } from 'react-select';
import { FormSelect, FormSelectProps } from '../../../form/FormSelect';

const selectStyles = {
  control: (base: CSSObjectWithLabel) => ({
    ...base,
    border: 'none',
    padding: '0.5rem',
  }),
};

export const SectionFormSelect = (props: FormSelectProps) =>
  FormSelect({ ...props, styles: selectStyles });
