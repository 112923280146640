import { styled } from '@mui/material';
import { AppTypography } from '../AppTypography';

const AppTableHeaderText = styled(AppTypography)({
  padding: '1rem',
});

AppTableHeaderText.defaultProps = {
  variant: 'h6',
  $bold: true,
};

export { AppTableHeaderText };
