import { Stack, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { AppTypography } from '../../../base/AppTypography';

const StyledStack = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem',
  backgroundColor: theme.palette.componentBase.main,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
}));

export function AddOrEditSiteListHeader() {
  return (
    <StyledStack direction="row">
      <AppTypography variant="h6" $bold>
        <FormattedMessage
          id="organize-sites-add-edit-sites-add-sites-label"
          description="Add sites label"
          defaultMessage="Add Sites"
        />
      </AppTypography>
    </StyledStack>
  );
}
