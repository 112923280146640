import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { IconLabel, LoadingPaper } from '../../base';
import { AccountSettingsProps } from './AccountSettings';

const SettingsLoadingPaper = styled(LoadingPaper)({
  height: '50rem',
  width: '40rem',
  margin: '0.5rem',
});

const AccountSettingsForm = lazy(() =>
  import('./AccountSettingsQuery').then(({ AccountSettingsQuery }) => ({
    default: AccountSettingsQuery,
  }))
);

export function SuspenseAccountSettings({
  mutation,
  error,
}: AccountSettingsProps) {
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <IconLabel
                label={
                  <FormattedMessage
                    id="settings-account-settings-error-label"
                    description="Error message for account settings error"
                    defaultMessage="Account Settings Unavailable"
                  />
                }
              />
            );
          }}
        >
          <Suspense fallback={<SettingsLoadingPaper />}>
            <AccountSettingsForm mutation={mutation} error={error} />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
