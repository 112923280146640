import { FormattedMessage } from 'react-intl';
import { AppTableHeader, AppTableHeaderControlsContainer } from '../../base';
import { DocumentationSearch } from './DocumentationSearch';
import { useResponsiveSizes } from '../../utils';
import { DocumentationButtons } from './DocumentationButtons';

interface DocumentationPanelHeaderProps {
  showAddForm: () => void;
  toggleDeletedDocuments: () => void;
  showDeletedDocuments: boolean;
}

export const DocumentationPanelHeader = ({
  showAddForm,
  toggleDeletedDocuments,
  showDeletedDocuments,
}: DocumentationPanelHeaderProps) => {
  const { isDesktopSize } = useResponsiveSizes();
  return (
    <AppTableHeader
      label={
        <FormattedMessage
          id="documentation-table-header-title"
          defaultMessage="Documentation"
          description="header for documentation table"
        />
      }
    >
      <AppTableHeaderControlsContainer>
        <DocumentationSearch />
        {isDesktopSize && (
          <DocumentationButtons
            showAddForm={showAddForm}
            toggleDeletedDocuments={toggleDeletedDocuments}
            showDeletedDocuments={showDeletedDocuments}
          />
        )}
      </AppTableHeaderControlsContainer>
    </AppTableHeader>
  );
};
