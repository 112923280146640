import { useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert } from '@mui/material';
import { useObservable } from '@ngneat/use-observable';

import {
  getEndDate,
  lineStatisticsDate$,
  lineStatisticsFilterRun$,
  lineStatisticsViewingFilter$,
  useActiveAppEntities,
  useGetStatisticsExport,
} from '@beeriot/api-client';
import { AppDialog } from '../../base/AppDialog';

export interface ConfirmExportDataDialogProps {
  cancel: () => void;
  confirm: () => void;
  open: boolean;
}

export const ConfirmExportDataDialog = ({
  cancel,
  confirm,
  open,
}: ConfirmExportDataDialogProps) => {
  const [hasError, setHasError] = useState(false);
  const { activeSystem } = useActiveAppEntities();
  const [viewingFilter] = useObservable(lineStatisticsViewingFilter$);
  const [date] = useObservable(lineStatisticsDate$);
  const [filterRun] = useObservable(lineStatisticsFilterRun$);

  const exportAnalyticsDataMutation = useGetStatisticsExport(
    (url) => {
      const link = document.createElement('a');
      link.href = url;
      link.click();
      link.remove();
      cancel();
    },
    (_err) => setHasError(true)
  );

  const onConfirmDataExport = useCallback(() => {
    if (viewingFilter === 'Run') {
      exportAnalyticsDataMutation.mutate({
        systemId: activeSystem?.id,
        filterRunId: filterRun?.filterRunId ?? undefined,
      });
    } else if (date) {
      exportAnalyticsDataMutation.mutate({
        systemId: activeSystem?.id,
        startTime: date,
        endTime: getEndDate(date, viewingFilter),
      });
    }
    confirm();
  }, [
    activeSystem?.id,
    confirm,
    date,
    exportAnalyticsDataMutation,
    filterRun?.filterRunId,
    viewingFilter,
  ]);

  const intl = useIntl();
  const dialogTitle = intl.formatMessage({
    id: 'statistics-export-data-modal-confirm-dialog-title-label',
    description: 'Label for the data export confirmation dialog title',
    defaultMessage: 'Export Data',
  });

  const dialogMessage = intl.formatMessage({
    id: 'statistics-export-data-modal-confirm-dialog-message-label',
    description: 'Label for the data export confirmation dialog message',
    defaultMessage: 'Are you sure you want to export statistics data?',
  });
  return (
    <AppDialog
      dialogTitle={dialogTitle}
      dialogContent={
        <>
          {dialogMessage}
          {hasError && (
            <Alert
              variant="filled"
              severity="info"
              sx={{ marginBottom: '1rem' }}
            >
              <FormattedMessage
                id="export-statistics-data-dialog-error-label"
                defaultMessage="An error occurred while exporting statistics data. Please try again later or contact support."
                description="Label for statistics export error"
              />
            </Alert>
          )}
        </>
      }
      open={open}
      confirm={onConfirmDataExport}
      showCancelButton
      cancel={cancel}
    />
  );
};
