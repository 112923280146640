import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { QueryErrorResetBoundary } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { Stack, Button, Typography, styled } from '@mui/material';
import { LoadingPaper } from '../../base';

const LineStatusPanelContent = lazy(() =>
  import('./LineStatusPanelContentQuery').then(
    ({ LineStatusPanelContentQuery }) => ({
      default: LineStatusPanelContentQuery,
    })
  )
);

const LineStatusLoadingPaper = styled(LoadingPaper)({
  height: '8rem',
  width: '30rem',
  margin: '1.5rem',
});

const ErrorContainer = styled(Stack)({
  width: '100%',
  alignItems: 'center',
});

const MessageContainer = styled(Stack)({
  textAlign: 'center',
  width: '30rem',
  margin: '3rem',
  justifyContent: 'center',
});

function LineStatusLoadingPapers() {
  return (
    <Stack>
      <Stack direction="row">
        <LineStatusLoadingPaper />
        <LineStatusLoadingPaper />
      </Stack>
      <Stack direction="row">
        <LineStatusLoadingPaper />
        <LineStatusLoadingPaper />
      </Stack>
      <Stack direction="row">
        <LineStatusLoadingPaper />
        <LineStatusLoadingPaper />
      </Stack>
    </Stack>
  );
}

export function SuspenseLineStatusPanelContent() {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ resetErrorBoundary }) => {
            return (
              <ErrorContainer>
                <MessageContainer>
                  <Typography>
                    <FormattedMessage
                      id="line-status-sidebar-loading-error-label"
                      description="Label for line status loading error"
                      defaultMessage="There was an error loading the line status"
                    />
                  </Typography>
                  <Button onClick={() => resetErrorBoundary()}>
                    <FormattedMessage
                      id="common-try-again-button"
                      description="Button for trying an action again"
                      defaultMessage="Try Again"
                    />
                  </Button>
                </MessageContainer>
              </ErrorContainer>
            );
          }}
          onReset={reset}
        >
          <Suspense fallback={<LineStatusLoadingPapers />}>
            <LineStatusPanelContent />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
