import { QueryClient } from 'react-query';
import {
  LONG_POLLING_INTERVAL,
  LONG_STALE_TIME_INTERVAL
} from './queryConstants';

export const AppQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      staleTime: LONG_STALE_TIME_INTERVAL,
      cacheTime: LONG_STALE_TIME_INTERVAL,
      refetchInterval: LONG_POLLING_INTERVAL,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});
