import { Grid, Stack, styled } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { GQLSystem, useGQLSystemCleanUpLiveKPI } from '@beeriot/api-client';
import { AppCard, AppMedallion, AppTypography } from '../../../base';
import { getTimeSince } from '../../../utils';

const StyledStack = styled(Stack)({
  alignItems: 'center',
  gap: '0.5rem',
});

const CleaningTypeIndicator = styled(AppMedallion)({
  height: '1rem',
  width: '1rem',
});

const StyledCard = styled(AppCard)({
  height: '100%',
});

const StyledGridItem = styled(Grid)({
  padding: '0.5rem',
  display: 'flex',
  alignItems: 'center',
});

const ShadedGridItem = styled(StyledGridItem)(({ theme }) => ({
  backgroundColor: theme.palette.componentBase.main,
}));

export interface CFSCleaningKPICardProps {
  system: GQLSystem;
}

export function CFSCleaningKPICard({ system }: CFSCleaningKPICardProps) {
  const intl = useIntl();
  const NA = intl.formatMessage({
    id: 'common-not-applicable',
    defaultMessage: 'N/A',
    description: "Label for when there isn't a value to display",
  });
  useGQLSystemCleanUpLiveKPI(system);
  return (
    <StyledCard>
      <Grid container height="100%">
        <ShadedGridItem item xs={4}>
          <AppTypography $bold>
            <FormattedMessage
              id="cfs-kpi-cleaning-type-label"
              defaultMessage="Cleaning Type"
              description="Label for cfs kpi cleaning type"
            />
          </AppTypography>
        </ShadedGridItem>
        <ShadedGridItem item xs={4}>
          <AppTypography $bold>
            <FormattedMessage
              id="cfs-kpi-time-since-last-clean-label"
              defaultMessage="Time Since Last Clean"
              description="Label for cfs kpi time since last clean"
            />
          </AppTypography>
        </ShadedGridItem>
        <ShadedGridItem item xs={4}>
          <AppTypography $bold>
            <FormattedMessage
              id="common-processed-volume"
              defaultMessage="Processed Volume"
              description="Label for processed amount"
            />
          </AppTypography>
        </ShadedGridItem>
        <StyledGridItem item xs={4}>
          <StyledStack direction="row">
            <CleaningTypeIndicator colorKey="highlight1" />
            <AppTypography $bold>
              <FormattedMessage
                id="cfs-kpi-sanitation-label"
                defaultMessage="Sanitation"
                description="Label for cfs kpi sanitation"
              />
            </AppTypography>
          </StyledStack>
        </StyledGridItem>
        <StyledGridItem item xs={4}>
          <AppTypography $bold>
            {getTimeSince(
              system.liveRegeneration?.cleanTypeSanitation?.lastClean
            ) ?? NA}
          </AppTypography>
        </StyledGridItem>
        <StyledGridItem item xs={4}>
          <AppTypography $bold $colorKey="highlight1">
            {system.liveRegeneration?.cleanTypeSanitation?.processedVolumeHl !=
            null
              ? `${intl.formatNumber(
                  system.liveRegeneration.cleanTypeSanitation.processedVolumeHl
                )} hL`
              : NA}
          </AppTypography>
        </StyledGridItem>
        <ShadedGridItem item xs={4}>
          <StyledStack direction="row">
            <CleaningTypeIndicator colorKey="highlight6" />
            <AppTypography $bold>
              <FormattedMessage
                id="common-caustic-label"
                description="Label for caustic"
                defaultMessage="Caustic"
              />
            </AppTypography>
          </StyledStack>
        </ShadedGridItem>
        <ShadedGridItem item xs={4}>
          <AppTypography $bold>
            {getTimeSince(
              system.liveRegeneration?.cleanTypeCaustic?.lastClean
            ) ?? NA}
          </AppTypography>
        </ShadedGridItem>
        <ShadedGridItem item xs={4}>
          <AppTypography $bold $colorKey="highlight6">
            {system.liveRegeneration?.cleanTypeCaustic?.processedVolumeHl !=
            null
              ? `${intl.formatNumber(
                  system.liveRegeneration.cleanTypeCaustic.processedVolumeHl
                )} hL`
              : NA}
          </AppTypography>
        </ShadedGridItem>
        <StyledGridItem item xs={4}>
          <StyledStack direction="row">
            <CleaningTypeIndicator colorKey="highlight2" />
            <AppTypography $bold>
              <FormattedMessage
                id="cfs-kpi-enzymatic-label"
                defaultMessage="Enzymatic"
                description="Label for cfs kpi enzymatic"
              />
            </AppTypography>
          </StyledStack>
        </StyledGridItem>
        <StyledGridItem item xs={4}>
          <AppTypography $bold>
            {getTimeSince(
              system.liveRegeneration?.cleanTypeEnzymatic?.lastClean
            ) ?? NA}
          </AppTypography>
        </StyledGridItem>
        <StyledGridItem item xs={4}>
          <AppTypography $bold $colorKey="highlight2">
            {system.liveRegeneration?.cleanTypeEnzymatic?.processedVolumeHl !=
            null
              ? `${intl.formatNumber(
                  system.liveRegeneration.cleanTypeEnzymatic.processedVolumeHl
                )} hL`
              : NA}
          </AppTypography>
        </StyledGridItem>
      </Grid>
    </StyledCard>
  );
}
