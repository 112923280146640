import { Paper, styled } from '@mui/material';
import { useResponsiveSizes } from '../utils';
import { AlertHistoryTableHeader } from './AlertHistoryTableHeader';
import { SuspenseAlertHistoryTableItems } from './SuspenseAlertHistoryTableItems';

const StyledPaper = styled(Paper)({
  margin: '1rem',
  overflow: 'auto',
});

export function AlertHistoryTable() {
  const { isMobileSize } = useResponsiveSizes();

  return isMobileSize ? (
    <SuspenseAlertHistoryTableItems />
  ) : (
    <StyledPaper>
      <AlertHistoryTableHeader />
      <SuspenseAlertHistoryTableItems />
    </StyledPaper>
  );
}
