import { useAreas, useGQLSystems } from '@beeriot/api-client';
import { Suspense } from 'react';

interface LoaderProps {
  siteId?: string;
  companyId?: string;
}

export function SiteLoader({ siteId, companyId }: LoaderProps) {
  return (
    <Suspense>
      <InnerLoader siteId={siteId} companyId={companyId} />
    </Suspense>
  );
}

function InnerLoader({ siteId, companyId }: LoaderProps) {
  useAreas(siteId);
  useGQLSystems(siteId, companyId, { suspense: false });
  return null;
}
