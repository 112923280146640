import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { IconLabel, LoadingPaper } from '../base';

const AlertHistoryTableItems = lazy(() =>
  import('./AlertHistoryTableItemsQuery').then(
    ({ AlertHistoryTableItemsQuery }) => ({
      default: AlertHistoryTableItemsQuery,
    })
  )
);

export function SuspenseAlertHistoryTableItems() {
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <IconLabel
                label={
                  <FormattedMessage
                    id="alert-history-table-error-label"
                    description="Error message for alert history table"
                    defaultMessage="Alert History Unavailable"
                  />
                }
              />
            );
          }}
        >
          <Suspense
            fallback={<LoadingPaper sx={{ height: '35rem', width: '100%' }} />}
          >
            <AlertHistoryTableItems />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
