import { useMemo } from 'react';
import { KPIGridItem } from '../../kpi-grid/KPIGrid';
import { KPIVerticalLabels } from '../../kpi-grid/KPIVerticalLabel';
import { FormattedMessage } from 'react-intl';
import { SystemKpiHookProps } from '../../SystemKpiHookProps';
import { useShouldRenderKpi } from '../../useShouldRenderKpi';
import { BrandSelectLabel } from '../../../../../brand-select/BrandSelectLabel';
import { GQLSystem } from '@beeriot/api-client';
import { PermissionKey, PermissionView } from '../../../../../../base';

// generic common hook between editable/non-editable versions
function _useBrandLabel(system: GQLSystem | undefined) {
  const brandOptions = useMemo(() => {
    // TODO: Get brand options from brands API
    const brandOptions = system?.liveStatus?.productionBrand
      ? [
          {
            value: system?.liveStatus?.productionBrand,
            label: system?.liveStatus?.productionBrand,
          },
        ]
      : [];

    return brandOptions;
  }, [system?.liveStatus?.productionBrand]);
  const brand = useMemo(() => {
    const brand = system?.liveStatus?.productionBrand
      ? brandOptions[0]
      : undefined;
    return brand;
  }, [brandOptions, system?.liveStatus?.productionBrand]);
  return { brand, brandOptions };
}

export function useBrandLabelGridItem(props: SystemKpiHookProps) {
  const { system, NA, boldLabelTitles, gridArea } = props;
  const shouldRender = useShouldRenderKpi(props);
  return useMemo(() => {
    if (!shouldRender) {
      return undefined;
    }

    return (
      <KPIGridItem gridArea={gridArea ?? 'brand'}>
        <KPIVerticalLabels
          label={system?.liveStatus?.productionBrand ?? NA}
          labelBold={boldLabelTitles}
          $flexWrap={'wrap'}
          $whiteSpace={'normal'}
          subLabel={
            <FormattedMessage
              id="site-line-detail-brand-label"
              defaultMessage="Brand"
              description="sub label for brand"
            />
          }
        />
      </KPIGridItem>
    );
  }, [
    shouldRender,
    gridArea,
    system?.liveStatus?.productionBrand,
    NA,
    boldLabelTitles,
  ]);
}

export function useEditBrandLabelGridItem(props: SystemKpiHookProps) {
  const { system, gridArea } = props;
  const shouldRender = useShouldRenderKpi(props);
  const { brand, brandOptions } = _useBrandLabel(system);
  let permissionKey: PermissionKey;

  switch (system?.type) {
    case 'profi':
      permissionKey = PermissionKey.SitesPROFiBrandEdit;
      break;
    case 'cbs':
      permissionKey = PermissionKey.SitesCBSBrandEdit;
      break;
    case 'cfs':
      permissionKey = PermissionKey.SitesCFSBrandEdit;
      break;
    case 'keraflux':
      permissionKey = PermissionKey.SitesKerafluxBrandEdit;
      break;
    default:
      permissionKey = PermissionKey.SitesPROFiBrandEdit;
      break;
  }

  return useMemo(() => {
    if (!shouldRender) {
      return undefined;
    }

    return (
      <PermissionView permissionKey={permissionKey}>
        <KPIGridItem gridArea={gridArea ?? 'editBrand'}>
          <BrandSelectLabel
            value={brand}
            options={brandOptions}
            $flexWrap={'wrap'}
            $whiteSpace={'normal'}
            subLabel={
              <FormattedMessage
                id="site-line-detail-brand-label"
                defaultMessage="Brand"
                description="sub label for brand"
              />
            }
          />
        </KPIGridItem>
      </PermissionView>
    );
  }, [shouldRender, gridArea, brand, brandOptions]);
}
