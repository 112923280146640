import { useMemo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UseMutationResult } from 'react-query';
import {
  CompanySite,
  CompanySiteBase,
  GQLCompanySite,
  toCompanySiteBase,
} from '@beeriot/api-client';
import { Box, Stack, styled } from '@mui/material';
import { AppTypography } from '../../base';
import { Form, FormFooterButtons } from '../../form';
import {
  SectionControl,
  SectionLabel,
  SectionTextInput,
} from '../../settings/settings-form';

const StyledFormFooterButtons = styled(FormFooterButtons)({
  padding: '1rem',
  width: '100%',
});

const WarningContainer = styled(Box)({
  marginTop: '1rem',
});

const mapDefaultValues = (
  site?: GQLCompanySite
): EditSiteFormFields | undefined => {
  if (site === undefined) return undefined;

  const defaultValues: EditSiteFormFields = {
    siteName: site.name ?? '',
    siteAddress: site.address?.state ?? '',
  };

  return defaultValues;
};

export interface EditSiteFormFields {
  siteName?: string;
  siteAddress?: string;
}

export interface EditSiteFormProps {
  site: GQLCompanySite;
  mutation: UseMutationResult<CompanySite, unknown, CompanySiteBase, unknown>;
  confirm: (site: CompanySiteBase) => void;
  cancel: () => void;
}

export const EditSiteForm = ({
  site,
  mutation,
  confirm,
  cancel,
}: EditSiteFormProps) => {
  const intl = useIntl();
  const defaultValues = useMemo(() => mapDefaultValues(site), [site]);

  const saveButtonLabel = intl.formatMessage({
    id: 'common-save-button',
    description: 'Save button',
    defaultMessage: 'Save',
  });

  const cancelLabel = intl.formatMessage({
    id: 'common-cancel-button',
    description: 'Label for cancel button',
    defaultMessage: 'Cancel',
  });

  const onSubmit = useCallback(
    (fields: EditSiteFormFields) => {
      const item = toCompanySiteBase(site);

      item.name = fields.siteName ?? '';
      if (item.address) {
        item.address.state = fields.siteAddress ?? '';
      }

      confirm(item);
    },
    [confirm, site]
  );

  return (
    <Form<EditSiteFormFields>
      onSubmit={onSubmit}
      formProps={{ defaultValues: defaultValues }}
    >
      <Stack>
        <SectionControl>
          <SectionLabel>
            <FormattedMessage
              id="edit-sites-site-name-label"
              defaultMessage="Site Name"
              description="label for site name"
            />
          </SectionLabel>
          <SectionTextInput
            name="siteName"
            required
            placeholder={intl.formatMessage({
              id: 'edit-sites-site-name-placeholder',
              defaultMessage: 'Enter Site Name',
              description: 'label for site name placeholder',
            })}
          />
        </SectionControl>
        <SectionControl>
          <SectionLabel>
            <FormattedMessage
              id="edit-sites-site-address-label"
              defaultMessage="Site Headquarters"
              description="label for site headquarters"
            />
          </SectionLabel>
          <SectionTextInput
            name="siteAddress"
            required
            placeholder={intl.formatMessage({
              id: 'edit-sites-site-address-placeholder',
              defaultMessage: 'Enter Site Headquarters',
              description: 'label for site headquarters placeholder',
            })}
          />
        </SectionControl>
        {mutation.isError && (
          <WarningContainer>
            <AppTypography $colorKey="error">
              <FormattedMessage
                id="common-error-message-label"
                defaultMessage="An error occured"
                description="label for standard error message"
              />
            </AppTypography>
          </WarningContainer>
        )}
        <StyledFormFooterButtons
          justify="center"
          requireCancelConfirmation={false}
          submitLabel={saveButtonLabel}
          isLoading={mutation.isLoading}
          onCancel={cancel}
        />
      </Stack>
    </Form>
  );
};
