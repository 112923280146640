import { styled, Typography, TypographyTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { ColorKey } from '@beeriot/api-client';
import { transientOptions } from '../utils/transientOptions';
import { Property } from 'csstype';

export interface AppTypographyProps {
  $colorKey?: ColorKey;
  $bold?: boolean;
  $whiteSpace?: Property.WhiteSpace;
  $wordWrap?: string;
  $flexWrap?: Property.FlexWrap;
  $lineHeight?: Property.LineHeight;
  $ellipsize?: boolean;
  $lineClamp?: Property.WebkitLineClamp;
  $maxWidth?: Property.MaxWidth;
}

export type OverridableComponentAppTypography = OverridableComponent<
  TypographyTypeMap<AppTypographyProps>
>;

export const AppTypography = styled(
  Typography,
  transientOptions
)<AppTypographyProps>(
  ({
    theme,
    $colorKey,
    $bold,
    $flexWrap,
    $whiteSpace,
    $wordWrap,
    $lineHeight,
    $ellipsize,
    $lineClamp,
    $maxWidth,
  }) => ({
    color: $colorKey ? theme.palette[$colorKey].main : 'inherit',
    fontWeight: $bold ? 'bold' : 'inherit',
    flexWrap: $flexWrap,
    whiteSpace: $whiteSpace,
    wordWrap: $wordWrap ? ($wordWrap as Property.WordWrap) : undefined,
    lineHeight: $lineHeight,
    overflow: $ellipsize ? 'hidden' : 'inherit',
    textOverflow: $ellipsize ? 'ellipsis' : 'inherit',
    maxWidth: $maxWidth,
    ...($lineClamp && {
      WebkitLineClamp: $lineClamp,
      WebkitBoxOrient: 'vertical',
      display: '-webkit-box',
    }),
  })
) as OverridableComponentAppTypography;
