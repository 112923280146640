import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { Stack, Button, Typography, styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { LoadingPaper } from '../base/LoadingPaper';

const MessageContainer = styled(Stack)({
  textAlign: 'center',
  width: '30rem',
  margin: '3rem',
  justifyContent: 'center',
});

const ErrorContainer = styled(Stack)({
  width: '100%',
  alignItems: 'center',
});

const AlertHeaderLoadingPaper = styled(LoadingPaper)({
  height: '7.5rem',
  width: 'calc(100% - 2rem)',
  margin: '1rem',
});

const AlertLoadingPaper = styled(LoadingPaper)({
  height: '4rem',
  width: 'calc(100% - 2rem)',
  margin: '1rem',
});

function AlertsLoadingPapers() {
  return (
    <Stack>
      <AlertHeaderLoadingPaper />
      <AlertLoadingPaper />
      <AlertLoadingPaper />
      <AlertLoadingPaper />
      <AlertLoadingPaper />
      <AlertLoadingPaper />
    </Stack>
  );
}

const AlertsDrawerContent = lazy(() =>
  import('./AlertsDrawerContentQuery').then(({ AlertsDrawerContentQuery }) => ({
    default: AlertsDrawerContentQuery,
  }))
);

export function SuspenseAlertsDrawerContent() {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ resetErrorBoundary }) => {
            return (
              <ErrorContainer>
                <MessageContainer>
                  <Typography>
                    <FormattedMessage
                      id="alerts-sidebar-loading-error-label"
                      description="Label for alerts loading error"
                      defaultMessage="There was an error loading alerts"
                    />
                  </Typography>
                  <Button onClick={() => resetErrorBoundary()}>
                    <FormattedMessage
                      id="common-try-again-button"
                      description="Button for trying an action again"
                      defaultMessage="Try Again"
                    />
                  </Button>
                </MessageContainer>
              </ErrorContainer>
            );
          }}
          onReset={reset}
        >
          <Suspense fallback={<AlertsLoadingPapers />}>
            <AlertsDrawerContent />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
