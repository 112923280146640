import { useMemo } from 'react';
import { KPIGridItem } from '../../kpi-grid/KPIGrid';
import { KPIVerticalLabels } from '../../kpi-grid/KPIVerticalLabel';
import { FormattedMessage } from 'react-intl';
import { SystemKpiHookProps } from '../../SystemKpiHookProps';
import { useShouldRenderKpi } from '../../useShouldRenderKpi';
import { formatTimeFromSeconds } from '../../../../../../utils/formatUnits';

export function useTimeRemainingLabelGridItem(props: SystemKpiHookProps) {
  const { system, NA, boldLabelTitles, gridArea } = props;
  const shouldRender = useShouldRenderKpi(props);

  return useMemo(() => {
    if (!shouldRender) {
      return undefined;
    }

    return (
      <KPIGridItem gridArea={gridArea ?? 'timeRemaining'}>
        <KPIVerticalLabels
          label={
            system?.liveStatus?.remainingTimeSeconds != null
              ? formatTimeFromSeconds(system.liveStatus.remainingTimeSeconds)
              : NA
          }
          labelBold={boldLabelTitles}
          labelColorKey="highlight3"
          subLabel={
            <FormattedMessage
              id="common-time-remaining-label"
              defaultMessage="Time Remaining"
              description="Label for time remaining"
            />
          }
        />
      </KPIGridItem>
    );
  }, [
    shouldRender,
    gridArea,
    system?.liveStatus?.remainingTimeSeconds,
    NA,
    boldLabelTitles,
  ]);
}
