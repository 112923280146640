import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useObservable } from '@ngneat/use-observable';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  styled,
} from '@mui/material';
import {
  activeCompany$,
  useCreateGroup,
  GroupBase,
  GroupStatusEnum,
  useUpdateSite,
  GQLCompanySite,
  toCompanySiteBase,
} from '@beeriot/api-client';
import { LoadingButton } from '../../../../base/LoadingButton';
import {
  AddEditGroupNameForm,
  AddEditGroupNameFormFields,
} from '../../AddEditGroupNameForm';
import { SuspenseAddOrEditSiteList } from '../../group-site-list/SuspenseAddOrEditSiteList';
import { AddGroupDialogHeader } from './AddGroupDialogHeader';

const StyledDialog = styled(Dialog)({
  '& .MuiDialogContent-root': {
    padding: '0rem 0rem 1.5rem 0rem',
    minWidth: 'fit-content',
  },
});

export interface AddGroupDialogProps {
  open: boolean;
  parentGroupId: string;
  handleClose: () => void;
}

export function AddGroupDialog({
  open,
  parentGroupId,
  handleClose,
}: AddGroupDialogProps) {
  const [activeCompany] = useObservable(activeCompany$);
  const [selectedSites, setSelectedSites] = useState<GQLCompanySite[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [groupName, setGroupName] = useState<string>('');

  const createGroup = useCreateGroup();
  const updateSite = useUpdateSite(activeCompany?.id ?? '');
  const intl = useIntl();

  const handleDialogClose = useCallback(() => {
    setGroupName('');
    setCurrentStep(0);
    handleClose();
  }, [handleClose]);

  const handleSaveGroup = useCallback(
    (data: AddEditGroupNameFormFields) => {
      setGroupName(data.name);
      setCurrentStep(1);
    },
    [setCurrentStep, setGroupName]
  );

  const handleSubmit = useCallback(async () => {
    const group: GroupBase = {
      name: groupName,
      companyId: activeCompany?.id ?? '',
      parentGroupId: parentGroupId === '' ? undefined : parentGroupId,
      status: GroupStatusEnum.Active,
    };

    const newGroup = await createGroup.mutateAsync(group);
    for (const site of selectedSites) {
      if (newGroup.id && site?.groups) {
        site?.groups.push(newGroup.id);
        updateSite.mutate({ ...toCompanySiteBase(site), id: site.id });
      }
    }

    setGroupName('');
    setCurrentStep(0);
    handleClose();
  }, [
    groupName,
    parentGroupId,
    activeCompany,
    selectedSites,
    createGroup,
    updateSite,
    handleClose,
  ]);

  const backLabel = intl.formatMessage({
    id: 'organize-sites-add-new-group-back-button',
    description: 'Button to go back to name input',
    defaultMessage: 'Back',
  });

  const submitLabel = intl.formatMessage({
    id: 'organize-sites-add-new-group-save-button',
    description: 'Button to create the new group',
    defaultMessage: 'Save Group',
  });

  return (
    <StyledDialog open={open}>
      <AddGroupDialogHeader currentStep={currentStep} />
      <DialogContent>
        {currentStep === 0 ? (
          <AddEditGroupNameForm
            mutation={null}
            handleSubmit={handleSaveGroup}
            handleClose={handleDialogClose}
            isChildGroup={true}
            editMode={false}
            defaultValue={groupName === '' ? undefined : groupName}
          />
        ) : (
          <SuspenseAddOrEditSiteList
            selectedSites={selectedSites}
            setSelectedSites={setSelectedSites}
            editMode={false}
          />
        )}
      </DialogContent>
      {currentStep === 1 ? (
        <DialogActions>
          <Button
            variant="contained"
            color="shade"
            onClick={() => setCurrentStep(0)}
          >
            {backLabel}
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            onClick={handleSubmit}
            isLoading={createGroup.isLoading}
          >
            {submitLabel}
          </LoadingButton>
        </DialogActions>
      ) : null}
    </StyledDialog>
  );
}
