import React from 'react';
import { RegisterOptions } from 'react-hook-form';
import { InputBaseProps } from '@mui/material';
import ReactTagInput, { ReactTagInputProps } from '@pathofdev/react-tag-input';
import '@pathofdev/react-tag-input/build/index.css';
import './react-tag-input.css';
import { useValidationRules } from './useValidationRules';

const ENTER_Event = new KeyboardEvent('keydown', {
  altKey: false,
  bubbles: true,
  cancelable: true,
  charCode: 0,
  code: 'Enter',
  composed: true,
  ctrlKey: false,
  detail: 0,
  isComposing: false,
  key: 'Enter',
  keyCode: 13,
  location: 0,
  metaKey: false,
  repeat: false,
  shiftKey: false,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleRefOnBlurEvent = (ref: any) => {
  if (!ref) return;
  ref.inputRef.current.onblur = () =>
    ref.inputRef.current.dispatchEvent(ENTER_Event);
};

declare type Tags = string[];

export interface FormTagInputProps
  extends Omit<InputBaseProps, 'onChange'>,
    Omit<ReactTagInputProps, 'tags' | 'onChange'> {
  name: string;
  required: boolean;
  validationOptions?:
    | Partial<RegisterOptions>
    | ((values: Record<string, unknown>) => Partial<RegisterOptions>);

  // optional props from ReactTagInputProps
  tags?: Tags;
  onChange?: (tags: Tags) => void;
  tagItemValidator?: (val: string) => boolean;
}

export const FormTagInput: React.FC<FormTagInputProps> = ({
  name,
  required,
  validationOptions,
  tags,
  onChange,
  tagItemValidator,
  ...props
}) => {
  const { rules, register, getValues, setValue } = useValidationRules(
    required,
    validationOptions
  );
  const field = register(name, rules);
  const formValues = getValues();
  const tagValues = formValues[name];
  const tagsCurrent = tagValues ? (tagValues as string[]) : [];

  return (
    <ReactTagInput
      {...field}
      tags={tagsCurrent}
      editable
      readOnly={false}
      removeOnBackspace
      onChange={(newTags) => {
        setValue(name, newTags);
        if (onChange) onChange(newTags);
      }}
      validator={tagItemValidator}
      placeholder={props.placeholder}
      ref={handleRefOnBlurEvent}
    />
  );
};
