import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button, Grid, styled } from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import {
  execSummaryStore,
  execSummaryFilterLines$,
  execSummaryFilterBrands$,
  FilterLine,
  FilterBrand,
  activeSystem$,
} from '@beeriot/api-client';
import { AppIcon, ControlBox } from '../../../base';
import { FilterPill } from '../../../analytics';

function isFilterLine(filter: FilterLine | FilterBrand): filter is FilterLine {
  return (filter as FilterLine).system !== undefined;
}

const StyledControlBox = styled(ControlBox)({
  gap: '0.5rem',
  zIndex: 1,
});

export function ExecSummaryFiltersPillBox() {
  const intl = useIntl();
  const [activeSystem] = useObservable(activeSystem$);
  const [filterLines] = useObservable(execSummaryFilterLines$);
  const [filterBrands] = useObservable(execSummaryFilterBrands$);
  const displayLines = filterLines && filterLines.length > 0;
  const displayBrands = !displayLines && filterBrands.length > 0;
  const filters = useMemo(() => {
    if (displayLines) return filterLines;
    if (displayBrands) return filterBrands;
    return undefined;
  }, [displayBrands, displayLines, filterBrands, filterLines]);

  const line = intl.formatMessage({
    id: 'common-line-label',
    description: 'Label for line',
    defaultMessage: 'Line',
  });
  const brand = intl.formatMessage({
    id: 'common-brand-label',
    description: 'Label for brand',
    defaultMessage: 'Brand',
  });
  const addLine = intl.formatMessage({
    id: 'exec-summary-comparison-add-line-button',
    description: 'Add Line Button',
    defaultMessage: 'Add Line',
  });
  const addBrand = intl.formatMessage({
    id: 'exec-summary-comparison-add-brand-button',
    description: 'Add Brand Button',
    defaultMessage: 'Add Brand',
  });

  const deletePill = useCallback(
    (id: string) => {
      if (displayLines) {
        execSummaryStore.update((state) => ({
          ...state,
          filterLines: filterLines.filter((x) => x.system.id !== id),
        }));
      } else if (displayBrands) {
        execSummaryStore.update((state) => ({
          ...state,
          filterBrands: filterBrands.filter((x) => x.brand.id !== id),
        }));
      }
    },
    [displayLines, displayBrands, filterLines, filterBrands]
  );

  function toPillModel(filter: FilterLine | FilterBrand) {
    const filterIsLine = isFilterLine(filter);
    const name = filterIsLine ? filter.system.name : filter.brand.name;
    return {
      id: filterIsLine ? filter.system.id : filter.brand.id,
      colorKey: filter.colorKey,
      category: filterIsLine ? line : brand,
      value: name ?? '-',
    };
  }

  function openDialog() {
    execSummaryStore.update((state) => ({
      ...state,
      filterBrandsDialogOpen: displayBrands ? true : false,
      filterLinesDialogOpen: displayLines ? true : false,
    }));
  }

  return filters ? (
    <StyledControlBox>
      <Grid container spacing={2}>
        {filters.map((x) => {
          const pillModel = toPillModel(x);
          return (
            <Grid item key={pillModel.id}>
              <FilterPill
                colorKey={pillModel.colorKey}
                filterCategory={pillModel.category}
                filterValue={pillModel.value}
                onDelete={
                  pillModel.id === activeSystem?.id
                    ? undefined
                    : () => deletePill(pillModel.id)
                }
              />
            </Grid>
          );
        })}
        <Grid item>
          <Button
            variant="contained"
            color="highlight3"
            startIcon={<AppIcon icon="plus" />}
            onClick={openDialog}
          >
            {displayLines ? addLine : addBrand}
          </Button>
        </Grid>
      </Grid>
    </StyledControlBox>
  ) : null;
}
