import { FormattedMessage } from 'react-intl';
import { AppTableHeader } from '../base';

export function AlertHistoryTableHeader() {
  return (
    <AppTableHeader
      label={
        <FormattedMessage
          id="alert-history-table-header-title"
          defaultMessage="Alert History"
          description="header for alert history table"
        />
      }
    />
  );
}
