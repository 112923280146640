import {
  filteredSitesCount$,
  unfilteredSitesCount$,
} from '@beeriot/api-client';
import { Button, styled } from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { VerticalLabels } from '../../base';
import { transientOptions, useResponsiveSizes } from '../../utils';
import { SitesFilterFormDialog } from './SitesFilterFormDialog';

const StyledButton = styled(
  Button,
  transientOptions
)<{ $isMobile: boolean }>(({ $isMobile }) => ({
  flex: $isMobile ? '1' : '0 1 auto',
}));

export function SitesFiltersFormButton() {
  const [unfilteredSitesCount] = useObservable(unfilteredSitesCount$);
  const [filteredSitesCount] = useObservable(filteredSitesCount$);
  const { isMobileSize, isSmallTabletSize } = useResponsiveSizes();
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const adjustFilters = intl.formatMessage({
    id: 'common-adjust-filters-button',
    description: 'Button for adjusting filters',
    defaultMessage: 'Adjust Filters',
  });
  const filters = intl.formatMessage({
    id: 'common-filters-button',
    description: 'Button for adjusting filters, shorthand',
    defaultMessage: 'Filters',
  });
  const xOfYShown = intl.formatMessage(
    {
      id: 'common-x-of-y-shown-label',
      description: 'Label for displaying how many elements are being shown',
      defaultMessage: '{x} of {y} shown',
    },
    { x: filteredSitesCount, y: unfilteredSitesCount }
  );

  return (
    <>
      <StyledButton
        $isMobile={isMobileSize}
        variant="contained"
        color="highlight2"
        onClick={() => setOpen(true)}
      >
        <VerticalLabels
          labelBold
          $lineHeight="1.15"
          labelVariant="body1"
          labelColorKey="white"
          label={isSmallTabletSize || isMobileSize ? filters : adjustFilters}
          subLabelColorKey="base"
          subLabelVariant="caption"
          subLabel={
            filteredSitesCount != null && unfilteredSitesCount != null
              ? xOfYShown
              : null
          }
        />
      </StyledButton>
      <SitesFilterFormDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
}
