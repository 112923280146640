import { createStore, select, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { startOfMonth } from 'date-fns';
import { GQLAlertActive, GQLAlertHistory } from '../generated/gql';
import { CalendarMode } from '../enums';

export const alertTypes = [
  'machineAlarms',
  'progressAlerts',
  'maintenanceWarnings',
  'systemWarnings',
] as const;
export type AlertType = typeof alertTypes[number];
export function isAlertType(value?: string | null): value is AlertType {
  return !!value && alertTypes.includes(value as AlertType);
}

export interface AlertState {
  selectedAlert: GQLAlertActive | GQLAlertHistory | null;
  startDate: string;
  filterByType: string;
  filterValue: string;
  siteIdFilter: string | undefined;
  systemIdFilter: string | undefined;
  alertTypeFilter: AlertType | undefined;
  calanderMode: CalendarMode;
  lastUpdate: string | undefined;
  alertMappingUploadProgress: number;
}

export const alertStore = createStore(
  { name: 'alert-history' },
  withProps<AlertState>({
    selectedAlert: null,
    startDate: startOfMonth(new Date()).toISOString(),
    filterByType: '',
    filterValue: '',
    siteIdFilter: undefined,
    systemIdFilter: undefined,
    alertTypeFilter: undefined,
    calanderMode: CalendarMode.Month,
    lastUpdate: undefined,
    alertMappingUploadProgress: 0,
  })
);

persistState(alertStore, {
  key: 'alert-history',
  storage: sessionStorageStrategy,
});

export const selectedAlert$ = alertStore.pipe(
  select((state) => {
    return state.selectedAlert;
  })
);

export const alertHistoryStartDate$ = alertStore.pipe(
  select((state) => {
    return state.startDate;
  })
);

export const alertHistoryCalendarMode$ = alertStore.pipe(
  select((state) => {
    return state.calanderMode;
  })
);

export const alertSiteIdFilter$ = alertStore.pipe(
  select((state) => {
    return state.siteIdFilter;
  })
);

export const alertSystemIdFilter$ = alertStore.pipe(
  select((state) => {
    return state.systemIdFilter;
  })
);

export const alertTypeFilter$ = alertStore.pipe(
  select((state) => {
    return state.alertTypeFilter;
  })
);

export const alertsLastUpdate$ = alertStore.pipe(
  select((state) => {
    return state.lastUpdate;
  })
);

export const alertMappingUploadProgress$ = alertStore.pipe(
  select((state) => {
    return state.alertMappingUploadProgress;
  })
);
