import { ReactNode } from 'react';
import { Stack, styled } from '@mui/material';

const RootAuthPageBackground = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
  background: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
}));

const AuthPageContent = styled(Stack)({
  width: '18rem',
});

interface RootAuthPageStackProps {
  children: ReactNode;
}

export function RootAuthPageStack({ children }: RootAuthPageStackProps) {
  return (
    <RootAuthPageBackground>
      <AuthPageContent>{children}</AuthPageContent>
    </RootAuthPageBackground>
  );
}
