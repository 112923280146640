import { createStore, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { GQLBlock } from '../../generated/gql';

export interface BlockProps {
  activeBlock: GQLBlock | null;
}

export const blockStore = createStore(
  { name: 'block' },
  withProps<BlockProps>({
    activeBlock: null,
  })
);

persistState(blockStore, {
  key: 'block',
  storage: sessionStorageStrategy,
});
