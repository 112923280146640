import { PermissionKey } from '../../base';

export interface RouteListItem {
  key: PermissionKey;
  route: React.ReactNode;
}

export function getFilteredRoutes(
  hasPermission: (permissionKey: PermissionKey) => boolean,
  routeList: RouteListItem[]
): React.ReactNode[] {
  return routeList
    .filter(({ key }) => hasPermission(key))
    .map(({ route }) => route);
}
