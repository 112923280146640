import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { Stack, Button, Typography, styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { LoadingPaper } from '../../base';

const GroupFilters = lazy(() =>
  import('./GroupFiltersQuery').then(({ GroupFiltersQuery }) => ({
    default: GroupFiltersQuery,
  }))
);

const FilterLoadingPaper = styled(LoadingPaper)(({ theme }) => ({
  height: '3rem',
  width: '20rem',
}));

const ErrorContainer = styled(Stack)({
  width: '20rem',
  alignItems: 'center',
});

const MessageContainer = styled(Stack)({
  alignItems: 'center',
  whiteSpace: 'nowrap',
});

export function SuspenseGroupFilters() {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ resetErrorBoundary }) => {
            return (
              <ErrorContainer>
                <MessageContainer direction="row">
                  <Typography>
                    <FormattedMessage
                      id="groups-error-loading-label"
                      description="Label for groups loading error"
                      defaultMessage="There was an error loading groups"
                    />
                  </Typography>
                  <Button onClick={() => resetErrorBoundary()}>
                    <FormattedMessage
                      id="common-try-again-button"
                      description="Button for trying an action again"
                      defaultMessage="Try Again"
                    />
                  </Button>
                </MessageContainer>
              </ErrorContainer>
            );
          }}
          onReset={reset}
        >
          <Suspense fallback={<FilterLoadingPaper />}>
            <GroupFilters />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
