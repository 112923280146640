import { FormattedMessage } from 'react-intl';
import { Stack, styled } from '@mui/material';
import { useResponsiveSizes } from '../../utils/responsive-design/useResponsiveSizes';
import { AppTableHeader, AppTypography, RangedAppDatePicker } from '../../base';
import { useActiveAppEntities } from '@beeriot/api-client';

export const FilterRunsTableHeaderControlsContainer = styled(Stack)({
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'right',
  margin: '0 1rem',
});

export const StyledDivAlign = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingRight: '0.5rem',
});

export interface FilterRunsTableHeaderProps {
  startDate: Date | null;
  endDate: Date | null;
  onStartDateChange: (date: Date | null) => void;
  onEndDateChange: (date: Date | null) => void;
}

export function FilterRunsTableHeader({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
}: FilterRunsTableHeaderProps) {
  const { activeSystem } = useActiveAppEntities();
  const { isMobileSize } = useResponsiveSizes();
  return (
    <AppTableHeader
      label={
        activeSystem?.type === 'keraflux' ? (
          <FormattedMessage
            id="batch-info-table-header-title"
            defaultMessage="Batch Info"
            description="Header for batch info table"
          />
        ) : (
          <FormattedMessage
            id="filter-run-table-header-title"
            defaultMessage="Filter Runs"
            description="header for filter run table"
          />
        )
      }
    >
      {isMobileSize ? null : (
        <FilterRunsTableHeaderControlsContainer>
          <StyledDivAlign>
            <AppTypography variant="body1" $colorKey="subtitleText">
              <FormattedMessage
                id="filter-run-table-header-date-descripttion"
                defaultMessage="Runs between"
                description="description for filter run table date range"
              />
            </AppTypography>
          </StyledDivAlign>
          <RangedAppDatePicker
            startDate={startDate}
            endDate={endDate}
            onStartChange={onStartDateChange}
            onEndChange={onEndDateChange}
          />
        </FilterRunsTableHeaderControlsContainer>
      )}
    </AppTableHeader>
  );
}
