import { FormattedMessage } from 'react-intl';
import { Box, styled, Chip } from '@mui/material';
import { AppTypography } from '../../base/AppTypography';

const StyledGroupHeader = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const StyledTitle = styled(AppTypography)({
  marginRight: '1rem',
});

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.shade.main,
  color: theme.palette.white.main,
}));

interface CompanyCardListSectionHeaderTextProps {
  companyCount: number;
}

function CompanyCardListSectionHeaderText({
  companyCount,
}: CompanyCardListSectionHeaderTextProps) {
  return companyCount === 1 ? (
    <FormattedMessage
      id="companies-card-list-section-header-singular"
      description="Section header for companies list in singular"
      defaultMessage="{companyCount} Company"
      values={{ companyCount: companyCount }}
    />
  ) : (
    <FormattedMessage
      id="companies-card-list-section-header-plural"
      description="Section header for companies list in plural"
      defaultMessage="{companyCount} Companies"
      values={{ companyCount: companyCount }}
    />
  );
}

interface CompanyCardListSectionHeaderProps {
  companyCount: number;
  companyLetter: string;
}

export function CompanyCardListSectionHeader({
  companyCount,
  companyLetter,
}: CompanyCardListSectionHeaderProps) {
  return (
    <StyledGroupHeader>
      <StyledTitle variant="h6" $bold>
        {companyLetter}
      </StyledTitle>
      <StyledChip
        label={<CompanyCardListSectionHeaderText companyCount={companyCount} />}
        size="small"
      />
    </StyledGroupHeader>
  );
}
