import { Alert, Link, Stack, styled, useTheme } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { AppTypography } from '../base/AppTypography';
import { Form, FormError, FormLabeledCheckbox } from '../form';
import { LoginTextInput } from './LoginTextInput';
import { PallLogo } from './PallLogo';
import { AuthSubmitButton } from './AuthSubmitButton';
import { RouterLink } from '../navigation';
import { FormErrorContainer } from './FormErrorContainer';
import { useCallback, useState } from 'react';
import { UseMutationResult } from 'react-query';
import { privacyPolicyURL, termsOfServiceURL } from '../utils';

const StyledFormLabeledCheckbox = styled(FormLabeledCheckbox)(({ theme }) => ({
  color: theme.palette.white.main,
  '&.Mui-checked': {
    color: theme.palette.white.main,
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  padding: '0',
  color: theme.palette.white.main,
  fontWeight: 'inherit',
  textDecoration: 'underline',
  textDecorationColor: theme.palette.white.main,
}));

const FormLabel = styled(AppTypography)({
  marginTop: '1rem',
  marginBottom: '1rem',
});

const ForgotPasswordContainer = styled(Stack)({
  alignItems: 'end',
});

interface LoginFormProps {
  mutation?: UseMutationResult<void, unknown, LoginFormFields, unknown>;
  error?: React.ReactNode;
}

export interface LoginFormFields {
  email: string;
  password: string;
}

export function LoginForm({ mutation, error }: LoginFormProps) {
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);
  const [termsOfServiceChecked, setTermsOfServiceChecked] = useState(false);

  const theme = useTheme();
  const intl = useIntl();
  const emailPlaceholder = intl.formatMessage({
    id: 'login-email-placeholder',
    description: 'Placeholder for email input',
    defaultMessage: 'Enter email address',
  });
  const passwordPlaceholder = intl.formatMessage({
    id: 'login-password-placeholder',
    description: 'Placeholder for password input',
    defaultMessage: 'Enter password',
  });

  const handlePolicyCheckedUpdate = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPrivacyPolicyChecked(event.target.checked);
  };

  const handleTermsCheckedUpdate = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTermsOfServiceChecked(event.target.checked);
  };

  const handleLogin = useCallback(
    (data: LoginFormFields) => {
      mutation?.mutate(data);
    },
    [mutation]
  );

  return (
    <Form<LoginFormFields> onSubmit={handleLogin}>
      <PallLogo />
      <AppTypography variant="h5" gutterBottom $colorKey="white">
        Beer
        <AppTypography component="span" variant="h5" $bold $colorKey="white">
          IoT
        </AppTypography>{' '}
        Systems
      </AppTypography>
      <FormLabel $colorKey="white">
        <FormattedMessage
          id="login-form-title-label"
          defaultMessage="Please enter your email & password."
          description="Label for login form"
        />
      </FormLabel>
      <LoginTextInput
        required
        name="email"
        placeholder={emailPlaceholder}
        position="top"
      />
      <LoginTextInput
        required
        name="password"
        placeholder={passwordPlaceholder}
        type="password"
        position="bottom"
      />
      <ForgotPasswordContainer>
        <RouterLink to="/forgot-password">
          <AppTypography $colorKey="white" variant="subtitle2">
            <FormattedMessage
              id="login-forgot-password-button"
              defaultMessage="Forgot password?"
              description="Forgot password button"
            />
          </AppTypography>
        </RouterLink>
      </ForgotPasswordContainer>
      <FormErrorContainer>
        <FormError error={error} color="info" />
      </FormErrorContainer>
      <Stack>
        <StyledFormLabeledCheckbox
          id="privacy-policy-checkbox"
          name="privacy-policy-checkbox"
          onChange={handlePolicyCheckedUpdate}
          required={true}
          preventBoldOnSelect={true}
          $labelVariant="subtitle2"
          $labelColorKey="white"
          $checkedColorKey="white"
          $uncheckedColorKey="white"
          label={
            <FormattedMessage
              id="login-privacy-notice-label"
              defaultMessage="I agree to the {privacyPolicy}"
              description="Label for privacy policy notice"
              values={{
                privacyPolicy: (
                  <StyledLink href={privacyPolicyURL} target="_blank">
                    <FormattedMessage
                      id="login-privacy-policy-button"
                      defaultMessage="Privacy Policy"
                      description="Privacy policy button"
                    />
                  </StyledLink>
                ),
              }}
            />
          }
        />
        <StyledFormLabeledCheckbox
          id="terms-of-service-checkbox"
          name="terms-of-service-checkbox"
          onChange={handleTermsCheckedUpdate}
          required={true}
          preventBoldOnSelect={true}
          $labelVariant="subtitle2"
          $labelColorKey="white"
          $checkedColorKey="white"
          $uncheckedColorKey="white"
          label={
            <FormattedMessage
              id="login-tos-notice-label"
              defaultMessage="I agree to the {termsOfService}"
              description="Label for terms of service notice"
              values={{
                termsOfService: (
                  <StyledLink href={termsOfServiceURL} target="_blank">
                    <FormattedMessage
                      id="login-tos-button"
                      defaultMessage="Terms of Service"
                      description="Terms of service button"
                    />
                  </StyledLink>
                ),
              }}
            />
          }
        />
      </Stack>
      <Alert
        sx={{
          margin: '0.5rem 0',
          borderColor: theme.palette.white.main,
          '.MuiAlert-icon': { color: theme.palette.white.main },
        }}
        severity="warning"
        variant="outlined"
      >
        <AppTypography variant="subtitle2" $colorKey="white">
          <FormattedMessage
            id="login-form-authorized-only-warning-label"
            defaultMessage="This application must only be accessed by an authorized user"
            description="Label for warning that application is only for authorized users"
          />
        </AppTypography>
      </Alert>
      <AuthSubmitButton
        type="submit"
        disabled={!privacyPolicyChecked || !termsOfServiceChecked}
        isLoading={mutation?.isLoading}
        loadingLabel={
          <FormattedMessage
            id="login-button-authenticating"
            defaultMessage="Authenticating"
            description="Login button authenticating"
          />
        }
      >
        <FormattedMessage
          id="login-button"
          defaultMessage="Login"
          description="Login button"
        />
      </AuthSubmitButton>
    </Form>
  );
}
