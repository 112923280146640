import { useMutation, useQueryClient } from 'react-query';
import { Brand, BrandApi, BrandBase } from '../generated/api';
import {
  GQLBeerBrand,
  GQLListBeerBrandsQuery,
  useInfiniteListBeerBrandsQuery,
} from '../generated/gql';
import {
  defaultPagingParams,
  getNextPageParamHandler,
  insertToInfiniteData,
  optimisticUpdateInfiniteData,
  optimisticDeleteInfiniteData,
  removeFalsey,
} from '../utils';
import { appConfiguration } from './configuration';

interface BeerBrandQueryVariables {
  skip: number;
  limit: number;
  companyId: string | null | undefined;
  status: string;
}

export const beerBrandsApi = new BrandApi(appConfiguration);
const queryKeyPrefix = 'listBeerBrands.infinite';
const queryVariables = (companyId?: string | null): BeerBrandQueryVariables => {
  return {
    companyId,
    status: 'active',
    ...defaultPagingParams,
  };
};
const queryKey = (companyId?: string | null) => [
  queryKeyPrefix,
  queryVariables(companyId),
];

export function useBeerBrands(companyId?: string | null): GQLBeerBrand[] {
  const {
    data: beerBrands,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteListBeerBrandsQuery(queryVariables(companyId), {
    enabled: !!companyId,
    refetchInterval: 15000,
    getNextPageParam: getNextPageParamHandler(
      (query) => query?.listBeerBrands?.length
    ),
  });

  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage();
  }

  // This filters out undefined and null companies and allows for neat typing
  const gqlBeerBrands = removeFalsey<GQLBeerBrand>(
    beerBrands?.pages.flatMap((x) => x.listBeerBrands)
  );

  return gqlBeerBrands.sort((a, b) =>
    (a.name ?? '').localeCompare(b.name ?? '')
  );
}

function toBrand(gqlBeerBrand: GQLBeerBrand): BrandBase {
  return {
    name: gqlBeerBrand.name ?? '',
    companyId: gqlBeerBrand.companyId ?? '',
    companySiteId: gqlBeerBrand.companySiteId ?? '',
    rawMaterial: gqlBeerBrand.rawMaterial ?? '',
    plato: gqlBeerBrand.plato ?? 0,
    abv: gqlBeerBrand.abv ?? 0,
    fermented: gqlBeerBrand.fermented ?? '',
    beerType: gqlBeerBrand.beerType ?? '',
    sortOrder: gqlBeerBrand.sortOrder ?? 0,
    status: 'active',
  };
}

async function updateBeerBrand(gqlBrand: GQLBeerBrand) {
  if (!gqlBrand.id) return null;
  const res = await beerBrandsApi.updateBrand(gqlBrand.id, toBrand(gqlBrand));
  return res.data;
}

async function addBeerBrand(gqlBrand: GQLBeerBrand) {
  if (!gqlBrand.companyId) return null;
  const res = await beerBrandsApi.createBrand(toBrand(gqlBrand));
  return res.data;
}

async function softDeleteBeerBrand(gqlBrand: GQLBeerBrand) {
  if (!gqlBrand.id) return null;
  const res = await beerBrandsApi.updateBrand(gqlBrand.id, {
    ...toBrand(gqlBrand),
    status: 'inactive',
  });
  return res.data;
}

export const useAddBeerBrand = (
  onSuccess: (brand: Brand | null) => void,
  onError: (err: string) => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (brand: GQLBeerBrand) => {
      const response = await addBeerBrand(brand);
      return response;
    },
    {
      onError: (err) => {
        onError(err as string);
      },
      onSuccess: async (brand, gqlBrand) => {
        await insertToInfiniteData<GQLListBeerBrandsQuery, GQLBeerBrand>(
          queryClient,
          queryKey(brand?.companyId),
          'listBeerBrands',
          { ...gqlBrand, id: brand?.id ?? '' }
        );
        onSuccess(brand);
      },
    }
  );
};

export const useUpdateBeerBrand = (
  onSuccess: (brand: Brand | null) => void,
  onError: (err: string) => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (brand: GQLBeerBrand) => {
      const response = await updateBeerBrand(brand);
      return response;
    },
    {
      onMutate: async (brand: GQLBeerBrand) => {
        return await optimisticUpdateInfiniteData<
          GQLListBeerBrandsQuery,
          GQLBeerBrand
        >(queryClient, queryKey(brand.companyId), 'listBeerBrands', brand);
      },
      onError: (err, brand, context) => {
        if (context?.previousState) {
          queryClient.setQueryData(
            queryKey(brand.companyId),
            context.previousState
          );
        }
        onError(err as string);
      },
      onSuccess: (brand) => {
        onSuccess(brand);
      },
    }
  );
};

export const useDeleteBeerBrand = (
  onSuccess: () => void,
  onError: (err: string) => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (brand: GQLBeerBrand) => {
      const response = await softDeleteBeerBrand(brand);
      return response;
    },
    {
      onMutate: async (brand: GQLBeerBrand) => {
        return await optimisticDeleteInfiniteData<
          GQLListBeerBrandsQuery,
          GQLBeerBrand
        >(queryClient, queryKey(brand.companyId), 'listBeerBrands', brand);
      },
      onError: (err, brand, context) => {
        if (context?.previousState) {
          queryClient.setQueryData(
            queryKey(brand.companyId),
            context.previousState
          );
        }
        onError(err as string);
      },
      onSuccess: () => {
        onSuccess();
      },
    }
  );
};
