import { useRef, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as d3 from 'd3';
import { Arc, PieArcDatum } from 'd3';
import { Box, styled, useTheme } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { ColorKey } from '@beeriot/api-client';
import { VerticalLabels } from './VerticalLabels';

const BoxContainer = styled(Box)({
  display: 'grid',
  gridTemplate: 'container',
  alignItems: 'end',
  justifyItems: 'center',
  placeContent: 'center',
  overflow: 'hidden',
});

const CenterSvg = styled('svg')({
  gridArea: 'container',
  maxWidth: '100%',
});

const CenterBox = styled(Box)`
  grid-area: container;
  max-width: 100%;
  & > * {
    text-align: center;
    justify-content: center !important;
  }
`;

const StyledLabels = styled(VerticalLabels)`
  padding-bottom: 0.2em;
`;

// TODO: mock object
export interface Flow {
  amount?: number | null;
  unit: string;
  max: number;
}

export interface GaugeLimits {
  safe: number;
  warning: number;
  error: number;
}

export const enum GaugeSection {
  danger = 'danger',
  warning = 'warning',
  safe = 'safe',
  active = 'active',
}

export function getResponsiveDataForGauge(
  isMobileSize: boolean,
  isTabletSize: boolean
) {
  let flowGaugeRadius;
  if (isMobileSize) {
    flowGaugeRadius = 35;
  } else if (isTabletSize) {
    flowGaugeRadius = 45;
  } else {
    flowGaugeRadius = 55;
  }

  let flowGaugeLabelVariant: Variant;
  if (isMobileSize) {
    flowGaugeLabelVariant = 'body2';
  } else if (isTabletSize) {
    flowGaugeLabelVariant = 'body1';
  } else {
    flowGaugeLabelVariant = 'h5';
  }

  return {
    radius: flowGaugeRadius,
    labelVariant: flowGaugeLabelVariant,
  };
}

const defaultSafeThreshold = 65;
const defaultWarningThreshold = 25;
const defaultDangerThreshold = 10;

export interface RadialGaugeProps {
  flow?: Flow;
  radius?: number;
  gaugeMap?: Map<number, GaugeSection>;
  warningLimit?: number;
  successLimit?: number;
  labelVariant?: Variant;
  subLabelVariant?: Variant;
  backgroundColor?: string;
  gaugeOverrideColorKey?: ColorKey;
  gaugeBackgroundOverrideColorKey?: ColorKey;
  displayLabelAsDash?: boolean;
}

export function RadialGauge({
  flow = {
    amount: 0,
    unit: '',
    max: 0,
  },
  radius = 55,
  gaugeMap = new Map([
    [defaultDangerThreshold, GaugeSection.danger],
    [defaultWarningThreshold, GaugeSection.warning],
    [defaultSafeThreshold, GaugeSection.safe],
  ]),
  warningLimit = 40,
  successLimit = 64,
  labelVariant = 'h5',
  subLabelVariant = 'subtitle2',
  backgroundColor = 'white',
  gaugeOverrideColorKey,
  gaugeBackgroundOverrideColorKey,
  displayLabelAsDash = false,
}: RadialGaugeProps) {
  const theme = useTheme();
  const svgRef = useRef(null);
  const intl = useIntl();
  const NA = intl.formatMessage({
    id: 'common-not-applicable',
    defaultMessage: 'N/A',
    description: "Label for when there isn't a value to display",
  });

  const startAngle = -0.65 * Math.PI;
  const endAngle = 0.65 * Math.PI;
  const outerRadius = radius;
  const innerRadius = outerRadius - Math.floor(outerRadius / 4);
  const height = radius * 1.5;
  const width = radius * 2;

  const indicatorArcAngles = useMemo(() => {
    const percent = ((flow.amount ?? 0) / flow.max) * 100;
    const ratio = Number(((flow.amount ?? 0) / flow.max).toFixed(2));
    const arcRatio = 1.3 * ratio - 0.65;
    const arcEndAngle = arcRatio * Math.PI;
    return {
      startAngle: startAngle,
      endAngle: arcEndAngle,
      percent: percent,
    };
  }, [flow, startAngle]);

  const pieGenerator = d3
    .pie()
    .sort(null)
    .startAngle(startAngle)
    .endAngle(endAngle);

  const gaugeSections = pieGenerator(Array.from(gaugeMap.keys()));

  const lineGenerator = d3
    .line()
    .context(null)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .x((d: any) => d.x)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .y((d: any) => d.y);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const arc: Arc<any, any> = d3
    .arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius);

  useEffect(() => {
    const colorMap: Map<GaugeSection, string> = new Map([
      [GaugeSection.danger, theme.palette.error.light],
      [GaugeSection.warning, theme.palette.warning.light],
      [GaugeSection.safe, theme.palette.success.light],
    ]);

    const getLineData = () => {
      const x2 = Math.sin(indicatorArcAngles.endAngle) * outerRadius;
      const y2 = Math.cos(indicatorArcAngles.endAngle) * outerRadius * -1;
      const linePoints: { p: { x: number; y: number }[]; percent: number }[] = [
        {
          p: [
            { x: 0, y: 0 },
            { x: x2, y: y2 },
          ],
          percent: indicatorArcAngles.percent,
        },
      ];
      return linePoints;
    };

    const determineGaugeColor = (data: number): string => {
      const section = gaugeMap.get(data) ?? GaugeSection.danger;
      const mappedColor = colorMap.get(section) ?? theme.palette.error.main;
      return mappedColor;
    };

    const determineIndicatorColor = (percent: number) => {
      if (percent > successLimit) return theme.palette.success.main;
      if (percent > warningLimit) return theme.palette.warning.main;

      return theme.palette.error.main;
    };

    const svg = d3
      .select(svgRef.current)
      .selectAll('g')
      .attr('class', 'arc')
      .attr('transform', `translate(${outerRadius}, ${outerRadius})`);

    // gauge sections
    svg
      .selectAll('path')
      .data(gaugeSections)
      .join('path')
      .attr('class', 'arc-bg')
      .attr('d', arc)
      .attr('fill', (d: PieArcDatum<number | { valueOf(): number }>) => {
        return gaugeBackgroundOverrideColorKey
          ? theme.palette[gaugeBackgroundOverrideColorKey].main
          : determineGaugeColor(d.data.valueOf());
      });

    // gauge indicator
    svg
      .append('path')
      .datum(indicatorArcAngles)
      .join('path')
      .attr('class', 'arc-ind')
      .attr('d', arc)
      .attr('fill', (d) => {
        return gaugeOverrideColorKey
          ? theme.palette[gaugeOverrideColorKey].main
          : determineIndicatorColor(d.percent);
      });

    // line
    svg
      .selectAll('.arc-line')
      .data(getLineData())
      .join('path')
      .attr('class', 'arc-line')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .attr('d', (d: any) => lineGenerator(d.p))
      .style('stroke', (d) => {
        return gaugeOverrideColorKey
          ? theme.palette[gaugeOverrideColorKey].main
          : determineIndicatorColor(d.percent);
      })
      .style('stroke-width', 4);

    // masking circle
    svg
      .append('circle')
      .attr('cy', '0')
      .attr('cx', '0')
      .attr('r', `${Math.floor(outerRadius / 2)}`)
      .attr('fill', backgroundColor);
  }, [
    backgroundColor,
    gaugeSections,
    lineGenerator,
    indicatorArcAngles,
    arc,
    outerRadius,
    theme,
    gaugeMap,
    successLimit,
    warningLimit,
    gaugeOverrideColorKey,
    gaugeBackgroundOverrideColorKey,
  ]);

  return (
    <BoxContainer height={height} width={width}>
      <CenterSvg ref={svgRef} height={height} width={width}>
        <g />
      </CenterSvg>
      <CenterBox>
        <StyledLabels
          label={
            displayLabelAsDash ? '-' : flow.amount != null ? flow.amount : NA
          }
          labelColorKey={'darkText'}
          labelVariant={labelVariant}
          subLabel={displayLabelAsDash ? '' : flow.unit ?? ''}
          subLabelColorKey="black"
          subLabelVariant={subLabelVariant}
        />
      </CenterBox>
    </BoxContainer>
  );
}
