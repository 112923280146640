import { ContactSupportDialog } from '../../../dialogs/contact-support/ContactSupportDialog';
import { LogoutDialog } from '../../../dialogs/LogoutDialog';

export interface DialogState {
  tooltip: boolean;
  contactSupport: boolean;
  logout: boolean;
}

interface UserInfoDialogsProps {
  dialogState: DialogState;
  setDialogState: (dialogState: DialogState) => void;
}

export const UserInfoDialogs = ({
  dialogState,
  setDialogState,
}: UserInfoDialogsProps) => {
  return (
    <>
      <ContactSupportDialog
        open={dialogState.contactSupport}
        onClose={() =>
          setDialogState({
            tooltip: false,
            contactSupport: false,
            logout: false,
          })
        }
      />
      <LogoutDialog
        open={dialogState.logout}
        onClose={() =>
          setDialogState({
            tooltip: false,
            contactSupport: false,
            logout: false,
          })
        }
      />
    </>
  );
};
