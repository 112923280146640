import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { UserRoleDisplay } from './UserRoleQuery';

const UserRole = lazy(() =>
  import('./UserRoleQuery').then(({ UserRoleQuery }) => ({
    default: UserRoleQuery,
  }))
);

interface SuspenseUserRoleProps {
  name?: string;
}

export function SuspenseUserRole({ name }: SuspenseUserRoleProps) {
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <UserRoleDisplay
                name={name}
                role={
                  <FormattedMessage
                    id="user-info-role-error-label"
                    description="Error message for user role error"
                    defaultMessage="Role Unavailable"
                  />
                }
              />
            );
          }}
        >
          <Suspense
            fallback={
              <UserRoleDisplay
                name={name}
                role={
                  <FormattedMessage
                    id="common-loading-label"
                    description="Loading message"
                    defaultMessage="Loading..."
                  />
                }
              />
            }
          >
            <UserRole name={name} />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
