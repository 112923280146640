/* eslint-disable @typescript-eslint/no-explicit-any */
import { InfiniteData, QueryClient, QueryKey } from 'react-query';

// For use within an onMutate call of a react-query useMutation
// T is a GQL query e.g. GQLListBeerBrandsQuery
// K is the GQL model for T e.g. GQLBeerBrand
export async function optimisticDeleteInfiniteData<
  T extends object,
  K extends { id: string }
>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  listKey: string,
  mutatedModel: K
) {
  await queryClient.cancelQueries(queryKey);
  const previousState = queryClient.getQueryData<InfiniteData<T>>(queryKey);
  const newData = previousState
    ? { ...previousState }
    : { pages: [], pageParams: [] };

  let deletedIdx = -1;
  const updatedPageIdx =
    previousState?.pages.findIndex((page) => {
      deletedIdx =
        (page as any)[listKey]?.findIndex(
          (x: K) => x?.id === mutatedModel.id
        ) ?? -1;
      return deletedIdx !== -1;
    }) ?? -1;

  if (updatedPageIdx !== -1 && deletedIdx !== -1) {
    const newPage: (K | null)[] | null | undefined = (
      newData.pages[updatedPageIdx] as any
    )[listKey];
    if (newPage) {
      newPage.splice(deletedIdx, 1);
      (newData.pages[updatedPageIdx] as any)[listKey] = newPage;
      queryClient.setQueryData(queryKey, newData);
    }
  }

  return { previousState };
}
