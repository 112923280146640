import { styled } from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';
import { RouteTabLink, RouteTabLinkProps } from '../tabs/RouteTabLink';

const StyledMainTabsButton = styled(RouteTabLink)(({ theme }) => ({
  color: theme.palette.disabledPrimary.main,
  '&.Mui-selected': {
    color: theme.palette.disabled.contrastText,
  },
}));
StyledMainTabsButton.displayName = 'StyledMainTabsButton';

export const MainTabsButton = forwardRef(
  (props: RouteTabLinkProps, ref: ForwardedRef<HTMLAnchorElement>) => {
    return <StyledMainTabsButton ref={ref} {...props} />;
  }
);
MainTabsButton.displayName = 'MainTabsButton';
