import {
  GQLCompanySite,
  execSummaryStore,
  SiteGroup,
  useGQLSites,
  useActiveAppEntities,
  useAreas,
} from '@beeriot/api-client';
import {
  ButtonBase,
  ButtonBaseProps,
  Divider,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useAppRoutePaths } from '../../../app-routing/utils';
import { useIsExecSummaryPage } from '../../../navigation/hooks';
import { transientOptions } from '../../../utils/transientOptions';
import { AppIcon } from '../../AppIcon';
import {
  AppTypography,
  OverridableComponentAppTypography,
} from '../../AppTypography';
import { VerticalLabels } from '../../VerticalLabels';
import { ParentPageName } from '../types/ParentPageName';

const NoSitesTypography = styled(Typography)({
  textAlign: 'center',
  padding: '1rem',
});

const ContainerStack = styled(Stack)({
  width: '20rem',
  maxHeight: '50rem',
});

const StyledTypography = styled(AppTypography)(({ theme }) => ({
  padding: '0.25rem 0.5rem',
  textTransform: 'uppercase',
  backgroundColor: theme.palette.componentShade.main,
}));

const ItemButton = styled(
  ButtonBase,
  transientOptions
)<ButtonBaseProps & { $selected: boolean }>(({ theme, $selected }) => ({
  justifyContent: 'space-between',
  padding: '0.25rem 0.5rem',
  backgroundColor: $selected ? theme.palette.disabledPrimary.main : 'inherit',
}));

const SelectedIcon = styled(AppIcon)({
  marginRight: '0.5rem',
});

const SelectedContainer = styled(Stack)({
  alignItems: 'center',
});

const SiteName = styled(AppTypography)({
  lineHeight: '1',
}) as OverridableComponentAppTypography;

interface BreadcrumbsSiteDropdownProps {
  groupedSites?: SiteGroup[] | null;
  parentPageName?: ParentPageName;
  onClose: () => void;
}

export function BreadcrumbsSiteDropdown({
  groupedSites,
  parentPageName,
  onClose,
}: BreadcrumbsSiteDropdownProps) {
  const navigate = useNavigate();
  const { activeSite, activeCompany } = useActiveAppEntities();
  const isExecSummary = useIsExecSummaryPage();
  const appRoutes = useAppRoutePaths();
  const { query } = useGQLSites(activeCompany?.id);
  const areaQuery = useAreas(activeSite?.id);

  const loading =
    query.isLoading ||
    areaQuery.isLoading ||
    query.isFetching ||
    areaQuery.isFetching;

  const selectSite = useCallback(
    (site: GQLCompanySite) => {
      // siteStore.update((state) => ({ ...state, activeSite: site }));
      // setActiveSite(site);
      if (isExecSummary) {
        execSummaryStore.update((state) => ({
          ...state,
          maxDetail: 'site',
        }));

        navigate(appRoutes.siteExecSummaryPathForSite(site), {
          replace: false,
        });
      } else {
        navigate(appRoutes.siteDetailsPathForSite(site), { replace: false });
      }
      onClose();
    },
    [isExecSummary, appRoutes, navigate, onClose]
  );

  const getSiteSystemCount = (site: GQLCompanySite) => site.siteSystemsCount?.count ?? 0
    // site.liveStatus?.systems?.reduce(
    //   (accu, curr) => accu + (curr?.count ?? 0),
    //   0
    // );

  return (
    <ContainerStack>
      {!loading && groupedSites?.length === 0 && (
        <NoSitesTypography>
          <FormattedMessage
            id="sites-no-sites-found-label"
            description="Label for when there are no sites found"
            defaultMessage="No Sites Found"
          />
        </NoSitesTypography>
      )}
      {groupedSites?.map(({ name, sites }) => (
        <Stack key={name}>
          <StyledTypography
            gap="0.5rem"
            variant="subtitle2"
            $colorKey="subtitleText"
          >
            {name}
          </StyledTypography>
          {sites?.map((site) => {
            const selected = site.id === activeSite?.id;
            return (
              <Stack key={site.id}>
                <ItemButton
                  $selected={selected}
                  onClick={() => selectSite(site)}
                >
                  <SelectedContainer direction="row">
                    {selected && (
                      <SelectedIcon icon="check" $colorKey="primary" />
                    )}
                    <VerticalLabels
                      textAlign={'left'}
                      justifyContent={'start'}
                      label={
                        <SiteName component="div" variant="h6">
                          {site?.name}
                        </SiteName>
                      }
                      subLabel={site?.address?.state}
                    />
                  </SelectedContainer>
                  <VerticalLabels
                    textAlign={'right'}
                    justifyContent={'end'}
                    label={
                      <SiteName component="div" variant="h6">
                        {getSiteSystemCount(site) ?? (
                          <FormattedMessage
                            id="common-not-applicable"
                            defaultMessage="N/A"
                            description="Label for when there isn't a value to display"
                          />
                        )}
                      </SiteName>
                    }
                    subLabel={
                      <FormattedMessage
                        id="common-systems-label"
                        description="Systems label"
                        defaultMessage="Systems"
                      />
                    }
                  />
                </ItemButton>
                <Divider />
              </Stack>
            );
          })}
        </Stack>
      ))}
    </ContainerStack>
  );
}
