import { Observable, firstValueFrom, lastValueFrom } from 'rxjs';
import { first, last } from 'rxjs/operators';
import { syncResult } from './sync-result';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface GenericObservablePrototype<T> {
  syncFirstResult(): T;
  syncLastResult(): T;
  asyncFirstResult(): Promise<T>;
  asyncLastResult(): Promise<T>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface GenericObservable<T> extends Observable<T> {
  prototype: GenericObservablePrototype<T>;
}
export const extendRxJS = <T>(opts?: {
  ObservableClass?: GenericObservable<T>;
}) =>
  // opts?: { ObservableClass?: any }
  {
    const { ObservableClass = Observable as unknown as GenericObservable<T> } =
      opts ?? {};

    ObservableClass.prototype.syncFirstResult = function <T>(
      this: GenericObservable<T>
    ): T {
      return syncResult<T>(this.pipe(first()));
    };

    ObservableClass.prototype.syncLastResult = function <T>(
      this: GenericObservable<T>
    ): T {
      return syncResult<T>(this.pipe(last()));
    };

    Observable.prototype.asyncFirstResult = async function <T>(
      this: Observable<T>
    ): Promise<T> {
      return firstValueFrom(this);
    };

    Observable.prototype.asyncLastResult = async function <T>(
      this: Observable<T>
    ): Promise<T> {
      return lastValueFrom(this);
    };
  };
