import { Stack, styled } from '@mui/material';
import { GlobalFooter, ScrollStack } from '../../../base';
import { RootPage } from '../../RootPage';
import { ExecSummarySiteControlBox } from './site-control-box';
import { ExecSummarySiteSidebarDrawer } from './site-sidebar';
import { SuspenseExecSummarySitePageContent } from './SuspenseExecSummarySitePageContent';

const ContentStack = styled(Stack)({
  overflow: 'hidden',
  height: '100%',
});

export function ExecSummarySitePage() {
  return (
    <RootPage>
      <ExecSummarySiteControlBox />
      <ContentStack direction="row">
        <ExecSummarySiteSidebarDrawer />
        <ScrollStack>
          <SuspenseExecSummarySitePageContent />
          <GlobalFooter />
        </ScrollStack>
      </ContentStack>
    </RootPage>
  );
}
