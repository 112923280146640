import { useNavigate } from 'react-router-dom';
import { styled, Grid, Button, Stack } from '@mui/material';
import { GQLCompany, CompanyGroup } from '@beeriot/api-client';
import { CompanyCard } from '../CompanyCard';
import { CompanyCardListSectionHeader } from './CompanyCardListSectionHeader';
import { useIntl } from 'react-intl';
import { AppTypography } from '../../base';

const StyledButton = styled(Button)({
  padding: '0',
  width: '100%',
  textAlign: 'start',
});

const CompanyGridSectionsContainer = styled(Grid)({
  padding: '1rem',
});

const SectionHeaderGridItem = styled(Grid)({
  marginBottom: '1rem',
});
const SectionGridItem = styled(Grid)({});

const SectionCardGridContainer = styled(Grid)({
  width: '100%',
});
const SectionCardGridItem = styled(Grid)({
  width: '100%',
});

export interface CompanyCardListProps {
  companyGroups: CompanyGroup[];
  favoritedCompanyIds: string[];
}

export function CompanyCardList({
  companyGroups,
  favoritedCompanyIds,
}: CompanyCardListProps) {
  const navigate = useNavigate();
  const intl = useIntl();
  const selectCompany = (company: GQLCompany) => {
    navigate(`/companies/${company.id}/sites`);
  };

  const noCompaniesLabel = intl.formatMessage({
    id: 'companies-list-no-companies-label',
    description: 'Label for when no companies are available',
    defaultMessage: 'No Companies Found',
  });
  return (
    <CompanyGridSectionsContainer container spacing={3}>
      {companyGroups.length === 0 ? (
        <Stack sx={{ alignItems: 'center', width: '100%', marginTop: '2rem' }}>
          <AppTypography $colorKey="lightText" $bold>
            {noCompaniesLabel}
          </AppTypography>
        </Stack>
      ) : null}
      {companyGroups.map((companyGroup) => {
        return (
          <SectionGridItem
            key={`company-group--${companyGroup.letter}`}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <SectionHeaderGridItem item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CompanyCardListSectionHeader
                companyCount={companyGroup.companies.length}
                companyLetter={companyGroup.letter}
              />
            </SectionHeaderGridItem>
            <SectionCardGridContainer
              container
              item
              spacing={3}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              {companyGroup.companies.map((company) => {
                const favorited = company.id
                  ? favoritedCompanyIds.includes(company.id)
                  : false;
                return (
                  <SectionCardGridItem
                    key={`company-card--${company.id}`}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={4}
                  >
                    <StyledButton onClick={() => selectCompany(company)}>
                      <CompanyCard company={company} favorited={favorited} />
                    </StyledButton>
                  </SectionCardGridItem>
                );
              })}
            </SectionCardGridContainer>
          </SectionGridItem>
        );
      })}
    </CompanyGridSectionsContainer>
  );
}
