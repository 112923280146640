import { styled } from '@mui/material';
import { RootPage } from '../../pages/RootPage';
import { LineSidebarDrawer } from '../line-sidebar-menu';
import { FilterRunsTable } from './FilterRunsTable';
import { FilterRunsDetailDialog } from '../../dialogs';
import {
  GlobalFooter,
  ScrollStack,
  PermissionView,
  PermissionKey,
} from '../../base';
import { AlertsDrawer } from '../../alerts';

const StyledRootPage = styled(RootPage)({
  overflow: 'auto',
});

export function FilterRunsPanel() {
  return (
    <StyledRootPage direction="row">
      <LineSidebarDrawer />
      <ScrollStack>
        <FilterRunsTable />
        <GlobalFooter />
      </ScrollStack>
      <PermissionView permissionKey={PermissionKey.AlertWidget}>
        <AlertsDrawer />
      </PermissionView>
      <FilterRunsDetailDialog />
    </StyledRootPage>
  );
}
