import LinearProgress, {
  LinearProgressProps,
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const ContainerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  width: '100%',
  alignItems: 'center',
}));

const BorderLinearProgressContainerBox = styled(Box)(({ theme }) => ({
  width: '100%',
  marginRight: theme.spacing(1),
}));
interface LinearProgressWithLabelProps extends LinearProgressProps {
  value: number;
}

export function LinearProgressWithLabel({
  value,
  className,
  ...linearProgressProps
}: LinearProgressWithLabelProps) {
  return (
    <ContainerBox className={className}>
      <BorderLinearProgressContainerBox>
        <BorderLinearProgress
          variant="determinate"
          {...linearProgressProps}
          value={value}
        />
      </BorderLinearProgressContainerBox>
      <Box>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </ContainerBox>
  );
}
