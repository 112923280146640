import { useCallback, useState } from 'react';
import { Alert, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { TelemetryDataExport } from '@beeriot/api-client';
import { RootPage } from '../../pages/RootPage';
import { TelemetryDataExportButtons } from './TelemetryDataExportButtons';
import { AppIcon, AppTypography, GlobalFooter } from '../../base';
import { useResponsiveSizes } from '../../utils';
import { TelemetryDataExportTableContainer } from './TelemetryDataExportTableContainer';
import { ExportDataDialog, MobileDataExportActionsDialog,ShareExportDialog } from '../../dialogs/export-data';



const StyledRootPage = styled(RootPage)({
  overflow: 'auto',
});

const WarningAlert = styled(Alert)(({ theme }) => ({
  margin: '1rem 1rem 0 1rem',
  border: `1px solid ${theme.palette.warning.main}`,
}));

export function TelemetryDataExportPanel() {
  
  const [openNewDataExport, setOpenNewDataExport] = useState<boolean>(false);
  const [openActionsDialog, setOpenActionsDialog] = useState<boolean>(false);
  const [openShareExportDialog, setOpenShareExportDialog] =
    useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<
    TelemetryDataExport | undefined
  >();
  const { isDesktopSize } = useResponsiveSizes();

  const showNewDataExportForm = useCallback(() => {
    setSelectedItem(undefined);
    setOpenNewDataExport(true);
  }, [setSelectedItem, setOpenNewDataExport]);
  const showActionsDialog = useCallback(
    (data: TelemetryDataExport | undefined) => {
      setOpenActionsDialog(true);
      setSelectedItem(data);
    },
    [setOpenActionsDialog, setSelectedItem]
  );
  const downloadDataExport = useCallback(
    (data: TelemetryDataExport | undefined) => {
      setSelectedItem(data);
      const link = document.createElement('a');
        link.href = data?.exportDownloadUrl ?? '';
        link.click();
        link.remove();
    },
    [setSelectedItem]
  );
  const shareDataExport = useCallback(
    (data: TelemetryDataExport | undefined) => {
      setSelectedItem(data);
      setOpenShareExportDialog(true)
    },
    [setSelectedItem]
  );

  return (
    <StyledRootPage>
      <WarningAlert
        severity="warning"
        icon={
          <AppIcon
            icon={'circle-exclamation'}
            $colorKey={'warning'}
            size="1x"
          />
        }
      >
        <AppTypography $bold variant="subtitle2">
          <FormattedMessage
            id="telemetry-data-export-expiration-warning-title"
            description="Warning for expiration of data"
            defaultMessage="Data is stored for 12 months. Please make sure to download it before it expires."
          />
        </AppTypography>
        <AppTypography $bold variant="subtitle2">
          <FormattedMessage
            id="telemetry-data-export-expiration-warning-content"
            description="Warning for expiration of data"
            defaultMessage="Depending on the file size, it may take some time to process your data export requests. Upon success your requested file(s) will appear in the table below."
          />
        </AppTypography>
      </WarningAlert>
      {!isDesktopSize && (
        <TelemetryDataExportButtons
          showNewDataExportForm={showNewDataExportForm}
        />
      )}
      <TelemetryDataExportTableContainer
        showNewDataExportForm={showNewDataExportForm}
        downloadDataExport={downloadDataExport}
        shareDataExport={shareDataExport}
        showActionsDialog={showActionsDialog}
      />
      <GlobalFooter />
      <ExportDataDialog
        open={openNewDataExport}
        onClose={() => setOpenNewDataExport(false)}
      />
      
        <ShareExportDialog
          open={openShareExportDialog}
          onClose={() => setOpenShareExportDialog(false)}
          dataExport={selectedItem}
        />
      
      <MobileDataExportActionsDialog
        open={openActionsDialog}
        onClose={() => setOpenActionsDialog(false)}
        item={selectedItem}
        downloadDataExport={downloadDataExport}
        shareDataExport={shareDataExport}
      />
    </StyledRootPage>
  );
}
