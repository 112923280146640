import { Paper, Stack, styled } from '@mui/material';
import { GQLReportDefinition, GQLReport } from '@beeriot/api-client';
import { ReportsPanelHeader } from './ReportsPanelHeader';
import { SuspenseReportsTableContainer } from './SuspenseReportsTableContainer';
import { useResponsiveSizes } from '../../utils';
import { ReportsHeaderButtons } from './ReportsHeaderButtons';

const TableContainer = styled(Stack)({
  margin: '1rem',
  gap: '1rem',
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  overflow: 'auto',
}));

export interface ReportsTableContainerProps {
  showRecurringReports: boolean;
  viewButtonLoading: boolean;
  showAddRecurringReportForm: () => void;
  showShareReport: (data: GQLReport) => void;
  showReport: (data: GQLReport) => void;
  showConfirmDeleteReport: (data: GQLReportDefinition) => void;
  toggleRecurringReports: () => void;
}

export const ReportsTableContainer = ({
  showRecurringReports,
  viewButtonLoading,
  showAddRecurringReportForm,
  showShareReport,
  showReport,
  showConfirmDeleteReport,
  toggleRecurringReports,
}: ReportsTableContainerProps) => {
  const { isMobileSize } = useResponsiveSizes();
  return (
    <TableContainer>
      {isMobileSize ? (
        <>
          <ReportsHeaderButtons
            showAddRecurringReportForm={showAddRecurringReportForm}
            toggleRecurringReports={toggleRecurringReports}
            showRecurringReports={showRecurringReports}
          />
          <SuspenseReportsTableContainer
            showShareReport={showShareReport}
            viewButtonLoading={viewButtonLoading}
            showReport={showReport}
            showConfirmDeleteReport={showConfirmDeleteReport}
          />
        </>
      ) : (
        <StyledPaper>
          <ReportsPanelHeader
            showAddRecurringReportForm={showAddRecurringReportForm}
            toggleRecurringReports={toggleRecurringReports}
            showRecurringReports={showRecurringReports}
          />
          <SuspenseReportsTableContainer
            showShareReport={showShareReport}
            viewButtonLoading={viewButtonLoading}
            showReport={showReport}
            showConfirmDeleteReport={showConfirmDeleteReport}
          />
        </StyledPaper>
      )}
    </TableContainer>
  );
};
