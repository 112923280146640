import { FormattedMessage } from 'react-intl';
import { Button, Stack, styled } from '@mui/material';
import { IconLabel, PermissionKey, PermissionView } from '../../base';
import { transientOptions, useResponsiveSizes } from '../../utils';
import { useObservable } from '@ngneat/use-observable';
import { activeSystem$ } from '../../../../../api-client/src';

const StyledStack = styled(
  Stack,
  transientOptions
)<{ $desktopSize: boolean }>(({ $desktopSize }) => ({
  justifyContent: $desktopSize ? 'flex-end' : 'flex-start',
  flex: $desktopSize ? '1' : 'inherit',
  maxWidth: $desktopSize ? '30rem' : 'unset',
  padding: '1rem',
  gap: '1rem',
}));

const StyledButton = styled(Button)({
  width: '100%',
});

interface DocumentationButtonsProps {
  showAddForm: () => void;
  toggleDeletedDocuments: () => void;
  showDeletedDocuments: boolean;
}

export function DocumentationButtons({
  showAddForm,
  toggleDeletedDocuments,
  showDeletedDocuments,
}: DocumentationButtonsProps) {
  const { isDesktopSize } = useResponsiveSizes();
  const [activeSystem] = useObservable(activeSystem$);
  let documentAddPermissionKey: PermissionKey;

  switch (activeSystem?.type) {
    case 'profi':
      documentAddPermissionKey = PermissionKey.SitesPROFiDocumentationAdd;
      break;
    case 'cbs':
      documentAddPermissionKey = PermissionKey.SitesPROFiDocumentationAdd;
      break;
    case 'cfs':
      documentAddPermissionKey = PermissionKey.SitesPROFiDocumentationAdd;
      break;
    case 'keraflux':
      documentAddPermissionKey = PermissionKey.SitesPROFiDocumentationAdd;
      break;
    default:
      documentAddPermissionKey = PermissionKey.SitesPROFiDocumentationAdd;
      break;
  }
  return (
    <StyledStack $desktopSize={isDesktopSize} direction="row">
      <StyledButton
        color={showDeletedDocuments ? 'success' : 'highlight2'}
        variant="contained"
        onClick={toggleDeletedDocuments}
      >
        {showDeletedDocuments ? (
          <FormattedMessage
            id="documentation-table-header-return-to-documents-button"
            defaultMessage="Return to Documents"
            description="button label for returning to documents list"
          />
        ) : (
          <FormattedMessage
            id="documentation-table-header-show-deleted-documents-button"
            defaultMessage="Deleted Documents"
            description="button label for deleted documents"
          />
        )}
      </StyledButton>
      {!showDeletedDocuments && (
        <PermissionView permissionKey={documentAddPermissionKey}>
          <StyledButton variant="contained" onClick={showAddForm}>
            <IconLabel
              variant="button"
              label={
                <FormattedMessage
                  id="documentation-table-header-add-document-button"
                  defaultMessage="Add Document"
                  description="button label for add document"
                />
              }
              colorKey="white"
              icon={['far', 'circle-plus']}
              iconColorKey="white"
              iconSize="lg"
            />
          </StyledButton>
        </PermissionView>
      )}
    </StyledStack>
  );
}
