import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { GroupApi } from '../generated';
import { appConfiguration } from './configuration';
import {
  CompanySite,
  Group,
  GroupBase,
  GroupStatusEnum,
} from '../generated/api';
import { groupStore } from '../state';
import { AppQueryClient, LONG_POLLING_INTERVAL } from '../utils';

export const groupApi = new GroupApi(appConfiguration);

export interface GroupSites {
  group: Group;
  sites: CompanySite[];
}

async function getGroups(companyId: string) {
  const groups = await groupApi.getGroups(
    companyId,
    undefined,
    'active',
    0,
    100,
    
  );
  return groups.data;
}

export function useCreateGroup() {
  const queryClient = useQueryClient();
  return useMutation(
    async (groupBase: GroupBase) => {
      const response = await groupApi.createGroup(groupBase);
      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(['groups', data.companyId]);
      },
    }
  );
}

//Set group to inactive
export function useSoftDeleteGroup() {
  const queryClient = useQueryClient();
  return useMutation(
    async (group: Group) => {
      const response = await groupApi.updateGroup(group.id, {
        ...group,
        status: GroupStatusEnum.Inactive,
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(['groups', data.companyId]);
      },
    }
  );
}

export function useUpdateGroup() {
  const queryClient = useQueryClient();
  return useMutation(
    async (group: Group) => {
      const response = await groupApi.updateGroup(group.id, group);
      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(['groups', data.companyId]);
      },
    }
  );
}

export function useGroups(companyId: string): UseQueryResult<Group[]> {
  return useQuery(['groups', companyId], () => getGroups(companyId), {
    refetchOnMount: false,
    refetchInterval: LONG_POLLING_INTERVAL,
  });
}

export async function setToDefaultGroup(companyId?: string | null) {
  const s = groupStore.getValue();
  if (!companyId) {
    if (s?.activeGroupType?.id) {
      groupStore.update((state) => ({ ...state, activeGroupType: null }));
    }
    return;
  }

  const g = await AppQueryClient.fetchQuery(
    ['groups', companyId],
    () => getGroups(companyId),
    { staleTime: LONG_POLLING_INTERVAL }
  );
  const rawGroups = g; //await getGroups(companyId);
  const groupTypes = rawGroups.filter((x) => !x.parentGroupId);
  const activeGroupType = groupTypes.length > 0 ? groupTypes[0] : null;

  if (s?.activeGroupType?.id !== activeGroupType?.id) {
    groupStore.update((state) => ({
      ...state,
      activeGroupType: activeGroupType,
    }));
  }
}
