import { createEffectFn } from '@ngneat/effects';
import { Observable, from, map, switchMap, tap } from 'rxjs';
import { GQLCompany } from '../../generated/gql';
import { setToDefaultGroup } from '../../lib/groups';
import { setToDefaultSite } from '../../lib/sites/setToDefaultSite';
import { execSummaryStore } from '../execSummaryStore';
import { groupStore } from '../groupStore';
import { siteStore } from '../site';
import { systemStore } from '../system/systemStore';
import { setActiveCompany } from './setActiveCompany';
import { analyticsStore } from '../analyticsStore';
import { emitOnce } from '@ngneat/elf';

export const updateActiveCompanyEffect = createEffectFn(
  (newCompany$: Observable<GQLCompany | null>) => {
    
    return newCompany$.pipe(
      tap(() => execSummaryStore.reset()),
      tap(() => systemStore.reset()),
      // tap(() => siteStore.reset()),
      tap(() => groupStore.reset()),
      tap(() => analyticsStore.reset()),
      tap((newCompany) => {
        // emitOnce(() => {
          setActiveCompany(newCompany);
          setToDefaultSite(newCompany?.id);
          setToDefaultGroup(newCompany?.id);
        // })
      }),
      // tap(setActiveCompany),
      // tap((x) => setToDefaultSite(x?.id)),
      // tap((x) => setToDefaultGroup(x?.id))
    );
  }
);
