import { ReactNode } from 'react';
import { Stack, styled } from '@mui/material';
import { AppTableHeaderText } from './AppTableHeaderText';

const AppTableHeaderContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  flex: 1,
  width: '100%',
}));

export const AppTableHeaderControlsContainer = styled(Stack)({
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: '0 1rem',
});

interface AppTableHeaderProps {
  label: ReactNode;
  children?: ReactNode;
}

export const AppTableHeader = ({ label, children }: AppTableHeaderProps) => {
  return (
    <AppTableHeaderContainer direction="row">
      <AppTableHeaderText>{label}</AppTableHeaderText>
      {children}
    </AppTableHeaderContainer>
  );
};
