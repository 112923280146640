import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Paper, Stack, styled } from '@mui/material';
import { SuspenseAutomaticReportSettings } from './SuspenseAutomaticReportSettings';
import {
  AppTableHeader,
  AppTableHeaderControlsContainer,
  PermissionKey,
  PermissionView,
} from '../../base';
import { SettingsHeaderButton } from '../settings-form';
import { useAppRoutePaths } from '../../app-routing/utils';
import { useResponsiveSizes } from '../../utils';

const TableContainer = styled(Stack)({
  margin: '1rem',
  minWidth: '50rem',
  maxWidth: '80rem',
});

const StyledAppTableHeaderControlsContainer = styled(
  AppTableHeaderControlsContainer
)({
  justifyContent: 'end',
});

export const AutomaticReportSettingsPanel = () => {
  const { isMobileSize } = useResponsiveSizes();
  const navigate = useNavigate();

  const appPaths = useAppRoutePaths();
  const showAddForm = useCallback(() => {
    navigate(appPaths.settingsNewReportPath);
  }, [navigate, appPaths.settingsNewReportPath]);

  return isMobileSize ? (
    <>
      <PermissionView permissionKey={PermissionKey.SettingsReportingCreate}>
        <SettingsHeaderButton
          onClick={showAddForm}
          label={
            <FormattedMessage
              id="reports-new-report-button"
              defaultMessage="New Report"
              description="Button for new report"
            />
          }
        />
      </PermissionView>
      <SuspenseAutomaticReportSettings />
    </>
  ) : (
    <TableContainer>
      <Paper>
        <AppTableHeader
          label={
            <FormattedMessage
              id="automatic-report-table-header-title"
              defaultMessage="Recurring Reports"
              description="header for recurring reports table"
            />
          }
        >
          <StyledAppTableHeaderControlsContainer>
            <PermissionView
              permissionKey={PermissionKey.SettingsReportingCreate}
            >
              <SettingsHeaderButton
                onClick={showAddForm}
                label={
                  <FormattedMessage
                    id="reports-new-report-button"
                    defaultMessage="New Report"
                    description="Button for new report"
                  />
                }
              />
            </PermissionView>
          </StyledAppTableHeaderControlsContainer>
        </AppTableHeader>
        <SuspenseAutomaticReportSettings />
      </Paper>
    </TableContainer>
  );
};
