import { combineLatest, from, Observable, of } from 'rxjs';
import {
  concatMap,
  groupBy,
  map,
  mergeMap,
  pluck,
  tap,
  toArray,
} from 'rxjs/operators';
import { GQLCompanySite } from '../../generated/gql';
import { SiteGroup } from '../../lib';

export function groupSitesByLetterOperator() {
  return _groupSitesByLetter;
}

// implement returned method as a module function so its JIT'ed
function _groupSitesByLetter(dataObs: Observable<GQLCompanySite[] | null>) {
  return dataObs.pipe(
    concatMap((x) => {
      if (!x) {
        return of(null);
      }
      return from(
        (x ?? []).sort((a, b) => (a?.name ?? '').localeCompare(b?.name ?? ''))
      ).pipe(
        map((site) => ({
          site,
          firstLetter: site.name?.charAt(0)?.toLocaleUpperCase() ?? '',
        })),
        groupBy(
          ({ firstLetter, site }) => {
            return firstLetter;
          },
          {
            element: ({ site }) => site,
          }
        ),
        mergeMap((group) => {
          return combineLatest({
            name: of(group.key),
            sites: group.pipe(toArray()),
          });
        }),

        toArray<SiteGroup>()
      );
    })
  );
}
