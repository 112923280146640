export async function registerWebPushServiceWorker() {
  navigator.serviceWorker.register('/web-push.serviceWorker.js').then(
    async () => {
      console.log('service worker registered');
    },
    (err) => {
      console.log('service worker registration error', err);
    }
  );
}
