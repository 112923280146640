import { AutomaticReportSettingsPanel } from '../../settings';
import { SettingsPage } from './SettingsPage';

export function AutomaticReportSettingsPage() {
  return (
    <SettingsPage>
      <AutomaticReportSettingsPanel />
    </SettingsPage>
  );
}
