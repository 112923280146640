import React, { ReactNode } from 'react';
import { Route } from 'react-router-dom';
import { RouteTabObject } from '../models/RouteTabObject';

/**
 * Default renderer for TabPanel Routes, renders a list of
 * TabRouterPanels, can be used as a template for other renderers
 *
 * @param route
 * @param index
 * @param array
 * @param _children
 * @returns
 */
export function defaultTabPanelRenderer(
  route: Partial<RouteTabObject>,
  index: number,
  array: Partial<RouteTabObject>[],
  _children: ReactNode[]
):
  | React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>>
  | undefined {
  // console.log(`render route tab: `, route);
  return (
    <Route
      // spread props so this picks up click handlers, loaders, etc
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...((route as any) ?? {})}
      handle={route.handle}
      key={index}
      path={route.path ?? '/'}
      element={route.element}
    >
      {route.children as ReactNode[]}
    </Route>
  );
}
