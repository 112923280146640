import { GQLCompany } from '../../generated/gql';
import { companyStore } from './companyStore';
import { prefetchCompanySites } from '../../lib/prefetchCompanySites';
import { prefetchSystems } from '../../lib/prefetchSystems';
import { updateSitesFromCache } from './updateSitesFromCache';


export const setActiveCompany = (activeCompany: GQLCompany | null) => {
  companyStore.update((state) => ({ ...state, activeCompany }));
  // updateSitesFromCache(activeCompany);
  if (activeCompany) {
    prefetchCompanySites(activeCompany?.id);
    prefetchSystems(activeCompany?.id);
  }
};
