import { FormattedMessage } from 'react-intl';
import { Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import { parseISO } from 'date-fns';
import {
  CalendarMode,
  execSummaryDate$,
  execSummaryStore,
  execSummaryViewingFilter$,
} from '@beeriot/api-client';
import {
  AppBreadcrumbsContainer,
  ControlBox,
  AppTypography,
  PermissionKey,
  PermissionView,
  CalendarModePicker,
  AppDatePicker,
  CombinedCalendarModePicker,
  RoutingBreadcrumbs,
} from '../../../../base';
import { SuspenseExecSummaryLineCompareButton } from './line-compare';
import { SuspenseExecSummaryBrandCompareButton } from './brand-compare';
import { dateToStartOfView, useResponsiveSizes } from '../../../../utils';
import { EndComparisonButton } from './EndComparisonButton';

const StyledControlBox = styled(ControlBox)(({ theme }) => ({
  padding: '0.5rem 1rem',
  flexWrap: 'wrap',
  gap: '1rem',
}));

const Container = styled(Stack)({
  width: '100%',
});

const CompareButtonsContainer = styled(Stack)(({ theme }) => ({
  flex: 1,
  gap: '1rem',
  justifyContent: 'flex-end',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
  },
}));

export function ExecSummaryLineControlBox() {
  const theme = useTheme();
  const xlBreakpoint = useMediaQuery(theme.breakpoints.down('xl'));
  const { isMobileSize, isSmallTabletSize } = useResponsiveSizes();
  const [view] = useObservable(execSummaryViewingFilter$);
  const [dateString] = useObservable(execSummaryDate$);
  const date = parseISO(dateString);

  const setView = (viewingFilter: CalendarMode) => {
    execSummaryStore.update((state) => ({
      ...state,
      viewingFilter,
      date: dateToStartOfView(viewingFilter, date).toISOString(),
    }));
  };

  const setDate = (newDate: Date | null) => {
    if (newDate)
      execSummaryStore.update((state) => ({
        ...state,
        date: newDate.toISOString(),
      }));
  };

  return (
    <Container direction={isMobileSize || isSmallTabletSize ? 'column' : 'row'}>
      <AppBreadcrumbsContainer>
        <RoutingBreadcrumbs />
      </AppBreadcrumbsContainer>
      <StyledControlBox direction="row">
        {xlBreakpoint ? (
          <CombinedCalendarModePicker
            fullWidth={isMobileSize}
            calendarMode={view}
            value={date}
            onChange={setDate}
            onCalendarModeChange={setView}
          />
        ) : (
          <>
            <AppTypography $bold $colorKey="lightText">
              <FormattedMessage
                id="common-viewing-label"
                defaultMessage="Viewing"
                description="Label for viewing"
              />
            </AppTypography>
            <CalendarModePicker value={view} onChange={setView} />
            <AppDatePicker
              calendarMode={view}
              value={date}
              onChange={setDate}
            />
          </>
        )}
        <CompareButtonsContainer direction="row">
          <EndComparisonButton />
          <PermissionView
            permissionKey={PermissionKey.ExecutiveSummaryCompareLines}
          >
            <SuspenseExecSummaryLineCompareButton />
          </PermissionView>
          <PermissionView
            permissionKey={PermissionKey.ExecutiveSummaryCompareBrands}
          >
            <SuspenseExecSummaryBrandCompareButton />
          </PermissionView>
        </CompareButtonsContainer>
      </StyledControlBox>
    </Container>
  );
}
