import { GQLGetSystemStatsQuery } from '../../../generated/gql';
import { LineStatisticsGroup } from '../lineStatistics';
import { getCBSStatisticsGroups } from './getCBSStatisticsGroups';
import { getCFSStatisticsGroups } from './getCFSStatisticsGroups';
import { getKerafluxStatisticsGroups } from './getKerafluxStatisticsGroups';
import { getProfiStatisticsGroups } from './getProfiStatisticsGroups';

export function toStatisticsGroups(
  systemType: string | undefined,
  processQuery?: GQLGetSystemStatsQuery,
  cleaningConsumptionQuery?: GQLGetSystemStatsQuery,
  qualityQuery?: GQLGetSystemStatsQuery,
  regenerationQuery?: GQLGetSystemStatsQuery
): LineStatisticsGroup[] {
  switch (systemType) {
    case 'profi':
      return getProfiStatisticsGroups(
        processQuery,
        cleaningConsumptionQuery,
        qualityQuery
      );
    case 'cbs':
      return getCBSStatisticsGroups(processQuery, regenerationQuery);
    case 'cfs':
      return getCFSStatisticsGroups(processQuery, regenerationQuery);
    case 'keraflux':
      return getKerafluxStatisticsGroups(
        processQuery,
        cleaningConsumptionQuery
      );
    default: {
      return [];
    }
  }
}
