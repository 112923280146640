import { Group } from '@beeriot/api-client';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  styled,
} from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UseMutationResult } from 'react-query';
import { AppTypography, LoadingButton } from '../../base';

const StyledDialogContent = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const WarningContainer = styled(Box)({
  marginTop: '1rem',
});

const DividerContainer = styled(Box)({
  padding: '0px 2.5rem',
});

const StyledDivider = styled(Divider)({
  borderBottomWidth: '2px',
});

export interface ConfirmGroupDeleteDialogProps {
  mutation: UseMutationResult<Group, unknown, Group, unknown>;
  group: Group | null;
  open: boolean;
  confirm: (group: Group | null) => void;
  cancel: () => void;
  isGroupType: boolean;
}

export function ConfirmGroupDeleteDialog({
  mutation,
  group,
  open,
  confirm,
  cancel,
  isGroupType,
}: ConfirmGroupDeleteDialogProps) {
  const intl = useIntl();

  const dialogTitle = useMemo(() => {
    const groupTypeTitle = intl.formatMessage({
      id: 'organize-sites-delete-group-type-dialog-title',
      description: 'Title for group type deletion',
      defaultMessage: 'Remove Group Type',
    });

    const groupTitle = intl.formatMessage({
      id: 'organize-sites-delete-group-dialog-title',
      description: 'Title for group deletion',
      defaultMessage: 'Remove Group',
    });
    return isGroupType ? groupTypeTitle : groupTitle;
  }, [intl, isGroupType]);

  const warningMessage = intl.formatMessage({
    id: 'common-delete-warning-message',
    description: 'Warning to let user know the effect deleting this will have',
    defaultMessage: 'Warning, this action cannot be undone.',
  });

  const confirmLabel = intl.formatMessage({
    id: 'common-remove-button',
    description: 'Label for confirmation button',
    defaultMessage: 'Yes, Remove',
  });
  const cancelLabel = intl.formatMessage({
    id: 'common-cancel-button',
    description: 'Label for cancel button',
    defaultMessage: 'Cancel',
  });

  return (
    <Dialog open={open}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DividerContainer>
        <StyledDivider />
      </DividerContainer>
      <StyledDialogContent>
        <Box>
          <AppTypography>
            {isGroupType ? (
              <FormattedMessage
                id="organize-sites-delete-group-type-dialog-content"
                description="Content text for group type deletion"
                defaultMessage="Are you sure you want to remove the group type {groupName}?"
                values={{ groupName: `'${group?.name}'` ?? '' }}
              />
            ) : (
              <FormattedMessage
                id="organize-sites-delete-group-dialog-content"
                description="Content text for group deletion"
                defaultMessage="Are you sure you want to remove the group {groupName}?"
                values={{ groupName: `'${group?.name}'` ?? '' }}
              />
            )}
          </AppTypography>
        </Box>
        <WarningContainer>
          <AppTypography $colorKey="error">{warningMessage}</AppTypography>
        </WarningContainer>
      </StyledDialogContent>
      <DialogActions>
        <Button color="shade" variant="contained" onClick={cancel}>
          {cancelLabel}
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          onClick={() => confirm(group)}
          isLoading={mutation.isLoading}
        >
          {confirmLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
