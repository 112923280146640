import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ReportAggregationEnum } from '@beeriot/api-client';

export function useIntlReportAggregation(): (
  reportAggregation?: ReportAggregationEnum
) => string {
  const intl = useIntl();
  const hourly = intl.formatMessage({
    id: 'report-aggregation-hourly-label',
    defaultMessage: 'Hourly',
    description: 'Label for an hourly report aggregation',
  });
  const daily = intl.formatMessage({
    id: 'report-aggregation-daily-label',
    defaultMessage: 'Daily',
    description: 'Label for an daily report aggregation',
  });
  const weekly = intl.formatMessage({
    id: 'report-aggregation-weekly-label',
    defaultMessage: 'Weekly',
    description: 'Label for an weekly report aggregation',
  });
  const monthly = intl.formatMessage({
    id: 'report-aggregation-monthly-label',
    defaultMessage: 'Montly',
    description: 'Label for an monthly report aggregation',
  });

  return useCallback(
    (reportAggregation?: ReportAggregationEnum) => {
      if (!reportAggregation) return '-';

      const reportAggregationIntlRecord: Record<ReportAggregationEnum, string> =
        {
          [ReportAggregationEnum.Hourly]: hourly,
          [ReportAggregationEnum.Daily]: daily,
          [ReportAggregationEnum.Weekly]: weekly,
          [ReportAggregationEnum.Monthly]: monthly,
        };

      return reportAggregationIntlRecord[reportAggregation];
    },
    [daily, hourly, monthly, weekly]
  );
}
