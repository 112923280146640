import { addDays, addMonths, addWeeks, addYears, parseISO } from 'date-fns';
import { CalendarMode } from '../enums';

export function getEndDate(
  dateStr: string,
  calendarMode: CalendarMode
): string {
  const date = parseISO(dateStr);
  switch (calendarMode) {
    case CalendarMode.Day:
      return addDays(date, 1).toISOString();
    case CalendarMode.Week:
      return addWeeks(date, 1).toISOString();
    case CalendarMode.Month:
      return addMonths(date, 1).toISOString();
    case CalendarMode.Year:
      return addYears(date, 1).toISOString();
  }
}
