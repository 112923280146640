import { axiosHelper } from '../../generated/axiosHelper';
import {
  GQLCompanySite, GQLListCompanySitesQuery,
  GQLListCompanySitesQueryVariables,
  ListCompanySitesDocument
} from '../../generated/gql';
import { siteStore } from '../../state/site/siteStore';
import { removeFalsey, AppQueryClient } from '../../utils';


export async function setToDefaultSite(companyId?: string | null) {
  // if (!companyId) {
  //   return;
  // }
  const s = siteStore.getValue();
  if (!companyId) {
    if (s?.activeSite?.id) {
      siteStore.update((state) => ({ ...state, activeSite: null }));
    }
    return;
  }

  const variables = {
    companyId,
    limit: 1,
  };
  const sites = await AppQueryClient.fetchQuery(
    ['listCompanySites.infinite', variables],
    axiosHelper<GQLListCompanySitesQuery, GQLListCompanySitesQueryVariables>(
      ListCompanySitesDocument
    ).bind(null, variables)
  );

  // const sitesQuery = axiosHelper<
  //   GQLListCompanySitesQuery,
  //   GQLListCompanySitesQueryVariables
  // >(ListCompanySitesDocument).bind(null, variables);
  // const rawSites = await sitesQuery();
  const gqlSites = removeFalsey<GQLCompanySite>(sites.listCompanySites);
  const activeSite = gqlSites.length > 0 ? gqlSites[0] : null;

  if (s?.activeSite?.id !== activeSite?.id) {
    siteStore.update((state) => ({ ...state, activeSite: activeSite }));
  }

  return activeSite;
}
