import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export function useIntlError(): (message: string) => string {
  const intl = useIntl();
  const unexpectedError = intl.formatMessage({
    id: 'api-error-unexpected-error',
    description: 'Unexpected error message',
    defaultMessage: 'An unexpected error occurred',
  });
  const userDoesNotExist = intl.formatMessage({
    id: 'api-error-user-does-not-exist',
    description: 'User does not exist error message',
    defaultMessage: 'User does not exist',
  });
  const incorrectUsernamePassword = intl.formatMessage({
    id: 'api-error-incorrect-username-password',
    description: 'Incorrect username or password error message',
    defaultMessage: 'Incorrect username or password',
  });
  const expiredCode = intl.formatMessage({
    id: 'api-error-expired-code',
    description: 'Expired code error message',
    defaultMessage: 'Invalid code provided, please request a code again.',
  });
  const limitExceeded = intl.formatMessage({
    id: 'api-error-limit-exceeded',
    description: 'Limit exceeded error message',
    defaultMessage: 'Attempt limit exceeded, please try after some time.',
  });

  return useCallback(
    (error: string) => {
      const errorMap = new Map<string, string>([
        ['UserNotFoundException', userDoesNotExist],
        ['NotAuthorizedException', incorrectUsernamePassword],
        ['ExpiredCodeException', expiredCode],
        ['LimitExceededException', limitExceeded],
      ]);

      const errorMessage = errorMap.get(error);
      return errorMessage ?? unexpectedError;
    },
    [
      userDoesNotExist,
      incorrectUsernamePassword,
      expiredCode,
      limitExceeded,
      unexpectedError,
    ]
  );
}
