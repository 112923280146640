import { lazy, Suspense } from 'react';
import { FormattedMessage } from 'react-intl';
import { QueryErrorResetBoundary } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { Button, Stack, styled, Typography } from '@mui/material';
import { LoadingPaper } from '../../../base';

const ErrorContainer = styled(Stack)({
  width: '100%',
  alignItems: 'center',
});

const MessageContainer = styled(Stack)({
  textAlign: 'center',
  width: '30rem',
  margin: '3rem',
  justifyContent: 'center',
});

const UsersSidebarTabLoadingPaper = styled(LoadingPaper)({
  height: '100%',
  width: '100%',
});

const UsersSidebarsTab = lazy(() =>
  import('./UsersSidebarTabsQuery').then(({ UsersSidebarTabsQuery }) => ({
    default: UsersSidebarTabsQuery,
  }))
);

export function SuspenseUsersSidebarTabs() {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ resetErrorBoundary }) => {
            return (
              <ErrorContainer>
                <MessageContainer>
                  <Typography>
                    <FormattedMessage
                      id="settings-user-settings-error-loading-label"
                      description="label for user settings error loading"
                      defaultMessage="There was an error loading users"
                    />
                  </Typography>
                  <Button onClick={() => resetErrorBoundary()}>
                    <FormattedMessage
                      id="common-try-again-button"
                      description="Button for trying an action again"
                      defaultMessage="Try Again"
                    />
                  </Button>
                </MessageContainer>
              </ErrorContainer>
            );
          }}
          onReset={reset}
        >
          <Suspense fallback={<UsersSidebarTabLoadingPaper />}>
            <UsersSidebarsTab />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
