import { createStore, select, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';


export interface PushNotificationState {
  enabled: boolean;
  machineAlarms: boolean;
  progressAlerts: boolean;
  maintenanceWarnings: boolean;
  systemWarnings: boolean;
}

export const pushNotificationStore = createStore(
  { name: 'push-notification-history' },
  withProps<PushNotificationState>({
    enabled: false,
    machineAlarms: false,
    progressAlerts: false,
    maintenanceWarnings: false,
    systemWarnings: false,
  })
);

persistState(pushNotificationStore, {
  key: 'alert-history',
  storage: sessionStorageStrategy,
});

export const enabled$ = pushNotificationStore.pipe(
  select((state) => {
    return state.enabled;
  })
);

export const machineAlarms$ = pushNotificationStore.pipe(
  select((state) => {
    return state.machineAlarms;
  })
);

export const progressAlerts$ = pushNotificationStore.pipe(
  select((state) => {
    return state.progressAlerts;
  })
);

export const maintenanceWarnings$ = pushNotificationStore.pipe(
  select((state) => {
    return state.maintenanceWarnings;
  })
);

export const systemWarnings$ = pushNotificationStore.pipe(
  select((state) => {
    return state.systemWarnings;
  })
);
