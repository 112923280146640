import { styled, Grid, Stack } from '@mui/material';
import { alertStore, SiteGroup } from '@beeriot/api-client';
import { RouterLink } from '../../navigation';
import { SiteCard } from '../SiteCard';
import { SiteCardListSectionHeader } from './SiteCardListSectionHeader';
import { AppTypography } from '../../base';
import { useIntl } from 'react-intl';

const SiteGridSectionsContainer = styled(Grid)({
  padding: '1rem',
});

const SectionHeaderGridItem = styled(Grid)({
  marginBottom: '1rem',
});

const StyledGrid = styled(Grid)({
  width: '100%',
});

export interface SiteCardListProps {
  siteGroups: SiteGroup[];
}

export function SiteCardList({ siteGroups }: SiteCardListProps) {
  const intl = useIntl();
  const noSitesLabel = intl.formatMessage({
    id: 'sites-card-list-no-sites-label',
    description: 'Label for when no sites are available',
    defaultMessage: 'No Sites Found',
  });
  return (
    <SiteGridSectionsContainer
      container
      spacing={3}
      key={`site-group-container`}
    >
      {siteGroups.length === 0 ? (
        <Stack sx={{ alignItems: 'center', width: '100%', marginTop: '2rem' }}>
          <AppTypography $colorKey="lightText" $bold>
            {noSitesLabel}
          </AppTypography>
        </Stack>
      ) : (
        siteGroups.map((siteGroup) => {
          return (
            <SectionHeaderGridItem
              key={`site-group--${siteGroup.name}`}
              item
              xs={12}
            >
              <SectionHeaderGridItem item xs={12}>
                <SiteCardListSectionHeader
                  siteCount={siteGroup.sites.length}
                  siteLetter={siteGroup.name}
                />
              </SectionHeaderGridItem>
              <StyledGrid container item spacing={3} xs={12}>
                {siteGroup.sites.map((site) => (
                  <StyledGrid
                    key={`site-card--${site.id}`}
                    item
                    xs={12}
                    md={6}
                    xl={4}
                  >
                    <RouterLink
                      to={`/companies/${site.companyId}/sites/${site.id}/overview`}
                      onClick={() => {
                        alertStore.update((s) => ({
                          ...s,
                          siteIdFilter: site.id,
                        }));
                      }}
                    >
                      <SiteCard site={site} />
                    </RouterLink>
                  </StyledGrid>
                ))}
              </StyledGrid>
            </SectionHeaderGridItem>
          );
        })
      )}
    </SiteGridSectionsContainer>
  );
}
