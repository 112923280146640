export const AWS_SPECIAL_CHARACTERS = [
  ' ',
  '^',
  '$',
  '*',
  '.',
  '[',
  ']',
  '{',
  '}',
  '(',
  ')',
  '?',
  '"',
  '!',
  '@',
  '#',
  '%',
  '&',
  '/',
  '\\',
  ',',
  '>',
  '<',
  "'",
  ':',
  ';',
  '|',
  '_',
  '~',
  '`',
  '=',
  '+',
  '-',
];
