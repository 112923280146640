import { useMemo } from 'react';
import { KPIGridItem } from '../../kpi-grid/KPIGrid';
import { SystemKpiHookProps } from '../../SystemKpiHookProps';
import { useShouldRenderKpi } from '../../useShouldRenderKpi';
import { KPIVerticalLabels } from '../../kpi-grid/KPIVerticalLabel';
import { formatUnit } from '../../../../../../utils';
import { FormattedMessage } from 'react-intl';
import { ThresholdValue } from '../../../../../../utils/threshold-color/thresholdValue';
import { useThresholdColor } from '../../../../../../utils/threshold-color/useThresholdColor';

export function useNetCapacityGridItem(props: SystemKpiHookProps) {
  const { system, gridArea, NA, boldLabelTitles } = props;
  const shouldRender = useShouldRenderKpi(props);

  const thresholdColor = useThresholdColor(system?.id);

  return useMemo(() => {
    if (!shouldRender) {
      return undefined;
    }

    const labelColor = thresholdColor(
      ThresholdValue.NetCapacity,
      system?.liveKPI?.productionNetCapacityLmh
    );

    const squared = '\u00B2';
    return (
      <KPIGridItem gridArea={gridArea ?? 'netCapacity'}>
        <KPIVerticalLabels
          label={
            system?.liveKPI?.productionNetCapacityLmh != null
              ? formatUnit(
                  Math.trunc(system?.liveKPI?.productionNetCapacityLmh),
                  `L/m${squared}`
                )
              : NA
          }
          labelBold
          subLabel={
            <FormattedMessage
              id="common-net-capacity-label"
              defaultMessage="Net Capacity"
              description="net capacity label"
            />
          }
          labelColorKey={labelColor}
        />
      </KPIGridItem>
    );
  }, [
    shouldRender,
    thresholdColor,
    system?.liveKPI?.productionNetCapacityLmh,
    gridArea,
    NA,
  ]);
}
