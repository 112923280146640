import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { AppTypography, ConfirmDeleteButton } from '../../base';
import { GQLReportDefinition } from '@beeriot/api-client';

export interface ConfirmDeleteAutomaticReportButtonProps {
  selectedReport: GQLReportDefinition;
  onDelete: () => void;
  buttonTitle?: ReactNode;
  startIcon?: ReactNode;
}

export const ConfirmDeleteAutomaticReportButton = ({
  selectedReport,
  onDelete,
  buttonTitle,
  startIcon,
}: ConfirmDeleteAutomaticReportButtonProps) => {
  return (
    <ConfirmDeleteButton
      onDelete={onDelete}
      buttonVariant={'text'}
      buttonTitle={buttonTitle}
      startIcon={startIcon}
      dialogTitle={
        <FormattedMessage
          id="recurring-report-dialog-confirm-delete-dialog-title-label"
          description="Label for the recurring report form delete confirmation dialog title"
          defaultMessage="Delete Recurring Report?"
        />
      }
      dialogMessage={
        <AppTypography>
          <FormattedMessage
            id="recurring-report-dialog-confirm-delete-dialog-message-label"
            description="Label for the recurring report form delete confirmation dialog message"
            defaultMessage={`Are you sure you wish to delete the recurring report "{reportName}"?`}
            values={{ reportName: selectedReport.reportName }}
          />
        </AppTypography>
      }
    />
  );
};
