import { Grid, styled, TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import { AppTypography } from '../AppTypography';
import {
  CalendarPickerView,
  LocalizationProvider,
  MobileDatePicker,
} from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const DatePickerControlContainer = styled(Grid)({
  flex: 1,
  flexDirection: 'column',
});
DatePickerControlContainer.defaultProps = {
  container: true,
};

const LabeledControlContainer = styled(Grid)({
  flex: 1,
  alignItems: 'center',
  padding: '0.5rem',
  justifyItems: 'space-between',
});
LabeledControlContainer.defaultProps = {
  container: true,
  item: true,
  columns: 12,
};

const StyledTypography = styled(AppTypography)({
  marginRight: '0.5rem',
});
const StyledTextField = styled(TextField)({
  flex: 1,
});

const LabelGridItem = styled(Grid)({
  flex: 1,
});
LabelGridItem.defaultProps = {
  item: true,
  xs: 4,
};
const InputGridItem = styled(Grid)({
  flex: 1,
});
InputGridItem.defaultProps = {
  item: true,
  xs: 'auto',
};

export interface MobileCombinedDatePickerInputsProps {
  isRanged: boolean;
  startDate: string;
  endDate: string;
  setStartDate: (newDate: string) => void;
  setEndDate: (newDate: string) => void;
  views?: CalendarPickerView[];
}

export function MobileCombinedDatePickerInputs({
  isRanged,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  views,
}: MobileCombinedDatePickerInputsProps) {
  const intl = useIntl();
  const startDateLabel = intl.formatMessage({
    id: 'common-start-date-label',
    description: 'start date label',
    defaultMessage: 'Start Date',
  });
  const endDateLabel = intl.formatMessage({
    id: 'common-end-date-label',
    description: 'end date label',
    defaultMessage: 'End Date',
  });

  return (
    <DatePickerControlContainer columns={{ xs: 1 }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <LabeledControlContainer direction="row">
          <LabelGridItem>
            <StyledTypography variant="body1" $bold $colorKey="lightText">
              {startDateLabel}
            </StyledTypography>
          </LabelGridItem>
          <InputGridItem>
            <MobileDatePicker
              className="startDateInput"
              value={startDate}
              onChange={(newValue) => {
                if (newValue) setStartDate(newValue);
              }}
              views={views}
              renderInput={(params) => <StyledTextField {...params} />}
            />
          </InputGridItem>
        </LabeledControlContainer>
        {isRanged ? (
          <LabeledControlContainer direction="row">
            <LabelGridItem>
              <StyledTypography variant="body1" $bold $colorKey="lightText">
                {endDateLabel}
              </StyledTypography>
            </LabelGridItem>
            <InputGridItem>
              <MobileDatePicker
                className="endDateInput"
                value={endDate}
                onChange={(newValue) => {
                  if (newValue) setEndDate(newValue);
                }}
                views={views}
                renderInput={(params) => <StyledTextField {...params} />}
              />
            </InputGridItem>
          </LabeledControlContainer>
        ) : null}
      </LocalizationProvider>
    </DatePickerControlContainer>
  );
}
