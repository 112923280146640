import { useGQLBlocks, activeBlock$ } from '@beeriot/api-client';
import { useObservable } from '@ngneat/use-observable';
import { activeSystem$ } from '@beeriot/api-client';
import { AppTypography } from '../../../AppTypography';
import { Breadcrumb } from '../../Breadcrumb';

import { BreadcrumbsBlockDropdown } from '../../dropdown';

export function RoutingBlockSelectorBreadcrumb() {
  const [activeSystem] = useObservable(activeSystem$);
  const [activeBlock] = useObservable(activeBlock$);
  const blocks = useGQLBlocks(activeSystem?.id);

  return (
    <Breadcrumb
      $stripeColorKey={'highlight5'}
      $isDropdown={true}
      renderDropdownContent={(open, setOpen) => (
        <BreadcrumbsBlockDropdown
          blocks={blocks}
          onClose={() => setOpen(false)}
        />
      )}
    >
      <AppTypography $bold={true}>
        {activeBlock?.description ?? ''}
      </AppTypography>
    </Breadcrumb>
  );
}
