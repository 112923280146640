import { FormattedMessage } from 'react-intl';
import { KPIVerticalLabels } from '../base-card-common/grids/kpi-grid/KPIVerticalLabel';
import { RunningStatusTimelineView, TimelineData } from '../../../base';
import { styled } from '@mui/material';

export const StyledRunningStatusVerticalLabel = styled(KPIVerticalLabels)({
  width: '100%',
  '& > .top-label > *': {
    width: '100%',
  },
});
export const StyledRunningStatusTimeline = styled(RunningStatusTimelineView)({
  width: '100%',
});

export function RunningStatusKpiElement(props: {
  timelineData: TimelineData[];
}) {
  return (
    <StyledRunningStatusVerticalLabel
      label={<StyledRunningStatusTimeline data={props.timelineData} />}
      subLabel={
        <FormattedMessage
          id="common-running-status-label"
          description="Label for running status"
          defaultMessage="Running Status"
        />
      }
    />
  );
}
