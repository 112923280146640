import { CardContent, Grid, Stack, styled } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  BlockCluster,
  ClusterLiveStatusTestResultEnum,
  ColorKey,
  SettingsSystems,
} from '@beeriot/api-client';
import {
  AppCard,
  AppTypography,
  VerticalLabels,
  FlowGauge,
  CurrentStateLabel,
  RunningStatusView,
  getResponsiveDataForGauge,
} from '../../../base';
import { RunningState, RunningStateColorKey } from '../../../enums';
import {
  formatUnit,
  useResponsiveSizes,
  getEnumFromString,
  formatTimeFromSeconds,
} from '../../../utils';
import { ClusterTestLabel } from './ClusterTestLabel';
import { ThresholdValue } from '../../../utils/threshold-color/thresholdValue';
import { getFlowGaugeInfo } from '../../../utils/getFlowGaugeInfo';
import { useThresholdColorProcessor } from '../../../utils/threshold-color/useThresholdColorProcessor';

const StyledCard = styled(AppCard)({
  flex: '1 0 auto',
});

const StyledCardContent = styled(CardContent)({
  '&:last-child': {
    paddingBottom: '16px',
  },
});

const StackColumn = styled(Stack)({
  marginRight: '1rem',
});

const StyledBoldSpan = styled('span')({ fontWeight: 'bold' });

const StyledClusterStack = styled(Stack)({
  display: 'flex',
  gap: '0.4rem',
});

const TimelineContainer = styled(Stack)({
  padding: '1rem 1rem 1rem 0',
  width: '100%',
});

export interface BlockDetailCardProps {
  blockCluster: BlockCluster;
  systemSettings?: SettingsSystems;
}

export const BlockDetailCard = ({
  blockCluster: { block, clusters },
  systemSettings,
}: BlockDetailCardProps) => {
  const { isMobileSize, isTabletSize } = useResponsiveSizes();
  const getThresholdColor = useThresholdColorProcessor(systemSettings);

  const { maxFlow } = getFlowGaugeInfo(systemSettings);

  const squared = '\u00B2';
  const intl = useIntl();
  const NA = intl.formatMessage({
    id: 'common-not-applicable',
    defaultMessage: 'N/A',
    description: "Label for when there isn't a value to display",
  });
  const netCapacity =
    block?.liveKPI?.estCapacityLm2 != null
      ? formatUnit(Math.trunc(block?.liveKPI?.estCapacityLm2), `L/m${squared}`)
      : NA;
  const netCapacityLabelColor = getThresholdColor(
    ThresholdValue.NetCapacity,
    block?.liveKPI?.estCapacityLm2
  );
  const runningState = getEnumFromString(
    RunningState,
    block?.liveStatus?.sequenceStatus
  );

  const responsiveGaugeInfo = getResponsiveDataForGauge(
    isMobileSize,
    isTabletSize
  );

  return (
    <StyledCard>
      <StyledCardContent>
        <Stack direction="row">
          <StackColumn direction="column">
            <CurrentStateLabel
              state={runningState}
              labelVariant="h6"
              iconSize="lg"
              labelBold
            />
            <VerticalLabels
              label={netCapacity}
              labelBold
              labelColorKey={netCapacityLabelColor}
              subLabel={
                <FormattedMessage
                  id="common-net-capacity-label"
                  defaultMessage="Net Capacity"
                  description="net capacity label"
                />
              }
            />
          </StackColumn>
          <StackColumn direction="column">
            <VerticalLabels
              label={
                runningState === RunningState.Cleaning
                  ? '-'
                  : block?.liveStatus?.timeToMaxdPSeconds != null
                  ? formatTimeFromSeconds(block.liveStatus.timeToMaxdPSeconds)
                  : NA
              }
              labelBold
              labelColorKey={
                runningState === RunningState.Cleaning ? 'black' : 'warning'
              }
              subLabel={
                <FormattedMessage
                  id="site-block-detail-time-to-max"
                  defaultMessage="Time to Max dP"
                  description="sub label for time to max"
                />
              }
            />
            <VerticalLabels
              label={
                runningState === RunningState.Cleaning
                  ? '-'
                  : block?.liveKPI?.tmpBar != null
                  ? formatUnit(block.liveKPI.tmpBar, 'bar')
                  : NA
              }
              labelBold
              labelColorKey={
                runningState === RunningState.Cleaning ? 'black' : 'warning'
              }
              subLabel={
                <FormattedMessage
                  id="common-actual-tmp"
                  defaultMessage="Actual Tmp"
                  description="label for actual tmp"
                />
              }
            />
          </StackColumn>
          <Stack direction="column">
            <FlowGauge
              flow={{
                amount: block?.liveKPI?.flowHlH
                  ? Math.trunc(block.liveKPI.flowHlH)
                  : undefined,
                unit: 'hL/h',
                max: maxFlow,
              }}
              radius={responsiveGaugeInfo.radius}
              labelVariant={responsiveGaugeInfo.labelVariant}
              subLabelVariant={
                isMobileSize || isTabletSize ? 'caption' : 'subtitle2'
              }
              backgroundColor="white"
              gaugeOverrideColorKey="primary"
              gaugeBackgroundOverrideColorKey="disabledPrimary"
              displayLabelAsDash={runningState === RunningState.Cleaning}
            />
          </Stack>
        </Stack>
        {isTabletSize && (
          <TimelineContainer>
            <RunningStatusView
              $colorKey={
                runningState ? RunningStateColorKey[runningState] : 'disabled'
              }
            />
            <AppTypography variant="caption" $colorKey="lightText">
              <FormattedMessage
                id="common-running-status-label"
                description="Label for running status"
                defaultMessage="Running Status"
              />
            </AppTypography>
          </TimelineContainer>
        )}
        {clusters && clusters.length > 0 && (
          <StyledClusterStack direction="column">
            <AppTypography $colorKey="black">
              <StyledBoldSpan>
                <FormattedMessage
                  id="site-block-detail-clusters-last-test-value"
                  defaultMessage="Clusters Last Test Value"
                  description="label for cluster last test value"
                />
              </StyledBoldSpan>
            </AppTypography>
            <Grid container columnSpacing={3} rowSpacing={3}>
              {clusters.map((cluster) => {
                const testResult = getEnumFromString(
                  ClusterLiveStatusTestResultEnum,
                  cluster?.liveStatus?.testResult
                );
                return (
                  <Grid item xs={6} md={6} lg={12} key={cluster.id}>
                    <ClusterTestLabel
                      key={cluster.description}
                      getThresholdColor={getThresholdColor}
                      name={cluster.description ?? NA}
                      result={
                        runningState === RunningState.Cleaning
                          ? '-'
                          : testResult
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </StyledClusterStack>
        )}
      </StyledCardContent>
    </StyledCard>
  );
};
