import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { Stack, Button, Typography, styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { LoadingBox } from '../../../base';

const MessageContainer = styled(Stack)({
  textAlign: 'center',
  width: '30rem',
  margin: '3rem',
  justifyContent: 'center',
});

const ErrorContainer = styled(Stack)({
  width: '100%',
  alignItems: 'center',
});

const SummaryLoadingBox = styled(LoadingBox)({
  height: '25rem',
  width: '35rem',
  margin: '0.5rem',
});

function SummaryLoadingBoxes() {
  return (
    <Stack>
      <Stack direction="row">
        <SummaryLoadingBox />
        <SummaryLoadingBox />
      </Stack>
      <Stack direction="row">
        <SummaryLoadingBox />
        <SummaryLoadingBox />
      </Stack>
    </Stack>
  );
}

const ExecSummaryLinePageContent = lazy(() =>
  import('./ExecSummaryLinePageContentQuery').then(
    ({ ExecSummaryLinePageContentQuery }) => ({
      default: ExecSummaryLinePageContentQuery,
    })
  )
);

export function SuspenseExecSummaryLinePageContent() {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ resetErrorBoundary }) => {
            return (
              <ErrorContainer>
                <MessageContainer>
                  <Typography>
                    <FormattedMessage
                      id="site-details-error-loading-label"
                      description="Label for site details loading error"
                      defaultMessage="There was an error loading site details"
                    />
                  </Typography>
                  <Button onClick={() => resetErrorBoundary()}>
                    <FormattedMessage
                      id="common-try-again-button"
                      description="Button for trying an action again"
                      defaultMessage="Try Again"
                    />
                  </Button>
                </MessageContainer>
              </ErrorContainer>
            );
          }}
          onReset={reset}
        >
          <Suspense fallback={<SummaryLoadingBoxes />}>
            <ExecSummaryLinePageContent />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
