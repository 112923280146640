import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ClusterLiveStatusTestResultEnum, ColorKey } from '@beeriot/api-client';

export function useIntlTestResult(): (
  testResult?: ClusterLiveStatusTestResultEnum
) => string {
  const intl = useIntl();
  const pass = intl.formatMessage({
    id: 'test-result-pass',
    defaultMessage: 'Pass',
    description: 'Label to indicate a passing test result',
  });
  const fail = intl.formatMessage({
    id: 'test-result-fail',
    defaultMessage: 'Fail',
    description: 'Label to indicate a failing test result',
  });
  const NA = intl.formatMessage({
    id: 'common-not-applicable',
    defaultMessage: 'N/A',
    description: "Label for when there isn't a value to display",
  });

  return useCallback(
    (testResult?: ClusterLiveStatusTestResultEnum) => {
      if (!testResult) return NA;
      const testResultIntlRecord: Record<
        ClusterLiveStatusTestResultEnum,
        string
      > = {
        [ClusterLiveStatusTestResultEnum.Passed]: pass,
        [ClusterLiveStatusTestResultEnum.Failed]: fail,
      };
      return testResultIntlRecord[testResult];
    },
    [pass, fail, NA]
  );
}

export const TestResultColorKey: Record<
  ClusterLiveStatusTestResultEnum,
  ColorKey
> = {
  [ClusterLiveStatusTestResultEnum.Passed]: 'success',
  [ClusterLiveStatusTestResultEnum.Failed]: 'error',
};
