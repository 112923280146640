import { createStore, select, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { addMonths } from 'date-fns';
import { GQLFilterRun } from '../generated/gql';

export interface FilterRunsStoreProps {
  selectedFilterRun: GQLFilterRun | null;
  startDate: string;
  endDate: string;
}

const lastMonth = addMonths(new Date(), -1);

export const filterRunsStore = createStore(
  { name: 'filter-runs' },
  withProps<FilterRunsStoreProps>({
    selectedFilterRun: null,
    startDate: lastMonth.toISOString(),
    endDate: new Date().toISOString(),
  })
);

persistState(filterRunsStore, {
  key: 'filter-runs',
  storage: sessionStorageStrategy,
});

export const selectedFilterRun$ = filterRunsStore.pipe(
  select((state) => {
    return state.selectedFilterRun;
  })
);

export const filterRunsStartDate$ = filterRunsStore.pipe(
  select((state) => {
    return state.startDate;
  })
);

export const filterRunsEndDate$ = filterRunsStore.pipe(
  select((state) => {
    return state.endDate;
  })
);
