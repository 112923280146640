import React, { PropsWithChildren, ReactElement, useEffect, } from 'react';
import { useMatches, useParams } from 'react-router-dom';
import { useEffectFn } from '@ngneat/effects-hooks';
import {
  blockStore,
  updateActiveCompanyEffect,
  useGQLBlock,
  useGQLSite,
  useGQLSystem,
  setActiveSite,
  useCompany,
  useActiveAppEntities,
  alertStore,
  setActiveSystem,
  useGetSystemQuery,
  urlLocationStore,
  urlParamsAndActiveEntities$,
  useCompanies,
  usePrefetchBasicData,
  setActiveCompanyId,
} from '@beeriot/api-client';
import { useObservable } from '@ngneat/use-observable';

export const  ParamIdHandler= (props: PropsWithChildren<unknown>) => {
  const updateActiveCompany = useEffectFn(updateActiveCompanyEffect);


  // no-op change to trigger PR
  usePrefetchBasicData();

  
  const [{urlLocationStore, activeEntities}] = useObservable(urlParamsAndActiveEntities$);
  const { siteId, lineId, blockId, companyId } = urlLocationStore;
  const { activeBlock, activeCompany, activeSite, activeSystem } = activeEntities;

  const urlCompany = useCompany(companyId, {suspense: false});
  const urlSite = useGQLSite(siteId, {suspense: false});
  const urlSystem = useGQLSystem({ systemId: lineId, isActive: true }, {suspense: false}); // useGetSystemQuery({systemId: lineId ?? ''}); //
  const urlBlock = useGQLBlock(blockId, {suspense: false});


  useEffect(() => {
    // if (urlCompany && urlCompany.id !== activeCompany?.id) {
      if (urlCompany?.id && urlCompany?.id !== activeCompany?.id) {
        setActiveCompanyId(urlCompany?.id)
        updateActiveCompany(urlCompany ?? null);
    }

    if (urlSite?.id !== activeSite?.id && urlSite?.id !== undefined && urlSite?.id !== 'undefined') {
      setActiveSite(urlSite);
    }

    if (urlSystem && urlSystem.id !== activeSystem?.id) {
      setActiveSystem(urlSystem);
      alertStore.update((s) => ({ ...s, systemIdFilter: urlSystem?.id }));
    }

    if (urlBlock && urlBlock.id !== activeBlock?.id) {
      blockStore.update((state) => ({
        ...state,
        activeBlock: urlBlock,
      }));
    }
  }, [
    urlSystem,
    activeSystem?.id,
    urlSite,
    activeSite?.id,
    urlBlock,
    activeBlock?.id,
    activeCompany?.id,
    companyId,
    updateActiveCompany,
    urlCompany,
  ]);

  return (props?.children ?? null) as ReactElement<unknown> | null;
}



export const  ParamIdReader = (props: PropsWithChildren<unknown>) => {

  const { siteId, lineId, blockId, companyId } = useParams();
  
  useEffect(() => {
    urlLocationStore.update(() => ({
      siteId, lineId, blockId, companyId
    }))
  }, [siteId, lineId, blockId, companyId])


  return (props?.children ?? null) as ReactElement<unknown> | null;
}
