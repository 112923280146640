import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  styled,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { TelemetryDataExport } from '@beeriot/api-client';
import { AppTypography } from '../../base/AppTypography';

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.base.main,
  borderTopRightRadius: theme.shape.borderRadius,
  borderTopLeftRadius: theme.shape.borderRadius,
}));

const ButtonStack = styled(Stack)({
  margin: '2rem 0.5rem 0.5rem 0.5rem',
  gap: '1rem',
  justifyContent: 'center',
});

const FooterStack = styled(Stack)({
  margin: '2rem 0.5rem 0.5rem 0.5rem',
  gap: '1rem',
  justifyContent: 'center',
});

export interface MobileDataExportActionsDialogProps {
  open: boolean;
  onClose: () => void;
  item?: TelemetryDataExport;
  downloadDataExport: (data: TelemetryDataExport | undefined) => void;
  shareDataExport: (data: TelemetryDataExport | undefined) => void;
}

export function MobileDataExportActionsDialog({
  open,
  onClose,
  item,
  downloadDataExport,
  shareDataExport,
}: MobileDataExportActionsDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') onClose();
      }}
      maxWidth="md"
      fullWidth
    >
      <StyledDialogTitle>
        <AppTypography $bold>
          <FormattedMessage
            id="mobile-data-export-actions-dialog-title"
            defaultMessage="Data Export Actions"
            description="Dialog title for the mobile data export actions"
          />
        </AppTypography>
      </StyledDialogTitle>
      <DialogContent>
        <ButtonStack direction="row">
          <Button variant="contained" onClick={() => downloadDataExport(item)}>
            <FormattedMessage
              id="common-download-button"
              description="Button for download"
              defaultMessage="Download"
            />
          </Button>
          <Button variant="contained" onClick={() => shareDataExport(item)}>
            <FormattedMessage
              id="common-share-button"
              description="Button for share"
              defaultMessage="Share"
            />
          </Button>
        </ButtonStack>
      </DialogContent>
      <FooterStack>
        <Button variant="contained" color="shade" onClick={() => onClose()}>
          <FormattedMessage
            id="common-cancel-button"
            description="Label for cancel button"
            defaultMessage="Cancel"
          />
        </Button>
      </FooterStack>
    </Dialog>
  );
}
