import { forwardRef, Suspense, useMemo, Children, cloneElement } from 'react';
import { useIntl } from 'react-intl';
import { useObservable } from '@ngneat/use-observable';
import { activeSystem$, useActiveEntityRouteParams } from '@beeriot/api-client';
import { RouteTab, SidebarTabsButton, SubTabs } from '../navigation';
import {
  DocumentationPanel,
  LineStatisticsPanel,
  LineStatusPanel,
  ReportsPanel,
  FilterRunsPanel,
  TelemetryDataExportPanel,
} from '../lines';
import { PermissionKey, useHasPermission } from '../base/user-permissions';
import { LineSubTabsControls } from './LineSubTabsControls';
import { useResponsiveSizes } from '../utils';

export const LineSubTabs = forwardRef((props, ref) => {
  const intl = useIntl();
  const hasPermission = useHasPermission();
  const [activeSystem] = useObservable(activeSystem$);
  const isCBS = activeSystem?.type === 'cbs';
  const isKeraflux = activeSystem?.type === 'keraflux';

  const routeParams = useActiveEntityRouteParams();

  const { isMobileSize } = useResponsiveSizes();

  const { routes, routeKeys } = useMemo(() => {
    const lineStatusLabel = intl.formatMessage({
      id: 'common-line-status-label',
      description: 'Line status label',
      defaultMessage: 'Line Status',
    });
    const statisticsLabel = intl.formatMessage({
      id: 'common-statistics-label',
      description: 'Statistics label',
      defaultMessage: 'Statistics',
    });
    const batchInfoLabel = intl.formatMessage({
      id: 'common-batch-info-label',
      description: 'Batch Info Label',
      defaultMessage: 'Batch Info',
    });
    const filterRunsLabel = intl.formatMessage({
      id: 'common-filter-runs-label',
      description: 'Filter runs label',
      defaultMessage: 'Filter Runs',
    });
    const documentationLabel = intl.formatMessage({
      id: 'common-documentation-label',
      description: 'Documentation label',
      defaultMessage: 'Documentation',
    });
    const reportsLabel = intl.formatMessage({
      id: 'common-reports-label',
      description: 'Reports label',
      defaultMessage: 'Reports',
    });

    const telemetryDataExportLabel = intl.formatMessage({
      id: 'common-telemetry-data-exports-label',
      description: 'Telemetry Data Export label',
      defaultMessage: 'Telemetry Data Export',
    });

    const routes: JSX.Element[] = [];
    const routeKeys: string[] = [];

    let lineStatusPermissionKey: PermissionKey;
    let reportsPermissionKey: PermissionKey;
    let documentationPermissionKey: PermissionKey;
    let statisticsPermissionKey: PermissionKey;

    switch (activeSystem?.type) {
      case 'profi':
        lineStatusPermissionKey = PermissionKey.SitesPROFiLineStatus;
        reportsPermissionKey = PermissionKey.SitesPROFiReports;
        documentationPermissionKey = PermissionKey.SitesPROFiDocumentation;
        statisticsPermissionKey = PermissionKey.SitesPROFiStatistics;
        break;
      case 'cbs':
        lineStatusPermissionKey = PermissionKey.SitesCBSLineStatus;
        reportsPermissionKey = PermissionKey.SitesCBSReports;
        documentationPermissionKey = PermissionKey.SitesCBSDocumentation;
        statisticsPermissionKey = PermissionKey.SitesCBSStatistics;
        break;
      case 'cfs':
        lineStatusPermissionKey = PermissionKey.SitesCFSLineStatus;
        reportsPermissionKey = PermissionKey.SitesCFSReports;
        documentationPermissionKey = PermissionKey.SitesCFSDocumentation;
        statisticsPermissionKey = PermissionKey.SitesCFSStatistics;
        break;
      case 'keraflux':
        lineStatusPermissionKey = PermissionKey.SitesKerafluxLineStatus;
        reportsPermissionKey = PermissionKey.SitesKerafluxReports;
        documentationPermissionKey = PermissionKey.SitesKerafluxDocumentation;
        statisticsPermissionKey = PermissionKey.SitesKerafluxStatistics;
        break;
      default:
        lineStatusPermissionKey = PermissionKey.SitesPROFiLineStatus;
        reportsPermissionKey = PermissionKey.SitesPROFiReports;
        documentationPermissionKey = PermissionKey.SitesPROFiDocumentation;
        statisticsPermissionKey = PermissionKey.SitesCBSStatistics;
        break;
    }

    routeKeys.push('line-status');
    routes.push(
      <RouteTab
        path="line-status/*"
        params={routeParams}
        label={lineStatusLabel}
        hidden={!hasPermission(lineStatusPermissionKey)}
        element={
          <Suspense>
            <LineStatusPanel />
          </Suspense>
        }
      />
    );

    if (hasPermission(statisticsPermissionKey)) {
      routeKeys.push('statistics');
      routes.push(
        <RouteTab
          path="statistics/*"
          params={routeParams}
          label={statisticsLabel}
          element={<LineStatisticsPanel />}
        />
      );
    }

    routeKeys.push(isKeraflux ? 'batch-info' : 'filter-runs');
    const batchInfoFilterRunPermission = isKeraflux
      ? PermissionKey.SitesKerafluxBatchInfo
      : activeSystem?.type === 'cfs'
      ? PermissionKey.SitesCFSFilterRuns
      : PermissionKey.SitesPROFiFilterRuns;
    routes.push(
      <RouteTab
        path={isKeraflux ? 'batch-info/*' : 'filter-runs/*'}
        params={routeParams}
        hidden={!(!isCBS && hasPermission(batchInfoFilterRunPermission))}
        label={isKeraflux ? batchInfoLabel : filterRunsLabel}
        element={<FilterRunsPanel />}
      />
    );

    if (hasPermission(documentationPermissionKey)) {
      routeKeys.push('documentation');
      routes.push(
        <RouteTab
          path="documentation/*"
          params={routeParams}
          label={documentationLabel}
          element={<DocumentationPanel />}
        />
      );
    }
    if (hasPermission(reportsPermissionKey)) {
      routeKeys.push('reports');
      routes.push(
        <RouteTab
          path="reports/*"
          params={routeParams}
          label={reportsLabel}
          element={<ReportsPanel />}
        />
      );
    }
    //To-Do: Add permissions for telemetry data export tab
    routeKeys.push('telemetry-data-export');
    routes.push(
      <RouteTab
        path="telemetry-data-export/*"
        params={routeParams}
        label={telemetryDataExportLabel}
        element={<TelemetryDataExportPanel />}
      />
    );

    return {
      routes: Children.map(routes, (child, idx) =>
        cloneElement(child, {
          buttonElement: isMobileSize ? (
            <SidebarTabsButton
              $isMobile={isMobileSize}
              $showViewingIndicator={true}
            />
          ) : undefined,
        })
      ),
      routeKeys,
    };
  }, [
    intl,
    activeSystem?.type,
    routeParams,
    hasPermission,
    isKeraflux,
    isCBS,
    isMobileSize,
  ]);

  return (
    <SubTabs
      ref={ref}
      tabsKey="lines"
      key="lines"
      routes={routes}
      renderControls={(activeIdx: number) => (
        <LineSubTabsControls
          routeKey={routeKeys[activeIdx]}
          systemType={activeSystem?.type ?? ''}
        />
      )}
    />
  );
});
