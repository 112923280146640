import { FormattedMessage } from 'react-intl';
import { AppTableHeader } from '../../base';
import { useResponsiveSizes } from '../../utils';
import { TelemetryDataExportButtons } from './TelemetryDataExportButtons';
import styled from '@emotion/styled';
import { Stack } from '@mui/material';

export const ControlsContainer = styled(Stack)({
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'flex-end',
  margin: '0 1rem',
});

interface TelemetryDataExportPanelHeaderProps {
  showNewDataExportForm: () => void;
}

export const TelemetryDataExportPanelHeader = ({
  showNewDataExportForm,
}: TelemetryDataExportPanelHeaderProps) => {
  const { isDesktopSize } = useResponsiveSizes();
  return (
    <AppTableHeader
      label={
        <FormattedMessage
          id="telemetry-data-export-table-header-title"
          defaultMessage="Telemetry Data Export Repository"
          description="header for telemetry data export table"
        />
      }
    >
      <ControlsContainer>
        {isDesktopSize && (
          <TelemetryDataExportButtons
            showNewDataExportForm={showNewDataExportForm}
          />
        )}
      </ControlsContainer>
    </AppTableHeader>
  );
};
