import { betterForwardRef } from './../utils/betterForwardRef';
import { ReactNode } from 'react';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { Stack } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { Property } from 'csstype';
import { ColorKey } from '@beeriot/api-client';
import { IconLabel } from './IconLabel';
import { TextWrapProps } from './TextWrapProps';

export interface VerticalLabelsProps extends TextWrapProps {
  label: ReactNode;
  labelBold?: boolean;
  labelVariant?: Variant;
  labelColorKey?: ColorKey;
  labelIcon?: IconProp;
  labelIconSize?: SizeProp;
  labelIconColorKey?: ColorKey;
  subLabel: ReactNode;
  subLabelBold?: boolean;
  subLabelVariant?: Variant;
  subLabelColorKey?: ColorKey;
  subLabelIcon?: IconProp;
  subLabelIconSize?: SizeProp;
  subLabelIconColorKey?: ColorKey;
  justifyContent?: Property.JustifyContent;
  textAlign?: Property.TextAlign;
  $lineHeight?: Property.LineHeight;
  ellipsize?: boolean;
  lineClamp?: Property.WebkitLineClamp;
  className?: string;
}

export const VerticalLabels = betterForwardRef<
  HTMLDivElement,
  VerticalLabelsProps
>((props: VerticalLabelsProps, ref) => {
  const {
    label,
    labelBold,
    labelVariant = 'h6',
    labelColorKey = 'darkText',
    labelIcon,
    labelIconSize,
    labelIconColorKey = labelColorKey,
    subLabel,
    subLabelBold,
    subLabelVariant = 'subtitle2',
    subLabelColorKey = 'subtitleText',
    subLabelIcon,
    subLabelIconSize,
    subLabelIconColorKey = subLabelColorKey,
    justifyContent,
    textAlign,
    className,
    $flexWrap,
    $whiteSpace,
    $wordWrap,
    $lineHeight,
    ellipsize,
    lineClamp,
  } = props;
  return (
    <Stack className={className} ref={ref}>
      <IconLabel
        className="top-label"
        label={label}
        bold={labelBold}
        colorKey={labelColorKey}
        variant={labelVariant}
        justifyContent={justifyContent}
        textAlign={textAlign}
        icon={labelIcon}
        iconSize={labelIconSize}
        iconColorKey={labelIconColorKey}
        $flexWrap={$flexWrap}
        $wordWrap={$wordWrap}
        $whiteSpace={$whiteSpace}
        $lineHeight={$lineHeight ?? 1.3}
        ellipsize={ellipsize}
        lineClamp={lineClamp}
      />
      <IconLabel
        className="sub-label"
        label={subLabel}
        bold={subLabelBold}
        colorKey={subLabelColorKey}
        variant={subLabelVariant}
        justifyContent={justifyContent}
        textAlign={textAlign}
        icon={subLabelIcon}
        iconSize={subLabelIconSize}
        iconColorKey={subLabelIconColorKey}
        $flexWrap={$flexWrap}
        $wordWrap={$wordWrap}
        $whiteSpace={$whiteSpace}
        $lineHeight={$lineHeight ?? 1.3}
        ellipsize={ellipsize}
        lineClamp={lineClamp}
      />
    </Stack>
  );
});
VerticalLabels.displayName = 'VerticalLabels';
