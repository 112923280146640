import { Box, BoxProps, ButtonBase, styled } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { ColorKey } from '@beeriot/api-client';
import { AppIcon } from '../base/AppIcon';
import { AppTypography } from '../base/AppTypography';
import { transientOptions } from '../utils/transientOptions';

const HeaderBox = styled(
  Box,
  transientOptions
)<BoxProps & { $colorKey: ColorKey }>(({ theme, $colorKey }) => {
  return {
    display: 'grid',
    gridTemplateColumns: '1fr repeat(1, auto) 1fr',
    justifyItems: 'center',
    backgroundColor: theme.palette[$colorKey].main,
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
    marginBottom: '0.5rem',
    padding: '0.5rem',
  };
});

const HeaderTypography = styled(AppTypography)({
  margin: 'auto',
  gridColumnStart: 2,
  textAlign: 'center',
});

const CloseButton = styled(ButtonBase)({
  marginLeft: 'auto',
  padding: '0.5rem 1rem',
});

interface DialogHeaderProps {
  colorKey: ColorKey;
  label?: string;
  labelColorKey?: ColorKey;
  headerVariant?: Variant;
  onClose: () => void;
}

export function DialogHeader({
  colorKey,
  label,
  labelColorKey,
  headerVariant = 'h5',
  onClose,
}: DialogHeaderProps) {
  return (
    <HeaderBox $colorKey={colorKey}>
      <HeaderTypography
        $colorKey={labelColorKey ?? 'white'}
        variant={headerVariant}
      >
        {label}
      </HeaderTypography>
      <CloseButton onClick={onClose}>
        <AppIcon size="2x" icon="close" $colorKey={labelColorKey ?? 'white'} />
      </CloseButton>
    </HeaderBox>
  );
}
