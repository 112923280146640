import { useMemo } from 'react';
import { KPIGridItem } from '../../kpi-grid/KPIGrid';
import { SystemKpiHookProps } from '../../SystemKpiHookProps';
import { useShouldRenderKpi } from '../../useShouldRenderKpi';
import { KPIVerticalLabels } from '../../kpi-grid/KPIVerticalLabel';
import { FormattedMessage } from 'react-intl';
import { useThresholdColor } from '../../../../../../utils/threshold-color/useThresholdColor';
import { ThresholdValue } from '../../../../../../utils/threshold-color/thresholdValue';

export function useCommonDegreeOfEfficiencyGridItem(props: SystemKpiHookProps) {
  const { system, gridArea, NA, boldLabelTitles } = props;
  const shouldRender = useShouldRenderKpi(props);
  const thresholdColor = useThresholdColor(system?.id);

  return useMemo(() => {
    if (!shouldRender) {
      return undefined;
    }
    const labelColor = thresholdColor(
      ThresholdValue.DegreeOfEfficiency,
      system?.liveKPI?.efficiencyPct
    );

    return (
      <KPIGridItem gridArea={gridArea ?? 'commonDegreeOfEfficiency'}>
        <KPIVerticalLabels
          label={
            system?.liveKPI?.efficiencyPct != null
              ? `${system?.liveKPI?.efficiencyPct}%`
              : NA
          }
          labelBold
          labelColorKey={labelColor}
          subLabel={
            <FormattedMessage
              id="common-degree-of-efficiency"
              defaultMessage="Degree of Efficiency (MEBAK)"
              description="Label for degree of efficiency"
            />
          }
        />
      </KPIGridItem>
    );
  }, [
    shouldRender,
    thresholdColor,
    system?.liveKPI?.efficiencyPct,
    gridArea,
    NA,
  ]);
}
