import { Stack, styled } from '@mui/material';
import {
  AppBreadcrumbsContainer,
  ControlBox,
  RoutingBreadcrumbs,
} from '../../base';

const ControlContainer = styled(Stack)({
  width: '100%',
});

export function BlockDetailsControlBox() {
  return (
    <ControlContainer direction="row">
      <AppBreadcrumbsContainer>
        <RoutingBreadcrumbs />
      </AppBreadcrumbsContainer>
      <ControlBox />
    </ControlContainer>
  );
}
