import { Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export interface EmbeddedPdfProps {
  url?: string;
}

export function EmbeddedPdf({ url }: EmbeddedPdfProps) {
  return (
    <object data={url} type="application/pdf" height={window.innerHeight}>
      <Box textAlign="center" fontSize={20} marginY={5}>
        <FormattedMessage
          id="pdf-display-fallback"
          description="Fallback message for bro"
          defaultMessage="This browser does not support embedded PDFs. Please download the PDF from the table."
        />
      </Box>
    </object>
  );
}
