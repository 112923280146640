import { useIntl } from 'react-intl';
import { AppDrawer } from '../../base';
import { DrawerType } from '../../enums';
import { SuspenseBlockDetailsSidebar } from './SuspenseBlockDetailsSidebar';

export function BlockDetailsSidebarDrawer() {
  const intl = useIntl();
  const headerLabel = intl.formatMessage({
    id: 'block-details-sidebar-header',
    defaultMessage: 'Block Status',
    description: 'Label for block status',
  });

  return (
    <AppDrawer
      type={DrawerType.BlockDetails}
      headerLabel={headerLabel}
      drawerContent={<SuspenseBlockDetailsSidebar />}
      $width="25rem"
    />
  );
}
