import { useMemo } from 'react';
import { KPIGridItem } from '../../kpi-grid/KPIGrid';
import { KPIVerticalLabels } from '../../kpi-grid/KPIVerticalLabel';
import { FormattedMessage } from 'react-intl';
import { SystemKpiHookProps } from '../../SystemKpiHookProps';
import { useShouldRenderKpi } from '../../useShouldRenderKpi';
import { formatUnit } from '../../../../../../utils/formatUnits';

export function useRemainingVolumeLabelGridItem(props: SystemKpiHookProps) {
  const { system, NA, boldLabelTitles, gridArea } = props;
  const shouldRender = useShouldRenderKpi(props);

  return useMemo(() => {
    if (!shouldRender) {
      return undefined;
    }

    return (
      <KPIGridItem gridArea={gridArea ?? 'remainingVolume'}>
        <KPIVerticalLabels
          label={
            system?.liveStatus?.productionRemainingVolumeHl != null
              ? formatUnit(
                  Math.trunc(system.liveStatus.productionRemainingVolumeHl),
                  'hL'
                )
              : NA
          }
          labelBold={boldLabelTitles}
          labelColorKey="success"
          subLabel={
            <FormattedMessage
              id="site-line-detail-remaining-volume"
              defaultMessage="Remaining Volume"
              description="sub label for remaining volume"
            />
          }
        />
      </KPIGridItem>
    );
  }, [
    shouldRender,
    gridArea,
    system?.liveStatus?.productionRemainingVolumeHl,
    NA,
    boldLabelTitles,
  ]);
}
