import { Button, Stack, styled } from '@mui/material';
import { GQLCompanySite } from '@beeriot/api-client';
import { AppCard, AppIcon, VerticalLabels } from '../../base';

const StyledButton = styled(Button)({
  padding: '0.5rem',
  minWidth: '2rem',
});

const DeleteButton = styled(StyledButton)({
  marginLeft: 'auto',
});

const StyledCard = styled(AppCard)({
  padding: '0.5rem 1rem',
  minWidth: '20rem',
});

const StackContainer = styled(Stack)({
  gap: '0.5rem',
});

export interface EditSiteCardProps {
  site: GQLCompanySite;
  setIsDeleteModalOpen: (isOpen: boolean) => void;
  setIsEditModalOpen: (isOpen: boolean) => void;
}

export function EditSiteCard({
  site,
  setIsDeleteModalOpen,
  setIsEditModalOpen,
}: EditSiteCardProps) {
  return (
    <StyledCard>
      <StackContainer direction="row">
        <StyledButton onClick={() => setIsEditModalOpen(true)}>
          <AppIcon $colorKey="info" icon={['fas', 'pencil']} size="sm" />
        </StyledButton>
        <VerticalLabels
          label={site.name}
          labelVariant={'body1'}
          subLabel={site?.address?.state}
          subLabelVariant={'subtitle2'}
          subLabelIcon={'location-dot'}
        />
        <DeleteButton onClick={() => setIsDeleteModalOpen(true)}>
          <AppIcon $colorKey="error" icon={['far', 'circle-minus']} size="1x" />
        </DeleteButton>
      </StackContainer>
    </StyledCard>
  );
}
