import { FC, PropsWithChildren, useCallback, useState } from 'react';
import { RouteTabObject } from '../models/RouteTabObject';
import { TabsContext, TabsContextState } from './TabsContext';

const defaultState: TabsContextState = {
  tabs: {},
  setLastRouteMatchForTabsNamed: (props: {
    tabsName: string;
    route?: string | undefined;
    routes: RouteTabObject[];
    activeIdx?: number;
  }) => undefined,
};

export type TabsProviderProps = PropsWithChildren<{
  tabsState?: TabsContextState;
}>;

export const TabsProvider: FC<TabsProviderProps> = ({
  children,
  tabsState,
}: TabsProviderProps) => {
  const [state, setState] = useState(tabsState ?? defaultState);
  const setLastRouteMatchForTabsNamed = useCallback(({
    tabsName,
    route,
    routes,
    activeIdx,
  }: {
    tabsName: string;
    route?: string | undefined;
    routes: RouteTabObject[];
    activeIdx?: number;
  }) => {
    const tabBarState = state?.tabs[tabsName] ?? {
      lastRouteMatch: undefined,
      lastRoutesMatches: routes.map((r) => r.path),
    };
    // only update state if the last match is actually new
    if (tabBarState.lastRouteMatch !== route) {
      tabBarState.lastRouteMatch = route;
      if (activeIdx) tabBarState.lastRoutesMatches[activeIdx] = route;
      setState((s) => {
        return {
          ...s,
          tabs: {
            ...s.tabs,
            [tabsName]: tabBarState,
          },
        };
      });
    }
  },[state?.tabs])
  return (
    <TabsContext.Provider
      value={{
        ...state,
        setLastRouteMatchForTabsNamed,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
};
