import { siteStore } from '@beeriot/api-client';
import { styled } from '@mui/material';
import { ControlBox } from '../../base';
import { Form, SelectOption, SuspenseSiteDropdown } from '../../form';

const SiteSelectionForm = styled(Form)({
  display: 'flex',
  gap: '2rem',
  height: '100%',
}) as typeof Form;

interface SiteSelectionFormFields {
  site: SelectOption;
}

export const SiteSelectionHeader = () => {
  return (
    <ControlBox>
      <SiteSelectionForm<SiteSelectionFormFields>
        submitOnChange
        onSubmit={(data) => {
          if (data.site !== undefined) {
            siteStore.update((state) => ({
              ...state,
              activeSite: data.site.data,
            }));
          }
        }}
      >
        <SuspenseSiteDropdown />
      </SiteSelectionForm>
    </ControlBox>
  );
};
