import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { Stack, Button, Typography, styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { SystemLoadingPaper } from './SystemLoadingPaper';

const MessageContainer = styled(Stack)({
  textAlign: 'center',
  width: '30rem',
  margin: '3rem',
  justifyContent: 'center',
});

const ErrorContainer = styled(Stack)({
  width: '100%',
  alignItems: 'center',
});

const SystemsList = lazy(() =>
  import('./SystemsListQuery').then(({ SystemsListQuery }) => ({
    default: SystemsListQuery,
  }))
);

export function SuspenseSystemsList() {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ resetErrorBoundary }) => {
            return (
              <ErrorContainer>
                <MessageContainer>
                  <Typography>
                    <FormattedMessage
                      id="systems-error-loading-label"
                      description="Label for systems loading error"
                      defaultMessage="There was an error loading systems"
                    />
                  </Typography>
                  <Button onClick={() => resetErrorBoundary()}>
                    <FormattedMessage
                      id="common-try-again-button"
                      description="Button for trying an action again"
                      defaultMessage="Try Again"
                    />
                  </Button>
                </MessageContainer>
              </ErrorContainer>
            );
          }}
          onReset={reset}
        >
          <Suspense fallback={<SystemLoadingPaper />}>
            <SystemsList />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
