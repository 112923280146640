import { Box, Stack, styled } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { Property } from 'csstype';
import { AppTypography } from '../../base/AppTypography';
import { ReactComponent as PallSVG } from '../../../assets/Pall.svg';

const StyledTypograpy = styled(AppTypography)({
  whiteSpace: 'nowrap',
});

const StyledStack = styled(Stack)({
  alignItems: 'center',
  columnGap: '1rem',
});
const StyledIconWrapper = styled(Box)<{ $logoWidth?: Property.Width }>(
  ({ $logoWidth }) => ({
    alignItems: 'center',
    width: $logoWidth,
  })
);

interface PallBeerIotLogoProps {
  variant?: Variant;
  logoWidth?: Property.Width;
}

export function PallBeerIotLogo({
  variant = 'h5',
  logoWidth = '6rem',
}: PallBeerIotLogoProps) {
  return (
    <StyledStack direction="row">
      <StyledIconWrapper $logoWidth={logoWidth}>
        <PallSVG width={'100%'} />
      </StyledIconWrapper>
      <StyledTypograpy variant={variant} $colorKey="white">
        Beer
        <AppTypography
          component="span"
          variant={variant}
          $bold
          $colorKey="white"
        >
          IoT
        </AppTypography>{' '}
        Systems
      </StyledTypograpy>
    </StyledStack>
  );
}
