import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useObservable } from '@ngneat/use-observable';
import { Box, Stack, styled } from '@mui/material';
import {
  GQLSystem,
  systemStore,
  useUpdateSystem,
  activeSystem$,
} from '@beeriot/api-client';
import { AppTypography } from '../../base';
import { Form, FormFooterButtons } from '../../form';
import {
  SectionControl,
  SectionLabel,
  SectionTextInput,
} from '../../settings/settings-form';

const maxDate = new Date().toISOString().slice(0, 10);

const StyledFormFooterButtons = styled(FormFooterButtons)({
  padding: '1rem',
  width: '100%',
});

const WarningContainer = styled(Box)({
  padding: '1rem',
});

const mapDefaultValues = (
  system?: GQLSystem
): EditSystemFormFields | undefined => {
  if (system === undefined) return undefined;

  const deploymentDate = system.deploymentDate
    ? system.deploymentDate.slice(0, 10)
    : '';

  const defaultValues: EditSystemFormFields = {
    name: system.name ?? '',
    serialNumber: system.serialNumber ?? '',
    deploymentDate: deploymentDate,
  };

  return defaultValues;
};

export interface EditSystemFormFields {
  name?: string;
  serialNumber?: string;
  deploymentDate?: string;
}

export interface EditSystemFormProps {
  system: GQLSystem;
  onClose: () => void;
}

export const EditSystemForm = ({ system, onClose }: EditSystemFormProps) => {
  const intl = useIntl();
  const [activeSystem] = useObservable(activeSystem$);
  const defaultValues = useMemo(() => mapDefaultValues(system), [system]);
  const updateSystem = useUpdateSystem(
    (updatedSystem) => {
      if (updatedSystem?.id && updatedSystem.id === activeSystem?.id) {
        systemStore.update((state) => ({
          ...state,
          activeSystem: { ...state.activeSystem, ...updatedSystem },
        }));
      }
      onClose();
    },
    (err: string) => {
      console.log('updateSystem: onError: ', err);
    }
  );

  const saveButtonLabel = intl.formatMessage({
    id: 'common-save-button',
    description: 'Save button',
    defaultMessage: 'Save',
  });

  const onSubmit = (fields: EditSystemFormFields) => {
    const deploymentDate = fields.deploymentDate
      ? new Date(fields.deploymentDate).toISOString()
      : '';

    updateSystem.mutate({
      ...system,
      name: fields.name,
      serialNumber: fields.serialNumber,
      deploymentDate: deploymentDate,
    });
  };

  return (
    <Form<EditSystemFormFields>
      onSubmit={onSubmit}
      formProps={{ defaultValues: defaultValues }}
    >
      <Stack>
        <SectionControl>
          <SectionLabel>
            <FormattedMessage
              id="edit-system-name-label"
              defaultMessage="System Name"
              description="label for system name"
            />
          </SectionLabel>
          <SectionTextInput
            name="name"
            required
            placeholder={intl.formatMessage({
              id: 'edit-system-name-placeholder',
              defaultMessage: 'Enter System Name',
              description: 'label for system name placeholder',
            })}
          />
        </SectionControl>
        <SectionControl>
          <SectionLabel>
            <FormattedMessage
              id="edit-system-serial-number-label"
              defaultMessage="Serial Number"
              description="label for system serial number"
            />
          </SectionLabel>
          <SectionTextInput
            name="serialNumber"
            required
            placeholder={intl.formatMessage({
              id: 'edit-system-serial-number-placeholder',
              defaultMessage: 'Enter Serial Number',
              description: 'label for system serial number placeholder',
            })}
          />
        </SectionControl>
        <SectionControl>
          <SectionLabel>
            <FormattedMessage
              id="edit-system-deployment-date-label"
              defaultMessage="Deployment Date"
              description="label for system deployment date"
            />
          </SectionLabel>
          <SectionTextInput
            name="deploymentDate"
            type="date"
            inputProps={{ max: maxDate }}
            required
            placeholder={intl.formatMessage({
              id: 'edit-system-deployment-date-placeholder',
              defaultMessage: 'Enter Deployment Date',
              description: 'label for system deployment date placeholder',
            })}
          />
        </SectionControl>
        {updateSystem.isError && (
          <WarningContainer>
            <AppTypography $colorKey="error">
              <FormattedMessage
                id="common-error-message-label"
                defaultMessage="An error occured"
                description="label for standard error message"
              />
            </AppTypography>
          </WarningContainer>
        )}
        <StyledFormFooterButtons
          justify="center"
          requireCancelConfirmation={false}
          submitLabel={saveButtonLabel}
          isLoading={updateSystem.isLoading}
          onCancel={onClose}
        />
      </Stack>
    </Form>
  );
};
