import { Grid, styled } from '@mui/material';
import { SiteKPIHeader } from '../../../sites/site-kpi-header';
import { SuspenseSystemsList } from './systems-list/SuspenseSystemsList';

const SiteGridContainer = styled(Grid)({
  width: '100%',
  padding: '2rem 0 2rem 1rem',
});

export function SiteDetailsContent() {
  return (
    <SiteGridContainer container spacing={3} key={`site-grid-container`}>
      <SiteKPIHeader />
      <SuspenseSystemsList />
    </SiteGridContainer>
  );
}
