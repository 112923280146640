import { useMemo } from 'react';
import { ThresholdValue } from '../../../../../../utils/threshold-color/thresholdValue';
import { useThresholdColor } from '../../../../../../utils/threshold-color/useThresholdColor';
import { KPIGridItem } from '../../kpi-grid/KPIGrid';
import { KPIVerticalLabels } from '../../kpi-grid/KPIVerticalLabel';
import { FormattedMessage } from 'react-intl';
import { SystemKpiHookProps } from '../../SystemKpiHookProps';
import { useShouldRenderKpi } from '../../useShouldRenderKpi';

export function useOperationalClustersLabelGridItem(props: SystemKpiHookProps) {
  const { system, NA, boldLabelTitles, gridArea, intl } = props;
  const shouldRender = useShouldRenderKpi(props);
  const thresholdColor = useThresholdColor(system?.id);

  return useMemo(() => {
    if (!shouldRender) {
      return undefined;
    }
    const operationalClusterPercent =
      system?.liveStatus?.installedClusters === 0
        ? 0
        : system?.liveStatus?.operationalClusters != null &&
          system?.liveStatus?.installedClusters != null
        ? system?.liveStatus.operationalClusters /
          system?.liveStatus.installedClusters
        : undefined;

    const labelColor = thresholdColor(
      ThresholdValue.OperationalCluster,
      operationalClusterPercent
    );

    return (
      <KPIGridItem gridArea={gridArea ?? 'operationalClusters'}>
        <KPIVerticalLabels
          label={
            operationalClusterPercent != null &&
            system?.liveStatus?.operationalClusters != null
              ? `${intl.formatNumber(
                  system?.liveStatus.operationalClusters
                )} (${(operationalClusterPercent * 100).toFixed(2)}%)`
              : NA
          }
          labelBold={boldLabelTitles}
          labelColorKey={labelColor}
          subLabel={
            <FormattedMessage
              id="common-operational-clusters-label"
              description="label for operational clusters"
              defaultMessage="Operational Clusters"
            />
          }
        />
      </KPIGridItem>
    );
  }, [
    shouldRender,
    system?.liveStatus?.installedClusters,
    system?.liveStatus?.operationalClusters,
    thresholdColor,
    gridArea,
    intl,
    NA,
    boldLabelTitles,
  ]);
}
