import React, { ReactNode } from 'react';
import { Stack, styled } from '@mui/material';

const StyledControlContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  borderBottom: 'solid 1px',
  borderColor: theme.palette.grey[300],
}));

export interface SectionVerticalControlProps {
  children: ReactNode;
}

export function SectionVerticalControl({
  children,
}: SectionVerticalControlProps) {
  return (
    <StyledControlContainer direction="column">
      {children}
    </StyledControlContainer>
  );
}
