import { SystemStatsCategory } from '../../../enums';
import { GQLGetSystemStatsQuery } from '../../../generated/gql';
import { LineStatisticsGroup } from '../lineStatistics';
import { createComparableSection } from './createComparableSection';
import { processSystemStatsSection } from './processSystemStatsSection';

export function getCFSStatisticsGroups(
  processQuery?: GQLGetSystemStatsQuery,
  regenerationQuery?: GQLGetSystemStatsQuery
): LineStatisticsGroup[] {
  const process = createComparableSection(
    [
      'totalColdFilteredBeer',
      'averageFlow',
      'degreeOfEfficiency',
      'totalCIPS',
      'fluxRate',
      'beerLossByFilterLoss',
      'sanitationCycles',
      'causticCycles',
      'enzymaticCycles',
    ],
    processQuery
  );
  const processGroup = processSystemStatsSection(
    SystemStatsCategory.ProcessData,
    process
  );

  const regeneration = createComparableSection(
    ['water', 'daWater', 'caustic', 'acidicChemical', 'enzymaticConsumption'],
    regenerationQuery
  );
  const regenerationGroup = processSystemStatsSection(
    SystemStatsCategory.Regeneration,
    regeneration
  );

  const groups: LineStatisticsGroup[] = [processGroup, regenerationGroup];

  return groups;
}
