import { styled } from '@mui/material';
import { JSXElementConstructor, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { AppTypography } from '../../base';
import { SidebarTabs } from '../../navigation';
import { RouteTabObject } from '../../navigation/tabs/models/RouteTabObject';
import { RouterRedirect } from '../RouterRedirect';
import { SettingsFooter } from './SettingsFooter';
import { useSettingsSidebarTabRoutes } from './useSettingsSidebarTabRoutes';

const SettingsHeader = styled(AppTypography)({
  padding: '0 0.5rem',
});

export const SettingsSidebarTabs = () => {
  const intl = useIntl();
  const routes = useSettingsSidebarTabRoutes() as ReactElement<
    RouteTabObject,
    JSXElementConstructor<RouteTabObject>
  >[];

  return (
    <>
      <RouterRedirect index replace to={'account'} />
      <SidebarTabs
        breadcrumbMaxDetail="settings"
        parentPageTitle={intl.formatMessage({
          id: 'common-settings-label',
          description: 'Settings label',
          defaultMessage: 'Settings',
        })}
        dropdownPlaceholderText={intl.formatMessage({
          id: 'settings-dropdown-pick-setting',
          description: 'Mobile settings nav menu dropdown placeholder text',
          defaultMessage: 'Pick a setting',
        })}
        header={
          <SettingsHeader $bold variant="h6">
            {intl.formatMessage({
              id: 'common-settings-label',
              description: 'Settings label',
              defaultMessage: 'Settings',
            })}
          </SettingsHeader>
        }
        footer={<SettingsFooter />}
        tabsKey="settings"
        routes={routes}
      />
    </>
  );
};
