import { Stack, styled } from '@mui/material';
import { cloneElement, useMemo, useRef } from 'react';
import { from, firstValueFrom, merge } from 'rxjs';
import {
  concatMap,
  filter,
  last,
  map,
  scan,
  share,
  skipLast,
  toArray,
} from 'rxjs/operators';
import { useRouteContext } from '../../../navigation/hooks';
import { useMemoAsync } from '../../../utils/hooks';
import { BreadcrumbProps } from '../Breadcrumb';

const StyledBreadcrumbs = styled(Stack)(({ theme }) => ({
  width: '100%',
  justifyContent: 'flex-start',
  boxShadow: theme.shadows[3],
  backgroundColor: theme.palette.componentBase.main,
}));

export type RoutingBreadcrumbsProps = {
  didSelectLastBreadCrumb?: () => void;
} & Partial<BreadcrumbProps>;

export function RoutingBreadcrumbs({
  didSelectLastBreadCrumb,
}: RoutingBreadcrumbsProps) {
  const { matches, ...restOfRouteContext } = useRouteContext();
 const runs = useRef(0);

  const matchesHashStr = from(matches ?? []).pipe(
    filter((r) => r?.route?.handle?.breadcrumbs),
    map((r) => r?.route?.path),
    scan((acc, value) => {
      return acc.concat(value ?? '', '-')
    }, ''),
    toArray()
  ).syncFirstResult()

  const breadcrumbComponents = useMemo(() => {
    try {
      runs.current = runs.current + 1;
      // collect breadcrumb components from routes
      // and make a flattened list
      const matches$ = from(matches ?? []).pipe(
        filter((r) => r?.route?.handle?.breadcrumbs),
        concatMap((r) => {
          let _maybeBreadcrumbs =
            (r.route.handle.breadcrumbs() as
              | JSX.Element[]
              | JSX.Element
              | undefined) ?? [];
          if (!Array.isArray(_maybeBreadcrumbs)) {
            if (_maybeBreadcrumbs) {
              _maybeBreadcrumbs = [_maybeBreadcrumbs];
            }
          }
          return from(_maybeBreadcrumbs ?? []).pipe(
            map((breadcrumbElement) => ({
              breadcrumbElement,
              route: r,
              isLast: false,
            }))
          );
        }),

        share()
      );
      // then take the flattened list, apply click handlers
      //  and mark the last breadcrumb
      const breadcrumbs =
        merge(
          matches$.pipe(skipLast(1)),
          matches$.pipe(
            last(),
            map((x) => ({ ...x, isLast: true }))
          )
        ).pipe(
          map(({ breadcrumbElement: b, route: r, isLast }, idx) =>
            cloneElement(b, {
              ...(b?.props ?? {}),
              key: `breadcrumb-${r.route.path}-${idx}`,
              onClick: () => {
                b?.props?.onClick?.();
                isLast && didSelectLastBreadCrumb?.();
              },
              isLast,
            })
          ),
          toArray()
        ).syncFirstResult()
    
      return breadcrumbs;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchesHashStr, didSelectLastBreadCrumb]);

  // const breadcrumbComponents = useMemoAsync(async () => {
  //   try {
  //     runs.current = runs.current + 1;
  //     // collect breadcrumb components from routes
  //     // and make a flattened list
  //     const matches$ = from(matches ?? []).pipe(
  //       filter((r) => r?.route?.handle?.breadcrumbs),
  //       concatMap((r) => {
  //         let _maybeBreadcrumbs =
  //           (r.route.handle.breadcrumbs() as
  //             | JSX.Element[]
  //             | JSX.Element
  //             | undefined) ?? [];
  //         if (!Array.isArray(_maybeBreadcrumbs)) {
  //           if (_maybeBreadcrumbs) {
  //             _maybeBreadcrumbs = [_maybeBreadcrumbs];
  //           }
  //         }
  //         return from(_maybeBreadcrumbs ?? []).pipe(
  //           map((breadcrumbElement) => ({
  //             breadcrumbElement,
  //             route: r,
  //             isLast: false,
  //           }))
  //         );
  //       }),

  //       share()
  //     );
  //     // then take the flattened list, apply click handlers
  //     //  and mark the last breadcrumb
  //     const breadcrumbs = await firstValueFrom(
  //       merge(
  //         matches$.pipe(skipLast(1)),
  //         matches$.pipe(
  //           last(),
  //           map((x) => ({ ...x, isLast: true }))
  //         )
  //       ).pipe(
  //         map(({ breadcrumbElement: b, route: r, isLast }, idx) =>
  //           cloneElement(b, {
  //             ...(b?.props ?? {}),
  //             key: `breadcrumb-${r.route.path}-${idx}`,
  //             onClick: () => {
  //               b?.props?.onClick?.();
  //               isLast && didSelectLastBreadCrumb?.();
  //             },
  //             isLast,
  //           })
  //         ),
  //         toArray()
  //       ),
  //       { defaultValue: [] }
  //     );
  //     return breadcrumbs;
  //   } catch (error) {
  //     console.error(error);
  //     return undefined;
  //   }
  // }, [matches, didSelectLastBreadCrumb]);

  return (
    <StyledBreadcrumbs direction="row">
      {breadcrumbComponents}
    </StyledBreadcrumbs>
  );
}
