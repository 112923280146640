import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { IconLabel, LoadingBox } from '../../base';
import { ReportsTableQueryProps } from './ReportsTableQuery';

const TableLoadingBox = styled(LoadingBox)({
  height: '100%',
  minHeight: '20rem',
  width: '100%',
  borderRadius: '0 0 0.5rem 0.5rem',
});

const ReportTableContainerComponent = lazy(() =>
  import('./ReportsTableQuery').then(({ ReportsTableQuery }) => ({
    default: ReportsTableQuery,
  }))
);

export function SuspenseReportsTableContainer({
  viewButtonLoading,
  showShareReport,
  showReport,
  showConfirmDeleteReport,
}: ReportsTableQueryProps) {
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <IconLabel
                label={
                  <FormattedMessage
                    id="reports-table-error-label"
                    description="Error message for reports table"
                    defaultMessage="Reports Unavailable"
                  />
                }
              />
            );
          }}
        >
          <Suspense fallback={<TableLoadingBox />}>
            <ReportTableContainerComponent
              viewButtonLoading={viewButtonLoading}
              showShareReport={showShareReport}
              showReport={showReport}
              showConfirmDeleteReport={showConfirmDeleteReport}
            />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
