import React from 'react';
import { Stack, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { ColorKey } from '@beeriot/api-client';
import { AppTypography } from '../../base/AppTypography';
import { PasswordConditionLabel } from './PasswordConditionLabel';
import {
  meetsLengthRequirements,
  hasUpperCase,
  hasLowerCase,
  hasSpecialChar,
  verifyPasswordsMatch,
} from './PasswordRequirementUtils';

const PasswordRequirementsLabel = styled(AppTypography)({
  margin: '0.5rem 0',
});

export interface PasswordConditionsProps {
  $colorKey?: ColorKey;
  className?: string;
  isVerifyEnabled?: boolean;
}

export const PasswordConditions = React.forwardRef(
  ({ $colorKey, className, isVerifyEnabled }: PasswordConditionsProps, ref) => {
    const { watch } = useFormContext();
    const password = watch('password');
    const passwordVerify = isVerifyEnabled ? watch('passwordVerify') : '';
    const colorKeyValue = $colorKey ?? 'white';

    return (
      <Stack ref={ref} className={className}>
        <PasswordRequirementsLabel
          variant="body1"
          $bold
          $colorKey={colorKeyValue}
        >
          <FormattedMessage
            id="reset-password-requirements-label"
            defaultMessage="Password requirements:"
            description="Label for password requirements"
          />
        </PasswordRequirementsLabel>
        <PasswordConditionLabel
          $colorKey={colorKeyValue}
          conditionMet={meetsLengthRequirements(password)}
          label={
            <FormattedMessage
              id="reset-password-requirement-character-count-label"
              defaultMessage="At least 10 characters"
              description="Label for password character count requirement"
            />
          }
        />
        <PasswordConditionLabel
          $colorKey={colorKeyValue}
          conditionMet={hasUpperCase(password)}
          label={
            <FormattedMessage
              id="reset-password-requirement-uppercase-label"
              defaultMessage="At least 1 uppercase character"
              description="Label for uppercase characters requirement"
            />
          }
        />
        <PasswordConditionLabel
          $colorKey={colorKeyValue}
          conditionMet={hasLowerCase(password)}
          label={
            <FormattedMessage
              id="reset-password-requirement-lowercase-label"
              defaultMessage="At least 1 lowercase character"
              description="Label for lowercase characters requirement"
            />
          }
        />
        <PasswordConditionLabel
          $colorKey={colorKeyValue}
          conditionMet={hasSpecialChar(password)}
          label={
            <FormattedMessage
              id="reset-password-requirement-special-label"
              defaultMessage="At least 1 special character"
              description="Label for special characters requirement"
            />
          }
        />
        {isVerifyEnabled ? (
          <PasswordConditionLabel
            $colorKey={colorKeyValue}
            conditionMet={verifyPasswordsMatch(password, passwordVerify)}
            label={
              <FormattedMessage
                id="reset-password-requirement-passwords-match-label"
                defaultMessage="Passwords must match"
                description="Label for password match requirement"
              />
            }
          />
        ) : null}
      </Stack>
    );
  }
);
