import { ReactNode } from 'react';
import { Grid, styled } from '@mui/material';
import { transientOptions } from '../utils/transientOptions';
import { GridItemStatus } from '../types';

const StyledGrid = styled(
  Grid,
  transientOptions
)<{ $status: GridItemStatus }>(({ $status }) => ({
  display: 'flex',
  flex: $status === 'selected' ? '1 1 auto' : 'inherit',
}));

export interface ExpandableGridItemProps {
  status: GridItemStatus;
  children?: ReactNode;
}

export function ExpandableGridItem({
  children,
  status,
}: ExpandableGridItemProps) {
  return status !== 'hidden' ? (
    <StyledGrid
      $status={status}
      item
      xs={12}
      md={status === 'selected' ? 12 : 6}
    >
      {children}
    </StyledGrid>
  ) : null;
}
