import { Children, isValidElement, ReactElement, ReactNode } from 'react';
import {
  createRoutesFromChildren,
  Route,
  RoutesProps,
  useRoutes,
} from 'react-router-dom';

const getElementName = (element: ReactNode): string | null => {
  if (!isValidElement(element)) {
    return null;
  }

  return typeof element.type === 'string' ? element.type : element.type.name;
};

/**
 * A custom `Routes` component for react-router v6, which allows for custom `Route` components.
 * The only requirement is for the Custom Route's to be a HoC with a REAL route.  The real route component
 * can be deeply nested.
 *
 * @param props a RoutesCustom component accepts the same props as react-router's Routes component
 * @returns
 */
export const RoutesCustom = (props: RoutesProps) => {
  const { children, location } = props;
  return useRoutes(
    createRoutesFromChildren(_unwrapComplexRoutes(children)),
    location
  );
};

/**
 * Internal Utility Method
 *
 * Takes in a collection of Route's and Custom Route's, returns a flattened
 * list of ONLY the top-level Route's, and the Route's which any Custom Routes
 * may wrap.
 *
 * @param children a collection of Route and/or Custom Route components
 * @returns array of Route components
 */
export function _unwrapComplexRoutes(children: ReactNode) {
  return Children.map(children, (topLevelChild) => {
    const route: ReactNode = _deepFindRoute(topLevelChild);
    if (route) return route;
    _warnIncompatibleRouteComponents(topLevelChild);
    return route;
  });
}

/**
 * Internal Utility Method
 *
 * Drills down into a components first-children to find any deeply embedded Route components
 *
 * @param children a collection of Route and/or Custom Route components
 * @returns the first Route component encountered while drilling down into an element.
 */
export function _deepFindRoute(
  c: ReactNode,
  _parentEleName = 'top-level'
): ReactNode {
  const eleName = getElementName(c);

  if (isValidElement(c)) {
    const children = Children.toArray(c.props.children);
    if (eleName === 'Route' || c.type === Route) {
      return c;
    }
    if (children.length > 0) {
      const first = children[0];
      return _deepFindRoute(first, eleName ?? '');
    } else {
      if (typeof c.type !== 'string') {
        if (typeof c.type === 'function') {
          const ComponentDef = c.type as (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            props: any
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ) => ReactElement<any, any> | null;
          return _deepFindRoute(ComponentDef(c.props), eleName ?? '');
        }
      }
      console.warn(`<${eleName}> had no children`);
    }
  }
  return undefined;
}
function _warnIncompatibleRouteComponents(child: ReactNode) {
  console.warn(
    `<RoutesCustom> only accepts <Route> components, and Wrapped/HoC <Route> components as children.  The ${getElementName(
      child
    )} component must be a <Route> or be a composed <Route>, it will be filtered out of the render tree`
  );
}
