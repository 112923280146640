import { GQLColumn, useInfiniteListColumnsQuery } from '../generated/gql';
import {
  defaultPagingParams,
  getNextPageParamHandler,
  removeFalsey,
} from '../utils';

interface ColumnQueryVariables {
  skip: number;
  limit: number;
  systemId: string | null | undefined;
  status: string;
}

const queryVariables = (systemId?: string | null): ColumnQueryVariables => {
  return {
    systemId,
    status: 'active',
    ...defaultPagingParams,
  };
};

export function useGQLColumns(systemId?: string | null): GQLColumn[] {
  const {
    data: columns,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteListColumnsQuery(queryVariables(systemId), {
    enabled: !!systemId,
    refetchInterval: 15000,
    getNextPageParam: getNextPageParamHandler(
      (query) => query?.listColumns?.length
    ),
  });

  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage();
  }

  const gqlColumns = removeFalsey<GQLColumn>(
    columns?.pages.flatMap((x) => x?.listColumns)
  );

  return gqlColumns.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
}
