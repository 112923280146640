import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { Stack, Button, Typography, styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { LoadingPaper } from '../../base';

const MessageContainer = styled(Stack)({
  textAlign: 'center',
  width: '30rem',
  margin: '3rem',
  justifyContent: 'center',
});

const ErrorContainer = styled(Stack)({
  width: '100%',
  alignItems: 'center',
});

const LineDetailsLoadingPaper = styled(LoadingPaper)({
  height: '7.5rem',
  width: 'calc(100% - 2rem)',
  margin: '1rem',
});

const BlockLoadingPaper = styled(LoadingPaper)({
  height: '4rem',
  width: 'calc(100% - 2rem)',
  margin: '1rem',
});

function LineLoadingPapers() {
  return (
    <Stack>
      <LineDetailsLoadingPaper />
      <BlockLoadingPaper />
      <BlockLoadingPaper />
      <BlockLoadingPaper />
      <BlockLoadingPaper />
      <BlockLoadingPaper />
    </Stack>
  );
}

const LineSidebarMenu = lazy(() =>
  import('./LineSidebarMenuQuery').then(({ LineSidebarMenuQuery }) => ({
    default: LineSidebarMenuQuery,
  }))
);

export function SuspenseLineSidebarMenu() {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ resetErrorBoundary }) => {
            return (
              <ErrorContainer>
                <MessageContainer>
                  <Typography>
                    <FormattedMessage
                      id="line-status-sidebar-loading-error-label"
                      description="Label for line status loading error"
                      defaultMessage="There was an error loading the line status"
                    />
                  </Typography>
                  <Button onClick={() => resetErrorBoundary()}>
                    <FormattedMessage
                      id="common-try-again-button"
                      description="Button for trying an action again"
                      defaultMessage="Try Again"
                    />
                  </Button>
                </MessageContainer>
              </ErrorContainer>
            );
          }}
          onReset={reset}
        >
          <Suspense fallback={<LineLoadingPapers />}>
            <LineSidebarMenu />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
