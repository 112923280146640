import { styled } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { AppTypography } from '../../base/AppTypography';
import { Form, FormError } from '../../form';
import { RouterLink } from '../../navigation';
import { LoginTextInput } from '../LoginTextInput';
import { PallLogo } from '../PallLogo';
import { AuthSubmitButton } from '../AuthSubmitButton';
import { FormErrorContainer } from '../FormErrorContainer';
import { useCallback } from 'react';
import { UseMutationResult } from 'react-query';
import { User } from '@beeriot/api-client';

const CancelButton = styled(RouterLink)(({ theme }) => ({
  display: 'flex',
  textDecoration: 'underline',
  color: theme.palette.white.main,
  justifyContent: 'center',
  marginTop: '2rem',
}));

const FormLabel = styled(AppTypography)({
  marginTop: '0.5rem',
  marginBottom: '1rem',
});

const StyledAuthSubmitButton = styled(AuthSubmitButton)({
  marginTop: '3rem',
});

interface ForgotPasswordFormProps {
  mutation: UseMutationResult<User, unknown, string, unknown>;
  error?: React.ReactNode;
}

export interface ForgotPasswordFormFields {
  email: string;
}

export function ForgotPasswordForm({
  mutation,
  error,
}: ForgotPasswordFormProps) {
  const intl = useIntl();
  const emailPlaceholder = intl.formatMessage({
    id: 'login-email-placeholder',
    description: 'Placeholder for email input',
    defaultMessage: 'Enter email address',
  });

  const handleForgotPassword = useCallback(
    (data: ForgotPasswordFormFields) => {
      mutation.mutate(data.email);
    },
    [mutation]
  );

  return (
    <Form<ForgotPasswordFormFields> onSubmit={handleForgotPassword}>
      <PallLogo />
      <AppTypography variant="body1" $bold $colorKey="white">
        <FormattedMessage
          id="forgot-password-form-title-label"
          defaultMessage="Recover Password"
          description="Label for forgot password form title"
        />
      </AppTypography>
      <FormLabel variant="subtitle2" $colorKey="white">
        <FormattedMessage
          id="forgot-password-form-label"
          defaultMessage="It happens, we'll help you get logged in."
          description="Label for forgot password form"
        />
      </FormLabel>
      <LoginTextInput required name="email" placeholder={emailPlaceholder} />
      <FormErrorContainer>
        <FormError error={error} color="info" />
      </FormErrorContainer>
      <StyledAuthSubmitButton
        type="submit"
        isLoading={mutation.isLoading}
        loadingLabel={
          <FormattedMessage
            id="forgot-password-email-recovery-sending-label"
            defaultMessage="Sending"
            description="Label when email recovery link is being sent"
          />
        }
      >
        <FormattedMessage
          id="forgot-password-email-recovery-link-button"
          defaultMessage="Email me a recovery link"
          description="Button to send email recovery link"
        />
      </StyledAuthSubmitButton>
      <CancelButton to="/auth">
        <FormattedMessage
          id="common-cancel-button"
          description="Label for cancel button"
          defaultMessage="Cancel"
        />
      </CancelButton>
    </Form>
  );
}
