import { useCallback, useMemo } from 'react';
import { useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import Select, {
  components,
  DropdownIndicatorProps,
  CSSObjectWithLabel,
  ActionMeta,
  SingleValue,
  MultiValue,
} from 'react-select';
import { AppIcon } from '../../base/AppIcon';
import { SelectOption } from '../../form/SelectOption';

export interface BrandSelectProps {
  defaultValue?: SelectOption | null;
  value?: SelectOption | null;
  options?: SelectOption[];
  onChange?: (newValue: SelectOption | null) => void;
}

export function BrandSelect({
  defaultValue,
  value,
  options,
  onChange,
}: BrandSelectProps) {
  const theme = useTheme();
  const intl = useIntl();
  const styles = useMemo(() => {
    const variant = 'h6';
    const fontStyle = {
      fontFamily: theme.typography[variant].fontFamily,
      fontSize: theme.typography[variant].fontSize,
      // fontVariant: theme.typography[variant].fontVariant,

      fontWeight: 'bold',
    };
    return {
      control: (base: CSSObjectWithLabel) => ({
        ...base,
        border: 'none',
        minHeight: '5px',
        backgroundColor: 'inherit',
        maxWidth: '15rem',
        fontStyle: 'b',
        ...fontStyle,
        [theme.breakpoints.down('sm')]: {
          // fontSize: '0.8rem',
          // maxWidth: '6.5rem',
        },
      }),
      singleValue: (base: CSSObjectWithLabel) => ({
        ...base,
        padding: 0,
        ...fontStyle,
      }),
      placeholder: (base: CSSObjectWithLabel) => ({
        ...base,
        color: theme.palette.link.main,
      }),
      valueContainer: (base: CSSObjectWithLabel) => ({
        ...base,
        padding: 0,
      }),
      input: (base: CSSObjectWithLabel) => ({
        ...base,
        padding: 0,
      }),
      dropdownIndicator: (base: CSSObjectWithLabel) => ({
        ...base,
        padding: 6,
      }),
      option: (base: CSSObjectWithLabel) => ({
        ...base,
        border: `1px solid ${theme.palette.grey[300]}`,
        height: '100%',
        fontFamily: theme.typography.fontFamily,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }),
      menu: (base: CSSObjectWithLabel) => ({
        ...base,
        width: 'unset',
        maxWidth: '20rem',
      }),
    };
  }, [theme]);

  const DropdownIndicator = useCallback(
    (props: DropdownIndicatorProps<SelectOption>) => {
      return (
        <components.DropdownIndicator {...props}>
          <AppIcon icon="pencil" $colorKey="secondary" />
        </components.DropdownIndicator>
      );
    },
    []
  );

  const IndicatorSeparator = useCallback(() => {
    return null;
  }, []);

  const onHandleChange = useCallback(
    (
      newValue: SingleValue<SelectOption> | MultiValue<SelectOption>,
      actionMeta: ActionMeta<SelectOption>
    ) => {
      if (onChange === undefined) return;
      onChange(newValue as SelectOption);
    },
    [onChange]
  );

  return (
    <Select
      defaultValue={defaultValue}
      value={value}
      options={options}
      components={{ DropdownIndicator, IndicatorSeparator }}
      styles={styles}
      placeholder={intl.formatMessage({
        id: 'site-brand-select-options-placeholder',
        defaultMessage: 'Select a Brand',
        description: 'Placeholder for the brand select',
      })}
      onChange={onHandleChange}
    />
  );
}
