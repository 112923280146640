import CognitoProvider from 'aws-sdk/clients/cognitoidentityserviceprovider';

import { awsRegion, clientId, clientSecret } from './awsConstants';
import { getUserAttribute } from './getUserAttribute';
import { AuthStore } from '../state/auth/authStore.model';

export async function getUserInfo(authStore: AuthStore) {
  if (!awsRegion || !clientId || !clientSecret) {
    throw new Error('Missing cognito environment variables');
  }

  const state = authStore.getValue();
  const accessToken = state.accessToken;
  if (!accessToken) {
    throw new Error('Missing auth state');
  }

  const cognito = new CognitoProvider({
    region: awsRegion,
  });

  try {
    const data = await cognito
      .getUser({
        AccessToken: accessToken,
      })
      .promise();
    authStore.update((state) => ({
      ...state,
      username: data?.Username || null,
      userId: getUserAttribute('sub', data?.UserAttributes),
      email: getUserAttribute('email', data?.UserAttributes),
      firstName: getUserAttribute('given_name', data?.UserAttributes),
      lastName: getUserAttribute('family_name', data?.UserAttributes),
    }));
    return data;
  } catch (error) {
    console.log('userInfo error: ', error);
    throw error;
  }
}
