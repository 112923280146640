import { TimelineData } from '../base';

interface RecentStatusItem {
  percent?: number | undefined | null;
  status?: string | undefined | null;
}
type RecentStatus = (RecentStatusItem | undefined | null)[];

export function recentStatusToTimelineData(
  recentStatus: RecentStatus | undefined | null
): TimelineData[] {
  return (
    recentStatus
      ?.filter((x) => x?.percent != null && x.status != null)
      .map((x, i) => ({
        id: i.toString(),
        magnitude: x?.percent,
        status: x?.status,
      })) ?? []
  );
}
