import { styled, Stack } from '@mui/material';
import {
  AppTablePagination,
  AppTablePaginationProps,
} from './AppTablePagination';

const PaginationContainer = styled(Stack)({
  width: '100%',
  flex: 1,
  alignItems: 'end',
});

export function AppTableFooterPagination({
  page = 0,
  pageSizes = [10, 15, 20, 25, 30],
  rowsPerPage = 10,
  totalRows = 0,
  setPage,
  setRowsPerPage,
}: AppTablePaginationProps) {
  return (
    <PaginationContainer>
      <AppTablePagination
        page={page}
        pageSizes={pageSizes}
        rowsPerPage={rowsPerPage}
        totalRows={totalRows}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </PaginationContainer>
  );
}
