import { useCallback } from 'react';
import { ButtonBase, Stack, styled } from '@mui/material';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  alertStore,
  AlertType,
  ColorKey,
  GQLAlertActive,
  GQLAlertHistory,
  GQLCompanySite,
  GQLSystem,
  GQLSystemWithColor,
  isAlertType,
} from '@beeriot/api-client';
import {
  AppCard,
  AppIcon,
  AppTypography,
  useHasPermission,
  PermissionKey,
} from '../base';
import {
  transientOptions,
  useFormatDateTime,
  useResponsiveSizes,
} from '../utils';

const alertIconMap: Record<AlertType, IconProp> = {
  machineAlarms: 'bell-on',
  progressAlerts: 'warning',
  maintenanceWarnings: 'wrench',
  systemWarnings: 'wrench',
};

export function getAlertIcon(alertType?: string | null) {
  if (isAlertType(alertType)) {
    return alertIconMap[alertType];
  }
  return alertIconMap['machineAlarms'];
}

const alertColorMap: Record<AlertType, ColorKey> = {
  machineAlarms: 'error',
  progressAlerts: 'warning',
  maintenanceWarnings: 'info',
  systemWarnings: 'warning',
};

export function getAlertColor(alertType?: string | null) {
  if (isAlertType(alertType)) {
    return alertColorMap[alertType];
  }
  return undefined;
}

const StyledButton = styled(ButtonBase)({
  width: '100%',
  textAlign: 'start',
  justifyContent: 'start',
});

const StyledContentsStack = styled(
  Stack,
  transientOptions
)<{ $isMobile: boolean }>(({ $isMobile }) => ({
  gap: '1rem',
  padding: '0.5rem',
  alignItems: $isMobile ? 'center' : 'unset',
}));

const StyledCard = styled(AppCard)({
  margin: '0.5rem',
});

const StyledStack = styled(Stack)({
  columnGap: '1rem',
  flexWrap: 'wrap',
});

export interface AlertCardProps {
  alert: GQLAlertActive | GQLAlertHistory;
  sites: GQLCompanySite[] | null;
  systems: GQLSystemWithColor[] | null;
}
export function AlertCard({ alert, sites, systems }: AlertCardProps) {
  const { isMobileSize } = useResponsiveSizes();
  const colorKey = getAlertColor(alert.alertType);
  const icon = getAlertIcon(alert.alertType);
  const formatDateTime = useFormatDateTime();
  const dateTime = formatDateTime(alert.time);
  const hasPermission = useHasPermission();

  const selectAlert = useCallback(() => {
    if (hasPermission(PermissionKey.AlertHistoryDetails)) {
      alertStore.update((state) => ({ ...state, selectedAlert: alert }));
    }
  }, [hasPermission, alert]);

  const alertSite = sites?.find((x) => x.id === alert.siteId);
  // TODO: provide alert system id to predicate when available
  const alertSystem = systems?.find((x) => x.id === 'TBD');
  const getSubtitle = () => {
    if (alertSite?.name && alertSystem?.name)
      return `${alertSite?.name} - ${alertSystem?.name}`;
    if (alertSite?.name) return alertSite.name;
    if (alertSystem?.name) return alertSystem.name;
    return '';
  };
  const subtitle = getSubtitle();

  return (
    <StyledCard>
      <StyledButton onClick={selectAlert}>
        <StyledContentsStack direction="row" $isMobile={isMobileSize}>
          <AppIcon size="lg" icon={icon} $colorKey={colorKey} />
          <Stack>
            <AppTypography
              $bold={true}
              variant="subtitle2"
              $colorKey={colorKey}
            >
              {alert.measure_name}
            </AppTypography>
            <StyledStack direction={isMobileSize ? 'row' : 'column'}>
              <AppTypography
                $bold={true}
                variant="subtitle2"
                $colorKey="darkText"
              >
                {subtitle}
              </AppTypography>
              <AppTypography variant="subtitle2" $colorKey="subtitleText">
                {dateTime}
              </AppTypography>
            </StyledStack>
          </Stack>
        </StyledContentsStack>
      </StyledButton>
    </StyledCard>
  );
}
