import { useMemo, useCallback, useEffect } from 'react';
import { InfiniteData, UseMutationResult } from 'react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  GQLListReportDefinitionsQuery,
  GQLReportDefinition,
  GQLSystem,
  ReportDefinition,
  ReportDefinitionBase,
  ReportDefinitionBaseFrequencyEnum,
  ReportDefinitionBaseReportTypeEnum,
  ReportFrequencyEnum,
  ReportTypeEnum,
  defaultFrequencyAggregationRecord,
  useActiveAppEntities,
  useDeleteReport,
} from '@beeriot/api-client';
import { Grid, Paper, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  CheckBoxSection,
  Form,
  FormError,
  FormLabeledCheckbox,
  SelectOption,
} from '../../form';
import {
  SectionHeader,
  SectionControl,
  SectionFormSelect,
  SectionLabel,
  SectionTextInput,
  SettingsFooterButtons,
} from '../settings-form';
import { FormErrorContainer } from '../../auth/FormErrorContainer';
import { getLanguageOptions } from '../Settings.models';
import { useIntlLanguage } from '../../enums';
import { ConfirmDeleteAutomaticReportButton } from './ConfirmDeleteAutomaticReportButton';
import {
  AppTypography,
  AppIcon,
  PermissionView,
  PermissionKey,
} from '../../base';
import { getEnumFromString } from '../../utils';
import { useIntlReportFrequency } from '../../intl/useIntlReportFrequency';
import { useIntlReportType } from '../../intl/useIntlReportType';
import { systemIdString } from '../../dialogs/reports/NewRecurringReportDialogContent';
import { useFormContext } from 'react-hook-form';
import { ReportDeletionConfirmationDialog } from '../../dialogs/reports';

export interface SiteSystems {
  siteId: string;
  siteName: string;
  systems: GQLSystem[] | null;
}

function toReportDefinitionBase(
  fields: AddEditAutomaticReportFormFields,
  gqlSystems: GQLSystem[],
  companyId: string,
  startDate: string,
  endDate: string
): ReportDefinitionBase {
  const reportType = getEnumFromString(
    ReportDefinitionBaseReportTypeEnum,
    fields.reportType?.value
  );
  if (!reportType) throw new Error('Invalid report type encountered');
  const reportFrequency = getEnumFromString(
    ReportDefinitionBaseFrequencyEnum,
    fields.reportFrequency?.value
  );
  if (!reportFrequency) throw new Error('Invalid report frequency encountered');
  return {
    companyId: companyId,
    reportName: fields.name,
    reportType: reportType,
    frequency: reportFrequency,
    startDate: startDate,
    endDate: endDate,
    aggregation: defaultFrequencyAggregationRecord[reportFrequency] ?? 'daily',
    systems: gqlSystems
      .filter((x) => {
        const key: systemIdString = `systemId-${x.id}`;
        const checked = fields[key];
        return checked;
      })
      .map((x) => ({
        companySiteId: x.companySiteId ?? '',
        systemId: x.id,
      })),
  };
}

const StyledPaper = styled(Paper)({
  maxWidth: '40rem',
});

const HeaderControlsContainer = styled(Stack)({
  padding: '0 1rem',
  alignItems: 'end',
  flex: 1,
  width: '100%',
});

const StyledFormErrorContainer = styled(FormErrorContainer)({
  margin: '1rem',
});

const FormHeaderTitle = styled(AppTypography)(({ theme }) => ({
  padding: '1rem',
}));

const FormHeader = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderBottom: 'solid 1px',
  borderColor: theme.palette.grey[300],
  borderTopLeftRadius: '0.5rem',
  borderTopRightRadius: '0.5rem',
  alignItems: 'center',
}));

const defaultValues: AddEditAutomaticReportFormFields = {
  reportType: null,
  reportFrequency: null,
  name: '',
  // emails: [],
  // language: undefined,
};

export interface AddEditAutomaticReportFormFields {
  reportType?: SelectOption | null;
  reportFrequency?: SelectOption | null;
  name: string;
  // emails: string[];
  // language?: SelectOption;
  [key: systemIdString]: boolean;
}

export interface AddEditAutomaticReportProps {
  activeAutomaticReport?: GQLReportDefinition;
  siteSystems: SiteSystems[] | null;
  addMutation?: UseMutationResult<
    ReportDefinition,
    unknown,
    ReportDefinitionBase,
    unknown
  >;
  editMutation?: UseMutationResult<
    ReportDefinition,
    unknown,
    {
      id: string;
      definitionBase: ReportDefinitionBase;
    },
    {
      previousState: InfiniteData<GQLListReportDefinitionsQuery> | undefined;
    }
  >;
  error?: React.ReactNode;
}

export const AddEditAutomaticReport = ({
  activeAutomaticReport,
  siteSystems,
  editMutation,
  addMutation,
  error,
}: AddEditAutomaticReportProps) => {
  const intl = useIntl();
  const intlLanguage = useIntlLanguage();
  const reportFrequencyIntl = useIntlReportFrequency();
  const reportTypeIntl = useIntlReportType();
  const languageOptions = getLanguageOptions(intlLanguage);
  const { activeCompany } = useActiveAppEntities();
  const isEditMode = useMemo(
    () => !!activeAutomaticReport,
    [activeAutomaticReport]
  );
  const deleteMutation = useDeleteReport();

  const machineOptionSections: CheckBoxSection[] = useMemo(() => {
    return siteSystems?.map((x) => ({
      sectionName: x.siteName,
      items: x?.systems?.map((system) => ({
        label: system.name ?? '-',
        value: system.id,
        isChecked:
          activeAutomaticReport?.systems?.find(
            (reportSystem) => reportSystem?.systemId === system.id
          ) != null ?? false,
      })) ?? [],
    })) ?? [];
  }, [activeAutomaticReport?.systems, siteSystems]);

  const onDelete = useCallback(
    (automaticReportId: string) => {
      deleteMutation.mutate(automaticReportId);
    },
    [deleteMutation]
  );

  const frequencyOptions: SelectOption[] = useMemo(
    () => [
      {
        value: 'Daily',
        label: reportFrequencyIntl(ReportFrequencyEnum.Daily),
      },
      {
        value: 'Weekly',
        label: reportFrequencyIntl(ReportFrequencyEnum.Weekly),
      },
      {
        value: 'Monthly',
        label: reportFrequencyIntl(ReportFrequencyEnum.Monthly),
      },
      {
        value: 'Yearly',
        label: reportFrequencyIntl(ReportFrequencyEnum.Yearly),
      },
    ],
    [reportFrequencyIntl]
  );

  const reportTypeOptions: SelectOption[] = useMemo(
    () => [
      {
        value: 'ConsumptionReport',
        label: reportTypeIntl(ReportTypeEnum.ConsumptionReport),
      },
      {
        value: 'CostReport',
        label: reportTypeIntl(ReportTypeEnum.CostReport),
      },
      {
        value: 'QualityReport',
        label: reportTypeIntl(ReportTypeEnum.QualityReport),
      },
    ],
    [reportTypeIntl]
  );

  const defaultFormValues = useMemo(() => {
    const systemIds = siteSystems?.flatMap((x) => x.systems).filter(x => !!x).map((x) => (x as unknown as GQLSystem).id) ?? [];
    if (activeAutomaticReport) {
      const defaults: AddEditAutomaticReportFormFields = {
        name: activeAutomaticReport.reportName ?? '',
        reportType: reportTypeOptions.find(
          (x) => x.value === activeAutomaticReport.reportType
        ),
        reportFrequency: frequencyOptions.find(
          (x) => x.value === activeAutomaticReport.frequency
        ),
      };
      for (const systemId of systemIds) {
        defaults[`systemId-${systemId}`] = false;
      }
      for (const system of activeAutomaticReport.systems ?? []) {
        if (system?.systemId) {
          defaults[`systemId-${system.systemId}`] = true;
        }
      }
      return { defaultValues: defaults };
    } else {
      const defaults = { ...defaultValues };
      for (const systemId of systemIds) {
        defaults[`systemId-${systemId}`] = false;
      }
      return { defaultValues: defaults };
    }
  }, [activeAutomaticReport, frequencyOptions, reportTypeOptions, siteSystems]);

  const systems = siteSystems?.flatMap((x) => x.systems).filter(x => x !== null) as unknown as GQLSystem[] ?? [];
  return (
    <StyledPaper>
      <Form<AddEditAutomaticReportFormFields>
        onSubmit={(data) =>
          isEditMode
            ? editMutation?.mutate({
                id: activeAutomaticReport?.id ?? '',
                definitionBase: toReportDefinitionBase(
                  data,
                  systems,
                  activeCompany?.id ?? '',
                  new Date().toISOString(),
                  ''
                ),
              })
            : addMutation?.mutate(
                toReportDefinitionBase(
                  data,
                  systems,
                  activeCompany?.id ?? '',
                  new Date().toISOString(),
                  ''
                )
              )
        }
        formProps={defaultFormValues}
      >
        <FormResetter reportDefinitionId={activeAutomaticReport?.id} />
        <FormHeader direction="row">
          <FormHeaderTitle $bold variant="h6">
            {isEditMode ? (
              <FormattedMessage
                id="settings-automatic-report-edit-header-title"
                defaultMessage="Report Details"
                description="label for edit recurring report header"
              />
            ) : (
              <FormattedMessage
                id="settings-automatic-report-add-header-title"
                defaultMessage="Add Recurring Report"
                description="label for add recurring report header"
              />
            )}
          </FormHeaderTitle>
          {activeAutomaticReport && (
            <PermissionView
              permissionKey={PermissionKey.SettingsReportingDelete}
            >
              <HeaderControlsContainer>
                <ConfirmDeleteAutomaticReportButton
                  selectedReport={activeAutomaticReport}
                  buttonTitle={
                    <FormattedMessage
                      id="settings-automatic-report-delete-button"
                      description="Label for delete report button"
                      defaultMessage="Delete Report"
                    />
                  }
                  onDelete={() => onDelete(activeAutomaticReport?.id)}
                  startIcon={
                    <AppIcon $colorKey="error" icon={['far', 'trash-alt']} />
                  }
                />
                <ReportDeletionConfirmationDialog
                  shouldNavigate
                  deleteMutation={deleteMutation}
                />
              </HeaderControlsContainer>
            </PermissionView>
          )}
        </FormHeader>
        <SectionControl>
          <SectionLabel>
            <FormattedMessage
              id="settings-automatic-report-type-label"
              defaultMessage="Report Type"
              description="label for report type"
            />
          </SectionLabel>
          <SectionFormSelect
            name="reportType"
            placeholder={intl.formatMessage({
              id: 'settings-automatic-report-type-placeholder',
              defaultMessage: 'Select Report Type',
              description: 'label for report type placeholder',
            })}
            options={reportTypeOptions}
          />
        </SectionControl>
        <SectionControl>
          <SectionLabel>
            <FormattedMessage
              id="settings-automatic-report-frequency-label"
              defaultMessage="Frequency"
              description="label for report frequency"
            />
          </SectionLabel>
          <SectionFormSelect
            name="reportFrequency"
            placeholder={intl.formatMessage({
              id: 'settings-automatic-report-frequency-placeholder',
              defaultMessage: 'Select Frequency',
              description: 'label for report frequency placeholder',
            })}
            options={frequencyOptions}
          />
        </SectionControl>
        <SectionControl>
          <SectionLabel>
            <FormattedMessage
              id="settings-automatic-report-name-label"
              defaultMessage="Report Name"
              description="label for name"
            />
          </SectionLabel>
          <SectionTextInput
            name="name"
            required
            placeholder={intl.formatMessage({
              id: 'settings-automatic-report-name-placeholder',
              defaultMessage: 'Enter Report Name',
              description: 'label for report name placeholder',
            })}
          />
        </SectionControl>
        <Stack>
          <SectionHeader>
            <FormattedMessage
              id="settings-automatic-report-options-section-title"
              defaultMessage="Report Options"
              description="header for report options form section header"
            />
          </SectionHeader>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="settings-automatic-report-systems-label"
                defaultMessage="System(s)"
                description="label for systems"
              />
            </SectionLabel>
            <Stack sx={{ padding: '0.5rem 0' }}>
              {machineOptionSections.length > 0
                ? machineOptionSections.map((x) => (
                    <Stack key={x.sectionName}>
                      <AppTypography $colorKey="lightText" $bold>
                        {x.sectionName}
                      </AppTypography>
                      <Grid container>
                        {x.items.map((item) => (
                          <Grid item xs={12} md={6} key={item.value}>
                            <FormLabeledCheckbox
                              id={item.value}
                              name={`systemId-${item.value}`}
                              label={item.label}
                              $colorFilledCheckbox
                              $checkedColorKey="primary"
                              defaultValue={item.isChecked}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Stack>
                  ))
                : null}
            </Stack>
          </SectionControl>
        </Stack>
        {/* TODO: Add fields for emails and language when supported */}
        {/* <Stack>
          <SectionHeader>
            <FormattedMessage
              id="settings-automatic-report-delivery-options-section-title"
              defaultMessage="Delivery Options"
              description="header for delivery options form section header"
            />
          </SectionHeader>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="common-email-address-label"
                defaultMessage="Email Address(s)"
                description="label for email addresses"
              />
            </SectionLabel>
            <FormTagEmailField
              name="emails"
              required
              placeholder={intl.formatMessage({
                id: 'common-emails-placeholder',
                defaultMessage: 'Enter emails',
                description: 'label for emails placeholder',
              })}
            />
          </SectionControl>
          <SectionControl>
            <SectionLabel>
              <FormattedMessage
                id="common-language-label"
                defaultMessage="Language"
                description="label for language"
              />
            </SectionLabel>
            <SectionFormSelect
              name="language"
              placeholder={intl.formatMessage({
                id: 'common-language-placeholder',
                defaultMessage: 'Select Language',
                description: 'label for language placeholder',
              })}
              options={languageOptions}
            />
          </SectionControl>
        </Stack> */}
        <StyledFormErrorContainer>
          <FormError error={error} color="info" />
        </StyledFormErrorContainer>
        <SettingsFooterButtons
          isLoading={
            isEditMode ? editMutation?.isLoading : addMutation?.isLoading
          }
          isSuccess={
            isEditMode ? editMutation?.isSuccess : addMutation?.isSuccess
          }
          submitLabel={intl.formatMessage({
            id: 'settings-automatic-report-submit-button',
            defaultMessage: 'Save Report',
            description: 'label for automatic report form submit button',
          })}
          successLabel={
            isEditMode
              ? intl.formatMessage({
                  id: 'settings-edit-automatic-report-success-message',
                  defaultMessage: 'Successfully updated report.',
                  description: 'label for edit report success message',
                })
              : intl.formatMessage({
                  id: 'settings-add-automatic-report-success-message',
                  defaultMessage: 'Successfully added report.',
                  description: 'label for add report success message',
                })
          }
        />
      </Form>
    </StyledPaper>
  );
};

function FormResetter({ reportDefinitionId }: { reportDefinitionId?: string }) {
  const { reset } = useFormContext();
  useEffect(() => {
    reset();
  }, [reportDefinitionId, reset]);
  return null;
}
