import { Box, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { ColorKey } from '@beeriot/api-client';
import { transientOptions } from '../utils/transientOptions';

const BadgeContainer = styled(
  Box,
  transientOptions
)<{ $colorKey: ColorKey; $fontSize: string }>(
  ({ $colorKey, $fontSize, theme }) => {
    return {
      height: '1.5rem',
      width: '1.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette[$colorKey].main,
      color: theme.palette.common.white,
      fontSize: $fontSize,
      borderRadius: '50%',
      padding: '0.25rem',
    };
  }
);

const StyledTypography = styled(Typography)({
  fontSize: 'inherit',
  fontWeight: 'inherit',
  alignSelf: 'center',
  lineHeight: '0',
});

export interface AppMedallionProps {
  children?: ReactNode;
  colorKey?: ColorKey;
  className?: string;
  fontSize?: string;
}

export function AppMedallion({
  children,
  colorKey = 'secondary',
  className,
  fontSize = '0.6rem',
}: AppMedallionProps) {
  return (
    <BadgeContainer
      $colorKey={colorKey}
      $fontSize={fontSize}
      className={className}
    >
      <StyledTypography>{children}</StyledTypography>
    </BadgeContainer>
  );
}
