import { useObservable } from '@ngneat/use-observable';

import { combineLatest, Observable } from 'rxjs';
import {
  shareReplay,
  tap,
  startWith,
  distinctUntilChanged,
  throttleTime,
  catchError
} from 'rxjs/operators';
import { activeBlock$ } from '../state/block/blockStore.selectors';
import {
  activeCompany$,
  companySearch$,
} from '../state/company/companyStore.selectors';
import {
  gqlSystems$,
  activeSystem$,
  GQLSystemWithColor,
} from '../state/system/systemStore.selectors';
import {
  sites$,
  groupedSites$,
  activeSiteAreas$,
  activeSite$,
  siteSearch$,
} from '../state/site/siteStore.selectors';
import {
  companies$,
  groupedCompanies$,
} from '../state/company/companyStore.selectors';
import { activeSiteAreaSystems$ } from '../state/site/activeSiteAreaSystems$';
import { Area, SettingsUsers } from '../generated';
import {
  GQLCompany,
  GQLCompanySite,
  GQLSystem,
  GQLBlock,
} from '../generated/gql';
import { AreaSystem, SiteGroup } from '../lib/interfaces';
import { distinctUntilChangedObject } from '../utils';
import { CompanyGroup } from '../lib';
import {
  userFavoriteCompanyIds$,
  userSettings$,
} from '../state/settings/settingsStore.selectors';

export type ActiveEntitiesType = {
  // search
  companySearchText: string;
  siteSearchText: string;
  // actives
  activeCompany: GQLCompany | null;
  activeSite: GQLCompanySite | null;
  activeSystem: GQLSystem | null;
  activeBlock: GQLBlock | null;
  // lists
  companies: GQLCompany[];
  groupedCompanies: CompanyGroup[];
  sites: GQLCompanySite[] | null;
  groupedSites: SiteGroup[] | null;
  areas: Area[] | null;
  areaSystems: AreaSystem[] | null;
  gqlSystems: GQLSystemWithColor[] | null;

  // settings
  userSettings: SettingsUsers | null;
  userFavoriteCompaniesIds: string[];
};

let _activeEntities$: Observable<ActiveEntitiesType>;
const defaultActiveEntities: ActiveEntitiesType = {
  // search
  companySearchText: '',
  siteSearchText: '',
  // actives
  activeCompany: null,
  activeSite: null,
  activeSystem: null,
  activeBlock: null,
  // lists
  companies: [],
  groupedCompanies: [],
  sites: null,
  groupedSites: null,
  areas: null,
  areaSystems: null,
  gqlSystems: null,
  // settings
  userSettings: null,
  userFavoriteCompaniesIds: [],
};

export const activeEntities$: Observable<ActiveEntitiesType> = combineLatest({
  //
  // search
  //
  companySearchText: companySearch$.pipe(startWith(''), distinctUntilChanged(),
  // tap(x => console.log(`next:`, x)),
  ),
  siteSearchText: siteSearch$.pipe(startWith(''), distinctUntilChanged(),
  // tap(x => console.log(`next:`, x)),
  ),
  //
  // actives
  //
  activeCompany: activeCompany$.pipe(
    startWith(null),
    distinctUntilChangedObject(),
    // tap(x => console.log(`next:`, x)),
  ),
  activeSite: activeSite$.pipe(startWith(null), distinctUntilChangedObject(),
  // tap(x => console.log(`next:`, x)),
  ),
  activeSystem: activeSystem$.pipe(
    startWith(null),
    distinctUntilChangedObject(),
    // tap(x => console.log(`next:`, x)),
  ),
  activeBlock: activeBlock$.pipe(startWith(null), distinctUntilChangedObject(),
  // tap(x => console.log(`next:`, x)),
  ),
  //
  // lists
  //
  companies: companies$.pipe(startWith([]), distinctUntilChangedObject(),
  // tap(x => console.log(`next:`, x)),
  ),
  groupedCompanies: groupedCompanies$.pipe(
    startWith([]),
    distinctUntilChangedObject(),
    // tap(x => console.log(`next:`, x)),
  ),
  sites: sites$.pipe(startWith(null), distinctUntilChangedObject(),
  // tap(x => console.log(`next:`, x)),
  ),
  groupedSites: groupedSites$.pipe(startWith(null), distinctUntilChangedObject(),
  // tap(x => console.log(`next:`, x)),
  ),
  areas: activeSiteAreas$.pipe(startWith(null), distinctUntilChangedObject(),
  // tap(x => console.log(`next:`, x)),
  ),
  areaSystems: activeSiteAreaSystems$.pipe(
    startWith(null),
    distinctUntilChangedObject(),
    // tap(x => console.log(`next:`, x)),
  ),
  gqlSystems: gqlSystems$.pipe(startWith(null), distinctUntilChangedObject(),
  // tap(x => console.log(`next:`, x)),
  ),
  //
  // settings
  //
  userSettings: userSettings$.pipe(
    startWith(null),
    distinctUntilChangedObject(),
    // tap(x => console.log(`next:`, x)),
  ),
  userFavoriteCompaniesIds: userFavoriteCompanyIds$.pipe(
    startWith([]),
    distinctUntilChangedObject(),
    // tap(x => console.log(`next:`, x)),
  ),
}).pipe(
  // useful for debugging
  // tap((actives) => {
  //   console.log(`activeEntities: `, actives);
  // }),

  throttleTime(30, undefined, {leading: true, trailing: true}),
  // tap(x => console.log(`all actives:`, x)),
  catchError((err, caught) => {
    // console.log(`actives ERR:`, err);
    return caught;
  }),
  shareReplay({ refCount: true, bufferSize: 1 }),
  
);

export const useActiveAppEntities = () => {
  const [actives] = useObservable(activeEntities$);
  return actives;
};
