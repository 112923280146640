import { useMutation } from 'react-query';
import { SupportTicketApi, SupportTicketBase } from '../generated/api';
import { appConfiguration } from './configuration';
export const supportTicketApi = new SupportTicketApi(appConfiguration);

export function useCreateSupportTicket() {
  return useMutation(async (supportTicketBase: SupportTicketBase) => {
    const response = await supportTicketApi.createSupportTicket(
      supportTicketBase
    );
    return response.data;
  });
}
