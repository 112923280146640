import { Button, Stack, styled } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AppTypography } from '../../base';
import { IconLabel } from '../../base/IconLabel';
import { ContactSupportDialog, LogoutDialog } from '../../dialogs';

const VersionText = styled(AppTypography)({
  marginLeft: '0.5rem',
});

const FooterStack = styled(Stack)({
  gap: '0.5rem',
});

const FooterButton = styled(Button)({
  display: 'block',
  width: '100%',
  justifyContent: 'start',
});

interface DialogState {
  contactSupport: boolean;
  logout: boolean;
}

const versionName = process.env.NX_BEER_IOT_VERSION_NAME;

export const SettingsFooter = () => {
  const [dialogState, setDialogState] = useState<DialogState>({
    contactSupport: false,
    logout: false,
  });

  return (
    <FooterStack>
      <FooterButton
        onClick={() => setDialogState({ contactSupport: true, logout: false })}
      >
        <IconLabel
          variant="button"
          colorKey="primary"
          label={
            <FormattedMessage
              id="common-contact-support"
              description="Label for contact support"
              defaultMessage="Contact Support"
            />
          }
          icon="life-ring"
        />
      </FooterButton>
      <FooterButton
        onClick={() => setDialogState({ contactSupport: false, logout: true })}
      >
        <IconLabel
          variant="button"
          colorKey="error"
          label={
            <FormattedMessage
              id="common-logout-button"
              description="Button for logging out"
              defaultMessage="Log Out"
            />
          }
          icon="arrow-right-from-bracket"
        />
      </FooterButton>
      <LogoutDialog
        open={dialogState.logout}
        onClose={() => setDialogState({ contactSupport: false, logout: false })}
      />
      <ContactSupportDialog
        open={dialogState.contactSupport}
        onClose={() => setDialogState({ contactSupport: false, logout: false })}
      />
      {versionName && (
        <VersionText variant="subtitle2">Version {versionName}</VersionText>
      )}
    </FooterStack>
  );
};
