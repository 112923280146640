export const lineTypes = [
  {
    name: 'PROFi',
    abbreviation: 'PR',
  },
  {
    name: 'Keraflux',
    abbreviation: 'KF',
  },
  {
    name: 'CBS - Stabilization',
    abbreviation: 'CBS',
  },
  {
    name: 'CFS - Final Filtration',
    abbreviation: 'CFS',
  },
];
