import { Stack, styled } from '@mui/material';
import {
  ScrollStack,
  GlobalFooter,
  PermissionView,
  PermissionKey,
} from '../../base';
import { RootPage } from '../../pages/RootPage';
import { LineStatisticsHeader } from './LineStatisticsHeader';
import { SuspenseLineStatisticsCardList } from './line-statistics-card-list/SuspenseLineStatisticsCardList';
import { LineSidebarDrawer } from '../line-sidebar-menu/LineSidebarDrawer';
import { AlertsDrawer } from '../../alerts';

const ContentStack = styled(Stack)({
  flex: 1,
});

export function LineStatisticsPanel() {
  return (
    <RootPage direction="row">
      <LineSidebarDrawer />
      <ContentStack>
        <LineStatisticsHeader />
        <ScrollStack>
          <SuspenseLineStatisticsCardList />
          <GlobalFooter />
        </ScrollStack>
      </ContentStack>
      <PermissionView permissionKey={PermissionKey.AlertWidget}>
        <AlertsDrawer />
      </PermissionView>
    </RootPage>
  );
}
