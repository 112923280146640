import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ColorKey } from '@beeriot/api-client';

// TODO: find line/machine states
export enum RunningState {
  Running = 'Running',
  Cleaning = 'Cleaning',
  Idle = 'Idle',
  Off = 'Off',
  Regeneration = 'Regeneration',
  Sanitation = 'Sanitation',
  Caustic = 'Caustic',
  Enzymatic = 'Enzymatic',
}

// The ColorKey associated with each state
export const RunningStateColorKey: Record<RunningState, ColorKey> = {
  Running: 'success',
  Cleaning: 'primary',
  Idle: 'warning',
  Off: 'highlight4',
  Regeneration: 'highlight2',
  Sanitation: 'primary',
  Caustic: 'warning',
  Enzymatic: 'highlight2',
};

export const RunningStateIcon: Record<RunningState, IconProp> = {
  Running: ['far', 'refresh'],
  Cleaning: ['far', 'washing-machine'],
  Idle: ['far', 'clock'],
  Off: ['far', 'octagon-exclamation'],
  Regeneration: ['far', 'refresh'],
  Sanitation: ['far', 'refresh'],
  Caustic: ['far', 'refresh'],
  Enzymatic: ['far', 'refresh'],
};
