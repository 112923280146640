import { styled, Card } from '@mui/material';
import { ColorKey } from '@beeriot/api-client';
import { transientOptions } from '../utils/transientOptions';

export interface AppCardProps {
  $stripeWidth?: string | number;
  $colorKey?: ColorKey;
}

export const AppCard = styled(
  Card,
  transientOptions
)<AppCardProps>(({ theme, $colorKey, $stripeWidth }) => ({
  boxShadow: theme.shadows[3],
  ...($colorKey && {
    borderLeftColor: theme.palette[$colorKey].main,
    borderLeftStyle: 'solid',
    borderLeftWidth: $stripeWidth ?? '1rem',
  }),
}));
AppCard.displayName = 'AppCard';
