import { SettingsPage } from './SettingsPage';
import { SuspensePushNotificationSettings } from '../../settings/push-notifications-settings';

export function PushNotificationSettingsPage() {
  return (
    <SettingsPage>
      <SuspensePushNotificationSettings />
    </SettingsPage>
  );
}
