import { addDays } from 'date-fns';
import { FormattedDaysLeft } from './FormattedDaysLeft';

interface FormattedDaysLeftInYearProps {
  date: Date | undefined;
}

export function FormattedDaysLeftInYear({
  date,
}: FormattedDaysLeftInYearProps) {
  if (date === undefined) return null;

  return (
    <FormattedDaysLeft startDate={new Date()} endDate={addDays(date, 365)} />
  );
}
