import { Box, Button, Stack, styled } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { SupportTicket, SupportTicketBase } from '@beeriot/api-client';
import { AppTypography } from '../../base/AppTypography';
import { useCallback } from 'react';
import { UseMutationResult } from 'react-query';
import { FileWithPath } from 'react-dropzone';
import {
  Form,
  FormFooterButtons,
  SuspenseSiteDropdown,
  SelectOption,
  FormTextArea,
  FormDropzone,
} from '../../form';
import { SuspenseSystemDropdown } from '../../form/data-dropdowns/SuspenseSystemDropdown';
import { SectionLabel } from '../../settings/settings-form';
import { transientOptions } from '../../utils';

const HeaderText = styled(AppTypography)({ alignSelf: 'center' });

const HeaderStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.componentBase.main,
  borderTopRightRadius: theme.shape.borderRadius,
  borderTopLeftRadius: theme.shape.borderRadius,
  justifyContent: 'space-between',
  padding: '1rem',
  gap: '2rem',
}));

const FormStack = styled(Stack)({ padding: '0 1rem 1rem 1rem' });

const FormLabel = styled(AppTypography)({
  marginTop: '1rem',
  marginBottom: '1rem',
});

const SelectContainer = styled(Box)({ maxWidth: '25rem' });

const AddAttachmentButton = styled(Button)({
  width: 'auto',
});

const FormButtonContainer = styled(Box)({ marginTop: '1rem' });

const StyledFormButtons = styled(FormFooterButtons)({ margin: '0.5rem' });

const PhoneLink = styled('a')(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  textDecoration: 'none',
}));

interface ContactSupportFormProps {
  onSubmit: (data: ContactSupportFormFields) => void;
  onCancel: () => void;
  mutation: UseMutationResult<
    SupportTicket,
    unknown,
    SupportTicketBase,
    unknown
  >;
}

export interface ContactSupportFormFields {
  site: SelectOption;
  system: SelectOption;
  description: string;
  files: FileWithPath[];
}

export function ContactSupportForm({
  onSubmit,
  onCancel,
  mutation,
}: ContactSupportFormProps) {
  const intl = useIntl();
  const descriptionPlaceholder = intl.formatMessage({
    id: 'support-modal-describe-issue-placeholder',
    description: 'Placeholder for issue description',
    defaultMessage: 'Describe issue i.e. broken part number etc.',
  });
  const submitButtonLabel = intl.formatMessage({
    id: 'support-modal-submit-issue-button',
    description: 'Submit issue button',
    defaultMessage: 'Submit Issue',
  });

  const successLabel = intl.formatMessage({
    id: 'contact-support-success-label',
    description: 'Success message for dialog',
    defaultMessage: 'Support Ticket Submitted',
  });

  const onConfirmOkSuccessDialog = useCallback(() => {
    mutation?.reset();
    onCancel();
  }, [mutation, onCancel]);

  return (
    <>
      <HeaderStack direction="row">
        <HeaderText $bold variant="h6">
          <FormattedMessage
            id="common-contact-support"
            description="Label for contact support"
            defaultMessage="Contact Support"
          />
        </HeaderText>
        <HeaderText>
          <FormattedMessage
            id="support-modal-instructions-label"
            description="Label for support instructions"
            defaultMessage="Fill out the form below, call support at {support} or FaceTime us at {facetime}"
            values={{
              support: (
                <PhoneLink href="tel:+1-800-555-555">1-800-555-5555</PhoneLink>
              ),
              facetime: (
                <PhoneLink href="tel:+1-800-555-555">1-800-555-5555</PhoneLink>
              ),
            }}
          />
        </HeaderText>
      </HeaderStack>
      <Form<ContactSupportFormFields> onSubmit={onSubmit}>
        <FormStack>
          <FormLabel>
            <FormattedMessage
              id="common-site-label"
              description="Site label"
              defaultMessage="Site"
            />
          </FormLabel>
          <SelectContainer>
            <SuspenseSiteDropdown />
          </SelectContainer>
          <FormLabel>
            <FormattedMessage
              id="common-machine-label"
              description="Machine label"
              defaultMessage="Machine"
            />
          </FormLabel>
          <SelectContainer>
            <SuspenseSystemDropdown />
          </SelectContainer>
          <FormLabel>
            <FormattedMessage
              id="support-modal-description-label"
              description="Label for issue description"
              defaultMessage="Please provide detailed information of your issue:"
            />
          </FormLabel>
          <FormTextArea
            name="description"
            placeholder={descriptionPlaceholder}
          />
          <FormLabel>
            <FormattedMessage
              id="support-modal-attachments-label"
              description="Label for attachments"
              defaultMessage="Attachments / Images (Optional)"
            />
          </FormLabel>
          <Stack direction="row">
            <FormDropzone
              name="files"
              required={false}
              placeholder={undefined}
              multiple
              maxFiles={5}
            />
          </Stack>
          <FormButtonContainer>
            <StyledFormButtons
              requireCancelConfirmation={false}
              submitLabel={submitButtonLabel}
              successLabel={successLabel}
              onCancel={onCancel}
              isLoading={mutation?.isLoading}
              isSuccess={mutation?.isSuccess}
              onConfirmOkSuccessDialog={onConfirmOkSuccessDialog}
            />
          </FormButtonContainer>
        </FormStack>
      </Form>
    </>
  );
}
