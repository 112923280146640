import { Paper, styled } from '@mui/material';
import { ReactNode } from 'react';
import { ColorKey } from '@beeriot/api-client';
import { AppTypography } from './AppTypography';

const StyledAppTypography = styled(AppTypography)({
  margin: '1rem',
  textAlign: 'center',
  width: '100%',
});

interface ToastPaperProps {
  label?: ReactNode;
  className?: string;
  $colorKey?: ColorKey;
  $bold?: boolean;
}

export const ToastPaper = ({
  label,
  className,
  $colorKey,
  $bold,
}: ToastPaperProps) => {
  return (
    <Paper className={className}>
      <StyledAppTypography
        variant={'subtitle1'}
        $bold={$bold}
        $colorKey={$colorKey ?? 'subtitleText'}
      >
        {label}
      </StyledAppTypography>
    </Paper>
  );
};
