import { FormattedMessage } from 'react-intl';
import { Variant } from '@mui/material/styles/createTypography';
import { useFormatDateTime } from '../../../utils';
import { AppTypography } from '../../../base/AppTypography';

export interface FilterRunsDateFieldProps {
  date?: Date | string;
  variant?: Variant;
}

export function FilterRunsDateField({
  date,
  variant,
}: FilterRunsDateFieldProps) {
  const formatDateTime = useFormatDateTime();

  return date ? (
    <AppTypography variant={variant}>{formatDateTime(date)}</AppTypography>
  ) : (
    <AppTypography $colorKey="success">
      <FormattedMessage
        id="filter-run-table-cell-in-progress"
        defaultMessage="In Progress"
        description="table cell message for in progress"
      />
    </AppTypography>
  );
}
