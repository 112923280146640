import { useActiveAppEntities, userInfo$ } from '@beeriot/api-client';
import {
  ClickAwayListener,
  Button,
  Divider,
  Stack,
  styled,
} from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import { FormattedMessage } from 'react-intl';
import { RouterLink } from '../../RouterLink';
import { AppTypography } from '../../../base/AppTypography';
import { IconLabel } from '../../../base/IconLabel';
import { useResponsiveSizes } from '../../../utils/responsive-design/useResponsiveSizes';
import { SuspenseUserRole } from './SuspenseUserRole';

const DropdownTitle = styled(AppTypography)(({ theme }) => ({
  color: theme.palette.lightText.main,
  textTransform: 'uppercase',
  padding: '0.5rem',
}));

const DropdownItem = styled(Button)({
  padding: '0.5rem',
  justifyContent: 'flex-start',
});

const DropdownUserRoleContainer = styled(Stack)({
  padding: '0.5rem',
});

interface UserInfoBoxProps {
  email: string;
  onClose: () => void;
  onSelectContactSupport: () => void;
  onSelectLogout: () => void;
}

export function UserInfoDropdown({
  email,
  onClose,
  onSelectContactSupport,
  onSelectLogout,
}: UserInfoBoxProps) {
  const { activeCompany } = useActiveAppEntities();
  const { isTabletSize, isMobileSize } = useResponsiveSizes();
  const [userInfo] = useObservable(userInfo$);
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Stack>
        <DropdownTitle $bold variant="subtitle2">
          <FormattedMessage
            id="user-info-dropdown-title-label"
            description="Label for user info dropdown title"
            defaultMessage="Logged in as {user}"
            values={{
              user: email,
            }}
          />
        </DropdownTitle>
        {isTabletSize ? (
          <>
            <Divider />
            <DropdownUserRoleContainer>
              <SuspenseUserRole name={userInfo?.name} />
            </DropdownUserRoleContainer>
            <Divider />
          </>
        ) : null}
        <Divider />
        <DropdownItem onClick={onClose}>
          <RouterLink to={`companies/${activeCompany?.id}/settings/account`}>
            <IconLabel
              variant="body1"
              colorKey="link"
              icon="gear"
              label={
                <FormattedMessage
                  id="common-user-settings"
                  description="Label for user settings"
                  defaultMessage="User Settings"
                />
              }
            />
          </RouterLink>
        </DropdownItem>
        <Divider />
        <DropdownItem onClick={onSelectContactSupport}>
          <IconLabel
            variant="body1"
            colorKey="link"
            icon="life-ring"
            label={
              <FormattedMessage
                id="common-contact-support"
                description="Label for contact support"
                defaultMessage="Contact Support"
              />
            }
          />
        </DropdownItem>
        <Divider />
        <DropdownItem onClick={onSelectLogout}>
          <IconLabel
            variant="body1"
            colorKey="error"
            icon="arrow-right-from-bracket"
            label={
              <FormattedMessage
                id="common-logout-button"
                description="Button for logging out"
                defaultMessage="Log Out"
              />
            }
          />
        </DropdownItem>
      </Stack>
    </ClickAwayListener>
  );
}
