import { SettingsUsersLanguageEnum } from '@beeriot/api-client';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export function useIntlLanguage(): (
  language: SettingsUsersLanguageEnum
) => string {
  const intl = useIntl();
  const de = intl.formatMessage({
    id: 'language-de',
    defaultMessage: 'German',
    description: 'Label for German',
  });
  const en = intl.formatMessage({
    id: 'language-en',
    defaultMessage: 'English',
    description: 'Label for English',
  });
  const enUS = intl.formatMessage({
    id: 'language-enUS',
    defaultMessage: 'English (American)',
    description: 'Label for English (American)',
  });
  const es = intl.formatMessage({
    id: 'language-es',
    defaultMessage: 'Spanish',
    description: 'Label for Spanish',
  });
  const fr = intl.formatMessage({
    id: 'language-fr',
    defaultMessage: 'French',
    description: 'Label for French',
  });
  const it = intl.formatMessage({
    id: 'language-it',
    defaultMessage: 'Italian',
    description: 'Label for Italian',
  });
  const ja = intl.formatMessage({
    id: 'language-ja',
    defaultMessage: 'Japanese',
    description: 'Label for Japanese',
  });
  const nl = intl.formatMessage({
    id: 'language-nl',
    defaultMessage: 'Dutch',
    description: 'Label for Dutch',
  });
  const ru = intl.formatMessage({
    id: 'language-ru',
    defaultMessage: 'Russian',
    description: 'Label for Russian',
  });
  const zh = intl.formatMessage({
    id: 'language-zh',
    defaultMessage: 'Chinese',
    description: 'Label for Chinese',
  });

  return useCallback(
    (runningState: SettingsUsersLanguageEnum) => {
      const runningStateIntlRecord: Record<SettingsUsersLanguageEnum, string> =
        {
          [SettingsUsersLanguageEnum.De]: de,
          [SettingsUsersLanguageEnum.En]: en,
          [SettingsUsersLanguageEnum.EnUs]: enUS,
          [SettingsUsersLanguageEnum.Es]: es,
          [SettingsUsersLanguageEnum.Fr]: fr,
          [SettingsUsersLanguageEnum.It]: it,
          [SettingsUsersLanguageEnum.Ja]: ja,
          [SettingsUsersLanguageEnum.Nl]: nl,
          [SettingsUsersLanguageEnum.Ru]: ru,
          [SettingsUsersLanguageEnum.Zh]: zh,
        };

      return runningStateIntlRecord[runningState];
    },
    [de, en, enUS, es, fr, it, ja, nl, ru, zh]
  );
}
