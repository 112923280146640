import {
  QueryClient,
  QueryKey,
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useMutation,
  useQueryClient,
} from 'react-query';
import { companyStore } from '../state/company/companyStore';
import { Company, CompanyApi, CompanyStatusEnum } from '../generated/api';
import {
  GQLCompany,
  GQLGetCompanyQuery,
  ListCompanySitesDocument,
  useGetCompanyQuery,
  useInfiniteListCompaniesQuery,
} from '../generated/gql';
import {
  getNextPageParamHandler,
  defaultPagingParams,
  LONG_POLLING_INTERVAL,
  AppQueryClient,
} from '../utils';
import { appConfiguration } from './configuration';
import { from, of } from 'rxjs';
import { concatMap, map, toArray } from 'rxjs/operators';
import { useEffect, useMemo, useRef } from 'react';
import { filterNil } from '@ngneat/elf';
import fastEqual from 'fast-deep-equal';
import { isAuthenticated$ } from '../state/auth';
import { useObservable } from '@ngneat/use-observable';
import { refetchIfPartialData, refetchIfPartialInfiniteData } from './refetchIfPartialData';
import { prefetchCompanySites } from './prefetchCompanySites';
import { prefetchSystems } from './prefetchSystems';

export const companyApi = new CompanyApi(appConfiguration);

const CompaniesQueryKey = 'listCompanies.infinite';

export function useCompany(
  companyId?: string | null,
  options?:
    | UseQueryOptions<GQLGetCompanyQuery, unknown, GQLGetCompanyQuery, QueryKey>
    | undefined
): GQLCompany | undefined {
  const { data: company, isFetching, refetch } = useGetCompanyQuery(
    {
      companyId: companyId ?? '',
    },
    { enabled: !!companyId, ...(options ?? {}) }
  );

  // refetchIfPartialData(company, isFetching, refetch);
  return company?.getCompany ?? undefined;
}

export function useCompanies<Options>(searchText: string, opts?:Options): GQLCompany[] {
  const {
    data: companies,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    dataUpdatedAt,
  } = useInfiniteListCompaniesQuery(
    {
      // replace spaces with +
      name: searchText.replace(/\s+/g, '+'),
      status: 'active',
      ...defaultPagingParams,
    },
    {
      ...(opts ?? {}),
      // staleTime: searchText.length > 0 ? 5 : LONG_POLLING_INTERVAL,
      getNextPageParam: getNextPageParamHandler(
        (query) => query?.listCompanies?.length
      ),
    }
  );

  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage();
  }

  // flatten all pages, remove nils, pre-sort on name
  const result = useMemo(() => {
    if (companies) {
      const _gqlCompanies = of(companies)
        .pipe(
          concatMap((_companies) => from(_companies?.pages ?? [])),
          concatMap((page) =>
            from((page?.listCompanies ?? []) as GQLCompany[])
          ),
          filterNil(),
          toArray(),
          map((_companies) =>
            _companies.sort((a, b) =>
              (a.name ?? '').localeCompare(b.name ?? '')
            )
          )
        )
        .syncLastResult();
      return _gqlCompanies;
    } else {
      return [];
    }
  }, [dataUpdatedAt, companies]);

  const oldResult = useRef(result);

  useEffect(() => {
    // if (fastEqual(oldResult.current, result)) return; removed due to issue with loading companies after login
    if (hasNextPage || isFetchingNextPage) return;
    oldResult.current = result;
    result.forEach((company) => {
      const cachedCompany = AppQueryClient.getQueryData(
        ['getCompany', { companyId: company?.id, }],
      );
      if(!cachedCompany) {
        AppQueryClient.setQueryData(
          ['getCompany', { companyId: company?.id, }],
          {getCompany: company, isCachedPartial: true},
        );
      }
    })
    companyStore.update((state) => {
      if (fastEqual(state.companies, result)) return state;
      return {
        ...state,
        companies: result ? result : state.companies,
      };
    });
  }, [result, isFetchingNextPage, hasNextPage]);

  return result;
}

export interface CompanyGroup {
  letter: string;
  companies: GQLCompany[];
}

interface UpdateCompanyCallbacks {
  onSuccess: (data: Company | undefined) => void;
  onError: (err: string) => void;
}

export function groupCompaniesByLetter(data: GQLCompany[]) {
  const groupedCompanies = data.reduce((acc, curr) => {
    const firstLetter = curr.name?.charAt(0).toLocaleUpperCase() ?? '?';
    const letterIndex = acc.findIndex((x) => x.letter === firstLetter);

    if (letterIndex > -1) acc[letterIndex].companies.push(curr);
    else acc.push({ letter: firstLetter, companies: [curr] });

    return acc;
  }, [] as CompanyGroup[]);

  const sortedGroupedCompanies = groupedCompanies.sort((a, b) =>
    a.letter.localeCompare(b.letter)
  );

  return sortedGroupedCompanies;
}

export function useDeleteCompany(
  companyId: string,
  { onSuccess, onError }: UpdateCompanyCallbacks
) {
  const queryClient = useQueryClient();
  return useMutation(
    async (company: GQLCompany) => {
      const response = await companyApi.updateCompany(companyId, {
        ...toCompany(company),
        status: 'inactive',
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        refetchCompanies(queryClient);
        onSuccess(data);
      },
      onError,
    }
  );
}

export function useUpdateCompany({
  onSuccess,
  onError,
}: UpdateCompanyCallbacks) {
  const queryClient = useQueryClient();
  return useMutation(
    async (company: Company) => {
      const { id: companyId, ...baseCompany } = company;
      const response = await companyApi.updateCompany(companyId, baseCompany);
      return response.data;
    },
    {
      onSuccess: (data) => {
        refetchCompanies(queryClient);
        onSuccess(data);
      },
      onError,
    }
  );
}

function toCompany(company: GQLCompany): Company {
  return {
    id: company.id ?? '',
    name: company.name ?? '',
    address: {
      address1: company.address?.address1 ?? '',
      address2: company.address?.address2 ?? '',
      city: company.address?.city ?? '',
      country: company.address?.country ?? '',
      state: company.address?.state ?? '',
      zipcode: company.address?.zipcode ?? '',
    },
    status: stringToStatus(company.status),
  };
}

function stringToStatus(str?: string | null): CompanyStatusEnum {
  if (!str) return CompanyStatusEnum.Active;
  switch (str) {
    case 'active':
      return CompanyStatusEnum.Active;
    case 'inactive':
      return CompanyStatusEnum.Inactive;
    default:
      return CompanyStatusEnum.Active;
  }
}

function refetchCompanies(queryClient: QueryClient) {
  const companySearch = companyStore.getValue().companySearch;
  queryClient.refetchQueries([CompaniesQueryKey, { name: companySearch }]);
}

export function usePrefetchBasicData(
  // companyId?: string | null, siteId?: string | null, lineId?: string | null, blockId?: string | null
) {
  const hasRunRef = useRef(false);
  const [isAuthenticated] = useObservable(isAuthenticated$)
  useEffect(() => {
    console.log("mount")
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    // let unsub = () => {};
    
    if (!hasRunRef.current && isAuthenticated) {
      // unsub = AppQueryClient.getQueryCache().subscribe((event) => {
      //   const queriesToWatch = [
      //     'listCompanySites.infinite',
      //     // 'getCompanySite'
      //   ]
      //   const queryKey = event?.query.queryKey;
      //   if(queriesToWatch.includes(queryKey) || queriesToWatch.includes(queryKey?.[0])) {
      //     console.log(event)

      //   }
      // })
      
      // prefetchCompanySites();
      // prefetchSystems();
      hasRunRef.current = true;
    }
    return () => {
      // unsub();
      console.log("unmount")
      hasRunRef.current = false;
    }
  }, [isAuthenticated,]);
}



