export enum CalendarMode {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
}

export const calendarModeAggregations: Record<
  CalendarMode,
  'hourly' | 'daily' | 'weekly' | 'monthly'
> = {
  Day: 'hourly',
  Week: 'daily',
  Month: 'weekly',
  Year: 'monthly',
};
