import {
  MutableRefObject,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { Stack, Paper, styled } from '@mui/material';
import {
  useCompanies,
  useGQLSites,
  GQLCompany,
  useActiveAppEntities,
  GQLCompanySite,
  useAreas,
} from '@beeriot/api-client';
import {
  AppTableHeader,
  AppTableHeaderControlsContainer,
  PermissionKey,
  PermissionView,
} from '../../base';
import { SettingsHeaderButton } from '../settings-form';
import { useDomNodeWidth } from '../../utils';
import { SuspenseProvisioningTable } from './SuspenseProvisioningTable';
import { ProvisioningSetupDialog } from './ProvisioningSetupDialog';
import { AutocompleteOption } from '../../form/FormAutocompleteTextField';

const TableContainer = styled(Stack)({
  minWidth: '50rem',
  maxWidth: '80rem',
});

const StyledAppTableHeaderControlsContainer = styled(
  AppTableHeaderControlsContainer
)({
  justifyContent: 'end',
});

type UseMobileProvisioningTableProps = {
  containerRef?: MutableRefObject<HTMLDivElement | null>;
};
export function useMobileProvisioningTable({
  containerRef,
}: UseMobileProvisioningTableProps = {}) {
  const width = useDomNodeWidth({
    nodeRef: containerRef,
    watchWindowResize: true,
    cachedValueIdentifier: 'ProvisioningCompanyTable',
  });
  return width < 1035;
}

export const ProvisioningSettingsPanel = () => {
  const [setupOpen, setSetupOpen] = useState<boolean>(false);
  const [formSelectedCompanyName, setFormSelectedCompanyName] = useState<
    string | AutocompleteOption | null
  >(null);
  const [formSelectedSiteName, setFormSelectedSiteName] = useState<
    string | AutocompleteOption | null
  >(null);
  const { companies } = useActiveAppEntities();
  useCompanies('');
  const formSelectedCompany: GQLCompany | undefined = useMemo(() => {
    const name = formSelectedCompanyName
      ? typeof formSelectedCompanyName === 'string'
        ? formSelectedCompanyName
        : formSelectedCompanyName.label
      : null;
    return companies.find((x) => x.name === name);
  }, [companies, formSelectedCompanyName]);

  const { sortedData: sites } = useGQLSites(formSelectedCompany?.id ?? null);

  const formSelectedSite: GQLCompanySite | undefined = useMemo(() => {
    const name = formSelectedSiteName
      ? typeof formSelectedSiteName === 'string'
        ? formSelectedSiteName
        : formSelectedSiteName.label
      : null;
    return sites.find((x) => x.name === name);
  }, [sites, formSelectedSiteName]);

  const { data: areas } = useAreas(formSelectedSite?.id ?? null);

  const startSetup = useCallback((companyName?: string) => {
    if (companyName) {
      setFormSelectedCompanyName(companyName);
    }
    setSetupOpen(true);
  }, []);
  const onSetupClose = useCallback(() => {
    setFormSelectedCompanyName(null);
    setSetupOpen(false);
  }, []);
  const containerRef = useRef(null);
  const useMobileSize = useMobileProvisioningTable({
    containerRef,
  });
  return (
    <>
      {useMobileSize ? (
        <>
          <PermissionView
            permissionKey={PermissionKey.SettingsProvisioningStartSetup}
          >
            <SettingsHeaderButton
              onClick={startSetup}
              label={
                <FormattedMessage
                  id="settings-provisioning-start-setup-button"
                  defaultMessage="Start Setup"
                  description="Start setup button"
                />
              }
            />
          </PermissionView>
          <SuspenseProvisioningTable
            containerRef={containerRef}
            startSetup={startSetup}
          />
        </>
      ) : (
        <TableContainer>
          <Paper ref={containerRef}>
            <AppTableHeader
              label={
                <FormattedMessage
                  id="settings-provisioning-companies-header"
                  defaultMessage="Companies"
                  description="header for provisioning table"
                />
              }
            >
              <StyledAppTableHeaderControlsContainer>
                <SettingsHeaderButton
                  onClick={startSetup}
                  label={
                    <FormattedMessage
                      id="settings-provisioning-start-setup-button"
                      defaultMessage="Start Setup"
                      description="Start setup button"
                    />
                  }
                />
              </StyledAppTableHeaderControlsContainer>
            </AppTableHeader>
            <SuspenseProvisioningTable
              containerRef={containerRef}
              startSetup={startSetup}
            />
          </Paper>
        </TableContainer>
      )}
      <ProvisioningSetupDialog
        open={setupOpen}
        companies={companies}
        sites={sites}
        areas={areas ?? []}
        defaultCompanyName={formSelectedCompany?.name ?? undefined}
        onClose={onSetupClose}
        setSelectedCompany={setFormSelectedCompanyName}
        setSelectedSite={setFormSelectedSiteName}
      />
    </>
  );
};
