import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { IconLabel, LoadingBox } from '../../base';

const StyledLoadingBox = styled(LoadingBox)({
  minHeight: '4rem',
  width: '30rem',
});

const FilterRunComparisonSelect = lazy(() =>
  import('./FilterRunComparisonSelectQuery').then(
    ({ FilterRunComparisonSelectQuery }) => ({
      default: FilterRunComparisonSelectQuery,
    })
  )
);

export function SuspenseFilterRunComparisonSelect() {
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <IconLabel
                label={
                  <FormattedMessage
                    id="system-statistics-filter-runs-error-label"
                    description="Error message for filter runs"
                    defaultMessage="Filter Runs Unavailable"
                  />
                }
              />
            );
          }}
        >
          <Suspense fallback={<StyledLoadingBox />}>
            <FilterRunComparisonSelect />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
