import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useConfirmForgotPassword, useIntlError } from '@beeriot/api-client';
import { ResetPasswordForm, ResetPasswordSuccess } from '../../auth';
import { RootAuthPageStack } from './RootAuthPageStack';

export function ResetPasswordPage() {
  const [resetSucceeded, setResetSucceeded] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const navigate = useNavigate();
  const intlError = useIntlError();
  const [searchParams] = useSearchParams();
  const username = searchParams.get('username');
  const confirmationCode = searchParams.get('confirmationCode');

  useEffect(() => {
    if (!username || !confirmationCode) {
      navigate('/auth');
    }
  }, [username, confirmationCode, navigate]);

  const mutation = useConfirmForgotPassword(
    username ?? '',
    confirmationCode ?? '',
    () => {
      setError(''); // Clear error on subsequent submission
      setResetSucceeded(true);
    },
    (error) => setError(intlError(error?.message))
  );

  return (
    <RootAuthPageStack>
      {resetSucceeded ? (
        <ResetPasswordSuccess />
      ) : (
        <ResetPasswordForm forgotPasswordMutation={mutation} error={error} />
      )}
    </RootAuthPageStack>
  );
}
