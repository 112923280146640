import { PermissionKey } from '../PermissionKey';

export const operatorPermissionKeys = [
  PermissionKey.SiteDetails,
  PermissionKey.SitesPROFiLineStatus,
  PermissionKey.SitesPROFiLineStatusDetailsCard,
  PermissionKey.SitesPROFiLineStatusWidget,

  PermissionKey.SitesPROFiStatistics,

  PermissionKey.SitesPROFiFilterRuns,

  PermissionKey.SitesPROFiDocumentation,
  PermissionKey.SitesPROFiReports,
  PermissionKey.SitesPROFiClusters,
  PermissionKey.SitesPROFiBrandEdit,
  PermissionKey.SitesPROFiMembraneInfo,

  PermissionKey.SitesCBSLineStatus,
  PermissionKey.SitesCBSLineStatusDetailsCard,
  PermissionKey.SitesCBSLineStatusWidget,

  PermissionKey.SitesCBSStatistics,

  PermissionKey.SitesCBSDocumentation,
  PermissionKey.SitesCBSReports,
  PermissionKey.SitesCBSBrandEdit,

  PermissionKey.SitesCFSLineStatus,
  PermissionKey.SitesCFSLineStatusDetailsCard,
  PermissionKey.SitesCFSLineStatusWidget,
  PermissionKey.SitesCFSFilterRuns,
  PermissionKey.SitesCFSStatistics,
  PermissionKey.SitesCFSDocumentation,
  PermissionKey.SitesCFSReports,

  PermissionKey.SitesKerafluxLineStatus,
  PermissionKey.SitesKerafluxLineStatusDetailsCard,
  PermissionKey.SitesKerafluxLineStatusWidget,
  PermissionKey.SitesKerafluxDocumentation,
  PermissionKey.SitesKerafluxBatchInfo,
  PermissionKey.SitesKerafluxReports,
  PermissionKey.SitesKerafluxStatistics,

  PermissionKey.ExecutiveSummaryMachine,

  PermissionKey.ExecutiveSummaryMembraneBlock,
  PermissionKey.ExecutiveSummaryMembraneBlockEquipmentEffectiveGraph,

  PermissionKey.ExecutiveSummaryCompareBrands,

  PermissionKey.Analytics,

  PermissionKey.AlertWidget,

  PermissionKey.AlertHistory,
  PermissionKey.AlertHistoryDetails,
  PermissionKey.AlertHistoryDismiss,

  PermissionKey.Settings,

  PermissionKey.SettingsAccount,

  PermissionKey.SettingsChangePassword,

  PermissionKey.SettingsThresholds,

  PermissionKey.SettingsPushNotifications,
];
