import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { useObservable } from '@ngneat/use-observable';
import {
  SettingsApi,
  SettingsSites,
  SettingsSitesCurrencyEnum,
  SettingsSitesElectricUnitsEnum,
  SettingsSitesVolumeUnitsEnum,
  SettingsUsers,
  SettingsUsersPressureUnitsEnum,
  SettingsUsersVolumeUnitsEnum,
  SettingsUsersLanguageEnum,
  SettingsSystems,
  SettingsUsersAlerts,
  SettingsPushNotifications,
} from '../generated';
import { appConfiguration } from './configuration';
import { makeStubbedRequest } from './makeStubbedRequest';
import { isAuthenticated$, userId$ } from '../state/auth/authStore.selectors';
import { settingsStore } from '../state';
import fastEqual from 'fast-deep-equal';
import { useGQLUser } from './users';
import { GQLGetUserQuery, useGetSystemQuery, useGetUserQuery } from '../generated/gql';
import { LONG_POLLING_INTERVAL, ULTRA_LONG_POLLING_INTERVAL } from '../utils';

const UserSettingsQueryKey = 'userSettings';
const UserAlertSettingsQueryKey = 'userAlertSettings';
const SiteSettingsQueryKey = 'siteSettings';
const SystemSettingsQueryKey = 'systemSettings';
const UserPushSettingsQueryKey = 'userPushSettings';

export interface MutationCallbackMethods {
  onSuccess: () => void;
  onError: (err: string) => void;
}

export const settingsApi = new SettingsApi(appConfiguration);

const defaultUserSettings: SettingsUsers = {
  language: SettingsUsersLanguageEnum.EnUs,
  dateFormat: '',
  monthFormat: '',
  volumeUnits: SettingsUsersVolumeUnitsEnum.HL,
  pressureUnits: SettingsUsersPressureUnitsEnum.Psi,
  favorites: [],
};

const defaultUserAlertSettings: SettingsUsersAlerts = {
  id: '',
  entityId: '',
  type: 'alerts',
  alertTypes: {
    machineAlarms: true,
    progressAlerts: true,
    maintenanceWarnings: true,
    systemWarnings: true,
  },
  deliveryOptions: {
    language: 'en-US',
    failureFrequencyAlert: 'daily',
  },
};

const defaultUserPushNotificationSettings: SettingsPushNotifications = {
  pushNotificationsEnabled: false,
  pushNotificationSystemIds: [],
  pushNotificationChannels: [],
  pushNotificationSubscriptions: [],
};

const defaultSystemSettings: SettingsSystems = {
  consumption: {
    waterL: 6600,
    airKg: 24,
    co2Kg: 6,
    beerLostL: 75,
    causticKg: 20,
    membraneCleanerKg: 21,
    peroxideL: 6,
    cipEnergykWh: 0,
  },
  every10thCycle: {
    acidicChemicalL: 5,
  },
  thresholdValues: {
    targetFlowRateHlh: 400,
  },
  degreeOfEfficiency: {
    green: 90,
    red: 80,
  },
  actualFlow: {
    green: 20,
    red: 50,
    yellow: 0,
  },
  actualDp: {
    green: 1,
    red: 2,
    yellow: 0,
  },
  cluster: {
    green: 'Pass',
    red: 'Fail',
  },
  turbidityEbc: {
    green: 0.6,
    red: 0.0,
    yellow: 0,
  },
  oxygen: {
    green: 15,
    red: 30,
    yellow: 0,
  },
  netCapacity: {
    green: 300,
    red: 150,
    yellow: 0,
  },
  opex: {
    green: 0.15,
    red: 0.25,
    yellow: 0,
  },
  concentrateStatus: {
    green: 'Good',
    red: 'Low',
  },
  waterHl: {
    green: 30,
    red: 40,
    yellow: 0,
  },
  daWaterHl: {
    green: 4,
    red: 6,
    yellow: 0,
  },
  caustic: {
    green: false,
    yellow: false,
    red: false,
  },
  peroxyde: {
    green: false,
    yellow: false,
    red: false,
  },
  membraneCleaner: {
    green: false,
    yellow: false,
    red: false,
  },
  serviceSettings: 'profi',
};

export const defaultUtilityCostsSettings: SettingsSites = {
  electricUnits: SettingsSitesElectricUnitsEnum.KWh,
  volumeUnits: SettingsSitesVolumeUnitsEnum.Gal,
  currency: SettingsSitesCurrencyEnum.Usd,
  electricCost: 0.0,
  coldWaterCost: 0.0,
  hotWaterCost: 0.0,
  daWaterCost: 0.0,
  chemCausticCost: 0.0,
  chemHydrogenPeroxideCost: 0.0,
  chemAcidCost: 0.0,
  chemSpecialCleanerCost: 0.0,
  pvppCost: 0.0,
  enzymaticCost: 0.0,
  coolingEnergyCost: 0.0,
};

async function getUserSettings(userId?: string | null) {
  if (!userId) return null;
  const res = await settingsApi
    .getSettingsUsers(userId, undefined)
    .catch(() => null);

  const settings = !res || res.status >= 400 ? defaultUserSettings : res.data;
  settingsStore.update((s) => {
    if (fastEqual(s.userSettings, settings)) return s;
    return { ...s, userSettings: settings };
  });

  return settings;
}

export function useUserSettings(): UseQueryResult<SettingsUsers> {
  // user settings are fetched as part of localization provider which occurs before
  // the auth token is updated, so we will need to wait for it here
  const [isAuthenticated] = useObservable(isAuthenticated$);
  const [userId] = useObservable(userId$);
  const q = useGetUserQuery(
    { userId: userId ?? '' },
    { refetchInterval: 15000, enabled: !!userId }
  );

  return useQuery(
    [UserSettingsQueryKey, userId],
    () => getUserSettings(userId),
    // refetchOnWindowFocus is disabled here to prevent a race condition with company favoriting
    { enabled: isAuthenticated && !!userId, refetchOnWindowFocus: false, placeholderData: defaultUserSettings }
  );
}

export function useGQLUserSettings(): UseQueryResult<GQLGetUserQuery> {
  // user settings are fetched as part of localization provider which occurs before
  // the auth token is updated, so we will need to wait for it here
  const [isAuthenticated] = useObservable(isAuthenticated$);
  const [userId] = useObservable(userId$);
  const q = useGetUserQuery(
    { userId: userId ?? '' },
    { refetchInterval: LONG_POLLING_INTERVAL, enabled: !!userId && isAuthenticated, refetchOnMount: false }
  );

  return q;
}

export function useUpdateUserSettings(callbacks?: MutationCallbackMethods) {
  const queryClient = useQueryClient();
  const [userId] = useObservable(userId$);
  return useMutation(
    async (userSettings: SettingsUsers) => {
      if (!userId) return null;
      const response = await settingsApi.updateSettingsUsers(
        userId,
        userSettings
      );
      return response.data;
    },
    {
      onMutate: async (data: SettingsUsers) => {
        await queryClient.cancelQueries([UserSettingsQueryKey, userId]);
        const previousState = queryClient.getQueryData<SettingsUsers>([
          UserSettingsQueryKey,
          userId,
        ]);
        queryClient.setQueryData([UserSettingsQueryKey, userId], data);
        settingsStore.update((s) => {
          if (fastEqual(s.userSettings, data)) return s;
          return { ...s, userSettings: data };
        });
        return { previousState };
      },
      onError: (_err, _newSettings, context) => {
        if (context?.previousState) {
          queryClient.setQueryData(
            [UserSettingsQueryKey, userId],
            context.previousState
          );
        }
        settingsStore.update((s) => {
          if (
            !context?.previousState ||
            fastEqual(s.userSettings, context.previousState)
          )
            return s;
          return { ...s, userSettings: context.previousState };
        });
        callbacks?.onError(_err as string);
      },
      onSuccess: () => {
        callbacks?.onSuccess();
      },
    }
  );
}

export const saveAccountSettings = (userId: string, data: SettingsUsers) => {
  return settingsApi.updateSettingsUsers(userId, data);
};

export const saveUtilityCostsSettings = (
  siteId: string,
  data: SettingsSites
) => {
  return settingsApi.updateSettings(siteId, data);
};

export function useUpdateUtilityCostsSettings(
  siteId?: string | null,
  callbacks?: MutationCallbackMethods
) {
  const queryClient = useQueryClient();
  return useMutation(
    async (userSettings: SettingsSites) => {
      if (!siteId) return null;
      const response = await saveUtilityCostsSettings(siteId, userSettings);
      return response.data;
    },
    {
      onMutate: async (data: SettingsSites) => {
        await queryClient.cancelQueries([SiteSettingsQueryKey, siteId]);
        const previousState = queryClient.getQueryData<SettingsSites>([
          SiteSettingsQueryKey,
          siteId,
        ]);
        queryClient.setQueryData([SiteSettingsQueryKey, siteId], data);
        return { previousState };
      },
      onError: (_err, _newSettings, context) => {
        if (context?.previousState) {
          queryClient.setQueryData(
            [SiteSettingsQueryKey, siteId],
            context.previousState
          );
        }
        callbacks?.onError(_err as string);
      },
      onSuccess: () => {
        callbacks?.onSuccess();
      },
    }
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sendPallNotification = (data: any) => {
  console.log('sendPallNotification: ', data);
  // TODO: Make Api call here
  return makeStubbedRequest(null);
};

async function getUtilityCostsSettings(siteId?: string | null) {
  if (!siteId) return null;
  return await new Promise<SettingsSites>((resolve) => {
    return settingsApi
      .getSettingsSites(siteId, undefined, {
        validateStatus: (status) => {
          return status === 200 || status === 404;
        },
      })
      .then((res) => {
        if (res.status === 404) {
          resolve(defaultUtilityCostsSettings);
        } else resolve(res.data);
      });
  });
}

export function useSiteSettings(
  siteId?: string | null
): UseQueryResult<SettingsSites> {
  return useQuery(['siteSettings', siteId], () =>
    getUtilityCostsSettings(siteId)
  , { placeholderData: defaultUtilityCostsSettings });
}

async function getSystemSettings(systemId?: string | null) {
  if (!systemId) return undefined;
  const res = await settingsApi.getSettingsSystems(systemId, undefined, {
    validateStatus: (status) => {
      return status === 200 || status === 404;
    },
  });

  const settings =
    !res || res.status === 404 ? defaultSystemSettings : res.data;
  return settings;
}

export function useSystemSettings(
  systemId?: string | null
): UseQueryResult<SettingsSystems> {
  // const [isAuthenticated] = useObservable(isAuthenticated$);
  // const [userId] = useObservable(userId$);
  // const q = useGetSystemQuery(
  //   { systemId: userId ?? '' },
  //   { refetchInterval: LONG_POLLING_INTERVAL, enabled: !!userId && isAuthenticated, refetchOnMount: false }
  // );
  // return q


  return useQuery([SystemSettingsQueryKey, systemId], () =>
    getSystemSettings(systemId), { placeholderData: defaultSystemSettings, refetchOnMount: false, refetchInterval: ULTRA_LONG_POLLING_INTERVAL }
  );
}

export const editSystemSettings = async (
  systemId: string,
  data: SettingsSystems
) => {
  const res = await settingsApi.updateSettingsSystems(systemId, data);
  return res;
};

export function useUpdateSystemSettings(
  systemId?: string | null,
  callbacks?: MutationCallbackMethods
) {
  // const queryClient = useQueryClient();
  return useMutation(
    async (data: SettingsSystems) => {
      if (!systemId) return null;
      return editSystemSettings(systemId, data);
    },
    {
      onSuccess: () => {
        callbacks?.onSuccess();
      },
      onError: (err: string) => {
        callbacks?.onError(err);
      },
    }
  );
}

async function getUsersAlertSettings(userId?: string | null) {
  if (!userId) return null;
  const res = await settingsApi.getSettingsUsersAlerts(userId, undefined, {
    validateStatus: (status) => {
      return status === 200 || status === 404;
    },
  });

  const settings =
    !res || res.status === 404 ? defaultUserAlertSettings : res.data;
  return settings;
}

export function useUsersAlertSettings(): UseQueryResult<SettingsUsersAlerts> {
  const [userId] = useObservable(userId$);
  return useQuery(
    [UserAlertSettingsQueryKey, userId],
    () => getUsersAlertSettings(userId),
    { enabled: !!userId, placeholderData: defaultUserAlertSettings }
  );
}

export function useUpdateUsersAlertSettings(
  callbacks?: MutationCallbackMethods
) {
  const queryClient = useQueryClient();
  const [userId] = useObservable(userId$);
  return useMutation(
    async (userAlertSettings: SettingsUsersAlerts) => {
      if (!userId) return null;
      const response = await settingsApi.updateSettingsUsersAlerts(
        userId,
        userAlertSettings
      );
      return response.data;
    },
    {
      onError: (err) => {
        callbacks?.onError(err as string);
      },
      onSuccess: () => {
        queryClient.resetQueries(UserAlertSettingsQueryKey);
        callbacks?.onSuccess();
      },
    }
  );
}

async function getUsersPushNotificationSettings(userId?: string | null) {
  if (!userId) return null;
  const res = await settingsApi.getSettingsPushNotifications(
    userId,
    undefined,
    {
      validateStatus: (status) => {
        return status === 200 || status === 404;
      },
    }
  );

  const settings =
    !res || res.status === 404
      ? (defaultUserSettings as unknown as SettingsPushNotifications)
      : res.data;
  if (!settings.pushNotificationChannels) {
    settings.pushNotificationChannels = [];
  }
  if (!settings.pushNotificationSubscriptions) {
    settings.pushNotificationSubscriptions = [];
  }
  return settings;
}

export function useUsersPushNotificationSettings(): UseQueryResult<SettingsPushNotifications> {
  const [userId] = useObservable(userId$);
  return useQuery(
    [UserPushSettingsQueryKey, userId],
    () => getUsersPushNotificationSettings(userId),
    { enabled: !!userId, placeholderData: defaultUserPushNotificationSettings }
  );
}

export function useUpdateUsersPushNotificationSettings(
  callbacks?: MutationCallbackMethods
) {
  const queryClient = useQueryClient();
  const [userId] = useObservable(userId$);
  return useMutation(
    async (userAlertSettings: SettingsPushNotifications) => {
      if (!userId) return null;
      userAlertSettings.pushNotificationSubscriptions =
        userAlertSettings.pushNotificationSubscriptions?.map((x) => {
          if (typeof x === 'string') {
            return JSON.parse(x);
          } else if (x instanceof PushSubscription) {
            return x.toJSON();
          } else {
            return x;
          }
        }) ?? [];
      const response = await settingsApi.updateSettingsPushNotifications(
        userId,
        userAlertSettings
      );
      return response.data;
    },
    {
      onError: (err) => {
        callbacks?.onError(err as string);
      },
      onSuccess: () => {
        queryClient.resetQueries(UserPushSettingsQueryKey);
        callbacks?.onSuccess();
      },
    }
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sendTestPushNotification = async (userId: string) => {
  console.log('send test notification for user id: ', userId);
  const response = await settingsApi.sendTestPushNotifications(userId);
  return response.data;
};
