import { useMemo } from 'react';
import { styled, useTheme } from '@mui/material';
import Select, {
  DropdownIndicatorProps,
  components,
  CSSObjectWithLabel,
} from 'react-select';
import { SelectOption } from '../../form/SelectOption';
import { AppIcon } from '../AppIcon';
import { Form } from '../../form';

const StyledSelect = styled(Select)({
  padding: '0.5rem',
});

const StyledAppIcon = styled(AppIcon)({
  fontSize: '1.5rem',
});

const IndicatorSeparator = () => {
  return null;
};

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <StyledAppIcon icon={'chevron-down'} $colorKey="link" size="lg" />
    </components.DropdownIndicator>
  );
};

export interface GraphSelectProps {
  defaultValue?: SelectOption;
  options?: SelectOption[];
  formOnSubmit?: (data: SelectOption) => void;
}

export function GraphSelect({
  defaultValue,
  options,
  formOnSubmit,
}: GraphSelectProps) {
  const theme = useTheme();
  const optionStyles = useMemo(
    () => ({
      option: (base: CSSObjectWithLabel) => ({
        ...base,
        borderBottom: `1px solid ${theme.palette.shade.dark}`,
        color: theme.palette.shade.dark,
        backgroundColor: theme.palette.componentShade.main,
        height: '100%',
      }),
      control: (base: CSSObjectWithLabel) => ({
        ...base,
        backgroundColor: theme.palette.componentShade.main,
        minWidth: '16rem',
      }),
      menu: (base: CSSObjectWithLabel) => ({
        ...base,
        backgroundColor: theme.palette.componentShade.main,
        zIndex: 1,
      }),
    }),
    [theme]
  );

  return (
    <StyledSelect
      onChange={(data) => {
        if (data != null) {
          formOnSubmit?.(data as SelectOption);
        }
      }}
      defaultValue={defaultValue ?? ''}
      options={options}
      styles={optionStyles}
      components={{ IndicatorSeparator, DropdownIndicator }}
    />
  );
}
