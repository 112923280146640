import { Alert, Divider, styled } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, FormFooterButtons } from '../../form';
import {
  SectionLabel,
  SectionControl,
  SectionTextInput,
  SectionTextAreaInput,
} from '../../settings/settings-form';

const StyledFormFooterButtons = styled(FormFooterButtons)({
  margin: '1rem',
});

interface ShareExportFormFields {
  email: string;
}

export interface ShareExportDialogContentProps {
  onClose: () => void;
  onSubmit: (data: ShareExportFormFields) => void;
  isLoading?: boolean;
  isError?: boolean;
}

export function ShareExportDialogContent({
  onClose,
  onSubmit,
  isLoading,
  isError,
}: ShareExportDialogContentProps) {
  const intl = useIntl();

  const emailLabel = intl.formatMessage({
    id: 'common-email-address-label',
    description: 'label for email address',
    defaultMessage: 'Email Address',
  });

  const emailPlaceholder = intl.formatMessage({
    id: 'common-email-placeholder',
    defaultMessage: 'Enter email',
    description: 'label for email placeholder',
  });

  const subjectLabel = intl.formatMessage({
    id: 'common-subject-label',
    description: 'label for subject',
    defaultMessage: 'Subject',
  });

  const subjectPlaceholder = intl.formatMessage({
    id: 'reports-share-subject-placeholder',
    description: 'placegholder for subject text input',
    defaultMessage: 'Enter subject',
  });

  const messageLabel = intl.formatMessage({
    id: 'common-message-label',
    description: 'label for message',
    defaultMessage: 'Message',
  });

  const messagePlaceholder = intl.formatMessage({
    id: 'reports-share-message-placeholder',
    description: 'placeholder for message text area',
    defaultMessage: 'Enter message to recipients',
  });

  const submitLabel = intl.formatMessage({
    id: 'reports-share-submit-button-label',
    description: 'label for dialog submit button',
    defaultMessage: 'Send',
  });

  return (
    <Form<ShareExportFormFields> onSubmit={onSubmit}>
      <SectionControl>
        <SectionLabel>{emailLabel}</SectionLabel>
        <SectionTextInput
          name="email"
          placeholder={emailPlaceholder}
          required
        />
      </SectionControl>
      <Divider />

      {isError && (
        <Alert
          variant="filled"
          severity="info"
          sx={{ margin: '1rem 1rem 0 1rem' }}
        >
          <FormattedMessage
            id="report-share-dialog-error-label"
            defaultMessage="An error occurred while sharing this report. Please try again later or contact support."
            description="Label for alert dismissal error"
          />
        </Alert>
      )}
      <StyledFormFooterButtons
        requireCancelConfirmation={false}
        justify="center"
        onCancel={onClose}
        submitLabel={submitLabel}
        isLoading={isLoading}
      />
    </Form>
  );
}
