import { UseMutationResult } from 'react-query';
import { Dialog } from '@mui/material';
import { CreateUpdateDocumentRequest, Document } from '@beeriot/api-client';
import { AddEditDocumentForm } from './AddEditDocumentForm';

export interface AddEditDocumentDialogProps {
  item?: Document;
  open: boolean;
  mutation:
    | UseMutationResult<Document, string, CreateUpdateDocumentRequest, unknown>
    | UseMutationResult<
        Document | undefined,
        string,
        CreateUpdateDocumentRequest,
        unknown
      >;
  error?: React.ReactNode;
  onClose: () => void;
  downloadDocument: (data: Document) => void;
  showDocument: (data: Document) => void;
  setError: (err: string) => void;
}

export function AddEditDocumentDialog({
  item,
  open,
  mutation,
  error,
  onClose,
  downloadDocument,
  showDocument,
  setError,
}: AddEditDocumentDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') onClose();
      }}
      maxWidth="md"
      fullWidth
    >
      <AddEditDocumentForm
        onCancel={onClose}
        item={item}
        mutation={mutation}
        error={error}
        downloadDocument={downloadDocument}
        showDocument={showDocument}
        setError={setError}
      />
    </Dialog>
  );
}
