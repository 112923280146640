import { Dialog, DialogTitle, Stack, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ShareExportDialogContent } from './ShareExportDialogContent';
import { TelemetryDataExport, useRefetchExports, useShareExport } from '@beeriot/api-client';
import { AppTypography } from '../../base/AppTypography';

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': { width: '100%' },
});

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.componentBase.main,
  borderTopRightRadius: theme.shape.borderRadius,
  borderTopLeftRadius: theme.shape.borderRadius,
}));

interface ShareExportDialogProps {
  open: boolean;
  onClose: () => void;
  dataExport?: TelemetryDataExport;
}

export function ShareExportDialog({
  open,
  onClose,
  dataExport,
}: ShareExportDialogProps) {
  const shareExportMutation = useShareExport(() => {
    onClose();
  });
  const refetch = useRefetchExports();
  return (
    <StyledDialog open={open}>
      <StyledDialogTitle>
        <FormattedMessage
          id="exports-share-dialog-title"
          description="Title for share export dialog"
          defaultMessage="Share Export"
        />
      </StyledDialogTitle>
      <AppTypography variant="body1" sx={{ padding: '1rem' }}>
        <FormattedMessage
          id="exports-share-description"
          description="label for export sharing description"
          defaultMessage={`This will share the "{exportName}" export with the email specified.`}
          values={{ exportName: dataExport?.name ?? '' }}
        />
      </AppTypography>
      <ShareExportDialogContent
        onClose={() => {
          onClose();
          shareExportMutation.reset();
          refetch();
        }}
        onSubmit={(data) => {
          if (!dataExport) return;
          shareExportMutation.mutate({
            exportId: dataExport.id ?? '',
            siteId: dataExport.siteId ?? '',
            systemId: dataExport.systemId ?? '',
            companyId: dataExport.companyId ?? '',

            emailAddress: data.email,
          });
        }}
        isLoading={shareExportMutation.isLoading}
        isError={shareExportMutation.isError}
      />
    </StyledDialog>
  );
}
