import { createStore, select, withProps,  } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { GQLReport, GQLReportDefinition } from '../generated/gql';
import { combineLatest, map, tap } from 'rxjs';
import { ActiveEntitiesType, activeEntities$ } from '../app/useActiveAppEntities';
import { active } from 'd3';

export interface UrlLocationProps {
  companyId?: string;
  siteId?: string;
  lineId?: string;
  blockId?: string;
}

export const urlLocationStore = createStore(
  { name: 'urlLocation' },
  withProps<UrlLocationProps>({})
);

persistState(urlLocationStore, {
  key: 'urlLocation',
  storage: sessionStorageStrategy,
});

export const companyId$ = urlLocationStore.pipe(
  select((state) => {
    return state.companyId;
  })
);

export const siteId$ = urlLocationStore.pipe(
  select((state) => {
    return state.siteId;
  })
);

export const lineId$ = urlLocationStore.pipe(
  select((state) => {
    return state.lineId;
  })
);


export const blockId$ = urlLocationStore.pipe(
  select((state) => {
    return state.blockId;
  })
);

export const urlParamsAndActiveEntities$ = combineLatest({urlLocationStore, activeEntities:activeEntities$}).pipe();