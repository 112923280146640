import { ReactNode } from 'react';
import { Stack, styled } from '@mui/material';
import { Property } from 'csstype';
import { Variant } from '@mui/material/styles/createTypography';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { ColorKey } from '@beeriot/api-client';
import { AppIcon } from './AppIcon';
import { transientOptions } from '../utils/transientOptions';
import { AppTypography } from './AppTypography';

import { TextWrapProps } from './TextWrapProps';

const Icon = styled(AppIcon)`
  display: flex;
  padding: 0.25rem;
  justify-content: center;
`;

const StackAlign = styled(
  Stack,
  transientOptions
)<{
  $justifyContent: Property.JustifyContent | undefined;
  $textAlign: Property.TextAlign | undefined;
  $gap: Property.Gap | undefined;
}>(({ $justifyContent, $textAlign, $gap }) => ({
  alignItems: 'center',
  justifyContent: $justifyContent ?? 'inherit',
  textAlign: $textAlign ?? 'inherit',
  gap: $gap ?? 'inherit',
}));

export interface IconLabelProps extends TextWrapProps {
  label?: ReactNode;
  bold?: boolean;
  colorKey?: ColorKey;
  variant?: Variant;
  icon?: IconProp;
  iconSize?: SizeProp;
  iconColorKey?: ColorKey;
  customIcon?: ReactNode;
  iconOnClick?: () => void;
  justifyContent?: Property.JustifyContent;
  textAlign?: Property.TextAlign;
  gap?: Property.Gap;
  className?: string;
  wrapLabels?: Property.WhiteSpace;
  $lineHeight?: Property.LineHeight;
  ellipsize?: boolean;
  lineClamp?: Property.WebkitLineClamp;
}

const VariantIconDefaultSizeMap = new Map<Variant, SizeProp>([
  ['h1', '3x'],
  ['h2', '2x'],
  ['h3', 'lg'],
  ['h4', 'lg'],
  ['h5', '1x'],
  ['button', '1x'],
  ['h6', 'sm'],
  ['body1', 'sm'],
  ['body2', 'sm'],
  ['subtitle1', 'xs'],
  ['subtitle2', 'xs'],
  ['caption', 'xs'],
]);

export function IconLabel({
  label = '',
  bold,
  colorKey,
  variant = 'h5',
  icon,
  iconSize = VariantIconDefaultSizeMap.get(variant) ?? '1x',
  iconColorKey = colorKey,
  customIcon,
  iconOnClick,
  justifyContent,
  textAlign,
  gap,
  className,
  $flexWrap,
  $whiteSpace,
  $wordWrap,
  $lineHeight,
  ellipsize,
  lineClamp,
}: IconLabelProps) {
  return (
    <StackAlign
      className={className}
      direction="row"
      $justifyContent={justifyContent}
      $textAlign={textAlign}
      $gap={gap}
    >
      {icon ? (
        <Icon
          icon={icon}
          $colorKey={iconColorKey}
          onClick={iconOnClick}
          size={iconSize}
        />
      ) : (
        !!customIcon && customIcon
      )}
      <AppTypography
        $flexWrap={$flexWrap}
        $whiteSpace={$whiteSpace}
        $wordWrap={$wordWrap}
        $bold={bold}
        $colorKey={colorKey}
        $lineHeight={$lineHeight}
        $ellipsize={ellipsize}
        $lineClamp={lineClamp}
        variant={variant}
      >
        {label}
      </AppTypography>
    </StackAlign>
  );
}
