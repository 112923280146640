import {
  useIntlError,
  activeSite$,
  useUpdateUtilityCostsSettings,
} from '@beeriot/api-client';
import { useObservable } from '@ngneat/use-observable';
import { useState } from 'react';
import {
  SuspenseLocalUtilityCostsSettings,
  SiteSelectionHeader,
} from '../../settings';
import { SettingsPage } from './SettingsPage';

export function LocalUtilityCostsSettingsPage() {
  const [error, setError] = useState<string | undefined>();
  const intlError = useIntlError();
  const [activeSite] = useObservable(activeSite$);

  const saveUtilityCostsMutation = useUpdateUtilityCostsSettings(
    activeSite?.id,
    {
      onSuccess: () => {
        setError('');
      },
      onError: (err: string) => {
        setError(intlError(err));
      },
    }
  );

  return (
    <SettingsPage header={<SiteSelectionHeader />}>
      <SuspenseLocalUtilityCostsSettings
        mutation={saveUtilityCostsMutation}
        error={error}
      />
    </SettingsPage>
  );
}
