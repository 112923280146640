import {
  CompanySite,
  CompanySiteBase,
  GQLCompanySite,
} from '@beeriot/api-client';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  styled,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { UseMutationResult } from 'react-query';
import { EditSiteForm } from './EditSiteForm';

const StyledDialogContent = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 0,
});

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: '0.5rem 1rem',
  backgroundColor: theme.palette.base.main,
  borderTopRightRadius: theme.shape.borderRadius,
  borderTopLeftRadius: theme.shape.borderRadius,
}));

const DividerContainer = styled(Box)({
  padding: '0',
});

const StyledDivider = styled(Divider)({
  borderBottomWidth: '2px',
});

export interface EditSiteDialogProps {
  mutation: UseMutationResult<CompanySite, unknown, CompanySiteBase, unknown>;
  site: GQLCompanySite;
  open: boolean;
  confirm: (site: CompanySiteBase) => void;
  cancel: () => void;
}

export function EditSiteDialog({
  mutation,
  site,
  open,
  confirm,
  cancel,
}: EditSiteDialogProps) {
  return (
    <Dialog open={open}>
      <StyledDialogTitle>
        <FormattedMessage
          id="edit-sites-edit-site-dialog-title"
          description="Title for edit site"
          defaultMessage="Edit Site"
        />
      </StyledDialogTitle>
      <DividerContainer>
        <StyledDivider />
      </DividerContainer>
      <StyledDialogContent>
        <EditSiteForm
          site={site}
          confirm={confirm}
          cancel={cancel}
          mutation={mutation}
        />
      </StyledDialogContent>
    </Dialog>
  );
}
