import { GQLSystem } from '@beeriot/api-client';
import { CardContent, Stack, styled } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  AppTypography,
  AppCard,
  RunningStatusTimelineView,
} from '../../../base';
import { recentStatusToTimelineData } from '../../../utils';

const TimelineContainer = styled(Stack)({
  padding: '0.25rem 0 0 0',
  width: '100%',
});

const StackContainer = styled(Stack)({
  gap: '1rem',
});

const StyledTypography = styled(AppTypography)({
  marginBottom: '0.5rem',
});

export interface KerafluxMembraneCleaningCardProps {
  system: GQLSystem;
}

export function KerafluxMembraneCleaningCard({
  system,
}: KerafluxMembraneCleaningCardProps) {
  const intl = useIntl();
  const NA = intl.formatMessage({
    id: 'common-not-applicable',
    defaultMessage: 'N/A',
    description: "Label for when there isn't a value to display",
  });

  return (
    <AppCard>
      <CardContent>
        <StackContainer>
          <Stack>
            <StyledTypography variant="h6" $bold>
              <FormattedMessage
                id="common-cip-label"
                description="Label for CIP"
                defaultMessage="CIP"
              />
            </StyledTypography>
            <TimelineContainer>
              {system.cleanUpLiveStatus?.recentCleanUpUnitStatus &&
              system.cleanUpLiveStatus.recentCleanUpUnitStatus.length > 0 ? (
                <RunningStatusTimelineView
                  data={recentStatusToTimelineData(
                    system.cleanUpLiveStatus.recentCleanUpUnitStatus
                  )}
                />
              ) : (
                <StyledTypography $bold variant="subtitle2">
                  {NA}
                </StyledTypography>
              )}
              <AppTypography variant="subtitle2" $colorKey="lightText">
                <FormattedMessage
                  id="common-running-status-label"
                  description="Label for running status"
                  defaultMessage="Running Status"
                />
              </AppTypography>
            </TimelineContainer>
          </Stack>
        </StackContainer>
      </CardContent>
    </AppCard>
  );
}
