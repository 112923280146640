import { Group, activeGroupType$ } from '@beeriot/api-client';
import { Button, Stack, styled } from '@mui/material';
import { useObservable } from '@ngneat/use-observable';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AppTypography,
  IconLabel,
  PermissionKey,
  PermissionView,
} from '../../../base';
import { AddGroupTypeDialog } from './add-group-type/AddGroupTypeDialog';
import { GroupTypeListItem } from './GroupTypeListItem';

const ListContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  padding: '0.8rem',
  justifyContent: 'flex-start',
  marginBottom: '1rem',
  backgroundColor: theme.palette.componentBase.main,
  overflowX: 'hidden',
}));

const NewGroupTypeButton = styled(Button)({
  justifyContent: 'flex-start',
  position: 'sticky',
});

const ListHeader = styled(AppTypography)({
  marginBottom: '1rem',
});

const NoGroupTypesMessage = styled(AppTypography)({
  textAlign: 'left',
  margin: '0.4rem 0.75rem',
});

export interface GroupTypeListProps {
  types: Group[];
  companyId: string | null;
}

export function GroupTypeList({ types, companyId }: GroupTypeListProps) {
  const [open, setOpen] = useState(false);
  const [activeGroupType] = useObservable(activeGroupType$);

  return (
    <ListContainer>
      <Stack>
        <ListHeader variant="h6" $bold>
          <FormattedMessage
            id="organize-sites-group-type-list-header"
            defaultMessage="Group Types"
            description="Header for Group Type List"
          />
        </ListHeader>
        <Stack>
          {types.length === 0 ? (
            <NoGroupTypesMessage variant="body1" $colorKey="lightText" $bold>
              <FormattedMessage
                id="organize-sites-group-type-list-no-group-types-error"
                defaultMessage="No Group Types Defined"
                description="Error message for no group types"
              />
            </NoGroupTypesMessage>
          ) : (
            types.map((item) => {
              return (
                <GroupTypeListItem
                  key={item.id}
                  item={item}
                  selectedTypeId={activeGroupType?.id ?? ''}
                />
              );
            })
          )}
        </Stack>
      </Stack>
      <PermissionView
        permissionKey={PermissionKey.SitesOrganizeSitesCreateRegionGroupType}
      >
        <>
          {companyId != null ? (
            <NewGroupTypeButton onClick={() => setOpen(true)}>
              <IconLabel
                label={
                  <FormattedMessage
                    id="organize-sites-add-new-group-type-button"
                    description="Button that opens New Group Type dialog"
                    defaultMessage="New Group Type"
                  />
                }
                colorKey="link"
                variant="button"
                icon={'plus'}
                iconSize="lg"
                iconColorKey="link"
              />
            </NewGroupTypeButton>
          ) : null}

          <AddGroupTypeDialog
            companyId={companyId ?? ''}
            open={open}
            handleClose={() => setOpen(false)}
          />
        </>
      </PermissionView>
    </ListContainer>
  );
}
