import { Suspense, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary } from 'react-query';
import { IconLabel, LoadingBox } from '../../base';

const TableLoadingBox = styled(LoadingBox)({
  minHeight: '4rem',
  width: '100%',
});

const CertificateProvisioningSystemFields = lazy(() =>
  import('./CertificateProvisioningSystemFieldsQuery').then(
    ({ CertificateProvisioningSystemFieldsQuery }) => ({
      default: CertificateProvisioningSystemFieldsQuery,
    })
  )
);

export function SuspenseCertificateProvisioningSystemFields() {
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackRender={() => {
            return (
              <IconLabel
                label={
                  <FormattedMessage
                    id="settings-certificate-provisioning-error-label"
                    description="Error message for certificate provisioning"
                    defaultMessage="Certificate Provisioning Unavailable"
                  />
                }
              />
            );
          }}
        >
          <Suspense fallback={<TableLoadingBox />}>
            <CertificateProvisioningSystemFields />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
