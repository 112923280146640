import { Stack, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { AppTypography, ConfirmDeleteButton } from '../../base';

const StyledStack = styled(Stack)({
  margin: '0.5rem',
});

export interface ConfirmDeleteDocumentButtonProps {
  onDelete: () => void;
}

export const ConfirmDeleteDocumentButton = ({
  onDelete,
}: ConfirmDeleteDocumentButtonProps) => {
  return (
    <StyledStack>
      <ConfirmDeleteButton
        onDelete={onDelete}
        buttonVariant={'outlined'}
        dialogTitle={
          <FormattedMessage
            id="documentation-document-modal-confirm-delete-dialog-title-label"
            description="Label for the document form delete confirmation dialog title"
            defaultMessage="Delete Document?"
          />
        }
        dialogMessage={
          <AppTypography>
            <FormattedMessage
              id="documentation-document-modal-confirm-delete-dialog-message-label"
              description="Label for the document form delete confirmation dialog message"
              defaultMessage="Are you sure you wish to Delete this Document?"
            />
          </AppTypography>
        }
      />
    </StyledStack>
  );
};
